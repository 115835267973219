export const UserType = {
  student: 'STUDENT',
  company: 'COMPANY',
  counselor: 'COUNSELOR',
};

export const Menus = {
  STUDENT: [
    {
      icon: 'user',
      selectedPathname: 'profile',
      name: '프로필',
      childerenMenus: [
        {
          url: '/profile/manage',
          name: '내 프로필 관리',
        },
        {
          url: '/profile/account',
          name: '계정 관리',
        },
      ],
    },
    {
      icon: 'chat',
      selectedPathname: 'counsel',
      name: '상담',
      childerenMenus: [
        {
          url: '/counsel/history',
          name: '상담 신청 내역',
        },
      ],
    },
    {
      icon: 'users',
      selectedPathname: 'meeting',
      name: '매칭',
      childerenMenus: [
        {
          url: '/meeting/manage-interview',
          name: '매칭 관리',
        },
        {
          url: '/meeting/qna',
          name: 'Q&A',
        },
      ],
    },
    {
      icon: 'presentation',
      selectedPathname: 'student-presentation-manage',
      name: '설명회',
      childerenMenus: [
        {
          url: '/student-presentation-manage/applications',
          name: '설명회 신청 내역',
        },
      ],
    },
  ],
  COMPANY: [
    {
      icon: 'user',
      selectedPathname: 'profile',
      name: '기업정보',
      childerenMenus: [
        {
          url: '/profile/manage',
          name: '기업정보 관리',
        },
        {
          url: '/profile/account',
          name: '계정 관리',
        },
      ],
    },
    {
      icon: 'chat',
      selectedPathname: 'hire',
      name: '채용 공고',
      childerenMenus: [
        {
          url: '/hire/manage-hire',
          name: '채용 공고 관리',
        },
      ],
    },
    {
      icon: 'bank',
      selectedPathname: 'booth-and-counsel',
      name: '박람회',
      childerenMenus: [
        {
          url: '/booth-and-counsel/display-booth',
          name: '박람회 신청 / 부스 전시',
        },
        {
          url: '/booth-and-counsel/manage-booth',
          name: '부스 관리',
        },
        {
          url: '/booth-and-counsel/manage-counsel',
          name: '상담 관리',
        },
      ],
    },
    {
      icon: 'users',
      selectedPathname: 'company-meeting',
      name: '매칭',
      childerenMenus: [
        {
          url: '/company-meeting/apply-matching',
          name: '매칭 신청',
        },
        {
          url: '/company-meeting/post-job-vacancy',
          name: '채용 공고 게시',
        },
        {
          url: '/company-meeting/manage-interview',
          name: '면접 제안 관리',
        },
        {
          url: '/company-meeting/manage-application',
          name: '면접 지원 관리',
        },
        {
          url: '/company-meeting/manage-saved',
          name: '저장 인재',
        },
        {
          url: '/company-meeting/qna',
          name: 'Q&A',
        },
      ],
    },
    {
      icon: 'presentation',
      selectedPathname: 'presentation-manage',
      name: '설명회',
      childerenMenus: [
        {
          url: '/presentation-manage/applications',
          name: '설명회 신청 내역',
        },
      ],
    },
    {
      icon: 'userbox',
      selectedPathname: 'advertisement-manage',
      name: '기업라운지',
      childerenMenus: [
        {
          url: '/advertisement-manage/manage',
          name: '홍보글 관리',
        },
        {
          url: '/advertisement-manage/state',
          name: '홍보글 상태 조회',
        },
      ],
    },
  ],
  COUNSELOR: [
    {
      icon: 'user',
      selectedPathname: 'profile-counsel',
      name: '기업정보',
      childerenMenus: [
        {
          url: '/profile-counsel/manage',
          name: '기업정보 관리',
        },
      ],
    },
    {
      icon: 'chat',
      selectedPathname: 'counsel',
      name: '상담',
      childerenMenus: [
        {
          url: '/counsel/manage-counsel',
          name: '상담 관리',
        },
        {
          url: '/counsel/manage-meeting',
          name: '미팅 관리',
        },
      ],
    },
  ],
};

export const CompanyProfileStep = {
  idle: 'IDLE',
  managerInfo: 'MANAGER_INFO',
  basicCompanyProfile: 'BASIC_COMPANY_PROFILE',
  logo: 'LOGO',
  images: 'IMAGES',
  introduction: 'INTRODUCTION',
  recruitmentTypes: 'RECRUITMENT_TYPES',
  welfare: 'WELFARE',
  urls: 'URLS',
  qnas: 'QNAS',
  complete: 'COMPLETE',
};

export const StudentProfileStep = {
  idle: 'IDLE',
  basicStudentProfile: 'BASIC_STUDENT_PROFILE',
  matchingInfo: 'MATCHING_INFO',
  educations: 'EDUCATIONS',
  labInfo: 'LAB_INFO',
  recruitmentTypes: 'RECRUITMENT_TYPES',
  skills: 'SKILLS',
  projects: 'PROJECTS',
  theses: 'THESES',
  patents: 'PATENTS',
  awards: 'AWARDS',
  careers: 'CAREERS',
  portfolios: 'PORTFOLIOS',
  introduction: 'INTRODUCTION',
  recruitmentInfo: 'RECRUITMENT_INFO',
  complete: 'COMPLETE',
};

export const ApplyMatchingStep = {
  1: '신청 서류 제출',
  2: '서비스 이용 동의',
  3: '유료 서비스 이용안내',
};

export const sizeArray = ['대기업', '중견기업', '중소기업', '스타트업'];

export const recruitmentTypeArray = [
  '일반채용',
  '산학장학생',
  '인턴',
  '전문연구요원 (현역)',
  '전문연구요원 (보충역)',
  '전문연구요원 (전직)',
  '산업기능요원 (현역)',
  '산업기능요원 (보충역)',
  '산업기능요원 (전직)',
];

export const domainArray = [
  'IT/웹/컨텐츠',
  '게임',
  '건설/건축/토론',
  '국방/방위',
  '공공기관',
  '금속/신소재/재료',
  '기계/설비',
  '레저/스포츠/여가',
  '로봇/인공지능',
  '물류/운송',
  '반도체/광학/디스플레이',
  '뷰티/생활/화장품',
  '생명/유전',
  '서비스',
  '석유/화학/에너지',
  '섬유/의류/패션',
  '식품가공/제조',
  '영상/음성/미디어',
  '은행/금융',
  '의료/임상',
  '연구기관',
  '제약/바이오',
  '전기/전자/제어',
  '정보보안/백신',
  '자동차',
  '제조',
  '조선/항공/우주',
  '판매/유통',
  '환경/에너지',
  '기타 전문과학분야',
];

export const BoothStep = {
  title: 'TITLE',
  online: 'IS_ONLINE',
  recruitmentInformation: 'JOB_VACANCIES',
  managerInformation: 'MANAGER_INFO',
  counselorInformation: 'COUNSELOR_ROLE',
  counselingTime: 'COUNSEL',
  present: 'PRESENT',
  complete: 'COMPLETE',
};

export const CounselorType = {
  field: 'FIELD',
  hr: 'HR',
};

export const FairApplicationsState = {
  pending: 'PENDING',
  approved: 'APPROVED',
  declined: 'DECLINED',
};

export const JobVacancyStep = {
  title: 'TITLE',
  managerEmail: 'MANAGER_EMAIL',
  taskDetail: 'TASK_DETAIL',
  requirements: 'REQUIREMENTS',
  skills: 'SKILLS',
  environment: 'ENVIRONMENT',
  recruitmentProcess: 'RECRUITMENT_PROCESS',
  publishedUniversities: 'PUBLISHED_UNIVERSITIES',
  complete: 'COMPLETE',
};

export const Location = [
  '서울특별시',
  '부산광역시',
  '대구광역시',
  '인천광역시',
  '광주광역시',
  '대전광역시',
  '울산광역시',
  '세종특별자치시',
  '경기도',
  '강원도',
  '충청북도',
  '충청남도',
  '전라북도',
  '전라남도',
  '경상북도',
  '경상남도',
  '제주특별자치도',
];

export const Degree = ['학위 무관', '학사 이상', '석사 이상', '박사 이상'];

export const ReservationState = {
  proceeding: 'PROCEEDING',
  pending: 'PENDING',
  approved: 'APPROVED',
  declined: 'DECLINED',
  completed: 'COMPLETED',
  canceled: 'CANCELED',
};

export const CounselState = {
  available: 'AVAILABLE',
  reserved: 'RESERVED',
};

export const NotificationType = {
  //  초기 로그인시 프로필 작성 요청 (기업, 학생이 보는 알림) -> 프로필 페이지로 이동
  profileUpdateRequest: 'PROFILE_UPDATE_REQUEST',
  // # 학생의 상담 예약알림 (기업이 보는 알림) -> 상담 관리 페이지로 이동
  counselReservationRequest: 'COUNSEL_RESERVATION_REQUEST',
  // # 상담 예약 결과 알림 (학생이 보는 알림) -> 상담신청내역 페이지로 이동
  counselReservationResult: 'COUNSEL_RESERVATION_RESULT',
  counselReservationCanceled: 'COUNSEL_RESERVATION_CANCELED',
  // # 전시 신청 결과 (기업이 보는 알림) -> 부스 전시 페이지로 이동
  fairApplicationResult: 'FAIR_APPLICATION_RESULT',
  // # 일반 공지-> 링크 이동 X
  notice: 'NOTICE',
  // # 매칭 서비스 신청 결과 (기업이 보는 알림) -> 매칭 신청 페이지로 이동
  matchingApplicationResult: 'MATCHING_APPLICATION_RESULT',
  // # 면접 지원 알림 (기업이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingApplied: 'MATCHING_APPLIED',
  // # 면접 제안 알림 (학생이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingSuggested: 'MATCHING_SUGGESTED',
  // # 면접 지원 취소 (기업이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingAppliedCanceled: 'MATCHING_APPLIED_CANCELED',
  // # 면접 제안 취소 (학생이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingSuggestedCanceled: 'MATCHING_SUGGESTED_CANCELED',
  // # 면접 지원 승인 반려 (학생이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingAppliedApproved: 'MATCHING_APPLIED_APPROVED',
  matchingAppliedDeclined: 'MATCHING_APPLIED_DECLINED',
  // # 면접 제안 수락 거절 (기업이 보는 알림) -> 매칭 관리 페이지로 이동
  matchingSuggestedApproved: 'MATCHING_SUGGESTED_APPROVED',
  matchingSuggestedDeclined: 'MATCHING_SUGGESTED_DECLINED',
};

export const EmailType = {
  signUp: 'SIGN_UP',
  resetPassword: 'RESET_PASSWORD',
};

export const FileType = {
  corporateIdentity: 'CORPORATE_IDENTITY',
  companyImage: 'COMPANY_IMAGE',
  businessRegistration: 'BUSINESS_REGISTRATION',
  portfolio: 'PORTFOLIO',
  fairImage: 'FAIR_IMAGE',
  presentationImage: 'PRESENTATION_IMAGE',
  advertisementImage: 'ADVERTISEMENT_IMAGE',
  presentatinoContentImage: 'PRESENTATION_CONTENT_IMAGE',
  advertisementContentImage: 'ADVERTISEMENT_CONTENT_IMAGE',
};

export const MatchingSuggestionState = {
  pending: 'SUGGEST_PENDING',
  approved: 'SUGGEST_ACCEPTED',
  declined: 'SUGGEST_DECLINED',
  canceled: 'SUGGEST_CANCELED',
};

export const MatchingApplicationState = {
  pending: 'APPLY_PENDING',
  approved: 'APPLY_ACCEPTED',
  declined: 'APPLY_DECLINED',
  canceled: 'APPLY_CANCELED',
};

export const MatchingType = {
  applied: 'APPLIED',
  suggested: 'SUGGESTED',
};

export const InterviewState = {
  planned: 'PLANNED',
  canceled: 'CANCELED',
  completed: 'COMPLETED',
  joined: 'JOINED',
};

export const ReactionType = {
  meet: 'WANT_MEET',
  apply: 'WANT_APPLY',
};

export const AdvertisementStep = {
  content: 'CONTENT',
  imageOrVideo: 'IMAGE_OR_VIDEO',
  companySize: 'COMPANY_SIZE',
  domain: 'DOMAIN',
  recruitmentTypes: 'RECRUITMENT_TYPES',
  complete: 'COMPLETE',
};
