import React from 'react';
import Modal from './Modal';
import styled from 'styled-components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 16px 16px 17.5px;
  display: flex;
  background-color: #ffffff;
  border: ${(props) => `1px solid ${props.color}`};
  p {
    margin-left: 17.5px;
  }
`;
const Alert = ({ isOpen, onClose, icon, text, color }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width="400px"
      height="56px"
      radius="10px"
    >
      <Layout color={color}>
        {icon}
        <p>{text}</p>
      </Layout>
    </Modal>
  );
};

export default Alert;
