import { BookOutlined, LeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { UserType } from '../../constants/static';
import { Button } from 'antd';
import {
  APPLY_ADVERTISEMENT,
  GET_ADVERTISEMENT,
  MEET_ADVERTISEMENT,
} from '../../queries/advertisement';
import 'react-quill/dist/quill.bubble.css';
import ReactQuill from 'react-quill';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import Youtube from 'react-youtube';
import { getYoutubeVideoId } from '../../constants/utils';
import { GET_STUDENT_PROFILE } from '../../queries/student';
import { formats } from '../../components/QuillToolbar';

const Layout = styled.div`
  height: 100%;
  overflow-y: scroll;
  .ql-editor {
    font-size: ${isMobile ? '14px' : '18px'};
  }
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
  button {
    outline: none;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    cursor: pointer;
    p {
      margin-left: 9px;
      font-weight: 500;
      color: #aaaaaa;
      font-size: 20px;
    }
  }
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding: ${isMobile ? '84px 4px 0' : '84px 102px 0 102px'};
  position: relative;
`;

const ContentLayout = styled.div`
  margin: ${isMobile ? '0' : '40px 0'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0 0 35px' : '0 18px 35px'};
  height: ${isMobile ? '' : 'calc(100vh - 220px)'};
  overflow-y: scroll;
  margin-bottom: 32px;
`;

const HeaderLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 18px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 40px;
  img {
    border-radius: 10px;
    width: 70px;
    margin-right: 24px;
  }
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: -0.0032em;
  text-align: left;
  word-break: break-all;
`;

const HeaderButton = styled(Button)`
  margin-left: 12px;
  width: 176px;
  height: 53px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const Spacer = styled.div`
  flex: 1;
`;

const TopLayout = styled.div`
  width: 100%;
  margin-top: ${isMobile ? '12px' : '32px'};
  margin-bottom: 24px;
  display: ${isMobile ? 'flex' : 'flex'};
  align-items: center;
  justify-content: center;
  flex-flow: ${isMobile ? 'column' : 'column'};
  img {
    width: ${isMobile ? '90vw' : '800px'};
    height: ${isMobile ? '60vw' : '408px'};
    border-radius: 10px;
    object-fit: cover;
    margin-right: ${isMobile ? '' : ''};
  }
`;

const TopRightLayout = styled.div`
  display: flex;
  align-items: top;
  margin-bottom: 15px;
  width: ${isMobile ? '' : '400px'};
  margin-top: ${isMobile ? '16px' : ''};
  p {
    width: 100px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    min-width: 100px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 14px;
  }
  span {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    word-break: break-all;
    text-align: left;
    width: ${isMobile ? 'calc(100vw - 166px)' : '280px'};
    max-width: ${isMobile ? 'calc(100vw - 166px)' : '280px'};
  }
`;

const BodyButton = styled(Button)`
  width: 176px;
  margin: 0 auto 8px;
  height: 53px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const AdvertisementDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const [getAdvertisement, { data: getAdvertisementData }] =
    useLazyQuery(GET_ADVERTISEMENT);
  useEffect(() => {
    const id = location.pathname.split('/')?.[2];
    if (id) {
      getAdvertisement({
        variables: {
          id,
        },
      });
    }
  }, [getAdvertisement, location]);
  const [meetAdvertisement] = useMutation(MEET_ADVERTISEMENT, {
    onCompleted: () => {
      const id = location.pathname.split('/')?.[2];
      if (id) {
        getAdvertisement({
          variables: {
            id,
          },
        });
      }
    },
  });
  const [applyAdvertisement] = useMutation(APPLY_ADVERTISEMENT, {
    onCompleted: () => {
      const id = location.pathname.split('/')?.[2];
      if (id) {
        getAdvertisement({
          variables: {
            id,
          },
        });
      }
    },
  });
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  const [getStudentProfile, { data: getStudentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  const [isMatchingDisabledCompanyName, setIsMatchingDisabledCompanyName] =
    useState(null);
  const [matchingDisabledState, setMatchingDisabledState] = useState(false);
  const [matchingCompanyId, setMatchingCompanyId] = useState(null);
  const [meetCompanyName, setMeetCompanyName] = useState(null);
  useEffect(() => {
    if (user.userType === UserType.student) {
      getStudentProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [user, getStudentProfile]);
  return (
    <Layout>
      <Header>
        <button onClick={() => history.push('/advertisement')}>
          <LeftOutlined style={{ fontSize: '24px', color: '#aaaaaa' }} />
          <p>목록</p>
        </button>
      </Header>
      <BodyLayout>
        <ContentLayout>
          {getAdvertisementData && (
            <>
              <HeaderLayout>
                <img
                  src={getAdvertisementData?.advertisement?.company?.logo?.url}
                  alt="logo"
                />
                <Title>{getAdvertisementData?.advertisement?.title}</Title>
                <Spacer />
                {user.userType === UserType.student && !isMobile && (
                  <>
                    <HeaderButton
                      type="primary"
                      disabled={getAdvertisementData?.advertisement?.met}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          Boolean(
                            getStudentProfileData?.student?.basicStudentProfile
                              ?.name,
                          )
                        ) {
                          meetAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId:
                                getAdvertisementData?.advertisment?.company
                                  ?.advertisement?.id,
                            },
                          });
                          setMeetCompanyName(
                            getAdvertisementData?.advertisment?.company
                              ?.basicCompanyProfile?.companyNameKR,
                          );
                        } else {
                          setIsWarningModalOpened(true);
                        }
                      }}
                    >
                      만나보고 싶어요
                    </HeaderButton>
                    <HeaderButton
                      disabled={getAdvertisementData?.advertisement?.applied}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          Boolean(
                            getStudentProfileData?.student?.basicStudentProfile
                              ?.name,
                          )
                        ) {
                          applyAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId:
                                getAdvertisementData?.advertisment?.company
                                  ?.advertisement?.id,
                            },
                          });
                          setMatchingCompanyId(
                            getAdvertisementData?.advertisment?.company?.id,
                          );
                          if (
                            !getAdvertisementData?.advertisment?.company
                              ?.onMatching
                          ) {
                            setMatchingDisabledState(true);
                          }
                          setIsMatchingDisabledCompanyName(
                            getAdvertisementData?.advertisment?.company
                              ?.basicCompanyProfile?.companyNameKR,
                          );
                        } else {
                          setIsWarningModalOpened(true);
                        }
                      }}
                      type="primary"
                    >
                      지원하고 싶어요
                    </HeaderButton>
                  </>
                )}
              </HeaderLayout>
              <TopLayout>
                {getAdvertisementData?.advertisement?.image ? (
                  <img
                    src={getAdvertisementData?.advertisement.image?.url}
                    alt="thumbnail"
                  />
                ) : (
                  <div
                    style={{
                      overflow: 'hidden',
                      marginRight: isMobile ? '' : '',
                      borderRadius: '10px',
                      height: isMobile ? '216px' : '408px',
                      width: isMobile ? 'calc(100% - 16px)' : '800px',
                    }}
                  >
                    <Youtube
                      videoId={getYoutubeVideoId(
                        getAdvertisementData?.advertisement?.youtubeLink,
                      )}
                      opts={{
                        width: isMobile ? '100%' : '800',
                        height: isMobile ? '216' : '408',
                      }}
                    />
                  </div>
                )}
                <div>
                  {isMobile ? (
                    <>
                      <TopRightLayout>
                        <p>기업명</p>
                        <span>
                          {
                            getAdvertisementData?.advertisement?.company
                              ?.basicCompanyProfile?.companyNameKR
                          }
                        </span>
                      </TopRightLayout>
                      <TopRightLayout>
                        <p>기업 규모</p>
                        <span>
                          {getAdvertisementData?.advertisement?.companySize}
                        </span>
                      </TopRightLayout>
                      <TopRightLayout>
                        <p>분야</p>
                        <span>
                          {getAdvertisementData?.advertisement?.domains?.reduce(
                            (a, c, i) => a + (i > 0 ? ', ' : '') + c.name,
                            '',
                          )}
                        </span>
                      </TopRightLayout>
                      <TopRightLayout>
                        <p>채용형태</p>
                        <span>
                          {getAdvertisementData?.advertisement?.recruitmentTypes?.reduce(
                            (a, c, i) => a + (i > 0 ? ', ' : '') + c,
                            '',
                          )}
                        </span>
                      </TopRightLayout>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          width: '800px',
                          marginTop: '24px',
                        }}
                      >
                        <TopRightLayout>
                          <p>기업명</p>
                          <span>
                            {
                              getAdvertisementData?.advertisement?.company
                                ?.basicCompanyProfile?.companyNameKR
                            }
                          </span>
                        </TopRightLayout>
                        <TopRightLayout>
                          <p>기업 규모</p>
                          <span>
                            {getAdvertisementData?.advertisement?.companySize}
                          </span>
                        </TopRightLayout>
                      </div>
                      <div style={{ display: 'flex', width: '800px' }}>
                        <TopRightLayout>
                          <p>분야</p>
                          <span>
                            {getAdvertisementData?.advertisement?.domains?.reduce(
                              (a, c, i) => a + (i > 0 ? ', ' : '') + c.name,
                              '',
                            )}
                          </span>
                        </TopRightLayout>
                        <TopRightLayout>
                          <p>채용형태</p>
                          <span>
                            {getAdvertisementData?.advertisement?.recruitmentTypes?.reduce(
                              (a, c, i) => a + (i > 0 ? ', ' : '') + c,
                              '',
                            )}
                          </span>
                        </TopRightLayout>
                      </div>
                    </>
                  )}

                  {isMobile && (
                    <>
                      <div style={{ height: '8px' }} />
                      <BodyButton
                        type="primary"
                        disabled={getAdvertisementData?.advertisement?.met}
                        onClick={(e) => {
                          e.stopPropagation();
                          meetAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId:
                                getAdvertisementData?.advertismeent?.company
                                  ?.advertisement?.id,
                            },
                          });
                          setMeetCompanyName(
                            getAdvertisementData?.advertismeent?.company
                              ?.basicCompanyProfile?.companyNameKR,
                          );
                        }}
                      >
                        만나보고 싶어요
                      </BodyButton>
                      <BodyButton
                        disabled={getAdvertisementData?.advertisement?.applied}
                        onClick={(e) => {
                          e.stopPropagation();
                          applyAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId:
                                getAdvertisementData?.advertisment?.company
                                  ?.advertisement?.id,
                            },
                          });
                          setMatchingCompanyId(
                            getAdvertisementData?.advertisment?.company?.id,
                          );
                          if (
                            !getAdvertisementData?.advertisment?.company
                              ?.onMatching
                          ) {
                            setMatchingDisabledState(true);
                          }
                          setIsMatchingDisabledCompanyName(
                            getAdvertisementData?.advertisment?.company
                              ?.basicCompanyProfile?.companyNameKR,
                          );
                        }}
                        type="primary"
                      >
                        지원하고 싶어요
                      </BodyButton>
                    </>
                  )}
                </div>
              </TopLayout>
              <div
                style={{
                  height: '1px',
                  backgroundColor: '#efefef',
                  marginBottom: '24px',
                }}
              />
              <div
                style={{
                  width: isMobile ? '' : '880px',
                  margin: isMobile ? '' : '0 auto',
                }}
              >
                <ReactQuill
                  value={getAdvertisementData?.advertisement?.content ?? ''}
                  readOnly
                  theme="bubble"
                  formats={formats}
                />
              </div>
            </>
          )}
        </ContentLayout>
      </BodyLayout>
      <Modal
        isOpen={isMatchingDisabledCompanyName !== null}
        onClose={() => {
          setIsMatchingDisabledCompanyName(null);
          setMatchingDisabledState(false);
          setMatchingCompanyId(null);
        }}
      >
        {matchingDisabledState ? (
          <SmallModalLayout
            setDescriptionCenter
            title="지원하고싶어요"
            description={`${isMatchingDisabledCompanyName} 기업에 관심 가져주셔서 감사합니다.`}
            removeLeftButton
            onClickRightButton={() => {
              setMatchingDisabledState(false);
              setIsMatchingDisabledCompanyName(null);
              setMatchingCompanyId(null);
            }}
          />
        ) : (
          <SmallModalLayout
            setDescriptionCenter
            title="지원하고싶어요"
            description={`이미 매칭 서비스를 이용하고 있는 기업입니다.\n채용 지원을 하시겠습니까?`}
            onClickLeftButton={() => setMatchingDisabledState(true)}
            onClickRightButton={() =>
              history.push(`/matching/${matchingCompanyId}`)
            }
          />
        )}
      </Modal>
      <Modal
        isOpen={meetCompanyName !== null}
        onClose={() => setMeetCompanyName(null)}
      >
        <SmallModalLayout
          setDescriptionCenter
          title="만나보고싶어요"
          description={`${meetCompanyName} 기업에 관심 가져주셔서 감사합니다.\n가까운 시일내에 만나볼 수 있는 자리를 마련하도록 하겠습니다.`}
          removeLeftButton
          onClickRightButton={() => setMeetCompanyName(null)}
        />
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => setIsWarningModalOpened(false)}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AdvertisementDetail;
