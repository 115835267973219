import {
  CheckOutlined,
  DownOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import SmallModalWithTextareaLayout from '../../components/SmallModalWithTextareaLayout';
import { MatchingApplicationState, MatchingType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import {
  ACCEPT_MATCHING,
  DECLINE_MATCHING,
  GET_MATCHINGS_BY_COMPANY,
} from '../../queries/matching';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';

const convertStateToComponentStyle = (state) => {
  switch (state) {
    case MatchingApplicationState.declined:
      return { text: '반려', color: '#ff4d4f', background: '#ffccc7' };
    case MatchingApplicationState.canceled:
      return { text: '취소', color: '#373334', background: '#f2f2f2' };
    case MatchingApplicationState.approved:
      return { text: '승인', color: '#57a0f5', background: '#e6f7ff' };
    case MatchingApplicationState.pending:
      return { text: '대기', color: '#52c41a', background: '#f6ffed' };
    default:
      return { text: '대기', color: '#52c41a', background: '#f6ffed' };
  }
};
const Layout = styled.div`
  margin-top: 27px;
`;

const ApplicationItem = styled.div`
  width: 100%;
  cursor: pointer;

  border: 1.5px solid #fe706f;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
`;

const ApplicationItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const ApplicationItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
`;

const CountText = styled.p`
  margin-left: 16px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: baseline;
  margin: 20px 48px 0;
`;

const ToggleButton = styled.button`
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background-color: ${(props) => props.background};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  height: 22px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 20px 0;
`;

const StudentItem = styled.div`
  margin: 0 62px 10px;
  padding: 13px 15px;
  border-radius: 10px;
  background-color: #fbfbfb;
  height: 59px;
  display: flex;
  align-items: center;
`;

const DateBadge = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 5px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  border: 1px solid #d9d9d9;
  text-align: left;
  color: #262626;
  margin-right: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 51px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhoneNumberText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 74px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CounselStateText = styled.p`
  margin-left: 20px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  width: 96px;
  text-align: left;
  color: ${(props) => props.color};
`;

const CounselRightButton = styled.button`
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;
const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  top: 32px;
  z-index: 0;
`;

const ApplicationModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;
const ApplicationModalBody = styled.div`
  height: 495px;
  width: 100%;
  position: absolute;
  top: 92px;
  z-index: 0;
  overflow: scroll;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const ApplicationRowItem = styled.div`
  display: flex;
  width: 100%;
  margin: 22px 49px 22px 77px;
  span {
    width: 199px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  p {
    white-space: pre-wrap;
    font-family: Noto Sans KR;
    width: 567px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const filters = [
  MatchingApplicationState.pending,
  MatchingApplicationState.approved,
  MatchingApplicationState.declined,
  MatchingApplicationState.canceled,
];

export const maskingName = (name) => {
  if (!name) {
    return '';
  }
  if (name.length === 1) {
    return '*';
  }
  if (name.length === 2) {
    return `${name.charAt(0)}*`;
  }
  if (name.length > 2) {
    return `${name.charAt(0)}${'*'.repeat(name.length - 2)}${name.charAt(
      name.length - 1,
    )}`;
  }
};

const ApplicationTab = () => {
  const { user } = useAuth();

  const [openedApplicationId, setOpenedApplicationId] = useState(0);
  const [toggleFilters, setToggleFilters] = useState(
    new Set([
      MatchingApplicationState.pending,
      MatchingApplicationState.approved,
      MatchingApplicationState.declined,
      MatchingApplicationState.canceled,
    ]),
  );
  const handleClickToggleButton = (item) => {
    if (!toggleFilters.has(item)) {
      setToggleFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [canceledReason, setCanceledReason] = useState('');
  const [declinedReason, setDeclinedReason] = useState('');
  const [decliningId, setDecliningId] = useState(0);
  const [approvingId, setApprovingId] = useState(0);
  const [acceptMatching] = useMutation(ACCEPT_MATCHING, {
    onCompleted: (data) => {
      setApprovingId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.applied,
          companyId: user.id,
        },
      });
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'NO_PERMISSION') {
        alert('권한이 없습니다.');
      }
      setApprovingId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.applied,
          companyId: user.id,
        },
      });
    },
  });
  const [declineMatching] = useMutation(DECLINE_MATCHING, {
    onCompleted: (data) => {
      setDecliningId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.applied,
          companyId: user.id,
        },
      });
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'NO_PERMISSION') {
        alert('권한이 없습니다.');
      }
      setDecliningId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.applied,
          companyId: user.id,
        },
      });
    },
  });
  const [getMatchingsByCompany, { data: getMatchingsByCompanyData }] =
    useLazyQuery(GET_MATCHINGS_BY_COMPANY);
  useEffect(() => {
    if (user.id > 0) {
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.applied,
          companyId: user.id,
        },
      });
    }
  }, [user, getMatchingsByCompany]);
  return (
    <Layout>
      {getMatchingsByCompanyData?.matchingsByCompany?.map((item) => (
        <ApplicationItem
          opened={openedApplicationId === item.jobVacancy?.id}
          onClick={() =>
            setOpenedApplicationId(
              openedApplicationId === item.jobVacancy?.id
                ? 0
                : item.jobVacancy?.id,
            )
          }
        >
          <ApplicationItemTitleLayout
            opened={openedApplicationId === item.jobVacancy?.id}
          >
            {openedApplicationId === item.jobVacancy?.id ? (
              <DownOutlined
                style={{
                  fontSize: '24px',
                  color: 'black',
                }}
              />
            ) : (
              <RightOutlined
                style={{
                  fontSize: '24px',
                  color: '#fe706f',
                }}
              />
            )}
            <ApplicationItemTitle>
              {item.jobVacancy?.position}
            </ApplicationItemTitle>
            <Spacer />
            <CountText>
              대기:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingApplicationState.pending,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              승인:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingApplicationState.approved,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              반려:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingApplicationState.declined,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              취소:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingApplicationState.canceled,
              )?.length ?? 0}
              회
            </CountText>
          </ApplicationItemTitleLayout>
          {openedApplicationId === item.jobVacancy?.id && (
            <>
              <FilterLayout>
                <Spacer />
                {filters.map((item) => (
                  <ToggleButton
                    color={convertStateToComponentStyle(item).color}
                    background={convertStateToComponentStyle(item).background}
                    onClick={(e) => {
                      handleClickToggleButton(item);
                      e.stopPropagation();
                    }}
                  >
                    {toggleFilters.has(item) && <CheckOutlined />}
                    {convertStateToComponentStyle(item).text}
                  </ToggleButton>
                ))}
              </FilterLayout>
              <Divider />
              {item.matchings
                ?.filter((matching) => {
                  const state = matching.matchingState;
                  return toggleFilters.has(state);
                })
                ?.map((application) => (
                  <StudentItem>
                    <DateBadge>{application.createdAt.split(' ')[0]}</DateBadge>
                    <NameText>
                      {application?.matchingState !==
                      MatchingApplicationState.approved
                        ? maskingName(
                            application.student?.basicStudentProfile?.name,
                          )
                        : application.student?.basicStudentProfile?.name}
                    </NameText>
                    <PhoneNumberText>
                      {application.matchingState !==
                      MatchingApplicationState.approved
                        ? '010-****-****'
                        : application.student?.basicStudentProfile?.phoneNumber}
                    </PhoneNumberText>
                    <CounselStateText
                      color={
                        convertStateToComponentStyle(application.matchingState)
                          .color
                      }
                    >
                      {
                        convertStateToComponentStyle(application.matchingState)
                          .text
                      }
                    </CounselStateText>
                    <Spacer />
                    <CounselRightButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedStudentId(application.student?.id);
                      }}
                    >
                      프로필 보기
                    </CounselRightButton>
                    {application.matchingState ===
                      MatchingApplicationState.pending && (
                      <>
                        <CounselRightButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setApprovingId(application.id);
                          }}
                        >
                          승인
                        </CounselRightButton>
                        <CounselRightButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setDecliningId(application.id);
                          }}
                        >
                          반려
                        </CounselRightButton>
                      </>
                    )}
                    {application.matchingState ===
                      MatchingApplicationState.declined && (
                      <CounselRightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeclinedReason(application.reasonDeclined);
                        }}
                      >
                        반려 사유
                      </CounselRightButton>
                    )}
                    {application.matchingState ===
                      MatchingApplicationState.canceled && (
                      <CounselRightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setCanceledReason(application.reasonCanceled);
                        }}
                      >
                        취소 사유
                      </CounselRightButton>
                    )}
                  </StudentItem>
                ))}
              <div style={{ height: '26px' }} />
            </>
          )}
        </ApplicationItem>
      ))}
      <Modal
        isOpen={decliningId > 0}
        onClose={() => setDecliningId(0)}
        radius="30px"
      >
        <SmallModalWithTextareaLayout
          title="면접 지원 반려"
          description={'면접 지원을 반려하시겠습니까?'}
          onClickLeftButton={() => setDecliningId(0)}
          placeholder={'반려 사유를 입력해주세요.'}
          onClickRightButton={(text) => () => {
            declineMatching({
              variables: {
                matchingId: decliningId,
                declineReason: text,
              },
            });
          }}
        />
      </Modal>
      <Modal
        isOpen={approvingId > 0}
        onClose={() => setApprovingId(0)}
        radius="30px"
      >
        <SmallModalLayout
          title="면접 지원 승인"
          description={'면접 지원을 승인하시겠습니까?'}
          setDescriptionCenter={true}
          onClickLeftButton={() => setApprovingId(0)}
          onClickRightButton={() =>
            acceptMatching({
              variables: {
                matchingId: approvingId,
              },
            })
          }
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={canceledReason !== ''}
        onClose={() => setCanceledReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title="취소 사유"
          description={canceledReason}
          onClickRightButton={() => setCanceledReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={declinedReason !== ''}
        onClose={() => setDeclinedReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title="반려 사유"
          description={declinedReason}
          onClickRightButton={() => setDeclinedReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={selectedStudentId > 0}
        onClose={() => setSelectedStudentId(0)}
        closeButtonTransparent
      >
        <ModalLayout>
          <ModalHeader>
            <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>프로필</ModalHeaderTitle>
          </ModalHeader>
          <ModalBody>
            <StudentProfileModal id={selectedStudentId} hideInformation />
          </ModalBody>
        </ModalLayout>
      </Modal>
    </Layout>
  );
};

export default ApplicationTab;
