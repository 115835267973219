import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { SwapRightOutlined } from '@ant-design/icons';

const JobVacancyItem = styled.div`
  width: 100%;
  height: ${(props) => (props.showHeader ? '100%' : '')};
  border-radius: 10px;
  overflow: ${(props) => (!props.showHeader ? '' : 'scroll')};
  background-color: white;
`;

const JobVacancyItemTitleLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '0 22px' : '0 22px 0 33px'};
  align-items: center;
  height: 92px;
  background-color: #fff1f0;
`;
const JobVacancyItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: ${isMobile ? '' : '0 0 0 20px'};
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: ${isMobile ? '240px' : ''};
`;

const EditingText = styled.p`
  margin-right: 96px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fe706f;
`;

const RightButtonLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  button {
    margin: 0 12px;
  }
`;

const JobVacancyBodyLayout = styled.div`
  padding: ${(props) =>
    !props.showHeader ? '40px 0' : isMobile ? '22px 0 0 0' : '22px 0 34px'};
  height: ${(props) => (!props.showHeader ? '430px' : '')};
  overflow-y: ${(props) => (props.showHeader ? 'scroll' : '')};
`;

const JobVacancyContentLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  padding: ${isMobile ? '0 30px 0 23px' : '0 66px 0 77px'};
`;

const JobVacancyContentTitle = styled.p`
  min-width: 84x !important;
  width: ${isMobile ? '' : '84px !important'};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: #f0f0f0;
`;

const VerticalSpacer = styled.div`
  height: 9px;
`;
const JobVacancyBody = styled.div`
  margin-left: ${isMobile ? '' : '88px'};
  margin-top: ${isMobile ? '12px' : '4px'};
  white-space: pre-wrap;
  p {
    span {
      font-weight: 500;
    }
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 567px;
  }
`;

const SkillBody = styled.div`
  margin-left: ${isMobile ? '' : '88px'};
  margin-top: ${isMobile ? '12px' : '4px'};
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 20px;
    margin-bottom: 8px;
    min-height: 32px;
    border-radius: 5px;
    background-color: #fbfbfb;
    padding: 5px 16px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2e2e2e;
  }
`;

const JobVacancyModal = ({ showHeader = true, jobVacancy }) => {
  console.log(jobVacancy);
  return (
    <JobVacancyItem showHeader={showHeader}>
      {showHeader && (
        <JobVacancyItemTitleLayout>
          <JobVacancyItemTitle>{jobVacancy.position}</JobVacancyItemTitle>
        </JobVacancyItemTitleLayout>
      )}

      <JobVacancyBodyLayout showHeader={showHeader}>
        <JobVacancyContentLayout>
          <JobVacancyContentTitle>세부 업무 내용</JobVacancyContentTitle>
          <JobVacancyBody>
            <p>
              <span>근무지역 :</span> {jobVacancy.workplace} <br />
              <br />
              {jobVacancy.task}
            </p>
          </JobVacancyBody>
        </JobVacancyContentLayout>
        <Divider />
        <JobVacancyContentLayout>
          <JobVacancyContentTitle>지원 자격 및 우대사항</JobVacancyContentTitle>
          <JobVacancyBody>
            <p>
              <span>필요학위 :</span> {jobVacancy.requiredDegree} <br />
              <br />
              {jobVacancy.requiredKnowledge}
            </p>
          </JobVacancyBody>
        </JobVacancyContentLayout>
        <Divider />
        <JobVacancyContentLayout>
          <JobVacancyContentTitle>연구 분야 스킬</JobVacancyContentTitle>
          <SkillBody>
            {jobVacancy.skills?.map((item, index) => (
              <div key={index}>
                {item.field}/{item.skillDetail}
              </div>
            ))}
          </SkillBody>
        </JobVacancyContentLayout>
        <Divider />
        <JobVacancyContentLayout>
          <JobVacancyContentTitle>근무요건</JobVacancyContentTitle>

          <JobVacancyBody>
            <p>{jobVacancy.environment}</p>
          </JobVacancyBody>
        </JobVacancyContentLayout>
        <Divider />
        {/* <JobVacancyContentLayout>
          <JobVacancyContentTitle>지원방법</JobVacancyContentTitle>

          <JobVacancyBody>
            <p>{jobVacancy.applicationProcess}</p>
          </JobVacancyBody>
        </JobVacancyContentLayout>
        <Divider /> */}
        <JobVacancyContentLayout>
          <JobVacancyContentTitle>채용절차</JobVacancyContentTitle>

          <JobVacancyBody>
            <p>
              <span>
                {jobVacancy.recruitmentSchedule === '상시채용' && '상시채용'}
                {jobVacancy.recruitmentSchedule !== '상시채용' && (
                  <>
                    {jobVacancy.recruitmentSchedule?.split(' ~ ')[0]}
                    <SwapRightOutlined
                      style={{
                        fontSize: '16px',
                        color: '#bfbfbf',
                        margin: '0 8px',
                      }}
                    />
                    {jobVacancy.recruitmentSchedule?.split(' ~ ')[1]}
                  </>
                )}
              </span>
              <br />
              <br />
              {jobVacancy.recruitmentProcess}
            </p>
          </JobVacancyBody>
        </JobVacancyContentLayout>
        <div style={{ height: '24px' }} />
      </JobVacancyBodyLayout>
    </JobVacancyItem>
  );
};

export default JobVacancyModal;
