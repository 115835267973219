import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';
import TextArea from 'antd/lib/input/TextArea';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledTextarea = styled(TextArea)`
  width: 783px;
  min-height: 180px !important;
  border-radius: 10px;
  margin: 20px auto;
`;

const Present = ({ booth, isCreating, getBooths }) => {
  const [present, setPresent] = useState('');
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);

  useEffect(() => {
    setPresent(booth?.present);
  }, [booth]);

  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            present: present,
            step: BoothStep.complete,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            present: present,
            step: BoothStep.complete,
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    updateBooth({
      variables: {
        id: booth.id,
        boothInput: {
          present: present,
          step: BoothStep.counselingTime,
        },
      },
    });
  };
  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>상담학생분들께 상담완료시 주는 사은품이 있다면 작성해주세요.</p>
      </TitleLaout>
      <StyledTextarea
        value={present}
        onChange={(e) => setPresent(e.currentTarget.value)}
      />
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={onClickRightButton} type="primary">
              완료
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton onClick={onClickRightButton} type="primary">
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Present;
