import React, { createContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PresentationDetailPage from './PresentationDetail';
import PresentationDefault from './Presentation';
import { GET_PRESENTATIONS } from '../../queries/presentation';
import { useLazyQuery } from '@apollo/client';

export const PresentationContext = createContext({});

const Presentation = () => {
  const [toggleFilters, setToggleFilters] = useState(
    new Set(['expected', 'end']),
  );
  const handleClickToggleButton = (item) => {
    if (!toggleFilters.has(item)) {
      setToggleFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };
  const [listPresentations, setListPresentations] = useState([]);
  const [getFinished, setGetFinished] = useState(false);
  const [scrollItem, setScrollItem] = useState('');
  const [getPresentations, { loading: getPresentationsLoading }] = useLazyQuery(
    GET_PRESENTATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setListPresentations((prev) => {
          if (
            prev.findIndex(
              (item) => item?.id === data?.presentations?.[0]?.id,
            ) === -1
          ) {
            return [...prev, ...data?.presentations];
          }
          return prev;
        });
        if (data?.presentations?.length < 30) {
          setGetFinished(true);
        }
      },
    },
  );
  useEffect(() => {
    setListPresentations([]);
    if (toggleFilters.has('expected') || toggleFilters.has('end')) {
      getPresentations({
        variables: {
          first: 30,
          after: null,
          isScheduled: toggleFilters.has('expected'),
          isEnded: toggleFilters.has('end'),
        },
      });
    }
  }, [getPresentations, toggleFilters]);
  return (
    <PresentationContext.Provider
      value={{
        toggleFilters,
        handleClickToggleButton,
        listPresentations,
        getFinished,
        getPresentationsLoading,
        getPresentations,
        scrollItem,
        setScrollItem,
      }}
    >
      <Switch>
        <Route exact path="/presentation">
          <PresentationDefault />
        </Route>
        <Route exact path="/presentation/:companyId">
          <PresentationDetailPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </PresentationContext.Provider>
  );
};

export default Presentation;
