import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { useEffect } from 'react';
import { Input, Button, Radio } from 'antd';
import { StudentProfileStep } from '../../../../constants/static';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TextboxLayout = styled.div`
  padding: ${isMobile ? '0 28px' : '0 94px'};
  margin-bottom: 36px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #3a3a3a;
    margin-bottom: 11px;
  }
  input {
    border-radius: 10px;
  }
`;

const CheckboxLayout = styled.div`
  padding: ${isMobile ? '0 28px' : '0 94px'};
  margin-bottom: 25px;
  margin-top: ${isMobile ? '40px' : ''};

  p {
    font-weight: 500;
    font-size: 14px;
    color: #3a3a3a;
    margin-bottom: 11px;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: ${isMobile ? '120px 60px 0' : '0 60px'};
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const MatchingInfo = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [briefIntroduction, setBriefIntroduction] = useState('');
  const [onMatching, setOnMatching] = useState(false);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);

  useEffect(() => {
    if (profile?.onMatching) {
      setOnMatching(profile?.onMatching);
    }
    if (profile?.briefIntroduction) {
      setBriefIntroduction(profile?.briefIntroduction);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.basicStudentProfile,
          briefIntroduction: briefIntroduction,
          onMatching: onMatching,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.educations,
          briefIntroduction: briefIntroduction,
          onMatching: onMatching,
        },
      },
    });
  };

  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <CheckboxLayout>
        <p>
          매칭 서비스를 이용하여 기업으로부터 면접을 제안 받고, 면접을
          지원하시겠습니까?
        </p>
        <Radio onClick={() => setOnMatching(true)} checked={onMatching}>
          예
        </Radio>
        <Radio
          style={{ marginLeft: '40px' }}
          onClick={() => setOnMatching(false)}
          checked={!onMatching}
        >
          아니오
        </Radio>
      </CheckboxLayout>
      <TextboxLayout>
        <p>한줄 소개</p>
        <Input
          value={briefIntroduction}
          placeholder="기업 담당자에게 보여질 소개 정보를 입력해주세요."
          maxLength={500}
          onChange={(e) => setBriefIntroduction(e.currentTarget.value)}
        />
      </TextboxLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            disabled={onMatching && briefIntroduction === ''}
            type="primary"
            onClick={handleClickRightButton}
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={onMatching && briefIntroduction === ''}
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default MatchingInfo;
