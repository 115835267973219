import { gql } from '@apollo/client';
import { MANAGER_INFO, BASIC_COMPANY_PROFILE, URLS, QNAS } from './company';

// Queries

export const GET_EXHIBITED_BOOTHS_BY_COMPANY = gql`
  query GetExhibitedBoothsByCompany($companyId: ID!) {
    exhibitedBoothsByCompany(companyId: $companyId) {
      id
      booth {
        id
        title
        zoomLink
        counselMethod
      }
      fair {
        id
        isOnline
        title
      }
      dateAndCounselReservations {
        date
        counselReservations {
          id
          startAt
          endAt
          state
          memo
          student {
            id
            email
            basicStudentProfile {
              name
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export const GET_EXHIBITED_BOOTHS_BY_COUNSELOR = gql`
  query GetExhibitedBoothsByCounselor($counselorId: ID!) {
    exhibitedBoothsByCounselor(counselorId: $counselorId) {
      id
      booth {
        id
        title
        zoomLink
        counselMethod
      }
      fair {
        id
        isOnline
        title
      }
      dateAndCounselReservations {
        date
        counselReservations {
          id
          startAt
          endAt
          state
          memo
          student {
            id
            email
            basicStudentProfile {
              name
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export const GET_EXHIBITED_BOOTHS_BY_STUDENT = gql`
  query GetExhibitedBoothsByStudent($studentId: ID!) {
    exhibitedBoothsByStudent(studentId: $studentId) {
      id
      booth {
        id
        title
        zoomLink
        counselMethod
      }
      fair {
        id
        isOnline
        title
      }
      dateAndCounselReservations {
        date
        counselReservations {
          id
          startAt
          endAt
          state
          studentMemo
          student {
            id
          }
        }
      }
    }
  }
`;

export const GET_EXHIBITED_BOOTHS_BY_FAIR = gql`
  query GetExhibitedBoothsByFair(
    $fairId: ID!
    $counselEnabled: Boolean
    $studentSkillFiltered: Boolean
    $recruitmentTypes: [String]
    $counselorRoles: [CounselorRole]
    $dates: [String]
  ) {
    exhibitedBoothsByFair(
      fairId: $fairId
      counselEnabled: $counselEnabled
      studentSkillFiltered: $studentSkillFiltered
      recruitmentTypes: $recruitmentTypes
      counselorRoles: $counselorRoles
      dates: $dates
    ) {
      id
      booth {
        company {
          logo {
            id
            name
            url
          }
          basicCompanyProfile {
            companyNameKR
          }
        }
        title
        jobVacancies {
          id
          step
          position
          workplace
          task
          requiredDegree
          requiredKnowledge
          skills {
            id
            field
            skillDetail
          }
          environment
          recruitmentSchedule
          recruitmentProcess
        }
        counselorRoles
      }
      totalCounselCount
      approvedCounselCount
      dateAndCounselReservations {
        date
      }
    }
  }
`;

export const SEARCH_EXHIBITED_BOOTHS_BY_FAIR = gql`
  query SearchExhibitedBoothsByFair($fairId: ID!, $keyword: String!) {
    searchExhibitedBoothsByFair(fairId: $fairId, keyword: $keyword) {
      id
      booth {
        company {
          logo {
            id
            name
            url
          }
          basicCompanyProfile {
            companyNameKR
          }
        }
        title
        jobVacancies {
          id
          step
          position
          workplace
          task
          requiredDegree
          requiredKnowledge
          skills {
            id
            field
            skillDetail
          }
          environment
          recruitmentSchedule
          recruitmentProcess
        }
        counselorRoles
      }
      totalCounselCount
      approvedCounselCount
      dateAndCounselReservations {
        date
      }
    }
  }
`;

export const GET_BOOTHS_BY_COMPANY = gql`
  query GetBoothsByCompany($id: ID!) {
    boothsByCompany(companyId: $id) {
      id
      exhibited
      step
      title
      isOnline
      zoomLink
      counselMethod
      present
      managerInfo {
        name
        email
        phoneNumber
      }
      counselorRoles
      counselStartAt
      counselEndAt
      counselInterval
      enabledCounsels {
        startAt
        endAt
      }
      jobVacancies {
        id
        step
        position
        workplace
        task
        requiredDegree
        requiredKnowledge
        skills {
          id
          field
          skillDetail
        }
        environment
        recruitmentSchedule
        recruitmentProcess
      }
    }
  }
`;

export const GET_EXHIBITED_BOOTH = gql`
  ${MANAGER_INFO}
  ${BASIC_COMPANY_PROFILE}
  ${URLS}
  ${QNAS}
  query getExhibitedBooth($id: ID!) {
    exhibitedBooth(id: $id) {
      id
      dateAndCounselReservations {
        date
        counselReservations {
          id
          startAt
          endAt
          state
          student {
            id
          }
        }
        counsels {
          id
          startAt
          endAt
          state
        }
      }
      fair {
        universities {
          domain
        }
        studentApplicationStartAt
        studentApplicationEndAt
      }
      booth {
        id
        title
        present
        isOnline
        counselMethod
        jobVacancies {
          id
          step
          position
          workplace
          task
          requiredDegree
          requiredKnowledge
          skills {
            id
            field
            skillDetail
          }
          environment
          recruitmentSchedule
          recruitmentProcess
        }
        managerInfo {
          name
          email
          phoneNumber
        }
        company {
          step
          ...ManagerInfo
          ...BasicCompanyProfile
          logo {
            id
            name
            url
          }
          images {
            id
            name
            url
          }
          introduction
          recruitmentTypes
          welfare
          ...Urls
          ...Qnas
        }
      }
    }
  }
`;

export const GET_BOOTH = gql`
  ${MANAGER_INFO}
  ${BASIC_COMPANY_PROFILE}
  ${URLS}
  ${QNAS}
  query getBooth($id: ID) {
    booth(id: $id) {
      id
      managerInfo {
        name
        email
        phoneNumber
      }
      counsels {
        id
        startAt
        endAt
        state
        booth
      }
      jobVacancies {
        id
        step
        position
        workplace
        task
        requiredDegree
        requiredKnowledge
        skills {
          id
          field
          skillDetail
        }
        environment
        recruitmentSchedule
        recruitmentProcess
      }
      company {
        step
        ...ManagerInfo
        ...BasicCompanyProfile
        logo {
          id
          name
          url
        }
        images {
          id
          name
          url
        }
        introduction
        recruitmentTypes
        welfare
        ...Urls
        ...Qnas
      }
    }
  }
`;
// Mutations

export const CREATE_BOOTH = gql`
  mutation CreateBooth {
    createBooth {
      id
      step
      title
      isOnline
      zoomLink
      managerInfo {
        name
        email
        phoneNumber
      }
      counselorRoles
      counselStartAt
      counselEndAt
      counselInterval
    }
  }
`;

export const UPDATE_BOOTH = gql`
  mutation UpdateBooth($id: ID!, $boothInput: BoothInput) {
    updateBooth(id: $id, boothInput: $boothInput) {
      id
    }
  }
`;

export const DELETE_BOOTH = gql`
  mutation DeleteBooth($id: ID!) {
    deleteBooth(id: $id)
  }
`;

export const DUPLICATE_BOOTH = gql`
  mutation DuplicateBooth($id: ID!) {
    duplicateBooth(id: $id) {
      id
      step
      title
      isOnline
      zoomLink
      managerInfo {
        name
        email
        phoneNumber
      }
      counselorRoles
      counselStartAt
      counselEndAt
      counselInterval
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId)
  }
`;

export const SET_BOOTHS_TO_ORDER = gql`
  mutation SetBoothsToOrder($orderId: ID!, $boothIds: [ID]) {
    setBoothsToOrder(orderId: $orderId, boothIds: $boothIds)
  }
`;
