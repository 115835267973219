import React from 'react';
import * as icons from './svg';
import styled from 'styled-components';

const StyledIcon = styled.div`
  width: fit-content;
  height: auto;
  display: inline-block;
  background-color: transparent;
  svg {
    display: block;
    fill: ${(props) => props.color};
    width: ${(props) => props.size};
    height: auto;
  }
`;
const Icon = ({ icon, color = 'currentColor', size = '12px' }) => {
  const SVGIcon = icons[icon];
  return (
    <StyledIcon color={color} size={size}>
      <SVGIcon />
    </StyledIcon>
  );
};

export default Icon;
