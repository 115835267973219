import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import FairDetail from './FairDetail';
import FairList, { FairBoothContext } from './FairList';
import styled from 'styled-components';
import { LeftOutlined } from '@ant-design/icons';
import BoothPage from '../Booth';
import { isMobile } from 'react-device-detect';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_FAIRS } from '../../queries/fair';
import {
  GET_EXHIBITED_BOOTHS_BY_FAIR,
  SEARCH_EXHIBITED_BOOTHS_BY_FAIR,
} from '../../queries/booth';
import { GET_FAIR, GET_FAIR_APPLICATIONS } from '../../queries/fair';

const Layout = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
  button {
    outline: none;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    cursor: pointer;
    p {
      margin-left: 9px;
      font-weight: 500;
      color: #aaaaaa;
      font-size: 20px;
    }
  }
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #030303;
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding: 84px 0 0;
  position: relative;
`;

export const FairContext = createContext({});

const Fair = () => {
  const location = useLocation();
  const history = useHistory();
  const [toggleFairFilters, setToggleFairFilters] = useState(
    new Set(['applicationPeriod', 'proceeding', 'expected', 'end']),
  );

  const [savedScrollItem, setSavedScrollItem] = useState('');
  const [savedBoothScrollItem, setSavedBoothScrollItem] = useState('');
  const { data: fairs } = useQuery(GET_FAIRS);
  const [boothSearchValue, setBoothSearchValue] = useState('');
  const [isBoothSearching, setIsBoothSearching] = useState(false);
  const [boothFilter, setBoothFilter] = useState({
    availableToConsult: false,
    mySkills: false,
    recruitmentTypes: [],
    manager: [],
    dates: [],
  });
  const [getBoothsByFair, { data: getBoothsByFairData }] = useLazyQuery(
    GET_EXHIBITED_BOOTHS_BY_FAIR,
  );
  const [searchBoothsByFair, { data: searchBoothsByFairData }] = useLazyQuery(
    SEARCH_EXHIBITED_BOOTHS_BY_FAIR,
  );
  const [getFair, { data: getFairData }] = useLazyQuery(GET_FAIR);
  const [getFairApplications, { data: getFairApplicationsData }] = useLazyQuery(
    GET_FAIR_APPLICATIONS,
  );
  useEffect(() => {
    setBoothFilter({
      availableToConsult: false,
      mySkills: false,
      recruitmentTypes: [],
      manager: [],
      dates: [],
    });
  }, [getFairData]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (boothSearchValue === '') {
        setIsBoothSearching(false);
      } else {
        searchBoothsByFair({
          variables: {
            fairId: getFairData?.fair?.id,
            keyword: boothSearchValue,
          },
        });
        setIsBoothSearching(true);
        setBoothFilter({
          availableToConsult: false,
          mySkills: false,
          recruitmentTypes: [],
          manager: [],
          dates: [],
        });
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [getFairData?.fair?.id, searchBoothsByFair, boothSearchValue]);
  console.log(searchBoothsByFairData);
  return (
    <FairContext.Provider
      value={{
        toggleFairFilters,
        setToggleFairFilters,
        fairs,
        savedScrollItem,
        setSavedScrollItem,
        savedBoothScrollItem,
        setSavedBoothScrollItem,
        boothSearchValue,
        setBoothSearchValue,
        isBoothSearching,
        setIsBoothSearching,
        boothFilter,
        setBoothFilter,
        getBoothsByFair,
        getBoothsByFairData,
        searchBoothsByFair,
        searchBoothsByFairData,
        getFair,
        getFairData,
        getFairApplications,
        getFairApplicationsData,
      }}
    >
      <Layout>
        {location.pathname.split('/').findIndex((a) => a === 'booth') ===
          -1 && (
          <Header>
            {location.pathname === '/fair' ? (
              <HeaderTitle>
                박람회
                <span
                  style={{
                    fontSize: '12px',
                    marginLeft: '12px',
                    marginTop: '2px',
                  }}
                >
                  관심있는 박람회를 찾아 참가해보세요!
                </span>
              </HeaderTitle>
            ) : (
              <button onClick={() => history.push('/fair')}>
                <LeftOutlined style={{ fontSize: '24px', color: '#aaaaaa' }} />
                <p>목록</p>
              </button>
            )}
          </Header>
        )}
        <Route exact path="/fair/:fairId/booth/:boothId">
          <BoothPage />
        </Route>
        <BodyLayout>
          <Route exact path="/fair/:fairId">
            <FairDetail />
          </Route>
          <Route exact path="/fair">
            <FairList />
          </Route>
        </BodyLayout>
      </Layout>
    </FairContext.Provider>
  );
};

export default Fair;
