import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input, Radio } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 0;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const FlexLayout = styled.div`
  width: 420px;
  margin: 16px auto 24px;
  display: flex;
  div {
    margin-left: auto;
    margin-right: 0;
    display: flex;
  }
`;

const Online = ({ booth, isCreating, getBooths }) => {
  const [isOnline, setIsOnline] = useState(null);
  const [zoomLink, setZoomLink] = useState('');
  const [counselMethod, setCounselMethod] = useState('');
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);
  useEffect(() => {
    setIsOnline(booth?.isOnline === null ? true : booth?.isOnline);
    setZoomLink(booth?.zoomLink ?? '');
    setCounselMethod(booth?.counselMethod ?? '');
  }, [booth]);
  useEffect(() => {
    if (!isOnline && isOnline !== null) {
      setZoomLink('');
      setCounselMethod('');
    }
  }, [isOnline]);
  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            isOnline: isOnline,
            zoomLink: zoomLink,
            counselMethod:
              counselMethod === '' ? 'Zoom을 통한 화상상담' : counselMethod,
            step: BoothStep.recruitmentInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            isOnline: isOnline,
            zoomLink: zoomLink,
            counselMethod:
              counselMethod === '' ? 'Zoom을 통한 화상상담' : counselMethod,
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    updateBooth({
      variables: {
        id: booth.id,
        boothInput: {
          isOnline: isOnline,
          zoomLink: zoomLink,
          counselMethod:
            counselMethod === '' ? 'Zoom을 통한 화상상담' : counselMethod,
          step: BoothStep.title,
        },
      },
    });
  };
  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);
  return (
    <Layout>
      <TitleLaout style={{ paddingRight: '72px' }}>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>온라인 용도의 부스인가요 오프라인 용도의 부스인가요?</p>
      </TitleLaout>
      <FlexLayout>
        <div>
          <Radio
            style={{ marginLeft: '53px' }}
            onClick={() => setIsOnline(true)}
            checked={isOnline}
            disabled={booth?.exhibited}
          >
            온라인
          </Radio>
          <Radio
            style={{ marginLeft: '53px' }}
            onClick={() => setIsOnline(false)}
            checked={!isOnline}
            disabled={booth?.exhibited}
          >
            오프라인
          </Radio>
        </div>
      </FlexLayout>
      {isOnline && (
        <>
          <TitleLaout style={{ paddingRight: '6px' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p>온라인 부스일시 화상상담 링크를 작성해주세요.</p>
            <span
              style={{ marginLeft: '11px', color: '#fe706f', fontSize: '11px' }}
            >
              *링크는 추후 작성 가능
            </span>
          </TitleLaout>
          <StyledInput
            value={zoomLink}
            onChange={(e) => setZoomLink(e.currentTarget.value)}
            placeholder="화상상담 링크를 작성해주세요"
          />
          <TitleLaout style={{ paddingLeft: '43px' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p>
              Zoom이 아닌 타사 솔루션 혹은 유선상담인 경우 상담방법을
              입력해주세요.
            </p>
          </TitleLaout>
          <StyledInput
            value={counselMethod}
            onChange={(e) => setCounselMethod(e.currentTarget.value)}
            placeholder="상담 방법을 작성하지 않으시면 'Zoom을 통한 화상상담'으로 저장됩니다."
          />
        </>
      )}

      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={onClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton onClick={onClickRightButton} type="primary">
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Online;
