import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { Color } from '../../../constants/color';
import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  CompanyProfileStep,
  StudentProfileStep,
  UserType,
} from '../../../constants/static';
import {
  BasicStudentProfile,
  Educations,
  Careers,
  Introduction,
  Skills,
  Projects,
  Patents,
  Awards,
  Theses,
  RecruitmentTypes,
  RecruitmentInfo,
  Portfolios,
  MatchingInfo,
  LabInfo,
} from './StudentEditProfileComponents';
import {
  ManagerInfo,
  BasicCompanyProfile,
  Introduction as CompanyIntroduction,
  RecruitmentType as CompanyRecruitmentType,
  Welfare,
  Urls,
  Qnas,
  Logo,
  Images,
} from './CompanyEditProfileComponents';
import { isMobile } from 'react-device-detect';
import MobileProfileComplete from './StudentEditProfileComponents/MobileProfileComplete';
import { Upload } from 'antd';

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  min-height: ${isMobile ? '271px' : '527px'};
`;

const TitleLayout = styled.div`
  margin-left: ${isMobile ? '24px' : '48px'};
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const RequiredText = styled.p`
  margin-left: 25px;
  font-size: 12px;
  line-height: 16px;
  color: #f88988;
`;

const StepLayout = styled.div`
  margin: 0 72px 52px 61px;
  background-color: #e2e2ea;
  border-radius: 8.5px;
  height: 6px;
  position: relative;
`;

const FilledStepLayout = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${Color.Primary};
  border-radius: 6px;
  div {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${Color.Primary};
    border-radius: 24px;
    right: -10px;
    top: -9px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -1px;
    }
  }
`;

const FinishStepLaout = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 24px;
  right: -10px;
  top: -9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const convertStepToWidth = ({ step, userType }) => {
  if (userType === UserType.student) {
    const index = Object.values(StudentProfileStep).findIndex(
      (keyStep) => keyStep === step,
    );
    return index < 0 ? '0' : `${(index / 14) * 100}%`;
  } else {
    const index = Object.values(CompanyProfileStep).findIndex(
      (keyStep) => keyStep === step,
    );
    return index < 0 ? '0' : `${(index / 9) * 100}%`;
  }
};

const convertStepToRequiredString = ({ userType, step }) => {
  if (userType === UserType.student) {
    switch (step) {
      case StudentProfileStep.basicStudentProfile:
        return '* 필수 입력';
      case StudentProfileStep.educations:
        return '* 1개 이상 필수';
      case StudentProfileStep.skills:
        return '* 필수 항목';
      case StudentProfileStep.recruitmentTypes:
        return '* 1개 이상 선택 필수';
      default:
        return '';
    }
  } else {
    switch (step) {
      case CompanyProfileStep.managerInfo:
        return '* 이메일 필수 입력';
      case CompanyProfileStep.basicCompanyProfile:
        return '* 별표 항목 필수';
      case CompanyProfileStep.logo:
        return '* 필수 입력';
      case CompanyProfileStep.introduction:
        return '* 50자 이상 필수';
      case CompanyProfileStep.recruitmentTypes:
        return '* 1개 이상 선택 필수';
      case CompanyProfileStep.welfare:
        return '* 50자 이상 필수';
      default:
        return '';
    }
  }
};

const convertStepToStepString = ({ userType, step }) => {
  if (userType === UserType.student) {
    switch (step) {
      case StudentProfileStep.basicStudentProfile:
        return '기본정보';
      case StudentProfileStep.matchingInfo:
        return '매칭정보';
      case StudentProfileStep.educations:
        return '학력';
      case StudentProfileStep.careers:
        return '경력';
      case StudentProfileStep.labInfo:
        return '연구실 정보';
      case StudentProfileStep.introduction:
        return '자기소개';
      case StudentProfileStep.skills:
        return '보유스킬';
      case StudentProfileStep.projects:
        return '연구과제';
      case StudentProfileStep.theses:
        return '논문';
      case StudentProfileStep.patents:
        return '특허';
      case StudentProfileStep.awards:
        return '수상 / 자격증 / 외국어';
      case StudentProfileStep.portfolios:
        return '포트폴리오';
      case StudentProfileStep.recruitmentTypes:
        return '채용 희망 형태';
      case StudentProfileStep.recruitmentInfo:
        return '채용 관련 정보';
      default:
        return '기본정보';
    }
  } else {
    switch (step) {
      case CompanyProfileStep.managerInfo:
        return '담당자 정보';
      case CompanyProfileStep.basicCompanyProfile:
        return '기업 기본 정보';
      case CompanyProfileStep.logo:
        return '기업 로고';
      case CompanyProfileStep.introduction:
        return '기업 및 서비스 소개';
      case CompanyProfileStep.recruitmentTypes:
        return '채용 형태';
      case CompanyProfileStep.welfare:
        return '기업 복지 및 혜택';
      case CompanyProfileStep.urls:
        return '기타 영상 및 URL';
      case CompanyProfileStep.qnas:
        return '자주 묻는 질문';
      case CompanyProfileStep.images:
        return '대표 이미지';
      default:
        return '담당자 정보';
    }
  }
};

const ConvertStepToComponent = ({
  id,
  step,
  userType,
  isModal = false,
  refetchProfile,
  profile,
  closeModal,
  isShowingMobileCompleteForm,
  setIsShowingMobileCompleteForm,
}) => {
  const renderComponent = () => {
    if (userType === UserType.student) {
      if (isMobile && isShowingMobileCompleteForm) {
        return (
          <MobileProfileComplete
            id={id}
            refetchProfile={refetchProfile}
            setIsShowingMobileCompleteForm={setIsShowingMobileCompleteForm}
          />
        );
      }
      switch (step) {
        case StudentProfileStep.basicStudentProfile:
          return (
            <BasicStudentProfile
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.matchingInfo:
          return (
            <MatchingInfo
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.labInfo:
          return (
            <LabInfo
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.educations:
          return (
            <Educations
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.careers:
          return (
            <Careers
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.introduction:
          return (
            <Introduction
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.skills:
          return (
            <Skills
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.projects:
          return (
            <Projects
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.theses:
          return (
            <Theses
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.patents:
          return (
            <Patents
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.awards:
          return (
            <Awards
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.portfolios:
          return (
            <Portfolios
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.recruitmentTypes:
          return (
            <RecruitmentTypes
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case StudentProfileStep.recruitmentInfo:
          return (
            <RecruitmentInfo
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
              setIsShowingMobileCompleteForm={setIsShowingMobileCompleteForm}
            />
          );
        default:
          return (
            <BasicStudentProfile
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
      }
    } else {
      switch (step) {
        case CompanyProfileStep.managerInfo:
          return (
            <ManagerInfo
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.basicCompanyProfile:
          return (
            <BasicCompanyProfile
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.introduction:
          return (
            <CompanyIntroduction
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.recruitmentTypes:
          return (
            <CompanyRecruitmentType
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.welfare:
          return (
            <Welfare
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.urls:
          return (
            <Urls
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.qnas:
          return (
            <Qnas
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.logo:
          return (
            <Logo
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        case CompanyProfileStep.images:
          return (
            <Images
              id={id}
              refetchProfile={refetchProfile}
              profile={profile}
              isModal={isModal}
              closeModal={closeModal}
            />
          );
        default:
          <ManagerInfo
            id={id}
            refetchProfile={refetchProfile}
            profile={profile}
            isModal={isModal}
            closeModal={closeModal}
          />;
      }
    }
  };
  return (
    <EditingBodyLayout isModal={isModal}>
      <>
        {userType === UserType.student &&
        isMobile &&
        step !== StudentProfileStep.basicStudentProfile &&
        step !== StudentProfileStep.educations &&
        step !== StudentProfileStep.matchingInfo ? null : (
          <TitleLayout>
            <Icon icon="edit" size="21px" color="#ff343e" />
            <Title>
              {!isModal && (
                <>
                  Step{' '}
                  {userType === UserType.student
                    ? Object.values(StudentProfileStep).findIndex(
                        (keyStep) => keyStep === step,
                      )
                    : Object.values(CompanyProfileStep).findIndex(
                        (keyStep) => keyStep === step,
                      )}
                  .
                </>
              )}{' '}
              {convertStepToStepString({ userType, step })}
            </Title>
            <RequiredText>
              {convertStepToRequiredString({ userType, step })}
            </RequiredText>
          </TitleLayout>
        )}

        {!isModal && !isMobile && (
          <StepLayout>
            <FinishStepLaout>
              <CheckOutlined style={{ color: '#aaaaaa', fontSize: '12px' }} />
            </FinishStepLaout>
            <FilledStepLayout width={convertStepToWidth({ step, userType })}>
              <div>
                <p>
                  {userType === UserType.student ? (
                    Object.values(StudentProfileStep).findIndex(
                      (keyStep) => keyStep === step,
                    ) ===
                    Object.values(StudentProfileStep).length - 2 ? (
                      <CheckOutlined
                        style={{ color: 'white', fontSize: '12px' }}
                      />
                    ) : (
                      Object.values(StudentProfileStep).findIndex(
                        (keyStep) => keyStep === step,
                      )
                    )
                  ) : Object.values(CompanyProfileStep).findIndex(
                      (keyStep) => keyStep === step,
                    ) ===
                    Object.values(CompanyProfileStep).length - 2 ? (
                    <CheckOutlined
                      style={{ color: 'white', fontSize: '12px' }}
                    />
                  ) : (
                    Object.values(CompanyProfileStep).findIndex(
                      (keyStep) => keyStep === step,
                    )
                  )}
                </p>
              </div>
            </FilledStepLayout>
          </StepLayout>
        )}
        {step === StudentProfileStep.matchingInfo &&
          userType === UserType.student && (
            <p
              style={{
                marginTop: '-16px',
                marginLeft: isMobile ? '28px' : '94px',
                marginRight: isMobile ? '28px' : '',
                marginBottom: isMobile ? '-32px' : '8px',
                color: '#fe706f',
                fontSize: '12px',
              }}
            >
              * 본 서비스 이용하여 입사하면 병역특례 및 학사 50만원 / 석사
              70만원 /박사 100만원 입사축하금을 지원합니다!
            </p>
          )}
      </>
      {renderComponent()}
    </EditingBodyLayout>
  );
};

export default ConvertStepToComponent;
