import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  position: absolute;
  top: 56px;
  right: 56px;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const ItemLayout = styled.div`
  position: relative;
  padding: 5px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const FlexLayout = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 5px 80px 5px 116px;
  margin-right: 36px;
`;

const SectionInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  width: 138px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  width: 218px;
`;

const TitleInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  flex: 1;
`;

const RegistrationNumberInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  width: 138px;
`;

const InventorInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  flex: 1;
`;

const ApplicantInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  flex: 1;
`;

const CountryInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  margin-right: 10px;
  height: 40px;
  flex: 1;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Patent = ({ patent, updatePatent, deletePatent, isDeletable }) => {
  return (
    <ItemLayout isDeletable={isDeletable}>
      <FlexLayout>
        <SectionInput
          value={patent.section}
          placeholder="구분"
          onChange={(e) =>
            updatePatent({
              section: e.currentTarget.value,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: patent.country,
            })
          }
        />
        <TitleInput
          value={patent.title}
          placeholder="발명의 명칭"
          onChange={(e) =>
            updatePatent({
              section: patent.section,
              title: e.currentTarget.value,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: patent.country,
            })
          }
        />
        <RegistrationNumberInput
          value={patent.registrationNumber}
          placeholder="등록(출원)번호"
          onChange={(e) =>
            updatePatent({
              section: patent.section,
              title: patent.title,
              registrationNumber: e.currentTarget.value,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: patent.country,
            })
          }
        />
      </FlexLayout>
      <FlexLayout>
        <StyledDatePicker
          placeholder={'등록(출원)일자'}
          value={
            patent.registeredAt !== '' ? moment(patent.registeredAt) : undefined
          }
          onChange={(e) => {
            updatePatent({
              section: patent.section,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: e ? moment(e).format('YYYY-MM-DD') : '',
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: patent.country,
            });
          }}
        />
        <InventorInput
          value={patent.inventor}
          placeholder="발명자"
          onChange={(e) =>
            updatePatent({
              section: patent.section,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: e.currentTarget.value,
              applicant: patent.applicant,
              country: patent.country,
            })
          }
        />
        <CountryInput
          value={patent.country}
          placeholder="국가"
          onChange={(e) =>
            updatePatent({
              section: patent.section,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: e.currentTarget.value,
            })
          }
        />
        <ApplicantInput
          value={patent.applicant}
          placeholder="출원인"
          onChange={(e) =>
            updatePatent({
              section: patent.section,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: e.currentTarget.value,
              country: patent.country,
            })
          }
        />
      </FlexLayout>
      <DeleteRowButton disabled={!isDeletable} onClick={deletePatent}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </ItemLayout>
  );
};

const Patents = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [patents, setPatents] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.patents?.length > 0) {
      profile?.patents?.forEach((patent) => {
        setPatents((previousPatents) => {
          return [
            ...previousPatents,
            {
              section: patent.section,
              title: patent.title,
              registrationNumber: patent.registrationNumber,
              registeredAt: patent.registeredAt,
              inventor: patent.inventor,
              applicant: patent.applicant,
              country: patent.country,
            },
          ];
        });
      });
    } else {
      setPatents([
        {
          section: '',
          title: '',
          registrationNumber: '',
          registeredAt: '',
          inventor: '',
          applicant: '',
          country: '',
        },
      ]);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.theses,
          patents: patents,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.awards,
          patents: patents,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <BodyLayout>
        {patents.map((patent, index) => (
          <>
            <Patent
              key={index}
              isDeletable={patents.length > 1}
              deletePatent={() =>
                setPatents((previousPatent) =>
                  previousPatent.filter((_, prevIndex) => index !== prevIndex),
                )
              }
              patent={patent}
              updatePatent={(newPatent) =>
                setPatents((previousPatents) => {
                  return previousPatents.map((previousPatent, previousIndex) =>
                    previousIndex === index ? newPatent : previousPatent,
                  );
                })
              }
            />
            {index + 1 !== patents.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setPatents((prevPatents) => {
            return [
              ...prevPatents,
              {
                section: '',
                title: '',
                registrationNumber: '',
                registeredAt: '',
                inventor: '',
                applicant: '',
                country: '',
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Patents;
