import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input, Select, DatePicker } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { BoothStep, JobVacancyStep, Location } from '../../../constants/static';
import { UPDATE_JOB_VACANCY } from '../../../queries/jobVacancy';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import JobVacancy from '../JobVacancy';

const { Option } = Select;
const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 783px;
  margin: 24px auto 0;
  padding: 0 18px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 96px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledSelect = styled(Select)`
  margin-left: 15px;
  width: 298px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 783px;
  min-height: 121px !important;
  border-radius: 10px;
  margin: 20px auto;
`;

const CheckLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 135px;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  height: 40px;
  border-radius: 10px;
  width: 298px;
`;

const RecruitmentProcess = ({ jobVacancy, isCreating, getJobVacancies }) => {
  const [editingRecruitmentProcess, setEditingRecruitmentProcess] =
    useState('');
  const [editingDate, setEditingDate] = useState({
    startAt: '',
    endAt: '',
  });
  const [editingChecked, setEditingChecked] = useState(true);
  const editingRecruitmentSchedule = useMemo(() => {
    if (editingChecked) {
      return '상시채용';
    } else if (editingDate.startAt !== '') {
      return editingDate.startAt + ' ~ ' + editingDate.endAt;
    }
    return '';
  }, [editingDate, editingChecked]);
  const [updateJobVacancy, { data }] = useMutation(UPDATE_JOB_VACANCY);
  useEffect(() => {
    setEditingRecruitmentProcess(jobVacancy?.recruitmentProcess ?? '');
    let array = jobVacancy?.recruitmentSchedule?.split(' ~ ');
    if (array?.length === 2) {
      console.log(jobVacancy?.recruitmentSchedule?.split(' ~ '));
      console.log(array);
      setEditingDate({
        startAt: array[0],
        endAt: array[1],
      });
      setEditingChecked(false);
    } else {
      setEditingChecked(true);
    }
  }, [jobVacancy]);
  console.log(editingDate);
  const onClickLeftButton = () => {
    if (editingRecruitmentProcess === '' || editingRecruitmentSchedule === '') {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            step: JobVacancyStep.environment,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            recruitmentProcess: editingRecruitmentProcess,
            recruitmentSchedule: editingRecruitmentSchedule,
            step: JobVacancyStep.environment,
          },
        },
      });
    }
  };
  const onClickRightButton = () => {
    if (isCreating) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            recruitmentProcess: editingRecruitmentProcess,
            recruitmentSchedule: editingRecruitmentSchedule,
            step: JobVacancyStep.publishedUniversities,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            recruitmentProcess: editingRecruitmentProcess,
            recruitmentSchedule: editingRecruitmentSchedule,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getJobVacancies();
    }
  }, [data]);
  return (
    <Layout>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>채용 일정에 대해서 작성해주세요.</p>
      </FlexLayout>
      <CheckLayout>
        <Checkbox
          checked={editingChecked}
          onChange={(e) => {
            setEditingChecked(e.target.checked);
            setEditingDate({
              startAt: '',
              endAt: '',
            });
          }}
        >
          상시채용
        </Checkbox>
        <div style={{ width: '54px' }} />
        <StyledRangePicker
          placeholder={['시작일', '종료일']}
          disabled={editingChecked}
          value={[
            editingDate.startAt !== ''
              ? moment(editingDate.startAt)
              : undefined,
            editingDate.endAt !== '' ? moment(editingDate.endAt) : undefined,
          ]}
          onChange={(e) => {
            setEditingDate({
              startAt: e ? moment(e[0]).format('YYYY-MM-DD') : '',
              endAt: e ? moment(e[1]).format('YYYY-MM-DD') : '',
            });
          }}
        />
      </CheckLayout>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>채용까지의 절차를 설명해주세요.</p>
      </FlexLayout>

      <StyledTextarea
        placeholder="내용을 입력해주세요"
        value={editingRecruitmentProcess}
        onChange={(e) => setEditingRecruitmentProcess(e.currentTarget.value)}
      />
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={
                editingRecruitmentProcess === '' ||
                editingRecruitmentSchedule === ''
              }
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={
              editingRecruitmentProcess === '' ||
              editingRecruitmentSchedule === ''
            }
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default RecruitmentProcess;
