import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { BookOutlined, CheckOutlined } from '@ant-design/icons';
import { groupBy } from '../../../constants/utils';
import { Button, Input, Select, Tag } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SKILLS } from '../../../queries/skill';
import { UPDATE_BOOTH } from '../../../queries/booth';
import {
  BoothStep,
  JobVacancyStep,
  Location,
  Degree,
} from '../../../constants/static';
import { UPDATE_JOB_VACANCY } from '../../../queries/jobVacancy';

const { Option } = Select;
const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const StyledTag = styled(Tag)`
  border-radius: 2px;
  background-color: #fe706f;
  height: 22px;
  border: none;
  color: white;
  margin-bottom: 4px;
  svg {
    color: white;
  }
`;

const SkillFlexLayout = styled.div`
  display: flex;
  height: 192px;
  margin: 0 90px 27px;
`;

const FieldArrayLayout = styled.div`
  width: 40%;
  height: 192px;
  border-radius: 5px;
  background-color: #fcfcfc;
  overflow-y: scroll;
  margin-right: 16px;
  display: flex;
  flex-flow: column;
  padding: 2px 0;
`;

const SkillDetailsArrayLayout = styled.div`
  flex: 1;
  height: 192px;
  border-radius: 5px;
  background-color: #fcfcfc;
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
  padding: 2px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 32px;
`;

const SkillDetailItemButton = styled.button`
  height: 32px;
  width: 100%;
  margin-right: 1px;
  padding: 4px 12px;
  outline: none;
  background-color: ${(props) => (props.selected ? '#fff1f0' : 'transparent')};
  font-size: 14px;
  text-align: left;
  color: ${(props) => (props.selected ? '#262626' : '#bfbfbf')};
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  div {
    flex: 1;
  }
  svg {
    margin-left: auto;
    margin-right: 0;
  }
`;

const FieldButton = styled.button`
  height: 32px;
  padding: 4px 12px;
  outline: none;
  border: ${(props) => (props.currentField ? '1px solid #fe706f' : 'none')};
  background-color: ${(props) =>
    props.currentField ? '#fff1f0' : 'transparent'};
  font-size: 14px;
  text-align: left;
  color: ${(props) =>
    props.selected || props.currentField ? '#262626' : '#bfbfbf'};
  font-weight: ${(props) => (props.currentField ? 700 : 500)};
  cursor: pointer;
`;
const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 783px;
  margin: 0 auto;
  padding: 0 18px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 96px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledSelect = styled(Select)`
  margin-left: 15px;
  width: 298px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 783px;
  min-height: 149px !important;
  border-radius: 10px;
  margin: 20px auto;
`;

const TagLayout = styled.div`
  padding: 20px 30px;
  width: 790px;
  min-height: 63px;
  border: 1px solid #fe706f;
  background-color: #fcfcfc;
  border-radius: 10px;
  margin: 0 auto 20px;
`;

const SkillboxLayout = styled.div`
  margin: 0 90px 31px;
  background: #fcfcfc;
  border-radius: 15px;
  min-height: 107px;
  padding: 10px 0 22px;
  border: 1px solid #fe706f;
`;

const SkillboxTitie = styled.p`
  margin-left: 22px;
  font-weight: 500;
  font-size: 14px;
  color: #171725;
`;

const Skills = ({ jobVacancy, isCreating, getJobVacancies }) => {
  const { data: skillSet } = useQuery(GET_SKILLS);
  const [skillDetails, setSkillDetails] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState('');
  const newSkillSet = useMemo(() => {
    if (skillSet?.skills?.length > 0) {
      return groupBy(skillSet?.skills, 'field');
    }
    return {};
  }, [skillSet]);
  useEffect(() => {
    if (newSkillSet?.length > 0) {
      setSelectedSkill(Object.keys(newSkillSet)[0]);
    }
  }, [newSkillSet]);
  useEffect(() => {
    if (jobVacancy?.skills) {
      setSkillDetails(jobVacancy?.skills);
    }
  }, [jobVacancy]);

  const [updateJobVacancy, { data }] = useMutation(UPDATE_JOB_VACANCY);

  const onClickLeftButton = () => {
    if (skillDetails.length === 0) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            step: JobVacancyStep.requirements,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            skillIds: skillDetails.map((item) => item.id),
            step: JobVacancyStep.requirements,
          },
        },
      });
    }
  };
  const onClickRightButton = () => {
    if (isCreating) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            skillIds: skillDetails.map((item) => item.id),
            step: JobVacancyStep.environment,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            skillIds: skillDetails.map((item) => item.id),
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getJobVacancies();
    }
  }, [data]);
  return (
    <Layout>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>업무와 관련된 스킬을 모두 선택해주세요.</p>
      </FlexLayout>
      <div style={{ height: '20px' }} />
      <TagLayout>
        {skillDetails?.map((skill) => (
          <StyledTag
            key={skill.id}
            closable
            onClose={() =>
              setSkillDetails((prevSkills) =>
                prevSkills.filter((prevSkill) => {
                  return prevSkill.id !== skill.id;
                }),
              )
            }
          >
            <span>
              {skill.field}/{skill.skillDetail}
            </span>
          </StyledTag>
        ))}
      </TagLayout>
      <SkillFlexLayout>
        <FieldArrayLayout>
          {newSkillSet && (
            <>
              {Object.keys(newSkillSet)?.map((field) => (
                <FieldButton
                  onClick={() => setSelectedSkill(field)}
                  currentField={field === selectedSkill}
                  selected={
                    skillDetails.filter(
                      (selectedSkillDetail) =>
                        newSkillSet[field].findIndex(
                          (item) => item.id === selectedSkillDetail.id,
                        ) > -1,
                    ).length > 0
                  }
                >
                  {field}
                </FieldButton>
              ))}
            </>
          )}
        </FieldArrayLayout>
        <SkillDetailsArrayLayout>
          {newSkillSet[selectedSkill]?.length > 0 &&
            newSkillSet[selectedSkill]?.map((item) => (
              <SkillDetailItemButton
                onClick={() => {
                  if (
                    skillDetails?.findIndex(
                      (skillDetail) => skillDetail.id === item.id,
                    ) > -1
                  ) {
                    setSkillDetails((prevSkillDetails) =>
                      prevSkillDetails.filter(
                        (skillDetail) => skillDetail.id !== item.id,
                      ),
                    );
                  } else {
                    setSkillDetails((prevSkillDetails) => [
                      ...prevSkillDetails,
                      item,
                    ]);
                  }
                }}
                selected={
                  skillDetails?.findIndex(
                    (skillDetail) => skillDetail.id === item.id,
                  ) > -1
                }
              >
                {item.skillDetail}
                <div />
                {skillDetails?.findIndex(
                  (skillDetail) => skillDetail.id === item.id,
                ) > -1 && (
                  <CheckOutlined
                    style={{ color: '#fe706f', fontSize: '20px' }}
                  />
                )}
              </SkillDetailItemButton>
            ))}
        </SkillDetailsArrayLayout>
      </SkillFlexLayout>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={skillDetails.length === 0}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={skillDetails.length === 0}
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Skills;
