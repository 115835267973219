import React from 'react';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input as BaseInput } from 'antd';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  width: ${(props) => props.width};
  height: 68px;
`;

const Label = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const StyledPassword = styled(BaseInput.Password)`
  input {
    font-size: ${isMobile ? '16px' : '13px'};
  }
  border-radius: 10px;
  border-color: ${(props) => (props.error ? '#f5222d' : '')};
`;

const FlexLayout = styled.div`
  display: flex;
`;

const RightButton = styled(Button)`
  margin-left: 5px;
  border-radius: 10px;
  min-width: 84px;
`;

const StyledBaseInput = styled(BaseInput)`
  input {
    font-size: ${isMobile ? '16px' : '13px'};
  }
  border-radius: 10px;
  border-color: ${(props) => (props.error ? '#f5222d' : '')};
`;

const ErrorMessageLayout = styled.div`
  margin-top: 6px;
  display: flex;
  height: 9px;
  p {
    color: #ff4d4f;
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 0;
    margin-top: 1px;
  }
  align-items: center;
`;
const Input = ({
  name,
  width,
  label,
  value,
  onChange,
  errorMessage,
  disabled,
  secure,
  placeholder,
  inputRightIcon,
  rightButtonName,
  rightButtonDisabled,
  rightButtonLoading,
  rightButtonOnClick,
  onKeyPress,
}) => {
  return (
    <Layout width={width}>
      {label && <Label>{label}</Label>}
      <FlexLayout>
        {secure ? (
          <StyledPassword
            name={name}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            error={errorMessage}
            disabled={disabled}
            placeholder={placeholder}
            onKeyPress={onKeyPress}
          />
        ) : (
          <StyledBaseInput
            name={name}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            disabled={disabled}
            placeholder={placeholder}
            error={errorMessage}
            style={{
              borderRadius: '10px',
              fontSize: isMobile ? '16px' : '13px',
            }}
            suffix={inputRightIcon}
            onKeyPress={onKeyPress}
          />
        )}
        {rightButtonName && (
          <div>
            <RightButton
              disabled={rightButtonDisabled}
              loading={rightButtonLoading}
              type="primary"
              onClick={rightButtonOnClick}
            >
              {rightButtonName}
            </RightButton>
          </div>
        )}
      </FlexLayout>
      {errorMessage && (
        <ErrorMessageLayout>
          <ExclamationCircleOutlined
            style={{
              fontSize: '9px',
              height: '9px',
              color: '#ff4d4f',
              marginTop: '2px',
            }}
          />
          <p>{errorMessage}</p>
        </ErrorMessageLayout>
      )}
    </Layout>
  );
};

export default Input;
