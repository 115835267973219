import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import useAuth from '../../hooks/useAuth';
import {
  APPLY_INTERVIEW,
  CANCEL_SAVE_JOB_VACANCY,
  GET_SAVED_JOB_VACANCIES_BY_STUDENT,
  SAVE_JOB_VACANCY,
} from '../../queries/matching';
import JobVacancyComponent from './JobVacancyComponent';

const Layout = styled.div`
  margin-top: 28px;
  background-color: white;
  border-radius: 10px;
  padding: 25px 33px 48px;
`;

const ListLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(368px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '16px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
`;

const JobVacanciesTab = () => {
  const [unsavingJobVacancyId, setUnsavingJobVacancyId] = useState(0);
  const [applyingJobVacancy, setApplyingJobVacancy] = useState(null);
  const { user } = useAuth();
  const [cancelSaveJobVacancy] = useMutation(CANCEL_SAVE_JOB_VACANCY, {
    onCompleted: (data) => {
      setUnsavingJobVacancyId(0);
      getSavedJobVacanciesByStudent({
        variables: {
          studentId: user.id,
        },
      });
    },
  });
  const [interviewFailModalOpened, setInterviewFailModalOpened] =
    useState(false);
  const [applyInterview] = useMutation(APPLY_INTERVIEW, {
    onCompleted: (data) => {
      setApplyingJobVacancy(null);
      getSavedJobVacanciesByStudent({
        variables: {
          studentId: user.id,
        },
      });
    },
    onError: (error) => {
      if (
        error?.graphQLErrors?.[0]?.extensions?.code ===
        'STUDENT_NOT_ON_MATCHING'
      ) {
        setApplyingJobVacancy(null);
        setInterviewFailModalOpened(true);
      }
    },
  });
  const [getSavedJobVacanciesByStudent, { data }] = useLazyQuery(
    GET_SAVED_JOB_VACANCIES_BY_STUDENT,
  );
  useEffect(() => {
    if (user.id > 0) {
      getSavedJobVacanciesByStudent({
        variables: {
          studentId: user.id,
        },
      });
    }
  }, [user.id, getSavedJobVacanciesByStudent]);
  const history = useHistory();
  return (
    <Layout>
      <ListLayout>
        {data?.savedJobVacanciesByStudent?.map((item) => (
          <JobVacancyComponent
            saved={item.saved}
            onClickSaveButton={() => setUnsavingJobVacancyId(item.id)}
            onClickApplyButton={() => setApplyingJobVacancy(item)}
            applied={item.applied}
            jobVacancy={item}
          />
        ))}
      </ListLayout>
      <Modal
        isOpen={unsavingJobVacancyId > 0}
        onClose={() => setUnsavingJobVacancyId(0)}
        radius="30px"
      >
        <SmallModalLayout
          title="저장 취소"
          description={
            <>
              저장을 취소하시겠습니까?
              <br />
              저장 채용공고목록에서 제외집니다.
            </>
          }
          setDescriptionCenter={true}
          onClickLeftButton={() => setUnsavingJobVacancyId(0)}
          onClickRightButton={() => {
            cancelSaveJobVacancy({
              variables: {
                studentId: user.id,
                jobVacancyId: unsavingJobVacancyId,
              },
            });
          }}
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={applyingJobVacancy !== null}
        onClose={() => setApplyingJobVacancy(null)}
        radius="30px"
      >
        <SmallModalLayout
          title="면접 지원"
          description={
            <>
              <span style={{ fontWeight: 500 }}>
                {
                  applyingJobVacancy?.company?.basicCompanyProfile
                    ?.companyNameKR
                }
              </span>
              의
              <br />
              <span style={{ color: '#fe706f' }}>
                {applyingJobVacancy?.position}
              </span>{' '}
              채용공고에 면접을 지원하시겠습니까?
            </>
          }
          setDescriptionCenter={true}
          onClickLeftButton={() => setApplyingJobVacancy(null)}
          onClickRightButton={() => {
            applyInterview({
              variables: {
                studentId: user.id,
                jobVacancyId: applyingJobVacancy?.id,
              },
            });
          }}
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={interviewFailModalOpened}
        onClose={() => setInterviewFailModalOpened(false)}
        radius="30px"
      >
        <SmallModalLayout
          title="면접 지원 실패"
          description={'프로필에서 매칭 서비스 이용을 ON으로 바꿔주세요.'}
          setDescriptionCenter={true}
          removeLeftButton={true}
          onClickRightButton={() => history.push('/profile/manage')}
          height="305px"
        />
      </Modal>
    </Layout>
  );
};

export default JobVacanciesTab;
