import { BookOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { UserType } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import {
  UPDATE_RESERVATION_MEMO,
  UPDATE_RESERVATION_STUDENT_MEMO,
} from '../../../queries/counsel';

const { TextArea } = Input;
const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: ${isMobile ? '24px 0 24px' : '37px 0 23px'};
  overflow-y: scroll;
`;

const TitleLayout = styled.div`
  margin-left: ${isMobile ? '24px' : '48px'};
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const ContentLayout = styled.div`
  display: flex;
  width: fit-content;
  margin: ${isMobile ? '16px 32px 20px' : '16px 112px 20px'};
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: ${isMobile ? '300px' : '783px'};
  min-height: 249px !important;
  border-radius: 10px;
  margin: 0 auto;
`;
const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const CounselMemoModal = ({ id, currentMemo, onClose }) => {
  const [memo, setMemo] = useState('');
  useEffect(() => {
    setMemo(currentMemo);
  }, [currentMemo]);
  const [updateReservationMemo, { data }] = useMutation(
    UPDATE_RESERVATION_MEMO,
  );
  const [
    updateReservationStudentMemo,
    { data: updateReservationStudentMemoData },
  ] = useMutation(UPDATE_RESERVATION_STUDENT_MEMO);
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);
  useEffect(() => {
    if (updateReservationStudentMemoData) {
      onClose();
    }
  }, [updateReservationStudentMemoData]);
  const { user } = useAuth();
  return (
    <Layout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>상담 기록</Title>
      </TitleLayout>
      <ContentLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>상담 내용을 기록해주세요.</p>
      </ContentLayout>
      <StyledTextarea
        placeholder="내용을 입력해주세요"
        value={memo}
        onChange={(e) => setMemo(e.currentTarget.value)}
      />
      <ButtonLayout>
        <BottomCenterButton
          type="primary"
          onClick={() => {
            if (user.userType === UserType.student) {
              updateReservationStudentMemo({
                variables: {
                  id: id,
                  studentMemo: memo,
                },
              });
            } else {
              updateReservationMemo({
                variables: {
                  id: id,
                  memo: memo,
                },
              });
            }
          }}
        >
          확인
        </BottomCenterButton>
      </ButtonLayout>
    </Layout>
  );
};

export default CounselMemoModal;
