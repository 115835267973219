import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox } from 'antd';

import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import {
  CompanyProfileStep,
  recruitmentTypeArray,
} from '../../../../constants/static';
import { UPDATE_COMPANY_PROFILE } from '../../../../queries/company';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0 151px;
  width: 100%;
  height: 204px;
  .ant-checkbox-wrapper {
    margin: 0 !important;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const RecruitmentTypes = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
}) => {
  const [recruitmentTypes, setRecruitmentTypes] = useState([]);
  const [updateCompanyProfile, { data }] = useMutation(UPDATE_COMPANY_PROFILE);
  useEffect(() => {
    if (profile?.recruitmentTypes?.length > 0) {
      setRecruitmentTypes(profile?.recruitmentTypes);
    }
  }, [profile]);
  const handleClickLeftButton = () => {
    if (recruitmentTypes.length === 0) {
      updateCompanyProfile({
        variables: {
          id: id,
          companyProfileInput: {
            step: CompanyProfileStep.introduction,
          },
        },
      });
    } else {
      updateCompanyProfile({
        variables: {
          id: id,
          companyProfileInput: {
            step: CompanyProfileStep.introduction,
            recruitmentTypes: recruitmentTypes,
          },
        },
      });
    }
  };
  const handleClickRightButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: isModal
            ? CompanyProfileStep.complete
            : CompanyProfileStep.welfare,
          recruitmentTypes: recruitmentTypes,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <p style={{ marginLeft: '150px', fontSize: '15px', fontWeight: 500 }}>
        현재 채용 가능한 유형만 선택해 주세요.
      </p>
      <BodyLayout>
        {recruitmentTypeArray.map((item) => (
          <Checkbox
            checked={recruitmentTypes.findIndex((type) => type === item) > -1}
            onChange={() => {
              if (recruitmentTypes.findIndex((type) => type === item) > -1) {
                setRecruitmentTypes((prev) =>
                  prev.filter((prevItem) => prevItem !== item),
                );
              } else {
                setRecruitmentTypes((prev) => [...prev, item]);
              }
            }}
          >
            {item}
          </Checkbox>
        ))}
      </BodyLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            disabled={recruitmentTypes.length === 0}
            onClick={handleClickRightButton}
            type="primary"
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={recruitmentTypes.length === 0}
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default RecruitmentTypes;
