import { gql } from '@apollo/client';

export const GET_PRESENTATIONS = gql`
  query GetPresentations(
    $first: Int
    $after: ID
    $isScheduled: Boolean
    $isEnded: Boolean
  ) {
    presentations(
      first: $first
      after: $after
      isScheduled: $isScheduled
      isEnded: $isEnded
    ) {
      id
      title
      isOnline
      image {
        id
        name
        url
      }
      personnel
      maxPersonnel
      date
      startAt
      endAt
      link
    }
  }
`;

export const GET_IS_STUDENT_APPLIED = gql`
  query GetIsStudentApplied($studentId: ID, $presentationId: ID) {
    isStudentApplied(studentId: $studentId, presentationId: $presentationId)
  }
`;

export const GET_PRESENTATIONS_BY_COMPANY = gql`
  query GetPresentations($companyId: ID) {
    presentationsByCompany(companyId: $companyId) {
      id
      title
      date
    }
  }
`;

export const GET_PRESENTATION_APPLICATIONS_BY_COMPANY = gql`
  query GetPresentationApplicationsByCompany(
    $presentationId: ID
    $first: Int
    $after: ID
  ) {
    presentationApplicationsByCompany(
      presentationId: $presentationId
      first: $first
      after: $after
    ) {
      id
      student {
        id
        email
        basicStudentProfile {
          name
          phoneNumber
        }
        educations {
          university
          major
          degree
        }
      }
    }
  }
`;

export const GET_PRESENTATION = gql`
  query GetPresentation($id: ID) {
    presentation(id: $id) {
      id
      title
      isOnline
      image {
        id
        name
        url
      }
      universities {
        id
        name
        domain
      }
      personnel
      maxPersonnel
      date
      startAt
      endAt
      link
      content
      event {
        description
        winner
        winnerAnnouncedAt
      }
      surveyLink
    }
  }
`;

export const GET_APPLIED_PRESENTATIONS = gql`
  query GetAppliedPresentations($studentId: ID) {
    appliedPresentations(studentId: $studentId) {
      id
      title
      isOnline
      image {
        id
        name
        url
      }
      maxPersonnel
      date
      startAt
      endAt
      link
      entryCode
    }
  }
`;

export const INQUIRE_PRESENTATION = gql`
  mutation InquirePresentation(
    $companyId: ID
    $email: String
    $phoneNumber: String
    $content: String
  ) {
    inquirePresentation(
      companyId: $companyId
      email: $email
      phoneNumber: $phoneNumber
      content: $content
    )
  }
`;

export const APPLY_PRESENTATION = gql`
  mutation ApplyPresentation($studentId: ID!, $presentationId: ID!) {
    applyPresentation(studentId: $studentId, presentationId: $presentationId)
  }
`;

export const CANCEL_APPLY_PRESENTATION = gql`
  mutation CancelApplyPresentation($studentId: ID!, $presentationId: ID!) {
    cancelApplyPresentation(
      studentId: $studentId
      presentationId: $presentationId
    )
  }
`;
