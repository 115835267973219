import { gql } from '@apollo/client';

export const MANAGER_INFO = gql`
  fragment ManagerInfo on Company {
    managerInfo {
      name
      phoneNumber
      email
      emailMarketingAgreement
      phoneMarketingAgreement
    }
  }
`;

export const BASIC_COMPANY_PROFILE = gql`
  fragment BasicCompanyProfile on Company {
    basicCompanyProfile {
      companyNameKR
      companyNameEN
      homePage
      registrationNumber
      domain
      phoneNumber
      address
      foundedAt
    }
  }
`;

export const URLS = gql`
  fragment Urls on Company {
    urls {
      name
      link
    }
  }
`;

export const QNAS = gql`
  fragment Qnas on Company {
    qnas {
      question
      answer
    }
  }
`;

// Queries

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      email
      logo {
        url
      }
      basicCompanyProfile {
        companyNameKR
        companyNameEN
      }
    }
  }
`;

export const GET_COMPANY_PROFILE = gql`
  ${MANAGER_INFO}
  ${BASIC_COMPANY_PROFILE}
  ${URLS}
  ${QNAS}
  query GetCompanyProfile($id: ID!) {
    company(id: $id) {
      step
      ...ManagerInfo
      ...BasicCompanyProfile
      logo {
        id
        url
        name
      }
      images {
        id
        url
        name
      }
      introduction
      recruitmentTypes
      welfare
      ...Urls
      ...Qnas
    }
  }
`;

export const GET_COMPANY_ADVERTISEMENT_REACTIONS = gql`
  query GetCompanyAdvertisementReactions($id: ID!) {
    company(id: $id) {
      advertisement {
        approved
        reactions {
          reactionType
          student {
            basicStudentProfile {
              name
            }
            educations {
              university
              major
              degree
            }
          }
          reactedAt
        }
      }
    }
  }
`;

export const GET_COMPANY_ADVERTISEMENT = gql`
  query GetCompanyAdvertisement($id: ID!) {
    company(id: $id) {
      advertisement {
        id
        title
        content
        image {
          url
          name
          id
        }
        youtubeLink
        companySize
        domains {
          id
          name
        }
        recruitmentTypes
        approved
      }
    }
  }
`;

export const GET_PRESENTATIONS = gql`
  query GetPresentations($first: Int, $after: ID, $isScheduled: Boolean) {
    presentations(first: $first, after: $after, isScheduled: $isScheduled) {
      id
      title
      isOnline
      image {
        id
        name
        url
      }
      maxPersonnel
      date
      startAt
      endAt
    }
  }
`;

export const GET_COMPANY_PROFILE_WITH_JOB_VACANCIES = gql`
  ${MANAGER_INFO}
  ${BASIC_COMPANY_PROFILE}
  ${URLS}
  ${QNAS}
  query GetCompanyProfile($id: ID!) {
    company(id: $id) {
      id
      step
      ...ManagerInfo
      ...BasicCompanyProfile
      logo {
        id
        url
        name
      }
      images {
        id
        url
        name
      }
      introduction
      recruitmentTypes
      welfare
      ...Urls
      ...Qnas
      onMatchingJobVacancies {
        id
        position
        workplace
        task
        requiredDegree
        requiredKnowledge
        skills {
          id
          field
          skillDetail
        }
        environment
        recruitmentSchedule
        recruitmentProcess
        saved
        applied
        company {
          basicCompanyProfile {
            companyNameKR
          }
        }
      }
    }
  }
`;

// Mutations

export const UPDATE_COMPANY_PROFILE = gql`
  mutation UpdateCompanyProfile(
    $id: ID!
    $companyProfileInput: CompanyProfileInput!
  ) {
    updateCompanyProfile(id: $id, companyProfileInput: $companyProfileInput) {
      id
    }
  }
`;

export const UPLOAD_COMPANY_FILE = gql`
  mutation UploadCompanyFile(
    $userId: ID!
    $fileType: FileType!
    $file: Upload!
  ) {
    uploadCompanyFile(userId: $userId, fileType: $fileType, file: $file) {
      id
      name
      url
    }
  }
`;

export const DELETE_COMPANY_FILE = gql`
  mutation UploadCompanyFile($userId: ID, $fileType: FileType!, $fileId: ID) {
    deleteCompanyFile(userId: $userId, fileType: $fileType, fileId: $fileId)
  }
`;

export const GET_COMPANY_PROFILE_BY_COUNSELOR = gql`
  ${MANAGER_INFO}
  ${BASIC_COMPANY_PROFILE}
  ${URLS}
  ${QNAS}
  query GetCompanyByCounselor($id: ID!) {
    companyByCounselor(id: $id) {
      step
      ...ManagerInfo
      ...BasicCompanyProfile
      logo {
        id
        url
        name
      }
      images {
        id
        url
        name
      }
      introduction
      recruitmentTypes
      welfare
      ...Urls
      ...Qnas
    }
  }
`;

export const DELETE_FILE_BY_URL = gql`
  mutation DeleteFileByUrl($url: String) {
    deleteFileByUrl(url: $url)
  }
`;
