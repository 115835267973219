import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { userWhite } from '../../components/Icon/svg';
import Modal from '../../components/Modal';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import {
  GET_QUESTIONS_BY_COMPANY,
  LATEST_MATCHING_APPLICATION_BY_COMPANY,
  READ_QUESTION_BY_COMPANY,
  REPLY_QUESTION,
} from '../../queries/matching';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';
import { maskingName } from './ApplicationsTab';
import Icon from '../../components/Icon';
import BannerIllust from '../../img/MatchingBannerIllust.png';
import { useHistory } from 'react-router-dom';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: 24px;
`;

const BodyLayout = styled.div`
  margin: 29px 60px 0;
`;

const QnaItemLayout = styled.div`
  margin-bottom: 16px;
  max-width: 800px;
  cursor: pointer;
  height: 130px;
  padding: 16px 37px 22px 32px;
  border-radius: 10px;
  background-color: white;
  border: ${(props) =>
    !props.checked ? '1.5px solid #fe706f' : '1.5px solid #aaaaaa'};
`;

const QnaTitleLayout = styled.div`
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 15px;
  div {
    border-radius: 10px;
    background-color: white;
    padding: 5px 16px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border: 1px solid #d9d9d9;
    text-align: left;
    color: #262626;
    margin-left: 24px;
  }
`;

const QnaBodyLayout = styled.div`
  display: flex;
`;

const QuestionLayout = styled.div`
  display: flex;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.0032em;
  text-align: left;
  p {
    max-width: 553px;
    margin-left: 12px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-right: 16px;
    letter-spacing: -0.0032em;
    text-align: left;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 1.4em;
    line-height: 1.4em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StatusText = styled.p`
  text-align: left;
  width: 50px !important;
  min-width: 50px !important;
  margin-right: 44px;
  font-family: Noto Sans KR;
  font-size: 15px;
  color: ${(props) => (!props.checked ? '#fe706f' : '#aaaaaa')};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -13px;
`;

const RightButton = styled.button`
  cursor: pointer;
  height: 32px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 95px !important;
  margin-top: -16px;
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;
const ModalBody = styled.div`
  height: 495px;
  width: 100%;
  position: absolute;
  top: 32px;
  z-index: 0;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const QnaModalLayout = styled.div`
  display: flex;
  flex-flow: column;
  width: 892px;
  height: 527px;
`;

const QnaModalHeader = styled.div`
  min-height: 105px;
  width: 100%;
  background-color: #fff1f0;
  padding: 36px 50px 14px;
  display: flex;
  flex-flow: column;
  div {
    display: flex;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.0032em;
    text-align: left;
    p {
      width: 700px;
      margin-left: 12px;
      font-family: Noto Sans KR;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.0032em;
      text-align: left;
      white-space: pre-wrap;
    }
  }
  span {
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 640px;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }
`;

const QnaModalBody = styled.div`
  flex: 1;
  overflow: scroll;
`;

const QnaTextArea = styled(Input.TextArea)`
  margin-right: 44px;
  width: 664px;
  height: 83px !important;
  border-radius: 10px;
`;

const QnaSendButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 32px;
`;

const QnaModalBottom = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  height: 128px;
  border-top: 1px solid #f0f0f0;
  padding: 17px 38px 28px 50px;
  display: flex;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const QnaItem = styled.div`
  margin: ${(props) =>
    props.type === 'question' ? '0 auto 16px 0' : '0 0 16px auto'};
  display: flex;
  align-items: flex-end;
`;

const QnaDateText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
`;

const QnaBodyText = styled.p`
  padding: 16px 24px;
  width: 401px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === 'question' ? '#FFF1F0' : '#F2F2F2'};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
  white-space: pre-wrap;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  height: ${isMobile ? '288px' : ''};
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: ${isMobile ? '35px 23px' : '37px 48px'};
  img {
    right: ${isMobile ? '-8px' : '27px'};
    bottom: ${isMobile ? '44px' : '18px'};
    width: ${isMobile ? '148px' : '220px'};
    height: ${isMobile ? '148px' : '222px'};
    position: absolute;
    z-index: 0;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  position: ${isMobile ? 'absolute' : ''};
  z-index: 2;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: ${isMobile ? '140px' : '34px'};
  width: 226px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const Qna = () => {
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [selectedQnaId, setSelectedQnaId] = useState(0);
  const [getQuestionsByCompany, { data: getQuestionsByCompanyData, loading }] =
    useLazyQuery(GET_QUESTIONS_BY_COMPANY, {
      fetchPolicy: 'network-only',
    });
  const [listQnas, setListQnas] = useState([]);
  const [readQuestionByCompany] = useMutation(READ_QUESTION_BY_COMPANY, {
    onCompleted: (data) => {
      setListQnas((prev) =>
        prev.map((item) =>
          item.id === data.readQuestionByCompany
            ? { ...item, companyRead: true }
            : item,
        ),
      );
    },
  });
  const [replyQuestion] = useMutation(REPLY_QUESTION, {
    onCompleted: (data) => {
      setListQnas((prev) =>
        prev.map((item) =>
          item.id === selectedQnaId
            ? { ...item, replies: [...item.replies, data.replyQuestion] }
            : item,
        ),
      );
    },
  });
  const selectedQna = useMemo(() => {
    return listQnas.filter((qna) => qna.id === selectedQnaId)?.[0];
  }, [listQnas, selectedQnaId]);
  const [text, setText] = useState('');
  const [target, setTarget] = useState(null);
  useEffect(() => {
    if (selectedQnaId === 0) {
      setText('');
    }
  }, [selectedQnaId]);
  const { user } = useAuth();
  const onIntersect =
    (after) =>
    ([entry], observer) => {
      if (entry.isIntersecting && !loading && !finished) {
        getQuestionsByCompany({
          variables: {
            first: 20,
            after: after,
            companyId: user.id,
          },
        });
      }
    };
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(
        onIntersect(
          listQnas?.length === 0 ? null : listQnas?.[listQnas?.length - 1].id,
        ),
        {
          threshold: 0.4,
        },
      );
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, listQnas]);
  const [
    latestMatchingApplicationByCompany,
    { data: latestMatchingApplicationByCompanyData },
  ] = useLazyQuery(LATEST_MATCHING_APPLICATION_BY_COMPANY);
  useEffect(() => {
    if (user.id > 0) {
      latestMatchingApplicationByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [user.id, latestMatchingApplicationByCompany]);
  useEffect(() => {
    if (getQuestionsByCompanyData) {
      setListQnas((prev) => {
        if (
          prev.findIndex(
            (item) =>
              item.id === getQuestionsByCompanyData?.questionsByCompany?.[0].id,
          ) === -1
        ) {
          return [...prev, ...getQuestionsByCompanyData?.questionsByCompany];
        }
        return prev;
      });
      if (getQuestionsByCompanyData?.questionsByCompany?.length < 20) {
        setFinished(true);
      }
    }
  }, [getQuestionsByCompanyData]);
  const history = useHistory();

  return (
    <Layout>
      <TitleText>Q&A</TitleText>
      {latestMatchingApplicationByCompanyData !== undefined && (
        <>
          {latestMatchingApplicationByCompanyData?.latestMatchingApplicationByCompany ===
            null ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'PENDING' ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'DECLINED' ? (
            <AnnounceLayout>
              <img src={BannerIllust} alt="banner" />
              <AnnounceTitleLayout>
                <Icon icon={'edit'} size="21px" color="#ff434e" />
                <p>
                  {latestMatchingApplicationByCompanyData
                    ?.latestMatchingApplicationByCompany?.state === 'PENDING'
                    ? '매칭 서비스 신청 승인 대기 중입니다'
                    : '매칭 서비스를 신청해주세요!'}
                </p>
              </AnnounceTitleLayout>
              <AnnounceBodyText>
                {latestMatchingApplicationByCompanyData
                  ?.latestMatchingApplicationByCompany?.state === 'PENDING' ? (
                  <>
                    <br />
                    조금만 기다려주세요!
                    <br />
                    승인 완료 후 해당 기능 사용 가능합니다.
                    <br />
                  </>
                ) : (
                  <>
                    아직 매칭 서비스를 신청하지 않으셨네요!
                    <br />
                    매칭 서비스를 신청하여 인재를 만나볼 기회를 넓혀보세요.
                  </>
                )}
              </AnnounceBodyText>
              {latestMatchingApplicationByCompanyData
                ?.latestMatchingApplicationByCompany?.state !== 'PENDING' && (
                <AnnounceBottomButton
                  onClick={() => {
                    history.push('/company-meeting/apply-matching');
                  }}
                >
                  <p>매칭서비스 신청하러가기</p>
                  <Icon icon={'edit'} size="21px" color="#ffffff" />
                </AnnounceBottomButton>
              )}
            </AnnounceLayout>
          ) : (
            <>
              <BodyLayout>
                {listQnas.map((item) => (
                  <QnaItemLayout
                    onClick={() => {
                      setSelectedQnaId(item.id);
                      readQuestionByCompany({
                        variables: {
                          companyId: user.id,
                          questionId: item.id,
                        },
                      });
                    }}
                    key={item.id}
                    checked={item.companyRead}
                  >
                    <QnaTitleLayout>
                      {maskingName(item.student?.basicStudentProfile?.name)}
                      <div>{item.createdAt}</div>
                    </QnaTitleLayout>
                    <QnaBodyLayout>
                      <QuestionLayout>
                        Q.
                        <p>{item.message}</p>
                      </QuestionLayout>
                      <Spacer />
                      <StatusText checked={item.companyRead}>
                        {item.companyRead ? '확인' : '미확인'}
                      </StatusText>
                      <RightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedStudentId(item.student?.id);
                        }}
                      >
                        프로필 보기
                      </RightButton>
                    </QnaBodyLayout>
                  </QnaItemLayout>
                ))}
                <div ref={setTarget} className="Target-Element" />
              </BodyLayout>
              <Modal
                isOpen={selectedStudentId > 0}
                onClose={() => setSelectedStudentId(0)}
                closeButtonTransparent
              >
                <ModalLayout>
                  <ModalHeader>
                    <UserOutlined
                      style={{ fontSize: '20px', color: '#fe706f' }}
                    />
                    <ModalHeaderTitle>프로필</ModalHeaderTitle>
                  </ModalHeader>
                  <ModalBody>
                    <StudentProfileModal
                      id={selectedStudentId}
                      hideInformation
                    />
                  </ModalBody>
                </ModalLayout>
              </Modal>
              <Modal
                isOpen={selectedQnaId > 0}
                onClose={() => setSelectedQnaId(0)}
                closeButtonTransparent
              >
                <QnaModalLayout>
                  <QnaModalHeader>
                    <div>
                      Q.
                      <p>{selectedQna?.message}</p>
                    </div>
                    <span>{selectedQna?.createdAt}</span>
                  </QnaModalHeader>
                  <QnaModalBody>
                    <div
                      style={{
                        margin: '19px 50px 0',
                        display: 'flex',
                        flexFlow: 'column',
                      }}
                    >
                      {selectedQna?.replies.map((item) => (
                        <QnaItem
                          type={
                            item.userType === UserType.student
                              ? 'question'
                              : 'answer'
                          }
                        >
                          {item.userType === UserType.student ? (
                            <>
                              <QnaBodyText
                                type={
                                  item.userType === UserType.student
                                    ? 'question'
                                    : 'answer'
                                }
                              >
                                {item.message}
                              </QnaBodyText>
                              <div style={{ width: '12px' }} />
                              <QnaDateText>{item.createdAt}</QnaDateText>
                            </>
                          ) : (
                            <>
                              <QnaDateText>{item.createdAt}</QnaDateText>
                              <div style={{ width: '12px' }} />
                              <QnaBodyText
                                type={
                                  item.userType === UserType.student
                                    ? 'question'
                                    : 'answer'
                                }
                              >
                                {item.message}
                              </QnaBodyText>
                            </>
                          )}
                        </QnaItem>
                      ))}
                    </div>
                  </QnaModalBody>
                  <QnaModalBottom>
                    <QnaTextArea
                      placeholder="답변을 입력해주세요"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <QnaSendButton
                      disabled={text === ''}
                      type="primary"
                      onClick={() => {
                        replyQuestion({
                          variables: {
                            userType: UserType.company,
                            message: text,
                            questionId: selectedQnaId,
                          },
                        });
                        setText('');
                      }}
                    >
                      답글
                    </QnaSendButton>
                  </QnaModalBottom>
                </QnaModalLayout>
              </Modal>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default Qna;
