import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { LATEST_MATCHING_APPLICATION_BY_COMPANY } from '../../queries/matching';
import ApplicationTab from './ApplicationsTab';
import StudentsTab from './StudentsTab';
import SuggestionTab from './SuggestionTab';
import BannerIllust from '../../img/MatchingBannerIllust.png';
import Icon from '../../components/Icon';
import { useHistory } from 'react-router-dom';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: 24px;
`;

const BodyLayout = styled.div`
  margin: 29px 60px 0;
`;

const FlexLayout = styled.div`
  display: flex;
  align-items: center;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  height: ${isMobile ? '288px' : ''};
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: ${isMobile ? '35px 23px' : '37px 48px'};
  img {
    right: ${isMobile ? '-8px' : '27px'};
    bottom: ${isMobile ? '44px' : '18px'};
    width: ${isMobile ? '148px' : '220px'};
    height: ${isMobile ? '148px' : '222px'};
    position: absolute;
    z-index: 0;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  position: ${isMobile ? 'absolute' : ''};
  z-index: 2;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: ${isMobile ? '140px' : '34px'};
  width: 226px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const Tab = styled.div`
  padding: 16px 0;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: ${(props) => (props.selected ? '#FE706F' : '#262626')};
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: ${(props) =>
    props.selected ? '2px solid #fe706f' : '2px solid transparent'};
  margin-right: 32px;
  cursor: pointer;
`;

const ManageApplications = () => {
  const [
    latestMatchingApplicationByCompany,
    { data: latestMatchingApplicationByCompanyData },
  ] = useLazyQuery(LATEST_MATCHING_APPLICATION_BY_COMPANY);
  const { user } = useAuth();
  useEffect(() => {
    if (user.id > 0) {
      latestMatchingApplicationByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [user.id, latestMatchingApplicationByCompany]);
  const history = useHistory();
  return (
    <Layout>
      <TitleText>면접 지원 관리</TitleText>
      {latestMatchingApplicationByCompanyData !== undefined && (
        <>
          {latestMatchingApplicationByCompanyData?.latestMatchingApplicationByCompany ===
            null ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'PENDING' ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'DECLINED' ? (
            <AnnounceLayout>
              <img src={BannerIllust} alt="banner" />
              <AnnounceTitleLayout>
                <Icon icon={'edit'} size="21px" color="#ff434e" />
                <p>
                  {latestMatchingApplicationByCompanyData
                    ?.latestMatchingApplicationByCompany?.state === 'PENDING'
                    ? '매칭 서비스 신청 승인 대기 중입니다'
                    : '매칭 서비스를 신청해주세요!'}
                </p>
              </AnnounceTitleLayout>
              <AnnounceBodyText>
                {latestMatchingApplicationByCompanyData
                  ?.latestMatchingApplicationByCompany?.state === 'PENDING' ? (
                  <>
                    <br />
                    조금만 기다려주세요!
                    <br />
                    승인 완료 후 해당 기능 사용 가능합니다.
                    <br />
                  </>
                ) : (
                  <>
                    아직 매칭 서비스를 신청하지 않으셨네요!
                    <br />
                    매칭 서비스를 신청하여 인재를 만나볼 기회를 넓혀보세요.
                  </>
                )}
              </AnnounceBodyText>
              {latestMatchingApplicationByCompanyData
                ?.latestMatchingApplicationByCompany?.state !== 'PENDING' && (
                <AnnounceBottomButton
                  onClick={() => {
                    history.push('/company-meeting/apply-matching');
                  }}
                >
                  <p>매칭서비스 신청하러가기</p>
                  <Icon icon={'edit'} size="21px" color="#ffffff" />
                </AnnounceBottomButton>
              )}
            </AnnounceLayout>
          ) : (
            <>
              <BodyLayout>
                <ApplicationTab />
              </BodyLayout>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default ManageApplications;
