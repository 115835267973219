import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CounselState } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { isMobile } from 'react-device-detect';
import { MAKE_COUNSEL_RESERVATION } from '../../queries/counsel';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '35px 0 23px' : '35px 46px 23px'};
  overflow: scroll;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  margin-left: ${isMobile ? '30px' : ''};
  letter-spacing: 0em;
  text-align: left;
  color: #ff434e;
`;

const BodyLayout = styled.div`
  width: ${isMobile ? '100%' : '700px'};
  height: ${isMobile ? '' : '334px'};
  min-height: ${isMobile ? '334px' : ''};
  margin-top: ${isMobile ? '15px' : '33px'};
  margin-left: ${isMobile ? '' : '90px'};
  background-color: #f4f3f3;
  overflow-y: ${isMobile ? '' : 'scroll'};
  border-radius: 10px;
`;

const ContentLayout = styled.div`
  padding: ${isMobile ? '27px 18px' : '27px 30px'};
  display: grid;
  grid-template-columns: ${isMobile ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'};
  grid-gap: 15px 27px;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 23px auto 0;
`;

const DateBadge = styled.div`
  width: 139px;
  height: 32px;
  padding: 5px 12px;
  border-radius: 10px;
  border: 1px solid
    ${(props) =>
      props.disabled ? '#AAAAAA' : props.selected ? '#FFF1F0' : '#FE706F'};
  color: ${(props) =>
    props.disabled ? '#AAAAAA' : props.selected ? '#262626' : '#FE706F'};
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  background-color: ${(props) => (props.selected ? '#FFF1F0' : '#ffffff')};
  align-items: center;
  display: flex;
  p {
    margin-right: 8px;
  }
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
`;

const StyledSelect = styled(Select)`
  width: ${isMobile ? '304px' : '329px'};
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
  margin: 20px auto 0;
`;

const CounselModal = ({ booth, onClose }) => {
  const [id, setId] = useState(0);
  const [reserveCounsel, { data, error }] = useMutation(
    MAKE_COUNSEL_RESERVATION,
  );
  const dateList = booth?.dateAndCounselReservations
    ?.map((a) => a.date)
    .sort((a, b) => moment(a).diff(b));
  const now = moment();
  const [selectedDate, setSelectedDate] = useState(undefined);
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data, onClose]);
  useEffect(() => {
    if (
      error?.graphQLErrors?.[0]?.extensions?.code === 'COUNSEL_ALREADY_RESERVED'
    ) {
      alert('이미 예약된 상담 시간입니다.');
      onClose();
    }
  }, [error]);
  const { user } = useAuth();
  return (
    <Layout>
      <Title>상담신청</Title>
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <p
          style={{
            margin: isMobile ? '24px 22px 0' : '24px 90px 0',
            fontSize: '15px',
            fontWeight: 500,
          }}
        >
          사은품
        </p>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            padding: '8px',
            minHeight: '64px',
            maxHeight: '120px',
            overflow: 'scroll',
            margin: isMobile ? '8px 20px 0' : '8px 88px 0',
            borderRadius: '10px',
            border: '1px solid #fe706f',
          }}
        >
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {booth?.booth?.present ?? (
              <span style={{ color: 'grey' }}>
                상담 사은품 안내가 존재하지 않습니다.
              </span>
            )}
          </p>
        </div>
        {booth?.booth?.isOnline && (
          <>
            <p
              style={{
                margin: isMobile ? '24px 22px 0' : '24px 90px 0',
                fontSize: '15px',
                fontWeight: 500,
              }}
            >
              상담 방법
            </p>
            <div
              style={{
                whiteSpace: 'pre-wrap',
                padding: '8px',
                minHeight: '64px',
                maxHeight: '120px',
                overflow: 'scroll',
                margin: isMobile ? '8px 20px 0' : '8px 88px 0',
                borderRadius: '10px',
                border: '1px solid #fe706f',
              }}
            >
              {booth?.booth?.zoomLink !== '' &&
                '* 상담 10분전에 프로필 아이콘 > 상담 페이지에서 링크 표시 예정'}
              <br />
              {booth.booth?.counselMethod}
            </div>
          </>
        )}

        <StyledSelect
          size="large"
          placeholder="상담날짜를 선택해주세요"
          value={selectedDate}
          onChange={(value) => setSelectedDate(value)}
        >
          {dateList?.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </StyledSelect>
        <BodyLayout>
          {selectedDate !== '' && (
            <ContentLayout>
              {booth?.dateAndCounselReservations
                ?.filter((item) => item.date === selectedDate)?.[0]
                ?.counsels?.map((counsel) => (
                  <DateBadge
                    key={counsel.id}
                    onClick={() => {
                      if (
                        counsel.state !== CounselState.reserved &&
                        now.diff(
                          moment(
                            selectedDate.concat(' ').concat(counsel.startAt),
                          ),
                        ) <= 0
                      ) {
                        setId(counsel.id);
                      }
                    }}
                    selected={id === counsel.id}
                    disabled={
                      counsel.state === CounselState.reserved ||
                      now.diff(
                        moment(
                          selectedDate.concat(' ').concat(counsel.startAt),
                        ),
                      ) > 0
                    }
                  >
                    <p>
                      {counsel.startAt} ~ {counsel.endAt}
                    </p>
                    {id === counsel.id && (
                      <CheckOutlined
                        style={{
                          color: '#fe706f',
                          marginRight: 0,
                          marginLeft: 'auto',
                        }}
                      />
                    )}
                  </DateBadge>
                ))}
            </ContentLayout>
          )}
        </BodyLayout>
        <BottomCenterButton
          disabled={id === 0}
          type="primary"
          onClick={() => {
            reserveCounsel({
              variables: {
                counselId: id,
                userId: user.id,
              },
            });
          }}
        >
          확인
        </BottomCenterButton>
      </div>
    </Layout>
  );
};

export default CounselModal;
