import React, { useRef, useEffect, useState, useCallback } from 'react';
import BodyPortal from './BodyPortal';
import styled, { css } from 'styled-components';

const Layout = styled.div`
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 100ms;
  opacity: ${(props) => props.opacity};
  outline: none;
  z-index: 1000;
`;

const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  touch-action: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  z-index: 1000;
`;

const StyledPopover = styled.div`
  background-color: #ffffff;
  position: absolute;
  overflow: hidden;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  z-index: 1000;
  ${(props) =>
    props.horizontalAlign === 'right' &&
    css`
      transform: translateX(calc(${props.anchorWidth} - ${props.width}));
    `};
  border-radius: ${(props) => props.borderRadius ?? '6px'};
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  ${(props) =>
    !props.removePadding &&
    css`
      padding: 8px;
    `};
`;

function debounce(func, wait) {
  let timeoutId;
  return function debouncedFunc(...args) {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => func.apply(this, args), wait);
  };
}

export default function Popover({
  onClose,
  children,
  open,
  width,
  height = 'auto',
  anchorRef,
  horizontalAlign = 'left',
  borderRadius,
  removePadding = false,
  verticalAlign = 'top',
}) {
  const modalRef = useRef(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (open) {
      if (modalRef.current) modalRef.current.focus();
      setVisible(true);
    }
  }, [open]);
  const handleTransitionEnd = useCallback(() => {
    setVisible(false);
  }, []);
  const [popoverTop, setPopoverTop] = useState(0);
  const [popoverLeft, setPopoverLeft] = useState(0);
  const [anchorWidth, setAnchorWidth] = useState(0);
  useEffect(() => {
    if (open && anchorRef && anchorRef.current != null) {
      const anchorRect = anchorRef.current.getBoundingClientRect();
      setPopoverTop(
        verticalAlign === 'top' ? anchorRect.top : anchorRect.bottom,
      );
      setPopoverLeft(anchorRect.left);
      setAnchorWidth(anchorRect.width);
    }
  }, [anchorRef, open]);
  useEffect(() => {
    if (open) {
      const handleWindowResize = debounce(() => {
        if (anchorRef && anchorRef.current != null) {
          const anchorRect = anchorRef.current.getBoundingClientRect();
          setPopoverTop(anchorRect.top);
          setPopoverLeft(anchorRect.left);
          setAnchorWidth(anchorRect.width);
        }
      }, 300);
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }
    return () => undefined;
  }, [anchorRef, open]);

  if (!open && !visible) return <></>;
  return (
    <BodyPortal>
      <Layout
        ref={modalRef}
        role="presentation"
        tabIndex={-1}
        onKeyDown={(e) => {
          if (e.key === 'Escape' && onClose) {
            onClose();
          }
        }}
        onTransitionEnd={open ? undefined : handleTransitionEnd}
        opacity={open && visible ? 1 : 0}
      >
        <Backdrop
          aria-label="backdrop"
          aria-hidden="true"
          onClick={() => {
            if (onClose) onClose();
          }}
        />
        <StyledPopover
          top={`${popoverTop}px`}
          left={`${popoverLeft}px`}
          anchorWidth={`${anchorWidth}px`}
          width={width}
          height={height}
          horizontalAlign={horizontalAlign}
          removePadding={removePadding}
          borderRadius={borderRadius}
        >
          {children}
        </StyledPopover>
      </Layout>
    </BodyPortal>
  );
}
