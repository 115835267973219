import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BookOutlined, UploadOutlined } from '@ant-design/icons';
import {
  DELETE_COMPANY_FILE,
  UPLOAD_COMPANY_FILE,
} from '../../../queries/company';
import { Upload, Select, Button, Input } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FileType } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import { GET_FAIR_APPLICATIONS_IN_BOOTH_PAGE } from '../../../queries/fair';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const StyledSelect = styled(Select)`
  width: 537px;
  margin: 25px auto auto;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const StyledInput = styled(Input)`
  width: 498px;
  margin: 28px auto 49px;
  border-radius: 10px;
`;

const FileUploadLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 16px auto;
`;

const FileUploadButton = styled(Button)`
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 40px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  color: #8c8c8c;
`;

const FileLayout = styled.div`
  display: flex;
  align-items: center;
  margin-left: 13px;
  background-color: #f5f5f5;
  padding: 0 0 0 2px;
  p {
    margin-left: 8px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #40a9ff;
  }
  color: #8c8c8c;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const UploadButton = styled(Button)`
  width: 121px;
  height: 40px;
  border-radius: 10px;
`;

const BusinessRegistration = ({
  editingApplication,
  setEditingApplication,
}) => {
  const [uploadCompanyFile, { data: uploadCompanyFileData }] =
    useMutation(UPLOAD_COMPANY_FILE);
  const [deleteCompanyFile, { data: deleteCompanyFileData }] =
    useMutation(DELETE_COMPANY_FILE);
  const { user } = useAuth();
  const [getFairApplications, { data }] = useLazyQuery(
    GET_FAIR_APPLICATIONS_IN_BOOTH_PAGE,
  );
  useEffect(() => {
    getFairApplications({
      variables: {
        id: user.id,
      },
    });
  }, [user]);
  useEffect(() => {
    if (
      data &&
      data?.fairApplications?.length > 0 &&
      editingApplication.taxBillEmail === ''
    ) {
      const last = data?.fairApplications?.[0];
      setEditingApplication((prev) => ({
        ...prev,
        taxBillEmail: last?.taxBillEmail ?? '',
        businessRegistration: last?.businessRegistration ?? null,
      }));
    }
  }, [data]);
  const handleUpload = (file) => {
    if (file.size / 1024 / 1024 >= 10) {
      alert('10MB보다 작은 파일만 업로드 가능합니다.');
    } else {
      uploadCompanyFile({
        variables: {
          userId: user.id,
          fileType: FileType.businessRegistration,
          file: file,
        },
      });
    }
    return false;
  };
  useEffect(() => {
    if (uploadCompanyFileData !== undefined) {
      setEditingApplication((prev) => ({
        ...prev,
        businessRegistration: uploadCompanyFileData?.uploadCompanyFile,
      }));
    }
  }, [uploadCompanyFileData]);
  useEffect(() => {
    if (deleteCompanyFileData !== undefined) {
      setEditingApplication((prev) => ({
        ...prev,
        businessRegistration: undefined,
      }));
    }
  }, [deleteCompanyFileData]);
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>세금 계산서를 받으실 이메일을 입력해주세요.</p>
      </TitleLaout>
      <StyledInput
        size="large"
        placeholder="이메일을 입력해주세요"
        value={editingApplication.taxBillEmail}
        onChange={(e) =>
          setEditingApplication({
            ...editingApplication,
            taxBillEmail: e.currentTarget.value,
          })
        }
      />
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p style={{ width: '543px', textAlign: 'left', paddingTop: '20px' }}>
          사업자 등록증을 업로드해주세요. <br />
          (보안상의 문제로 업로드가 되지 않을 경우, help@senlab.co.kr 로 파일을
          보내주세요.){' '}
        </p>
      </TitleLaout>
      <p style={{ margin: '12px auto 0', fontSize: '12px' }}>
        *10MB 이하 파일만 업로드 가능합니다.
      </p>
      <FileUploadLayout>
        <Upload
          name="file"
          fileList={
            editingApplication.businessRegistration
              ? [
                  {
                    uid: editingApplication.businessRegistration.id,
                    name: editingApplication.businessRegistration.name,
                    status: 'done',
                    url: editingApplication.businessRegistration.url,
                  },
                ]
              : []
          }
          maxCount={1}
          beforeUpload={handleUpload}
          onRemove={(file) => {
            deleteCompanyFile({
              variables: {
                userId: user.id,
                fileType: FileType.businessRegistration,
                fileId: file.uid,
              },
            });
          }}
        >
          {(editingApplication.businessRegistration === undefined ||
            editingApplication.businessRegistration === null) && (
            <UploadButton>
              <UploadOutlined />
              파일 업로드
            </UploadButton>
          )}
        </Upload>
      </FileUploadLayout>
      <ButtonLayout>
        <BottomLeftButton
          onClick={() =>
            setEditingApplication({ ...editingApplication, step: 1 })
          }
          type="primary"
        >
          이전
        </BottomLeftButton>
        <BottomRightButton
          onClick={() =>
            setEditingApplication({ ...editingApplication, step: 3 })
          }
          disabled={
            editingApplication.taxBillEmail === '' ||
            editingApplication.businessRegistration === null ||
            editingApplication.businessRegistration === undefined
          }
          type="primary"
        >
          다음
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default BusinessRegistration;
