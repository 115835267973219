import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Color } from '../../constants/color';
import Background from '../../img/SignUpSuccessModalBackground.png';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BackgroundImg = styled.img`
  position: absolute;
  z-index: 0;
  width: ${isMobile ? '340px' : '464px'};
  top: ${isMobile ? '24px' : '24px'};
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '77px 36px 35px' : '85px 96px 35px'};
  z-index: 10;
  display: flex;
  flex-flow: column;
`;

const TitleText = styled.p`
  font-weight: ${isMobile ? 500 : 'bold'};
  font-size: ${isMobile ? '14px' : '15px'};
  line-height: 22px;
  text-align: center;
  color: #2e2e2e;
`;

const SignUpSuccessModal = ({ handlePage }) => {
  return (
    <Layout>
      <BackgroundImg src={Background} alt="background" />
      <BodyLayout>
        <TitleText>
          축하드립니다!
          <br />
          회원가입을 완료했습니다.
        </TitleText>
        <Button
          onClick={() => {
            handlePage('signIn');
          }}
          type="primary"
          style={{
            width: '272px',
            marginBottom: '0',
            borderRadius: '10px',
            marginTop: 'auto',
          }}
        >
          로그인
        </Button>
      </BodyLayout>
    </Layout>
  );
};

export default SignUpSuccessModal;
