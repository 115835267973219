import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { isMobile, isMobileOnly } from 'react-device-detect';
import useAuth from '../../hooks/useAuth';
import {
  Degree,
  domainArray,
  recruitmentTypeArray,
} from '../../constants/static';
import { Button, Input, Popover, Select } from 'antd';
import {
  FilterFilled,
  RetweetOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_ON_MATCHING_COMPANIES,
  SEARCH_ON_MATCHING_COMPANIES,
} from '../../queries/matching';
import CompanyItem from './CompanyItem';
import { useHistory } from 'react-router-dom';
import { GET_SKILLS, SEARCH_SKILLS } from '../../queries/skill';
import Modal from '../../components/Modal';
import { MatchingStudentContext } from '.';

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(327px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '20px'};
  padding-bottom: 24px;
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  align-items: ${isMobileOnly ? 'center' : ''};
  margin-bottom: ${isMobileOnly ? '' : '40px'};
  margin-top: ${isMobileOnly ? '' : '12px'};
  overflow-y: scroll;
  height: ${isMobileOnly ? '' : 'calc(100% - 172px)'};
`;

const FilterLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  padding: ${isMobile ? '24px 28px' : '0 53px'};
  background-color: white;
  position: ${isMobile ? 'relative' : ''};
  top: ${isMobile ? '' : ''};
  height: ${isMobile ? '' : '88px'};
  width: 100%;
  z-index: ${isMobile ? 1 : 10};
`;

const FilterButton = styled(Button)`
  min-width: 203px;
  height: 40px;
  display: flex;
  border: 1px solid #fe706f;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  margin-top: ${isMobile ? '12px' : ''};
`;
const PopoverLayout = styled.div`
  width: 356px;
  height: ${isMobile ? '440px' : '393px'};
  overflow-y: scroll;
  padding: 10px 0 19px;
  .ant-picker-panel {
    display: ${isMobile ? 'none' : ''};
    visibility: ${isMobile ? 'hidden' : ''};
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const SearchInput = styled(Input)`
  border-radius: 10px;
  width: 320px;
  font-size: ${isMobile ? '16px' : '14px'};
`;

const PopoverFlexLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '0 15px 0 24px' : ''};
  align-items: center;
  color: #262626;
`;

const PopoverDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin: 17px 0;
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  color: #262626;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledSelect2 = styled(Select)`
  flex: 1;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const CompanyTab = () => {
  const { user } = useAuth();
  const {
    searchValue,
    setSearchValue,
    getFinished,
    searchFinished,
    listCompaniesWithSkills,
    getOnMatchingCompanies,
    getOnMatchingCompaniesLoading,
    searchOnMatchingCompanies,
    searchOnMatchingCompaniesLoading,
    isFilterPopoverOpened,
    setIsFilterPopoverOpened,
    filter,
    setFilter,
    isSearching,
    setIsSearching,
    savedCompanyScrollItem,
    setSavedCompanyScrollItem,
  } = useContext(MatchingStudentContext);

  const [target, setTarget] = useState(null);
  useEffect(() => {
    document.getElementById(savedCompanyScrollItem)?.scrollIntoView(false);
  }, [savedCompanyScrollItem]);
  const onIntersect = useCallback(
    ([entry], observer) => {
      if (entry.isIntersecting && listCompaniesWithSkills.length > 0) {
        if (!isSearching) {
          if (!getOnMatchingCompaniesLoading && !getFinished) {
            getOnMatchingCompanies({
              variables: {
                first: 30,
                after:
                  listCompaniesWithSkills.length === 0
                    ? null
                    : listCompaniesWithSkills[
                        listCompaniesWithSkills.length - 1
                      ].id,
                domains: filter.domains?.length === 0 ? null : filter.domains,
                skillIds: filter.skills?.length === 0 ? null : filter.skills,
                recruitmentTypes:
                  filter.recruitmentTypes?.length === 0
                    ? null
                    : filter.recruitmentTypes,
                requiredDegrees:
                  filter.requiredDegrees?.length === 0
                    ? null
                    : filter.requiredDegrees,
              },
            });
          }
        } else {
          if (!searchOnMatchingCompaniesLoading && !searchFinished) {
            searchOnMatchingCompanies({
              variables: {
                first: 30,
                after:
                  listCompaniesWithSkills.length === 0
                    ? null
                    : listCompaniesWithSkills[
                        listCompaniesWithSkills.length - 1
                      ].id,
                keyword: searchValue,
              },
            });
          }
        }
      }
    },
    [
      filter.domains,
      filter.recruitmentTypes,
      filter.requiredDegrees,
      filter.skills,
      getFinished,
      getOnMatchingCompaniesLoading,
      isSearching,
      listCompaniesWithSkills,
      searchFinished,
      searchOnMatchingCompaniesLoading,
      searchValue,
    ],
  );
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, onIntersect]);
  const [editingFilter, setEditingFilter] = useState({
    domains: [],
    skills: [],
    recruitmentTypes: [],
    requiredDegrees: [],
  });
  useEffect(() => {
    if (isFilterPopoverOpened) {
      setEditingFilter(filter);
    }
  }, [filter, isFilterPopoverOpened]);
  const history = useHistory();
  const [isSearchingSkills, setIsSearchingSkills] = useState(false);
  const [
    searchSkills,
    { data: searchSkillsData, loading: searchSkillsLoading },
  ] = useLazyQuery(SEARCH_SKILLS);
  const { data: getSkillsData } = useQuery(GET_SKILLS);
  const [timeoutItem, setTimeoutItem] = useState(0);

  return (
    <>
      <FilterLayout>
        {isMobile ? (
          <>
            <SearchInput
              size="large"
              placeholder={'기업명 검색'}
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
              prefix={<SearchOutlined style={{ color: '#fe706f' }} />}
            />

            <FilterButton onClick={() => setIsFilterPopoverOpened(true)}>
              <FilterFilled
                style={{
                  fontSize: '16px',
                  color: '#fe706f',
                  marginRight: '10px',
                }}
              />
              필터로 원하는 기업 찾기
            </FilterButton>
          </>
        ) : (
          <>
            <Popover
              placement="bottomLeft"
              visible={isFilterPopoverOpened}
              onVisibleChange={(visible) => {
                setIsFilterPopoverOpened(visible);
              }}
              trigger={'click'}
              content={
                <PopoverLayout>
                  <PopoverFlexLayout>
                    <Spacer />
                    필터 초기화
                    <Button
                      type="link"
                      onClick={() => {
                        setEditingFilter({
                          domains: [],
                          skills: [],
                          recruitmentTypes: [],
                          requiredDegrees: [],
                        });
                      }}
                    >
                      <RetweetOutlined style={{ fontSize: '24px' }} />
                    </Button>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>산업분야</PopoverTitle>
                    <StyledSelect2
                      allowClear
                      mode="multiple"
                      showSearch={false}
                      filterOption={false}
                      placeholder="산업분야 선택"
                      value={editingFilter.domains}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          domains: value,
                        })
                      }
                    >
                      {domainArray.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>요구스킬</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      filterOption={false}
                      placeholder="스킬 선택"
                      value={editingFilter?.skills ?? []}
                      onDropdownVisibleChange={() =>
                        setIsSearchingSkills(false)
                      }
                      loading={searchSkillsLoading}
                      onSearch={(value) => {
                        if (value === '') {
                          setIsSearchingSkills(false);
                        } else {
                          setIsSearchingSkills(true);
                          clearTimeout(timeoutItem);
                          setTimeoutItem(
                            setTimeout(() => {
                              searchSkills({
                                variables: {
                                  keyword: value,
                                },
                              });
                            }, 300),
                          );
                        }
                      }}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          skills: value,
                        })
                      }
                    >
                      {!isSearchingSkills ? (
                        <>
                          {getSkillsData?.skills?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.field}/${item.skillDetail}`}
                            </Select.Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {searchSkillsData?.searchSkills?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.field}/${item.skillDetail}`}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>채용형태</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      placeholder="채용형태 선택"
                      showSearch={false}
                      filterOption={false}
                      value={editingFilter.recruitmentTypes}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          recruitmentTypes: value,
                        })
                      }
                    >
                      {recruitmentTypeArray?.map((item) => (
                        <Select.Option value={item}>{item}</Select.Option>
                      ))}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>지원학위</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      showSearch={false}
                      filterOption={false}
                      placeholder="지원학위 선택"
                      value={editingFilter.requiredDegrees}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          requiredDegrees: value,
                        })
                      }
                    >
                      {Degree?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '12px',
                      color: '#fe706f',
                      marginTop: '24px',
                      marginBottom: '8px',
                    }}
                  >
                    * 적용 버튼을 눌러야 변경사항이 적용됩니다.
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{ borderRadius: '10px' }}
                      type="primary"
                      onClick={() => {
                        setIsSearching(false);
                        setSearchValue('');
                        setIsFilterPopoverOpened(false);
                        setFilter(editingFilter);
                      }}
                    >
                      적용
                    </Button>
                  </div>
                </PopoverLayout>
              }
            >
              <FilterButton onClick={() => setIsFilterPopoverOpened(true)}>
                <FilterFilled
                  style={{
                    fontSize: '16px',
                    color: '#fe706f',
                    marginRight: '10px',
                  }}
                />
                필터로 원하는 기업 찾기
              </FilterButton>
            </Popover>
            <Spacer />
            <SearchInput
              size="large"
              placeholder={'기업명 검색'}
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
              prefix={<SearchOutlined style={{ color: '#fe706f' }} />}
            />
          </>
        )}
      </FilterLayout>
      <ListBodyLayout>
        {listCompaniesWithSkills?.map((item) => (
          <CompanyItem
            companyWithSkills={item}
            id={`company-${item.company.id}`}
            onClick={() => {
              setSavedCompanyScrollItem(`company-${item.company.id}`);
              history.push(`/matching/company/${item.company.id}`);
            }}
          />
        ))}
        <div ref={setTarget} className="Target-Element" />
      </ListBodyLayout>
      <Modal
        isOpen={isFilterPopoverOpened && isMobile}
        onClose={() => setIsFilterPopoverOpened(false)}
      >
        <PopoverLayout>
          <div style={{ height: '40px' }} />
          <PopoverFlexLayout>
            <Spacer />
            필터 초기화
            <Button
              type="link"
              onClick={() => {
                setEditingFilter({
                  domains: [],
                  skills: [],
                  recruitmentTypes: [],
                  requiredDegrees: [],
                });
              }}
            >
              <RetweetOutlined style={{ fontSize: '24px' }} />
            </Button>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>산업분야</PopoverTitle>
            <StyledSelect2
              allowClear
              mode="multiple"
              showSearch={false}
              filterOption={false}
              placeholder="산업분야 선택"
              value={editingFilter.domains}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  domains: value,
                })
              }
            >
              {domainArray.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </StyledSelect2>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>요구스킬</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              filterOption={false}
              placeholder="스킬 선택"
              value={editingFilter?.skills ?? []}
              onDropdownVisibleChange={() => setIsSearchingSkills(false)}
              loading={searchSkillsLoading}
              onSearch={(value) => {
                if (value === '') {
                  setIsSearchingSkills(false);
                } else {
                  setIsSearchingSkills(true);
                  clearTimeout(timeoutItem);
                  setTimeoutItem(
                    setTimeout(() => {
                      searchSkills({
                        variables: {
                          keyword: value,
                        },
                      });
                    }, 300),
                  );
                }
              }}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  skills: value,
                })
              }
            >
              {!isSearchingSkills ? (
                <>
                  {getSkillsData?.skills?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {`${item.field}/${item.skillDetail}`}
                    </Select.Option>
                  ))}
                </>
              ) : (
                <>
                  {searchSkillsData?.searchSkills?.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {`${item.field}/${item.skillDetail}`}
                    </Select.Option>
                  ))}
                </>
              )}
            </StyledSelect2>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>채용형태</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              placeholder="채용형태 선택"
              showSearch={false}
              filterOption={false}
              value={editingFilter.recruitmentTypes}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  recruitmentTypes: value,
                })
              }
            >
              {recruitmentTypeArray?.map((item) => (
                <Select.Option value={item}>{item}</Select.Option>
              ))}
            </StyledSelect2>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>지원학위</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              showSearch={false}
              filterOption={false}
              placeholder="지원학위 선택"
              value={editingFilter.requiredDegrees}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  requiredDegrees: value,
                })
              }
            >
              {Degree?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </StyledSelect2>
          </PopoverFlexLayout>
          <p
            style={{
              textAlign: 'center',
              fontSize: '12px',
              color: '#fe706f',
              marginTop: '24px',
              marginBottom: '8px',
            }}
          >
            * 적용 버튼을 눌러야 변경사항이 적용됩니다.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{ borderRadius: '10px' }}
              type="primary"
              onClick={() => {
                setIsSearching(false);
                setSearchValue('');
                setIsFilterPopoverOpened(false);
                setFilter(editingFilter);
              }}
            >
              적용
            </Button>
          </div>
        </PopoverLayout>
      </Modal>
    </>
  );
};

export default CompanyTab;
