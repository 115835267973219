import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input, Select, DatePicker } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { BoothStep, JobVacancyStep, Location } from '../../../constants/static';
import { UPDATE_JOB_VACANCY } from '../../../queries/jobVacancy';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import JobVacancy from '../JobVacancy';
import { GET_APPROVED_UNIVERSITIES } from '../../../queries/university';

const { Option } = Select;
const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 783px;
  margin: 24px auto 0;
  padding: 0 18px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 96px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledSelect = styled(Select)`
  margin-left: 15px;
  width: 298px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 783px;
  min-height: 121px !important;
  border-radius: 10px;
  margin: 20px auto;
`;

const CheckLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 135px;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  height: 40px;
  border-radius: 10px;
  width: 298px;
`;

const PublishedUniversities = ({ jobVacancy, isCreating, getJobVacancies }) => {
  const { data: getApprovedUniversitiesData } = useQuery(
    GET_APPROVED_UNIVERSITIES,
  );
  const [editingUniversities, setEditingUniversities] = useState([]);
  const [updateJobVacancy, { data }] = useMutation(UPDATE_JOB_VACANCY);
  useEffect(() => {
    setEditingUniversities(
      jobVacancy?.approvedUniversities?.map((u) => u.id) ?? [],
    );
  }, [jobVacancy]);

  const onClickLeftButton = () => {
    updateJobVacancy({
      variables: {
        id: jobVacancy.id,
        jobVacancyInput: {
          approvedUniversityIds:
            editingUniversities.length === 0
              ? undefined
              : editingUniversities.map((u) => u.id),
          step: JobVacancyStep.recruitmentProcess,
        },
      },
    });
  };
  const onClickRightButton = () => {
    if (isCreating) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            approvedUniversityIds:
              editingUniversities.length === 0
                ? undefined
                : editingUniversities,
            step: JobVacancyStep.complete,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            approvedUniversityIds:
              editingUniversities.length === 0
                ? undefined
                : editingUniversities,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getJobVacancies();
    }
  }, [data]);
  return (
    <Layout>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>지원 가능 학교를 선택해주세요.</p>
      </FlexLayout>
      <Select
        style={{ width: '440px', margin: '40px auto' }}
        value={editingUniversities}
        allowClear
        placeholder="미선택시 모든 학생에게 공개됩니다."
        onChange={(value) => {
          setEditingUniversities(value);
        }}
        mode="multiple"
      >
        {getApprovedUniversitiesData?.approvedUniversities?.map((item) => (
          <Select.Option value={item.id}>{item.name}</Select.Option>
        ))}
      </Select>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={onClickRightButton} type="primary">
              완료
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton onClick={onClickRightButton} type="primary">
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default PublishedUniversities;
