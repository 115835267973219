import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CompanyAdvertisementManage from './CompanyAdvertisementManage';
import CompanyAdvertisementState from './CompanyAdvertisementState';

const CompanyAdvertisement = () => {
  return (
    <Switch>
      <Route exact path="/advertisement-manage/state">
        <CompanyAdvertisementState />
      </Route>
      <Route exact path="/advertisement-manage/manage">
        <CompanyAdvertisementManage />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default CompanyAdvertisement;
