import moment from 'moment';
import { ReservationState, UserType } from './static';

export const decodeAccessToken = (accessToken) => {
  const base64Url = accessToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return jsonPayload;
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const convertDateToState = (
  userType,
  progressStartAt,
  progressEndAt,
  companyApplicationStartAt,
  companyApplicationEndAt,
  studentApplicationStartAt,
  studentApplicationEndAt,
) => {
  const now = moment().startOf('day');
  if (moment(progressStartAt) <= now && now <= moment(progressEndAt)) {
    return 'proceeding';
  }
  switch (userType) {
    case UserType.student:
      if (
        moment(studentApplicationStartAt) <= now &&
        now <= moment(studentApplicationEndAt)
      ) {
        return 'applicationPeriod';
      } else if (moment(progressStartAt) >= now) {
        return 'expected';
      } else {
        return 'end';
      }
    case UserType.company:
      if (
        moment(companyApplicationStartAt) <= now &&
        now <= moment(companyApplicationEndAt)
      ) {
        return 'applicationPeriod';
      } else if (moment(progressStartAt) > now) {
        return 'expected';
      } else {
        return 'end';
      }
    case UserType.counselor:
      if (
        moment(companyApplicationStartAt) <= now &&
        now <= moment(companyApplicationEndAt)
      ) {
        return 'applicationPeriod';
      } else if (moment(progressStartAt) > now) {
        return 'expected';
      } else {
        return 'end';
      }
    default:
      return 'applicationPeriod';
  }
};

export const groupBy = (data, key) => {
  return data.reduce(function (carry, el) {
    var group = el[key];

    if (carry[group] === undefined) {
      carry[group] = [];
    }

    carry[group].push(el);
    return carry;
  }, {});
};

export const convertStateToNumber = (state, startAt, endAt) => {
  switch (state) {
    case ReservationState.proceeding:
      return 0;
    case ReservationState.pending:
      return 1;
    case ReservationState.approved:
      return 2;
    case ReservationState.declined:
      return 3;
    case ReservationState.completed:
      return 4;
    case ReservationState.canceled:
      return 5;
    default:
      return 4;
  }
};

export const timeForToday = (value) => {
  const today = new Date();
  const timeValue = new Date(parseInt(value));

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60,
  );
  if (betweenTime < 1) return '방금전';
  if (betweenTime < 60) {
    return `${betweenTime}분전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 365) {
    return `${betweenTimeDay}일전`;
  }

  return `${Math.floor(betweenTimeDay / 365)}년전`;
};

export const getYoutubeVideoId = (link) => {
  var regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = link?.match(regExp);
  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return false;
  }
};

export const getYoutubeThumbnail = (url, quality) => {
  if (url) {
    var video_id, thumbnail, result;
    if ((result = url?.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url?.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      if (typeof quality == 'undefined') {
        quality = 'max';
      }

      var quality_key = 'maxresdefault'; // Max quality
      if (quality == 'low') {
        quality_key = 'sddefault';
      } else if (quality == 'medium') {
        quality_key = 'mqdefault';
      } else if (quality == 'high') {
        quality_key = 'hqdefault';
      }

      var thumbnail =
        'http://img.youtube.com/vi/' + video_id + '/' + quality_key + '.jpg';
      return thumbnail;
    }
  }
  return false;
};

export const httpUrl = (url) => {
  return url?.match(/^http[s]?:\/\//) ? url : 'http://' + url;
};
