import { gql } from '@apollo/client';

// QUERIES

export const COUNSEL_RESERVATION_BY_STUDENT = gql`
  query CounselReservationByStudent($studentId: ID) {
    counselReservationByStudent(studentId: $studentId) {
      id
      state
      memo
      counsel {
        startAt
        endAt
        booth {
          id
          title
          isOnline
          zoomLink
          j
        }
      }
    }
  }
`;

export const GET_COUNSELORS = gql`
  query GetCounselors($userId: ID) {
    counselors(userId: $userId) {
      id
      email
      name
    }
  }
`;

// MUTATIONS

export const APPROVE_COUNSEL_RESERVATION = gql`
  mutation ApproveCounselReservation($id: ID, $message: String) {
    approveCounselReservation(id: $id, message: $message)
  }
`;

export const DECLINE_COUNSEL_RESERVATION = gql`
  mutation DeclineCounselReservation($id: ID, $message: String) {
    declineCounselReservation(id: $id, message: $message)
  }
`;

export const COMPLETE_COUNSEL_RESERVATION = gql`
  mutation CompleteCounselReservation($id: ID) {
    completeCounselReservation(id: $id)
  }
`;

export const UPDATE_RESERVATION_MEMO = gql`
  mutation UpdateReservationMemo($id: ID, $memo: String) {
    updateReservationMemo(id: $id, memo: $memo) {
      id
    }
  }
`;

export const UPDATE_RESERVATION_STUDENT_MEMO = gql`
  mutation UpdateReservationStudentMemo($id: ID, $studentMemo: String) {
    updateReservationStudentMemo(id: $id, studentMemo: $studentMemo) {
      id
    }
  }
`;

export const MAKE_COUNSEL_RESERVATION = gql`
  mutation MakeCounselReservation($counselId: ID, $userId: ID) {
    makeCounselReservation(counselId: $counselId, userId: $userId) {
      id
    }
  }
`;

export const DELETE_COUNSEL_RESERVATION = gql`
  mutation DeleteCounselReservation($id: ID) {
    deleteCounselReservation(id: $id)
  }
`;

export const DELETE_COUNSELOR = gql`
  mutation DeleteCounselor($id: ID) {
    deleteCounselor(id: $id)
  }
`;

export const CREATE_COUNSELOR = gql`
  mutation CreateCounselor(
    $userId: ID
    $email: String
    $name: String
    $password: String
  ) {
    createCounselor(
      userId: $userId
      email: $email
      name: $name
      password: $password
    ) {
      id
    }
  }
`;

export const SUBSCRIBE_COUNSEL_STATE_CHANGED = gql`
  subscription SubscribeCounselStateChanged($userId: ID) {
    counselStateChanged(userId: $userId)
  }
`;

export const RESEND_COUNSEL_START_MESSAGE = gql`
  mutation ResendCounselStartMessage($id: ID) {
    resendCounselStartMessage(id: $id)
  }
`;
