import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserType } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import { GET_STUDENT_PROFILE } from '../../../queries/student';
import { GET_COMPANY_PROFILE } from '../../../queries/company';
import {
  createSignalIfSupported,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { Button } from 'antd';
import Modal from '../../../components/Modal';
import DeleteAccountModal from './DeleteAccountModal';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import DeleteCounselAccountModal from './DeleteCounselAccountModal';
import CreateCounselAccountModal from './CreateCounselAccountModal';
import ChangingPasswordModal from './ChangePasswordModal';
import { GET_COUNSELORS } from '../../../queries/counsel';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const AccountLayout = styled.div`
  margin: 30px 60px 60px;
  filter: drop-shadow(0px 4px 23px rgba(0, 0, 0, 0.04));
  border-radius: 30px;
  background-color: white;
  padding: 60px 60px 60px 44px;
`;

const CounselLayout = styled.div`
  margin: 30px 60px 60px;
`;

const RowLayout = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;
  p {
    width: 60px;
    margin-right: 38px;
    font-weight: 500;
    margin-left: 16px;
  }
`;

const AddAccountButton = styled(Button)`
  width: 170px;
  height: 47px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 27px;
`;

const CounselItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 23px 47px 23px 68px;
  width: 536px;
  height: 65px;
  font-size: 14px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #ffccc7;
  box-shadow: 0px 4px 23px 0px #0000000a;
  border-radius: 10px;
  p {
    margin-right: 32px;
    width: 66px;
    font-weight: 500;
  }
`;

const Account = () => {
  const { user, setUser } = useAuth();
  const [getStudentProfile, { data: studentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  const [getCounselors, { data: getCounselorsData }] =
    useLazyQuery(GET_COUNSELORS);
  useEffect(() => {
    if (user.userType !== '') {
      if (user.userType === UserType.student) {
        getStudentProfile({
          variables: {
            id: user.id,
          },
        });
      } else {
        getCompanyProfile({
          variables: {
            id: user.id,
          },
        });
      }
    }
  }, [getCompanyProfile, getStudentProfile, user]);
  const [isDeleteAccountModalOpened, setIsDeleteAccountModalOpened] =
    useState(false);
  const [isChangingPasswordModalOpened, setIsChangingPasswordModalOpened] =
    useState(false);
  const [deletingCounselAccount, setDeletingCounselAccount] = useState({
    id: 0,
    email: '',
  });
  const [isCreatingCounselAccount, setIsCreatingCounselAccount] =
    useState(false);
  useEffect(() => {
    if (
      isCreatingCounselAccount === false &&
      user.userType === UserType.company
    ) {
      getCounselors({
        variables: {
          userId: user.id,
        },
      });
    }
  }, [isCreatingCounselAccount, getCounselors, user]);
  useEffect(() => {
    if (deletingCounselAccount.id === 0 && user.userType === UserType.company) {
      getCounselors({
        variables: {
          userId: user.id,
        },
      });
    }
  }, [deletingCounselAccount, getCounselors, user]);
  const history = useHistory();
  return (
    <Layout>
      <TitleText>계정</TitleText>
      <AccountLayout>
        <RowLayout>
          <p>{user.userType === UserType.student ? '이름' : '담당자명'}</p>
          {user.userType === UserType.student
            ? studentProfileData?.student?.basicStudentProfile?.name
            : companyProfileData?.company?.managerInfo?.name}
        </RowLayout>
        <RowLayout>
          <p>이메일</p>
          {user.email}
        </RowLayout>
        <div style={{ marginBottom: '24px' }}>
          <Button
            onClick={() => setIsChangingPasswordModalOpened(true)}
            style={{ color: '#9e9e9e' }}
            type="link"
          >
            비밀번호 변경
          </Button>
        </div>
        <Button
          onClick={() => setIsDeleteAccountModalOpened(true)}
          style={{ color: '#FFA39E' }}
          type="link"
        >
          회원탈퇴
        </Button>
      </AccountLayout>
      {user.userType === UserType.company && (
        <>
          <TitleText>상담 계정</TitleText>
          <CounselLayout>
            <AddAccountButton
              type="primary"
              onClick={() => setIsCreatingCounselAccount(true)}
            >
              상담 계정 추가
              <EditOutlined style={{ fontSize: '18px', marginLeft: '16px' }} />
            </AddAccountButton>
            {getCounselorsData?.counselors?.map((item) => (
              <CounselItem
                onClick={() => {
                  setDeletingCounselAccount({
                    id: item.id,
                    email: item.email,
                  });
                }}
              >
                <p>{item.name}</p>
                {item.email}
                <Button
                  type="link"
                  style={{ marginRight: 0, marginLeft: 'auto' }}
                >
                  <DeleteOutlined
                    style={{ fontSize: '20px', color: '#767676' }}
                  />
                </Button>
              </CounselItem>
            ))}
          </CounselLayout>
        </>
      )}
      <Modal
        isOpen={isDeleteAccountModalOpened}
        onClose={() => setIsDeleteAccountModalOpened(false)}
        width={'464px'}
        height={'455px'}
        radius={'30px'}
      >
        <DeleteAccountModal
          signOut={() => {
            setUser({ id: 0, email: '', userType: '' });
            localStorage.setItem(
              'user',
              JSON.stringify({
                id: 0,
                email: '',
                userType: '',
              }),
            );
            localStorage.setItem('accessToken', '');
            history.push('/');
          }}
          email={user.email}
          onClose={() => setIsDeleteAccountModalOpened(false)}
        />
      </Modal>
      <Modal
        isOpen={deletingCounselAccount.id !== 0}
        onClose={() =>
          setDeletingCounselAccount({
            id: 0,
            email: '',
          })
        }
        width={'464px'}
        height={'455px'}
        radius={'30px'}
      >
        <DeleteCounselAccountModal
          counselAccount={deletingCounselAccount}
          onClose={() =>
            setDeletingCounselAccount({
              id: 0,
              email: '',
            })
          }
        />
      </Modal>
      <Modal
        isOpen={isCreatingCounselAccount}
        onClose={() => setIsCreatingCounselAccount(false)}
        width={'464px'}
        height={'553px'}
        radius={'30px'}
        closeButtonTransparent
      >
        <CreateCounselAccountModal
          onClose={() => setIsCreatingCounselAccount(false)}
        />
      </Modal>
      <Modal
        isOpen={isChangingPasswordModalOpened}
        onClose={() => setIsChangingPasswordModalOpened(false)}
        width={'464px'}
        height={'553px'}
        radius={'30px'}
        closeButtonTransparent
      >
        <ChangingPasswordModal
          onClose={() => setIsChangingPasswordModalOpened(false)}
        />
      </Modal>
    </Layout>
  );
};

export default Account;
