import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ProvideAuth } from './hooks/useAuth';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { createUploadLink } from 'apollo-upload-client';
import { ToastContainer } from 'react-toastify';
import { getMainDefinition } from '@apollo/client/utilities';
import 'react-toastify/dist/ReactToastify.css';
import { isMobile } from 'react-device-detect';

const WrappedToastContainer = ({ className, ...rest }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

const StyledToastContainer = styled(WrappedToastContainer)`
  .Toastify__toast-container {
    top: 84px !important;
    right: ${isMobile ? '32px' : '102px'};
    width: 337px;
    padding: 0px;
    height: 75px;
    z-index: 9;
  }
  .Toastify__toast--default {
    margin-bottom: 12px;
    padding: 0;
    box-shadow: none;
    background-color: #fbfbfb;
    font-size: 14px;
    color: #ffffff;
    border-radius: 10px;
    border: 1px solid #fe706f;
  }
  .Toastify__toast-body {
    display: flex;
    margin: 0px;
    align-items: center;
  }
`;

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    font-family: 'Noto Sans KR', 'Inter', 'Nanum Gothic', 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  * {
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  #root {
    height: 100%;
  }
  textarea {
    background-color: #ffffff;
    &:disabled {
      opacity: 1;
    }
  }
  button {
    background-color: #ffffff;
  }
  input {
    -webkit-appearance: none;
    padding: 0;
    &:disabled {
      opacity: 1;
    }
  }
  .ql-editor .ql-video {
    font-size: 18px !important;
    width: ${isMobile ? '320px' : '640px'};
    height: ${isMobile ? '180px' : '360px'};
  }
  *,
  *::after,
  *::before {
    margin: 0;
    box-sizing: border-box;
  }
  p {
    margin: 0;
    padding: 0;
  }
  @media(max-width: 576px) { 
    .ant-picker-panel {
    &:nth-child(2) {
      width: 0;
      .ant-picker-header {
        position: absolute;
        right: 24px;
        .ant-picker-header-prev-btn, .ant-picker-header-view {
          visibility: hidden;
        }
      }

      .ant-picker-body {
        display: none;
      }

      @media (min-width: 768px) {
        width: 340px!important;
        .ant-picker-header {
          position: relative;
          .ant-picker-header-prev-btn, .ant-picker-header-view {
            visibility: initial;
          }
        }

        .ant-picker-body {
          display: block;
        }
      }
    }
  }
}
`;

const defaultOptions = {
  query: {
    fetchPolicy: 'cache-and-network',
  },
  mutate: {},
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
};

let client;

const httpLink = createUploadLink({
  uri: 'https://www.fairon.co.kr/graphql',
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = new WebSocketLink({
  uri: 'wss://www.fairon.co.kr/subscriptions',
  options: {
    reconnect: true,
  },
});

function splitByOperation({ query }) {
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription'
  );
}

const linkOnError = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      console.log(networkError);
      alert('로그인 유지 기간이 만료되었습니다. 다시 로그인 해주세요.');
      window.location.href = '/sign-out';
    }
  },
);

const link = from([
  linkOnError,
  split(
    // split based on operation type
    splitByOperation,
    wsLink,
    authLink.concat(httpLink),
  ),
]);

client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ProvideAuth>
        <BrowserRouter>
          <GlobalStyle />
          <StyledToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick={true}
            draggable={false}
            pauseOnHover={false}
            closeButton={false}
            pauseOnFocusLoss={false}
          />
          <App />
        </BrowserRouter>
      </ProvideAuth>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
