import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PaperClipOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_STUDENT_PROFILE } from '../../../queries/student';

const Layout = styled.div`
  width: 100%;
  background-color: white;
  padding: 53px 0 31px;
  margin-top: 31px;
  margin-bottom: 13px;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  overflow: scroll;
  height: 100%;
`;

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 600;
    font-size: 17px;
    margin-right: 26px;
  }
  margin-left: 48px;
  button {
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

const BodyLayout = styled.div`
  margin-top: 26px;
  margin-left: 95px;
  white-space: pre-wrap;
  overflow: scroll;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #ededed;
  margin: 35px 0;
`;

const BasicProfileLayout = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 56px;
    display: flex;
    align-items: center;
  }
`;
const BasicProfileTitleText = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin-right: 24px;
`;

const EducationItemLayout = styled.div`
  margin-bottom: 13px;
  display: flex;
  font-weight: 500;

  div:nth-child(1) {
    width: 220px;
    margin-left: 14px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  div:nth-child(2) {
    margin-right: 33px;
  }
`;

const RankItemLayout = styled.div`
  margin-bottom: 22px;
  div:nth-child(1) {
    height: 40px;
    padding: 0 6px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    div:nth-child(1) {
      width: 220px;
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-right: 62px;
    }
    p {
      margin-right: 72px;
    }
  }
`;

const RankItemBodyText = styled.p`
  margin: 15px 95px 0 12px;
`;

const RemovePadddingBodyLayout = styled.div`
  margin-top: 26px;
`;

const ThinDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ededed;
  margin: 26px 0;
`;

const ProjectItemLayout = styled.div`
  padding: 8px 95px 8px 12px;
  margin-left: 95px;
  background-color: #fdfdfd;
  div:nth-child(1) {
    height: 40px;

    font-size: 15px;
    font-weight: 500;
    margin-bottom: 21px;
  }
`;

const ProjectItemTitleLayout = styled.p`
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 16px;
`;

const ProjectItemBottomLayout = styled.div`
  display: flex;
  align-items: center;
  p:nth-child(1) {
    margin-right: 27px;
    font-weight: 500;
  }
  p:nth-child(2) {
    margin-left: auto;
    margin-right: 0;
    font-weight: 500;
  }
`;

const ThesisItemLayout = styled.div`
  padding-right: 95px;
  margin-left: 95px;
`;

const ThesisItemTitleLayout = styled.div`
  min-height: 76px;
  background-color: #fdfdfd;
  padding: 13px 20px;
  font-size: 15px;
  font-weight: 500;
`;

const ThesisItemBodyLayout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  margin-left: 16px;
  p {
    width: 80px;
    font-weight: 500;
  }
`;

const PatentItemLayout = styled.div`
  padding-right: 95px;
  margin-left: 95px;
`;

const PatentItemTitleLayout = styled.div`
  min-height: 76px;
  background-color: #fdfdfd;
  padding: 8px 12px 14px;
  display: flex;
  align-items: top;
  span:nth-child(1) {
    font-size: 15px;
    p {
      margin-bottom: 8px;
    }
    font-weight: 500;
    min-width: 117px;
    margin-right: 12px;
  }
  margin-bottom: 18px;
`;

const PatentItemBottomLayout = styled.div`
  height: 40px;
  padding: 10px 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  div {
    width: 50%;
    display: flex;
    align-items: center;
    p {
      width: 68px;
      margin-right: 10px;
      font-weight: 500;
    }
  }
`;

const PatentItemTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
`;

const AwardItemLayout = styled.div`
  padding-right: 95px;
  margin-left: 95px;
  align-items: top;
  display: flex;
`;

const AwardItemDate = styled.div`
  padding: 8px 12px;
  font-weight: 500;
  margin-right: 20px;
  font-size: 15px;
  width: 100px;
  height: 40px;
  text-align: left;
`;

const AwardItemTitle = styled.p`
  font-weight: 500;
  margin-bottom: 24px;
  margin-top: 10px;
`;

const AwardItemBottomLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 26px;
    font-weight: 500;
  }
`;

const PortfolioItemLayout = styled.div`
  margin-top: 21px;
  display: flex;
  align-items: center;
  p {
    min-width: 188px;
    margin-right: 22px;
  }
  a {
    color: #f88988;
    cursor: pointer;
    text-decoration: none;
  }
`;

const MatchingLayout = styled.div`
  display: flex;
  div {
    margin-right: 56px;
    display: flex;
    margin-bottom: 20px;
  }
`;

const RecruitmentTypeItem = styled(Tag)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  height: 22px;
  border: none;
  color: #000000;
  margin-bottom: 19px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
`;

const SkillItem = styled(Tag)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  height: 22px;
  border: none;
  color: #000000;
  margin-bottom: 19px;
  height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const RecruitInfoItem = styled.div`
  display: flex;
  height: 39px;
  align-items: center;
  p {
    width: 124px;
    margin-right: 6px;
    font-weight: 600;
  }
`;

const ModalBodyLayout = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const StudentProfileModal = ({ id, hideInformation }) => {
  const { data } = useQuery(GET_STUDENT_PROFILE, {
    variables: {
      id: id,
    },
  });
  const profile = useMemo(() => data?.student, [data]);
  const translatedName = useMemo(() => {
    if (!profile?.basicStudentProfile?.name) {
      return '';
    }
    if (profile?.basicStudentProfile?.name.length === 1) {
      return '*';
    }
    if (profile?.basicStudentProfile?.name.length === 2) {
      return `${profile?.basicStudentProfile?.name.charAt(0)}*`;
    }
    if (profile?.basicStudentProfile?.name.length > 2) {
      return `${profile?.basicStudentProfile?.name.charAt(0)}${'*'.repeat(
        profile?.basicStudentProfile?.name.length - 2,
      )}${profile?.basicStudentProfile?.name.charAt(
        profile?.basicStudentProfile?.name.length - 1,
      )}`;
    }
  }, [profile?.basicStudentProfile?.name]);
  if (profile) {
    return (
      <Layout>
        <TitleLayout>
          <p>기본 정보</p>
        </TitleLayout>
        <BodyLayout>
          <BasicProfileLayout>
            <div>
              <BasicProfileTitleText>이름</BasicProfileTitleText>
              {hideInformation
                ? translatedName
                : profile?.basicStudentProfile?.name}
            </div>
            <div>
              <BasicProfileTitleText>전화번호</BasicProfileTitleText>
              {hideInformation
                ? '010-****-****'
                : profile?.basicStudentProfile?.phoneNumber}
            </div>
            <div>
              <BasicProfileTitleText>이메일</BasicProfileTitleText>
              {hideInformation
                ? `${profile?.email?.charAt(0)}${'*'.repeat(
                    profile?.email?.split('@')[0].length - 1,
                  )}@${profile?.email?.split('@')[1]}`
                : profile?.email}
            </div>
          </BasicProfileLayout>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>매칭 정보</p>
        </TitleLayout>
        <BodyLayout>
          <MatchingLayout>
            <div>
              <BasicProfileTitleText style={{ minWidth: '100px' }}>
                매칭 서비스 이용
              </BasicProfileTitleText>
              {profile?.onMatching ? 'ON' : 'OFF'}
            </div>
            <div>
              <BasicProfileTitleText style={{ minWidth: '84px' }}>
                한줄 소개
              </BasicProfileTitleText>
              {profile?.briefIntroduction}
            </div>
          </MatchingLayout>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>자기소개</p>
        </TitleLayout>
        <BodyLayout>
          <p style={{ marginRight: '95px' }}>{profile?.introduction}</p>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>학력</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.educations
            ?.filter(
              (item) =>
                item.startAt !== '' &&
                item.university !== '' &&
                item.major !== '' &&
                item.degree !== '',
            )
            .map((education, index) => (
              <EducationItemLayout key={index}>
                <div>
                  {education.startAt}
                  <SwapRightOutlined
                    style={{ fontSize: '16px', margin: '0 8px' }}
                  />
                  {education.endAt === '' ? '진행중' : education.endAt}
                </div>
                <div>
                  {education.university} {education.major}
                </div>
                <div>{education.degree}</div>
              </EducationItemLayout>
            ))}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>연구실 정보</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.labInfo && (
            <p>{`${profile?.labInfo?.approvedUniversity?.name ?? ''} ${
              profile?.labInfo?.major ?? ''
            } ${profile?.labInfo?.labNameKR ?? ''}${
              profile?.labInfo?.labNameEN
                ? `(${profile?.labInfo?.labNameEN})`
                : ''
            } | ${profile?.labInfo?.professorName ?? ''}${
              profile?.labInfo?.website ? `(${profile?.labInfo?.website})` : ''
            }`}</p>
          )}
          {profile?.temporaryLabInfo && (
            <p>{`${profile?.temporaryLabInfo?.approvedUniversity?.name ?? ''} ${
              profile?.temporaryLabInfo?.major ?? ''
            } ${profile?.temporaryLabInfo?.labNameKR ?? ''}${
              profile?.temporaryLabInfo?.labNameEN
                ? `(${profile?.temporaryLabInfo?.labNameEN})`
                : ''
            }) | ${
              profile?.temporaryLabInfo?.professorName
                ? `(${profile?.temporaryLabInfo?.professorName})`
                : ``
            }${
              profile?.temporaryLabInfo?.website
                ? `(${profile?.temporaryLabInfo?.website})`
                : ''
            }`}</p>
          )}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>경력</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.careers
            ?.filter((item) => item.startAt !== '' && item.task !== '')
            .map((career, index) => (
              <RankItemLayout key={index}>
                <div>
                  <div>
                    {career.startAt}
                    <SwapRightOutlined
                      style={{ fontSize: '16px', margin: '0 8px' }}
                    />
                    {career.endAt === '' ? '진행중' : career.endAt}
                  </div>
                  <p>
                    {career.companyName} {career.team}
                  </p>
                  {career.rank}
                </div>
                <RankItemBodyText>{career.task}</RankItemBodyText>
              </RankItemLayout>
            ))}
        </BodyLayout>

        <Divider />
        <TitleLayout>
          <p>연구과제</p>
        </TitleLayout>
        <RemovePadddingBodyLayout>
          {profile?.projects
            ?.filter(
              (item) =>
                item.startAt !== '' && item.endAt !== '' && item.title !== '',
            )
            .map((project, index) => (
              <div key={index}>
                <ProjectItemLayout>
                  <div>
                    {project.startAt}
                    <SwapRightOutlined
                      style={{ fontSize: '16px', margin: '0 8px' }}
                    />
                    {project.endAt}
                  </div>
                  <ProjectItemTitleLayout>
                    {project.title}
                  </ProjectItemTitleLayout>
                  <ProjectItemBottomLayout>
                    <p>수행기관</p>
                    {project.partyOfPerformance}
                    <p>{project.isDomestic ? '국내' : '해외'}</p>
                  </ProjectItemBottomLayout>
                </ProjectItemLayout>
                {index < profile?.careers?.length - 1 && <ThinDivider />}
              </div>
            ))}
        </RemovePadddingBodyLayout>
        <Divider />
        <TitleLayout>
          <p>논문</p>
        </TitleLayout>
        <RemovePadddingBodyLayout>
          {profile?.theses
            ?.filter((item) => item.title !== '')
            .map((thesis, index) => (
              <div key={index}>
                <ThesisItemLayout>
                  <ThesisItemTitleLayout>{thesis.title}</ThesisItemTitleLayout>
                  <ThesisItemBodyLayout>
                    <p>{thesis.publishedAt}</p>
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>저자구분</p>
                    {thesis.authorClassification}
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>저자</p>
                    {thesis.author}
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>저널구분</p>
                    {thesis.journalClassification}
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>저널명</p>
                    {thesis.journal}
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>Vol.</p>
                    {thesis.vol}
                  </ThesisItemBodyLayout>
                  <ThesisItemBodyLayout>
                    <p>IF</p>
                    {thesis.impactFactor}
                  </ThesisItemBodyLayout>
                </ThesisItemLayout>
                {index < profile?.theses?.length - 1 && <ThinDivider />}
              </div>
            ))}
        </RemovePadddingBodyLayout>
        <Divider />
        <TitleLayout>
          <p>특허</p>
        </TitleLayout>
        <RemovePadddingBodyLayout>
          {profile?.patents
            ?.filter((item) => item.title !== '')
            ?.map((patent, index) => (
              <div key={index}>
                <PatentItemLayout>
                  <PatentItemTitleLayout>
                    <span>
                      <p>{patent.registeredAt}</p>
                      {patent.section === '' ? '-' : patent.section}
                    </span>
                    <PatentItemTitle>{patent.title}</PatentItemTitle>
                  </PatentItemTitleLayout>
                  <PatentItemBottomLayout>
                    <div>
                      <p>등록번호</p>
                      {patent.registrationNumber}
                    </div>
                    <div>
                      <p>국가</p>
                      {patent.country}
                    </div>
                  </PatentItemBottomLayout>
                  <PatentItemBottomLayout>
                    <div>
                      <p>발명자</p>
                      {patent.inventor}
                    </div>
                    <div>
                      <p>출원인</p>
                      {patent.applicant}
                    </div>
                  </PatentItemBottomLayout>
                </PatentItemLayout>
                {index < profile?.patent?.length - 1 && <ThinDivider />}
              </div>
            ))}
        </RemovePadddingBodyLayout>
        <Divider />
        <TitleLayout>
          <p>수상 / 자격증 / 외국어</p>
        </TitleLayout>
        <RemovePadddingBodyLayout>
          {profile?.awards
            ?.filter((item) => item.title !== '')
            ?.map((award, index) => (
              <div key={index}>
                <AwardItemLayout>
                  <AwardItemDate>
                    {award.awardedAt === '' ? '-' : award.awardedAt}
                  </AwardItemDate>
                  <div>
                    <AwardItemTitle>
                      {award.title === '' ? '-' : award.title}
                    </AwardItemTitle>
                    <AwardItemBottomLayout>
                      <p>발행기관</p>
                      {award.issuer}
                    </AwardItemBottomLayout>
                  </div>
                </AwardItemLayout>
                {index < profile?.patent?.length - 1 && <ThinDivider />}
              </div>
            ))}
        </RemovePadddingBodyLayout>
        <Divider />
        <TitleLayout>
          <p>포트폴리오</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.portfolios
            ?.filter((item) => item.name !== '')
            ?.map((portfolio, index) => {
              return (
                <PortfolioItemLayout>
                  <p>{portfolio.name}</p>
                  {portfolio.file !== undefined && portfolio.file !== null && (
                    <>
                      <PaperClipOutlined
                        style={{
                          fontSize: '20px',
                          marginRight: '8px',
                          color: '#8c8c8c',
                        }}
                      />
                      <a
                        href={portfolio.file?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {portfolio.file?.name}
                      </a>
                    </>
                  )}
                  {portfolio.url !== null || portfolio.url !== undefined ? (
                    <a
                      href={
                        portfolio.url === null || portfolio.url === undefined
                          ? portfolio.file?.url
                          : portfolio.url
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {portfolio.url.substring(
                        portfolio.url.lastIndexOf('/') + 1,
                      )}
                    </a>
                  ) : (
                    <div />
                  )}
                </PortfolioItemLayout>
              );
            })}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>보유스킬</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.skills?.map((skill, index) => (
            <SkillItem key={index}>
              {skill.field} / {skill.skillDetail}
            </SkillItem>
          ))}
          {profile?.temporarySkills?.map((skill, index) => (
            <SkillItem key={index}>
              기타연구분야 / {skill.skillDetail}
            </SkillItem>
          ))}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>채용 희망 형태</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.recruitmentTypes?.map((type, index) => (
            <RecruitmentTypeItem key={index}>{type}</RecruitmentTypeItem>
          ))}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>채용 관련 정보</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.recruitmentInfo?.willJoinAt && (
            <RecruitInfoItem>
              <p>입사 예정 시기</p>
              {profile?.recruitmentInfo?.willJoinAt}
            </RecruitInfoItem>
          )}
          {profile?.recruitmentInfo?.expectedSalary && (
            <RecruitInfoItem>
              <p>희망 연봉</p>
              {profile?.recruitmentInfo?.expectedSalary} 만원
            </RecruitInfoItem>
          )}
          {(profile?.recruitmentInfo?.emailContact ||
            profile?.recruitmentInfo?.phoneContact) && (
            <RecruitInfoItem>
              <p>선호 연락 방법</p>
              {profile?.recruitmentInfo?.emailContact && '이메일'}{' '}
              {profile?.recruitmentInfo?.emailContact &&
                profile?.recruitmentInfo?.phoneContact &&
                '/'}{' '}
              {profile?.recruitmentInfo?.phoneContact && '핸드폰'}
            </RecruitInfoItem>
          )}
        </BodyLayout>
        <div style={{ height: '32px' }} />
      </Layout>
    );
  }
  return <div />;
};

export default StudentProfileModal;
