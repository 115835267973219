import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input, Checkbox } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  position: absolute;
  top: 120px;
  right: 56px;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  &:disabled {
    cursor: default;
  }
  z-index: 100;
`;

const FlexLayout = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 5px 74px 5px 94px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
`;

const StyledRangePicker = styled(RangePicker)`
  height: 40px;
  border-radius: 10px;
  width: 320px;
`;

const CompanyInput = styled(Input)`
  border-radius: 10px;
  width: 162px;
  font-size: 14px;
  height: 40px;
  margin-left: 11px;
`;

const TeamInput = styled(Input)`
  border-radius: 10px;
  width: 162px;
  font-size: 14px;
  height: 40px;
  margin-left: 11px;
`;

const RankInput = styled(Input)`
  border-radius: 10px;
  width: 162px;
  font-size: 14px;
  height: 40px;
  margin-left: 11px;
  margin-right: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const TextboxLayout = styled.div`
  padding: 0 94px;
  margin-top: 16px;
  p {
    font-weight: 500;
    font-size: 14px;
    color: #3a3a3a;
    margin-bottom: 15px;
  }
  textarea {
    height: 120px;
    border-radius: 10px;
  }
`;

const ItemLayout = styled.div`
  position: relative;
  padding: 5px 5px 16px 5px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const Career = ({ career, updateCareer, deleteCareer, isDeletable }) => {
  const [proceeding, setProceeding] = useState(false);
  useEffect(() => {
    if (career.startAt !== '' && career.endAt === '') {
      setProceeding(true);
    }
  }, [career]);
  useEffect(() => {
    if (proceeding) {
      updateCareer({
        startAt: career.startAt,
        endAt: '',
        companyName: career.companyName,
        team: career.team,
        rank: career.rank,
        task: career.task,
      });
    }
  }, [proceeding]);
  return (
    <ItemLayout isDeletable={isDeletable}>
      <FlexLayout>
        {proceeding ? (
          <StyledDatePicker
            placeholder={'시작년월'}
            picker="month"
            value={career.startAt !== '' ? moment(career.startAt) : undefined}
            onChange={(e) => {
              updateCareer({
                startAt: e ? moment(e).format('YYYY-MM') : '',
                endAt: career.endAt,
                companyName: career.companyName,
                team: career.team,
                rank: career.rank,
                task: career.task,
              });
            }}
          />
        ) : (
          <StyledRangePicker
            placeholder={['시작년월', '종료년월']}
            picker="month"
            value={[
              career.startAt !== '' ? moment(career.startAt) : undefined,
              career.endAt !== '' ? moment(career.endAt) : undefined,
            ]}
            onChange={(e) => {
              updateCareer({
                startAt: e ? moment(e[0]).format('YYYY-MM') : '',
                endAt: e ? moment(e[1]).format('YYYY-MM') : '',
                companyName: career.companyName,
                team: career.team,
                rank: career.rank,
                task: career.task,
              });
            }}
          />
        )}

        <Checkbox
          style={{ margin: '8px 0 0 16px', minWidth: '72px' }}
          onClick={(e) => setProceeding(e.target.checked)}
          checked={proceeding}
        >
          진행중
        </Checkbox>
        <CompanyInput
          value={career.companyName}
          placeholder="회사"
          onChange={(e) =>
            updateCareer({
              startAt: career.startAt,
              endAt: career.endAt,
              companyName: e.currentTarget.value,
              team: career.team,
              rank: career.rank,
              task: career.task,
            })
          }
        />
        <TeamInput
          value={career.team}
          placeholder="부서/팀"
          onChange={(e) =>
            updateCareer({
              startAt: career.startAt,
              endAt: career.endAt,
              companyName: career.companyName,
              team: e.currentTarget.value,
              rank: career.rank,
              task: career.task,
            })
          }
        />
        <RankInput
          value={career.rank}
          placeholder="직급"
          onChange={(e) =>
            updateCareer({
              startAt: career.startAt,
              endAt: career.endAt,
              companyName: career.companyName,
              team: career.team,
              rank: e.currentTarget.value,
              task: career.task,
            })
          }
        />
      </FlexLayout>
      <TextboxLayout>
        <p>상세 업무 내용</p>
        <TextArea
          value={career.task}
          placeholder="업무 내용을 입력해주세요"
          onChange={(e) =>
            updateCareer({
              startAt: career.startAt,
              endAt: career.endAt,
              companyName: career.companyName,
              team: career.team,
              rank: career.rank,
              task: e.currentTarget.value,
            })
          }
        />
      </TextboxLayout>
      <DeleteRowButton disabled={!isDeletable} onClick={deleteCareer}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </ItemLayout>
  );
};
const Careers = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [careers, setCareers] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.careers?.length > 0) {
      profile?.careers?.forEach((career) => {
        setCareers((previousCareers) => {
          return [
            ...previousCareers,
            {
              startAt: career.startAt,
              endAt: career.endAt,
              companyName: career.companyName,
              team: career.team,
              rank: career.rank,
              task: career.task,
            },
          ];
        });
      });
    } else {
      setCareers([
        {
          startAt: '',
          endAt: '',
          companyName: '',
          team: '',
          rank: '',
          task: '',
        },
      ]);
    }
  }, [profile]);
  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.awards,
          careers: careers,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.portfolios,
          careers: careers,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <BodyLayout>
        {careers.map((career, index) => (
          <>
            <Career
              key={index}
              isDeletable={careers.length > 1}
              deleteCareer={() =>
                setCareers((previousCareers) =>
                  previousCareers.filter((_, prevIndex) => index !== prevIndex),
                )
              }
              career={career}
              updateCareer={(newCareer) =>
                setCareers((prevCareers) => {
                  return prevCareers.map((prevCareer, previousIndex) =>
                    previousIndex === index ? newCareer : prevCareer,
                  );
                })
              }
            />
            {index + 1 !== careers.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setCareers((prevCareers) => {
            return [
              ...prevCareers,
              {
                startAt: '',
                endAt: '',
                companyName: '',
                team: '',
                rank: '',
                task: '',
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Careers;
