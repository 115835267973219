import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IssuesCloseOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { APPLY_FAIR, GET_FAIR } from '../../../queries/fair';
import { Select, Button, Checkbox } from 'antd';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin-left: 151px;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const TextLayout = styled.div`
  width: 670px;
  height: 142px;
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid #fe706f;
  padding: 16px;
  margin-left: 151px;
  margin-top: 16px;
  white-space: pre-wrap;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 43px;
  margin-left: 163px;
`;
const PolicyAgreement = ({
  editingApplication,
  setEditingApplication,
  showSuccessModal,
}) => {
  const { data } = useQuery(GET_FAIR, {
    variables: {
      id: editingApplication.fairId,
    },
  });
  const [applyFair, { data: applyFairData }] = useMutation(APPLY_FAIR);

  useEffect(() => {
    if (applyFairData) {
      setEditingApplication(null);
      showSuccessModal();
    }
  }, [applyFairData, setEditingApplication]);
  return (
    <Layout>
      <TitleLaout>
        <IssuesCloseOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>박람회 참가 규정</p>
      </TitleLaout>
      <TextLayout>{data?.fair?.policy}</TextLayout>
      <StyledCheckbox
        checked={editingApplication?.policyAgreement}
        onChange={(e) =>
          setEditingApplication({
            ...editingApplication,
            policyAgreement: e.target.checked,
          })
        }
      >
        박람회 참가 규정을 읽고, 이에 동의합니다.
      </StyledCheckbox>
      <ButtonLayout>
        <BottomLeftButton
          onClick={() =>
            setEditingApplication({ ...editingApplication, step: 2 })
          }
          type="primary"
        >
          이전
        </BottomLeftButton>
        <BottomRightButton
          onClick={() =>
            applyFair({
              variables: {
                fairApplicationInput: {
                  fairId: editingApplication.fairId,
                  taxBillEmail: editingApplication.taxBillEmail,
                  orders: editingApplication.orders.map((item) => ({
                    applicationDates: item.applicationDates,
                    paymentPlanId: item.paymentPlan.id,
                    number: item.number,
                  })),
                  totalPrice: editingApplication.orders.reduce(
                    (a, c) =>
                      a +
                      (c.paymentPlan?.price ?? 0) *
                        (c.number ?? 0) *
                        c.applicationDates.length,
                    0,
                  ),
                  policyAgreement: editingApplication.policyAgreement,
                  registrationFileId:
                    editingApplication.businessRegistration?.id,
                },
              },
            })
          }
          disabled={editingApplication.policyAgreement === false}
          type="primary"
        >
          완료
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default PolicyAgreement;
