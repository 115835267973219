import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Color } from '../constants/color';
import useAuth from '../hooks/useAuth';
import BackgroundStudent from '../img/SidemenuBackgroundStudent.png';
import BackgroundCompany from '../img/SidemenuBackgroundCompany.png';
import Profile from '../img/SidemenuProfile.png';
import Icon from './Icon';
import { Menus, UserType } from '../constants/static';

const Layout = styled.div`
  position: relative;
  width: 259px;
  position: fixed;
  top: 64;
  height: calc(100vh - 64px);
  background-color: ${Color.Primary};
  box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 0px;
  padding: 30px 0;
  z-index: 2;
  overflow: scroll;
`;

const BackgroundImg = styled.img`
  position: absolute;
  bottom: 0px;
  left: 3px;
  height: 150px;
  z-index: 0;
`;

const BodyLayout = styled.div`
  position: absolute;
  z-index: 2;
`;

const EmailLayout = styled.div`
  display: flex;
  color: #ffffff;
  font-size: 13px;
  align-items: center;
  margin-left: 22px;
  img {
    width: 35px;
    height: 35px;
    margin-right: 11px;
  }
  margin-bottom: 32px;
`;

const MenuTitleLayout = styled.div`
  background: ${(props) => (props.selected ? 'rgba(255, 255, 255, 0.21)' : '')};
  border-radius: 55px;
  width: 219px;
  height: 51px;
  display: flex;
  align-items: center;
  margin: 9px 0;
  margin-left: 22px;
  div {
    width: 51px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 51px;
    background-color: ${(props) => (props.selected ? '#ffffff' : '')};
  }
  p {
    font-size: 15px;
    margin: 0 0 0 23px;
    color: white;
    font-weight: ${(props) => (props.selected ? 700 : 400)};
  }
`;

const ChildMenu = styled.button`
  padding: 12px 0px 12px 99px;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background-color: transparent;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  text-align: left;
  &:hover {
    background-color: #fb605f;
  }
`;

const Sidemenu = () => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const pathArray = useMemo(() => {
    return location.pathname.split('/');
  }, [location]);
  const [selectedMenu, setSelectedMenu] = useState('');
  useEffect(() => {
    if (pathArray.length > 0) {
      setSelectedMenu(pathArray[1]);
    }
  }, [pathArray]);
  return (
    <Layout>
      {user.userType !== UserType.company && (
        <BackgroundImg
          src={
            user.userType === UserType.student
              ? BackgroundStudent
              : BackgroundCompany
          }
          alt="background"
        />
      )}
      <BodyLayout>
        <EmailLayout>
          <img src={Profile} alt="profile" />
          {user.email}
        </EmailLayout>
        {Menus[user.userType]?.map((mainMenu) => (
          <div
            style={{ cursor: 'pointer' }}
            key={mainMenu.selectedPathname}
            onClick={() => {
              history.push(mainMenu.childerenMenus?.[0]?.url);
            }}
          >
            <MenuTitleLayout
              selected={pathArray[1] === mainMenu.selectedPathname}
            >
              <div>
                <Icon
                  icon={`${mainMenu.icon}${
                    pathArray[1] === mainMenu.selectedPathname
                      ? 'Primary'
                      : 'White'
                  }`}
                  size={20}
                  color={'none'}
                />
              </div>
              <p>{mainMenu.name}</p>
            </MenuTitleLayout>
            {selectedMenu === mainMenu.selectedPathname && (
              <>
                {mainMenu.childerenMenus.map((childrenMenu) => (
                  <ChildMenu
                    key={childrenMenu.url}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(childrenMenu.url);
                    }}
                    selected={
                      pathArray.length > 2 &&
                      pathArray[1] + '/' + pathArray[2] ===
                        childrenMenu.url.substring(1)
                    }
                  >
                    {childrenMenu.name}
                  </ChildMenu>
                ))}
              </>
            )}
          </div>
        ))}
        <div style={{ height: '24px' }} />
      </BodyLayout>
    </Layout>
  );
};

export default Sidemenu;
