import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CompanyProfileStep } from '../../../../constants/static';
import { UPDATE_COMPANY_PROFILE } from '../../../../queries/company';
import InputBig from '../../../../components/InputBig';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 72px;
  &:disabled {
    cursor: default;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  padding: 5px 50px 5px 86px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Qna = ({ qna, updateQna, deleteQna, isDeletable }) => {
  return (
    <FlexLayout isDeletable={isDeletable}>
      <div style={{ flex: 1 }}>
        <InputBig
          name={'question'}
          value={qna.question}
          placeholder={'질문을 입력하세요'}
          onChange={(e) =>
            updateQna({
              question: e.currentTarget.value,
              answer: qna.answer,
            })
          }
          label="질문"
        />
        <Spacer />
        <InputBig
          name={'answer'}
          value={qna.answer}
          placeholder={'답변을 입력하세요'}
          onChange={(e) =>
            updateQna({
              question: qna.question,
              answer: e.currentTarget.value,
            })
          }
          label="답변"
        />
      </div>
      <div style={{ width: '22px' }} />
      <DeleteRowButton disabled={!isDeletable} onClick={deleteQna}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </FlexLayout>
  );
};
const Qnas = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [qnas, setQnas] = useState([]);
  const [updateCompanyProfile, { data }] = useMutation(UPDATE_COMPANY_PROFILE);
  useEffect(() => {
    if (profile?.qnas?.length > 0) {
      profile?.qnas?.forEach((qna) =>
        setQnas((previousQnas) => {
          return [
            ...previousQnas,
            {
              question: qna.question,
              answer: qna.answer,
            },
          ];
        }),
      );
    } else {
      setQnas([
        {
          question: '',
          answer: '',
        },
      ]);
    }
  }, [profile]);
  const handleClickLeftButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: CompanyProfileStep.urls,
          qnas: qnas,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: isModal
            ? CompanyProfileStep.complete
            : CompanyProfileStep.complete,
          qnas: qnas,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <BodyLayout>
        {qnas.map((qna, index) => (
          <>
            <Qna
              key={index}
              isDeletable={qnas.length > 1}
              deleteQna={() =>
                setQnas((previousQnas) =>
                  previousQnas.filter((_, prevIndex) => index !== prevIndex),
                )
              }
              qna={qna}
              updateQna={(newQna) =>
                setQnas((previousQnas) => {
                  return previousQnas.map((previousQna, previousIndex) =>
                    previousIndex === index ? newQna : previousQna,
                  );
                })
              }
            />
            {index + 1 !== qnas.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setQnas((prevQnas) => {
            return [
              ...prevQnas,
              {
                question: '',
                answer: '',
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton onClick={handleClickRightButton} type="primary">
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              완료
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Qnas;
