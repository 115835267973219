import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input, Select } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  position: absolute;
  top: 56px;
  right: 56px;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
  z-index: 100;
`;

const ItemLayout = styled.div`
  position: relative;
  padding: 5px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const FlexLayout = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 5px 80px 5px 116px;
  margin-right: 36px;
`;

const StyledRangePicker = styled(RangePicker)`
  height: 40px;
  border-radius: 10px;
`;

const TitleInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
`;

const PartyOfPerformanceInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 6px;
`;

const StyledSelect = styled(Select)`
  margin-right: 6px;
  div {
    height: 40px !important;
    border-radius: 10px !important;
    padding-top: 4px !important;
    input {
      margin-top: 4px !important;
    }
  }
  width: 110px;
  border-radius: 10px;
  height: 40px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Project = ({ project, updateProject, deleteProject, isDeletable }) => {
  return (
    <ItemLayout isDeletable={isDeletable}>
      <FlexLayout>
        <StyledSelect
          placeholder="해외여부"
          value={
            project.isDomestic === undefined
              ? undefined
              : project.isDomestic
              ? '국내'
              : '해외'
          }
          onChange={(value) =>
            updateProject({
              isDomestic: value === '국내',
              title: project.title,
              partyOfPerformance: project.partyOfPerformance,
              startAt: project.startAt,
              endAt: project.endAt,
            })
          }
        >
          <Option value="해외">해외</Option>
          <Option value="국내">국내</Option>
        </StyledSelect>
        <TitleInput
          value={project.title}
          placeholder="연구제목"
          onChange={(e) =>
            updateProject({
              isDomestic: project.isDomestic,
              title: e.currentTarget.value,
              partyOfPerformance: project.partyOfPerformance,
              startAt: project.startAt,
              endAt: project.endAt,
            })
          }
        />
      </FlexLayout>
      <FlexLayout>
        <PartyOfPerformanceInput
          value={project.partyOfPerformance}
          placeholder="수행기관"
          onChange={(e) =>
            updateProject({
              isDomestic: project.isDomestic,
              title: project.title,
              partyOfPerformance: e.currentTarget.value,
              startAt: project.startAt,
              endAt: project.endAt,
            })
          }
        />
        <StyledRangePicker
          placeholder={['시작년월', '종료년월']}
          picker="month"
          value={[
            project.startAt !== '' ? moment(project.startAt) : undefined,
            project.endAt !== '' ? moment(project.endAt) : undefined,
          ]}
          onChange={(e) => {
            updateProject({
              isDomestic: project.isDomestic,
              title: project.title,
              partyOfPerformance: project.partyOfPerformance,
              startAt: e ? moment(e[0]).format('YYYY-MM') : '',
              endAt: e ? moment(e[1]).format('YYYY-MM') : '',
            });
          }}
        />
      </FlexLayout>
      <DeleteRowButton disabled={!isDeletable} onClick={deleteProject}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </ItemLayout>
  );
};

const Projects = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [projects, setProjects] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.projects?.length > 0) {
      profile?.projects?.forEach((project) => {
        setProjects((previousProjects) => {
          return [
            ...previousProjects,
            {
              isDomestic: project.isDomestic,
              title: project.title,
              partyOfPerformance: project.partyOfPerformance,
              startAt: project.startAt,
              endAt: project.endAt,
            },
          ];
        });
      });
    } else {
      setProjects([
        {
          isDomestic: true,
          title: '',
          partyOfPerformance: '',
          startAt: '',
          endAt: '',
        },
      ]);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.skills,
          projects: projects,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.theses,
          projects: projects,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <BodyLayout>
        {projects.map((project, index) => (
          <>
            <Project
              key={index}
              isDeletable={projects.length > 1}
              deleteProject={() =>
                setProjects((previousProjects) =>
                  previousProjects.filter(
                    (_, prevIndex) => index !== prevIndex,
                  ),
                )
              }
              project={project}
              updateProject={(newProject) =>
                setProjects((previousProjects) => {
                  return previousProjects.map(
                    (previousProject, previousIndex) =>
                      previousIndex === index ? newProject : previousProject,
                  );
                })
              }
            />
            {index + 1 !== projects.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setProjects((prevProjects) => {
            return [
              ...prevProjects,
              {
                isDomestic: true,
                title: '',
                partyOfPerformance: '',
                startAt: '',
                endAt: '',
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Projects;
