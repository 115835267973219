import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CounselHistory from './History';
import { Typography } from 'antd';
import useAuth from '../../hooks/useAuth';
import { UserType } from '../../constants/static';
import ManageCounsel from '../BoothAndCounsel/ManageCounsel/ManageCounsel';
import { isMobile } from 'react-device-detect';
import MobileDisabled from '../MobileDisabled';

const Counsel = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route exact path="/counsel/history">
        <CounselHistory />
      </Route>
      <Route path="/counsel/manage-counsel">
        {isMobile ? <MobileDisabled /> : <ManageCounsel />}
      </Route>
      <Route path="/counsel/manage-meeting">
        <Typography.Title level={3} style={{ margin: '40px' }}>
          준비중인 페이지입니다.
        </Typography.Title>
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Counsel;
