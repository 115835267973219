import { UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CompanyProfileModal from '../../components/CompanyProfileModal';
import Modal from '../../components/Modal';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { isMobile } from 'react-device-detect';
import {
  GET_QUESTIONS_BY_STUDENT,
  READ_QUESTION_BY_STUDENT,
  REPLY_QUESTION,
} from '../../queries/matching';

const Layout = styled.div`
  padding: ${isMobile ? '50px 0 24px' : '50px 50px 24px 61px'};
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: ${isMobile ? '16px' : '24px'};
  margin-left: ${isMobile ? '30px' : ''};
`;

const BodyLayout = styled.div`
  margin: ${isMobile ? '24px 15px 0' : '29px 60px 0'};
`;

const QnaItemLayout = styled.div`
  margin-bottom: 16px;
  max-width: 800px;
  display: flex;
  cursor: pointer;
  height: 130px;
  padding: ${isMobile ? '8px 14px 17px 12px' : '16px 37px 22px 15px'};
  border-radius: 10px;
  background-color: white;
  border: ${(props) =>
    !props.checked ? '1.5px solid #fe706f' : '1.5px solid #aaaaaa'};
`;

const QnaTitleLayout = styled.div`
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: ${isMobile ? '4px' : '15px'};
  div {
    border-radius: 10px;
    background-color: white;
    padding: 5px 16px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    border: 1px solid #d9d9d9;
    text-align: left;
    color: #262626;
    margin-left: 24px;
  }
`;

const QnaBodyLayout = styled.div`
  display: flex;
`;

const QuestionLayout = styled.div`
  display: flex;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.0032em;
  text-align: left;
  p {
    max-width: 553px;
    margin-left: 12px;
    word-break: break-all;
    margin-right: 16px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    flex: ${isMobile ? 1 : 1};
    min-width: ${isMobile ? '124px' : ''};
    line-height: 16px;
    letter-spacing: -0.0032em;
    text-align: left;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: ${isMobile ? '4.2em' : '1.4em'};
    line-height: 1.4em;
    display: -webkit-box;
    -webkit-line-clamp: ${isMobile ? 3 : 1};
    -webkit-box-orient: vertical;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const StatusText = styled.p`
  text-align: ${isMobile ? 'center !important' : 'left'};
  width: ${isMobile ? '' : '50px !important'};
  min-width: 50px !important;
  margin-top: ${isMobile ? '19px' : '-12px'};
  margin-right: ${isMobile ? '' : '44px'};
  font-family: Noto Sans KR;
  font-size: 15px;
  color: ${(props) => (!props.checked ? '#fe706f' : '#aaaaaa')};
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const RightButton = styled.button`
  cursor: pointer;
  height: 32px;
  margin-top: -16px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 95px !important;
`;

const ModalLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '892px'};
  height: 527px;
`;
const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  top: 92px;
  padding-bottom: 60px;
  z-index: 0;
  overflow-y: scroll;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const QnaModalLayout = styled.div`
  display: flex;
  flex-flow: column;
  width: ${isMobile ? 'calc(100vw - 32px)' : '892px'};
  height: 527px;
`;

const QnaModalHeader = styled.div`
  min-height: 105px;
  width: 100%;
  background-color: #fff1f0;
  padding: 36px 50px 14px;
  display: flex;
  flex-flow: column;
  div {
    display: flex;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.0032em;
    text-align: left;
    p {
      width: 700px;
      margin-left: 12px;
      font-family: Noto Sans KR;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.0032em;
      text-align: left;
      white-space: pre-wrap;
    }
  }
  span {
    margin-top: auto;
    margin-bottom: 0;
    margin-left: 640px;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }
`;

const MobileQuestionHeader = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-flow: column;
  padding-right: 24px;
  div {
    display: flex;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    line-height: 16px;
    letter-spacing: -0.0032em;
    text-align: left;
    p {
      margin-left: 12px;
      font-family: Noto Sans KR;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.0032em;
      text-align: left;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
  span {
    margin-top: 24px;
    margin-bottom: 32px;
    margin-right: 0;
    margin-left: auto;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #767676;
  }
`;

const QnaModalBody = styled.div`
  flex: 1;
  overflow: scroll;
`;

const QnaTextArea = styled(Input.TextArea)`
  margin-right: ${isMobile ? '16px' : '44px'};
  width: ${isMobile ? '208px' : '664px'};
  height: 83px !important;
  border-radius: 10px;
`;

const QnaSendButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  margin-top: 32px;
`;

const QnaModalBottom = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  height: 128px;
  border-top: 1px solid #f0f0f0;
  padding: ${isMobile ? '16px' : '17px 38px 28px 50px'};
  display: flex;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const QnaItem = styled.div`
  margin: ${(props) =>
    props.type === 'question' ? '0 auto 16px 0' : '0 0 16px auto'};
  display: flex;
  align-items: flex-end;
`;

const QnaDateText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
`;

const QnaBodyText = styled.p`
  padding: 16px 24px;
  width: ${isMobile ? '142px' : '401px'};
  border-radius: 10px;
  background-color: ${(props) =>
    props.type === 'question' ? '#FFF1F0' : '#F2F2F2'};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
  white-space: pre-wrap;
`;

const ImageLayout = styled.div`
  margin-right: ${isMobile ? '12px' : '17px'};
  margin-top: ${isMobile ? '' : '12px'};
  height: ${isMobile ? '48px' : '72px'};
  width: ${isMobile ? '48px' : '72px'};
  position: relative;
  border-radius: 10px;
  background-color: #f2f2f2;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: ${isMobile ? '48px' : '72px'};
    max-height: ${isMobile ? '48px' : '72px'};
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Qna = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [selectedQnaId, setSelectedQnaId] = useState(0);
  const [getQuestionsByStudent, { data: getQuestionsByStudentData, loading }] =
    useLazyQuery(GET_QUESTIONS_BY_STUDENT, {
      fetchPolicy: 'network-only',
    });
  const [listQnas, setListQnas] = useState([]);
  const [readQuestionByStudent] = useMutation(READ_QUESTION_BY_STUDENT, {
    onCompleted: (data) => {
      setListQnas((prev) =>
        prev.map((item) =>
          item.id === data.readQuestionByStudent
            ? { ...item, studentRead: true }
            : item,
        ),
      );
    },
  });
  const [replyQuestion] = useMutation(REPLY_QUESTION, {
    onCompleted: (data) => {
      setListQnas((prev) =>
        prev.map((item) =>
          item.id === selectedQnaId
            ? { ...item, replies: [...item.replies, data.replyQuestion] }
            : item,
        ),
      );
    },
  });
  const selectedQna = useMemo(() => {
    return listQnas.filter((qna) => qna.id === selectedQnaId)?.[0];
  }, [listQnas, selectedQnaId]);
  const [text, setText] = useState('');
  const [target, setTarget] = useState(null);
  useEffect(() => {
    if (selectedQnaId === 0) {
      setText('');
    }
  }, [selectedQnaId]);
  const { user } = useAuth();
  const onIntersect =
    (after) =>
    ([entry], observer) => {
      if (entry.isIntersecting && !loading && !finished) {
        getQuestionsByStudent({
          variables: {
            first: 20,
            after: after,
            studentId: user.id,
          },
        });
      }
    };
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(
        onIntersect(
          listQnas?.length === 0 ? null : listQnas?.[listQnas?.length - 1].id,
        ),
        {
          threshold: 0.4,
        },
      );
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, listQnas]);
  useEffect(() => {
    if (getQuestionsByStudentData) {
      setListQnas((prev) => {
        if (
          prev.findIndex(
            (item) =>
              item.id === getQuestionsByStudentData?.questionsByStudent?.[0].id,
          ) === -1
        ) {
          return [...prev, ...getQuestionsByStudentData?.questionsByStudent];
        }
        return prev;
      });
      if (getQuestionsByStudentData?.questionsByStudent?.length < 20) {
        setFinished(true);
      }
    }
  }, [getQuestionsByStudentData]);
  return (
    <Layout>
      <TitleText>Q&A</TitleText>
      <BodyLayout>
        {listQnas.map((item) => (
          <QnaItemLayout
            onClick={() => {
              setSelectedQnaId(item.id);
              readQuestionByStudent({
                variables: {
                  studentId: user.id,
                  questionId: item.id,
                },
              });
            }}
            key={item.id}
            checked={item.studentRead}
          >
            <ImageLayout>
              {item?.company?.logo !== null &&
                item?.company?.logo !== undefined && (
                  <img src={item?.company?.logo?.url} alt="thumbnail" />
                )}
            </ImageLayout>
            <div style={{ flex: 1 }}>
              <QnaTitleLayout>
                {item.company?.basicCompanyProfile?.companyNameKR}
                {!isMobile && <div>{item.createdAt}</div>}
              </QnaTitleLayout>
              {isMobile && (
                <div style={{ fontSize: '12px', marginBottom: '4px' }}>
                  {item.createdAt}
                </div>
              )}
              <QnaBodyLayout>
                <QuestionLayout>
                  Q.
                  <p>{item.message}</p>
                </QuestionLayout>
                {!isMobile && <Spacer />}
                {!isMobile ? (
                  <>
                    <StatusText checked={item.studentRead}>
                      {item.studentRead ? '확인' : '미확인'}
                    </StatusText>
                    <RightButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCompanyId(item.company?.id);
                      }}
                    >
                      기업 정보
                    </RightButton>
                  </>
                ) : (
                  <div
                    style={{
                      marginRight: 0,
                      marginLeft: 'auto',
                      marginTop: '-24px',
                    }}
                  >
                    <RightButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCompanyId(item.company?.id);
                      }}
                    >
                      기업 정보
                    </RightButton>
                    <StatusText checked={item.studentRead}>
                      {item.studentRead ? '확인' : '미확인'}
                    </StatusText>
                  </div>
                )}
              </QnaBodyLayout>
            </div>
          </QnaItemLayout>
        ))}
        <div ref={setTarget} className="Target-Element" />
      </BodyLayout>
      <Modal
        isOpen={selectedCompanyId > 0}
        onClose={() => setSelectedCompanyId(0)}
        closeButtonTransparent
      >
        <ModalLayout>
          <ModalHeader>
            <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>기업정보</ModalHeaderTitle>
          </ModalHeader>
          <ModalBody>
            <CompanyProfileModal id={selectedCompanyId} />
          </ModalBody>
        </ModalLayout>
      </Modal>
      <Modal
        isOpen={selectedQnaId > 0}
        onClose={() => setSelectedQnaId(0)}
        closeButtonTransparent
      >
        <QnaModalLayout>
          {!isMobile && (
            <QnaModalHeader>
              <div>
                Q.
                <p>{selectedQna?.message}</p>
              </div>
              <span>{selectedQna?.createdAt}</span>
            </QnaModalHeader>
          )}
          <QnaModalBody>
            <div
              style={{
                margin: '19px 50px 0',
                display: 'flex',
                flexFlow: 'column',
              }}
            >
              {isMobile && (
                <MobileQuestionHeader>
                  <div>
                    <div style={{ color: '#fe706f' }}>Q.</div>
                    <p>{selectedQna?.message}</p>
                  </div>
                  <span>{selectedQna?.createdAt}</span>
                </MobileQuestionHeader>
              )}
              {selectedQna?.replies.map((item) => (
                <QnaItem
                  type={
                    item.userType === UserType.company ? 'question' : 'answer'
                  }
                >
                  {item.userType === UserType.company ? (
                    <>
                      <QnaBodyText
                        type={
                          item.userType === UserType.company
                            ? 'question'
                            : 'answer'
                        }
                      >
                        {item.message}
                      </QnaBodyText>
                      <div style={{ width: '12px' }} />
                      <QnaDateText>{item.createdAt}</QnaDateText>
                    </>
                  ) : (
                    <>
                      <QnaDateText>{item.createdAt}</QnaDateText>
                      <div style={{ width: '12px' }} />
                      <QnaBodyText
                        type={
                          item.userType === UserType.company
                            ? 'question'
                            : 'answer'
                        }
                      >
                        {item.message}
                      </QnaBodyText>
                    </>
                  )}
                </QnaItem>
              ))}
            </div>
          </QnaModalBody>
          <QnaModalBottom>
            <QnaTextArea
              value={text}
              placeholder="질문을 입력해주세요."
              onChange={(e) => setText(e.target.value)}
            />
            <QnaSendButton
              disabled={text === ''}
              type="primary"
              onClick={() => {
                replyQuestion({
                  variables: {
                    userType: UserType.student,
                    message: text,
                    questionId: selectedQnaId,
                  },
                });
                setText('');
              }}
            >
              답글
            </QnaSendButton>
          </QnaModalBottom>
        </QnaModalLayout>
      </Modal>
    </Layout>
  );
};

export default Qna;
