import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Typography } from 'antd';
import ApplyMatching from './ApplyMatching';
import PostJobVacancy from './PostJobVacancy';
import ManageMatching from './ManageMatching';
import Qna from './Qna';
import ManageApplications from './ManageApplications';
import ManageSaved from './ManageSaved';

const CompanyMatching = () => {
  return (
    <Switch>
      <Route exact path="/company-meeting/apply-matching">
        <ApplyMatching />
      </Route>
      <Route exact path="/company-meeting/post-job-vacancy">
        <PostJobVacancy />
      </Route>
      <Route exact path="/company-meeting/manage-interview">
        <ManageMatching />
      </Route>
      <Route exact path="/company-meeting/manage-application">
        <ManageApplications />
      </Route>
      <Route exact path="/company-meeting/manage-saved">
        <ManageSaved />
      </Route>
      <Route exact path="/company-meeting/qna">
        <Qna />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default CompanyMatching;
