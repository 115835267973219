import { SwapRightOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  width: ${isMobile ? '342px' : '368px'};
  height: 392px;
  background-color: #f9f9f9;
  border-radius: 30px;
  box-shadow: 4px 12px 23px 0px #0d03030a;

  display: flex;
  margin: ${isMobile ? '0 auto 25px' : ''};
  flex-flow: column;
  cursor: pointer;
`;

const ImageLayout = styled.div`
  margin: ${isMobile ? '21px 12px 19px' : '21px 24px 19px'};
  height: 200px;
  position: relative;
  border-radius: 10px;
  background-color: #fbfbfb;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 320px;
    max-height: 200px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Divider = styled.div`
  margin: 0 19px;
  background-color: #f1f1f1;
`;

const ExpectedTag = styled.div`
  border-radius: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  position: absolute;
  top: 15px;
  right: 20px;
`;

const ApplicationPeriodTag = styled.div`
  border-radius: 10px;
  background: #fff0f6;
  border: 1px solid #ffadd2;
  color: #eb2f96;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  position: absolute;
  top: 15px;
  right: 20px;
`;

const ProceedingTag = styled.div`
  border-radius: 10px;
  background: #fff2e8;
  border: 1px solid #ffbb96;
  color: #fa541c;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  position: absolute;
  top: 15px;
  right: 20px;
`;

const EndTag = styled.div`
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #979797;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  position: absolute;
  top: 15px;
  right: 20px;
`;

const TitleText = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 24px;
`;

const FooterLayout = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 0;
  align-items: center;
  padding: 0 24px 32px 31px;
`;

const OnlineBadge = styled.div`
  border-radius: 10px;
  background: ${(props) => (props.isOnline ? '#FFF7E6' : '#F9F0FF')};
  border: 1px solid ${(props) => (props.isOnline ? '#FFA171' : '#D3ADF7')};
  color: ${(props) => (props.isOnline ? '#FFA171' : '#722ED1')};
  font-weight: 500;
  font-size: 12px;
  margin-right: auto;
  margin-left: 0;
  padding: 0 8px;
`;

const DateLayout = styled.div`
  display: flex;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
`;

const renderState = (state) => {
  switch (state) {
    case 'expected':
      return <ExpectedTag>예정</ExpectedTag>;
    case 'applicationPeriod':
      return <ApplicationPeriodTag>신청가능</ApplicationPeriodTag>;
    case 'proceeding':
      return <ProceedingTag>진행중</ProceedingTag>;
    case 'end':
      return <EndTag>종료</EndTag>;
    default:
      return <ExpectedTag>예정</ExpectedTag>;
  }
};

const FairListItem = ({
  id,
  itemId,
  title,
  image,
  state,
  progressStartAt,
  progressEndAt,
  isOnline,
  onClick,
}) => {
  const history = useHistory();
  return (
    <Layout
      style={{ opacity: state === 'end' ? 0.5 : 1 }}
      id={itemId}
      onClick={() => {
        onClick();
        history.push(`/fair/${id}`);
      }}
    >
      <ImageLayout>
        {image !== null && <img src={image.url} alt="thumbnail" />}
        {renderState(state)}
      </ImageLayout>
      <Divider />
      <TitleText>{title}</TitleText>
      <FooterLayout>
        <OnlineBadge isOnline={isOnline}>
          {isOnline ? '온라인' : '오프라인'}
        </OnlineBadge>
        <DateLayout>
          {progressStartAt}
          <SwapRightOutlined
            style={{
              fontSize: '16px',
              margin: '0 8px',
              color: '#bfbfbf',
            }}
          />
          {progressEndAt}
        </DateLayout>
      </FooterLayout>
    </Layout>
  );
};

export default FairListItem;
