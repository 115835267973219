import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import InputBig from '../../../components/InputBig';
import { phoneNumberRegex, emailRegex } from '../../../constants/regex';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const InputLayout = styled.div`
  width: 294px;
  margin: 16px auto 0;
`;

const ManagerInfo = ({ booth, isCreating, getBooths }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);

  useEffect(() => {
    setName(booth?.managerInfo?.name ?? '');
    setEmail(booth?.managerInfo?.email ?? '');
    setPhoneNumber(booth?.managerInfo?.phoneNumber ?? '');
  }, [booth]);

  useEffect(() => {
    const regex = new RegExp(phoneNumberRegex);
    if (phoneNumber !== '' && !regex.test(phoneNumber)) {
      setPhoneNumberError('유효하지 않은 연락처입니다.');
    } else {
      setPhoneNumberError('');
    }
  }, [phoneNumber]);

  useEffect(() => {
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailError('유효하지 않은 이메일입니다.');
    } else {
      setEmailError('');
    }
  }, [email]);

  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            managerInfo: {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
            },
            step: BoothStep.counselorInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            managerInfo: {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
            },
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    if (
      name === '' ||
      email === '' ||
      phoneNumber === '' ||
      emailError !== '' ||
      phoneNumberError !== ''
    ) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            step: BoothStep.recruitmentInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            managerInfo: {
              name: name,
              email: email,
              phoneNumber: phoneNumber,
            },
            step: BoothStep.recruitmentInformation,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>부스 담당자의 연락정보를 작성해주세요.</p>
      </TitleLaout>
      <InputLayout>
        <InputBig
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          label="담당자 이름"
          placeholder="이름을 입력하세요"
        />
      </InputLayout>
      <InputLayout>
        <InputBig
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          label="담당자 이메일"
          errorMessage={emailError}
          placeholder="이메일을 입력하세요"
        />
      </InputLayout>
      <InputLayout>
        <InputBig
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.currentTarget.value)}
          label="담당자 연락처"
          placeholder={'012-3456-7890'}
          errorMessage={phoneNumberError}
        />
      </InputLayout>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={
                name === '' ||
                email === '' ||
                phoneNumber === '' ||
                emailError !== '' ||
                phoneNumberError !== ''
              }
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={
              name === '' ||
              email === '' ||
              phoneNumber === '' ||
              emailError !== '' ||
              phoneNumberError !== ''
            }
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default ManagerInfo;
