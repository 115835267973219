import { gql } from '@apollo/client';

export const SEARCH_UNIVERSITIES = gql`
  query SearchUniversities($keyword: String!) {
    searchUniversities(keyword: $keyword) {
      university
    }
  }
`;

export const GET_APPROVED_UNIVERSITIES = gql`
  query GetApprovedUniversities {
    approvedUniversities {
      id
      name
      domain
    }
  }
`;
