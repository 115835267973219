import { Button, Select } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Section1Background from '../img/HomeBackground2.png';
import Section1Background2 from '../img/HomeBackground1.png';
import Section1MobileBackground2 from '../img/HomeSection1MobileBottom.png';
import Section2Image1 from '../img/HomeSection2Image1.png';
import Section2Image2 from '../img/HomeSection2Image2.png';
import Section2Image3 from '../img/HomeSection2Image3.png';
import Section3Background from '../img/HomeSection3Image1.png';
import Section3MobileBackground from '../img/Section4MobileBackground.png';
import Section3LeftImg from '../img/Section3LeftImage.png';
import Section3RightImg from '../img/Section3RightImage.png';
import Section4Background from '../img/Section4Background.png';
import Section4MobileBackground from '../img/Section4MobileBackground2.png';
import Section4Background2 from '../img/Section4Background2.png';
import Section4MobileBackground2 from '../img/Section4MobileBackground3.png';
import Comma from '../img/Comma.png';
import { isMobile } from 'react-device-detect';
import useAuth from '../hooks/useAuth';
import ConntechLogo from '../img/ConntechLogo.png';

const Section1 = styled.div`
  width: 100%;
  height: 657px !important;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  position: relative;
  overflow: ${isMobile ? 'hidden' : ''};
`;

const Section1LeftLayout = styled.div`
  left: ${isMobile ? '33px' : '267px'};
  top: ${isMobile ? '50px' : '83px'};
  position: absolute;
  z-index: 2;
`;

const Section1Title = styled.p`
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
`;

const Section1BodyText = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  width: 240px;
  margin-top: 16px;
  .ant-select-selection-placeholder {
    color: black;
  }
  .ant-select-arrow {
    width: 30px !important;
  }
`;

const Section1Button = styled(Button)`
  width: 190px;
  height: 42px;
  border-radius: 10px;
  font-family: Noto Sans KR;
  border: 1px solid #ea5554;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ea5554;
`;

const Section1BackgroundImg = styled.img`
  position: absolute;
  top: ${isMobile ? '252px' : '29px'};
  right: ${isMobile ? '-33px' : '180px'};
  width: ${isMobile ? '356px' : '631px'};
`;

const Section1BackgroundImg2 = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 133px;
  width: 100%;
`;

const Section2 = styled.div`
  height: ${isMobile ? '1452px' : '608px'};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  background-color: #ffffff;
  padding: ${isMobile ? '133px 0 87px' : ''};
`;

const Section2Item = styled.div`
  width: 219px;
  height: 401px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #373334;
  word-wrap: break-word;
  margin-bottom: ${isMobile ? '80px' : ''};
  img {
    height: 162px;
    width: 219px;
    left: 0px;
    top: 0px;
    border-radius: 10px;
  }
  p {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 7px;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

const Section3 = styled.div`
  height: ${isMobile ? '922px' : '745px'};
  position: relative;
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Section3TitleLayout = styled.div`
  display: flex;
  align-items: center;
  width: ${isMobile ? '321px' : ''};
  margin: ${isMobile ? '0 30px 68px 30px' : '0 0 68px 0'};
  margin-bottom: 68px;
  position: relative;
  p {
    position: ${isMobile ? 'absolute' : 'inherit'};
    top: ${isMobile ? '160px' : ''};
    transform: ${isMobile ? 'translate(-50%, -50%)' : ''};
    left: ${isMobile ? '50%' : ''};
    min-width: ${isMobile ? '248px' : ''};
    span {
      margin: ${isMobile ? '' : '0 12px'};
      font-family: Noto Sans KR;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: center;
    }
    font-family: Noto Sans KR;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center !important;
  }
  img {
    width: 120px;
  }
`;

const Section3BackgroundImg = styled.img`
  width: ${isMobile ? '299px' : '846px'};
  margin-top: ${isMobile ? '120px' : ''};
`;

const Section4 = styled.div`
  height: ${isMobile ? '805px' : '702px'};
  position: relative;
  background-color: white;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Section4BackgroundImg = styled.img`
  top: 0;
  left: 50%;
  width: ${isMobile ? '319px' : '915px'};
  height: 142px;
  position: absolute;
  transform: translate(-50%, -50%);
`;

const Section4BackgroundImg2 = styled.img`
  margin-top: ${isMobile ? '92px' : 0};
  width: ${isMobile ? '307px' : '876px'};
  height: ${isMobile ? '348px' : '320px'};
`;

const Section4Text = styled.p`
  width: ${isMobile ? '232px' : '900px'};
  margin-right: ${isMobile ? '83px' : ''};
  margin-top: ${isMobile ? '90px' : ''};
  font-family: Noto Sans KR;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  span {
    font-family: Noto Sans KR;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const InfoLayout = styled.div`
  height: 280px;
  background-color: #fbfbfb;
  padding: ${isMobile ? '30px 35px' : '87px 268px'};
  div {
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      outline: none;
      cursor: pointer;
      font-family: Noto Sans KR;
      font-size: ${isMobile ? '11px' : '14px'};
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
      color: #2e2e2e;
    }
    span {
      width: ${isMobile ? '8px' : '51px'};
    }
  }
  p {
    font-size: 12px;
    color: #757575;
    margin-top: ${isMobile ? '16px' : '30px'};
  }
`;

const HomePage = ({ setIsSignInModalOpened }) => {
  const history = useHistory();
  const { user } = useAuth();
  const handleChange = (value) => {
    window.open(value, '_blank');
  };
  return (
    <>
      <Section1>
        <Section1LeftLayout>
          <Section1Title>
            Design your {isMobile && <br />}career
            <br />
            With fairon
            <img
              style={{ width: '8px', marginTop: '20px', marginLeft: '2px' }}
              src={Comma}
              alt="comma"
            />
          </Section1Title>
          <Section1BodyText>
            국내외 우수 R{`&`}D 인재와 기업간의 간편한 매칭
          </Section1BodyText>
          <Section1Button
            onClick={() => {
              if (user.id === 0) {
                setIsSignInModalOpened(true);
              } else {
                history.push('/fair');
              }
            }}
          >
            {user.id === 0 ? '로그인' : '박람회 둘러보기'}
          </Section1Button>
        </Section1LeftLayout>
        <Section1BackgroundImg2
          src={isMobile ? Section1MobileBackground2 : Section1Background}
          alt="1"
        />
        <Section1BackgroundImg src={Section1Background2} alt="2" />
      </Section1>
      <Section2>
        <Section2Item>
          <img src={Section2Image1} alt="section2Image1" />
          <p>박람회</p>
          기업 담당자와 주요 학교의 R{`&`}D {isMobile && <br />}인재와의 시간을
          가질 수 있는
          <br />
          온/오프라인 박람회를 참가하실 수<br />
          있습니다.
        </Section2Item>
        <div style={{ width: '109px' }} />
        <Section2Item>
          <img src={Section2Image2} alt="section2Image2" />
          <p>매칭</p>
          기업과 인재 서로가 서로에 대해
          <br />
          알아가고, 캐쥬얼 미팅과 면접,
          <br />
          채용까지 간편하게 이뤄질 수<br />
          있습니다.
        </Section2Item>
        <div style={{ width: '109px' }} />
        <Section2Item>
          <img src={Section2Image3} alt="section2Image3" />
          <p>R{`&`}D 전문</p>
          국내외 우수 이공계 대학 중심의
          <br />R{`&`}D 인재와, R{`&`}D 인재의 채용을
          <br />
          원하는 기업을 연결해 드리도록
          <br />
          최선을 다하는 국내 유일의 R{`&`}D<br />
          전문 채용 플랫폼입니다.
        </Section2Item>
      </Section2>
      <Section3>
        <Section3TitleLayout>
          <img src={Section3LeftImg} alt="left" />
          {isMobile && <div style={{ flex: 1 }} />}
          <p>
            다양한 기업이
            <br />
            <span>
              박람회와 매칭 서비스를 {isMobile && <br />}이용하고 있습니다.
            </span>
          </p>

          <img src={Section3RightImg} alt="right" />
        </Section3TitleLayout>
        <Section3BackgroundImg
          src={isMobile ? Section3MobileBackground : Section3Background}
          alt="section3"
        />
      </Section3>
      <Section4>
        <Section4BackgroundImg
          src={isMobile ? Section4MobileBackground : Section4Background}
          alt="section4Background"
        />
        <Section4Text>
          우수한 학교의 인재들이
          <br />
          <span>상담과 면접을 {isMobile && <br />}기다리고 있습니다.</span>
        </Section4Text>
        <Section4BackgroundImg2
          src={isMobile ? Section4MobileBackground2 : Section4Background2}
          alt="section4Background2"
        />
      </Section4>
      <InfoLayout>
        <div>
          <a
            href="https://docs.google.com/document/d/1GtIYMd0XPxbyFu7gjTH8Othd6NF_8Nv_/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            기업서비스 이용약관
          </a>
          <span />
          <a
            href="https://docs.google.com/document/d/1OuwLWuDTfgvp7Cu8cibqnZUgP9Q8SM97/edit"
            target="_blank"
            rel="noopener noreferrer"
          >
            학생서비스 이용약관
          </a>
          <span />
          <a
            href="https://docs.google.com/document/d/1lxihwjpteVY0a9oD8Bv8_1x5pWYM5-tB/edit?usp=sharing&ouid=114277475264944287911&rtpof=true&sd=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보처리방침
          </a>
        </div>
        <StyledSelect
          placeholder="패밀리사이트"
          value={null}
          onChange={handleChange}
          options={[
            {
              value: 'https://conntech.io',
              label: (
                <div
                  style={{ alignItems: 'center', display: 'flex', gap: '8px' }}
                >
                  <img
                    src={ConntechLogo}
                    alt="conntech"
                    style={{ width: '16px', height: '16px' }}
                  />
                  컨텍(CONNTECH)
                </div>
              ),
            },
          ]}
        />
        <p>
          서울특별시 강남구 논현로 98길 28, 3층 302호 주식회사 센랩 (우 06140){' '}
          {isMobile ? <br /> : '| '}개인정보보호 관리자 : 여원철{' '}
          {isMobile ? <br /> : '| '}통신판매번호 : 제 2017-서울강남-01932호
          <br />
          직업정보제공사업 신고 : J1200020180007 {isMobile ? <br /> : '| '}
          유료직업소개사업등록번호 : 제 2020-3220237-14-5-00026호{' '}
          {isMobile ? <br /> : '| '}사업자등록번호 : 102-87-00632{' '}
          {isMobile ? <br /> : '| '}문의 : 02.569.8015
        </p>
      </InfoLayout>
    </>
  );
};

export default HomePage;
