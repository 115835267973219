import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';

const Layout = styled.div`
  width: 320px;
  height: 287px;
  background-color: #fcfcfc;
  border-radius: 30px;
  box-shadow: 0px 12px 23px 0px #0d03030a;
  padding: 24px 0 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin-bottom: ${isMobileOnly ? '12px' : ''};
`;

const FlexLayout = styled.div`
  margin: 0 25px 17px;
  display: flex;
  align-items: center;
`;

const ImageLayout = styled.div`
  margin-right: 17px;
  height: 72px;
  width: 72px;
  position: relative;
  border-radius: 10px;
  background-color: #f2f2f2;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 72px;
    max-height: 72px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const CompanyNameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 2.8em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DomainText = styled.p`
  font-family: Noto Sans KR;
  margin-left: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 8px;
`;

const JobVacancyText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  margin-left: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #f1f1f1;
  margin: 0 0 20px 0;
`;

const SkillsLayout = styled.div`
  height: 27px;
  margin: 0 25px;
  overflow: hidden;
`;

const Skill = styled.div`
  height: 27px;
  border-radius: 10px;
  background-color: #fff3f0;
  color: #ffa171;
  margin: 0 8px 8px 0;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 13px;
  line-height: 19px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  display: -webkit-box;
  max-width: 100px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const TypesLayout = styled.div`
  height: 22px;
  margin: 16px 25px 0;
  overflow: hidden;
`;

const Type = styled.div`
  height: 22px;
  border-radius: 2px;
  padding: 1px 8px;
  background-color: #fff1f0;
  color: #fe706f;
  font-size: 12px;
  margin-right: 8px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.1em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const CompanyItem = ({ id, companyWithSkills, onClick }) => {
  return (
    <Layout id={id} onClick={onClick}>
      <FlexLayout>
        <ImageLayout>
          {companyWithSkills?.company?.logo !== null &&
            companyWithSkills?.company?.logo !== undefined && (
              <img
                src={companyWithSkills?.company?.logo?.url}
                alt="thumbnail"
              />
            )}
        </ImageLayout>
        <CompanyNameText>
          {companyWithSkills?.company?.basicCompanyProfile?.companyNameKR}
          <br />
          {companyWithSkills?.company?.basicCompanyProfile?.companyNameEN}
        </CompanyNameText>
      </FlexLayout>
      <DomainText>
        {companyWithSkills?.company?.basicCompanyProfile?.domain}
      </DomainText>
      <JobVacancyText>
        {companyWithSkills?.company?.onMatchingJobVacancies?.length === 0 &&
          '채용 중인 공고가 없음'}
        {`${
          companyWithSkills?.company?.onMatchingJobVacancies?.[0]?.position
        } ${
          companyWithSkills?.company?.onMatchingJobVacancies?.length > 1
            ? `외 ${
                companyWithSkills?.company?.onMatchingJobVacancies?.length - 1
              }건 `
            : ' '
        }채용중`}
      </JobVacancyText>
      <Divider />
      <SkillsLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {companyWithSkills?.skills?.slice(0, 2)?.map((item, index) => (
            <Skill key={index}>{item.skillDetail}</Skill>
          ))}
          {companyWithSkills?.skills?.length > 2 && (
            <p style={{ fontSize: '12px', marginTop: '4px', color: '#FFA171' }}>
              외 {companyWithSkills?.skills?.length - 2}개
            </p>
          )}
        </div>
      </SkillsLayout>
      <TypesLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {companyWithSkills?.company?.recruitmentTypes
            ?.slice(0, 2)
            ?.map((item, index) => (
              <Type key={index}>{item}</Type>
            ))}
          {companyWithSkills?.company?.recruitmentTypes?.length > 3 && (
            <p style={{ fontSize: '12px', marginTop: '2px', color: '#FE706F' }}>
              외 {companyWithSkills?.company?.recruitmentTypes?.length - 2}개
            </p>
          )}
        </div>
      </TypesLayout>
    </Layout>
  );
};

export default CompanyItem;
