import { EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import { Button } from 'antd';
import ConvertStepToComponent from './EditLayoutComponent';
import { CompanyProfileStep, UserType } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import SmallModalWithTextareaLayout from '../../../components/SmallModalWithTextareaLayout';
import JobVacancyComponent from '../../StudentMatching/JobVacancyComponent';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  APPLY_INTERVIEW,
  ASK_COMPANY,
  CANCEL_SAVE_JOB_VACANCY,
  SAVE_JOB_VACANCY,
} from '../../../queries/matching';
import SmallModalLayout from '../../../components/SmallModalLayout';
import { GET_CUSTOM_ON_MATCHING_JOB_VACANCIES } from '../../../queries/jobVacancy';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { httpUrl } from '../../../constants/utils';

const Layout = styled.div`
  width: 100%;
  background-color: white;
  padding: ${isMobile ? '18px 0 24px' : '53px 0 31px'};
  margin-top: 31px;
  margin-bottom: 13px;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
`;

const TitleLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  margin-bottom: 16px;
  p {
    font-weight: ${isMobile ? 400 : 600};
    font-size: ${isMobile ? '17px' : '14px'};
    margin-right: 26px;
    margin-bottom: ${isMobile ? '8px' : ''};
  }
  margin-left: ${isMobile ? '24px' : '80px'};
  button {
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

const BodyLayout = styled.div`
  margin-top: 26px;
  margin-left: ${isMobile ? '32px' : '95px'};
  white-space: pre-wrap;
`;

const RemovePaddingBodyLayout = styled.div`
  margin-top: 26px;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #ededed;
  margin: ${isMobile ? '24px 0' : '35px 0'};
`;

const ModalBodyLayout = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const LogoLayout = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${isMobile ? '24px' : '80px'};
  div {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fdfdfd;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 80px;
      max-width: 80px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 30px;
      margin-top: 30px;
      padding: 0;
      background-color: transparent;
    }
    div {
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
  p {
    font-weight: 500;
    font-size: ${isMobile ? '16px' : '20px'};
    margin-left: ${isMobile ? '16px' : '33px'};
  }
`;

const BasicProfileLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
`;

const ImagesLayout = styled.div`
  div {
    margin-left: ${isMobile ? '' : '60px'};
    width: 180px;
    height: 180px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    padding: 14px;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 152px;
      max-width: 152px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 64px;
      margin-top: 64px;
      padding: 0;
      background-color: transparent;
    }
    div {
      top: -15px;
      left: -75px;
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
`;

const ImagesBottomLayout = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 180px;
  margin-left: ${isMobile ? '' : '60px'};
  margin-bottom: ${isMobile ? '16px' : ''};
  button {
    width: 32px;
    height: 32px;
    padding: 0 9px;
    margin: 0 22px;
  }
  div {
    flex: 1;
  }
`;

const BasicProfileContentLayout = styled.div`
  margin-left: ${isMobile ? '32px' : '77px'};
  margin-top: 10px;
`;

const BasicProfileItemLayout = styled.div`
  margin-right: ${isMobile ? '36px' : ''};
  div {
    min-width: 259px;
    margin-right: ${isMobile ? '24px' : '85px'};
    display: flex;
    align-items: ${isMobile ? '' : 'center'};
    margin-bottom: 24px;
    word-break: break-all;

    p {
      font-weight: 600;
      width: 110px !important;
      min-width: 110px !important;
      margin-right: 4px;
    }
  }
`;

const ManagerInfoLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  div {
    width: 259px;
    display: flex;
    align-items: center;
    margin-bottom: ${isMobile ? '16px' : ''};

    p {
      font-weight: 600;
      width: ${isMobile ? '64px' : '52px'};
      margin-right: 10px;
    }
  }
`;

const RecruitmentTypeItem = styled(Tag)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  height: 22px;
  border: none;
  color: #000000;
  margin-bottom: 19px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
`;

const LinkItemLayout = styled.div`
  margin-top: 21px;
  display: flex;
  align-items: center;
  p {
    min-width: 188px;
    margin-right: 22px;
  }
  a {
    color: #f88988;
    cursor: pointer;
    text-decoration: none;
  }
`;

const QnaLayout = styled.div`
  margin-right: ${isMobile ? '24px' : '95px'};
  border-radius: 10px;
  border: 1px solid #d9d9d9;
`;

const QnaRow = styled.div`
  padding: 12px 24px;
  font-weight: 500;
  word-break: break-all;
  p {
    color: #f88988;
  }
`;

const QnaDivider = styled.div`
  height: 1px;
  background-color: #d9d9d9;
`;

const Spacer = styled.div`
  flex: 1;
`;

const AskButton = styled(Button)`
  width: ${isMobile ? '140px' : '176px'};
  height: 53px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  margin: ${isMobile ? '0 8px 0' : '0 44px 0 0'};
`;

const JobVacancyListLayout = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: ${isMobile ? '' : 'wrap'};
  flex-flow: ${isMobile ? 'column' : ''};
  width: ${(props) => props.width}px;
  align-items: center;
  padding: ${isMobile ? '' : '0 13px'};
`;

const ArrowButtonLayout = styled.div`
  width: ${isMobile ? '24px' : '56px'};
`;

const CompanyProfile = ({
  id,
  refetchProfile,
  profile,
  userType,
  forStudent = false,
  forPromotion = false,
}) => {
  const [asking, setAsking] = useState(false);
  const { user } = useAuth();
  const [openedStep, setOpenedStep] = useState('');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [applyingJobVacancy, setApplyingJobVacancy] = useState(null);
  const [showMoreJobVacancies, setShowMoreJobVacancies] = useState(false);
  const [showMoreCustomJobVacancies, setShowMoreCustomJobVacancies] =
    useState(false);

  useEffect(() => {
    if (openedStep !== '') {
      setIsModalOpened(true);
    }
  }, [openedStep]);
  const [cancelSaveJobVacancy] = useMutation(CANCEL_SAVE_JOB_VACANCY, {
    onCompleted: (data) => {
      refetchProfile();
      getCustomOnMatchingJobVacancies({
        variables: {
          studentId: user.id,
          companyd: profile?.id,
        },
      });
    },
  });
  const [saveJobVacancy] = useMutation(SAVE_JOB_VACANCY, {
    onCompleted: (data) => {
      refetchProfile();
      getCustomOnMatchingJobVacancies({
        variables: {
          studentId: user.id,
          companyd: profile?.id,
        },
      });
    },
  });
  const [
    getCustomOnMatchingJobVacancies,
    { data: getCustomOnMatchingJobVacanciesData },
  ] = useLazyQuery(GET_CUSTOM_ON_MATCHING_JOB_VACANCIES);
  useEffect(() => {
    if (forStudent && user.id > 0 && !forPromotion) {
      getCustomOnMatchingJobVacancies({
        variables: {
          studentId: user.id,
          companyId: profile?.id,
        },
      });
    }
  }, [
    forStudent,
    user.id,
    getCustomOnMatchingJobVacancies,
    profile,
    forPromotion,
  ]);
  const [askCompany] = useMutation(ASK_COMPANY, {
    onCompleted: (data) => {
      setAsking(false);
    },
  });
  const [interviewFailModalOpened, setInterviewFailModalOpened] =
    useState(false);
  const [applyInterview] = useMutation(APPLY_INTERVIEW, {
    onCompleted: (data) => {
      setApplyingJobVacancy(null);
      refetchProfile();
      getCustomOnMatchingJobVacancies({
        variables: {
          studentId: user.id,
          companyId: profile?.id,
        },
      });
    },
    onError: (error) => {
      if (
        error?.graphQLErrors?.[0]?.extensions?.code ===
        'STUDENT_NOT_ON_MATCHING'
      ) {
        setInterviewFailModalOpened(true);
        setApplyingJobVacancy(null);
      }
    },
  });

  const [maxCount, setMaxCount] = useState(3);
  useEffect(() => {
    handleResize();
  }, []);
  const handleResize = () => {
    console.log(window.innerWidth);
    setMaxCount(
      Math.floor((window.innerWidth - 300) / 320) === 0
        ? 1
        : (window.innerWidth - 300) / 320 > 3
        ? 3
        : Math.floor((window.innerWidth - 300) / 320),
    );
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const history = useHistory();
  const [isImageModalOpened, setIsImageModalOpened] = useState('');

  return (
    <Layout>
      <LogoLayout>
        <div>
          {profile?.logo ? (
            <img src={profile?.logo?.url} alt="sample" />
          ) : (
            <p
              style={{
                position: 'absolute',
                fontSize: '12px',
                marginTop: '18px',
                marginLeft: '20px',
                textAlign: 'center',
              }}
            >
              로고가
              <br />
              없습니다
            </p>
          )}
          {userType === UserType.company && (
            <div>
              <button onClick={() => setOpenedStep(CompanyProfileStep.logo)}>
                <EditOutlined
                  style={{
                    fontSize: '18px',
                    color: '#ffa39e',
                  }}
                />
              </button>
            </div>
          )}
        </div>
        <p>
          {profile?.basicCompanyProfile?.companyNameKR} (
          {profile?.basicCompanyProfile?.companyNameEN})
        </p>
        <Spacer />
        {forStudent &&
          !forPromotion &&
          !isMobile &&
          user.userType !== UserType.company && (
            <AskButton type="primary" onClick={() => setAsking(true)}>
              기업에 질문하기
            </AskButton>
          )}
      </LogoLayout>
      {isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '12px 0',
          }}
        >
          {forStudent && !forPromotion && (
            <AskButton type="primary" onClick={() => setAsking(true)}>
              기업에 질문하기
            </AskButton>
          )}
        </div>
      )}
      <Divider />
      {forStudent && !forPromotion && (
        <>
          {getCustomOnMatchingJobVacanciesData?.customOnMatchingJobVacancies
            ?.length > 0 && (
            <>
              <TitleLayout>
                <p>맞춤 채용공고</p>
                <Spacer />
                <span
                  style={{
                    marginRight: isMobile ? '' : '80px',
                    fontSize: '12px',
                    color: '#fe706f',
                  }}
                >
                  *면접지원은 하단의 공고를 확인 후 공고별로 지원이 가능합니다.
                </span>
              </TitleLayout>
              <JobVacancyListLayout
                width={maxCount * 320 + 16 * (maxCount - 1) + 26}
              >
                {getCustomOnMatchingJobVacanciesData?.customOnMatchingJobVacancies
                  ?.slice(
                    0,
                    showMoreCustomJobVacancies
                      ? getCustomOnMatchingJobVacanciesData
                          ?.customOnMatchingJobVacancies?.length
                      : maxCount,
                  )
                  .map((item, index) => (
                    <JobVacancyComponent
                      key={index}
                      jobVacancy={item}
                      forSlide={isMobile}
                      updateViewCount={true}
                      removeTypes={true}
                      saved={item.saved}
                      applied={item.applied}
                      removeCompany={true}
                      onClickSaveButton={() => {
                        if (item.saved) {
                          cancelSaveJobVacancy({
                            variables: {
                              studentId: user.id,
                              jobVacancyId: item.id,
                            },
                          });
                        } else {
                          saveJobVacancy({
                            variables: {
                              studentId: user.id,
                              jobVacancyId: item.id,
                            },
                          });
                        }
                      }}
                      onClickApplyButton={() => setApplyingJobVacancy(item)}
                    />
                  ))}
              </JobVacancyListLayout>
              {maxCount >
                getCustomOnMatchingJobVacanciesData
                  ?.customOnMatchingJobVacancies?.length && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    type="link"
                    style={{ margin: '16px auto' }}
                    onClick={() =>
                      setShowMoreCustomJobVacancies((prev) => !prev)
                    }
                  >
                    {showMoreCustomJobVacancies ? '숨기기' : '더보기'}
                  </Button>
                </div>
              )}

              <Divider />
            </>
          )}
          {profile?.onMatchingJobVacancies?.length > 0 && (
            <>
              <TitleLayout>
                <p>
                  진행중 채용공고 (
                  {profile?.onMatchingJobVacancies?.length ?? 0})
                </p>
                <Spacer />
                <span
                  style={{
                    marginRight: isMobile ? '' : '80px',
                    fontSize: '12px',
                    color: '#fe706f',
                  }}
                >
                  *면접지원은 하단의 공고를 확인 후 공고별로 지원이 가능합니다.
                </span>
              </TitleLayout>
              <JobVacancyListLayout
                width={maxCount * 320 + 16 * (maxCount - 1) + 26}
              >
                {profile?.onMatchingJobVacancies
                  ?.slice(
                    0,
                    showMoreJobVacancies
                      ? profile?.onMatchingJobVacancies?.length
                      : maxCount,
                  )
                  .map((item, index) => (
                    <JobVacancyComponent
                      key={index}
                      jobVacancy={item}
                      forSlide={isMobile}
                      removeTypes={true}
                      saved={item.saved}
                      applied={item.applied}
                      removeCompany={true}
                      onClickSaveButton={() => {
                        if (item.saved) {
                          cancelSaveJobVacancy({
                            variables: {
                              studentId: user.id,
                              jobVacancyId: item.id,
                            },
                          });
                        } else {
                          saveJobVacancy({
                            variables: {
                              studentId: user.id,
                              jobVacancyId: item.id,
                            },
                          });
                        }
                      }}
                      onClickApplyButton={() => setApplyingJobVacancy(item)}
                    />
                  ))}
              </JobVacancyListLayout>
              {maxCount < profile?.onMatchingJobVacancies?.length && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Button
                    type="link"
                    style={{ margin: '16px auto' }}
                    onClick={() => setShowMoreJobVacancies((prev) => !prev)}
                  >
                    {showMoreJobVacancies ? '숨기기' : '더보기'}
                  </Button>
                </div>
              )}

              <Divider />
            </>
          )}
        </>
      )}
      <TitleLayout>
        <p>기본 정보</p>
        {userType === UserType.company && (
          <button
            onClick={() =>
              setOpenedStep(CompanyProfileStep.basicCompanyProfile)
            }
          >
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <RemovePaddingBodyLayout>
        <BasicProfileLayout>
          <div>
            {isMobile ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexFlow: 'column',
                }}
              >
                <ImagesLayout>
                  <div
                    onClick={() => {
                      if (forStudent) {
                        setIsImageModalOpened(true);
                      }
                    }}
                  >
                    {profile?.images?.length > 0 ? (
                      <img
                        src={profile?.images?.[imageIndex]?.url}
                        alt="images"
                      />
                    ) : (
                      <p style={{ position: 'absolute' }}>
                        등록된 이미지가 없습니다
                      </p>
                    )}
                    {userType === UserType.company && (
                      <div>
                        <button
                          onClick={() =>
                            setOpenedStep(CompanyProfileStep.images)
                          }
                        >
                          <EditOutlined
                            style={{
                              fontSize: '18px',
                              color: 'white',
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </ImagesLayout>
                <ImagesBottomLayout>
                  <Button
                    disabled={
                      profile?.images === undefined ||
                      imageIndex === 0 ||
                      profile?.images.length === 0
                    }
                    onClick={() => setImageIndex(imageIndex - 1)}
                  >
                    <LeftOutlined />
                  </Button>
                  <div />
                  <Button
                    disabled={
                      profile?.images === undefined ||
                      imageIndex === profile?.images.length - 1 ||
                      profile?.images.length === 0
                    }
                    onClick={() => setImageIndex(imageIndex + 1)}
                  >
                    <RightOutlined />
                  </Button>
                </ImagesBottomLayout>
              </div>
            ) : (
              <>
                <ImagesLayout>
                  <div
                    onClick={() => {
                      if (forStudent) {
                        setIsImageModalOpened(true);
                      }
                    }}
                  >
                    {profile?.images?.length > 0 ? (
                      <img
                        src={profile?.images?.[imageIndex]?.url}
                        alt="images"
                      />
                    ) : (
                      <p style={{ position: 'absolute' }}>
                        등록된 이미지가 없습니다
                      </p>
                    )}
                    {userType === UserType.company && (
                      <div>
                        <button
                          onClick={() =>
                            setOpenedStep(CompanyProfileStep.images)
                          }
                        >
                          <EditOutlined
                            style={{
                              fontSize: '18px',
                              color: 'white',
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </ImagesLayout>
                <ImagesBottomLayout>
                  <Button
                    disabled={
                      profile?.images === undefined ||
                      imageIndex === 0 ||
                      profile?.images.length === 0
                    }
                    onClick={() => setImageIndex(imageIndex - 1)}
                  >
                    <LeftOutlined />
                  </Button>
                  <div />
                  <Button
                    disabled={
                      profile?.images === undefined ||
                      imageIndex === profile?.images.length - 1 ||
                      profile?.images.length === 0
                    }
                    onClick={() => setImageIndex(imageIndex + 1)}
                  >
                    <RightOutlined />
                  </Button>
                </ImagesBottomLayout>
              </>
            )}
          </div>
          <BasicProfileContentLayout>
            <BasicProfileItemLayout>
              <div>
                <p>홈페이지</p>
                <a
                  href={httpUrl(profile?.basicCompanyProfile?.homePage)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {profile?.basicCompanyProfile?.homePage}
                </a>
              </div>
              <div>
                <p>산업분야</p>
                {profile?.basicCompanyProfile?.domain}
              </div>
              {forStudent && (
                <>
                  <div>
                    <p>사업자 등록번호</p>
                    {profile?.basicCompanyProfile?.registrationNumber}
                  </div>
                  <div>
                    <p>전화번호</p>
                    {profile?.basicCompanyProfile?.phoneNumber}
                  </div>
                  <div>
                    <p>주소</p>
                    {profile?.basicCompanyProfile?.address}
                  </div>
                </>
              )}
            </BasicProfileItemLayout>
          </BasicProfileContentLayout>
        </BasicProfileLayout>
      </RemovePaddingBodyLayout>
      <Divider />
      <TitleLayout>
        <p>담당자 정보</p>
        {userType === UserType.company && (
          <button onClick={() => setOpenedStep(CompanyProfileStep.managerInfo)}>
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        <ManagerInfoLayout>
          <div>
            <p>이름</p>
            {profile?.managerInfo?.name}
          </div>
          <div>
            <p>연락처</p>
            {profile?.managerInfo?.phoneNumber}
          </div>
          <div>
            <p>이메일</p>
            {profile?.managerInfo?.email}
          </div>
        </ManagerInfoLayout>
      </BodyLayout>
      <Divider />
      <TitleLayout>
        <p>기업 및 서비스 소개</p>
        {userType === UserType.company && (
          <button
            onClick={() => setOpenedStep(CompanyProfileStep.introduction)}
          >
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        <p style={{ marginRight: isMobile ? '24px' : '95px' }}>
          {profile?.introduction}
        </p>
      </BodyLayout>
      <Divider />
      <TitleLayout>
        <p>채용 형태</p>
        {userType === UserType.company && (
          <button
            onClick={() => setOpenedStep(CompanyProfileStep.recruitmentTypes)}
          >
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        {profile?.recruitmentTypes?.map((type, index) => (
          <RecruitmentTypeItem key={index}>{type}</RecruitmentTypeItem>
        ))}
      </BodyLayout>
      <Divider />
      <TitleLayout>
        <p>복지 및 혜택</p>
        {userType === UserType.company && (
          <button onClick={() => setOpenedStep(CompanyProfileStep.welfare)}>
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        <p style={{ marginRight: isMobile ? '24px' : '95px' }}>
          {profile?.welfare}
        </p>
      </BodyLayout>
      <Divider />
      <TitleLayout>
        <p>기타 영상 및 URL</p>
        {userType === UserType.company && (
          <button onClick={() => setOpenedStep(CompanyProfileStep.urls)}>
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        {profile?.urls
          ?.filter((item) => item.name !== '' && item.link !== '')
          .map((url, index) => (
            <LinkItemLayout key={index}>
              <p>{url.name}</p>
              <a
                href={httpUrl(url.link)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url.link}
              </a>
            </LinkItemLayout>
          ))}
      </BodyLayout>
      <Divider />
      <TitleLayout>
        <p>자주 묻는 질문</p>
        {userType === UserType.company && (
          <button onClick={() => setOpenedStep(CompanyProfileStep.qnas)}>
            <EditOutlined style={{ fontSize: '18px', color: '#ffa39e' }} />
          </button>
        )}
      </TitleLayout>
      <BodyLayout>
        {profile?.qnas?.length > 0 && (
          <QnaLayout>
            <QnaRow>질문</QnaRow>
            {profile?.qnas?.map((qna) => (
              <>
                <QnaDivider />
                <QnaRow>
                  <p>{qna.question}</p>
                </QnaRow>
                <QnaRow>:{qna.answer}</QnaRow>
              </>
            ))}
          </QnaLayout>
        )}
      </BodyLayout>
      <Modal
        isOpen={isModalOpened}
        onClose={() => {
          setIsModalOpened(false);
          setTimeout(() => {
            setOpenedStep('');
          }, 500);
          refetchProfile();
        }}
        width="971px"
        height="528px"
        radius="30px"
      >
        <ModalBodyLayout>
          <ConvertStepToComponent
            id={id}
            step={openedStep}
            refetchProfile={refetchProfile}
            userType={userType}
            profile={profile}
            isModal
            closeModal={() => {
              setIsModalOpened(false);
              setTimeout(() => {
                setOpenedStep('');
              }, 500);
            }}
          />
        </ModalBodyLayout>
      </Modal>
      <Modal isOpen={asking} onClose={() => setAsking(false)}>
        <SmallModalWithTextareaLayout
          title="기업에 질문하기 (Q&A)"
          onClickLeftButton={() => setAsking(false)}
          placeholder={'질문 내용을 입력해주세요.'}
          onClickRightButton={(text) => () => {
            askCompany({
              variables: {
                studentId: user.id,
                companyId: profile?.id,
                message: text,
              },
            });
          }}
        />
      </Modal>
      <Modal
        isOpen={applyingJobVacancy !== null}
        onClose={() => setApplyingJobVacancy(null)}
        radius="30px"
      >
        <SmallModalLayout
          title="면접 지원"
          description={
            <>
              <span style={{ fontWeight: 500 }}>
                {
                  applyingJobVacancy?.company?.basicCompanyProfile
                    ?.companyNameKR
                }
              </span>
              의
              <br />
              <span style={{ color: '#fe706f' }}>
                {applyingJobVacancy?.position}
              </span>{' '}
              채용공고에 면접을 지원하시겠습니까?
            </>
          }
          setDescriptionCenter={true}
          onClickLeftButton={() => setApplyingJobVacancy(null)}
          onClickRightButton={() => {
            applyInterview({
              variables: {
                studentId: user.id,
                jobVacancyId: applyingJobVacancy?.id,
              },
            });
          }}
          height="305px"
        />
      </Modal>
      <Modal
        radius="30px"
        isOpen={interviewFailModalOpened}
        onClose={() => setInterviewFailModalOpened(false)}
      >
        <SmallModalLayout
          title="면접 지원 실패"
          description={'프로필에서 매칭 서비스 이용을 ON으로 바꿔주세요.'}
          setDescriptionCenter={true}
          removeLeftButton={true}
          onClickRightButton={() => history.push('/profile/manage')}
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={isImageModalOpened}
        onClose={() => setIsImageModalOpened(false)}
        width={isMobile ? '100%' : '600px'}
        height="600px"
        radius="30px"
        closeButtonTransparent
      >
        <div
          style={{
            height: '600px',
            width: isMobile ? '100%' : '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            position: 'relative',
          }}
        >
          <img
            style={{
              maxWidth: isMobile ? '100%' : '600px',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            src={profile?.images?.[imageIndex]?.url}
            alt="selected"
          />
          <Button
            type="primary"
            disabled={
              profile?.images === undefined ||
              imageIndex === profile?.images.length - 1 ||
              profile?.images.length === 0
            }
            style={{
              width: '40px',
              height: '40px',
              padding: '4px',
              backgroundColor: '#efefef',
              position: 'absolute',
              borderColor: '#efefef',
              opacity: '80%',
              top: '276px',
              right: '16px',
            }}
            onClick={() => setImageIndex(imageIndex + 1)}
          >
            <RightOutlined style={{ fontSize: '32px' }} />
          </Button>
          <Button
            disabled={
              profile?.images === undefined ||
              imageIndex === 0 ||
              profile?.images.length === 0
            }
            onClick={() => setImageIndex(imageIndex - 1)}
            type="primary"
            style={{
              width: '40px',
              height: '40px',
              padding: '4px',
              opacity: '80%',
              backgroundColor: '#efefef',
              position: 'absolute',
              borderColor: '#efefef',
              top: '276px',
              left: '16px',
            }}
          >
            <LeftOutlined style={{ fontSize: '32px' }} />
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default CompanyProfile;
