import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../hooks/useInput';
import { Color } from '../../constants/color';
import Input from '../Input';
import { emailRegex } from '../../constants/regex';
import { Button } from 'antd';
import { SIGN_IN } from '../../queries/auth';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { isMobile } from 'react-device-detect';
import { decodeAccessToken } from '../../constants/utils';
import { UserType } from '../../constants/static';
import { GET_APPROVED_UNIVERSITIES } from '../../queries/university';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  padding: ${isMobile ? '42px 36px 40px' : '42px 96px 40px'};
  z-index: 10;
`;

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 41px;
  color: '#3a3a3a';
`;

const SubTitleText = styled.p`
  font-size: 12px;
  color: '#3a3a3a';
  text-align: center;
  margin-bottom: 10px;
`;

const UserTypeSelectLayout = styled.div`
  width: 272px;
  overflow: hidden;
  display: flex;
  border-radius: 10px;
`;

const UserTypeSelect = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? Color.Primary : '#f5f5f5')};
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? '#ffffff' : '#bfbfbf')};
  border-left: ${(props) =>
    props.selected ? '' : props.left ? '1px solid #d9d9d9' : ''};
  border-right: ${(props) =>
    props.selected ? '' : props.right ? '1px solid #d9d9d9' : ''};
  border-top: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-bottom: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-radius: ${(props) =>
    props.left ? '10px 0 0 10px' : props.right ? '0 10px 10px 0' : ''};
  margin-bottom: 32px;
`;

const InputLayout = styled.div`
  margin-bottom: 22px;
`;

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: #d9d9d9;
`;

const LinkLayout = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  div {
    margin: 0 17px;
    height: 10px;
    width: 1px;
    background-color: #aaaaaa;
  }
`;

const BottomLink = styled(Link)`
  font-size: 12px;
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
`;

const SignInModal = ({ isOpen, onClose, handlePage }) => {
  const { setUser } = useAuth();
  const [signIn, { data, loading, error }] = useMutation(SIGN_IN);
  const [userType, setUserType] = useState(UserType.student);
  const [{ email, password }, onChange, reset] = useInputs({
    email: '',
    password: '',
  });
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  useEffect(() => {
    reset();
  }, [userType]);
  useEffect(() => {
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailError('유효하지 않은 이메일입니다.');
    } else {
      setEmailError('');
    }
  }, [email]);
  useEffect(() => {
    setPasswordError('');
  }, [password]);
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        reset();
        setUserType(UserType.student);
      }, 500);
    }
  }, [isOpen, reset]);
  useEffect(() => {
    if (data?.signIn?.token) {
      const decoded = JSON.parse(decodeAccessToken(data.signIn.token));
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: decoded.id,
          email: email,
          userType: userType,
        }),
      );
      localStorage.setItem('accessToken', data.signIn.token);
      setUser({
        id: decoded.id,
        email: email,
        userType: userType,
      });
      onClose();
    }
  }, [data]);
  useEffect(() => {
    if (error?.graphQLErrors?.[0]?.extensions?.code === 'EMAIL_NOT_FOUND') {
      setEmailError('가입되지 않은 이메일입니다.');
    } else if (
      error?.graphQLErrors?.[0]?.extensions?.code === 'INVALID_PASSWORD'
    ) {
      setPasswordError('비밀번호가 일치하지 않습니다.');
    }
  }, [error]);
  return (
    <Layout>
      <BodyLayout>
        <TitleText>로그인</TitleText>
        <SubTitleText>회원유형</SubTitleText>
        <UserTypeSelectLayout>
          <UserTypeSelect
            selected={userType === UserType.student}
            left
            onClick={() => setUserType(UserType.student)}
            disabled={loading}
          >
            학생
          </UserTypeSelect>
          <Divider />
          <UserTypeSelect
            selected={userType === UserType.company}
            onClick={() => setUserType(UserType.company)}
            disabled={loading}
          >
            기업
          </UserTypeSelect>
          <Divider />
          <UserTypeSelect
            selected={userType === UserType.counselor}
            right
            onClick={() => setUserType(UserType.counselor)}
            disabled={loading}
          >
            상담
          </UserTypeSelect>
        </UserTypeSelectLayout>
        <InputLayout>
          <Input
            name="email"
            value={email}
            onChange={onChange}
            label="이메일"
            placeholder={'이메일을 입력하세요'}
            errorMessage={emailError}
            disabled={loading}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailError !== '' ||
                  password === '' ||
                  passwordError !== ''
                )
              ) {
                signIn({
                  variables: {
                    email: email,
                    password: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="password"
            value={password}
            onChange={onChange}
            label="비밀번호"
            placeholder={'비밀번호를 입력하세요'}
            secure
            errorMessage={passwordError}
            disabled={loading}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailError !== '' ||
                  password === '' ||
                  passwordError !== ''
                )
              ) {
                signIn({
                  variables: {
                    email: email,
                    password: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <Button
          onClick={() => {
            signIn({
              variables: {
                email: email,
                password: password,
                userType: userType,
              },
            });
          }}
          disabled={
            email === '' ||
            emailError !== '' ||
            password === '' ||
            passwordError !== ''
          }
          loading={loading}
          type="primary"
          style={{ width: '100%', borderRadius: '10px', marginTop: '20px' }}
        >
          로그인
        </Button>
        <LinkLayout>
          <BottomLink onClick={() => handlePage('signUp')}>회원가입</BottomLink>
          <div />
          <BottomLink onClick={() => handlePage('findPassword')}>
            비밀번호 찾기
          </BottomLink>
        </LinkLayout>
      </BodyLayout>
    </Layout>
  );
};

export default SignInModal;
