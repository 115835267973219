import {
  CheckOutlined,
  DownOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import SmallModalWithTextareaLayout from '../../components/SmallModalWithTextareaLayout';
import { MatchingSuggestionState, MatchingType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import {
  CANCEL_MATCHING,
  GET_MATCHINGS_BY_COMPANY,
} from '../../queries/matching';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';

const mockSuggestions = {
  suggestions: [
    {
      jobVacancy: {
        id: 1,
        position: 'testadsfasfsadfasdf',
      },
      pendingCount: 0,
      approvedCount: 0,
      declinedCount: 0,
      canceledCount: 0,
      suggestions: [
        {
          id: 1,
          date: '2020-12-12',
          student: {
            id: 2,
            name: '김바보',
            phoneNumber: '010-1234-1234',
          },
          state: 'APPROVED',
          suggestion: {
            message: 'adsfasdf',
            rank: 'adsfasdfasdf',
            minSalary: '1000',
            maxSalary: '2000',
            stockOption:
              'Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.',
          },
        },
        {
          id: 2,
          date: '2020-12-12',
          student: {
            id: 2,
            name: '김바보',
            phoneNumber: '010-1234-1234',
          },
          suggestion: {
            message: 'adsfasdf',
            rank: 'adsfasdfasdf',
            minSalary: '1000',
            maxSalary: '2000',
            stockOption:
              'Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.Et nec, scelerisque nunc hendrerit eget ac sit. Elementum egestas urna duis porta lacinia. Fames sed vestibulum magnis iaculis phasellus varius consectetur viverra viverra. Amet, urna dolor sed diam molestie quam sed eget felis.',
          },
          state: 'APPROVED',
        },
      ],
    },
    {
      jobVacancy: {
        id: 2,
        position: 'testadsfasfsadfasdf',
      },
      pendingCount: 0,
      approvedCount: 0,
      declinedCount: 0,
      canceledCount: 0,
      suggestions: [
        {
          id: 3,
          date: '2020-12-12',
          student: {
            id: 2,
            name: '김바보',
            phoneNumber: '010-1234-1234',
          },
          state: 'PENDING',
          suggestion: {
            message: 'adsfasdf',
            rank: 'adsfasdfasdf',
            minSalary: '1000',
            maxSalary: '2000',
            stockOption: 'adsfasdf',
          },
        },
      ],
    },
    {
      jobVacancy: {
        id: 3,
        position: 'testadsfasfsadfasdf',
      },
      pendingCount: 0,
      approvedCount: 0,
      declinedCount: 0,
      canceledCount: 0,
      suggestions: [
        {
          id: 4,
          date: '2020-12-12',
          student: {
            id: 2,
            name: '김바보',
            phoneNumber: '010-1234-1234',
          },
          state: 'CANCELED',
          suggestion: {
            message: 'adsfasdf',
            rank: 'adsfasdfasdf',
            minSalary: '1000',
            maxSalary: '2000',
            stockOption: 'adsfasdf',
          },
        },
      ],
    },
    {
      jobVacancy: {
        id: 4,
        position: 'testadsfasfsadfasdf',
      },
      pendingCount: 0,
      approvedCount: 0,
      declinedCount: 0,
      canceledCount: 0,
      suggestions: [
        {
          id: 5,
          date: '2020-12-12',
          student: {
            id: 2,
            name: '김바보',
            phoneNumber: '010-1234-1234',
          },
          state: 'DECLINED',
          suggestion: {
            message: 'adsfasdf',
            rank: 'adsfasdfasdf',
            minSalary: '1000',
            maxSalary: '2000',
            stockOption: 'adsfasdf',
          },
        },
      ],
    },
  ],
};

const convertStateToText = (state) => {
  switch (state) {
    case MatchingSuggestionState.approved:
      return '수락';
    case MatchingSuggestionState.pending:
      return '대기';
    case MatchingSuggestionState.canceled:
      return '취소';
    case MatchingSuggestionState.declined:
      return '거절';
    default:
      return '거절';
  }
};

const convertStateToComponentStyle = (state) => {
  switch (state) {
    case MatchingSuggestionState.declined:
      return { text: '거절', color: '#ff4d4f', background: '#ffccc7' };
    case MatchingSuggestionState.canceled:
      return { text: '취소', color: '#373334', background: '#f2f2f2' };
    case MatchingSuggestionState.approved:
      return { text: '수락', color: '#57a0f5', background: '#e6f7ff' };
    case MatchingSuggestionState.pending:
      return { text: '대기', color: '#52c41a', background: '#f6ffed' };
    default:
      return { text: '대기', color: '#52c41a', background: '#f6ffed' };
  }
};
const Layout = styled.div`
  margin-top: 27px;
  cursor: pointer;
`;

const SuggestionItem = styled.div`
  width: 100%;
  border: 1.5px solid #fe706f;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
`;

const SuggestionItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const SuggestionItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
`;

const CountText = styled.p`
  margin-left: 16px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: baseline;
  margin: 20px 48px 0;
`;

const ToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background-color: ${(props) => props.background};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  height: 22px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 20px 0;
`;

const StudentItem = styled.div`
  margin: 0 62px 10px;
  padding: 13px 15px;
  border-radius: 10px;
  background-color: #fbfbfb;
  height: 59px;
  display: flex;
  align-items: center;
`;

const DateBadge = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 5px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  border: 1px solid #d9d9d9;
  text-align: left;
  color: #262626;
  margin-right: 48px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 51px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhoneNumberText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 74px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CounselStateText = styled.p`
  margin-left: 20px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  width: 96px;
  text-align: left;
  color: ${(props) => props.color};
`;

const CounselRightButton = styled.button`
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;
const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 0;
`;

const SuggestionModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;
const SuggestionModalBody = styled.div`
  height: 495px;
  width: 100%;
  position: absolute;
  top: 92px;
  z-index: 0;
  overflow: scroll;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const SuggestionRowItem = styled.div`
  display: flex;
  width: 100%;
  margin: 22px 49px 22px 77px;
  span {
    width: 199px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  p {
    white-space: pre-wrap;
    font-family: Noto Sans KR;
    width: 567px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const ModalDivider = styled.div`
  height: 1px;
  background-color: #f0f0f0;
`;

const filters = [
  MatchingSuggestionState.pending,
  MatchingSuggestionState.approved,
  MatchingSuggestionState.declined,
  MatchingSuggestionState.canceled,
];

export const maskingName = (name) => {
  if (!name) {
    return '';
  }
  if (name.length === 1) {
    return '*';
  }
  if (name.length === 2) {
    return `${name.charAt(0)}*`;
  }
  if (name.length > 2) {
    return `${name.charAt(0)}${'*'.repeat(name.length - 2)}${name.charAt(
      name.length - 1,
    )}`;
  }
};

const SuggestionTab = () => {
  const { user } = useAuth();
  const [getMatchingsByCompany, { data: getMatchingsByCompanyData }] =
    useLazyQuery(GET_MATCHINGS_BY_COMPANY);
  useEffect(() => {
    if (user.id > 0) {
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.suggested,
          companyId: user.id,
        },
      });
    }
  }, [user, getMatchingsByCompany]);
  const [openedSuggestionId, setOpenedSuggestionId] = useState(0);
  const [toggleFilters, setToggleFilters] = useState(
    new Set([
      MatchingSuggestionState.pending,
      MatchingSuggestionState.approved,
      MatchingSuggestionState.declined,
      MatchingSuggestionState.canceled,
    ]),
  );
  const handleClickToggleButton = (item) => {
    if (!toggleFilters.has(item)) {
      setToggleFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedStudentIdAndState, setSelectedStudentIdAndState] = useState({
    id: 0,
    state: MatchingSuggestionState.pending,
  });
  const [canceledReason, setCanceledReason] = useState('');
  const [declinedReason, setDeclinedReason] = useState('');
  const [cancelingId, setCancelingId] = useState(0);

  const [cancelMatching] = useMutation(CANCEL_MATCHING, {
    onCompleted: (data) => {
      setCancelingId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.suggested,
          companyId: user.id,
        },
      });
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'NO_PERMISSION') {
        alert('권한이 없습니다.');
      }
      setCancelingId(0);
      getMatchingsByCompany({
        variables: {
          matchingType: MatchingType.suggested,
          companyId: user.id,
        },
      });
    },
  });

  return (
    <Layout>
      {getMatchingsByCompanyData?.matchingsByCompany?.map((item) => (
        <SuggestionItem
          opened={openedSuggestionId === item.jobVacancy?.id}
          onClick={() =>
            setOpenedSuggestionId(
              openedSuggestionId === item.jobVacancy?.id
                ? 0
                : item.jobVacancy?.id,
            )
          }
        >
          <SuggestionItemTitleLayout
            opened={openedSuggestionId === item.jobVacancy?.id}
          >
            {openedSuggestionId === item.jobVacancy?.id ? (
              <DownOutlined
                style={{
                  fontSize: '24px',
                  color: 'black',
                }}
              />
            ) : (
              <RightOutlined
                style={{
                  fontSize: '24px',
                  color: '#fe706f',
                }}
              />
            )}
            <SuggestionItemTitle>
              {item.jobVacancy?.position}
            </SuggestionItemTitle>
            <Spacer />
            <CountText>
              대기:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingSuggestionState.pending,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              수락:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingSuggestionState.approved,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              거절:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingSuggestionState.declined,
              )?.length ?? 0}
              회
            </CountText>
            <CountText>
              취소:{' '}
              {item.matchings?.filter(
                (item) =>
                  item.matchingState === MatchingSuggestionState.canceled,
              )?.length ?? 0}
              회
            </CountText>
          </SuggestionItemTitleLayout>
          {openedSuggestionId === item.jobVacancy?.id && (
            <>
              <FilterLayout>
                <Spacer />
                {filters.map((item) => (
                  <ToggleButton
                    color={convertStateToComponentStyle(item).color}
                    background={convertStateToComponentStyle(item).background}
                    onClick={(e) => {
                      handleClickToggleButton(item);
                      e.stopPropagation();
                    }}
                  >
                    {toggleFilters.has(item) && <CheckOutlined />}
                    {convertStateToComponentStyle(item).text}
                  </ToggleButton>
                ))}
              </FilterLayout>
              <Divider />
              {item.matchings
                ?.filter((matching) => {
                  const state = matching.matchingState;
                  return toggleFilters.has(state);
                })
                ?.map((suggestion) => (
                  <StudentItem>
                    <DateBadge>{suggestion.createdAt.split(' ')[0]}</DateBadge>
                    <NameText>
                      {suggestion.matchingState !==
                      MatchingSuggestionState.approved
                        ? maskingName(
                            suggestion.student?.basicStudentProfile?.name,
                          )
                        : suggestion.student?.basicStudentProfile?.name}
                    </NameText>
                    <PhoneNumberText>
                      {suggestion.matchingState !==
                      MatchingSuggestionState.approved
                        ? '010-****-****'
                        : suggestion.student?.basicStudentProfile?.phoneNumber}
                    </PhoneNumberText>
                    <CounselStateText
                      color={
                        convertStateToComponentStyle(suggestion.matchingState)
                          .color
                      }
                    >
                      {
                        convertStateToComponentStyle(suggestion.matchingState)
                          .text
                      }
                    </CounselStateText>
                    <Spacer />
                    <CounselRightButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSuggestion(suggestion.interviewSuggestion);
                      }}
                    >
                      제안정보
                    </CounselRightButton>
                    <CounselRightButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedStudentIdAndState({
                          id: suggestion.student?.id,
                          state: suggestion.matchingState,
                        });
                      }}
                    >
                      프로필 보기
                    </CounselRightButton>
                    {(suggestion.matchingState ===
                      MatchingSuggestionState.pending ||
                      suggestion.matchingState ===
                        MatchingSuggestionState.approved) && (
                      <CounselRightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setCancelingId(suggestion.id);
                        }}
                      >
                        취소
                      </CounselRightButton>
                    )}
                    {suggestion.matchingState ===
                      MatchingSuggestionState.declined && (
                      <CounselRightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeclinedReason(suggestion.reasonDeclined);
                        }}
                      >
                        거절 사유
                      </CounselRightButton>
                    )}
                    {suggestion.state === MatchingSuggestionState.canceled && (
                      <CounselRightButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setCanceledReason(suggestion.reasonCanceled);
                        }}
                      >
                        취소 사유
                      </CounselRightButton>
                    )}
                  </StudentItem>
                ))}
              <div style={{ height: '26px' }} />
            </>
          )}
        </SuggestionItem>
      ))}
      <Modal
        isOpen={cancelingId > 0}
        onClose={() => setCancelingId(0)}
        radius="30px"
      >
        <SmallModalWithTextareaLayout
          title="면접 제안 취소"
          description={'면접 제안을 취소하시겠습니까?'}
          onClickLeftButton={() => setCancelingId(0)}
          placeholder={'취소 사유를 입력해주세요.'}
          onClickRightButton={(text) => () => {
            cancelMatching({
              variables: {
                matchingId: cancelingId,
                cancelReason: text,
              },
            });
          }}
        />
      </Modal>
      <Modal
        isOpen={canceledReason !== ''}
        onClose={() => setCanceledReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title="취소 사유"
          description={canceledReason}
          onClickRightButton={() => setCanceledReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={declinedReason !== ''}
        onClose={() => setDeclinedReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title="거절 사유"
          description={declinedReason}
          onClickRightButton={() => setDeclinedReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={selectedStudentIdAndState.id > 0}
        onClose={() =>
          setSelectedStudentIdAndState({
            id: 0,
            state: MatchingSuggestionState.pending,
          })
        }
        closeButtonTransparent
      >
        <ModalLayout>
          <ModalHeader>
            <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>프로필</ModalHeaderTitle>
          </ModalHeader>
          <ModalBody>
            <StudentProfileModal
              id={selectedStudentIdAndState.id}
              hideInformation={
                selectedStudentIdAndState.state !==
                MatchingSuggestionState.approved
              }
            />
          </ModalBody>
        </ModalLayout>
      </Modal>
      <Modal
        isOpen={selectedSuggestion !== null}
        onClose={() => setSelectedSuggestion(null)}
        closeButtonTransparent
      >
        <SuggestionModalLayout>
          <ModalHeader>
            <ModalHeaderTitle>제안 정보</ModalHeaderTitle>
          </ModalHeader>
          <SuggestionModalBody>
            {selectedSuggestion?.message && (
              <>
                <SuggestionRowItem>
                  <span>제안 메시지</span>
                  <p>{selectedSuggestion?.message}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.rank && (
              <>
                <SuggestionRowItem>
                  <span>직급</span>
                  <p>{selectedSuggestion?.rank}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.minSalary && (
              <>
                <SuggestionRowItem>
                  <span>연봉</span>
                  <p>
                    {selectedSuggestion?.minSalary} ~{' '}
                    {selectedSuggestion?.maxSalary} 만원
                  </p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.stockOption && (
              <>
                <SuggestionRowItem>
                  <span>스톡옵션 여부</span>
                  <p>{selectedSuggestion?.stockOption}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
          </SuggestionModalBody>
        </SuggestionModalLayout>
      </Modal>
    </Layout>
  );
};

export default SuggestionTab;
