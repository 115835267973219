import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { GET_COMPANY_PROFILE_WITH_JOB_VACANCIES } from '../../queries/company';
import CompanyProfile from '../Profile/ManageProfile/CompanyProfile';

const Layout = styled.div`
  height: 100%;
`;

const BodyLayout = styled.div`
  margin: ${isMobile ? '0' : '40px 102px'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0 0 35px' : '0'};
  margin-bottom: 32px;
  position: relative;
`;

const CompanyDetailPage = () => {
  const { companyId } = useParams();
  const [getCompany, { data: getCompanyData }] = useLazyQuery(
    GET_COMPANY_PROFILE_WITH_JOB_VACANCIES,
    {
      variables: {
        id: companyId,
      },
    },
  );
  useEffect(() => {
    getCompany({
      variables: {
        id: companyId,
      },
    });
  }, [companyId, getCompany]);
  const { user } = useAuth();
  return (
    <Layout>
      <BodyLayout>
        {getCompanyData && (
          <CompanyProfile
            forStudent={true}
            profile={getCompanyData?.company}
            refetchProfile={() =>
              getCompany({
                variables: {
                  id: companyId,
                },
              })
            }
            userType={UserType.student}
          />
        )}
      </BodyLayout>
    </Layout>
  );
};

export default CompanyDetailPage;
