import { gql } from '@apollo/client';

export const GET_POPUPS = gql`
  query GetPopups {
    popups {
      id
      image {
        id
        name
        url
      }
      url
    }
  }
`;

export const ADD_POPUP = gql`
  mutation AddPopup($popupInput: PopupInput) {
    addPopup(popupInput: $popupInput) {
      id
    }
  }
`;

export const DELETE_POPUP = gql`
  mutation DeletePopup($id: ID) {
    deletePopup(id: $id)
  }
`;
