import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import JobVacancy from './JobVacancy';
import { Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import MobileDisabled from '../MobileDisabled';

const JobVacancyAndMeeting = () => {
  return (
    <Switch>
      <Route exact path="/hire/manage-hire">
        {isMobile ? <MobileDisabled /> : <JobVacancy />}
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default JobVacancyAndMeeting;
