import { gql } from '@apollo/client';

export const GET_FAIRS = gql`
  query GetFairs {
    fairs {
      id
      isOnline
      title
      startAt
      endAt
      companyApplicationStartAt
      companyApplicationEndAt
      studentApplicationStartAt
      studentApplicationEndAt
      image {
        id
        name
        url
      }
      surveyLink
      policy
      introduction
      event {
        description
        winner
        winnerAnnouncedAt
      }
      universities {
        id
        name
        domain
      }
      paymentPlans {
        id
        name
        price
        numberOfBooth
      }
    }
  }
`;

export const GET_FAIR = gql`
  query GetFair($id: ID!) {
    fair(id: $id) {
      id
      isOnline
      title
      startAt
      endAt
      companyApplicationStartAt
      companyApplicationEndAt
      studentApplicationStartAt
      studentApplicationEndAt
      image {
        id
        name
        url
      }
      surveyLink
      policy
      introduction
      event {
        description
        winner
        winnerAnnouncedAt
      }
      universities {
        id
        name
        domain
      }
      paymentPlans {
        id
        name
        price
        numberOfBooth
      }
      stateByDates {
        date
        approvedBoothCount
        closed
      }
    }
  }
`;

export const GET_FAIR_APPLICATIONS = gql`
  query GetFairApplications($fairId: ID, $state: FairApplicationState) {
    fairApplications(fairId: $fairId, state: $state) {
      company {
        logo {
          url
        }
        basicCompanyProfile {
          companyNameKR
        }
      }
      orders {
        applicationDates
      }
    }
  }
`;

export const GET_FAIR_APPLICATIONS_IN_BOOTH_PAGE = gql`
  query GetFairApplications($id: ID) {
    fairApplications(companyId: $id) {
      id
      state
      taxBillEmail
      businessRegistration {
        id
        name
        url
      }
      fair {
        title
        isOnline
        startAt
        endAt
      }
      company {
        basicCompanyProfile {
          companyNameKR
        }
      }
      orders {
        id
        applicationDates
        paymentPlan {
          numberOfBooth
        }
        number
        booths {
          id
          title
        }
      }
    }
  }
`;

// Mutations

export const APPLY_FAIR = gql`
  mutation ApplyFair($fairApplicationInput: FairApplicationInput) {
    applyFair(fairApplicationInput: $fairApplicationInput) {
      id
    }
  }
`;

export const DELETE_FAIR_APPLICATION = gql`
  mutation deleteFairApplication($id: ID!) {
    deleteFairApplication(id: $id)
  }
`;

export const EXHIBIT_BOOTH_TO_FAIR = gql`
  mutation exhibitBoothToFair($fairId: ID!, $dates: [String], $boothIds: [ID]) {
    exhibitBoothToFair(fairId: $fairId, dates: $dates, boothIds: $boothIds)
  }
`;
