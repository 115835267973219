import {
  BookOutlined,
  DownOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import useAuth from '../../hooks/useAuth';
import BannerIllust from '../../img/PresentationIllust.png';
import { GET_COMPANY_PROFILE } from '../../queries/company';
import {
  GET_PRESENTATIONS_BY_COMPANY,
  GET_PRESENTATION_APPLICATIONS_BY_COMPANY,
} from '../../queries/presentation';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';
import InquiryModal from '../Presentation/InquiryModal';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: 37px 48px;
  img {
    right: 20px;
    bottom: 18px;
    width: 220px;
    height: 220px;
    position: absolute;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: 34px;
  width: 150px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const AnnounceBottomButton2 = styled.button`
  margin-top: 24px;
  width: 150px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const ApplicationsLayout = styled.div`
  padding: 0 60px;
  margin-top: 24px;
`;

const ApplicationItem = styled.div`
  width: 100%;
  border: ${(props) =>
    props.isEditing ? '1.5px dashed #ffccc7' : '1.5px solid  #fe706f'};
  border-radius: 10px;
  overflow: hidden;
  cursor: ${(props) => (props.isEditing ? 'pointer' : 'inherit')};
  background-color: white;
  margin-bottom: 20px;
`;

const ApplicationItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const ApplicationItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  cursor: pointer;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: 240px;
`;

const ApplicationItemBodyLayout = styled.div`
  padding: 22px 25px 38px 62px;
  max-height: 400px;
  overflow: scroll;
`;

const StudentItem = styled.div`
  padding: 18px 32px;
  background-color: #fbfbfb;
  border-radius: 10px;
  color: #2e2e2e;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-weight: 500;
  cursor: pointer;
  p {
    max-height: 1.4em;
    line-height: 1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StudentName = styled.p`
  min-width: 58px;
  width: 80px;
  margin-right: 24px;
`;

const StudentProperty = styled.p`
  flex: 1;
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 0;
`;

const CompanyPresentation = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [openedPresentationId, setOpenedPresentationId] = useState(0);
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const { user } = useAuth();
  const [getPresentationsByCompany, { data: getPresentationsByCompanyData }] =
    useLazyQuery(GET_PRESENTATIONS_BY_COMPANY);
  useEffect(() => {
    getPresentationsByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user.id, getPresentationsByCompany]);
  const [applications, setApplications] = useState([]);
  const [getPresentationApplicationsByCompany, { loading }] = useLazyQuery(
    GET_PRESENTATION_APPLICATIONS_BY_COMPANY,
    {
      onCompleted: (data) => {
        setApplications((prev) => {
          if (
            prev.findIndex(
              (item) =>
                item.id === data?.presentationApplicationsByCompany?.[0]?.id,
            ) === -1
          ) {
            return [...prev, ...data?.presentationApplicationsByCompany];
          }
          return prev;
        });
        if (data?.presentationApplicationsByCompany?.length < 30) {
          setFinished(true);
        }
      },
    },
  );
  useEffect(() => {
    setFinished(false);
    setApplications([]);
    if (openedPresentationId !== 0) {
      getPresentationApplicationsByCompany({
        variables: {
          first: 30,
          after: null,
          presentationId: openedPresentationId,
        },
      });
    }
  }, [openedPresentationId]);
  const [target, setTarget] = useState(null);
  const [finished, setFinished] = useState(false);

  const onIntersect = useCallback(
    ([entry], observer) => {
      if (
        entry.isIntersecting &&
        applications.length > 0 &&
        !loading &&
        !finished
      ) {
        getPresentationApplicationsByCompany({
          variables: {
            first: 30,
            after:
              applications.length === 0
                ? null
                : applications[applications.length - 1].id,
            presentationId: openedPresentationId,
          },
        });
      }
    },
    [applications, finished, loading, openedPresentationId],
  );
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [onIntersect, target, user.id]);
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  useEffect(() => {
    if (user.id) {
      getCompanyProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyProfile, user]);
  const history = useHistory();
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  return (
    <Layout>
      <TitleText>설명회 신청 내역</TitleText>
      {getPresentationsByCompanyData?.presentationsByCompany?.length === 0 ? (
        <>
          <AnnounceLayout>
            <AnnounceTitleLayout>
              <Icon icon={'edit'} size="21px" color="#ff434e" />
              <p>설명회를 신청해주세요!</p>
            </AnnounceTitleLayout>
            <AnnounceBodyText>
              아직 설명회를 신청하지 않으셨습니다.
              <br />
              관리자에게 문의하여 설명회를 신청하고 인재를 만나보세요!
            </AnnounceBodyText>
            {companyProfileData && (
              <AnnounceBottomButton
                onClick={() => {
                  if (
                    !Boolean(companyProfileData?.company?.managerInfo?.name) ||
                    !Boolean(
                      companyProfileData?.company?.basicCompanyProfile
                        ?.companyNameKR,
                    )
                  ) {
                    setIsWarningModalOpened(true);
                  } else {
                    setIsModalOpened(true);
                  }
                }}
              >
                <p>설명회 신청 문의</p>
              </AnnounceBottomButton>
            )}

            <img src={BannerIllust} alt="banner" />
          </AnnounceLayout>
        </>
      ) : (
        <>
          {companyProfileData && (
            <AnnounceBottomButton2
              onClick={() => {
                if (
                  !Boolean(companyProfileData?.company?.managerInfo?.name) ||
                  !Boolean(
                    companyProfileData?.company?.basicCompanyProfile
                      ?.companyNameKR,
                  )
                ) {
                  setIsWarningModalOpened(true);
                } else {
                  setIsModalOpened(true);
                }
              }}
            >
              <p>설명회 신청 문의</p>
            </AnnounceBottomButton2>
          )}
        </>
      )}
      <ApplicationsLayout>
        {getPresentationsByCompanyData?.presentationsByCompany?.map((item) => (
          <ApplicationItem
            key={item.id}
            opened={openedPresentationId === item.id}
            onClick={() => {
              setOpenedPresentationId(
                openedPresentationId === item.id ? 0 : item.id,
              );
            }}
          >
            <ApplicationItemTitleLayout
              opened={openedPresentationId === item.id}
            >
              {openedPresentationId === item.id ? (
                <DownOutlined
                  style={{
                    fontSize: '24px',
                    color: 'black',
                  }}
                />
              ) : (
                <RightOutlined
                  style={{
                    fontSize: '24px',
                    color: '#fe706f',
                  }}
                />
              )}
              <ApplicationItemTitle>
                {item.date} {item.title}
              </ApplicationItemTitle>
            </ApplicationItemTitleLayout>
            {openedPresentationId === item.id && (
              <ApplicationItemBodyLayout>
                {applications.map((application) => (
                  <StudentItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedStudentId(23);
                    }}
                  >
                    <StudentName>
                      {application?.student?.basicStudentProfile?.name}
                    </StudentName>
                    <StudentProperty>
                      {application?.student?.email}
                    </StudentProperty>
                    <StudentProperty>
                      {application?.student?.basicStudentProfile?.phoneNumber}
                    </StudentProperty>
                    <StudentProperty>
                      {application?.student?.educations?.[0]?.university}{' '}
                      {application?.student?.educations?.[0]?.major}{' '}
                      {application?.student?.educations?.[0]?.degree}
                    </StudentProperty>
                  </StudentItem>
                ))}
                <div ref={setTarget} className="Target-Element" />
              </ApplicationItemBodyLayout>
            )}
          </ApplicationItem>
        ))}
      </ApplicationsLayout>
      <Modal isOpen={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <InquiryModal close={() => setIsModalOpened(false)} />
      </Modal>
      <Modal
        isOpen={selectedStudentId !== 0}
        onClose={() => {
          setSelectedStudentId(0);
        }}
        closeButtonTransparent
      >
        <ModalLayout>
          <ModalHeader>
            <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>프로필</ModalHeaderTitle>
          </ModalHeader>
          <ModalBody>
            <StudentProfileModal id={selectedStudentId} />
          </ModalBody>
        </ModalLayout>
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => history.push('/profile/manage')}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default CompanyPresentation;
