import React, { useContext, createContext, useState } from 'react';

const authContext = createContext({
  user: undefined,
  setUser: undefined,
});

const useAuth = () => {
  return useContext(authContext);
};

export default useAuth;

function useProvideAuth() {
  const [user, setUser] = useState({ id: 0, email: '', userType: '' });

  return {
    user,
    setUser,
  };
}

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
