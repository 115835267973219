import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { Tag, Button } from 'antd';
import { useLazyQuery } from '@apollo/client';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  BookOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { GET_EXHIBITED_BOOTH } from '../../queries/booth';
import Modal from '../../components/Modal';
import JobVacancyModal from './JobVacancyModal';
import { ReservationState, UserType } from '../../constants/static';
import CounselModal from './CounselModal';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { GET_STUDENT_PROFILE } from '../../queries/student';
import { httpUrl } from '../../constants/utils';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #fcfcfc;
  padding: ${isMobile ? '34px 0 0' : '84px 185px 0'};
`;

const ContentLayout = styled.div`
  width: 100%;
  background-color: white;
  padding: ${isMobile ? '18px 30px' : '53px 0 31px'};
  margin-bottom: ${isMobile ? '' : '48px'};
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  display: ${isMobile ? 'flex' : 'inherit'};
  flex-flow: ${isMobile ? 'column' : ''};
`;

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 500;
    font-size: 17px;
    margin-right: 26px;
  }
  margin-left: ${isMobile ? '' : '48px'};
  button {
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

const BodyLayout = styled.div`
  margin-top: 26px;
  margin-left: ${isMobile ? '' : '95px'};
  white-space: pre-wrap;
`;

const RemovePaddingBodyLayout = styled.div`
  margin-top: 26px;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #ededed;
  margin: 35px 0;
`;

const ModalBodyLayout = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const LogoLayout = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${isMobile ? '' : '46px'};
  div {
    width: 80px;
    height: 80px;
    min-width: 80px;
    border-radius: 10px;
    background-color: #fdfdfd;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 80px;
      max-width: 80px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 30px;
      margin-top: 30px;
      padding: 0;
      background-color: transparent;
    }
    div {
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
  span {
    margin-left: 33px;
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
  p {
    margin-top: 4px;
    font-weight: 500;
    font-size: 20px;
  }
`;

const BasicProfileLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
`;

const ImagesLayout = styled.div`
  div {
    margin: ${isMobile ? '0 auto' : '0 0 0 60px'};
    width: 180px;
    height: 180px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    padding: 14px;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 152px;
      max-width: 152px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 64px;
      margin-top: 64px;
      padding: 0;
      background-color: transparent;
    }
    div {
      top: -15px;
      left: -75px;
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
`;

const ImagesBottomLayout = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  margin: ${isMobile ? '5px auto' : '5px 0 0 60px'};
  button {
    width: 32px;
    height: 32px;
    padding: 0 9px;
    margin: 0 22px;
  }
  div {
    flex: 1;
  }
`;

const BasicProfileContentLayout = styled.div`
  margin: ${isMobile ? '20px 0 0 0' : '10px 0 0 77px'};
`;

const BasicProfileItemLayout = styled.div`
  display: grid;
  grid-template-columns: ${isMobile ? 'repeat(1, 1fr)' : 'repeat(1, 1fr)'};
  div {
    min-width: ${isMobile ? '' : '259px'};
    margin-right: 40px;
    display: flex;
    align-items: ${isMobile ? 'top' : 'center'};
    margin-bottom: ${isMobile ? '12px' : '30px'};
    p {
      font-weight: 500;
      width: 100px;
      min-width: 100px;
      margin-right: 4px;
    }
    span {
      width: ${isMobile ? 'calc(100% - 100px)' : ''} !important;
      white-space: pre-wrap;
      word-break: break-all;
    }
    a {
      width: ${isMobile ? 'calc(100% - 100px)' : ''} !important;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`;

const ManagerInfoLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  div {
    width: 259px;
    display: flex;
    align-items: center;
    margin-bottom: ${isMobile ? '12px' : ''};
    p {
      font-weight: 500;
      width: 52px;
      margin-right: 10px;
    }
  }
`;

const RecruitmentTypeItem = styled(Tag)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  height: 22px;
  border: none;
  color: #000000;
  margin-bottom: 19px;
  height: 22px;
  font-size: 14px;
  font-weight: 500;
`;

const LinkItemLayout = styled.div`
  margin: ${isMobile ? '0 0 21px 0' : '21px 0 0 0'};
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  p {
    min-width: 188px;
    margin-right: 22px;
    font-weight: 500;
  }
  a {
    color: #f88988;
    cursor: pointer;
    text-decoration: none;
  }
`;

const QnaLayout = styled.div`
  margin-right: ${isMobile ? '' : '95px'};
  border-radius: 10px;
  border: 1px solid #d9d9d9;
`;

const QnaRow = styled.div`
  padding: 12px 24px;
  font-weight: 500;
  white-space: pre-wrap;
  word-break: break-all;
  p {
    color: #f88988;
  }
`;

const QnaDivider = styled.div`
  height: 1px;
  background-color: #d9d9d9;
`;

const CouncilButton = styled(Button)`
  border-radius: 10px;
  width: 176px;
  height: 53px;
  margin: ${isMobile ? '28px auto 0' : '0 44px 0 auto'};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  justify-content: center !important;
  color: white;
  span {
    margin: 0;
  }
`;

const JobVancyItem = styled.div`
  cursor: pointer;
  margin: ${isMobile ? '12px 0 0' : '10px 54px 0'};
  border-radius: 10px;
  background-color: #fff1f0;
  padding: 13px 27px;
  font-family: Noto Sans KR;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #373334;
  flex: 1;
`;

const BoothDetail = () => {
  const { boothId } = useParams();
  const { user } = useAuth();
  const [getExhbitedBooth, { data }] = useLazyQuery(GET_EXHIBITED_BOOTH);
  const [getStudentProfile, { data: getStudentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  useEffect(() => {
    if (user.userType === UserType.student) {
      getStudentProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [user, getStudentProfile]);
  const [isCounselModalOpened, setIsCounselModalOpened] = useState(false);
  useEffect(() => {
    if (boothId && !isCounselModalOpened) {
      getExhbitedBooth({
        variables: {
          id: boothId,
        },
      });
    }
  }, [boothId, getExhbitedBooth, isCounselModalOpened]);
  const isDisabled = useMemo(() => {
    let disabled = false;
    if (data) {
      data?.exhibitedBooth?.dateAndCounselReservations?.forEach((item) => {
        item.counselReservations.forEach((subItem) => {
          if (
            subItem.student.id == user.id &&
            (subItem.state === ReservationState.pending ||
              subItem.state === ReservationState.approved)
          ) {
            disabled = true;
          }
        });
      });
      let domains = [];
      data?.exhibitedBooth?.fair?.universities?.forEach(
        (univ) => (domains = domains.concat(univ.domain.split(','))),
      );
      if (
        domains.length > 0 &&
        domains.findIndex((item) => item === user.email.split('@')[1]) === -1
      ) {
        disabled = true;
      }
      const now = moment().startOf('day');

      if (
        !(
          now.startOf('day').format('YYYY-MM-DD') ===
            data?.exhibitedBooth?.fair?.studentApplicationStartAt ||
          now.startOf('day').format('YYYY-MM-DD') ===
            data?.exhibitedBooth?.fair?.studentApplicationEndAtt ||
          (now.isAfter(
            moment(data?.exhibitedBooth?.fair?.studentApplicationStartAt),
          ) &&
            moment(
              data?.exhibitedBooth?.fair?.studentApplicationEndAtt,
            ).isAfter(now))
        )
      ) {
        disabled = true;
      }
    }
    return disabled;
  }, [data, user]);
  const profile = data?.exhibitedBooth?.booth?.company;
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedJobVacancy, setSelectedJobVacancy] = useState(null);
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  const history = useHistory();
  return (
    <Layout>
      {data &&
        (user.userType === UserType.student
          ? getStudentProfileData !== undefined
          : true) && (
          <ContentLayout>
            <LogoLayout>
              <div>
                {profile?.logo?.url ? (
                  <img src={profile?.logo?.url} alt="logo" />
                ) : (
                  <p
                    style={{
                      position: 'absolute',
                      fontSize: '12px',
                      marginTop: '18px',
                      marginLeft: '20px',
                      textAlign: 'center',
                    }}
                  >
                    로고가
                    <br />
                    없습니다
                  </p>
                )}
              </div>
              <span>
                {profile?.basicCompanyProfile?.companyNameKR} (
                {profile?.basicCompanyProfile?.companyNameEN})
                <br />
                <p>{data?.exhibitedBooth?.booth?.title}</p>
              </span>
              {user.userType === UserType.student && !isMobile && (
                <CouncilButton
                  type="primary"
                  disabled={isDisabled}
                  onClick={() => {
                    if (
                      Boolean(
                        getStudentProfileData?.student?.basicStudentProfile
                          ?.name,
                      )
                    ) {
                      setIsCounselModalOpened(true);
                    } else {
                      setIsWarningModalOpened(true);
                    }
                  }}
                >
                  상담 신청
                </CouncilButton>
              )}
            </LogoLayout>
            {user.userType === UserType.student && isMobile && (
              <CouncilButton
                type="primary"
                disabled={isDisabled}
                onClick={() => {
                  if (
                    Boolean(
                      getStudentProfileData?.student?.basicStudentProfile?.name,
                    )
                  ) {
                    setIsCounselModalOpened(true);
                  } else {
                    setIsWarningModalOpened(true);
                  }
                }}
              >
                상담 신청
              </CouncilButton>
            )}
            <Divider />
            <TitleLayout>
              <p>관련 채용 공고</p>
            </TitleLayout>
            {data?.exhibitedBooth?.booth?.jobVacancies?.map((item) => (
              <JobVancyItem
                onClick={() => setSelectedJobVacancy(item)}
                key={item.id}
              >
                {item.position}
              </JobVancyItem>
            ))}
            <Divider />
            <TitleLayout>
              <p>기본 정보</p>
            </TitleLayout>
            <RemovePaddingBodyLayout>
              <BasicProfileLayout>
                <div>
                  <ImagesLayout>
                    <div>
                      {profile?.images?.length > 0 ? (
                        <img
                          src={profile?.images?.[imageIndex]?.url}
                          alt="images"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsImageModalOpened(true);
                          }}
                        />
                      ) : (
                        <p style={{ position: 'absolute' }}>
                          등록된 이미지가 없습니다
                        </p>
                      )}
                    </div>
                  </ImagesLayout>
                  <ImagesBottomLayout>
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === 0 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex - 1)}
                    >
                      <LeftOutlined />
                    </Button>
                    <div />
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === profile?.images.length - 1 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex + 1)}
                    >
                      <RightOutlined />
                    </Button>
                  </ImagesBottomLayout>
                </div>
                <BasicProfileContentLayout>
                  <BasicProfileItemLayout>
                    <div>
                      <p>홈페이지</p>
                      <a
                        href={httpUrl(profile?.basicCompanyProfile?.homePage)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {profile?.basicCompanyProfile?.homePage}
                      </a>
                    </div>
                    {/* <div>
                    <p>사업자 등록번호</p>
                    {profile?.basicCompanyProfile?.registrationNumber}
                  </div> */}
                  </BasicProfileItemLayout>
                  <BasicProfileItemLayout>
                    <div>
                      <p>산업분야</p>
                      {profile?.basicCompanyProfile?.domain}
                    </div>
                    {/* <div>
                    <p>전화번호</p>
                    {profile?.basicCompanyProfile?.phoneNumber}
                  </div> */}
                  </BasicProfileItemLayout>
                  {/* <BasicProfileItemLayout>
                  <div>
                    <p>주소</p>
                    {profile?.basicCompanyProfile?.address}
                  </div>
                </BasicProfileItemLayout> */}
                </BasicProfileContentLayout>
              </BasicProfileLayout>
            </RemovePaddingBodyLayout>
            <Divider />
            <TitleLayout>
              <p>담당자 정보</p>
            </TitleLayout>
            <BodyLayout>
              <ManagerInfoLayout>
                <div>
                  <p>이름</p>
                  {data?.exhibitedBooth?.booth?.managerInfo?.name}
                </div>
                <div>
                  <p>연락처</p>
                  {data?.exhibitedBooth?.booth?.managerInfo?.phoneNumber}
                </div>
                <div>
                  <p>이메일</p>
                  {data?.exhibitedBooth?.booth?.managerInfo?.email}
                </div>
              </ManagerInfoLayout>
            </BodyLayout>
            <Divider />
            <TitleLayout>
              <p>기업 및 서비스 소개</p>
            </TitleLayout>
            <BodyLayout>
              <p style={{ marginRight: isMobile ? '' : '95px' }}>
                {profile?.introduction}
              </p>
            </BodyLayout>
            <Divider />
            <TitleLayout>
              <p>채용 형태</p>
            </TitleLayout>
            <BodyLayout>
              {profile?.recruitmentTypes?.map((type, index) => (
                <RecruitmentTypeItem key={index}>{type}</RecruitmentTypeItem>
              ))}
            </BodyLayout>
            <Divider />
            <TitleLayout>
              <p>복지 및 혜택</p>
            </TitleLayout>
            <BodyLayout>
              <p style={{ marginRight: isMobile ? '' : '95px' }}>
                {profile?.welfare}
              </p>
            </BodyLayout>
            <Divider />
            <TitleLayout>
              <p>기타 영상 및 URL</p>
            </TitleLayout>
            <BodyLayout>
              {profile?.urls
                ?.filter((item) => item.name !== '' && item.link !== '')
                .map((url, index) => (
                  <LinkItemLayout key={index}>
                    <p>{url.name}</p>
                    <a
                      href={httpUrl(url.link)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {url.link}
                    </a>
                  </LinkItemLayout>
                ))}
            </BodyLayout>
            <Divider />
            <TitleLayout>
              <p>자주 묻는 질문</p>
            </TitleLayout>
            <BodyLayout>
              {profile?.qnas?.length > 0 && (
                <QnaLayout>
                  <QnaRow>질문</QnaRow>
                  {profile?.qnas?.map((qna) => (
                    <>
                      <QnaDivider />
                      <QnaRow>
                        <p>{qna.question}</p>
                      </QnaRow>
                      <QnaRow>:{qna.answer}</QnaRow>
                    </>
                  ))}
                </QnaLayout>
              )}
            </BodyLayout>
          </ContentLayout>
        )}
      {selectedJobVacancy && (
        <Modal
          isOpen={selectedJobVacancy !== null}
          onClose={() => setSelectedJobVacancy(null)}
          width={isMobile ? '343px' : '892px'}
          height="528px"
          radius={isMobile ? '10px' : '30px'}
          closeButtonTransparent
        >
          {JobVacancyModal({
            jobVacancy: selectedJobVacancy,
          })}
        </Modal>
      )}
      {data?.exhibitedBooth && (
        <Modal
          isOpen={isCounselModalOpened}
          onClose={() => setIsCounselModalOpened(false)}
          width={isMobile ? '343px' : '971px'}
          height="528px"
          radius="30px"
          closeButtonTransparent
        >
          <CounselModal
            booth={data?.exhibitedBooth}
            onClose={() => setIsCounselModalOpened(false)}
          />
        </Modal>
      )}
      <Modal
        isOpen={isImageModalOpened}
        onClose={() => setIsImageModalOpened(false)}
        width={isMobile ? 'calc(100% - 40px)' : '600px'}
        height="600px"
        radius="30px"
        closeButtonTransparent
      >
        <div
          style={{
            height: '600px',
            width: isMobile ? '100%' : '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            position: 'relative',
          }}
        >
          <img
            style={{
              maxWidth: isMobile ? '100%' : '600px',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            src={profile?.images?.[imageIndex]?.url}
            alt="selected"
          />
          <Button
            type="primary"
            disabled={
              profile?.images === undefined ||
              imageIndex === profile?.images.length - 1 ||
              profile?.images.length === 0
            }
            style={{
              width: '40px',
              height: '40px',
              padding: '4px',
              backgroundColor: '#efefef',
              position: 'absolute',
              borderColor: '#efefef',
              opacity: '80%',
              top: '276px',
              right: '16px',
            }}
            onClick={() => setImageIndex(imageIndex + 1)}
          >
            <RightOutlined style={{ fontSize: '32px' }} />
          </Button>
          <Button
            disabled={
              profile?.images === undefined ||
              imageIndex === 0 ||
              profile?.images.length === 0
            }
            onClick={() => setImageIndex(imageIndex - 1)}
            type="primary"
            style={{
              width: '40px',
              height: '40px',
              padding: '4px',
              opacity: '80%',
              backgroundColor: '#efefef',
              position: 'absolute',
              borderColor: '#efefef',
              top: '276px',
              left: '16px',
            }}
          >
            <LeftOutlined style={{ fontSize: '32px' }} />
          </Button>
        </div>
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => setIsWarningModalOpened(false)}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 상담 신청이 가능합니다.
            </p>
          </div>

          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default BoothDetail;
