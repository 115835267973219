import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useAuth from '../../../hooks/useAuth';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import {
  CheckOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { convertStateToNumber } from '../../../constants/utils';
import Modal from '../../../components/Modal';
import StudentProfileModal from './StudentProfileModal';
import ZoomLinkModal from './ZoomLinkModal';
import CounselMemoModal from './CounselMemoModal';
import DeclineModal from './DeclineModal';
import ApproveModal from './ApproveModal';
import {
  COMPLETE_COUNSEL_RESERVATION,
  SUBSCRIBE_COUNSEL_STATE_CHANGED,
} from '../../../queries/counsel';
import moment from 'moment';
import { ReservationState, UserType } from '../../../constants/static';
import {
  GET_EXHIBITED_BOOTHS_BY_COMPANY,
  GET_EXHIBITED_BOOTHS_BY_COUNSELOR,
} from '../../../queries/booth';
import MethodModal from './MethodModal';
import { DOWNLOAD_COUNSELS_EXCEL } from '../../../queries/excel';
import SendMessageModal from './SendMessageModal';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: 24px;
`;

const ListLayout = styled.div`
  margin: 0 61px;
`;

const BoothItem = styled.div`
  width: 100%;
  border: 1.5px solid #fe706f;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
`;

const BoothItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const BoothItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  span {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const DateBadge = styled.div`
  border-radius: 10px;
  background-color: ${(props) => (props.isOpened ? 'white' : '#fbfbfb')};
  padding: 5px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
  margin-right: 16px;
`;

const OnlineBadge = styled.div`
  border-radius: 10px;
  background: ${(props) => (props.isOnline ? '#FFF7E6' : '#F9F0FF')};
  border: 1px solid ${(props) => (props.isOnline ? '#FFA171' : '#D3ADF7')};
  color: ${(props) => (props.isOnline ? '#FFA171' : '#722ED1')};
  font-weight: 500;
  font-size: 12px;
  margin-right: auto;
  margin-left: 0;
  padding: 0 8px;
  height: 22px;
  margin-right: 16px;
`;

const WaitingText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4d4f;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 20px 0;
`;

const ZoomLinkLayout = styled.div`
  margin: 23px 77px 14px;
  display: flex;
  align-items: center;
`;

const ZoomLinkButton = styled(Button)`
  margin-right: 12px;
  font-family: Noto Sans KR;
  line-height: 20px;
  letter-spacing: 0em;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 77px;
`;

const ToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background-color: ${(props) => props.background};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  height: 22px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const CounselItem = styled.div`
  height: 59px;
  border-radius: 10px;
  background-color: #fbfbfb;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  margin: 0 62px 10px;
`;

const CounselDateBadge = styled.div`
  width: 144px;
  height: 32px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  p {
    width: 44px;
  }
`;

const CounselStateText = styled.p`
  margin-left: 20px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  width: 96px;
  text-align: left;
  color: ${(props) => props.color};
`;

const CounselRightButton = styled.button`
  cursor: pointer;
  margin-left: 8px;
  height: 32px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GuideText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #fe706f;
    margin-right: 8px;
  }
`;

const StudentText = styled.p`
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const filters = [
  ReservationState.proceeding,
  ReservationState.pending,
  ReservationState.completed,
  ReservationState.approved,
  ReservationState.declined,
  ReservationState.canceled,
];
const convertStateToComponentStyle = (state) => {
  switch (state) {
    case ReservationState.proceeding:
      return {
        text: '상담중',
        color: '#52c41a',
        background: '#f6ffed',
      };
    case ReservationState.pending:
      return { text: '승인대기', color: '#ff4d4f', background: '#ffccc7' };
    case ReservationState.completed:
      return { text: '상담완료', color: '#373334', background: '#f2f2f2' };
    case ReservationState.approved:
      return { text: '승인', color: '#57a0f5', background: '#e6f7ff' };
    case ReservationState.declined:
      return { text: '반려', color: '#820014', background: '#ffccc7' };
    case ReservationState.canceled:
      return { text: '취소', color: '#b9b9b9', background: '#fdfdfd' };
    default:
      return {
        text: '상담중',
        color: '#52c41a',
        background: '#f6ffed',
      };
  }
};

const ManageCounsel = () => {
  const [openedBooth, setOpenedBooth] = useState({
    id: 0,
    date: '',
    fairId: '',
    isOnline: false,
    zoomLink: '',
    counselMethod: '',
  });

  const [
    getExhibitedBoothsByCompany,
    { data: getExhibitedBoothsByCompanyData },
  ] = useLazyQuery(GET_EXHIBITED_BOOTHS_BY_COMPANY);
  const [
    getExhibitedBoothsByCounselor,
    { data: getExhibitedBoothsByCounselorData },
  ] = useLazyQuery(GET_EXHIBITED_BOOTHS_BY_COUNSELOR);
  const [completeCounselReservation, { data: completeCounselReservationData }] =
    useMutation(COMPLETE_COUNSEL_RESERVATION);
  const { user } = useAuth();
  const { data: subscribeCounselStateChanged } = useSubscription(
    SUBSCRIBE_COUNSEL_STATE_CHANGED,
    {
      variables: {
        userId: user.id,
      },
    },
  );
  useEffect(() => {
    console.log(subscribeCounselStateChanged);

    if (subscribeCounselStateChanged) {
      if (user.userType === UserType.company) {
        getExhibitedBoothsByCompany({
          variables: {
            companyId: user.id,
          },
        });
      } else if (user.userType === UserType.counselor) {
        getExhibitedBoothsByCounselor({
          variables: {
            counselorId: user.id,
          },
        });
      }
    }
  }, [user, subscribeCounselStateChanged]);
  const booths = useMemo(() => {
    if (user.userType === UserType.company) {
      if (getExhibitedBoothsByCompanyData) {
        let array = [];
        getExhibitedBoothsByCompanyData?.exhibitedBoothsByCompany?.forEach(
          (item) => {
            item.dateAndCounselReservations?.forEach((subItem) => {
              array.push({
                date: subItem.date,
                fairTitle: item?.fair?.title ?? '',
                fairId: item?.fair?.id ?? '',
                isOnline: item?.fair?.isOnline ?? false,
                boothTitle: item?.booth?.title ?? '',
                exhibitedBoothId: item?.id,
                boothId: item?.booth?.id ?? 0,
                boothZoomLink: item?.booth?.zoomLink ?? '',
                boothCounselMethod: item?.booth?.counselMethod ?? '',
                counselReservations:
                  [...subItem.counselReservations]?.sort((a, b) =>
                    moment(a.startAt).diff(moment(b.startAt)),
                  ) ?? [],
              });
            });
          },
        );
        return [...array].sort((a, b) => -moment(a.date).diff(moment(b.date)));
      }
    } else if (user.userType === UserType.counselor) {
      let array = [];
      getExhibitedBoothsByCounselorData?.exhibitedBoothsByCounselor?.forEach(
        (item) => {
          item.dateAndCounselReservations?.forEach((subItem) => {
            array.push({
              date: subItem.date,
              fairTitle: item?.fair?.title ?? '',
              fairId: item?.fair?.id ?? '',
              isOnline: item?.fair?.isOnline ?? false,
              boothTitle: item?.booth?.title ?? '',
              exhibitedBoothId: item?.id,
              boothId: item?.booth?.id ?? 0,
              boothZoomLink: item?.booth?.zoomLink ?? '',
              boothCounselMethod: item?.booth?.counselMethod ?? '',
              counselReservations:
                [...subItem.counselReservations]?.sort((a, b) =>
                  moment(a.startAt).diff(moment(b.startAt)),
                ) ?? [],
            });
          });
        },
      );
      return [...array].sort((a, b) => -moment(a.date).diff(moment(b.date)));
    }
    return [];
  }, [
    getExhibitedBoothsByCompanyData,
    user.userType,
    getExhibitedBoothsByCounselorData,
  ]);
  const [toggleFilters, setToggleFilters] = useState(
    new Set([
      ReservationState.proceeding,
      ReservationState.pending,
      ReservationState.completed,
      ReservationState.approved,
      ReservationState.declined,
      ReservationState.canceled,
    ]),
  );
  const handleClickToggleButton = (item) => {
    if (!toggleFilters.has(item)) {
      setToggleFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  useEffect(() => {
    if (user.userType === UserType.company) {
      getExhibitedBoothsByCompany({
        variables: {
          companyId: user.id,
        },
      });
    } else if (user.userType === UserType.counselor) {
      getExhibitedBoothsByCounselor({
        variables: {
          counselorId: user.id,
        },
      });
    }
  }, [user, getExhibitedBoothsByCompany, getExhibitedBoothsByCounselor]);
  useEffect(() => {
    if (completeCounselReservationData) {
      if (user.userType === UserType.company) {
        getExhibitedBoothsByCompany({
          variables: {
            companyId: user.id,
          },
        });
      } else if (user.userType === UserType.counselor) {
        getExhibitedBoothsByCounselor({
          variables: {
            counselorId: user.id,
          },
        });
      }
    }
  }, [
    user,
    completeCounselReservationData,
    getExhibitedBoothsByCompany,
    getExhibitedBoothsByCounselor,
  ]);
  const [zoomLinkItem, setZoomLinkItem] = useState({
    id: 0,
    link: '',
    counselMethod: '',
  });
  const [selectedMemoModal, setSelectedMemoModal] = useState({
    id: 0,
    memo: '',
  });
  const [selectedApproveModal, setSelectedApproveModal] = useState({
    id: 0,
    boothName: '',
  });
  const [selectedDeclineModal, setSelectedDeclineModal] = useState({
    id: 0,
    boothName: '',
  });
  useEffect(() => {
    if (
      openedBooth.id > 0 &&
      openedBooth.isOnline &&
      openedBooth.zoomLink === '' &&
      openedBooth.counselMethod === ''
    ) {
      setZoomLinkItem({
        id: openedBooth.id,
        link: '',
        counselMethod: '',
      });
    }
  }, [openedBooth]);
  const [method, setMethod] = useState(
    {
      opened: false,
      link: '',
      method: '',
    },
    [],
  );
  const [downloadCounselsExcel, { data: downloadCounselsExcelData }] =
    useMutation(DOWNLOAD_COUNSELS_EXCEL);
  useEffect(() => {
    if (downloadCounselsExcelData) {
      window.open(
        downloadCounselsExcelData?.downloadCounselsExcel?.url,
        '_blank',
      );
    }
  }, [downloadCounselsExcelData]);
  const [sendMessageReservationId, setSendMessageReservationId] = useState(0);
  return (
    <Layout>
      <TitleText>상담 관리</TitleText>
      <GuideText>
        <div />
        온라인 박람회 시작 전 꼭 Zoom Link 혹은 기타 상담 방법 작성을
        부탁드립니다.
      </GuideText>
      <GuideText>
        <div />
        상담 승인 및 반려시 학생에게 문자가 전송됩니다.
      </GuideText>
      <GuideText>
        <div />
        상담 10분전에 학생에게 문자가 전송되고, 온라인 일시 학생에게 영상통화에
        입장할 수 있는 링크가 공개됩니다.
      </GuideText>
      <GuideText>
        <div />
        학생과 상담을 완료한 후에는 상담완료 버튼을 클릭하여야만 관련 정보를
        엑셀로 다운 받을 수 있습니다.
      </GuideText>
      <div style={{ height: '24px' }} />
      <ListLayout>
        {booths?.map((item) => (
          <BoothItem
            key={item.date.concat(item.boothId)}
            opened={
              openedBooth.id === item.boothId &&
              openedBooth.date === item.date &&
              openedBooth.fairId === item.fairId
            }
            onClick={() => {
              setOpenedBooth(
                openedBooth.id === item.boothId &&
                  openedBooth.date === item.date &&
                  openedBooth.fairId === item.fairId
                  ? {
                      id: 0,
                      fairId: '',
                      isOnline: false,
                      date: '',
                      zoomLink: '',
                      counselMethod: '',
                    }
                  : {
                      id: item.boothId,
                      isOnline: item.isOnline,
                      fairId: item.fairId,
                      date: item.date,
                      zoomLink: item.boothZoomLink,
                      counselMethod: item.boothCounselMethod,
                    },
              );
            }}
          >
            <BoothItemTitleLayout
              opened={
                openedBooth.id === item.boothId &&
                openedBooth.date === item.date &&
                openedBooth.fairId === item.fairId
              }
            >
              {openedBooth.id === item.boothId &&
              openedBooth.date === item.date &&
              openedBooth.fairId === item.fairId ? (
                <DownOutlined
                  style={{
                    fontSize: '24px',
                    color: 'black',
                  }}
                />
              ) : (
                <RightOutlined
                  style={{
                    fontSize: '24px',
                    color: '#fe706f',
                  }}
                />
              )}
              <BoothItemTitle
                opened={
                  openedBooth.id === item.boothId &&
                  openedBooth.date === item.date &&
                  openedBooth.fairId === item.fairId
                }
              >
                {item.fairTitle} <br />
                <span>{item.boothTitle}</span>
              </BoothItemTitle>
              <Spacer />
              <DateBadge
                isOpened={
                  openedBooth.id === item.boothId &&
                  openedBooth.date === item.date &&
                  openedBooth.fairId === item.fairId
                }
              >
                {item.date}
              </DateBadge>
              <OnlineBadge isOnline={item.isOnline}>
                {item.isOnline ? '온라인' : '오프라인'}
              </OnlineBadge>
              <WaitingText>
                승인대기{' '}
                {
                  item.counselReservations.filter(
                    (reservation) =>
                      reservation.state === ReservationState.pending,
                  ).length
                }
                명
              </WaitingText>
            </BoothItemTitleLayout>
            {openedBooth.id === item.boothId &&
              openedBooth.date === item.date &&
              openedBooth.fairId === item.fairId && (
                <>
                  <ZoomLinkLayout>
                    {item.isOnline && (
                      <>
                        {item.boothZoomLink === '' &&
                        item.boothCounselMethod === '' ? (
                          <p style={{ color: 'grey', marginRight: '20px' }}>
                            상담 방법이 없습니다
                          </p>
                        ) : (
                          <ZoomLinkButton
                            onClick={(e) => {
                              setMethod({
                                opened: true,
                                link: item.boothZoomLink,
                                method: item.boothCounselMethod,
                              });
                              e.stopPropagation();
                            }}
                            type="primary"
                            style={{ borderRadius: '10px' }}
                          >
                            온라인 상담 방법
                          </ZoomLinkButton>
                        )}
                        <p style={{ marginRight: '12px', color: 'grey' }}>
                          {item.boothZoomLink === ''
                            ? '상담 링크가 없습니다'
                            : item.boothZoomLink}
                        </p>
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            setZoomLinkItem({
                              id: item.boothId,
                              link: item.boothZoomLink,
                              counselMethod: item.boothCounselMethod,
                            });
                          }}
                          type="link"
                          style={{ padding: 0 }}
                        >
                          <EditOutlined
                            style={{ color: '#ffa39e', fontSize: '20px' }}
                          />
                        </Button>
                      </>
                    )}
                  </ZoomLinkLayout>
                  <FilterLayout>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadCounselsExcel({
                          variables: {
                            exhibitedBoothId: item.exhibitedBoothId,
                            date: item.date,
                          },
                        });
                      }}
                      type="primary"
                      style={{ borderRadius: '10px' }}
                    >
                      Excel 다운로드
                    </Button>
                    <Spacer />
                    {filters.map((item) => (
                      <ToggleButton
                        color={convertStateToComponentStyle(item).color}
                        background={
                          convertStateToComponentStyle(item).background
                        }
                        onClick={(e) => {
                          handleClickToggleButton(item);
                          e.stopPropagation();
                        }}
                      >
                        {toggleFilters.has(item) && <CheckOutlined />}
                        {convertStateToComponentStyle(item).text}
                      </ToggleButton>
                    ))}
                  </FilterLayout>
                  <Divider />
                  {item.counselReservations
                    ?.filter((reservation) => {
                      const state = reservation.state;
                      return toggleFilters.has(state);
                    })
                    .map((reservation) => (
                      <CounselItem key={reservation.id}>
                        <CounselDateBadge>
                          <p>{reservation.startAt}</p>
                          <SwapRightOutlined
                            style={{ color: '#BFBFBF', margin: '0 8px' }}
                          />
                          <p>{reservation.endAt}</p>
                        </CounselDateBadge>
                        <CounselStateText
                          color={
                            convertStateToComponentStyle(reservation.state)
                              .color
                          }
                        >
                          {convertStateToComponentStyle(reservation.state).text}
                        </CounselStateText>
                        <StudentText>
                          {reservation?.student?.basicStudentProfile?.name} /{' '}
                          {
                            reservation?.student?.basicStudentProfile
                              ?.phoneNumber
                          }{' '}
                          / {reservation?.student?.email}
                        </StudentText>
                        <Spacer />
                        {reservation.state === ReservationState.pending && (
                          <>
                            <CounselRightButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedApproveModal({
                                  id: reservation.id,
                                  boothName: item.boothTitle,
                                });
                              }}
                            >
                              승인
                            </CounselRightButton>
                            <CounselRightButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedDeclineModal({
                                  id: reservation.id,
                                  boothName: item.boothTitle,
                                });
                              }}
                            >
                              반려
                            </CounselRightButton>
                          </>
                        )}
                        {reservation.state === ReservationState.proceeding && (
                          <>
                            <CounselRightButton
                              onClick={(e) => {
                                e.stopPropagation();
                                completeCounselReservation({
                                  variables: {
                                    id: reservation.id,
                                  },
                                });
                              }}
                            >
                              상담완료
                            </CounselRightButton>
                            <CounselRightButton
                              onClick={(e) => {
                                e.stopPropagation();
                                setSendMessageReservationId(reservation.id);
                              }}
                            >
                              상담 문자 재전송
                            </CounselRightButton>
                          </>
                        )}
                        {(reservation.state === ReservationState.proceeding ||
                          reservation.state === ReservationState.completed) && (
                          <CounselRightButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedMemoModal({
                                id: reservation.id,
                                memo: reservation.memo,
                              });
                            }}
                          >
                            상담기록
                          </CounselRightButton>
                        )}
                        {reservation.state !== ReservationState.canceled && (
                          <CounselRightButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedStudentId(reservation.student?.id);
                            }}
                          >
                            학생 프로필
                          </CounselRightButton>
                        )}
                      </CounselItem>
                    ))}
                </>
              )}
          </BoothItem>
        ))}
      </ListLayout>
      <Modal
        isOpen={selectedStudentId !== 0}
        onClose={() => {
          setSelectedStudentId(0);
        }}
        width="971px"
        height="527px"
        closeButtonTransparent
      >
        <StudentProfileModal id={selectedStudentId} />
      </Modal>
      <Modal
        isOpen={zoomLinkItem.id > 0}
        onClose={() => {
          setZoomLinkItem({
            id: 0,
            link: '',
            counselMethod: '',
          });
        }}
        width="971px"
        height="527px"
      >
        <ZoomLinkModal
          item={zoomLinkItem}
          onClose={() => {
            setZoomLinkItem({
              id: 0,
              link: '',
              counselMethod: '',
            });
            if (user.userType === UserType.company) {
              getExhibitedBoothsByCompany({
                variables: {
                  companyId: user.id,
                },
              });
            } else if (user.userType === UserType.counselor) {
              getExhibitedBoothsByCounselor({
                variables: {
                  counselorId: user.id,
                },
              });
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={selectedMemoModal.id > 0}
        onClose={() => {
          setSelectedMemoModal({
            id: 0,
            memo: '',
          });
        }}
        width="971px"
        height="527px"
      >
        <CounselMemoModal
          id={selectedMemoModal.id}
          currentMemo={selectedMemoModal.memo}
          onClose={() => {
            setSelectedMemoModal({
              id: 0,
              memo: '',
            });
            if (user.userType === UserType.company) {
              getExhibitedBoothsByCompany({
                variables: {
                  companyId: user.id,
                },
              });
            } else if (user.userType === UserType.counselor) {
              getExhibitedBoothsByCounselor({
                variables: {
                  counselorId: user.id,
                },
              });
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={selectedApproveModal.id > 0}
        onClose={() => {
          setSelectedApproveModal({
            id: 0,
            boothName: '',
          });
        }}
        width="664px"
        height="455px"
      >
        <ApproveModal
          id={selectedApproveModal.id}
          boothName={selectedApproveModal.boothName}
          onClose={() => {
            setSelectedApproveModal({
              id: 0,
              boothName: '',
            });
            if (user.userType === UserType.company) {
              getExhibitedBoothsByCompany({
                variables: {
                  companyId: user.id,
                },
              });
            } else if (user.userType === UserType.counselor) {
              getExhibitedBoothsByCounselor({
                variables: {
                  counselorId: user.id,
                },
              });
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={selectedDeclineModal.id > 0}
        onClose={() => {
          setSelectedDeclineModal({
            id: 0,
            boothName: '',
          });
        }}
        width="664px"
        height="455px"
      >
        <DeclineModal
          id={selectedDeclineModal.id}
          boothName={selectedDeclineModal.boothName}
          onClose={() => {
            setSelectedDeclineModal({
              id: 0,
              boothName: '',
            });
            if (user.userType === UserType.company) {
              getExhibitedBoothsByCompany({
                variables: {
                  companyId: user.id,
                },
              });
            } else if (user.userType === UserType.counselor) {
              getExhibitedBoothsByCounselor({
                variables: {
                  counselorId: user.id,
                },
              });
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={method.opened}
        onClose={() => {
          setMethod({
            opened: false,
            link: '',
            method: '',
          });
        }}
        width="664px"
        height="455px"
      >
        <MethodModal
          onClose={() =>
            setMethod({
              opened: false,
              link: '',
              method: '',
            })
          }
          link={method.link}
          method={method.method}
        />
      </Modal>
      <Modal
        isOpen={sendMessageReservationId > 0}
        onClose={() => {
          setSendMessageReservationId(0);
        }}
        width="400px"
        height="300px"
      >
        <SendMessageModal
          onClose={() => setSendMessageReservationId(0)}
          id={sendMessageReservationId}
        />
      </Modal>
    </Layout>
  );
};

export default ManageCounsel;
