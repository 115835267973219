import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../../hooks/useInput';
import useAuth from '../../../hooks/useAuth';
import { useMutation } from '@apollo/client';
import { Color } from '../../../constants/color';
import Input from '../../../components/Input';
import { passwordRegex } from '../../../constants/regex';
import { Button } from 'antd';
import { CHANGE_PASSWORD } from '../../../queries/auth';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '42px 36px 14px' : '42px 96px 40px'};
  z-index: 10;
  display: flex;
  flex-flow: column;
`;

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${isMobile ? '12px' : '80px'};
  color: '#3a3a3a';
`;

const InputLayout = styled.div`
  margin-bottom: ${isMobile ? '8px' : '22px'};
`;

const ChangingPasswordModal = ({ onClose }) => {
  const [changePassword, { data, error }] = useMutation(CHANGE_PASSWORD);
  const { user } = useAuth();
  const [{ password, newPassword, confirmPassword }, onChange] = useInputs({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');
  useEffect(() => {
    if (error?.graphQLErrors?.[0]?.extensions?.code === 'INVALID_PASSWORD') {
      setPasswordError('비밀번호가 일치하지 않습니다.');
    }
  }, [error]);
  const passwordErrorMessage = useMemo(() => {
    const regex = new RegExp(passwordRegex);
    return newPassword !== '' && !regex.test(newPassword)
      ? '비밀번호가 형식에 맞지 않습니다.'
      : '';
  }, [newPassword]);
  const confirmPasswordErrorMessage = useMemo(() => {
    return confirmPassword !== '' &&
      newPassword !== '' &&
      newPassword !== confirmPassword
      ? '비밀번호가 일치하지 않습니다.'
      : '';
  }, [newPassword, confirmPassword]);
  useEffect(() => {
    if (data?.changePassword) {
      alert('비밀번호가 변경되었습니다.');
      onClose();
    }
  }, [data]);
  useEffect(() => {
    setPasswordError('');
  }, [password]);
  return (
    <Layout>
      <BodyLayout>
        <TitleText>비밀번호 변경</TitleText>

        <InputLayout>
          <Input
            name="password"
            value={password}
            onChange={onChange}
            label="비밀번호"
            placeholder={'영문, 숫자, 특수문자 포함 8자 이상'}
            errorMessage={passwordError}
            secure
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  password === '' ||
                  newPassword === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== ''
                )
              ) {
                changePassword({
                  variables: {
                    userId: user.id,
                    password: password,
                    newPassword: newPassword,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="newPassword"
            value={newPassword}
            onChange={onChange}
            label="새로운 비밀번호"
            placeholder={'영문, 숫자, 특수문자 포함 8자 이상'}
            secure
            errorMessage={passwordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  password === '' ||
                  newPassword === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== ''
                )
              ) {
                changePassword({
                  variables: {
                    userId: user.id,
                    password: password,
                    newPassword: newPassword,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChange}
            label="비밀번호 확인"
            placeholder={'영문, 숫자, 특수문자 포함 8자 이상'}
            secure
            errorMessage={confirmPasswordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  password === '' ||
                  newPassword === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== ''
                )
              ) {
                changePassword({
                  variables: {
                    userId: user.id,
                    password: password,
                    newPassword: newPassword,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <Button
          onClick={() => {
            changePassword({
              variables: {
                userId: user.id,
                password: password,
                newPassword: newPassword,
              },
            });
          }}
          disabled={
            password === '' ||
            newPassword === '' ||
            passwordErrorMessage !== '' ||
            confirmPassword === '' ||
            confirmPasswordErrorMessage !== ''
          }
          type="primary"
          style={{
            width: '272px',
            margin: isMobile ? '6px auto 0' : '64px auto 0',
            borderRadius: '10px',
          }}
        >
          비밀번호 변경
        </Button>
      </BodyLayout>
    </Layout>
  );
};

export default ChangingPasswordModal;
