import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import { useHistory } from 'react-router-dom';
import BannerIllust from '../../img/PresentationIllust.png';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CANCEL_APPLY_PRESENTATION,
  GET_APPLIED_PRESENTATIONS,
} from '../../queries/presentation';
import useAuth from '../../hooks/useAuth';
import { Button } from 'antd';
import Modal from '../../components/Modal';
import { CopyOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import SmallModalLayout from '../../components/SmallModalLayout';

const Layout = styled.div`
  padding: ${isMobile ? '50px 0 24px' : '50px 50px 24px 61px'};
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: ${isMobile ? '16px' : ''};
  margin-left: ${isMobile ? '30px' : ''};
`;

const AnnounceLayout = styled.div`
  width: 100%;
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: 37px 48px;
  img {
    right: 20px;
    bottom: 18px;
    width: 220px;
    height: 220px;
    position: absolute;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: 34px;
  width: 183px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(387px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '20px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-top: ${isMobileOnly ? '' : '24px'};
  align-items: ${isMobileOnly ? 'center' : ''};
`;

const ContentLayout = styled.div`
  padding: ${isMobile ? '8px' : '31px 24px'};
  background-color: white;
  margin-top: ${isMobile ? '12px' : '20px'};
  border-radius: 10px;
`;

const ItemLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 16px)' : '387px'};
  height: ${isMobile ? 'calc(97vw - 15.5px)' : '375px'};
  border-radius: 16px;
  border: 1px solid #edf0f5;
  padding: 10px;
  margin-bottom: ${isMobile ? '6px' : ''};
  cursor: pointer;
  img {
    width: ${isMobile ? 'calc(100vw - 38px)' : '367px'};
    height: ${isMobile ? 'calc(64vw - 24px)' : '236px'};
    border-radius: 10px;
    object-fit: cover;
  }
`;

const ItemTitleLayout = styled.div`
  margin-top: 12px;
  display: flex;
`;

const ItemTitleText = styled.p`
  margin-right: 10px;
  width: ${isMobile ? '240px' : '268px'};
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.8em;
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
`;

const Badge = styled.div`
  height: 22px;
  border-radius: 35px;
  padding: 0 8px;
  margin-left: auto;
  margin-right: 10px;
  background-color: ${(props) =>
    props.isRejected ? '#f2f2f2' : props.isOnline ? '#fff7e6' : '#F9F0FF'};
  border: 1px solid ${(props) => (props.isOnline ? '#ffa171' : '#D3ADF7')};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => (props.isOnline ? '#ffa171' : '#722ED1')};
`;

const DateLayout = styled.div`
  font-family: Noto Sans KR;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #767676;
  text-align: left;
  span {
    margin-left: 16px;
  }
`;

const BottomLayout = styled.div`
  margin-top: 13px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const BottomLeftButton = styled(Button)`
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-size: 14px;
  color: #fe706f;
  border: 1px solid #fe706f;
  line-height: 22px;
  letter-spacing: 0em;
  margin-left: 8px;
`;

const BottomButton = styled(Button)`
  margin-left: 8px;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  font-weight: 500;
`;

const ModalLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '464px'};
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 34px 0 22px;
  overflow: scroll;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fe706f;
`;

const CopyLayout = styled.div`
  display: flex;
  margin: 30px auto 27px;
  align-items: center;
`;

const CodeText = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 5px;
`;

const FlexLayout = styled.div`
  display: flex;
  flex-flow: ${isMobile ? 'column' : ''};
  padding: ${isMobile ? '0 24px' : ''};
  margin: ${(props) => (props.center ? 'auto' : '0 auto auto')};
`;

const Text = styled.p`
  margin-left: 8px;
  margin-top: ${isMobile ? '8px' : '-2px'};
  width: ${(props) => (props.center ? '' : isMobile ? '220px' : '210px')};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: ${isMobile ? 'center' : 'left'};
  white-space: pre-wrap;
  color: #3a3a3a;
`;

const ButtonLayout = styled.div`
  margin-top: 51px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`;

const BottomRightButton = styled(Button)`
  width: 96px;
  height: 50px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 15px;
`;

const StudentPresentation = () => {
  const history = useHistory();
  const [getAppliedPresentations, { data: getAppliedPresentationsData }] =
    useLazyQuery(GET_APPLIED_PRESENTATIONS);
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      getAppliedPresentations({
        variables: {
          studentId: user.id,
        },
      });
    }
  }, [user, getAppliedPresentations]);
  const [cancelingId, setCancelingId] = useState(null);
  const [selectedEntryCode, setSelectedEntryCode] = useState('');
  const [cancelApplyPresentation] = useMutation(CANCEL_APPLY_PRESENTATION, {
    onCompleted: (data) => {
      getAppliedPresentations({
        variables: {
          studentId: user.id,
        },
      });
      setCancelingId(null);
    },
  });
  return (
    <Layout>
      <TitleText>설명회 신청 내역</TitleText>
      {false ? (
        <>
          <AnnounceLayout>
            <AnnounceTitleLayout>
              <Icon icon={'edit'} size="21px" color="#ff434e" />
              <p>설명회를 신청해주세요!</p>
            </AnnounceTitleLayout>
            <AnnounceBodyText>
              아직 설명회를 신청하지 않으셨습니다.
              <br />
              설명회를 신청하고 기업들을 만나보세요!
            </AnnounceBodyText>
            <AnnounceBottomButton
              onClick={() => {
                history.push('/presentation');
              }}
            >
              <p>설명회 목록 보기</p>
            </AnnounceBottomButton>
            <img src={BannerIllust} alt="banner" />
          </AnnounceLayout>
        </>
      ) : (
        <ContentLayout>
          <ListBodyLayout>
            {getAppliedPresentationsData?.appliedPresentations?.map((item) => (
              <ItemLayout
                key={item.id}
                onClick={() => history.push(`/presentation/${item.id}`)}
              >
                <img src={item.image.url} alt="thumbnail" />
                <ItemTitleLayout>
                  <ItemTitleText>{item.title}</ItemTitleText>
                  <Badge isOnline={item.isOnline}>
                    {item.isOnline ? '온라인' : '오프라인'}
                  </Badge>
                </ItemTitleLayout>
                <DateLayout>
                  {item.date}
                  <span>
                    {item.startAt} - {item.endAt}
                  </span>
                </DateLayout>
                <BottomLayout>
                  <BottomLeftButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setCancelingId(item.id);
                    }}
                  >
                    신청 취소
                  </BottomLeftButton>{' '}
                  <div style={{ flex: 1 }} />
                  <BottomButton
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(item.link, '_blank');
                    }}
                  >
                    링크 이동
                  </BottomButton>{' '}
                  {item.entryCode && (
                    <BottomButton
                      type="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedEntryCode(item.entryCode);
                      }}
                    >
                      입장 코드
                    </BottomButton>
                  )}
                </BottomLayout>{' '}
              </ItemLayout>
            ))}
          </ListBodyLayout>
          <Modal
            isOpen={selectedEntryCode !== ''}
            onClose={() => setSelectedEntryCode('')}
          >
            <ModalLayout>
              <Title>입장 코드</Title>
              <CopyLayout>
                <CodeText>{selectedEntryCode}</CodeText>
                <button
                  style={{
                    border: 'none',
                    outline: 'none',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(selectedEntryCode);
                  }}
                >
                  <CopyOutlined
                    style={{ fontSize: '23px', color: '#767676' }}
                  />
                </button>
              </CopyLayout>
              <FlexLayout>
                <IssuesCloseOutlined
                  style={{ fontSize: '17px', color: '#fe706f' }}
                />
                <Text>
                  위 입장코드를 줌링크로 이동하여
                  <br />
                  입력해주세요.
                </Text>
              </FlexLayout>
              <ButtonLayout>
                <BottomRightButton
                  type="primary"
                  onClick={() => setSelectedEntryCode('')}
                >
                  확인
                </BottomRightButton>
              </ButtonLayout>
            </ModalLayout>
          </Modal>
        </ContentLayout>
      )}
      <Modal isOpen={cancelingId !== null} onClose={() => setCancelingId(null)}>
        <SmallModalLayout
          setDescriptionCenter
          height="305px"
          title={'설명회 참가 신청 취소'}
          description={'참가 신청을 취소하시겠습니까?'}
          onClickLeftButton={() => setCancelingId(null)}
          onClickRightButton={() => {
            cancelApplyPresentation({
              variables: {
                studentId: user.id,
                presentationId: cancelingId,
              },
            });
          }}
        />
      </Modal>
    </Layout>
  );
};

export default StudentPresentation;
