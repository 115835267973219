import React, { useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../../../hooks/useInput';
import InputBig from '../../../../components/InputBig';
import { Button, Select, DatePicker } from 'antd';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_COMPANY_PROFILE } from '../../../../queries/company';
import { CompanyProfileStep, domainArray } from '../../../../constants/static';
import moment from 'moment';

const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const BodyLayout = styled.div`
  padding: 0 125px;
  margin-bottom: 28px;
`;

const InputLayout = styled.div`
  margin: 0 auto 16px;
  margin-bottom: 27px;
  display: flex;
  align-items: center;
  flex: 1;
`;

const Spacer = styled.div`
  width: 133px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const DatePickerLayout = styled.div`
  width: 242px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const SelectLayout = styled.div`
  width: 294px;
  height: 78px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 11px;
  margin-left: 7px;
  display: flex;
  align-items: center;
`;

const RequiredText = styled.p`
  color: #f3706f;
  margin-left: 2px;
`;

const StyledSelect = styled(Select)`
  div {
    height: 40px !important;
    border-radius: 10px !important;
    padding-top: 4px !important;
    input {
      margin-top: 4px !important;
    }
  }
  border-radius: 10px;
  height: 40px;
  width: 100%;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const BasicCompanyProfile = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
}) => {
  const [
    {
      companyNameKR,
      companyNameEN,
      homePage,
      registrationNumber,
      phoneNumber,
      address,
    },
    onChange,
  ] = useInputs({
    companyNameKR: profile?.basicCompanyProfile?.companyNameKR ?? '',
    companyNameEN: profile?.basicCompanyProfile?.companyNameEN ?? '',
    homePage: profile?.basicCompanyProfile?.homePage ?? '',
    registrationNumber: profile?.basicCompanyProfile?.registrationNumber ?? '',
    phoneNumber: profile?.basicCompanyProfile?.phoneNumber ?? '',
    address: profile?.basicCompanyProfile?.address ?? '',
  });
  const [domain, setDomain] = useState('');
  const [foundedAt, setFoundedAt] = useState('');
  useEffect(() => {
    setDomain(profile?.basicCompanyProfile?.domain ?? '');
    setFoundedAt(profile?.basicCompanyProfile?.foundedAt ?? '');
  }, [profile]);
  const [updateCompanyProfile, { data }] = useMutation(UPDATE_COMPANY_PROFILE);
  const handleClickLeftButton = () => {
    if (
      companyNameKR === '' ||
      companyNameEN === '' ||
      homePage === '' ||
      registrationNumber === '' ||
      domain === ''
    ) {
      updateCompanyProfile({
        variables: {
          id: id,
          companyProfileInput: {
            step: CompanyProfileStep.managerInfo,
          },
        },
      });
    } else {
      updateCompanyProfile({
        variables: {
          id: id,
          companyProfileInput: {
            step: CompanyProfileStep.managerInfo,
            basicCompanyProfile: {
              companyNameKR: companyNameKR,
              companyNameEN: companyNameEN,
              homePage: homePage,
              registrationNumber: registrationNumber,
              domain: domain ?? '',
              phoneNumber: phoneNumber,
              address: address,
              foundedAt: foundedAt,
            },
          },
        },
      });
    }
  };
  const handleClickRightButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: isModal ? CompanyProfileStep.complete : CompanyProfileStep.logo,
          basicCompanyProfile: {
            companyNameKR: companyNameKR,
            companyNameEN: companyNameEN,
            homePage: homePage,
            registrationNumber: registrationNumber,
            domain: domain ?? '',
            phoneNumber: phoneNumber,
            address: address,
            foundedAt: foundedAt,
          },
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      console.log(isModal);
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <BodyLayout>
        <InputLayout>
          <InputBig
            width="294px"
            name={'companyNameKR'}
            value={companyNameKR}
            onChange={onChange}
            label="기업명 (국문)"
            placeholder="기업명을 입력하세요"
            isRequired
          />
          <Spacer />
          <InputBig
            width="294px"
            name={'companyNameEN'}
            value={companyNameEN}
            onChange={onChange}
            label="기업명 (영문)"
            placeholder="기업명을 입력하세요"
            isRequired
          />
        </InputLayout>
        <InputLayout>
          <InputBig
            width="294px"
            name={'homePage'}
            value={homePage}
            onChange={onChange}
            label="홈페이지"
            placeholder="www.company.com"
            isRequired
          />
          <Spacer />
          <InputBig
            width="294px"
            name={'registrationNumber'}
            value={registrationNumber}
            onChange={onChange}
            placeholder="사업자등록번호를 입력하세요"
            label="사업자등록번호"
            isRequired
          />
        </InputLayout>
        <InputLayout>
          <SelectLayout>
            <Label>
              산업분야<RequiredText>*</RequiredText>
            </Label>
            <StyledSelect
              placeholder="산업분야를 선택하세요"
              value={domain === '' ? undefined : domain}
              onChange={(value) => setDomain(value)}
            >
              {domainArray.map((domain) => (
                <Option value={domain}>{domain}</Option>
              ))}
            </StyledSelect>
          </SelectLayout>
          <Spacer />
          <InputBig
            width="294px"
            name={'phoneNumber'}
            value={phoneNumber}
            onChange={onChange}
            label="대표전화번호"
            placeholder="전화번호를 입력하세요"
          />
        </InputLayout>
        <InputLayout>
          <InputBig
            width="452px"
            name={'address'}
            value={address}
            onChange={onChange}
            label="주소"
            placeholder="주소를 입력하세요"
          />
          <div style={{ width: '27px' }} />
          <DatePickerLayout>
            <Label>설립일</Label>
            <StyledDatePicker
              placeholder={'설립일'}
              value={foundedAt !== '' ? moment(foundedAt) : undefined}
              onChange={(e) => {
                setFoundedAt(e ? moment(e).format('YYYY-MM-DD') : '');
              }}
            />
          </DatePickerLayout>
        </InputLayout>
      </BodyLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            disabled={
              companyNameKR === '' ||
              companyNameEN === '' ||
              homePage === '' ||
              registrationNumber === '' ||
              domain === ''
            }
            type="primary"
            onClick={handleClickRightButton}
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton type="primary" onClick={handleClickLeftButton}>
              이전
            </BottomLeftButton>
            <BottomRightButton
              type="primary"
              onClick={handleClickRightButton}
              disabled={
                companyNameKR === '' ||
                companyNameEN === '' ||
                homePage === '' ||
                registrationNumber === '' ||
                domain === ''
              }
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default BasicCompanyProfile;
