import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ManageMatching from './ManageMatching';
import Qna from './Qna';

const StudentMatching = () => {
  return (
    <Switch>
      <Route exact path="/meeting/manage-interview">
        <ManageMatching />
      </Route>
      <Route exact path="/meeting/qna">
        <Qna />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default StudentMatching;
