import { useLazyQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { GET_PRESENTATIONS } from '../../queries/presentation';
import { UserType } from '../../constants/static';
import Modal from '../../components/Modal';
import InquiryModal from './InquiryModal';
import { BookOutlined, CheckOutlined } from '@ant-design/icons';
import { GET_COMPANY_PROFILE } from '../../queries/company';
import { PresentationContext } from '.';
import moment, { now } from 'moment';

const Layout = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const BodyLayout = styled.div`
  padding: 84px 0 0;
  height: 100%;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #030303;
`;

const ToggleLayout = styled.div`
  position: sticky;
  margin: ${isMobile ? '20px 5px 0' : '35px 102px 0'};
  height: 73px;
  display: flex;
  justify-content: ${isMobile ? 'center' : ''};
  padding: ${isMobile ? '24px 12px 24px 0' : '25px 54px'};
  border-radius: 10px;
  background-color: white;
`;

const ListLayout = styled.div`
  padding: ${isMobile ? '35px 0 0' : '35px 0 0'};
  flex: 1;
  overflow-y: scroll;
  background-color: white;
  margin: ${isMobile ? '0 12px 0' : '0 102px 0'};
`;

const ItemLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 16px)' : '387px'};
  height: ${isMobile ? 'calc(90vw - 14.5px)' : '350px'};
  border-radius: 16px;
  background-color: #f9f9f9;
  border-radius: 30px;
  box-shadow: 4px 12px 23px 0px #0d03030a;
  padding: 10px;
  margin-bottom: ${isMobile ? '6px' : ''};
  cursor: pointer;
  img {
    width: ${isMobile ? 'calc(100vw - 38px)' : '367px'};
    height: ${isMobile ? 'calc(64vw - 24px)' : '236px'};
    border-radius: 10px;
    object-fit: cover;
  }
  position: relative;
`;

const ItemTitleLayout = styled.div`
  margin-top: 12px;
  display: flex;
`;

const ItemTitleText = styled.p`
  margin-right: 10px;
  width: ${isMobile ? '240px' : '268px'};
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.8em;
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 700;
`;

const Badge = styled.div`
  height: 22px;
  border-radius: 35px;
  padding: 0 8px;
  margin-left: auto;
  margin-right: 10px;
  background-color: ${(props) =>
    props.isRejected ? '#f2f2f2' : props.isOnline ? '#fff7e6' : '#F9F0FF'};
  border: 1px solid ${(props) => (props.isOnline ? '#ffa171' : '#D3ADF7')};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => (props.isOnline ? '#ffa171' : '#722ED1')};
`;

const DateLayout = styled.div`
  font-family: Noto Sans KR;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  color: #767676;
  text-align: left;
  span {
    margin-left: 16px;
  }
`;

const BottomLayout = styled.div`
  margin-top: 6px;
  padding-right: 10px;
  text-align: right;
  color: #f88988;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: right;
`;

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(368px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '40px 25px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 40px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ExpectedToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
  font-weight: 500;
  font-size: 12px;
  height: 26px;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`;

const ExpectedToggleBadge = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
  font-weight: 500;
  font-size: 12px;
  height: 26px;
  width: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 24px;
  top: 20px;
`;

const EndToggleBadge = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 10px;
  width: 40px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #979797;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 26px;
  right: 24px;
  top: 20px;
`;
const EndToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #979797;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  height: 26px;
  svg {
    margin-left: 4px;
  }
`;

const PresentationDefault = () => {
  const { user } = useAuth();
  const [target, setTarget] = useState(null);
  const history = useHistory();
  const {
    toggleFilters,
    handleClickToggleButton,
    listPresentations,
    getFinished,
    getPresentations,
    getPresentationsLoading,
    scrollItem,
    setScrollItem,
  } = useContext(PresentationContext);
  useEffect(() => {
    document.getElementById(scrollItem)?.scrollIntoView();
  }, [scrollItem]);
  const onIntersect = useCallback(
    ([entry], observer) => {
      if (
        entry.isIntersecting &&
        listPresentations.length > 0 &&
        (toggleFilters.has('expected') || toggleFilters.has('end'))
      ) {
        if (!getPresentationsLoading && !getFinished) {
          getPresentations({
            variables: {
              first: 30,
              after:
                listPresentations.length === 0
                  ? null
                  : listPresentations[listPresentations.length - 1].id,
              isScheduled: toggleFilters.has('expected'),
              isEnded: toggleFilters.has('end'),
            },
          });
        }
      }
    },
    [getFinished, getPresentationsLoading, listPresentations, toggleFilters],
  );
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, onIntersect]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  useEffect(() => {
    if (user.id) {
      getCompanyProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyProfile, user]);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  return (
    <Layout>
      <Header>
        <HeaderTitle>
          설명회
          <span
            style={{
              fontSize: '12px',
              marginLeft: '12px',
              marginTop: '2px',
            }}
          >
            {user.userType === UserType.student
              ? '원하는 설명회를 찾아서 참가해보세요!'
              : '설명회 목록을 만나보세요!'}
          </span>
        </HeaderTitle>
      </Header>
      <BodyLayout>
        <ToggleLayout>
          {user.userType === UserType.company && companyProfileData && (
            <Button
              type="primary"
              style={{
                marginLeft: '12px',
                borderRadius: '10px',
                height: '40px',
              }}
              onClick={() => {
                if (
                  !Boolean(companyProfileData?.company?.managerInfo?.name) ||
                  !Boolean(
                    companyProfileData?.company?.basicCompanyProfile
                      ?.companyNameKR,
                  )
                ) {
                  setIsWarningModalOpened(true);
                } else {
                  setIsModalOpened(true);
                }
              }}
            >
              설명회 신청 문의
            </Button>
          )}
          {!isMobile && <Spacer />}
          <ExpectedToggleButton
            onClick={() => handleClickToggleButton('expected')}
          >
            예정
            {toggleFilters.has('expected') && <CheckOutlined />}
          </ExpectedToggleButton>
          <EndToggleButton onClick={() => handleClickToggleButton('end')}>
            종료
            {toggleFilters.has('end') && <CheckOutlined />}
          </EndToggleButton>
        </ToggleLayout>
        <ListLayout>
          <ListBodyLayout>
            {listPresentations.map((item) => (
              <ItemLayout
                style={{
                  opacity: moment(item.date) < moment() ? 0.5 : 1,
                }}
                key={item.id}
                id={`presentation-${item.id}`}
                onClick={() => {
                  setScrollItem(`presentation-${item.id}`);
                  history.push(`/presentation/${item.id}`);
                }}
              >
                {moment(item.date) > moment() ? (
                  <ExpectedToggleBadge>예정</ExpectedToggleBadge>
                ) : (
                  <EndToggleBadge>종료</EndToggleBadge>
                )}
                <img src={item.image?.url} alt="thumbnail" />
                <ItemTitleLayout>
                  <ItemTitleText>{item.title}</ItemTitleText>
                  <Badge isOnline={item.isOnline}>
                    {item.isOnline ? '온라인' : '오프라인'}
                  </Badge>
                </ItemTitleLayout>
                <DateLayout>
                  {item.date}
                  <span>
                    {item.startAt} - {item.endAt}
                  </span>
                </DateLayout>
                <BottomLayout>
                  신청가능 {item.personnel} / {item.maxPersonnel}
                </BottomLayout>
              </ItemLayout>
            ))}
          </ListBodyLayout>
          <div ref={setTarget} className="Target-Element" />
        </ListLayout>
      </BodyLayout>

      <Modal isOpen={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <InquiryModal close={() => setIsModalOpened(false)} />
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => history.push('/profile/manage')}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default PresentationDefault;
