import React, { useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../../../hooks/useInput';
import InputBig from '../../../../components/InputBig';
import { Button, Checkbox, Upload } from 'antd';
import { phoneNumberRegex } from '../../../../constants/regex';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const InputLayout = styled.div`
  width: 294px;
  margin: 0 auto 16px;
`;

const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3a3a3a;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 26px;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 280px;
  margin: 0 auto;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: ${isMobile ? '60px 60px 0' : '0 60px'};
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const BasicStudentProfile = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
}) => {
  const [{ name, phoneNumber }, onChange] = useInputs({
    name: profile?.basicStudentProfile?.name ?? '',
    phoneNumber: profile?.basicStudentProfile?.phoneNumber ?? '',
  });
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isPhoneNumberChecked, setIsPhoneNumberChecked] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  useEffect(() => {
    setIsPhoneNumberChecked(
      profile?.basicStudentProfile?.phoneMarketingAgreement,
    );
    setIsEmailChecked(profile?.basicStudentProfile?.emailMarketingAgreement);
  }, [profile]);
  useEffect(() => {
    const regex = new RegExp(phoneNumberRegex);
    if (phoneNumber !== '' && !regex.test(phoneNumber)) {
      setPhoneNumberError('유효하지 않은 연락처입니다.');
    } else {
      setPhoneNumberError('');
    }
  }, [phoneNumber]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  const handleClickNextButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.matchingInfo,
          basicStudentProfile: {
            name: name,
            phoneNumber: phoneNumber,
            emailMarketingAgreement: isEmailChecked,
            phoneMarketingAgreement: isPhoneNumberChecked,
          },
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <InputLayout>
        <InputBig name={'name'} value={name} onChange={onChange} label="이름" />
      </InputLayout>
      <InputLayout>
        <InputBig
          name={'phoneNumber'}
          value={phoneNumber}
          onChange={onChange}
          placeholder={'012-3456-7890'}
          label="연락처"
          errorMessage={phoneNumberError}
        />
      </InputLayout>
      <DescriptionText>행사 정보 및 뉴스레터를 받아보시겠어요?</DescriptionText>
      <FlexLayout>
        <Checkbox
          checked={isEmailChecked}
          onChange={(e) => setIsEmailChecked(e.target.checked)}
        >
          이메일 수신 동의
        </Checkbox>
        <Spacer />
        <Checkbox
          checked={isPhoneNumberChecked}
          onChange={(e) => setIsPhoneNumberChecked(e.target.checked)}
        >
          핸드폰 수신 동의
        </Checkbox>
      </FlexLayout>
      <ButtonLayout>
        {isModal || isMobile ? (
          <BottomCenterButton
            type="primary"
            onClick={handleClickNextButton}
            disabled={
              name === '' || phoneNumber === '' || phoneNumberError !== ''
            }
          >
            {!isModal ? '다음' : '완료'}
          </BottomCenterButton>
        ) : (
          <BottomRightButton
            type="primary"
            onClick={handleClickNextButton}
            disabled={
              name === '' || phoneNumber === '' || phoneNumberError !== ''
            }
          >
            다음
          </BottomRightButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default BasicStudentProfile;
