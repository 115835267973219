import { gql } from '@apollo/client';

const BASIC_STUDENT_PROFILE = gql`
  fragment BasicStudentProfile on Student {
    basicStudentProfile {
      name
      phoneNumber
      emailMarketingAgreement
      phoneMarketingAgreement
    }
  }
`;

const EDUCATIONS = gql`
  fragment Educations on Student {
    educations {
      startAt
      endAt
      university
      major
      degree
    }
  }
`;

const CAREERS = gql`
  fragment Careers on Student {
    careers {
      startAt
      endAt
      companyName
      team
      rank
      task
    }
  }
`;

const PROJECTS = gql`
  fragment Projects on Student {
    projects {
      isDomestic
      title
      partyOfPerformance
      startAt
      endAt
    }
  }
`;

const THESES = gql`
  fragment Theses on Student {
    theses {
      journal
      journalClassification
      title
      author
      authorClassification
      publishedAt
      vol
      impactFactor
    }
  }
`;

const SKILLS = gql`
  fragment Skills on Student {
    skills {
      id
      field
      skillDetail
    }
  }
`;

const TEMPORARY_SKILLS = gql`
  fragment TemporarySkills on Student {
    temporarySkills {
      id
      skillDetail
    }
  }
`;

const PATENTS = gql`
  fragment Patents on Student {
    patents {
      section
      title
      registrationNumber
      registeredAt
      inventor
      applicant
      country
    }
  }
`;

const AWARDS = gql`
  fragment Awards on Student {
    awards {
      awardedAt
      title
      issuer
    }
  }
`;

const PORTFOLIOS = gql`
  fragment Portfolios on Student {
    portfolios {
      name
      url
      file {
        id
        name
        url
      }
    }
  }
`;

const RECRUITMENT_INFO = gql`
  fragment RecruitmentInfo on Student {
    recruitmentInfo {
      willJoinAt
      expectedSalary
      emailContact
      phoneContact
    }
  }
`;

// Queries

export const GET_STUDENT_PROFILE = gql`
  ${BASIC_STUDENT_PROFILE}
  ${EDUCATIONS}
  ${CAREERS}
  ${PROJECTS}
  ${THESES}
  ${PATENTS}
  ${AWARDS}
  ${PORTFOLIOS}
  ${RECRUITMENT_INFO}
  ${SKILLS}
  ${TEMPORARY_SKILLS}
  query GetStudentProfile($id: ID!) {
    student(id: $id) {
      step
      email
      ...BasicStudentProfile
      ...Educations
      ...Careers
      introduction
      ...Skills
      ...Projects
      ...Theses
      ...Patents
      ...Awards
      ...Portfolios
      recruitmentTypes
      ...RecruitmentInfo
      briefIntroduction
      onMatching
      ...TemporarySkills
      labInfo {
        id
        approvedUniversity {
          id
          name
        }
        labNameKR
        labNameEN
        professorName
        major
        website
      }
      temporaryLabInfo {
        id
        approvedUniversity {
          id
          name
        }
        major
        labNameKR
        labNameEN
        professorName
        website
      }
    }
  }
`;

export const SEARCH_LAB_INFOS = gql`
  query SearchLabInfos($keyword: String!) {
    searchLabInfos(keyword: $keyword) {
      id
      approvedUniversity {
        id
        name
      }
      labNameKR
      labNameEN
      professorName
      major
      website
    }
  }
`;

// Mutations

export const UPDATE_STUDENT_PROFILE = gql`
  mutation UpdateStudentProfile(
    $id: ID!
    $studentProfileInput: StudentProfileInput
  ) {
    updateStudentProfile(id: $id, studentProfileInput: $studentProfileInput) {
      id
      step
    }
  }
`;

export const UPLOAD_STUDENT_FILE = gql`
  mutation UploadCompanyFile(
    $userId: ID
    $fileType: FileType!
    $file: Upload!
  ) {
    uploadStudentFile(userId: $userId, fileType: $fileType, file: $file) {
      id
      name
      url
    }
  }
`;

export const DELETE_STUDENT_FILE = gql`
  mutation UploadStudentFile($userId: ID, $fileType: FileType!, $fileId: ID) {
    deleteStudentFile(userId: $userId, fileType: $fileType, fileId: $fileId)
  }
`;

export const ADD_TEMPORARY_LAB_INFO = gql`
  mutation AddStudentTemporaryLabInfo(
    $studentId: ID
    $labInfoInput: LabInfoInput
  ) {
    addStudentTemporaryLabInfo(
      studentId: $studentId
      labInfoInput: $labInfoInput
    )
  }
`;

export const DELETE_TEMPORARY_LAB_INFO = gql`
  mutation DeleteStudentTemporaryLabInfo(
    $studentId: ID
    $temporaryLabInfoId: ID
  ) {
    deleteStudentTemporaryLabInfo(
      studentId: $studentId
      temporaryLabInfoId: $temporaryLabInfoId
    )
  }
`;
