import { Button } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import { maskingName } from '../CompanyMatching/SuggestionTab';

const Layout = styled.div`
  width: 320px;
  cursor: pointer;
  height: 287px;
  border-radius: 30px;
  background-color: #fcfcfc;
  padding: 15px 0 19px;
  box-shadow: 0px 12px 23px 0px #0d03030a;
`;

const FlexLayout = styled.div`
  padding: 0 32px;
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const Name = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
`;

const Introduction = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin: 15px 32px 12px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 4.2em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const Education = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin: 0 32px 9px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Date = styled.p`
  height: 22px;
  border-radius: 2px;
  padding: 1px 8px;
  background-color: #f5f5f5;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const DateLabel = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-right: 8px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #f1f1f1;
  margin: 16px 0;
`;

const SkillsLayout = styled.div`
  height: 27px;
  margin: 0 32px;
  overflow: hidden;
`;

const Skill = styled.div`
  height: 27px;
  border-radius: 10px;
  background-color: #fff3f0;
  color: #ffa171;
  margin: 0 8px 8px 0;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 13px;
  line-height: 19px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const TypesLayout = styled.div`
  height: 22px;
  margin: 16px 32px 0;
  overflow: hidden;
`;

const Type = styled.div`
  height: 22px;
  border-radius: 2px;
  padding: 1px 8px;
  background-color: #fff1f0;
  color: #fe706f;
  font-size: 12px;
  margin-right: 8px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.1em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const StudentComponent = ({
  name,
  saved,
  introduction,
  education,
  date,
  skills,
  types,
  onClick,
  onClickSaveButton,
}) => {
  const translatedName = useMemo(() => {
    return maskingName(name);
  }, [name]);
  return (
    <Layout onClick={onClick}>
      <FlexLayout>
        <Name>{translatedName}</Name>
        <Spacer />
        <Button
          type="link"
          style={{ padding: '3px 6px' }}
          onClick={(e) => {
            e.stopPropagation();
            onClickSaveButton();
          }}
        >
          <Icon size="17px" icon={saved ? 'bookmarkFilled' : 'bookmark'} />
        </Button>
      </FlexLayout>
      <Introduction>{introduction}</Introduction>
      <Education>{education}</Education>
      <FlexLayout>
        <DateLabel>입사 예정일</DateLabel>
        <Date>{date === undefined || date === null ? '없음' : date}</Date>
      </FlexLayout>
      <Divider />
      <SkillsLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {skills?.slice(0, 2)?.map((item, index) => (
            <Skill key={index}>{item}</Skill>
          ))}
          {skills?.length > 2 && (
            <p style={{ fontSize: '12px', marginTop: '4px', color: '#FFA171' }}>
              외 {skills?.length - 2}개
            </p>
          )}
        </div>
      </SkillsLayout>
      <TypesLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {types?.slice(0, 2)?.map((item, index) => (
            <Type key={index}>{item}</Type>
          ))}
          {3 < types?.length && (
            <p style={{ fontSize: '12px', marginTop: '2px', color: '#FE706F' }}>
              외 {types?.length - 2}개
            </p>
          )}
        </div>
      </TypesLayout>
    </Layout>
  );
};

export default StudentComponent;
