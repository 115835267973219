import { gql } from '@apollo/client';

// QUERIES
export const GET_MATCHING_SERVICE_AGREEMENT = gql`
  query GetMatchingServiceAgreement {
    getMatchingServiceAgreement
  }
`;

export const GET_PAY_SERVICE_AGREEMENT = gql`
  query GetPayServiceAgreement {
    getPayServiceAgreement
  }
`;

export const LATEST_MATCHING_APPLICATION_BY_COMPANY = gql`
  query LatestMatchingApplicationByCompany($companyId: ID) {
    latestMatchingApplicationByCompany(companyId: $companyId) {
      state
    }
  }
`;

export const GET_MATCHINGS_BY_COMPANY = gql`
  query GetMatchingsByCompany($companyId: ID, $matchingType: MatchingType) {
    matchingsByCompany(companyId: $companyId, matchingType: $matchingType) {
      jobVacancy {
        id
        position
      }
      matchings {
        id
        matchingType
        student {
          id
          basicStudentProfile {
            name
            phoneNumber
          }
        }
        matchingState
        reasonDeclined
        reasonCanceled
        interviewSuggestion {
          message
          rank
          minSalary
          maxSalary
          stockOption
        }
        createdAt
      }
    }
  }
`;

export const GET_MATCHINGS_BY_STUDENT = gql`
  query GetMatchingsByStudent($studentId: ID, $matchingType: MatchingType) {
    matchingsByStudent(studentId: $studentId, matchingType: $matchingType) {
      id
      matchingType
      jobVacancy {
        company {
          id
          logo {
            url
          }
          basicCompanyProfile {
            companyNameKR
            companyNameEN
            domain
          }
          recruitmentTypes
        }
        position
        workplace
        recruitmentSchedule
        skills {
          id
          field
          skillDetail
        }
      }
      matchingState
      reasonDeclined
      reasonCanceled
      interviewSuggestion {
        message
        rank
        minSalary
        maxSalary
        stockOption
      }
      createdAt
    }
  }
`;

export const GET_SAVED_STUDENTS_BY_COMPANY = gql`
  query GetSavedStudentsByCompany($companyId: ID) {
    savedStudentsByCompany(companyId: $companyId) {
      id
      basicStudentProfile {
        name
      }
      introduction
      educations {
        university
        major
        degree
      }
      recruitmentInfo {
        willJoinAt
      }
      skills {
        field
        skillDetail
      }
      temporarySkills {
        skillDetail
      }
      recruitmentTypes
      saved
      briefIntroduction
      suggested
    }
  }
`;

export const GET_ON_MATCHING_STUDENTS = gql`
  query GetOnMatchingStudents(
    $first: Int
    $after: ID
    $degrees: [String]
    $approvedUniversityIds: [ID]
    $skillIds: [ID]
    $recruitmentTypes: [String]
  ) {
    onMatchingStudents(
      first: $first
      after: $after
      degrees: $degrees
      approvedUniversityIds: $approvedUniversityIds
      skillIds: $skillIds
      recruitmentTypes: $recruitmentTypes
    ) {
      id
      basicStudentProfile {
        name
      }
      introduction
      educations {
        university
        major
        degree
      }
      recruitmentInfo {
        willJoinAt
      }
      skills {
        field
        skillDetail
      }
      temporarySkills {
        skillDetail
      }
      recruitmentTypes
      saved
      suggested
      briefIntroduction
    }
  }
`;

export const GET_ON_MATCHING_JOB_VACANCIES = gql`
  query GetOnMatchingJobVacancies(
    $first: Int
    $after: ID
    $domains: [String]
    $skillIds: [ID]
    $recruitmentTypes: [String]
    $requiredDegrees: [String]
  ) {
    onMatchingJobVacancies(
      first: $first
      after: $after
      requiredDegrees: $requiredDegrees
      domains: $domains
      skillIds: $skillIds
      recruitmentTypes: $recruitmentTypes
    ) {
      id
      company {
        id
        logo {
          url
        }
        basicCompanyProfile {
          companyNameKR
          companyNameEN
          domain
        }
        recruitmentTypes
      }
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
      saved
      applied
    }
  }
`;

export const SEARCH_ON_MATCHING_JOB_VACANCIES = gql`
  query SearchOnMatchingJobVacancies(
    $first: Int
    $after: ID
    $keyword: String
  ) {
    searchOnMatchingJobVacancies(
      first: $first
      after: $after
      keyword: $keyword
    ) {
      id
      company {
        id
        logo {
          url
        }
        basicCompanyProfile {
          companyNameKR
          companyNameEN
          domain
        }

        recruitmentTypes
      }
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
      saved
      applied
    }
  }
`;

export const GET_ON_MATCHING_COMPANIES = gql`
  query GetOnMatchingCompanies(
    $first: Int
    $after: ID
    $domains: [String]
    $skillIds: [ID]
    $recruitmentTypes: [String]
    $requiredDegrees: [String]
  ) {
    onMatchingCompanies(
      first: $first
      after: $after
      requiredDegrees: $requiredDegrees
      domains: $domains
      skillIds: $skillIds
      recruitmentTypes: $recruitmentTypes
    ) {
      company {
        id
        logo {
          url
        }
        basicCompanyProfile {
          companyNameKR
          companyNameEN
          domain
        }

        recruitmentTypes
        onMatchingJobVacancies {
          position
        }
      }
      skills {
        id
        field
        skillDetail
      }
    }
  }
`;

export const SEARCH_ON_MATCHING_COMPANIES = gql`
  query SearchOnMatchingCompanies($first: Int, $after: ID, $keyword: String) {
    searchOnMatchingCompanies(first: $first, after: $after, keyword: $keyword) {
      company {
        logo {
          url
        }
        id
        basicCompanyProfile {
          companyNameKR
          companyNameEN
          domain
        }

        recruitmentTypes
        onMatchingJobVacancies {
          position
        }
      }
      skills {
        id
        field
        skillDetail
      }
    }
  }
`;

export const SEARCH_ON_MATCHING_STUDENTS = gql`
  query SearchOnMatchingStudents($first: Int, $after: ID, $keyword: String) {
    searchOnMatchingStudents(first: $first, after: $after, keyword: $keyword) {
      id
      basicStudentProfile {
        name
      }
      introduction
      educations {
        university
        major
        degree
      }
      recruitmentInfo {
        willJoinAt
      }
      skills {
        field
        skillDetail
      }
      temporarySkills {
        skillDetail
      }
      recruitmentTypes
      saved
      suggested
      briefIntroduction
    }
  }
`;

export const GET_QUESTIONS_BY_COMPANY = gql`
  query GetQuestionsByCompany($first: Int, $after: ID, $companyId: ID) {
    questionsByCompany(first: $first, after: $after, companyId: $companyId) {
      id
      student {
        id
        basicStudentProfile {
          name
        }
      }
      companyRead
      studentRead
      message
      createdAt
      replies {
        userType
        message
        createdAt
      }
    }
  }
`;

export const GET_QUESTIONS_BY_STUDENT = gql`
  query GetQuestionsByCompany($first: Int, $after: ID, $studentId: ID) {
    questionsByStudent(first: $first, after: $after, studentId: $studentId) {
      id
      student {
        id
        basicStudentProfile {
          name
        }
      }
      company {
        id
        basicCompanyProfile {
          companyNameKR
        }
        logo {
          url
        }
      }
      companyRead
      studentRead
      message
      createdAt
      replies {
        userType
        message
        createdAt
      }
    }
  }
`;

export const GET_SAVED_JOB_VACANCIES_BY_STUDENT = gql`
  query GetSavedJobvacanciesBystudent($studentId: ID) {
    savedJobVacanciesByStudent(studentId: $studentId) {
      id
      company {
        id
        logo {
          url
        }
        basicCompanyProfile {
          companyNameKR
          companyNameEN
          domain
        }

        recruitmentTypes
      }
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
      saved
      applied
    }
  }
`;

// Mutations

export const APPLY_MATCHING = gql`
  mutation ApplyMatching($matchingApplicationInput: MatchingApplicationInput) {
    applyMatching(matchingApplicationInput: $matchingApplicationInput) {
      id
      state
      taxBillEmail
      company {
        id
      }
      appliedAt
      serviceAgreement
      payServiceAgreement
    }
  }
`;

export const ACCEPT_MATCHING = gql`
  mutation AcceptMatcing($matchingId: ID) {
    acceptMatching(matchingId: $matchingId)
  }
`;

export const DECLINE_MATCHING = gql`
  mutation DeclineMatcing($matchingId: ID, $declineReason: String) {
    declineMatching(matchingId: $matchingId, declineReason: $declineReason)
  }
`;

export const CANCEL_MATCHING = gql`
  mutation CancelMatcing($matchingId: ID, $cancelReason: String) {
    cancelMatching(matchingId: $matchingId, cancelReason: $cancelReason)
  }
`;

export const CANCEL_SAVE_STUDENT = gql`
  mutation CancelSaveStudent($companyId: ID!, $studentId: ID!) {
    cancelSaveStudent(companyId: $companyId, studentId: $studentId)
  }
`;

export const SAVE_STUDENT = gql`
  mutation SaveStudent($companyId: ID!, $studentId: ID!) {
    saveStudent(companyId: $companyId, studentId: $studentId)
  }
`;

export const SUGGEST_INTERVIEW = gql`
  mutation SuggestInterview(
    $studentId: ID!
    $jobVacancyId: ID!
    $interviewSuggestionInput: InterviewSuggestionInput
  ) {
    suggestInterview(
      studentId: $studentId
      jobVacancyId: $jobVacancyId
      interviewSuggestionInput: $interviewSuggestionInput
    )
  }
`;

export const READ_QUESTION_BY_COMPANY = gql`
  mutation ReadQuestionByCompany($companyId: ID!, $questionId: ID) {
    readQuestionByCompany(companyId: $companyId, questionId: $questionId)
  }
`;

export const READ_QUESTION_BY_STUDENT = gql`
  mutation ReadQuestionByStudent($studentId: ID!, $questionId: ID) {
    readQuestionByStudent(studentId: $studentId, questionId: $questionId)
  }
`;

export const REPLY_QUESTION = gql`
  mutation ReplyQuestion(
    $userType: UserType!
    $message: String!
    $questionId: ID
  ) {
    replyQuestion(
      userType: $userType
      message: $message
      questionId: $questionId
    ) {
      userType
      message
      createdAt
    }
  }
`;

export const CANCEL_SAVE_JOB_VACANCY = gql`
  mutation CancelSaveJobVacancy($studentId: ID!, $jobVacancyId: ID!) {
    cancelSaveJobVacancy(studentId: $studentId, jobVacancyId: $jobVacancyId)
  }
`;

export const SAVE_JOB_VACANCY = gql`
  mutation SaveJobVacancy($studentId: ID!, $jobVacancyId: ID!) {
    saveJobVacancy(studentId: $studentId, jobVacancyId: $jobVacancyId)
  }
`;

export const APPLY_INTERVIEW = gql`
  mutation ApplyInterview($studentId: ID!, $jobVacancyId: ID!) {
    applyInterview(studentId: $studentId, jobVacancyId: $jobVacancyId)
  }
`;

export const ASK_COMPANY = gql`
  mutation AskCompany($studentId: ID!, $companyId: ID!, $message: String) {
    askCompany(studentId: $studentId, companyId: $companyId, message: $message)
  }
`;

export const SET_STUDENT_MATCHING_INTERVIEW_STATE = gql`
  mutation SetStudentMatchingInterviewState(
    $matchingId: ID
    $interviewState: InterviewState
    $stateDetail: String
  ) {
    setStudentMatchingInterviewState(
      matchingId: $matchingId
      interviewState: $interviewState
      stateDetail: $stateDetail
    )
  }
`;

export const SET_COMPANY_MATCHING_INTERVIEW_STATE = gql`
  mutation SetCompanyMatchingInterviewState(
    $matchingId: ID
    $interviewState: InterviewState
    $stateDetail: String
  ) {
    setCompanyMatchingInterviewState(
      matchingId: $matchingId
      interviewState: $interviewState
      stateDetail: $stateDetail
    )
  }
`;
