import { BookOutlined, LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import {
  APPROVE_COUNSEL_RESERVATION,
  RESEND_COUNSEL_START_MESSAGE,
} from '../../../queries/counsel';

const { TextArea } = Input;
const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 37px 0 23px;
  overflow-y: scroll;
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const ContentLayout = styled.div`
  display: flex;
  width: fit-content;
  margin: 80px auto 20px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 544px;
  min-height: 129px !important;
  border-radius: 10px;
  margin: 0 auto;
`;
const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 8px 0 auto;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto 0 8px;
`;

const SendMessageModal = ({ id, onClose }) => {
  const [send, { data }] = useMutation(RESEND_COUNSEL_START_MESSAGE);
  useEffect(() => {
    send({
      variables: {
        id,
      },
    });
  }, [id, send]);
  return (
    <Layout>
      {data !== undefined ? (
        <>
          <ContentLayout>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p>
              상담 문자 재전송을{' '}
              {data.resendCounselStartMessage === true
                ? '성공하였습니다.'
                : '실패하였습니다.'}
            </p>
          </ContentLayout>
        </>
      ) : (
        <LoadingOutlined style={{ marginTop: '100px' }} />
      )}
    </Layout>
  );
};

export default SendMessageModal;
