import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { CounselorType } from '../../../constants/static';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const FlexLayout = styled.div`
  margin: 24px auto 24px;
  display: flex;
  div {
    margin-left: auto;
    margin-right: 0;
    display: flex;
  }
`;

const CounselorInformation = ({ booth, isCreating, getBooths }) => {
  const [isPracticeSelected, setIsPracticeSelected] = useState(false);
  const [isHRSelected, setIsHRSelected] = useState(false);
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);

  useEffect(() => {
    setIsPracticeSelected(
      booth.counselorRoles?.findIndex((item) => item === CounselorType.field) >
        -1,
    );
    setIsHRSelected(
      booth.counselorRoles?.findIndex((item) => item === CounselorType.hr) > -1,
    );
  }, [booth]);

  const counselorRoleArray = useMemo(() => {
    if (isPracticeSelected) {
      if (isHRSelected) {
        return [CounselorType.field, CounselorType.hr];
      } else {
        return [CounselorType.field];
      }
    } else {
      if (isHRSelected) {
        return [CounselorType.hr];
      } else {
        return [];
      }
    }
  }, [isPracticeSelected, isHRSelected]);
  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselorRoles: counselorRoleArray,
            step: BoothStep.counselingTime,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselorRoles: counselorRoleArray,
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    if (!isHRSelected && !isPracticeSelected) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            step: BoothStep.managerInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselorRoles: counselorRoleArray,
            step: BoothStep.managerInformation,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);

  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>상담을 진행하시는 분들은 어떤 역할이신가요?</p>
      </TitleLaout>
      <FlexLayout>
        <Checkbox
          style={{ margin: '0 26px' }}
          onClick={(e) => setIsPracticeSelected(e.target.checked)}
          checked={isPracticeSelected}
        >
          현업
        </Checkbox>
        <Checkbox
          style={{ margin: '0 26px' }}
          onClick={(e) => setIsHRSelected(e.target.checked)}
          checked={isHRSelected}
        >
          인사
        </Checkbox>
      </FlexLayout>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={!isHRSelected && !isPracticeSelected}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={!isHRSelected && !isPracticeSelected}
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default CounselorInformation;
