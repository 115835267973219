import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Tag, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_PROFILE } from '../queries/company';
import Modal from '../components/Modal';
import { isMobile } from 'react-device-detect';
import { httpUrl } from '../constants/utils';

const Layout = styled.div`
  width: 100%;
  margin-top: 31px;
  margin-bottom: 13px;
`;

const TitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: ${isMobile ? 400 : 600};
    font-size: ${isMobile ? '17px' : '14px'};
    margin-right: 26px;
  }
  margin-left: ${isMobile ? '24px' : '80px'};
  button {
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

const BodyLayout = styled.div`
  margin-top: 26px;
  margin-left: ${isMobile ? '32px' : '95px'};
  white-space: pre-wrap;
`;

const RemovePaddingBodyLayout = styled.div`
  margin-top: 26px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #f0f0f0;
  margin: ${isMobile ? '24px 0' : '35px 0'};
`;

const ModalBodyLayout = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const LogoLayout = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${isMobile ? '24px' : '80px'};
  div {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fdfdfd;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-height: 80px;
      max-width: 80px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 30px;
      margin-top: 30px;
      padding: 0;
      background-color: transparent;
    }
    div {
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
  p {
    font-weight: 600;
    font-size: ${isMobile ? '16px' : '20px'};
    margin-left: ${isMobile ? '16px' : '33px'};
  }
`;

const BasicProfileLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
`;

const ImagesLayout = styled.div`
  div {
    margin-left: ${isMobile ? '' : '80px'};
    width: 180px;
    height: 180px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    padding: 14px;
    position: relative;
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      height: 152px;
      width: 152px;
    }
    button {
      outline: none;
      border: none;
      cursor: pointer;
      margin-left: 64px;
      margin-top: 64px;
      padding: 0;
      background-color: transparent;
    }
    div {
      top: -15px;
      left: -75px;
      opacity: 0%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:hover {
      div {
        opacity: 100%;
      }
    }
  }
`;

const ImagesBottomLayout = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  width: 180px;
  margin-left: ${isMobile ? '' : '80px'};
  margin-bottom: ${isMobile ? '16px' : ''};
  button {
    width: 32px;
    height: 32px;
    padding: 0 9px;
    margin: 0 22px;
  }
  div {
    flex: 1;
  }
`;

const BasicProfileContentLayout = styled.div`
  margin-left: ${isMobile ? '32px' : '77px'};
  margin-top: 10px;
`;

const BasicProfileItemLayout = styled.div`
  margin-right: ${isMobile ? '36px' : ''};
  div {
    min-width: 259px;
    margin-right: ${isMobile ? '24px' : '85px'};
    display: flex;
    align-items: ${isMobile ? '' : 'center'};
    margin-bottom: 16px;
    word-break: break-all;

    p {
      font-weight: 600;
      width: 110px !important;
      min-width: 110px !important;
      margin-right: 4px;
    }
  }
`;

const ManagerInfoLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  div {
    width: 259px;
    display: flex;
    align-items: center;
    margin-bottom: ${isMobile ? '16px' : ''};
    p {
      font-weight: 600;
      width: ${isMobile ? '64px' : '52px'};
      margin-right: 10px;
    }
  }
`;

const RecruitmentTypeItem = styled(Tag)`
  border-radius: 2px;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  height: 22px;
  border: none;
  color: #000000;
  margin-bottom: 19px;
  height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const LinkItemLayout = styled.div`
  margin-top: 21px;
  display: flex;
  align-items: center;
  p {
    min-width: 188px;
    margin-right: 22px;
  }
  a {
    color: #f88988;
    cursor: pointer;
    text-decoration: none;
  }
`;

const QnaLayout = styled.div`
  margin-right: ${isMobile ? '24px' : '95px'};
  border-radius: 10px;
  border: 1px solid #d9d9d9;
`;

const QnaRow = styled.div`
  padding: 12px 24px;
  font-weight: 600;
  p {
    color: #f88988;
  }
`;

const QnaDivider = styled.div`
  height: 1px;
  background-color: #d9d9d9;
`;

const CompanyProfileModal = ({ id }) => {
  const { data } = useQuery(GET_COMPANY_PROFILE, {
    variables: {
      id: id,
    },
  });
  const profile = useMemo(() => data?.company, [data]);
  const [imageIndex, setImageIndex] = useState(0);
  const [isImageModalOpened, setIsImageModalOpened] = useState('');
  if (profile) {
    return (
      <Layout>
        <LogoLayout>
          <div>
            {profile?.logo ? (
              <img src={profile?.logo?.url} alt="sample" />
            ) : (
              <p
                style={{
                  position: 'absolute',
                  fontSize: '12px',
                  marginTop: '18px',
                  marginLeft: '20px',
                  textAlign: 'center',
                }}
              >
                로고가
                <br />
                없습니다
              </p>
            )}
          </div>
          <p>
            {profile?.basicCompanyProfile?.companyNameKR} (
            {profile?.basicCompanyProfile?.companyNameEN})
          </p>
        </LogoLayout>
        <Divider />
        <TitleLayout>
          <p>기본 정보</p>
        </TitleLayout>
        <RemovePaddingBodyLayout>
          <BasicProfileLayout>
            <div>
              {isMobile ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: 'column',
                  }}
                >
                  <ImagesLayout>
                    <div>
                      {profile?.images?.length > 0 ? (
                        <img
                          src={profile?.images?.[imageIndex]?.url}
                          alt="images"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsImageModalOpened(true);
                          }}
                        />
                      ) : (
                        <p style={{ position: 'absolute' }}>
                          등록된 이미지가 없습니다
                        </p>
                      )}
                    </div>
                  </ImagesLayout>
                  <ImagesBottomLayout>
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === 0 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex - 1)}
                    >
                      <LeftOutlined />
                    </Button>
                    <div />
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === profile?.images.length - 1 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex + 1)}
                    >
                      <RightOutlined />
                    </Button>
                  </ImagesBottomLayout>
                </div>
              ) : (
                <>
                  <ImagesLayout>
                    <div>
                      {profile?.images?.length > 0 ? (
                        <img
                          src={profile?.images?.[imageIndex]?.url}
                          alt="images"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setIsImageModalOpened(true);
                          }}
                        />
                      ) : (
                        <p style={{ position: 'absolute' }}>
                          등록된 이미지가 없습니다
                        </p>
                      )}
                    </div>
                  </ImagesLayout>
                  <ImagesBottomLayout>
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === 0 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex - 1)}
                    >
                      <LeftOutlined />
                    </Button>
                    <div />
                    <Button
                      disabled={
                        profile?.images === undefined ||
                        imageIndex === profile?.images.length - 1 ||
                        profile?.images.length === 0
                      }
                      onClick={() => setImageIndex(imageIndex + 1)}
                    >
                      <RightOutlined />
                    </Button>
                  </ImagesBottomLayout>
                </>
              )}
            </div>
            <BasicProfileContentLayout>
              <BasicProfileItemLayout>
                <div>
                  <p>홈페이지</p>
                  <a
                    href={httpUrl(profile?.basicCompanyProfile?.homePage)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {profile?.basicCompanyProfile?.homePage}
                  </a>{' '}
                </div>
                <div>
                  <p>사업자 등록번호</p>
                  {profile?.basicCompanyProfile?.registrationNumber}
                </div>
                <div>
                  <p>산업분야</p>
                  {profile?.basicCompanyProfile?.domain}
                </div>
                <div>
                  <p>전화번호</p>
                  {profile?.basicCompanyProfile?.phoneNumber}
                </div>
                <div>
                  <p>주소</p>
                  {profile?.basicCompanyProfile?.address}
                </div>
              </BasicProfileItemLayout>
            </BasicProfileContentLayout>
          </BasicProfileLayout>
        </RemovePaddingBodyLayout>
        <Divider />
        <TitleLayout>
          <p>담당자 정보</p>
        </TitleLayout>
        <BodyLayout>
          <ManagerInfoLayout>
            <div>
              <p>이름</p>
              {profile?.managerInfo?.name}
            </div>
            <div>
              <p>연락처</p>
              {profile?.managerInfo?.phoneNumber}
            </div>
            <div>
              <p>이메일</p>
              {profile?.managerInfo?.email}
            </div>
          </ManagerInfoLayout>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>기업 및 서비스 소개</p>
        </TitleLayout>
        <BodyLayout>
          <p style={{ marginRight: isMobile ? '24px' : '95px' }}>
            {profile?.introduction}
          </p>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>채용 형태</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.recruitmentTypes?.map((type, index) => (
            <RecruitmentTypeItem key={index}>{type}</RecruitmentTypeItem>
          ))}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>복지 및 혜택</p>
        </TitleLayout>
        <BodyLayout>
          <p style={{ marginRight: isMobile ? '24px' : '95px' }}>
            {profile?.welfare}
          </p>
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>기타 영상 및 URL</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.urls
            ?.filter((item) => item.name !== '' && item.link !== '')
            .map((url, index) => (
              <LinkItemLayout key={index}>
                <p>{url.name}</p>
                <a
                  href={httpUrl(url.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {url.link}
                </a>
              </LinkItemLayout>
            ))}
        </BodyLayout>
        <Divider />
        <TitleLayout>
          <p>자주 묻는 질문</p>
        </TitleLayout>
        <BodyLayout>
          {profile?.qnas?.length > 0 && (
            <QnaLayout>
              <QnaRow>질문</QnaRow>
              {profile?.qnas?.map((qna) => (
                <>
                  <QnaDivider />
                  <QnaRow>
                    <p>{qna.question}</p>
                  </QnaRow>
                  <QnaRow>:{qna.answer}</QnaRow>
                </>
              ))}
            </QnaLayout>
          )}
        </BodyLayout>
        <div style={{ height: '32px' }} />
        <Modal
          isOpen={isImageModalOpened}
          onClose={() => setIsImageModalOpened(false)}
          width={isMobile ? '100%' : '600px'}
          height="600px"
          radius="30px"
          closeButtonTransparent
        >
          <div
            style={{
              height: '600px',
              width: isMobile ? '100%' : '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              position: 'relative',
            }}
          >
            <img
              style={{
                maxWidth: isMobile ? '100%' : '600px',
                maxHeight: '100%',
                borderRadius: '10px',
              }}
              src={profile?.images?.[imageIndex]?.url}
              alt="selected"
            />
            <Button
              type="primary"
              disabled={
                profile?.images === undefined ||
                imageIndex === profile?.images.length - 1 ||
                profile?.images.length === 0
              }
              style={{
                width: '40px',
                height: '40px',
                padding: '4px',
                backgroundColor: '#efefef',
                position: 'absolute',
                borderColor: '#efefef',
                opacity: '80%',
                top: '276px',
                right: '16px',
              }}
              onClick={() => setImageIndex(imageIndex + 1)}
            >
              <RightOutlined style={{ fontSize: '32px' }} />
            </Button>
            <Button
              disabled={
                profile?.images === undefined ||
                imageIndex === 0 ||
                profile?.images.length === 0
              }
              onClick={() => setImageIndex(imageIndex - 1)}
              type="primary"
              style={{
                width: '40px',
                height: '40px',
                padding: '4px',
                opacity: '80%',
                backgroundColor: '#efefef',
                position: 'absolute',
                borderColor: '#efefef',
                top: '276px',
                left: '16px',
              }}
            >
              <LeftOutlined style={{ fontSize: '32px' }} />
            </Button>
          </div>
        </Modal>
      </Layout>
    );
  }
  return <div />;
};

export default CompanyProfileModal;
