import { DownOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Popover } from 'antd';
import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { AdvertisementStep, ReactionType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { GET_COMPANY_ADVERTISEMENT_REACTIONS } from '../../queries/company';
import { maskingName } from '../CompanyMatching/ApplicationsTab';

const GlobalStyle = createGlobalStyle`
  body {
    .sort-popover {
      padding-top: 4px;
      .ant-popover-arrow {
        opacity: 0;
      }
      .ant-popover-inner {
        border-radius: 10px;

      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }
   
  }
`;
const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const ContentLayout = styled.div`
  margin: 24px 0;
  background-color: white;
  border-radius: 10px;
  padding: 30px 18px 30px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  margin-bottom: 0px;
  position: relative;
`;

const TopLayout = styled.div`
  background-color: white;
  border-bottom: 1px solid #f1f1f1;
  padding: 0 0 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const MeetLayout = styled.div`
  margin: 0 17px;
  width: 333px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #f58025;
  p {
    color: #ffa171;
    margin-right: 36px;
    font-family: Noto Sans KR;
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
  span {
    font-family: Noto Sans KR;
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #373334;
  }
`;

const ApplyLayout = styled.div`
  margin: 0 17px;
  width: 333px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #fe706f;
  p {
    color: #fe706f;
    margin-right: 36px;
    font-family: Noto Sans KR;
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
  span {
    font-family: Noto Sans KR;
    font-size: 19px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #373334;
  }
`;

const SortPopoverButton = styled.button`
  width: 100%;
  font-size: 16px;
  outline: none;
  height: 40px;
  cursor: pointer;
  text-align: center;
  border: none;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  color: #262626;
`;

const SortPopoverLayout = styled.div`
  width: 180px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const BodyLayout = styled.div`
  margin-top: 22px;
  padding: 0 100px;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const SortButton = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
`;

const SortPopoverDivider = styled.div`
  height: 2px;
  background-color: #f0f0f0;
`;

const StudentItem = styled.div`
  margin-bottom: 8px;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  background-color: #fbfbfb;
  border-radius: 10px;
  p {
    max-height: 1.4em;
    line-height: 1.4em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Date = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2e;
  flex: 1;
`;

const MeetReaction = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffa171;
  min-width: 120px;
  max-width: 120px;
  margin-right: 60px;
  width: 120px;
`;

const ApplyReaction = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fe706f;
  min-width: 120px;
  max-width: 120px;
  margin-right: 60px;
  width: 120px;
`;

const Name = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2e;
  width: 80px;
  margin-right: 60px;
`;

const Degree = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2e;
  flex: 1;
`;

const CompanyAdvertisementState = () => {
  const { user } = useAuth();
  const [
    getCompanyAdvertisementReactions,
    { data: getCompanyAdvertisementReactionsData },
  ] = useLazyQuery(GET_COMPANY_ADVERTISEMENT_REACTIONS);
  useEffect(() => {
    if (user) {
      getCompanyAdvertisementReactions({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyAdvertisementReactions, user]);
  const [sort, setSort] = useState(null);
  const [isSortPopoverOpened, setIsSortPopoverOpened] = useState(false);

  return (
    <Layout>
      <TitleText>홍보글 상태 조회</TitleText>
      {getCompanyAdvertisementReactionsData?.company?.advertisement
        ?.approved && (
        <ContentLayout>
          <TopLayout>
            <MeetLayout>
              <p>만나보고 싶어요</p>
              <span>
                {
                  getCompanyAdvertisementReactionsData?.company?.advertisement?.reactions?.filter(
                    (item) => item.reactionType === ReactionType.meet,
                  )?.length
                }
                명
              </span>
            </MeetLayout>
            <ApplyLayout>
              <p>지원하고 싶어요</p>
              <span>
                {
                  getCompanyAdvertisementReactionsData?.company?.advertisement?.reactions?.filter(
                    (item) => item.reactionType === ReactionType.apply,
                  )?.length
                }
                명
              </span>
            </ApplyLayout>
          </TopLayout>
          <BodyLayout>
            <FilterLayout>
              <Spacer />
              <Popover
                placement="bottomLeft"
                visible={isSortPopoverOpened}
                overlayClassName={'sort-popover'}
                onVisibleChange={(visible) => {
                  setIsSortPopoverOpened(visible);
                }}
                trigger={'click'}
                content={
                  <SortPopoverLayout>
                    <SortPopoverButton
                      selected={sort === null}
                      onClick={() => {
                        setSort(null);
                        setIsSortPopoverOpened(false);
                      }}
                    >
                      전체
                    </SortPopoverButton>
                    <SortPopoverDivider />
                    <SortPopoverButton
                      selected={sort === ReactionType.meet}
                      onClick={() => {
                        setSort(ReactionType.meet);
                        setIsSortPopoverOpened(false);
                      }}
                    >
                      만나보고싶어요
                    </SortPopoverButton>
                    <SortPopoverDivider />
                    <SortPopoverButton
                      selected={sort === ReactionType.apply}
                      onClick={() => {
                        setSort(ReactionType.apply);
                        setIsSortPopoverOpened(false);
                      }}
                    >
                      지원하고싶어요
                    </SortPopoverButton>
                  </SortPopoverLayout>
                }
              >
                <SortButton type="primary">
                  <GlobalStyle />
                  <DownOutlined
                    style={{
                      fontSize: '12px',
                      color: '#ffffff',
                      marginRight: '10px',
                    }}
                  />
                  {sort === null
                    ? '전체'
                    : sort === ReactionType.apply
                    ? '지원하고싶어요'
                    : '만나보고싶어요'}
                </SortButton>
              </Popover>
            </FilterLayout>
            {getCompanyAdvertisementReactionsData?.company?.advertisement?.reactions
              ?.filter((item) => {
                if (sort === null) {
                  return true;
                }
                return item.reactionType === sort;
              })
              .map((item) => (
                <StudentItem>
                  <Date>{item.reactedAt}</Date>
                  {item.reactionType === ReactionType.meet ? (
                    <MeetReaction>만나보고 싶어요</MeetReaction>
                  ) : (
                    <ApplyReaction>지원하고 싶어요</ApplyReaction>
                  )}
                  <Name>
                    {maskingName(item.student?.basicStudentProfile?.name)}
                  </Name>
                  <Degree>
                    {item?.student?.educations?.[0]?.university}{' '}
                    {item?.student?.educations?.[0]?.major}{' '}
                    {item?.student?.educations?.[0]?.degree}
                  </Degree>
                </StudentItem>
              ))}
          </BodyLayout>
        </ContentLayout>
      )}
    </Layout>
  );
};

export default CompanyAdvertisementState;
