import { gql } from '@apollo/client';

// Queries

export const JOB_VACANCIES_BY_COMPANY = gql`
  query JobVacanciesByCompany($companyId: ID!) {
    jobVacanciesByCompany(companyId: $companyId) {
      id
      step
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      managerEmail
      approvedUniversities {
        id
        name
      }
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
    }
  }
`;

export const COMPLETED_JOB_VACANCIES_BY_COMPANY = gql`
  query CompletedJobVacanciesByCompany($companyId: ID!) {
    completedJobVacanciesByCompany(companyId: $companyId) {
      id
      step
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
      onMatching
      approvedUniversities {
        id
        name
      }
    }
  }
`;

export const GET_CUSTOM_ON_MATCHING_JOB_VACANCIES = gql`
  query GetCustomOnMatchingJobVacancies($studentId: ID, $companyId: ID) {
    customOnMatchingJobVacancies(studentId: $studentId, companyId: $companyId) {
      id
      step
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
      saved
      applied
      company {
        basicCompanyProfile {
          companyNameKR
        }
      }
    }
  }
`;

// Mutations

export const CREATE_JOB_VACANCY = gql`
  mutation CreateJobVacancy {
    createJobVacancy {
      id
      step
      position
      workplace
      task
      requiredDegree
      requiredKnowledge
      skills {
        id
        field
        skillDetail
      }
      environment
      recruitmentSchedule
      recruitmentProcess
    }
  }
`;

export const UPDATE_JOB_VACANCY = gql`
  mutation UpdateJobVacancy($id: ID, $jobVacancyInput: JobVacancyInput) {
    updateJobVacancy(id: $id, jobVacancyInput: $jobVacancyInput) {
      id
    }
  }
`;

export const DELETE_JOB_VACANCY = gql`
  mutation DeleteJobVacancy($id: ID) {
    deleteJobVacancy(id: $id)
  }
`;

export const DUPLICATE_JOB_VACANCY = gql`
  mutation DuplicateJobVacancy($id: ID) {
    duplicateJobVacancy(id: $id) {
      id
    }
  }
`;

export const LOOK_UP_JOB_VACANCY = gql`
  mutation LookUpJobVacancy($studentId: ID, $jobVacancyId: ID) {
    lookUpJobVacancy(studentId: $studentId, jobVacancyId: $jobVacancyId)
  }
`;
