import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import BannerIllust from '../../img/BoothIllust.png';
import Modal from '../../components/Modal';
import { BoothStep, CounselorType } from '../../constants/static';
import { Color } from '../../constants/color';
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusSquareOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  BoothTitle,
  Online,
  RecruitmentInformation,
  ManagerInfo,
  CounselorInformation,
  CounselingTime,
} from './ManageBoothComponents';
import { Button } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_BOOTHS_BY_COMPANY,
  CREATE_BOOTH,
  DELETE_BOOTH,
  DUPLICATE_BOOTH,
} from '../../queries/booth';
import useAuth from '../../hooks/useAuth';
import Present from './ManageBoothComponents/Present';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: 37px 48px;
  img {
    right: 27px;
    bottom: 18px;
    width: 220px;
    height: 222px;
    position: absolute;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: 34px;
  width: 183px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  min-height: ${(props) => (props.isModal ? '527px' : '527px')};
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const StepLayout = styled.div`
  margin: 0 72px 32px 61px;
  background-color: #e2e2ea;
  border-radius: 8.5px;
  height: 6px;
  position: relative;
`;

const FilledStepLayout = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${Color.Primary};
  border-radius: 6px;
  div {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${Color.Primary};
    border-radius: 24px;
    right: -10px;
    top: -9px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -1px;
    }
  }
`;

const FinishStepLaout = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 24px;
  right: -10px;
  top: -9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoothListLayout = styled.div`
  padding: 0 60px;
  margin-top: 43px;
`;

const BoothItem = styled.div`
  width: 100%;
  cursor: pointer;
  border: ${(props) =>
    props.isEditing ? '1.5px dashed #ffccc7' : '1.5px solid  #fe706f'};
  border-radius: 10px;
  overflow: hidden;
  cursor: ${(props) => (props.isEditing ? 'pointer' : 'inherit')};
  background-color: white;
  margin-bottom: 20px;
`;

const BoothItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const BoothItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: 240px;
`;

const EditingText = styled.p`
  margin-right: 96px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fe706f;
`;

const RightButtonLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  button {
    margin: 0 12px;
  }
`;

const BoothBodyLayout = styled.div`
  padding: 22px 0 34px;
`;

const BoothContentLayout = styled.div`
  display: flex;
  padding: 0 66px 0 77px;
`;

const BoothContentTitle = styled.p`
  min-width: 78px;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: #f0f0f0;
`;

const VerticalSpacer = styled.div`
  height: 9px;
`;
const BoothBody = styled.div`
  margin-left: 88px;
  margin-top: 4px;
  p {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
  }
`;

const AddBoothButton = styled(Button)`
  display: flex;
  width: 159px;
  height: 47px;
  align-items: center;
  border-radius: 15px;
  font-weight: 700;
  padding: 0 25px;
  margin: 56px auto 24px;
  background-color: #ff4d4f;
`;

const DateText = styled.p`
  padding: 4px;
  border-radius: 10px;
  background-color: #fdfdfd;
`;
const convertStepToWidth = ({ step }) => {
  const index = Object.values(BoothStep).findIndex(
    (keyStep) => keyStep === step,
  );
  return index < 0 ? '0' : `${((index + 1) / 7) * 100}%`;
};

const renderStepComponent = ({ editingBooth, editingStep, getBooths }) => {
  const step = editingStep ?? editingBooth.step;
  switch (step) {
    case BoothStep.title:
      return (
        <BoothTitle
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.online:
      return (
        <Online
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.recruitmentInformation:
      return (
        <RecruitmentInformation
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.managerInformation:
      return (
        <ManagerInfo
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.counselorInformation:
      return (
        <CounselorInformation
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.counselingTime:
      return (
        <CounselingTime
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    case BoothStep.present:
      return (
        <Present
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
    default:
      return (
        <BoothTitle
          booth={editingBooth}
          isCreating={editingBooth.step !== BoothStep.complete}
          getBooths={getBooths}
        />
      );
  }
};

const renderContent = ({ editingBooth, editingStep, getBooths }) => {
  console.log(editingStep);

  return (
    <EditingBodyLayout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>
          {!editingStep && (
            <>
              Step{' '}
              {Object.values(BoothStep).findIndex(
                (keyStep) => keyStep === editingBooth?.step,
              ) + 1}
              .
            </>
          )}{' '}
          {convertStepToStepString({ step: editingStep ?? editingBooth?.step })}
        </Title>
      </TitleLayout>
      {!editingStep && (
        <StepLayout>
          <FinishStepLaout>
            <CheckOutlined style={{ color: '#aaaaaa', fontSize: '12px' }} />
          </FinishStepLaout>
          <FilledStepLayout
            width={convertStepToWidth({ step: editingBooth?.step })}
          >
            <div>
              <p>
                {Object.values(BoothStep).findIndex(
                  (keyStep) => keyStep === editingBooth?.step,
                ) ===
                Object.values(BoothStep).length - 2 ? (
                  <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
                ) : (
                  Object.values(BoothStep).findIndex(
                    (keyStep) => keyStep === editingBooth?.step,
                  ) + 1
                )}
              </p>
            </div>
          </FilledStepLayout>
        </StepLayout>
      )}
      {renderStepComponent({ editingBooth, editingStep, getBooths })}
    </EditingBodyLayout>
  );
};

const convertStepToStepString = ({ step }) => {
  switch (step) {
    case BoothStep.title:
      return '부스 제목';
    case BoothStep.online:
      return '온라인 여부';
    case BoothStep.recruitmentInformation:
      return '채용 정보';
    case BoothStep.managerInformation:
      return '담당자 정보';
    case BoothStep.counselorInformation:
      return '상담자 정보';
    case BoothStep.counselingTime:
      return '상담자 시간';
    case BoothStep.present:
      return '상담 사은품';
    default:
      return '부스 제목';
  }
};

const mockBooths = [
  {
    id: 1,
    title: 'asdfasdfasdf',
    step: 'IS_ONLINE',
    isOnline: false,
    zoomLink: 'http://google.com',
    jobVacancies: ['test', 'test1'],
    managerInfo: {
      name: 'adsf',
      email: 'adsfasdf',
      phoneNumber: '02032324',
    },
    counselorRoles: ['FIELD'],
    counselStartAt: '12:00',
    counselEndAt: '14:00',
    counsels: [
      {
        startAt: '12:00',
        endAt: '12:15',
      },
      {
        startAt: '12:15',
        endAt: '12:30',
      },
    ],
    counselInterval: 15,
  },
  {
    id: 2,
    title: 'asdfsadfasdfasdf',
    step: 'COMPLETE',
    isOnline: true,
    zoomLink: 'http://google.com',
    jobVacancies: ['test', 'test1'],
    managerInfo: {
      name: 'adsf',
      email: 'adsfasdf',
      phoneNumber: '02032324',
    },
    counselorRoles: ['FIELD', 'HR'],
    counselStartAt: '12:00',
    counselEndAt: '14:00',
    counsels: [
      {
        startAt: '12:00',
        endAt: '12:15',
      },
      {
        startAt: '12:15',
        endAt: '12:30',
      },
    ],
    counselInterval: 15,
  },
  {
    id: 3,
    title: 'asdfsadfasdfasdf',
    step: 'COMPLETE',
    isOnline: true,
    zoomLink: 'http://google.com',
    jobVacancies: ['test', 'test1'],
    managerInfo: {
      name: 'adsf',
      email: 'adsfasdf',
      phoneNumber: '02032324',
    },
    counselorRoles: ['HR'],
    counselStartAt: '12:00',
    counselEndAt: '14:00',
    counsels: [
      {
        startAt: '12:00',
        endAt: '12:15',
      },
      {
        startAt: '12:15',
        endAt: '12:30',
      },
    ],
    counselInterval: 15,
  },
];
const ManageBooth = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [editingBooth, setEditingBooth] = useState(null);
  const [editingStep, setEditingStep] = useState(null);
  const [openedBoothId, setOpenedBoothId] = useState(0);
  const [getBoothsByCompany, { data: getBoothsByCompanyData }] = useLazyQuery(
    GET_BOOTHS_BY_COMPANY,
  );
  const [createBooth, { data: createBoothData }] = useMutation(CREATE_BOOTH);
  const [deleteBooth, { data: deleteBoothData }] = useMutation(DELETE_BOOTH);
  const [duplicateBooth, { data: duplicateBoothData }] =
    useMutation(DUPLICATE_BOOTH);
  const [duplicatingId, setDuplicatingId] = useState(0);
  const [deletingId, setDeletingId] = useState(0);
  const { user } = useAuth();
  useEffect(() => {
    getBoothsByCompany({
      variables: {
        id: user.id,
      },
    });
  }, [user, getBoothsByCompany]);
  useEffect(() => {
    if (editingBooth && getBoothsByCompanyData) {
      const index = getBoothsByCompanyData?.boothsByCompany?.findIndex(
        (item) => item.id === editingBooth.id,
      );
      if (index > -1) {
        setEditingBooth(
          getBoothsByCompanyData.boothsByCompany[index]?.step ===
            BoothStep.complete
            ? null
            : getBoothsByCompanyData.boothsByCompany[index],
        );
      }
    }
  }, [getBoothsByCompanyData]);
  useEffect(() => {
    if (createBoothData) {
      getBoothsByCompany({
        variables: {
          id: user.id,
        },
      });
      setEditingBooth(createBoothData?.createBooth);
      setIsModalOpened(true);
    }
  }, [createBoothData]);
  useEffect(() => {
    if (duplicateBoothData) {
      setDuplicatingId(0);
      getBoothsByCompany({
        variables: {
          id: user.id,
        },
      });
    }
  }, [duplicateBoothData]);
  useEffect(() => {
    if (deleteBoothData) {
      setDeletingId(0);
      getBoothsByCompany({
        variables: {
          id: user.id,
        },
      });
    }
  }, [deleteBoothData]);
  return (
    <Layout>
      <TitleText>부스관리</TitleText>
      {getBoothsByCompanyData && (
        <>
          {getBoothsByCompanyData?.boothsByCompany?.length === 0 ? (
            <AnnounceLayout>
              <AnnounceTitleLayout>
                <Icon icon={'edit'} size="21px" color="#ff434e" />
                <p>부스를 만들어주세요!</p>
              </AnnounceTitleLayout>
              <AnnounceBodyText>
                아직 부스가 존재하지 않네요.
                <br />
                부스를 만들고 박람회에 전시하세요!
              </AnnounceBodyText>
              <AnnounceBottomButton
                onClick={() => {
                  createBooth();
                  setEditingStep(null);
                }}
              >
                <p>부스 만들기</p>
                <Icon icon={'edit'} size="21px" color="#ffffff" />
              </AnnounceBottomButton>
              <img src={BannerIllust} alt="banner" />
            </AnnounceLayout>
          ) : (
            <>
              <AddBoothButton
                type="primary"
                onClick={() => {
                  createBooth();
                  setEditingStep(null);
                }}
              >
                부스 만들기
                <EditOutlined
                  style={{ fontSize: '23px', marginLeft: '26px' }}
                />
              </AddBoothButton>
              <BoothListLayout>
                {getBoothsByCompanyData?.boothsByCompany?.map((item) => (
                  <BoothItem
                    key={item.id}
                    opened={openedBoothId === item.id}
                    isEditing={item.step !== BoothStep.complete}
                    onClick={() => {
                      if (item.step === BoothStep.complete) {
                        setOpenedBoothId(
                          openedBoothId === item.id ? 0 : item.id,
                        );
                      } else {
                        setEditingStep(null);
                        setEditingBooth(item);
                        setIsModalOpened(true);
                      }
                    }}
                  >
                    <BoothItemTitleLayout opened={openedBoothId === item.id}>
                      {openedBoothId === item.id ? (
                        <DownOutlined
                          style={{
                            fontSize: '24px',
                            color:
                              item.step === BoothStep.complete
                                ? 'black'
                                : '#ffffff',
                          }}
                        />
                      ) : (
                        <RightOutlined
                          style={{
                            fontSize: '24px',
                            color:
                              item.step === BoothStep.complete
                                ? '#fe706f'
                                : '#ffffff',
                          }}
                        />
                      )}
                      <BoothItemTitle opened={openedBoothId === item.id}>
                        {item.title}
                      </BoothItemTitle>
                      {openedBoothId === item.id && (
                        <Button
                          type="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingStep(BoothStep.title);
                            setEditingBooth(item);
                            setIsModalOpened(true);
                          }}
                        >
                          <EditOutlined
                            style={{ fontSize: '18px', color: '#ffa283' }}
                          />
                        </Button>
                      )}
                      <Spacer />
                      {item.step !== BoothStep.complete && (
                        <EditingText>작성중</EditingText>
                      )}
                      <RightButtonLayout>
                        {item.step === BoothStep.complete && (
                          <Button
                            type="link"
                            loading={duplicatingId === item.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDuplicatingId(item.id);
                              duplicateBooth({
                                variables: {
                                  id: item.id,
                                },
                              });
                            }}
                          >
                            <CopyOutlined
                              style={{ fontSize: '20px', color: '#767676' }}
                            />
                          </Button>
                        )}
                        {item.exhibited ? (
                          <p style={{ color: '#fe706f', marginRight: '38px' }}>
                            전시중
                          </p>
                        ) : (
                          <Button
                            type="link"
                            loading={item.id === deletingId}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeletingId(item.id);
                              deleteBooth({
                                variables: {
                                  id: item.id,
                                },
                              });
                            }}
                          >
                            <DeleteOutlined
                              style={{ fontSize: '20px', color: '#767676' }}
                            />
                          </Button>
                        )}
                      </RightButtonLayout>
                    </BoothItemTitleLayout>
                    {openedBoothId === item.id && (
                      <BoothBodyLayout>
                        <BoothContentLayout>
                          <BoothContentTitle>온라인 여부</BoothContentTitle>
                          <Button
                            type="link"
                            style={{ width: '48px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingStep(BoothStep.online);
                              setEditingBooth(item);
                              setIsModalOpened(true);
                            }}
                          >
                            <EditOutlined
                              style={{
                                fontSize: '18px',
                                color: '#ffa283',
                                marginRight: '78px',
                              }}
                            />
                          </Button>
                          <BoothBody>
                            <p>
                              {item.isOnline
                                ? `온라인  (상담 방법: ${
                                    item.counselMethod
                                  } / ${
                                    item.zoomLink !== ''
                                      ? '상담링크 입력완료'
                                      : '상담링크 미입력'
                                  })`
                                : '오프라인'}
                            </p>
                          </BoothBody>
                        </BoothContentLayout>
                        <Divider />
                        <BoothContentLayout>
                          <BoothContentTitle>채용 정보</BoothContentTitle>
                          <Button
                            type="link"
                            style={{ width: '48px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingStep(BoothStep.recruitmentInformation);
                              setEditingBooth(item);
                              setIsModalOpened(true);
                            }}
                          >
                            <EditOutlined
                              style={{
                                fontSize: '18px',
                                color: '#ffa283',
                                marginRight: '78px',
                              }}
                            />
                          </Button>
                          <BoothBody>
                            {item.jobVacancies?.map((jobVacancy, index) => (
                              <>
                                {jobVacancy.position}
                                {index < item.jobVacancies.length - 1 && (
                                  <VerticalSpacer />
                                )}
                              </>
                            ))}
                          </BoothBody>
                        </BoothContentLayout>
                        <Divider />
                        <BoothContentLayout>
                          <BoothContentTitle>담당자 정보</BoothContentTitle>
                          <Button
                            type="link"
                            style={{ width: '48px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingStep(BoothStep.managerInformation);
                              setEditingBooth(item);
                              setIsModalOpened(true);
                            }}
                          >
                            <EditOutlined
                              style={{
                                fontSize: '18px',
                                color: '#ffa283',
                                marginRight: '78px',
                              }}
                            />
                          </Button>
                          <BoothBody>
                            <p>{item.managerInfo?.name}</p>
                            <VerticalSpacer />
                            <p>{item.managerInfo?.email}</p>
                            <VerticalSpacer />
                            <p>{item.managerInfo?.phoneNumber}</p>
                          </BoothBody>
                        </BoothContentLayout>
                        <Divider />
                        <BoothContentLayout>
                          <BoothContentTitle>상담자 정보</BoothContentTitle>
                          <Button
                            type="link"
                            style={{ width: '48px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingStep(BoothStep.counselorInformation);
                              setEditingBooth(item);
                              setIsModalOpened(true);
                            }}
                          >
                            <EditOutlined
                              style={{
                                fontSize: '18px',
                                color: '#ffa283',
                                marginRight: '78px',
                              }}
                            />
                          </Button>
                          <BoothBody>
                            <p>
                              {item.counselorRoles?.findIndex(
                                (item) => item === CounselorType.field,
                              ) > -1 && '현업'}
                              {item.counselorRoles?.length > 1 && ' / '}
                              {item.counselorRoles?.findIndex(
                                (item) => item === CounselorType.hr,
                              ) > -1 && '인사'}
                            </p>
                          </BoothBody>
                        </BoothContentLayout>
                        <Divider />
                        <BoothContentLayout>
                          <BoothContentTitle>상담 시간</BoothContentTitle>
                          {item.exhibited ? (
                            <div style={{ width: '48px' }} />
                          ) : (
                            <Button
                              type="link"
                              style={{ width: '48px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingStep(BoothStep.counselingTime);
                                setEditingBooth(item);
                                setIsModalOpened(true);
                              }}
                            >
                              <EditOutlined
                                style={{
                                  fontSize: '18px',
                                  color: '#ffa283',
                                  marginRight: '78px',
                                }}
                              />
                            </Button>
                          )}
                          <BoothBody>
                            <p>
                              {item.counselStartAt} - {item.counselEndAt}
                            </p>
                          </BoothBody>
                        </BoothContentLayout>
                        <Divider />
                        <BoothContentLayout>
                          <BoothContentTitle>상담 사은품</BoothContentTitle>
                          <Button
                            type="link"
                            style={{ width: '48px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditingStep(BoothStep.present);
                              setEditingBooth(item);
                              setIsModalOpened(true);
                            }}
                          >
                            <EditOutlined
                              style={{
                                fontSize: '18px',
                                color: '#ffa283',
                                marginRight: '78px',
                              }}
                            />
                          </Button>
                          <BoothBody>
                            <p>{item.present}</p>
                          </BoothBody>
                        </BoothContentLayout>
                      </BoothBodyLayout>
                    )}
                  </BoothItem>
                ))}
              </BoothListLayout>
            </>
          )}
        </>
      )}
      {editingBooth !== null && (
        <Modal
          isOpen={isModalOpened}
          onClose={() => {
            setIsModalOpened(false);
          }}
          width="971px"
          height="528px"
          radius="30px"
          dismissable
        >
          {renderContent({
            editingBooth,
            editingStep: editingStep,
            getBooths: () => {
              getBoothsByCompany({
                variables: {
                  id: user.id,
                },
              });
              if (editingStep !== null) {
                setIsModalOpened(false);
              }
            },
          })}
        </Modal>
      )}
    </Layout>
  );
};

export default ManageBooth;
