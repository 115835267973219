import { gql } from '@apollo/client';

export const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      field
      skillDetail
    }
  }
`;

export const SEARCH_SKILLS = gql`
  query SearchSkills($keyword: String!) {
    searchSkills(keyword: $keyword) {
      id
      field
      skillDetail
    }
  }
`;

export const ADD_STUDENT_SKILL = gql`
  mutation AddStudentSkill($studentId: ID, $skillId: ID) {
    addStudentSkill(studentId: $studentId, skillId: $skillId) {
      id
      field
      skillDetail
    }
  }
`;

export const ADD_STUDENT_TEMPORARY_SKILL = gql`
  mutation AddStudentTemporarySkill($studentId: ID, $skillDetail: String) {
    addStudentTemporarySkill(studentId: $studentId, skillDetail: $skillDetail) {
      id
      skillDetail
    }
  }
`;

export const DELETE_STUDENT_SKILL = gql`
  mutation DeleteStudentSkill($studentId: ID, $skillId: ID) {
    deleteStudentSkill(studentId: $studentId, skillId: $skillId)
  }
`;

export const DELETE_STUDENT_TEMPORARY_SKILL = gql`
  mutation DeleteStudentTemporarySkill($studentId: ID, $temporarySkillId: ID) {
    deleteStudentTemporarySkill(
      studentId: $studentId
      temporarySkillId: $temporarySkillId
    )
  }
`;
