import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import JobVacancyTab from './JobVacancyTab';
import CompanyTab from './CompanyTab';
import useAuth from '../../hooks/useAuth';
import { UserType } from '../../constants/static';
import { MatchingStudentContext } from '.';

const Layout = styled.div`
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  top: ${isMobile ? '56px' : '64px'};
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: ${isMobile ? '16px' : '20px'};
  color: #030303;
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding: ${isMobile ? '0 0 0' : '40px 0 0'};
  position: relative;
`;

const ListLayout = styled.div`
  margin: ${isMobile ? '0' : '40px 102px 0'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0' : '0 18px 35px'};
  height: ${isMobile ? '' : 'calc(100vh - 230px)'};
  margin-bottom: 32px;
  position: relative;
`;

const TabLayout = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  margin-bottom: ${isMobile ? '' : '12px'};
  background-color: white;
  position: ${isMobile ? 'relative' : ''};
  top: ${isMobile ? '' : ''};
  width: 100%;
  z-index: ${isMobile ? 1 : 10};
  margin-top: ${isMobile ? '16px' : ''};
  margin-left: ${isMobile ? '16px' : ''};
`;

const Tab = styled.div`
  padding: 16px 0;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: ${(props) => (props.selected ? '#FE706F' : '#262626')};
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: ${(props) =>
    props.selected ? '2px solid #fe706f' : '2px solid transparent'};
  margin-right: 32px;
  cursor: pointer;
`;

const StudentPage = () => {
  const { user } = useAuth();
  const { selectedTab, setSelectedTab } = useContext(MatchingStudentContext);
  return (
    <Layout>
      <Header>
        <HeaderTitle>
          {user.userType === UserType.company ? '매칭 기업' : '매칭'}
          <span
            style={{
              fontSize: '12px',
              marginLeft: '12px',
              marginTop: '2px',
            }}
          >
            매칭에 참여하는 기업들을 만나보세요!
          </span>
        </HeaderTitle>
      </Header>
      <BodyLayout>
        <ListLayout>
          <TabLayout>
            <Tab
              style={{ marginLeft: '52px' }}
              selected={selectedTab === 'company'}
              onClick={() => setSelectedTab('company')}
            >
              기업 기준 조회
            </Tab>
            <Tab
              selected={selectedTab === 'jobVacancy'}
              onClick={() => setSelectedTab('jobVacancy')}
            >
              채용 공고 기준 조회
            </Tab>
          </TabLayout>
          {selectedTab === 'jobVacancy' ? <JobVacancyTab /> : <CompanyTab />}
        </ListLayout>
      </BodyLayout>
    </Layout>
  );
};

export default StudentPage;
