import {
  BookOutlined,
  DownOutlined,
  FilterFilled,
  RetweetOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Popover, Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import {
  recruitmentTypeArray,
  sizeArray,
  UserType,
} from '../../constants/static';
import ReactPlayer from 'react-player/youtube';
import { getYoutubeThumbnail } from '../../constants/utils';
import useAuth from '../../hooks/useAuth';
import {
  APPLY_ADVERTISEMENT,
  GET_ADVERTISEMENT_DOMAINS,
  GET_APPROVED_ADVERTISEMENTS,
  GET_RANDOM_REP_ADVERTISEMENT,
  MEET_ADVERTISEMENT,
} from '../../queries/advertisement';
import { GET_STUDENT_PROFILE } from '../../queries/student';
import { AdvertisementContext } from '.';
import SmallModalWithTextareaLayout from '../../components/SmallModalWithTextareaLayout';
import InquiryModal from './InquiryModal';

const GlobalStyle = createGlobalStyle`
  body {
    .sort-popover {
      padding-top: 4px;
      .ant-popover-arrow {
        opacity: 0;
      }
      .ant-popover-inner {
        border-radius: 10px;

      }

      .ant-popover-inner-content {
        padding: 0;
      }
    }
   
  }
`;
const Layout = styled.div`
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  top: ${isMobile ? '56px' : '64px'};
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #030303;
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 84px;
  position: relative;
`;

const ListLayout = styled.div`
  position: absolute;
  width: ${isMobile ? '100%' : 'calc(100% - 216px)'};
  top: 200px;
  padding: ${isMobile ? '0' : '22px 0'};
  margin: ${isMobile ? '' : '0 108px'};
  background-color: white;
  height: ${isMobile ? '' : 'calc(100vh - 270px)'};
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: scroll;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: center;
  margin: ${isMobile ? '' : '35px 108px'};
  padding: ${isMobile ? '24px 28px' : '0 53px'};
  position: ${isMobile ? 'relative' : 'absolute'};
  top: ${isMobile ? '84px' : '84px'};
  height: ${isMobile ? '' : '88px'};
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  width: ${isMobile ? '100%' : 'calc(100% - 216px)'};
  z-index: ${isMobile ? 1 : 10};
`;

const FilterButton = styled(Button)`
  border-radius: 10px;
  margin-right: 8px;
  height: 40px;
  background-color: white;
  color: #373334;
  font-weight: 500;
  border-color: #ff7875;
`;

const ListBodyLayout = styled.div`
  width: ${isMobile ? '100vw' : '800px'};
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(390px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '20px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 20px;
  align-items: ${isMobileOnly ? 'center' : ''};
`;

const PopoverLayout = styled.div`
  width: 356px;
  height: ${isMobile ? '100%' : '314px'};
  overflow-y: scroll;
  padding: 10px 0 19px;
  .ant-picker-panel {
    display: ${isMobile ? 'none' : ''};
    visibility: ${isMobile ? 'hidden' : ''};
  }
`;

const PopoverFlexLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '0 15px 0 24px' : ''};
  align-items: center;
  color: #262626;
`;

const PopoverDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin: 17px 0;
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  color: #262626;
  margin-right: 12px;
  margin-left: 12px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledSelect2 = styled(Select)`
  flex: 1;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;
const SortButton = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  opacity: 80%;
`;

const SortPopoverLayout = styled.div`
  width: 100px;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const SortPopoverButton = styled.button`
  width: 100%;
  font-size: 16px;
  outline: none;
  height: 40px;
  cursor: pointer;
  text-align: center;
  border: none;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  color: #262626;
`;

const SortPopoverDivider = styled.div`
  height: 2px;
  background-color: #f0f0f0;
`;

const RepresentAdvertisement = styled.div`
  border-radius: 10px 10px 0 0;
  background-color: #f9f9f9;
  position: relative;
  width: ${isMobile ? 'calc(100vw - 16px)' : '800px'};
  min-height: ${isMobile ? 'calc(51vw - 8px)' : '408px !important'};
  max-height: ${isMobile ? 'calc(51vw - 8px)' : '408px'};
  overflow: hidden;
  img {
    width: ${isMobile ? 'calc(100vw - 16px)' : '800px'};
    height: ${isMobile ? 'calc(51vw - 8px)' : '408px'};
    object-fit: cover;
  }
`;

const RepresentOverlay = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  width: ${isMobile ? 'calc(100vw - 16px)' : '800px'};
  margin-bottom: ${isMobile ? '8px' : '20px'};
  box-shadow: 0px 15px 18px 0px #b452521c;
`;

const RepresentFlexLayout = styled.div`
  padding: 24px 24px 12px;
  display: flex;
  align-items: center;
`;

const RepresentImageLayout = styled.div`
  margin-right: 17px;
  height: 72px;
  width: 72px;
  position: relative;
  border-radius: 10px;
  background-color: #f2f2f2;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 72px;
    max-height: 72px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;
const RepresentOverlayText = styled.p`
  font-family: Noto Sans KR;
  font-size: ${isMobile ? '14px' : '24px'};
  font-weight: 700;
  line-height: ${isMobile ? '21px' : '32px'};
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: ${isMobile ? '' : ''};
`;

const RepresentButtonLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 24px;
`;

const RepresentButton = styled(Button)`
  border-radius: 10px;
  box-shadow: 0px 15px 18px 0px #b452521c;
  margin-right: ${isMobile ? '6px' : '12px'};
  width: ${isMobile ? '120px' : '165px'};
  height: ${isMobile ? '30px' : '36px'};
  border: none;
  color: white;
  font-size: ${isMobile ? '12px' : ''};
  font-weight: 700;
`;

const Advertisement = styled.div`
  border-radius: 10px 10px 0 0;
  margin-bottom: ${isMobile ? '' : ''};
  position: relative;
  width: ${isMobile ? 'calc(100vw - 16px)' : '390px'};
  min-height: ${isMobile ? 'calc(51vw - 8px)' : '199px'};
  max-height: ${isMobile ? 'calc(51vw - 8px)' : '199px'};
  overflow: hidden;
  img {
    width: ${isMobile ? 'calc(100vw - 16px)' : '390px'};
    height: ${isMobile ? 'calc(51vw - 8px)' : '199px'};
    object-fit: cover;
  }
`;

const Overlay = styled.div`
  background-color: #f9f9f9;
  width: ${isMobile ? 'calc(100vw - 16px)' : '390px'};
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 15px 18px 0px #b452521c;
  margin-bottom: ${isMobile ? '8px' : ''};
`;

const OverlayFlexLayout = styled.div`
  padding: 24px 24px 12px;
  display: flex;
  align-items: center;
`;

const OverlayText = styled.p`
  font-family: Noto Sans KR;
  font-size: ${isMobile ? '16px' : '15px'};
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const ButtonLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 24px;
`;

const AdvertisementButton = styled(Button)`
  border-radius: 10px;
  box-shadow: 0px 15px 18px 0px #b452521c;
  margin-right: 6px;
  width: 120px;
  height: 30px;
  font-size: 12px;
  border: none;
  color: white;
  font-weight: 700;
`;

const QuestionButton = styled(Button)`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  opacity: 80%;
  margin-left: 4px;
`;

const AdvertisementDefault = () => {
  const [target, setTarget] = useState(null);
  const history = useHistory();
  const [isFilterPopoverOpened, setIsFilterPopoverOpened] = useState(false);
  const { user } = useAuth();
  const [getStudentProfile, { data: getStudentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  useEffect(() => {
    if (user.userType === UserType.student) {
      getStudentProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [user, getStudentProfile]);
  const [editingFilter, setEditingFilter] = useState({
    size: [],
    domainIds: [],
    recruitmentTypes: [],
  });
  const {
    getFinished,
    sort,
    setSort,
    filter,
    setFilter,
    getApprovedAdvertisements,
    listAdvertisements,
    setListAdvertisements,
    getApprovedAdvertisementsData,
    getRandomRepAdvertisement,
    randomRepState,
    setRandomRepState,
    loading,
    scrollItem,
    setScrollItem,
  } = useContext(AdvertisementContext);
  useEffect(() => {
    if (isFilterPopoverOpened) {
      setEditingFilter(filter);
    }
  }, [filter, isFilterPopoverOpened]);
  const [isMatchingDisabledCompanyName, setIsMatchingDisabledCompanyName] =
    useState(null);
  const [matchingDisabledState, setMatchingDisabledState] = useState(false);
  const [matchingCompanyId, setMatchingCompanyId] = useState(null);
  const [meetCompanyName, setMeetCompanyName] = useState(null);
  const [isSortPopoverOpened, setIsSortPopoverOpened] = useState(false);
  const [meetAdvertisement] = useMutation(MEET_ADVERTISEMENT);
  const [applyAdvertisement] = useMutation(APPLY_ADVERTISEMENT);

  const onIntersect = useCallback(
    ([entry], observer) => {
      if (entry.isIntersecting && listAdvertisements.length > 0) {
        if (!loading && !getFinished) {
          getApprovedAdvertisements({
            variables: {
              first: 30,
              after:
                listAdvertisements.length === 0
                  ? null
                  : listAdvertisements[listAdvertisements.length - 1].id,
              companySize: filter.size,
              domainIds: filter.domainIds,
              recruitmentTypes: filter.recruitmentTypes,
              order: sort === '최신순' ? 'LATEST' : 'NAME',
            },
          });
        }
      }
    },
    [
      listAdvertisements,
      loading,
      getFinished,
      getApprovedAdvertisements,
      filter.size,
      filter.domainIds,
      filter.recruitmentTypes,
      sort,
    ],
  );
  const { data: getAdvertisementDomainsData } = useQuery(
    GET_ADVERTISEMENT_DOMAINS,
  );
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, onIntersect]);
  useEffect(() => {
    getRandomRepAdvertisement();
    setListAdvertisements([]);
    getApprovedAdvertisements({
      variables: {
        first: 30,
        after: null,
        companySize: filter.size,
        domainIds: filter.domainIds,
        recruitmentTypes: filter.recruitmentTypes,
        order: sort === '최신순' ? 'LATEST' : 'NAME',
      },
    });
  }, [sort, filter, getRandomRepAdvertisement, getApprovedAdvertisements]);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  const [playingId, setPlayingId] = useState(null);
  useEffect(() => {
    document.getElementById(scrollItem)?.scrollIntoView(true);
  }, [scrollItem]);
  const [questionModalOpened, setQuestionModalOpened] = useState(false);
  return (
    <Layout>
      <Header>
        <HeaderTitle>
          기업라운지
          <span
            style={{
              fontSize: '12px',
              marginLeft: '12px',
              marginTop: '2px',
            }}
          >
            원하는 기업의 정보를 찾아보세요!
          </span>
        </HeaderTitle>
      </Header>
      <Modal
        isOpen={questionModalOpened}
        onClose={() => setQuestionModalOpened(false)}
      >
        <InquiryModal close={() => setQuestionModalOpened(false)} />
      </Modal>
      <FilterLayout>
        {isMobile ? (
          <FilterButton onClick={() => setIsFilterPopoverOpened(true)}>
            <FilterFilled
              style={{
                fontSize: '16px',
                color: '#fe706f',
                marginRight: '10px',
              }}
            />
            필터로 원하는 기업 찾기
          </FilterButton>
        ) : (
          <Popover
            placement="bottomLeft"
            visible={isFilterPopoverOpened}
            onVisibleChange={(visible) => {
              setIsFilterPopoverOpened(visible);
            }}
            trigger={'click'}
            content={
              <PopoverLayout>
                <PopoverFlexLayout>
                  <Spacer />
                  필터 초기화
                  <Button
                    type="link"
                    onClick={() => {
                      setEditingFilter({
                        degree: [],
                        universities: [],
                        skills: [],
                        recruitmentTypes: [],
                      });
                    }}
                  >
                    <RetweetOutlined style={{ fontSize: '24px' }} />
                  </Button>
                </PopoverFlexLayout>
                <PopoverDivider />
                <PopoverFlexLayout>
                  <PopoverTitle>기업규모</PopoverTitle>
                  <StyledSelect2
                    mode="multiple"
                    allowClear
                    placeholder="기업규모 선택"
                    showSearch={false}
                    filterOption={false}
                    value={editingFilter.size}
                    onChange={(value) =>
                      setEditingFilter({
                        ...editingFilter,
                        size: value,
                      })
                    }
                  >
                    {sizeArray?.map((item) => (
                      <Select.Option value={item}>{item}</Select.Option>
                    ))}
                  </StyledSelect2>
                </PopoverFlexLayout>
                <PopoverDivider />
                <PopoverFlexLayout>
                  <PopoverTitle>산업분야</PopoverTitle>
                  <StyledSelect2
                    mode="multiple"
                    allowClear
                    placeholder="산업분야 선택"
                    showSearch={false}
                    filterOption={false}
                    value={editingFilter.domainIds}
                    onChange={(value) =>
                      setEditingFilter({
                        ...editingFilter,
                        domainIds: value,
                      })
                    }
                  >
                    {getAdvertisementDomainsData?.advertisementDomains?.map(
                      (item) => (
                        <Select.Option value={item.id}>
                          {item.name}
                        </Select.Option>
                      ),
                    )}
                  </StyledSelect2>
                </PopoverFlexLayout>
                <PopoverDivider />
                <PopoverFlexLayout>
                  <PopoverTitle>채용형태</PopoverTitle>
                  <StyledSelect2
                    mode="multiple"
                    allowClear
                    placeholder="채용형태 선택"
                    showSearch={false}
                    filterOption={false}
                    value={editingFilter.recruitmentTypes}
                    onChange={(value) =>
                      setEditingFilter({
                        ...editingFilter,
                        recruitmentTypes: value,
                      })
                    }
                  >
                    {recruitmentTypeArray?.map((item) => (
                      <Select.Option value={item}>{item}</Select.Option>
                    ))}
                  </StyledSelect2>
                </PopoverFlexLayout>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    color: '#fe706f',
                    marginTop: '24px',
                    marginBottom: '8px',
                  }}
                >
                  * 적용 버튼을 눌러야 변경사항이 적용됩니다.
                </p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    style={{ borderRadius: '10px' }}
                    type="primary"
                    onClick={() => {
                      setIsFilterPopoverOpened(false);
                      setFilter(editingFilter);
                    }}
                  >
                    적용
                  </Button>
                </div>
              </PopoverLayout>
            }
          >
            <FilterButton onClick={() => setIsFilterPopoverOpened(true)}>
              <FilterFilled
                style={{
                  fontSize: '16px',
                  color: '#fe706f',
                  marginRight: '10px',
                }}
              />
              필터로 원하는 기업 찾기
            </FilterButton>
          </Popover>
        )}
        {user.userType === UserType.company && (
          <Button
            type="primary"
            style={{
              marginLeft: '12px',
              borderRadius: '10px',
              height: '40px',
            }}
            onClick={() => {
              setQuestionModalOpened(true);
            }}
          >
            제작 문의
          </Button>
        )}
        <Spacer />
        <Popover
          placement="bottomLeft"
          visible={isSortPopoverOpened}
          overlayClassName={'sort-popover'}
          onVisibleChange={(visible) => {
            setIsSortPopoverOpened(visible);
          }}
          trigger={'click'}
          content={
            <SortPopoverLayout>
              <SortPopoverButton
                selected={sort === '최신순'}
                onClick={() => {
                  setSort('최신순');
                  setIsSortPopoverOpened(false);
                }}
              >
                최신순
              </SortPopoverButton>
              <SortPopoverDivider />
              <SortPopoverButton
                selected={sort === '이름순'}
                onClick={() => {
                  setSort('이름순');
                  setIsSortPopoverOpened(false);
                }}
              >
                이름순
              </SortPopoverButton>
            </SortPopoverLayout>
          }
        >
          <SortButton type="primary">
            <GlobalStyle />
            <DownOutlined
              style={{
                fontSize: '12px',
                color: '#ffffff',
                marginRight: '10px',
              }}
            />
            {sort}
          </SortButton>
        </Popover>
      </FilterLayout>
      <ListLayout>
        {randomRepState && (
          <>
            <RepresentAdvertisement
              onClick={() => {
                history.push(`/advertisement/${randomRepState?.id}`);
              }}
              onMouseEnter={() => {
                setPlayingId(0);
              }}
              onMouseLeave={() => {
                setPlayingId(null);
              }}
            >
              {randomRepState?.image ? (
                <img
                  src={
                    randomRepState?.image?.url ??
                    getYoutubeThumbnail(randomRepState?.youtubeLink)
                  }
                  alt="represent"
                />
              ) : (
                <>
                  {playingId === 0 ? (
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      controls={false}
                      onClick={() =>
                        history.push(`/advertisement/${randomRepState?.id}`)
                      }
                      playing={true}
                      url={randomRepState?.youtubeLink}
                    />
                  ) : (
                    <img
                      src={getYoutubeThumbnail(randomRepState?.youtubeLink)}
                      alt="thumbnail"
                    />
                  )}
                </>
              )}
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </RepresentAdvertisement>
            <RepresentOverlay>
              <RepresentFlexLayout>
                <RepresentImageLayout>
                  {randomRepState?.company?.logo !== null &&
                    randomRepState?.company?.logo !== undefined && (
                      <img
                        src={randomRepState?.company?.logo?.url}
                        alt="thumbnail"
                      />
                    )}
                </RepresentImageLayout>
                <RepresentOverlayText>
                  {randomRepState?.title}
                </RepresentOverlayText>
              </RepresentFlexLayout>
              {user.userType === UserType.student && (
                <RepresentButtonLayout>
                  <RepresentButton
                    disabled={randomRepState.met}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        Boolean(
                          getStudentProfileData?.student?.basicStudentProfile
                            ?.name,
                        )
                      ) {
                        setListAdvertisements((prev) =>
                          prev.map((ad) =>
                            ad.id === randomRepState.id
                              ? { ...ad, met: true }
                              : ad,
                          ),
                        );
                        setRandomRepState((prev) => ({ ...prev, met: true }));
                        meetAdvertisement({
                          variables: {
                            studentId: user.id,
                            advertisementId: randomRepState?.id,
                          },
                        });
                        setMeetCompanyName(
                          randomRepState?.company?.basicCompanyProfile
                            ?.companyNameKR,
                        );
                      } else {
                        setIsWarningModalOpened(true);
                      }
                    }}
                    type="primary"
                  >
                    만나보고 싶어요
                  </RepresentButton>
                  <RepresentButton
                    disabled={randomRepState.applied}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        Boolean(
                          getStudentProfileData?.student?.basicStudentProfile
                            ?.name,
                        )
                      ) {
                        setRandomRepState((prev) => ({
                          ...prev,
                          applied: true,
                        }));
                        setListAdvertisements((prev) =>
                          prev.map((ad) =>
                            ad.id === randomRepState.id
                              ? { ...ad, applied: true }
                              : ad,
                          ),
                        );

                        applyAdvertisement({
                          variables: {
                            studentId: user.id,
                            advertisementId: randomRepState?.id,
                          },
                        });
                        setMatchingCompanyId(randomRepState?.company?.id);
                        if (!randomRepState?.company?.onMatching) {
                          setMatchingDisabledState(true);
                        }
                        setIsMatchingDisabledCompanyName(
                          randomRepState?.company?.basicCompanyProfile
                            ?.companyNameKR,
                        );
                      } else {
                        setIsWarningModalOpened(true);
                      }
                    }}
                    type="primary"
                  >
                    지원하고 싶어요
                  </RepresentButton>
                </RepresentButtonLayout>
              )}
            </RepresentOverlay>
            {isMobile && user.userType === UserType.student && (
              <div
                style={{
                  height: '1px',
                  width: 'calc(100vw - 8px)',
                  marginBottom: '16px',
                  backgroundColor: '#efefef',
                }}
              />
            )}
          </>
        )}
        <ListBodyLayout>
          {listAdvertisements?.map((item) => (
            <div>
              <div>
                <Advertisement
                  key={item.id}
                  id={`advertisement-${item.id}`}
                  onClick={() => {
                    setScrollItem(`advertisement-${item.id}`);
                    history.push(`/advertisement/${item?.id}`);
                  }}
                  onMouseEnter={() => {
                    setPlayingId(item.id);
                  }}
                  onMouseLeave={() => {
                    setPlayingId(null);
                  }}
                >
                  {item?.image ? (
                    <img
                      src={
                        item?.image?.url ??
                        getYoutubeThumbnail(item?.youtubeLink)
                      }
                      alt="represent"
                    />
                  ) : (
                    <>
                      {playingId === item.id ? (
                        <ReactPlayer
                          width="100%"
                          height="199px"
                          controls={false}
                          onClick={() =>
                            history.push(`/advertisement/${randomRepState?.id}`)
                          }
                          playing={true}
                          url={item?.youtubeLink}
                        />
                      ) : (
                        <img
                          src={getYoutubeThumbnail(item?.youtubeLink)}
                          alt="thumbnail"
                        />
                      )}
                    </>
                  )}
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  />
                </Advertisement>
              </div>

              <Overlay>
                <OverlayFlexLayout>
                  <RepresentImageLayout>
                    {item?.company?.logo !== null &&
                      item?.company?.logo !== undefined && (
                        <img src={item?.company?.logo?.url} alt="thumbnail" />
                      )}
                  </RepresentImageLayout>
                  <OverlayText>{item.title}</OverlayText>
                </OverlayFlexLayout>

                {user.userType === UserType.student && (
                  <ButtonLayout>
                    <AdvertisementButton
                      disabled={item.met}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          Boolean(
                            getStudentProfileData?.student?.basicStudentProfile
                              ?.name,
                          )
                        ) {
                          if (randomRepState.id === item.id) {
                            setRandomRepState((prev) => ({
                              ...prev,
                              met: true,
                            }));
                          }
                          setListAdvertisements((prev) =>
                            prev.map((ad) =>
                              ad.id === item.id ? { ...ad, met: true } : ad,
                            ),
                          );
                          meetAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId: item?.id,
                            },
                          });
                          setMeetCompanyName(
                            item?.company?.basicCompanyProfile?.companyNameKR,
                          );
                        } else {
                          setIsWarningModalOpened(true);
                        }
                      }}
                      type="primary"
                    >
                      만나보고 싶어요
                    </AdvertisementButton>
                    <AdvertisementButton
                      disabled={item.applied}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          Boolean(
                            getStudentProfileData?.student?.basicStudentProfile
                              ?.name,
                          )
                        ) {
                          if (randomRepState.id === item.id) {
                            setRandomRepState((prev) => ({
                              ...prev,
                              applied: true,
                            }));
                          }
                          setListAdvertisements((prev) =>
                            prev.map((ad) =>
                              ad.id === item.id ? { ...ad, applied: true } : ad,
                            ),
                          );
                          applyAdvertisement({
                            variables: {
                              studentId: user.id,
                              advertisementId: item?.id,
                            },
                          });
                          setMatchingCompanyId(item?.company?.id);
                          if (!item?.company?.onMatching) {
                            setMatchingDisabledState(true);
                          }
                          setIsMatchingDisabledCompanyName(
                            item?.company?.basicCompanyProfile?.companyNameKR,
                          );
                        } else {
                          setIsWarningModalOpened(true);
                        }
                      }}
                      type="primary"
                    >
                      지원하고 싶어요
                    </AdvertisementButton>
                  </ButtonLayout>
                )}
              </Overlay>
            </div>
          ))}
          {getApprovedAdvertisementsData?.approvedAdvertisements?.length !==
            0 &&
          getApprovedAdvertisementsData?.approvedAdvertisements?.length % 2 ==
            1 ? (
            <div />
          ) : null}
        </ListBodyLayout>
        <div ref={setTarget} className="Target-Element" />
      </ListLayout>
      <Modal
        isOpen={isMatchingDisabledCompanyName !== null}
        onClose={() => {
          setIsMatchingDisabledCompanyName(null);
          setMatchingDisabledState(false);
          setMatchingCompanyId(null);
        }}
      >
        {matchingDisabledState ? (
          <SmallModalLayout
            setDescriptionCenter
            title="지원하고싶어요"
            description={`${isMatchingDisabledCompanyName} 기업에 관심 가져주셔서 감사합니다.\n해당 기업은 현재 매칭 서비스를 이용하고 있지 않지만,\n매칭서비스를 신청시 회원님께 바로 안내드리겠습니다.`}
            removeLeftButton
            onClickRightButton={() => {
              setMatchingDisabledState(false);
              setIsMatchingDisabledCompanyName(null);
              setMatchingCompanyId(null);
            }}
          />
        ) : (
          <SmallModalLayout
            setDescriptionCenter
            title="지원하고싶어요"
            description={`이미 매칭 서비스를 이용하고 있는 기업입니다.\n채용 지원을 하시겠습니까?`}
            onClickLeftButton={() => {
              setIsMatchingDisabledCompanyName(null);
              setMatchingCompanyId(null);
            }}
            onClickRightButton={() =>
              history.push(`/matching/${matchingCompanyId}`)
            }
          />
        )}
      </Modal>
      <Modal
        isOpen={meetCompanyName !== null}
        onClose={() => setMeetCompanyName(null)}
      >
        <SmallModalLayout
          setDescriptionCenter
          title="만나보고싶어요"
          description={`${meetCompanyName} 기업에 관심 가져주셔서 감사합니다.\n가까운 시일내에 만나볼 수 있는 자리를 마련하도록 하겠습니다.`}
          removeLeftButton
          onClickRightButton={() => setMeetCompanyName(null)}
        />
      </Modal>
      <Modal
        isOpen={isFilterPopoverOpened && isMobile}
        onClose={() => setIsFilterPopoverOpened(false)}
      >
        <PopoverLayout>
          <div style={{ height: '40px' }} />
          <PopoverFlexLayout>
            <Spacer />
            필터 초기화
            <Button
              type="link"
              onClick={() => {
                setEditingFilter({
                  degree: [],
                  universities: [],
                  skills: [],
                  recruitmentTypes: [],
                });
              }}
            >
              <RetweetOutlined style={{ fontSize: '24px' }} />
            </Button>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>기업규모</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              placeholder="기업규모 선택"
              showSearch={false}
              filterOption={false}
              value={editingFilter.size}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  size: value,
                })
              }
            >
              {sizeArray?.map((item) => (
                <Select.Option value={item}>{item}</Select.Option>
              ))}
            </StyledSelect2>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>산업분야</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              placeholder="산업분야 선택"
              showSearch={false}
              filterOption={false}
              value={editingFilter.domainIds}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  domainIds: value,
                })
              }
            >
              {getAdvertisementDomainsData?.advertisementDomains?.map(
                (item) => (
                  <Select.Option value={item.id}>{item.name}</Select.Option>
                ),
              )}
            </StyledSelect2>
          </PopoverFlexLayout>
          <PopoverDivider />
          <PopoverFlexLayout>
            <PopoverTitle>채용형태</PopoverTitle>
            <StyledSelect2
              mode="multiple"
              allowClear
              placeholder="채용형태 선택"
              showSearch={false}
              filterOption={false}
              value={editingFilter.recruitmentTypes}
              onChange={(value) =>
                setEditingFilter({
                  ...editingFilter,
                  recruitmentTypes: value,
                })
              }
            >
              {recruitmentTypeArray?.map((item) => (
                <Select.Option value={item}>{item}</Select.Option>
              ))}
            </StyledSelect2>
          </PopoverFlexLayout>
          <p
            style={{
              textAlign: 'center',
              fontSize: '12px',
              color: '#fe706f',
              marginTop: '24px',
              marginBottom: '8px',
            }}
          >
            * 적용 버튼을 눌러야 변경사항이 적용됩니다.
          </p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{ borderRadius: '10px' }}
              type="primary"
              onClick={() => {
                setIsFilterPopoverOpened(false);
                setFilter(editingFilter);
              }}
            >
              적용
            </Button>
          </div>
        </PopoverLayout>
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => setIsWarningModalOpened(false)}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default AdvertisementDefault;
