import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../../hooks/useInput';
import { Color } from '../../../constants/color';
import Input from '../../../components/Input';
import { emailRegex, passwordRegex } from '../../../constants/regex';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import { useMutation } from '@apollo/client';
import { CREATE_COUNSELOR } from '../../../queries/counsel';
import useAuth from '../../../hooks/useAuth';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '42px 36px 14px' : '42px 96px 40px'};
  z-index: 10;
  display: flex;
  flex-flow: column;
`;

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${isMobile ? '12px' : '41px'};
  color: '#3a3a3a';
`;

const InputLayout = styled.div`
  margin-bottom: ${isMobile ? '8px' : '22px'};
`;

const CreateCounselAccountModal = ({ onClose, counselAccounts }) => {
  const [createCounselor, { data, error }] = useMutation(CREATE_COUNSELOR);
  const { user } = useAuth();
  const [{ email, managerName, password, confirmPassword }, onChange] =
    useInputs({
      email: '',
      managerName: '',
      password: '',
      confirmPassword: '',
    });
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const passwordErrorMessage = useMemo(() => {
    const regex = new RegExp(passwordRegex);
    return password !== '' && !regex.test(password)
      ? '비밀번호가 형식에 맞지 않습니다.'
      : '';
  }, [password]);
  const confirmPasswordErrorMessage = useMemo(() => {
    return confirmPassword !== '' &&
      password !== '' &&
      password !== confirmPassword
      ? '비밀번호가 일치하지 않습니다.'
      : '';
  }, [password, confirmPassword]);
  useEffect(() => {
    if (
      error?.graphQLErrors?.[0]?.extensions?.code === 'EMAIL_ALREADY_EXISTS'
    ) {
      setEmailErrorMessage('이미 가입된 이메일입니다.');
    }
  }, [error]);
  useEffect(() => {
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailErrorMessage('유효하지 않은 이메일입니다.');
    } else if (
      counselAccounts?.findIndex((item) => item.email === email) > -1
    ) {
      setEmailErrorMessage('이미 추가된 이메일입니다.');
    } else {
      setEmailErrorMessage('');
    }
  }, [email, counselAccounts]);
  useEffect(() => {
    if (data && !error) {
      onClose();
    }
  }, [data, error]);
  return (
    <Layout>
      <BodyLayout>
        <TitleText>상담 계정 추가</TitleText>
        <InputLayout>
          <Input
            name="email"
            value={email}
            onChange={onChange}
            label="이메일"
            placeholder={'상담 계정 이메일을 입력해주세요'}
            errorMessage={emailErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  managerName === ''
                )
              ) {
                createCounselor({
                  variables: {
                    userId: user.id,
                    email: email,
                    name: managerName,
                    password: password,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="managerName"
            value={managerName}
            onChange={onChange}
            label="담당자"
            placeholder={'담당자 이름을 입력해주세요'}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  managerName === ''
                )
              ) {
                createCounselor({
                  variables: {
                    userId: user.id,
                    email: email,
                    name: managerName,
                    password: password,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="password"
            value={password}
            onChange={onChange}
            label="비밀번호"
            placeholder={'영문, 숫자, 특수문자 포함 8자 이상'}
            secure
            errorMessage={passwordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  managerName === ''
                )
              ) {
                createCounselor({
                  variables: {
                    userId: user.id,
                    email: email,
                    name: managerName,
                    password: password,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChange}
            label="비밀번호 확인"
            placeholder={'영문, 숫자, 특수문자 포함 8자 이상'}
            secure
            errorMessage={confirmPasswordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  managerName === ''
                )
              ) {
                createCounselor({
                  variables: {
                    userId: user.id,
                    email: email,
                    name: managerName,
                    password: password,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <Button
          onClick={() => {
            createCounselor({
              variables: {
                userId: user.id,
                email: email,
                name: managerName,
                password: password,
              },
            });
          }}
          disabled={
            email === '' ||
            emailErrorMessage !== '' ||
            password === '' ||
            passwordErrorMessage !== '' ||
            confirmPassword === '' ||
            confirmPasswordErrorMessage !== '' ||
            managerName === ''
          }
          type="primary"
          style={{
            width: '272px',
            margin: isMobile ? '6px auto 0' : '20px auto 0',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          상담 계정 추가
        </Button>
      </BodyLayout>
    </Layout>
  );
};

export default CreateCounselAccountModal;
