import { BookOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { isMobile } from 'react-device-detect';

const { TextArea } = Input;
const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 37px 0 23px;
  overflow-y: scroll;
`;

const TitleLayout = styled.div`
  margin-left: ${isMobile ? '24px' : '48px'};
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const ContentLayout = styled.div`
  display: flex;
  width: fit-content;
  margin: 16px auto 20px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 544px;
  min-height: 129px !important;
  border-radius: 10px;
  margin: 0 auto;
`;
const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto 0;
`;

const MethodModal = ({ link, onClose, method }) => {
  return (
    <Layout>
      <TitleLayout>
        <Title>상담 방법</Title>
      </TitleLayout>
      <div style={{ height: '32px' }} />
      {link !== '' && (
        <>
          <ContentLayout>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p>화상상담 링크</p>
          </ContentLayout>
          <Button
            style={{
              borderRadius: '10px',
              width: '120px',
              margin: '0 auto 12px',
              color: isMobile ? '#fe706f' : '',
              borderColor: isMobile ? '#fe706f' : '',
            }}
            onClick={() => {
              window.open(link, '_blank');
            }}
          >
            상담 링크 이동
          </Button>
        </>
      )}
      <ContentLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>상담 방법</p>
      </ContentLayout>
      <p style={{ textAlign: 'center' }}>{method}</p>
      <ButtonLayout>
        <BottomCenterButton type="primary" onClick={onClose}>
          확인
        </BottomCenterButton>
      </ButtonLayout>
    </Layout>
  );
};

export default MethodModal;
