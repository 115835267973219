import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, Input, Upload } from 'antd';
import { isMobile } from 'react-device-detect';
import Icon from '../../components/Icon';
import BannerIllust from '../../img/MatchingBannerIllust.png';
import PendingIllust from '../../img/MatchingPendingIllust.png';
import ApprovedIllust from '../../img/MatchingApprovedIllust.png';
import { ApplyMatchingStep, FileType } from '../../constants/static';
import {
  BookOutlined,
  CheckOutlined,
  IssuesCloseOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Color } from '../../constants/color';
import {
  DELETE_COMPANY_FILE,
  UPLOAD_COMPANY_FILE,
} from '../../queries/company';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import useAuth from '../../hooks/useAuth';
import {
  APPLY_MATCHING,
  GET_MATCHING_SERVICE_AGREEMENT,
  GET_PAY_SERVICE_AGREEMENT,
  LATEST_MATCHING_APPLICATION_BY_COMPANY,
} from '../../queries/matching';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: 24px;
`;

const GuideText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #fe706f;
    margin-right: 8px;
  }
`;

const AnnounceLayout = styled.div`
  width: 100%;
  height: ${isMobile ? '288px' : ''};
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: ${isMobile ? '35px 23px' : '37px 48px'};
  img {
    right: ${isMobile ? '-8px' : '27px'};
    bottom: ${isMobile ? '44px' : '18px'};
    width: ${isMobile ? '148px' : '220px'};
    height: ${isMobile ? '148px' : '222px'};
    position: absolute;
    z-index: 0;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  position: ${isMobile ? 'absolute' : ''};
  z-index: 2;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: ${isMobile ? '140px' : '34px'};
  width: 145px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const ProfileEditingLayout = styled.div`
  margin-bottom: 40px;
  background: #ffffff;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
  width: 971px;
`;

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  min-height: ${isMobile ? '271px' : '527px'};
`;

const TitleLayout = styled.div`
  margin-left: ${isMobile ? '24px' : '48px'};
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const StepLayout = styled.div`
  margin: 0 72px 52px 61px;
  background-color: #e2e2ea;
  border-radius: 8.5px;
  height: 6px;
  position: relative;
`;

const FilledStepLayout = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${Color.Primary};
  border-radius: 6px;
  div {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${Color.Primary};
    border-radius: 24px;
    right: -10px;
    top: -9px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -1px;
    }
  }
`;

const FinishStepLaout = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 24px;
  right: -10px;
  top: -9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const FileUploadLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 16px auto auto 234px;
`;

const UploadButton = styled(Button)`
  width: 176px;
  height: 40px;
  border-radius: 10px;
  margin-left: 0;
  margin-right: auto;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const TextLayout = styled.div`
  width: 670px;
  height: 142px;
  overflow: scroll;
  border-radius: 10px;
  border: 1px solid #fe706f;
  padding: 16px;
  margin-left: 151px;
  margin-top: 16px;
  white-space: pre-wrap;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 43px;
  margin-left: 163px;
`;

const convertStepToWidth = ({ step }) => {
  return `${(step / 3) * 100}%`;
};

const StyledInput = styled(Input)`
  width: 498px;
  margin: 28px auto 49px;
  border-radius: 10px;
`;

const FinishedLayout = styled.div`
  width: 720px;
  height: 225px;
  position: relative;
  box-shadow: 0px 3px 12px 0px #00000014;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 456px;
  }
  p {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 91px;
    margin-left: 39px;
  }
`;

const ConvertStepToComponent = ({
  editingApplyment,
  setEditingApplyment,
  step,
  applyMatching,
  setStep,
}) => {
  const { user } = useAuth();
  const [uploadCompanyFile, { data: uploadCompanyFileData }] =
    useMutation(UPLOAD_COMPANY_FILE);
  const [deleteCompanyFile, { data: deleteCompanyFileData }] = useMutation(
    DELETE_COMPANY_FILE,
    {
      onCompleted: (data) => {
        setEditingApplyment((prev) => ({
          ...prev,
          businessRegistration: null,
        }));
      },
    },
  );
  const { data: getMatchingServiceAgreementData } = useQuery(
    GET_MATCHING_SERVICE_AGREEMENT,
  );
  const { data: getPayServiceAgreementData } = useQuery(
    GET_PAY_SERVICE_AGREEMENT,
  );

  useEffect(() => {
    if (uploadCompanyFileData !== undefined) {
      setEditingApplyment((prev) => ({
        ...prev,
        businessRegistration: uploadCompanyFileData?.uploadCompanyFile,
      }));
    }
  }, [setEditingApplyment, uploadCompanyFileData]);

  const handleUpload = (file) => {
    if (file.size / 1024 / 1024 >= 10) {
      alert('10MB보다 작은 파일만 업로드 가능합니다.');
    } else {
      uploadCompanyFile({
        variables: {
          userId: user.id,
          fileType: FileType.businessRegistration,
          file: file,
        },
      });
    }
    return false;
  };
  const renderComponent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <TitleLaout>
              <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
              <p
                style={{
                  width: '520px',
                  textAlign: 'left',
                }}
              >
                세금 계산서를 받으실 이메일을 입력해주세요.
              </p>
            </TitleLaout>
            <StyledInput
              size="large"
              placeholder="이메일을 입력해주세요"
              value={editingApplyment.email}
              onChange={(e) =>
                setEditingApplyment({
                  ...editingApplyment,
                  email: e.currentTarget.value,
                })
              }
            />
            <TitleLaout>
              <BookOutlined
                style={{
                  fontSize: '14px',
                  color: '#fe706f',
                  marginLeft: '23px',
                }}
              />
              <p
                style={{
                  width: '543px',
                  textAlign: 'left',
                  paddingTop: '20px',
                }}
              >
                사업자 등록증을 업로드해주세요. <br />
                (보안상의 문제로 업로드가 되지 않을 경우, help@senlab.co.kr 로
                파일을 보내주세요.){' '}
              </p>
            </TitleLaout>
            <p style={{ margin: '12px auto 0', fontSize: '12px' }}>
              *10MB 이하 파일만 업로드 가능합니다.
            </p>
            <FileUploadLayout>
              <Upload
                name="file"
                fileList={
                  editingApplyment.businessRegistration
                    ? [
                        {
                          uid: editingApplyment.businessRegistration.id,
                          name: editingApplyment.businessRegistration.name,
                          status: 'done',
                          url: editingApplyment.businessRegistration.url,
                        },
                      ]
                    : []
                }
                maxCount={1}
                beforeUpload={handleUpload}
                onRemove={(file) => {
                  deleteCompanyFile({
                    variables: {
                      userId: user.id,
                      fileType: FileType.businessRegistration,
                      fileId: file.uid,
                    },
                  });
                }}
              >
                {editingApplyment.businessRegistration === undefined ||
                  (editingApplyment.businessRegistration === null && (
                    <UploadButton>
                      <UploadOutlined />
                      사업자등록증 업로드
                    </UploadButton>
                  ))}
              </Upload>
            </FileUploadLayout>
            <ButtonLayout>
              <BottomRightButton
                onClick={() => setStep(2)}
                disabled={
                  editingApplyment.email === '' ||
                  editingApplyment.businessRegistration === null ||
                  editingApplyment.businessRegistration === undefined
                }
                type="primary"
              >
                다음
              </BottomRightButton>
            </ButtonLayout>
          </>
        );
      case 2:
        return (
          <>
            <TitleLaout>
              <IssuesCloseOutlined
                style={{ fontSize: '14px', color: '#fe706f' }}
              />
              <p
                style={{
                  width: '648px',
                  textAlign: 'left',
                }}
              >
                서비스 이용동의
              </p>
            </TitleLaout>
            <TextLayout>
              {getMatchingServiceAgreementData?.getMatchingServiceAgreement}
            </TextLayout>
            <StyledCheckbox
              checked={editingApplyment?.serviceAgreement}
              onChange={(e) =>
                setEditingApplyment({
                  ...editingApplyment,
                  serviceAgreement: e.target.checked,
                })
              }
            >
              서비스 이용동의를 읽고, 이에 동의합니다.
            </StyledCheckbox>
            <div style={{ height: '56px' }} />
            <ButtonLayout>
              <BottomLeftButton onClick={() => setStep(1)} type="primary">
                이전
              </BottomLeftButton>
              <BottomRightButton
                onClick={() => setStep(3)}
                disabled={editingApplyment.serviceAgreement === false}
                type="primary"
              >
                다음
              </BottomRightButton>
            </ButtonLayout>
          </>
        );
      case 3:
        return (
          <>
            <TitleLaout>
              <IssuesCloseOutlined
                style={{ fontSize: '14px', color: '#fe706f' }}
              />
              <p
                style={{
                  width: '648px',
                  textAlign: 'left',
                }}
              >
                유료서비스 이용안내
              </p>
            </TitleLaout>
            <TextLayout>
              {getPayServiceAgreementData?.getPayServiceAgreement}
            </TextLayout>
            <StyledCheckbox
              checked={editingApplyment?.payServiceAgreement}
              onChange={(e) =>
                setEditingApplyment({
                  ...editingApplyment,
                  payServiceAgreement: e.target.checked,
                })
              }
            >
              유료서비스 이용안내를 읽고, 이에 동의합니다.
            </StyledCheckbox>
            <div style={{ height: '56px' }} />
            <ButtonLayout>
              <BottomLeftButton onClick={() => setStep(1)} type="primary">
                이전
              </BottomLeftButton>
              <BottomRightButton
                onClick={() => {
                  applyMatching({
                    variables: {
                      matchingApplicationInput: {
                        companyId: user.id,
                        taxBillEmail: editingApplyment.email,
                        registrationFileId:
                          editingApplyment.businessRegistration?.id,
                        serviceAgreement: editingApplyment.serviceAgreement,
                        payServiceAgreement:
                          editingApplyment.payServiceAgreement,
                      },
                    },
                  });
                }}
                disabled={editingApplyment.payServiceAgreement === false}
                type="primary"
              >
                완료
              </BottomRightButton>
            </ButtonLayout>
          </>
        );
      default:
        return <></>;
    }
  };
  return (
    <EditingBodyLayout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>
          Step {step}. {ApplyMatchingStep[step]}
        </Title>
      </TitleLayout>
      <StepLayout>
        <FinishStepLaout>
          <CheckOutlined style={{ color: '#aaaaaa', fontSize: '12px' }} />
        </FinishStepLaout>
        <FilledStepLayout width={convertStepToWidth({ step })}>
          <div>
            <p>
              {step === 3 ? (
                <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
              ) : (
                step
              )}
            </p>
          </div>
        </FilledStepLayout>
      </StepLayout>
      {renderComponent()}
    </EditingBodyLayout>
  );
};
const ApplyMatching = () => {
  const [step, setStep] = useState(0);
  const [applyMatching, { data: applyMatchingData }] =
    useMutation(APPLY_MATCHING);
  const [editingApplyment, setEditingApplyment] = useState({
    email: '',
    businessRegistration: null,
    serviceAgreement: false,
    payServiceAgreement: false,
  });
  const { user } = useAuth();

  const [
    latestMatchingApplicationByCompany,
    { data: latestMatchingApplicationByCompanyData },
  ] = useLazyQuery(LATEST_MATCHING_APPLICATION_BY_COMPANY);
  useEffect(() => {
    latestMatchingApplicationByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user.id, latestMatchingApplicationByCompany]);
  useEffect(() => {
    if (applyMatchingData) {
      latestMatchingApplicationByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [applyMatchingData, user.id]);

  return (
    <Layout>
      <TitleText>매칭 신청</TitleText>
      <GuideText>
        <div />
        서면으로 계약서 작성이 필요한 기업회원은 아래의 계약서 서식을 참고하여
        날인 후 (*등기우편) 하단의 주소로 보내주시기 바랍니다.
      </GuideText>
      <GuideText>
        <div />
        보내실 주소 : 서울특별시 강남구 논현로 98길 28, 3층 302호 주식회사 센랩
        (우 06140)
      </GuideText>
      <div style={{ height: '24px' }} />
      <Button
        type="primary"
        style={{
          width: '159px',
          fontWeight: 500,
          backgroundColor: '#FF7875',
          borderRadius: '10px',
          marginLeft: '24px',
          marginBottom: '27px',
        }}
        onClick={() => {
          window.open(
            'https://fairon.s3.ap-northeast-2.amazonaws.com/%E1%84%8B%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A2%E1%84%8E%E1%85%AE%E1%84%8E%E1%85%A5%E1%86%AB%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3+%E1%84%80%E1%85%A9%E1%86%BC%E1%84%80%E1%85%B3%E1%86%B8%E1%84%80%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8%E1%84%89%E1%85%A5+SENLab(210701).docx',
            '_blank',
          );
        }}
      >
        계약서 다운로드
      </Button>
      {latestMatchingApplicationByCompanyData !== undefined &&
      latestMatchingApplicationByCompanyData?.latestMatchingApplicationByCompany !==
        null &&
      latestMatchingApplicationByCompanyData?.latestMatchingApplicationByCompany
        ?.state !== 'DECLINED' ? (
        <FinishedLayout>
          <p>
            {latestMatchingApplicationByCompanyData
              ?.latestMatchingApplicationByCompany?.state === 'PENDING' ? (
              <>
                매칭 서비스 신청이 완료되었습니다!
                <br />
                관리자의 승인을 기다려주세요.
              </>
            ) : (
              <>
                매칭 서비스 승인이 완료되었습니다!
                <br />
                채용 공고를 게재하고, 인재를 찾아보세요!
              </>
            )}
          </p>
          <img
            src={
              latestMatchingApplicationByCompanyData
                ?.latestMatchingApplicationByCompany?.state === 'PENDING'
                ? PendingIllust
                : ApprovedIllust
            }
            alt="finished"
          />
        </FinishedLayout>
      ) : (
        <>
          {step === 0 ? (
            <AnnounceLayout>
              <img src={BannerIllust} alt="banner" />
              <AnnounceTitleLayout>
                <Icon icon={'edit'} size="21px" color="#ff434e" />
                <p>매칭 서비스를 신청해주세요!</p>
              </AnnounceTitleLayout>
              <AnnounceBodyText>
                매칭 서비스를 신청하여
                <br />
                인재를 만나볼 기회를 넓혀보세요!
              </AnnounceBodyText>
              <AnnounceBottomButton onClick={() => setStep(1)}>
                <p>인재 만나기</p>
                <Icon icon={'edit'} size="21px" color="#ffffff" />
              </AnnounceBottomButton>
            </AnnounceLayout>
          ) : (
            <ProfileEditingLayout>
              <ConvertStepToComponent
                applyMatching={applyMatching}
                editingApplyment={editingApplyment}
                setEditingApplyment={setEditingApplyment}
                step={step}
                setStep={setStep}
              />
            </ProfileEditingLayout>
          )}
        </>
      )}
    </Layout>
  );
};

export default ApplyMatching;
