import { BookOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { APPROVE_COUNSEL_RESERVATION } from '../../../queries/counsel';

const { TextArea } = Input;
const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 37px 0 23px;
  overflow-y: scroll;
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const ContentLayout = styled.div`
  display: flex;
  width: fit-content;
  margin: 16px auto 20px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 544px;
  min-height: 129px !important;
  border-radius: 10px;
  margin: 0 auto;
`;
const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 8px 0 auto;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto 0 8px;
`;

const ApproveModal = ({ id, onClose, boothName }) => {
  const [memo, setMemo] = useState('');
  const [approveCounselReservation, { data }] = useMutation(
    APPROVE_COUNSEL_RESERVATION,
  );
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);
  useEffect(() => {
    setMemo(`[부스명:${boothName}] 부스 상담 예약이 승인 되었습니다.
1. 원활한 상담을 위해서는 프로필 작성이 필요하니 아직 작성하지 못하신분은 상담전에 작성해 주시기 바랍니다.
2. 상담시작 10분전에 알림 메시지가 발송되니 상담시간 준수 부탁드립니다.
박람회관련문의 : 센랩 02-569-8015 www.fairon.co.kr`);
  }, [boothName]);
  return (
    <Layout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>상담 승인</Title>
      </TitleLayout>
      <ContentLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>학생에게 남길 메세지를 적어주세요.</p>
      </ContentLayout>
      <StyledTextarea
        placeholder="내용을 입력해주세요"
        value={memo}
        onChange={(e) => setMemo(e.currentTarget.value)}
      />
      <p style={{ marginLeft: '64px', marginTop: '8px', fontSize: '12px' }}>
        * 학생에게 아래와 같이 문자가 전송됩니다. 수정사항 필요 시 내용 을 수정
        바랍니다.
      </p>
      <ButtonLayout>
        <BottomLeftButton onClick={onClose}>취소</BottomLeftButton>
        <BottomCenterButton
          type="primary"
          onClick={() =>
            approveCounselReservation({
              variables: {
                id: id,
                message: memo,
              },
            })
          }
        >
          확인
        </BottomCenterButton>
      </ButtonLayout>
    </Layout>
  );
};

export default ApproveModal;
