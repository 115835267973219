import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, DatePicker, Input } from 'antd';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const BodyLayout = styled.div`
  width: ${isMobile ? '' : '512px'};
  height: ${isMobile ? '' : '258px'};
  padding: ${isMobile ? '24px' : '34px 60px 55px 55px'};
  margin: 0 auto;
  background-color: ${isMobile ? '' : '#fbfbfb'};
  border-radius: 5px; ;
`;

const InputFlexLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  margin-bottom: 33px;
`;

const InputTitle = styled.p`
  width: 100px;
  margin-right: 40px;
  margin-bottom: ${isMobile ? '8px' : ''};
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
`;

const CheckboxLayout = styled.div`
  margin-top: 7px;
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
`;

const CheckboxBodyLayout = styled.div`
  width: 247px;
  padding: 0 18px;
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: ${isMobile ? '40px' : 'auto'};
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  width: 247px;
`;

const ExpectedSalaryInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  width: 247px;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const RecruitmentInfo = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
  setIsShowingMobileCompleteForm,
}) => {
  const [recruitmentInfo, setRecruitmentInfo] = useState(null);
  useEffect(() => {
    if (profile?.recruitmentInfo) {
      setRecruitmentInfo(profile?.recruitmentInfo);
    } else {
      setRecruitmentInfo({
        willJoinAt: null,
        expectedSalary: null,
        emailContact: true,
        phoneContact: true,
      });
    }
  }, [profile]);

  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  const handleClickNextButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.complete,
          recruitmentInfo: {
            willJoinAt: recruitmentInfo.willJoinAt,
            expectedSalary:
              recruitmentInfo.expectedSalary === ''
                ? null
                : Number(recruitmentInfo.expectedSalary),
            emailContact: recruitmentInfo.emailContact,
            phoneContact: recruitmentInfo.phoneContact,
          },
        },
      },
    });
  };
  const handleClickPreviousButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.introduction,
          recruitmentInfo: {
            willJoinAt: recruitmentInfo.willJoinAt,
            expectedSalary: recruitmentInfo.expectedSalary,
            emailContact: recruitmentInfo.emailContact,
            phoneContact: recruitmentInfo.phoneContact,
          },
        },
      },
    });
  };
  useEffect(() => {
    if (
      isMobile &&
      data?.updateStudentProfile?.step === StudentProfileStep.complete
    ) {
      setIsShowingMobileCompleteForm?.(true);
    }
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal, setIsShowingMobileCompleteForm]);
  return (
    <Layout>
      <BodyLayout>
        {recruitmentInfo !== null && (
          <>
            <InputFlexLayout>
              <InputTitle>입사 예정 시기</InputTitle>
              <StyledDatePicker
                placeholder={'입사 예정 시기'}
                value={
                  recruitmentInfo?.willJoinAt !== null
                    ? moment(recruitmentInfo?.willJoinAt)
                    : undefined
                }
                onChange={(e) => {
                  setRecruitmentInfo({
                    willJoinAt: e ? moment(e).format('YYYY-MM-DD') : null,
                    expectedSalary: recruitmentInfo.expectedSalary,
                    emailContact: recruitmentInfo.emailContact,
                    phoneContact: recruitmentInfo.phoneContact,
                  });
                }}
              />
            </InputFlexLayout>
            <InputFlexLayout>
              <InputTitle>희망 연봉</InputTitle>
              <ExpectedSalaryInput
                value={recruitmentInfo.expectedSalary}
                placeholder={'희망 연봉'}
                suffix="만원"
                type="number"
                onChange={(e) =>
                  setRecruitmentInfo({
                    willJoinAt: recruitmentInfo.willJoinAt,
                    expectedSalary: e.currentTarget.value,
                    emailContact: recruitmentInfo.emailContact,
                    phoneContact: recruitmentInfo.phoneContact,
                  })
                }
              />
            </InputFlexLayout>
            <CheckboxLayout>
              <InputTitle>선호 연락 방법</InputTitle>
              <CheckboxBodyLayout>
                <Checkbox
                  checked={recruitmentInfo.emailContact}
                  onChange={(e) =>
                    setRecruitmentInfo({
                      willJoinAt: recruitmentInfo.willJoinAt,
                      expectedSalary: recruitmentInfo.expectedSalary,
                      emailContact: e.target.checked,
                      phoneContact: recruitmentInfo.phoneContact,
                    })
                  }
                >
                  이메일
                </Checkbox>
                <Spacer />
                <Checkbox
                  checked={recruitmentInfo.phoneContact}
                  onChange={(e) =>
                    setRecruitmentInfo({
                      willJoinAt: recruitmentInfo.willJoinAt,
                      expectedSalary: recruitmentInfo.expectedSalary,
                      emailContact: recruitmentInfo.emailContact,
                      phoneContact: e.target.checked,
                    })
                  }
                >
                  핸드폰
                </Checkbox>
              </CheckboxBodyLayout>
            </CheckboxLayout>
          </>
        )}
      </BodyLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            type="primary"
            onClick={handleClickNextButton}
            disabled={recruitmentInfo === null}
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton
              type="primary"
              onClick={handleClickPreviousButton}
            >
              이전
            </BottomLeftButton>
            <BottomRightButton
              type="primary"
              onClick={handleClickNextButton}
              disabled={recruitmentInfo === null}
            >
              완료
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default RecruitmentInfo;
