import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($userId: ID) {
    notifications(userId: $userId) {
      notificationType
      message
      createdAt
    }
  }
`;

export const GET_NOTIFICATION_STATE = gql`
  query GetNotificationState($userId: ID) {
    notificationState(userId: $userId) {
      read
    }
  }
`;

export const SUBSCRIBE_NOTIFICATION_STATE = gql`
  subscription GetNotificationState($userId: ID) {
    notificationStateChanged(userId: $userId) {
      read
    }
  }
`;

export const SUBSCRIBE_NOTIFICATION_ADDED = gql`
  subscription SubscribeNotificationAdded($userId: ID) {
    notificationAdded(userId: $userId) {
      notificationType
      message
      createdAt
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation ReadNotification($userId: ID) {
    readNotification(userId: $userId) {
      read
    }
  }
`;
