import { MessageOutlined, UserOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import useAuth from '../../hooks/useAuth';
import {
  COMPLETED_JOB_VACANCIES_BY_COMPANY,
  JOB_VACANCIES_BY_COMPANY,
} from '../../queries/jobVacancy';
import {
  CANCEL_SAVE_STUDENT,
  GET_SAVED_STUDENTS_BY_COMPANY,
  SUGGEST_INTERVIEW,
} from '../../queries/matching';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';
import StudentComponent from '../Matching/StudentComponent';

const Layout = styled.div`
  margin-top: 27px;
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(327px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '20px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 40px;
  margin-top: ${isMobileOnly ? '' : '24px'};
`;

const Spacer = styled.div`
  flex: 1;
`;

const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 0;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;

const MatchingModalLayout = styled.div`
  width: 892px;
  height: 598px;
`;

const MatchingModalBody = styled.div`
  height: 506px;
  position: absolute;
  padding: 41px 142px 34px;
  top: 92px;
  z-index: 0;
  overflow: scroll;
`;

const Label = styled.div`
  width: 110px;
  margin-right: 32px;
  margin-top: 4px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  span {
    color: #fe706f;
  }
`;

const StyledSelect = styled(Select)`
  width: 468px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(Input.TextArea)`
  width: 468px;
  min-height: 99px !important;
  border-radius: 10px;
`;

const StyledInput = styled(Input)`
  width: 320px;
  border-radius: 10px;
`;

const MiniInput = styled(Input)`
  width: 109px;
  border-radius: 10px;
`;

const StyledText = styled.p`
  margin: 6px 10px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const MiniTextarea = styled(Input.TextArea)`
  width: 468px;
  min-height: 51px !important;
  border-radius: 10px;
`;

const StudentsTab = () => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedMatchingStudentId, setSelectedMatchingStudentId] = useState(0);
  const [getJobVacancies, { data: getJobVacanciesData }] = useLazyQuery(
    COMPLETED_JOB_VACANCIES_BY_COMPANY,
  );
  const { user } = useAuth();
  const [suggestInterview] = useMutation(SUGGEST_INTERVIEW, {
    onCompleted: (data) => {
      setSelectedMatchingStudentId(0);
      getSavedStudentsByCompany({
        variables: {
          companyId: user.id,
        },
      });
    },
  });
  const [getSavedStudentsByCompany, { data: getSavedStudentsByCompanyData }] =
    useLazyQuery(GET_SAVED_STUDENTS_BY_COMPANY);
  const [cancelSaveStudent] = useMutation(CANCEL_SAVE_STUDENT, {
    onCompleted: (data) => {
      setCancelingStudentId(0);
      setSelectedStudent(null);
      getSavedStudentsByCompany({
        variables: {
          companyId: user.id,
        },
      });
    },
  });
  useEffect(() => {
    getSavedStudentsByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user.id, getSavedStudentsByCompany]);

  useEffect(() => {
    if (user.id) {
      getJobVacancies({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [user.id, getJobVacancies]);
  const [matchingInformation, setMatchingInformation] = useState({
    jobVacancyId: null,
    message: '',
    rank: '',
    minSalary: '',
    maxSalary: '',
    stockOption: '',
  });
  const [matchingErrorModalOpened, setMatchingErrorModalOpened] =
    useState(false);
  const history = useHistory();
  const [cancelingStudentId, setCancelingStudentId] = useState(0);
  return (
    <Layout>
      {getSavedStudentsByCompanyData?.savedStudentsByCompany?.map((item) => (
        <StudentComponent
          key={item.id}
          name={item.basicStudentProfile?.name}
          saved={item.saved}
          introduction={item.briefIntroduction}
          education={`${
            item.educations?.[
              item.educations?.length > 0 ? item.educations?.length - 1 : 0
            ]?.university ?? ''
          } ${
            item.educations?.[
              item.educations?.length > 0 ? item.educations?.length - 1 : 0
            ]?.major ?? ''
          } ${
            item.educations?.[
              item.educations?.length > 0 ? item.educations?.length - 1 : 0
            ]?.degree ?? ''
          }`}
          date={item.recruitmentInfo?.willJoinAt}
          skills={item.skills
            ?.map((skill) => skill.skillDetail)
            .concat(item.temporarySkills?.map((skill) => skill.skillDetail))}
          types={item.recruitmentTypes}
          onClick={() =>
            setSelectedStudent({
              id: item.id,
              suggested: item.suggested,
            })
          }
          onClickSaveButton={() => setCancelingStudentId(item.id)}
        />
      ))}
      <Modal
        isOpen={selectedStudent !== null}
        onClose={() => setSelectedStudent(null)}
        closeButtonTransparent
      >
        <ModalLayout>
          <ModalHeader>
            <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>프로필</ModalHeaderTitle>
            <Spacer />
            {selectedStudent?.suggested ? (
              <p
                style={{
                  padding: '0 16px',
                  color: '#fe706f',
                  fontSize: '14px',
                }}
              >
                면접 제안 완료
              </p>
            ) : (
              <Button
                type="primary"
                style={{ borderRadius: '10px', marginRight: '16px' }}
                onClick={() => {
                  if (
                    getJobVacanciesData?.completedJobVacanciesByCompany?.filter(
                      (item) => item.onMatching,
                    )?.length === 0
                  ) {
                    setMatchingErrorModalOpened(true);
                  } else {
                    setMatchingInformation({
                      jobVacancyId: null,
                      message: '',
                      rank: '',
                      minSalary: '',
                      maxSalary: '',
                      stockOption: '',
                    });
                    setSelectedMatchingStudentId(selectedStudent?.id);
                    setTimeout(() => setSelectedStudent(null), [100]);
                  }
                }}
              >
                면접 제안하기
              </Button>
            )}

            <div width="20px" />
            <Button
              style={{
                borderRadius: '10px',
                marginRight: '16px',
                backgroundColor: '#ffccc7',
                display: 'flex',
                alignItems: 'center',
                color: '#fe706f',
                border: 'none',
              }}
              onClick={() => setCancelingStudentId(selectedStudent?.id)}
            >
              <Icon size="12px" icon={'bookmarkFilled'} />
              <div style={{ width: '8px' }} />
              저장 완료
            </Button>
          </ModalHeader>
          <ModalBody>
            <StudentProfileModal id={selectedStudent?.id} hideInformation />
          </ModalBody>
        </ModalLayout>
      </Modal>
      <Modal
        isOpen={cancelingStudentId > 0}
        onClose={() => setCancelingStudentId(0)}
        radius="30px"
      >
        <SmallModalLayout
          title="저장 취소"
          description={
            <>
              저장을 취소하시겠습니까?
              <br />
              저장 인재목록에서 사라집니다.
            </>
          }
          setDescriptionCenter={true}
          onClickLeftButton={() => setCancelingStudentId(0)}
          onClickRightButton={() =>
            cancelSaveStudent({
              variables: {
                companyId: user.id,
                studentId: cancelingStudentId,
              },
            })
          }
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={selectedMatchingStudentId > 0}
        onClose={() => setSelectedMatchingStudentId(0)}
        closeButtonTransparent
      >
        <MatchingModalLayout>
          <ModalHeader>
            <MessageOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
            <ModalHeaderTitle>면접 제안하기</ModalHeaderTitle>
          </ModalHeader>
          <MatchingModalBody>
            <div style={{ display: 'flex', marginBottom: '25px' }}>
              <Label>
                제안 포지션<span>*</span>
              </Label>
              <StyledSelect
                value={matchingInformation.jobVacancyId}
                placeholder="채용 공고를 선택해주세요."
                onChange={(value) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    jobVacancyId: value,
                  }))
                }
              >
                {getJobVacanciesData?.completedJobVacanciesByCompany
                  ?.filter((item) => item.onMatching)
                  ?.map((item) => (
                    <Select.Option value={item.id}>
                      {item.position}
                    </Select.Option>
                  ))}
              </StyledSelect>
            </div>
            <div style={{ display: 'flex', marginBottom: '25px' }}>
              <Label>
                제안 메시지<span>*</span>
              </Label>
              <StyledTextarea
                placeholder="제안 메시지를 입력해주세요."
                value={matchingInformation.message}
                onChange={(e) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    message: e.currentTarget.value,
                  }))
                }
              />
            </div>
            <div style={{ display: 'flex', marginBottom: '25px' }}>
              <Label>직급</Label>
              <StyledInput
                placeholder="직급을 입력해주세요."
                value={matchingInformation.rank}
                onChange={(e) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    rank: e.target.value,
                  }))
                }
              />
            </div>
            <div style={{ display: 'flex', marginBottom: '25px' }}>
              <Label>연봉</Label>
              <MiniInput
                min={'0'}
                placeholder="최소"
                value={matchingInformation.minSalary}
                type="number"
                onChange={(e) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    minSalary: parseInt(e.target.value) ?? null,
                  }))
                }
              />
              <StyledText>~</StyledText>
              <MiniInput
                min={'0'}
                placeholder="최대"
                value={matchingInformation.maxSalary}
                type="number"
                onChange={(e) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    maxSalary: parseInt(e.target.value) ?? null,
                  }))
                }
              />
              <StyledText>만원</StyledText>
            </div>
            <div style={{ display: 'flex', marginBottom: '25px' }}>
              <Label>스톡옵션 여부</Label>
              <MiniTextarea
                placeholder="스톡옵션 여부를 입력해주세요."
                value={matchingInformation.stockOption}
                onChange={(e) =>
                  setMatchingInformation((prev) => ({
                    ...prev,
                    stockOption: e.currentTarget.value,
                  }))
                }
              />
            </div>
            <Button
              type="primary"
              style={{
                marginLeft: '248px',
                marginTop: '17px',
                width: '113px',
                height: '50px',
                borderRadius: '10px',
                fontWeight: 500,
              }}
              onClick={() => {
                suggestInterview({
                  variables: {
                    studentId: selectedMatchingStudentId,
                    jobVacancyId: matchingInformation.jobVacancyId,
                    interviewSuggestionInput: {
                      message: matchingInformation.message,
                      rank: matchingInformation.rank,
                      minSalary:
                        matchingInformation.minSalary === ''
                          ? null
                          : parseInt(matchingInformation.minSalary === ''),
                      maxSalary:
                        matchingInformation.maxSalary === ''
                          ? null
                          : parseInt(matchingInformation.maxSalary === ''),
                      stockOption: matchingInformation.stockOption,
                    },
                  },
                });
              }}
              disabled={
                matchingInformation.message === '' ||
                matchingInformation.jobVacancyId === null
              }
            >
              면접 제안하기
            </Button>
          </MatchingModalBody>
        </MatchingModalLayout>
      </Modal>
      <Modal
        radius="30px"
        isOpen={matchingErrorModalOpened}
        onClose={() => setMatchingErrorModalOpened(false)}
      >
        <SmallModalLayout
          title="면접 제안 실패"
          description={'매칭 관리에서 채용공고를 먼저 게시해주세요.'}
          setDescriptionCenter={true}
          removeLeftButton={true}
          onClickRightButton={() =>
            history.push('/company-meeting/post-job-vacancy')
          }
          height="305px"
        />
      </Modal>
    </Layout>
  );
};

export default StudentsTab;
