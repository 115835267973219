import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ADD_LAB_INFO,
  ADD_TEMPORARY_LAB_INFO,
  DELETE_TEMPORARY_LAB_INFO,
  SEARCH_LAB_INFOS,
  UPDATE_STUDENT_PROFILE,
} from '../../../../queries/student';
import { useEffect } from 'react';
import { Input, Button, Radio, Select } from 'antd';
import { StudentProfileStep } from '../../../../constants/static';
import { CloseOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import Popover from '../../../../components/Popover';
import useAuth from '../../../../hooks/useAuth';
import { GET_APPROVED_UNIVERSITIES } from '../../../../queries/university';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const ManualInputLayout = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 10px 14px 10px 8px;
  height: 40px;
  border: 1px solid #d9d9d9;
  width: 458px;
  border-radius: 10px;
  background: #ffffff;
  p {
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const LabTextLayout = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 14px 10px 8px;
  width: 458px;
  margin: auto;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background: #fcfcfc;
  p {
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const MajorInput = styled(Input)`
  border-radius: 10px;
  width: ${isMobile ? '' : '244px'};
  font-size: 14px;
  height: 40px;
  margin-right: ${isMobile ? '' : '12px'};
  margin-top: ${isMobile ? '16px' : ''};
  margin-bottom: 8px;
`;

const SearchInputLayout = styled.div`
  display: flex;
  margin: auto;
  border-radius: 10px;
  width: ${isMobile ? '' : '458px'};
  height: 40px;
  overflow: hidden;
  input {
    border-radius: 10px 0 0 10px;
    width: ${isMobile ? '' : '419px'};
    font-size: 14px;
  }
  button {
    border-radius: 0 10px 10px 0;
    height: 40px;
  }
`;

const SearchPopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow-y: scroll;
  padding: 8px 16px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border: 1px solid #f1f1f5;
  p {
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 0;
  }
`;

const DisabledText = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0;
  color: #8c8c8c;
`;

const StyledSelect = styled(Select)`
  border-radius: 10px;
  width: 244px;
  margin-bottom: 8px;
  height: 40px;
  font-size: 14px;
  input {
    margin-top: 4px !important;
  }
  .ant-select-selector {
    height: 40px !important;
  }
  div {
    height: 40px;
    border-radius: 10px !important;
  }
`;
const LabInfo = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [labInfo, setLabInfo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [manual, setManual] = useState(false);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  const [deleteTemporaryLabInfo] = useMutation(DELETE_TEMPORARY_LAB_INFO);
  const [addTemporaryLabInfoWithLeftButton] = useMutation(
    ADD_TEMPORARY_LAB_INFO,
    {
      onCompleted: (data) => {
        updateStudentProfile({
          variables: {
            id: id,
            studentProfileInput: {
              step: StudentProfileStep.educations,
              temporaryLabInfoId: data.addStudentTemporaryLabInfo,
              labInfoId: 0,
            },
          },
        });
      },
    },
  );
  const [addTemporaryLabInfoWithRightButton] = useMutation(
    ADD_TEMPORARY_LAB_INFO,
    {
      onCompleted: (data) => {
        updateStudentProfile({
          variables: {
            id: id,
            studentProfileInput: {
              step: isModal
                ? StudentProfileStep.complete
                : StudentProfileStep.skills,
              temporaryLabInfoId: data.addStudentTemporaryLabInfo,
              labInfoId: 0,
            },
          },
        });
      },
    },
  );

  const [temporaryLabInfo, setTemporaryLabInfo] = useState(null);
  useEffect(() => {
    if (profile?.labInfo) {
      setLabInfo(profile?.labInfo);
    }
    if (profile?.temporaryLabInfo) {
      setTemporaryLabInfo({
        ...profile?.temporaryLabInfo,
        approvedUniversityId: profile?.temporaryLabInfo?.approvedUniversity?.id,
      });
      setManual(true);
    }
  }, [profile]);

  const { user } = useAuth();

  const handleClickLeftButton = () => {
    if (temporaryLabInfo && temporaryLabInfo?.id !== null) {
      deleteTemporaryLabInfo({
        variables: {
          studentId: user?.id,
          temporaryLabInfoId: temporaryLabInfo?.id,
        },
      });
    }
    if (manual) {
      if (
        !Boolean(temporaryLabInfo?.labNameKR) &&
        !Boolean(temporaryLabInfo?.labNameEN) &&
        !Boolean(temporaryLabInfo?.major) &&
        !Boolean(temporaryLabInfo?.approvedUniversityId) &&
        !Boolean(temporaryLabInfo?.professorName) &&
        !Boolean(temporaryLabInfo?.website)
      ) {
        updateStudentProfile({
          variables: {
            id: id,
            studentProfileInput: {
              labInfoId: labInfo?.id ?? 0,
              temporaryLabInfoId: 0,
              step: isModal
                ? StudentProfileStep.complete
                : StudentProfileStep.recruitmentTypes,
            },
          },
        });
      } else {
        addTemporaryLabInfoWithLeftButton({
          variables: {
            studentId: user.id,
            labInfoInput: {
              approvedUniversityId: temporaryLabInfo.approvedUniversityId,
              major: temporaryLabInfo.major,
              labNameKR: temporaryLabInfo.labNameKR,
              labNameEN: temporaryLabInfo.labNameEN,
              professorName: temporaryLabInfo.professorName,
              website: temporaryLabInfo.website,
            },
          },
        });
      }
    } else {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            labInfoId: labInfo?.id ?? 0,
            step: StudentProfileStep.educations,
          },
        },
      });
    }
  };

  const handleClickRightButton = () => {
    if (manual) {
      if (temporaryLabInfo?.id !== null) {
        deleteTemporaryLabInfo({
          variables: {
            studentId: user.id,
            temporaryLabInfoId: temporaryLabInfo.id,
          },
        });
      }
      if (
        !Boolean(temporaryLabInfo?.labNameKR) &&
        !Boolean(temporaryLabInfo?.labNameEN) &&
        !Boolean(temporaryLabInfo?.major) &&
        !Boolean(temporaryLabInfo?.approvedUniversityId) &&
        !Boolean(temporaryLabInfo?.professorName) &&
        !Boolean(temporaryLabInfo?.website)
      ) {
        updateStudentProfile({
          variables: {
            id: id,
            studentProfileInput: {
              labInfoId: labInfo?.id ?? 0,
              temporaryLabInfoId: 0,
              step: isModal
                ? StudentProfileStep.complete
                : StudentProfileStep.recruitmentTypes,
            },
          },
        });
      } else {
        addTemporaryLabInfoWithRightButton({
          variables: {
            studentId: user.id,
            labInfoInput: {
              approvedUniversityId: temporaryLabInfo.approvedUniversityId,
              major: temporaryLabInfo.major,
              labNameKR: temporaryLabInfo.labNameKR,
              labNameEN: temporaryLabInfo.labNameEN,
              professorName: temporaryLabInfo.professorName,
              website: temporaryLabInfo.website,
            },
          },
        });
      }
    } else {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            labInfoId: labInfo?.id ?? 0,
            step: isModal
              ? StudentProfileStep.complete
              : StudentProfileStep.recruitmentTypes,
          },
        },
      });
    }
  };
  const { data: getApprovedUniversitiesData } = useQuery(
    GET_APPROVED_UNIVERSITIES,
  );
  const [searchPopoverOpened, setSearchPopoverOpened] = useState(false);
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  const [searchLabInfos, { data: searchLabInfosData }] =
    useLazyQuery(SEARCH_LAB_INFOS);
  const buttonRef = useRef(null);

  return (
    <Layout>
      {manual ? (
        <div style={{ margin: 'auto' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div style={{ flex: 1 }} />
            검색해서 찾기
            <button
              style={{ outline: 'none', border: 'none' }}
              onClick={() => {
                setManual(false);
                setSearchValue('');
                setSearchPopoverOpened(false);
              }}
            >
              <SyncOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
            </button>
          </div>
          <StyledSelect
            size="large"
            placeholder="학교명"
            value={temporaryLabInfo.approvedUniversityId}
            onChange={(value) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                approvedUniversityId: value,
              }))
            }
          >
            {getApprovedUniversitiesData?.approvedUniversities?.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </StyledSelect>
          <br />
          <MajorInput
            placeholder="학과"
            value={temporaryLabInfo.major ?? ''}
            onChange={(e) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                major: e.target.value,
              }))
            }
          />
          <br />
          <MajorInput
            placeholder="랩명 (국문)"
            value={temporaryLabInfo.labNameKR ?? ''}
            onChange={(e) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                labNameKR: e.target.value,
              }))
            }
          />
          <br />
          <MajorInput
            placeholder="랩명 (영문)"
            value={temporaryLabInfo.labNameEN ?? ''}
            onChange={(e) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                labNameEN: e.target.value,
              }))
            }
          />
          <br />
          <MajorInput
            placeholder="교수명"
            value={temporaryLabInfo.professorName ?? ''}
            onChange={(e) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                professorName: e.target.value,
              }))
            }
          />
          <br />
          <MajorInput
            placeholder="홈페이지"
            value={temporaryLabInfo.website ?? ''}
            onChange={(e) =>
              setTemporaryLabInfo((prev) => ({
                ...prev,
                website: e.target.value,
              }))
            }
          />
        </div>
      ) : (
        <>
          {temporaryLabInfo === '' || labInfo === null ? (
            <SearchInputLayout>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchLabInfos({
                      variables: {
                        studentId: user.id,
                        keyword: searchValue,
                      },
                    });
                    setSearchPopoverOpened(true);
                  }
                }}
                placeholder="연구실명 혹은 지도교수명을 검색해주세요."
              />
              <Button
                ref={buttonRef}
                disabled={searchValue === ''}
                type="primary"
                onClick={() => {
                  searchLabInfos({
                    variables: {
                      keyword: searchValue,
                    },
                  });
                  setSearchPopoverOpened(true);
                }}
              >
                <SearchOutlined />
              </Button>
              <Popover
                open={searchPopoverOpened}
                width="458px"
                height="154px"
                onClose={() => setSearchPopoverOpened(false)}
                anchorRef={buttonRef}
                horizontalAlign="right"
                verticalAlign="bottom"
                removePadding
                borderRadius="0 0 6px 6px"
              >
                <SearchPopoverLayout>
                  {searchLabInfosData?.searchLabInfos?.length === 0 && (
                    <DisabledText>결과 없음</DisabledText>
                  )}
                  {searchLabInfosData?.searchLabInfos?.map((item) => (
                    <p
                      onClick={() => {
                        setLabInfo(item);
                        setSearchValue('');
                        setSearchPopoverOpened(false);
                      }}
                    >
                      {`${item.approvedUniversity?.name ?? ''} ${
                        item.major ?? ''
                      } ${item.labNameKR ?? ''}${
                        item.labNameEN
                          ? `${item.labNameEN ? `(${item.labNameEN})` : ''}`
                          : ''
                      } | ${item.professorName ?? ''}`}
                    </p>
                  ))}
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      color: '#fe706f',
                      padding: 0,
                      fontSize: 12,
                    }}
                    onClick={() => {
                      setTemporaryLabInfo({
                        id: null,
                        labNameKR: searchValue,
                      });
                      setManual(true);
                    }}
                  >
                    직접 입력
                  </button>
                </SearchPopoverLayout>
              </Popover>
            </SearchInputLayout>
          ) : (
            <LabTextLayout>
              <p>
                {`${labInfo.approvedUniversity?.name ?? ''} ${
                  labInfo.major ?? ''
                } ${labInfo.labNameKR ?? ''}${
                  labInfo.labNameEN ? `(${labInfo.labNameEN})` : ''
                } | ${labInfo.professorName ?? ''}`}
              </p>
              <button
                onClick={() => {
                  setLabInfo(null);
                }}
              >
                <CloseOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
              </button>
            </LabTextLayout>
          )}
        </>
      )}
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default LabInfo;
