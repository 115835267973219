import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { userPrimary } from '../../../components/Icon/svg';
import { UserType } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  width: ${isMobile ? '344px' : '368px'};
  height: 314px;
  background-color: white;
  background: #ffffff;
  box-shadow: 0px 12px 23px rgba(13, 3, 3, 0.04);
  border-radius: 30px;
  margin: ${isMobile ? '0 auto 8px' : ''};
  display: flex;
  flex-flow: column;
  cursor: pointer;
`;

const ImageLayout = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 10px;
  position: relative;
  background-color: #fbfbfb;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 72px;
    max-width: 72px;
  }
  margin-right: 17px;
`;

const TitleLayout = styled.div`
  padding: 25px 24px 14px;
  height: 111px;
  display: flex;
  align-items: center;
  width: 100%;
  p {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 2.8em;
    line-height: 1.4em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    line-height: 21px;
    font-size: 15px;
    color: #000000;
    width: 230px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  margin: 17px 0;
`;

const BoothNameText = styled.p`
  margin: 0 32px;
  height: 64px;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.2em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 300;
`;

const HiringText = styled.p`
  margin: 0 28px;
  height: 24px;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 12px;
  color: #fe706f;
`;

const DateLayout = styled.div`
  margin: 2px 28px 0;
  height: 32px;
  display: flex;
  align-items: center;
`;

const DateText = styled.p`
  height: 22px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  background-color: #f2f2f2;
  margin: 0 4px 0 0;
  color: #111111;
`;

const DateGuideText = styled.p`
  font-size: 12px;
  color: #111111;
  margin-left: 4px;
`;

const BottomLayout = styled.div`
  display: flex;
  margin-top: 4px;
  padding: 0 24px;
  align-items: center;
`;

const Badge = styled.div`
  height: 22px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  color: white;
  font-size: 12px;
  background-color: ${(props) => props.backgroundColor};
  margin: 0 4px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ConsultingLayout = styled.div`
  height: 32px;
  padding: 8px 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #f2f2f2;
  p {
    margin-left: 4px;
    color: #977dff;
  }
`;

const BoothItem = ({
  fairId,
  id,
  itemId,
  logo,
  companyNameKR,
  boothName,
  hirings,
  managers,
  consultingTotalCount,
  consultingAppliedCount,
  onClick,
  dates,
}) => {
  const history = useHistory();
  const { user } = useAuth();
  return (
    <Layout
      id={itemId}
      onClick={() => {
        onClick();
        history.push(`/fair/${fairId}/booth/${id}`);
      }}
    >
      <TitleLayout>
        <ImageLayout>{logo && <img src={logo} alt="logo" />}</ImageLayout>
        <p>{companyNameKR}</p>
      </TitleLayout>
      <BoothNameText>{boothName}</BoothNameText>
      <Divider />
      <HiringText>
        {hirings.length === 0
          ? '현재 진행 중인 채용이 없습니다'
          : `${hirings[0]}${
              hirings.length > 1 ? ` 외 ${hirings.length - 1}건` : ''
            } 채용 중`}
      </HiringText>
      <DateLayout>
        {dates.slice(0, 3).map((date) => (
          <DateText>{date}</DateText>
        ))}
        <DateGuideText>
          {dates.length > 3 && `외 ${dates.length - 3}`}
        </DateGuideText>
      </DateLayout>
      <BottomLayout>
        {managers.findIndex((item) => item === '현업담당자') > -1 && (
          <Badge backgroundColor="#ffa171">현업담당자</Badge>
        )}
        {managers.findIndex((item) => item === '인사담당자') > -1 && (
          <Badge backgroundColor="#f88988">인사담당자</Badge>
        )}
        <Spacer />
        {user.userType === UserType.student && (
          <ConsultingLayout>
            상담신청
            <p>
              {consultingAppliedCount} / {consultingTotalCount}
            </p>
          </ConsultingLayout>
        )}
      </BottomLayout>
    </Layout>
  );
};

export default BoothItem;
