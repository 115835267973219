import { gql } from '@apollo/client';

// Mutations
// TODO: 수정 예정

export const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String!, $userType: UserType!) {
    signIn(email: $email, password: $password, userType: $userType) {
      token
    }
  }
`;

export const SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail(
    $email: String!
    $emailType: EmailType
    $userType: UserType!
  ) {
    sendVerificationEmail(
      email: $email
      emailType: $emailType
      userType: $userType
    )
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($code: String, $email: String, $emailType: EmailType) {
    verify(code: $code, email: $email, emailType: $emailType)
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($email: String!, $password: String!, $userType: UserType!) {
    signUp(email: $email, password: $password, userType: $userType)
  }
`;

export const REFRESH_ACCESS_TOKEN = gql`
  mutation RefreshAccessToken {
    refreshAccessToken {
      token
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $userId: ID
    $password: String
    $newPassword: String
  ) {
    changePassword(
      userId: $userId
      password: $password
      newPassword: $newPassword
    )
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String
    $newPassword: String
    $userType: UserType
  ) {
    resetPassword(email: $email, newPassword: $newPassword, userType: $userType)
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($userId: ID) {
    deleteAccount(userId: $userId)
  }
`;
