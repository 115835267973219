import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import ApplicationsTab from './ApplicationsTab';
import JobVacanciesTab from './JobVacanciesTab';
import SuggestionsTab from './SuggestionsTab';

const Layout = styled.div`
  padding: ${isMobile ? '50px 0 24px' : '50px 50px 24px 61px'};
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: ${isMobile ? '16px' : '24px'};
  margin-left: ${isMobile ? '30px' : ''};
`;

const BodyLayout = styled.div`
  margin: ${isMobile ? '24px 0 0' : '29px 60px 0'};
`;

const FlexLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? 'center' : ''};
  margin: ${isMobile ? '0 6px' : ''};
`;

const Tab = styled.div`
  padding: 16px 0;
  font-family: Noto Sans KR;
  font-size: ${isMobile ? '14px' : '15px'};
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  color: ${(props) => (props.selected ? '#FE706F' : '#262626')};
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: ${(props) =>
    props.selected ? '2px solid #fe706f' : '2px solid transparent'};
  margin-right: ${isMobile ? '16px' : '32px'};
  margin-left: ${isMobile ? '16px' : ''};
  cursor: pointer;
`;

const ManageMatching = () => {
  const [tab, setTab] = useState('applications');
  // suggestions, applications, jobVacancies
  return (
    <Layout>
      <TitleText>매칭 관리</TitleText>
      <BodyLayout>
        <FlexLayout>
          <Tab
            selected={tab === 'applications'}
            onClick={() => setTab('applications')}
          >
            면접 지원 관리
          </Tab>
          <Tab
            selected={tab === 'suggestions'}
            onClick={() => setTab('suggestions')}
          >
            면접 제안 관리
          </Tab>
          <Tab
            selected={tab === 'jobVacancies'}
            onClick={() => setTab('jobVacancies')}
          >
            저장 채용공고
          </Tab>
        </FlexLayout>
      </BodyLayout>
      {tab === 'suggestions' && <SuggestionsTab />}
      {tab === 'applications' && <ApplicationsTab />}
      {tab === 'jobVacancies' && <JobVacanciesTab />}
    </Layout>
  );
};

export default ManageMatching;
