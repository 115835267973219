import { CheckOutlined } from '@ant-design/icons';
import { convertDateToState } from '../../constants/utils';
import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import 'moment/locale/ko';
import FairListItem from './components/FairListItem';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_FAIR, GET_FAIRS, GET_FAIR_APPLICATIONS } from '../../queries/fair';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { UserType } from '../../constants/static';
import { FairContext } from '.';
import {
  GET_EXHIBITED_BOOTHS_BY_FAIR,
  SEARCH_EXHIBITED_BOOTHS_BY_FAIR,
} from '../../queries/booth';

const Layout = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

const ToggleLayout = styled.div`
  position: sticky;
  margin: ${isMobile ? '20px 5px 0' : '35px 102px 0'};
  height: 73px;
  display: flex;
  justify-content: ${isMobile ? 'center' : ''};
  padding: ${isMobile ? '24px 12px 24px 0' : '25px 54px'};
  border-radius: 10px;
  background-color: white;
`;

const ListLayout = styled.div`
  padding: ${isMobile ? '35px 0 0' : '35px 0 0'};
  flex: 1;
  overflow-y: scroll;
  background-color: white;
  margin: ${isMobile ? '0 12px 0' : '0 102px 0'};
`;

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(368px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '40px 25px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 40px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ExpectedToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
  font-weight: 500;
  font-size: 12px;
  height: 26px;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`;

const ApplicationPeriodToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background: #fff0f6;
  border: 1px solid #ffadd2;
  color: #eb2f96;
  font-weight: 500;
  font-size: 12px;
  height: 26px;
  align-items: center;
  svg {
    margin-left: 4px;
  }
`;

const ProceedingToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background: #fff2e8;
  border: 1px solid #ffbb96;
  color: #fa541c;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  height: 26px;
  svg {
    margin-left: 4px;
  }
`;

const EndToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: 12px;
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #979797;
  font-weight: 500;
  font-size: 12px;
  align-items: center;
  height: 26px;
  svg {
    margin-left: 4px;
  }
`;

export const FairBoothContext = createContext({});

const FairList = () => {
  moment.locale('ko');
  const {
    fairs,
    toggleFairFilters,
    setToggleFairFilters,
    setSavedScrollItem,
    savedScrollItem,
    setSavedBoothScrollItem,
  } = useContext(FairContext);
  useEffect(() => {
    setSavedBoothScrollItem('');
    document.getElementById(savedScrollItem)?.scrollIntoView(false);
  }, [savedScrollItem, setSavedBoothScrollItem]);
  const { user } = useAuth();
  const handleClickToggleButton = (item) => {
    if (!toggleFairFilters.has(item)) {
      setToggleFairFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFairFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };

  return (
    <Layout>
      <ToggleLayout>
        {!isMobile && <Spacer />}
        <ExpectedToggleButton
          onClick={() => handleClickToggleButton('expected')}
        >
          예정
          {toggleFairFilters.has('expected') && <CheckOutlined />}
        </ExpectedToggleButton>
        <ApplicationPeriodToggleButton
          onClick={() => handleClickToggleButton('applicationPeriod')}
        >
          신청가능
          {toggleFairFilters.has('applicationPeriod') && <CheckOutlined />}
        </ApplicationPeriodToggleButton>
        <ProceedingToggleButton
          onClick={() => handleClickToggleButton('proceeding')}
        >
          진행중
          {toggleFairFilters.has('proceeding') && <CheckOutlined />}
        </ProceedingToggleButton>
        <EndToggleButton onClick={() => handleClickToggleButton('end')}>
          종료
          {toggleFairFilters.has('end') && <CheckOutlined />}
        </EndToggleButton>
      </ToggleLayout>
      <ListLayout>
        <ListBodyLayout>
          {fairs?.fairs
            ?.filter((item) => {
              const now = moment().startOf('day');
              if (toggleFairFilters.has('proceeding')) {
                if (moment(item.startAt) <= now && now <= moment(item.endAt)) {
                  return true;
                }
              }
              if (toggleFairFilters.has('applicationPeriod')) {
                if (user.userType === UserType.student) {
                  if (
                    moment(item.studentApplicationStartAt) <= now &&
                    now <= moment(item.studentApplicationEndAt)
                  ) {
                    return true;
                  }
                } else {
                  if (
                    moment(item.companyApplicationStartAt) <= now &&
                    now <= moment(item.companyApplicationEndAt)
                  ) {
                    return true;
                  }
                }
              }
              if (toggleFairFilters.has('expected')) {
                if (moment(item.startAt) > now) {
                  return true;
                }
              }
              if (toggleFairFilters.has('end') && moment(item.endAt) < now) {
                return true;
              }
              return false;
            })
            .map((item, index) => (
              <FairListItem
                key={index}
                id={item.id}
                itemId={`fair-${item.id}`}
                onClick={() => setSavedScrollItem(`fair-${item.id}`)}
                image={item.image}
                state={convertDateToState(
                  user.userType,
                  item.startAt,
                  item.endAt,
                  item.companyApplicationStartAt,
                  item.companyApplicationEndAt,
                  item.studentApplicationStartAt,
                  item.studentApplicationEndAt,
                )}
                title={item.title}
                isOnline={item.isOnline}
                progressEndAt={item.endAt}
                progressStartAt={item.startAt}
              />
            ))}
        </ListBodyLayout>
      </ListLayout>
    </Layout>
  );
};

export default FairList;
