import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input, Select } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';

const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  position: absolute;
  top: 88px;
  right: 56px;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const ItemLayout = styled.div`
  position: relative;
  padding: 5px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const FlexLayout = styled.div`
  display: flex;
  margin: 15px 0;
  padding: 5px 80px 5px 116px;
  margin-right: 36px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  width: 218px;
`;

const TitleInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  flex: 1;
`;

const JournalTitleInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
`;

const AuthorInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
`;

const VolInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  margin-right: 10px;
  height: 40px;
  flex: 1;
`;

const IFInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  flex: 1;
`;

const StyledSelect = styled(Select)`
  margin-right: 6px;
  div {
    height: 40px !important;
    border-radius: 10px !important;
    padding-top: 4px !important;
    input {
      margin-top: 4px !important;
    }
  }
  width: 138px;
  border-radius: 10px;
  height: 40px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const JournalClassificationInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  width: 138px !important;
`;

const AuthorClassificationInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  width: 138px !important;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Thesis = ({ thesis, updateThesis, deleteThesis, isDeletable }) => {
  return (
    <ItemLayout isDeletable={isDeletable}>
      <FlexLayout>
        <JournalClassificationInput
          value={thesis.journalClassification}
          placeholder="저널 구분"
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: e.currentTarget.value,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            })
          }
        />
        <TitleInput
          value={thesis.title}
          placeholder="제목"
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: e.currentTarget.value,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            })
          }
        />
      </FlexLayout>
      <FlexLayout>
        <JournalTitleInput
          value={thesis.journal}
          placeholder="저널명"
          onChange={(e) =>
            updateThesis({
              journal: e.currentTarget.value,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            })
          }
        />
        <AuthorInput
          value={thesis.author}
          placeholder="저자명"
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: e.currentTarget.value,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            })
          }
        />
      </FlexLayout>
      <FlexLayout>
        <AuthorClassificationInput
          value={thesis.authorClassification}
          placeholder="저자 구분"
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: e.currentTarget.value,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            })
          }
        />
        <StyledDatePicker
          placeholder={'게제년월'}
          picker="month"
          value={
            thesis.publishedAt !== '' ? moment(thesis.publishedAt) : undefined
          }
          onChange={(e) => {
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: e ? moment(e).format('YYYY-MM') : '',
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            });
          }}
        />
        <VolInput
          type={'number'}
          value={thesis.vol}
          placeholder="Vol."
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: e.currentTarget.value,
              impactFactor: thesis.impactFactor,
            })
          }
        />
        <IFInput
          type={'number'}
          value={thesis.impactFactor}
          placeholder="IF"
          onChange={(e) =>
            updateThesis({
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: e.currentTarget.value,
            })
          }
        />
      </FlexLayout>
      <DeleteRowButton disabled={!isDeletable} onClick={deleteThesis}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </ItemLayout>
  );
};

const Theses = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [theses, setTheses] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.theses?.length > 0) {
      profile?.theses?.forEach((thesis) => {
        setTheses((previousTheses) => {
          return [
            ...previousTheses,
            {
              journal: thesis.journal,
              journalClassification: thesis.journalClassification,
              authorClassification: thesis.authorClassification,
              title: thesis.title,
              author: thesis.author,
              publishedAt: thesis.publishedAt,
              vol: thesis.vol,
              impactFactor: thesis.impactFactor,
            },
          ];
        });
      });
    } else {
      setTheses([
        {
          journal: '',
          journalClassification: '',
          authorClassification: '',
          title: '',
          author: '',
          publishedAt: '',
          vol: null,
          impactFactor: null,
        },
      ]);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.projects,
          theses: theses,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.patents,
          theses: theses,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <BodyLayout>
        {theses.map((thesis, index) => (
          <>
            <Thesis
              key={index}
              isDeletable={theses.length > 1}
              deleteThesis={() =>
                setTheses((previousTheses) =>
                  previousTheses.filter((_, prevIndex) => index !== prevIndex),
                )
              }
              thesis={thesis}
              updateThesis={(newThesis) =>
                setTheses((previousTheses) => {
                  return previousTheses.map((previousThesis, previousIndex) =>
                    previousIndex === index ? newThesis : previousThesis,
                  );
                })
              }
            />
            {index + 1 !== theses.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setTheses((prevTheses) => {
            return [
              ...prevTheses,
              {
                journal: '',
                title: '',
                journalClassification: '',
                authorClassification: '',
                author: '',
                publishedAt: '',
                vol: null,
                impactFactor: null,
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Theses;
