import { gql } from '@apollo/client';

export const GET_APPROVED_ADVERTISEMENTS = gql`
  query GetApprovedAdvertisements(
    $first: Int
    $after: ID
    $companySize: [String]
    $domainIds: [ID]
    $recruitmentTypes: [String]
    $order: AdvertisementOrder
  ) {
    approvedAdvertisements(
      first: $first
      after: $after
      companySize: $companySize
      domainIds: $domainIds
      recruitmentTypes: $recruitmentTypes
      order: $order
    ) {
      id
      company {
        id
        basicCompanyProfile {
          companyNameKR
        }
        onMatching
        logo {
          url
        }
      }
      youtubeLink
      image {
        url
      }
      title
      applied
      met
    }
  }
`;

export const GET_RANDOM_REP_ADVERTISEMENT = gql`
  query GetRandomRepAdvertisement {
    randomRepAdvertisement {
      id
      company {
        id
        basicCompanyProfile {
          companyNameKR
        }
        onMatching
        logo {
          url
        }
      }
      youtubeLink
      image {
        url
      }
      title
      applied
      met
    }
  }
`;

export const GET_ADVERTISEMENT = gql`
  query GetAdvertisement($id: ID) {
    advertisement(id: $id) {
      id
      company {
        id
        basicCompanyProfile {
          companyNameKR
        }
        logo {
          url
        }
        onMatching
      }
      youtubeLink
      image {
        url
      }
      title
      companySize
      domains {
        id
        name
      }
      recruitmentTypes
      content
      applied
      met
    }
  }
`;
export const GET_ADVERTISEMENT_DOMAINS = gql`
  query GetAdvertisementDomains {
    advertisementDomains {
      id
      name
    }
  }
`;

export const CREATE_ADVERTISEMENT = gql`
  mutation CreateAdvertisement(
    $companyId: ID
    $advertisementInput: AdvertisementInput
  ) {
    createAdvertisement(
      companyId: $companyId
      advertisementInput: $advertisementInput
    ) {
      id
    }
  }
`;

export const UPDATE_ADVERTISEMENT = gql`
  mutation UpdateAdvertisement(
    $id: ID!
    $advertisementInput: AdvertisementInput
  ) {
    updateAdvertisement(id: $id, advertisementInput: $advertisementInput) {
      id
    }
  }
`;

export const MEET_ADVERTISEMENT = gql`
  mutation MeetAdvertisement($studentId: ID, $advertisementId: ID) {
    meetAdvertisement(studentId: $studentId, advertisementId: $advertisementId)
  }
`;

export const APPLY_ADVERTISEMENT = gql`
  mutation ApplyAdvertisement($studentId: ID, $advertisementId: ID) {
    applyAdvertisement(studentId: $studentId, advertisementId: $advertisementId)
  }
`;

export const INQUIRE_ADVERTISEMENT = gql`
  mutation InquireAdvertisement(
    $companyId: ID
    $email: String
    $phoneNumber: String
    $content: String
  ) {
    inquireAdvertisement(
      companyId: $companyId
      email: $email
      phoneNumber: $phoneNumber
      content: $content
    )
  }
`;
