import React from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, Route, Switch } from 'react-router-dom';
import MobileDisabled from '../MobileDisabled';
import DisplayBooth from './DisplayBooth';
import ManageBooth from './ManageBooth';
import ManageCounsel from './ManageCounsel/ManageCounsel';

const BoothAndCounsel = () => {
  return (
    <Switch>
      <Route exact path="/booth-and-counsel/manage-booth">
        {isMobile ? <MobileDisabled /> : <ManageBooth />}
      </Route>
      <Route exact path="/booth-and-counsel/display-booth">
        {isMobile ? <MobileDisabled /> : <DisplayBooth />}
      </Route>
      <Route exact path="/booth-and-counsel/manage-counsel">
        {isMobile ? <MobileDisabled /> : <ManageCounsel />}
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default BoothAndCounsel;
