import { IssuesCloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Layout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '464px'};
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 34px 0 22px;
  overflow: scroll;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fe706f;
`;

const FlexLayout = styled.div`
  display: flex;
  flex-flow: ${isMobile ? 'column' : ''};
  padding: ${isMobile ? '0 24px' : ''};
  margin: ${(props) => (props.center ? 'auto' : '32px auto auto')};
`;

const Text = styled.p`
  margin-left: 8px;
  margin-top: ${isMobile ? '8px' : '-2px'};
  width: ${(props) => (props.center ? '' : isMobile ? '220px' : '270px')};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: ${isMobile ? 'center' : 'left'};
  white-space: pre-wrap;
`;

const ButtonLayout = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`;

const BottomLeftButton = styled(Button)`
  width: 96px;
  height: 50px;
  border: 1px solid #fe706f;
  color: #fe706f;
  font-size: 15px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  width: 96px;
  height: 50px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 15px;
`;

const SmallModalLayout = ({
  title,
  description,
  onClickRightButton,
  onClickLeftButton,
  removeLeftButton = false,
  setDescriptionCenter = false,
  removeIcon = false,
  height = '268px',
}) => {
  return (
    <Layout height={height}>
      <Title>{title}</Title>
      {setDescriptionCenter && !isMobile && <div style={{ height: '24px' }} />}
      <FlexLayout center={setDescriptionCenter}>
        {!removeIcon && (
          <IssuesCloseOutlined style={{ fontSize: '17px', color: '#fe706f' }} />
        )}
        <Text
          style={{ textAlign: removeIcon ? 'center' : 'left' }}
          center={setDescriptionCenter}
        >
          {description}
        </Text>
      </FlexLayout>
      {!setDescriptionCenter && <div style={{ minHeight: '24px' }} />}
      <ButtonLayout>
        {!removeLeftButton && (
          <>
            <BottomLeftButton onClick={onClickLeftButton}>
              취소
            </BottomLeftButton>
            <div style={{ width: '8px' }} />
          </>
        )}

        <BottomRightButton type="primary" onClick={onClickRightButton}>
          확인
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default SmallModalLayout;
