import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import {
  DELETE_COMPANY_FILE,
  UPDATE_COMPANY_PROFILE,
  UPLOAD_COMPANY_FILE,
} from '../../../../queries/company';
import { Upload, Button } from 'antd';
import { CompanyProfileStep, FileType } from '../../../../constants/static';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { getBase64 } from '../../../../constants/utils';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const UploadLayout = styled.div`
  margin: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: 32px;
  color: #3a3a3a;
`;

const Logo = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [updateCompanyProfile, { data }] = useMutation(UPDATE_COMPANY_PROFILE);

  const handleClickLeftButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: CompanyProfileStep.basicCompanyProfile,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: isModal
            ? CompanyProfileStep.complete
            : CompanyProfileStep.images,
        },
      },
    });
  };
  const [uploadCompanyFile, { data: uploadCompanyFileData }] =
    useMutation(UPLOAD_COMPANY_FILE);
  const [deleteCompanyFile, { data: deleteCompanyFileData }] =
    useMutation(DELETE_COMPANY_FILE);
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: '24px' }} />
      <div style={{ marginTop: 8 }}>업로드</div>
    </div>
  );

  const handleUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      alert('JPG/PNG 파일만 업로드 가능합니다.');
    } else if (file.size / 1024 / 1024 >= 10) {
      alert('10MB보다 작은 파일만 업로드 가능합니다.');
    } else {
      uploadCompanyFile({
        variables: {
          userId: id,
          fileType: FileType.corporateIdentity,
          file: file,
        },
      });
    }
    return false;
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  useEffect(() => {
    if (deleteCompanyFileData !== undefined) {
      refetchProfile();
    }
  }, [deleteCompanyFileData]);
  useEffect(() => {
    if (uploadCompanyFileData !== undefined) {
      refetchProfile();
    }
  }, [uploadCompanyFileData]);
  return (
    <Layout>
      <p style={{ margin: '36px auto 0', fontSize: '12px' }}>
        *10MB 이하, png, jpg, jpeg만 업로드 가능합니다.
      </p>
      <UploadLayout>
        <Upload
          name="avatar"
          listType="picture-card"
          fileList={
            profile?.logo
              ? [
                  {
                    uid: profile?.logo?.id,
                    name: profile?.logo?.name,
                    status: 'done',
                    url: profile?.logo?.url,
                  },
                ]
              : []
          }
          showUploadList={{
            showPreviewIcon: false,
          }}
          beforeUpload={handleUpload}
          maxCount={1}
          onRemove={(file) => {
            deleteCompanyFile({
              variables: {
                userId: id,
                fileType: FileType.corporateIdentity,
                fileId: file.uid,
              },
            });
            return false;
          }}
        >
          {profile?.logo === null && uploadButton}
        </Upload>
        <StyledText>기업 로고</StyledText>
      </UploadLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            disabled={profile?.logo === null}
            onClick={handleClickRightButton}
            type="primary"
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={profile?.logo === null}
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Logo;
