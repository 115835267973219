import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { convertDateToState } from '../../constants/utils';
import useAuth from '../../hooks/useAuth';
import {
  CloseOutlined,
  FilterFilled,
  QuestionCircleOutlined,
  RetweetOutlined,
  SearchOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Input,
  Select,
  Switch,
  Popover,
  Tooltip,
} from 'antd';
import Modal from '../../components/Modal';
import EventModal from './components/EventModal';
import ParticipatedCompaniesModal from './components/ParticipatedCompaniesModal';
import BoothItem from './components/BoothItem';
import {
  CounselorType,
  FairApplicationsState,
  recruitmentTypeArray,
  UserType,
} from '../../constants/static';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { FairBoothContext } from './FairList';
import { FairContext } from '.';

const Layout = styled.div`
  margin: ${isMobile ? '0' : '40px 102px'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0 0 35px' : '0 18px 35px'};
  height: ${isMobile ? '' : 'calc(100vh - 220px)'};
  overflow-y: scroll;
  margin-bottom: 32px;
`;

const TitleLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  padding: ${isMobile ? '39px 30px 24px' : '35px 35px 0'};
  position: fixed;
  z-index: 800;
  width: ${isMobile ? '100%' : 'calc(100% - 222px)'};
  height: ${isMobile ? '' : '83px'};
  background-color: white;
  p {
    margin-right: auto;
    margin-left: 0;
    font-weight: 700;
    font-size: 18px;
    margin-right: 24px;
    margin-bottom: ${isMobile ? '13px' : ''};
  }
`;

const BadgeLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
`;

const OnlineBadge = styled.div`
  border-radius: 10px;
  background: ${(props) => (props.isOnline ? '#FFF7E6' : '#F9F0FF')};
  border: 1px solid ${(props) => (props.isOnline ? '#FFA171' : '#D3ADF7')};
  color: ${(props) => (props.isOnline ? '#FFA171' : '#722ED1')};
  font-weight: 500;
  font-size: 12px;
  margin-right: auto;
  margin-left: 0;
  padding: 0 8px;
  height: 22px;
  margin-right: 15px;
`;

const ExpectedTag = styled.div`
  border-radius: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  height: 22px;
`;

const ApplicationPeriodTag = styled.div`
  border-radius: 10px;
  background: #fff0f6;
  border: 1px solid #ffadd2;
  color: #eb2f96;
  font-weight: 500;
  font-size: 12px;
  width: fit-content;
  padding: 1px 8px;
  height: 22px;
`;

const ProceedingTag = styled.div`
  border-radius: 10px;
  background: #fff2e8;
  border: 1px solid #ffbb96;
  color: #fa541c;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  height: 22px;
`;

const EndTag = styled.div`
  border-radius: 10px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  color: #979797;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  width: fit-content;
  height: 22px;
`;

const ImageLayout = styled.div`
  width: 320px;
  height: 200px;
  border-radius: 10px;
  background-color: #fbfbfb;
  margin-right: 39px;
  position: relative;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 320px;
    max-height: 200px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const BodyLayout = styled.div`
  display: flex;
  align-items: center;
  padding: ${isMobile ? '100px 33px 0' : '96px 35px 0'};
`;

const ContentLayout = styled.div`
  flex: 1;
`;

const IntroductionText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 11px;
  padding: 0 26px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
  position: sticky;
  z-index: 1000;
  top: 83px;
`;

const TermLayout = styled.div`
  display: flex;
  align-items: center;
  padding: ${isMobile ? '' : '0 26px'};
  width: fit-content;
  margin-top: 14px;
  p {
    margin-right: 30px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const DateLayout = styled.div`
  display: flex;
  margin-right: 0;
  margin-left: auto;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #262626;
`;

const ButtonLayout = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
  margin-top: 24px;
  align-items: center;
  margin-right: ${isMobile ? '' : '18px'};
  margin-bottom: 25px;
  div {
    flex: 1;
  }
  button {
    border-radius: 10px;
    width: 176px;
    height: 53px;
    font-size: 14px;
    font-weight: 700;
    margin: ${isMobile ? '0 0 12px 0' : '0 16px'};
  }
`;

const FilterLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  padding: ${isMobile ? '24px 28px' : '0 10px'};
  background-color: white;
  position: ${isMobile ? 'relative' : 'sticky'};
  top: ${isMobile ? '' : '84px'};
  height: ${isMobile ? '' : '80px'};
  z-index: ${isMobile ? 1 : 10};
`;

const FilterButton = styled(Button)`
  width: 203px;
  height: 40px;
  display: flex;
  border: 1px solid #fe706f;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin-top: ${isMobile ? '12px' : ''};
`;

const PopoverDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin: 17px 0;
`;

const SearchInput = styled(Input)`
  border-radius: 10px;
  width: 320px;
  font-size: ${isMobile ? '16px' : '14px'};
`;

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(368px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '40px 25px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 40px;
  margin-top: ${isMobileOnly ? '' : '24px'};
  min-height: 320px;
`;

const PopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 0 19px;
  .ant-picker-panel {
    display: ${isMobile ? 'none' : ''};
    visibility: ${isMobile ? 'hidden' : ''};
  }
`;

const PopoverFlexLayout = styled.div`
  display: flex;
  padding: 0 15px 0 24px;
  align-items: center;

  color: #262626;
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  margin-right: 12px;
  color: #262626;
  min-width: 40px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const StyledSelect = styled(Select)`
  width: 222px;
  max-width: 222px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const renderState = (state) => {
  switch (state) {
    case 'expected':
      return <ExpectedTag>예정</ExpectedTag>;
    case 'applicationPeriod':
      return <ApplicationPeriodTag>신청기간</ApplicationPeriodTag>;
    case 'proceeding':
      return <ProceedingTag>진행중</ProceedingTag>;
    case 'end':
      return <EndTag>종료</EndTag>;
    default:
      return <ExpectedTag>예정</ExpectedTag>;
  }
};

const FairDetail = () => {
  const { fairId } = useParams();
  const {
    boothSearchValue,
    setBoothSearchValue,
    isBoothSearching,
    boothFilter,
    setSavedBoothScrollItem,
    savedBoothScrollItem,
    setBoothFilter,
    getBoothsByFair,
    getBoothsByFairData,
    searchBoothsByFairData,
    getFair,
    getFairData,
    getFairApplications,
    getFairApplicationsData,
  } = useContext(FairContext);
  useEffect(() => {
    console.log(document.getElementById(savedBoothScrollItem));
    document.getElementById(savedBoothScrollItem)?.scrollIntoView(false);
  }, [savedBoothScrollItem]);
  const { user } = useAuth();
  const [isEventModalOpened, setIsEventModalOpened] = useState(false);
  const [
    isParticipatedUniversitiesModalOpened,
    setIsParticipatedUniversitiesModalOpened,
  ] = useState(false);
  useEffect(() => {
    if (fairId) {
      getFair({
        variables: {
          id: fairId,
        },
      });
      getFairApplications({
        variables: {
          fairId: fairId,
          state: FairApplicationsState.approved,
        },
      });
    }
  }, [fairId, getFair, getFairApplications]);
  const companies = useMemo(() => {
    if (getFairApplicationsData) {
      let dateMap = {};
      getFairApplicationsData?.fairApplications?.forEach((item) => {
        if (item.company?.basicCompanyProfile?.companyNameKR) {
          item.orders?.forEach((order) =>
            order.applicationDates.forEach((date) => {
              const set = dateMap[date] ?? new Set([]);
              dateMap[date] = new Set([
                ...[...set],
                {
                  name: item.company?.basicCompanyProfile?.companyNameKR,
                  logo: item.company?.logo?.url,
                },
              ]);
            }),
          );
        }
      });
      return Object.entries(dateMap).sort((a, b) => (a[0] > b[0] ? 1 : -1));
    }
  }, [getFairApplicationsData]);
  useEffect(() => {
    if (user.userType !== UserType.student) {
      setBoothFilter({
        availableToConsult: false,
        mySkills: false,
        recruitmentTypes: [],
        manager: [],
        dates: [],
      });
    }
  }, [user]);
  useEffect(() => {
    if (fairId && !isBoothSearching) {
      getBoothsByFair({
        variables: {
          fairId: fairId,
          counselEnabled: boothFilter.availableToConsult,
          studentSkillFiltered: boothFilter.mySkills,
          recruitmentTypes: boothFilter.recruitmentTypes,
          counselorRoles: boothFilter.manager,
          dates: boothFilter.dates.length === 0 ? null : boothFilter.dates,
        },
      });
    }
  }, [fairId, getBoothsByFair, boothFilter, isBoothSearching]);
  const buttonRef = useRef(null);
  const [isFilterPopoverOpened, setIsFilterPopoverOpened] = useState(false);
  const [editingFilter, setEditingFilter] = useState({
    availableToConsult: true,
    mySkills: false,
    recruitmentTypes: [],
    manager: [],
    dates: [],
  });
  const history = useHistory();
  useEffect(() => {
    if (isFilterPopoverOpened) {
      setEditingFilter(boothFilter);
    }
  }, [boothFilter, isFilterPopoverOpened]);
  const buttonDisabled = useMemo(() => {
    const now = moment().startOf('day');
    return !(
      now.startOf('day').format('YYYY-MM-DD') ===
        getFairData?.fair?.companyApplicationStartAt ||
      now.startOf('day').format('YYYY-MM-DD') ===
        getFairData?.fair?.companyApplicationEndAt ||
      (now.isAfter(moment(getFairData?.fair?.companyApplicationStartAt)) &&
        moment(getFairData?.fair?.companyApplicationEndAt).isAfter(now))
    );
  }, [getFairData]);
  if (getFairData?.fair) {
    return (
      <Layout isFilterOpened={isFilterPopoverOpened}>
        <TitleLayout>
          <p>{getFairData?.fair?.title}</p>
          <BadgeLayout>
            <OnlineBadge isOnline={getFairData?.fair?.isOnline}>
              {getFairData?.fair?.isOnline ? '온라인' : '오프라인'}
            </OnlineBadge>
            {renderState(
              convertDateToState(
                user.userType,
                getFairData?.fair?.startAt,
                getFairData?.fair?.endAt,
                getFairData?.fair?.companyApplicationStartAt,
                getFairData?.fair?.companyApplicationEndAt,
                getFairData?.fair?.studentApplicationStartAt,
                getFairData?.fair?.studentApplicationEndAt,
              ),
            )}
          </BadgeLayout>
        </TitleLayout>
        <BodyLayout>
          {!isMobile && (
            <ImageLayout>
              {getFairData?.fair?.image && (
                <img src={getFairData?.fair?.image?.url} alt="thumbnail" />
              )}
            </ImageLayout>
          )}
          <ContentLayout>
            {!isMobile && (
              <IntroductionText>
                {getFairData?.fair?.introduction}
              </IntroductionText>
            )}

            {!isMobile && <Divider />}
            {isMobile && <div style={{ height: '40px' }} />}
            <TermLayout>
              <p>진행기간</p>
              <DateLayout>
                {getFairData?.fair?.startAt}
                <SwapRightOutlined
                  style={{
                    fontSize: '16px',
                    margin: '0 8px',
                    color: '#bfbfbf',
                  }}
                />
                {getFairData?.fair?.endAt}
              </DateLayout>
            </TermLayout>
            <TermLayout>
              <p>신청기간</p>
              <DateLayout>
                {user.userType === 'STUDENT'
                  ? getFairData?.fair?.studentApplicationStartAt
                  : getFairData?.fair?.companyApplicationStartAt}
                <SwapRightOutlined
                  style={{
                    fontSize: '16px',
                    margin: '0 8px',
                    color: '#bfbfbf',
                  }}
                />
                {user.userType === 'STUDENT'
                  ? getFairData?.fair?.studentApplicationEndAt
                  : getFairData?.fair?.companyApplicationEndAt}
              </DateLayout>
            </TermLayout>
          </ContentLayout>
        </BodyLayout>
        <ButtonLayout>
          {user.userType === UserType.student ? (
            <>
              <div />
              <Button
                type="primary"
                onClick={() => setIsParticipatedUniversitiesModalOpened(true)}
              >
                참가 기업 리스트
              </Button>
              <Button
                type="primary"
                onClick={() => setIsEventModalOpened(true)}
              >
                이벤트
              </Button>
              <Button
                onClick={() => {
                  window.open(getFairData?.fair?.surveyLink, '_blank');
                }}
                type="primary"
              >
                설문조사
              </Button>
            </>
          ) : user.userType === UserType.company && !isMobile ? (
            <>
              <div />
              <Button
                type="primary"
                onClick={() => history.push('/booth-and-counsel/display-booth')}
                disabled={buttonDisabled}
              >
                부스신청
              </Button>
            </>
          ) : null}
        </ButtonLayout>
        <Divider />
        <FilterLayout>
          {isMobile ? (
            <>
              <SearchInput
                size="large"
                placeholder="기업명 검색"
                value={boothSearchValue}
                onChange={(e) => setBoothSearchValue(e.currentTarget.value)}
                prefix={<SearchOutlined style={{ color: '#fe706f' }} />}
              />
              {user.userType === UserType.student && (
                <FilterButton
                  ref={buttonRef}
                  onClick={() => setIsFilterPopoverOpened(true)}
                >
                  <FilterFilled
                    style={{
                      fontSize: '16px',
                      color: '#fe706f',
                      marginRight: '10px',
                    }}
                  />
                  필터로 원하는 부스 찾기
                </FilterButton>
              )}
            </>
          ) : (
            <>
              {user.userType === UserType.student && (
                <>
                  <Popover
                    placement="bottomLeft"
                    visible={isFilterPopoverOpened}
                    onVisibleChange={(visible) =>
                      setIsFilterPopoverOpened(visible)
                    }
                    trigger={'click'}
                    content={
                      <PopoverLayout>
                        <PopoverFlexLayout>
                          <Spacer />
                          필터 초기화
                          <Button
                            type="link"
                            onClick={() => {
                              setEditingFilter({
                                availableToConsult: false,
                                mySkills: false,
                                recruitmentTypes: [],
                                manager: [],
                                dates: [],
                              });
                            }}
                          >
                            <RetweetOutlined style={{ fontSize: '24px' }} />
                          </Button>
                        </PopoverFlexLayout>
                        <PopoverDivider />
                        <PopoverFlexLayout>
                          <PopoverTitle>상담신청 가능</PopoverTitle>
                          <Switch
                            size="small"
                            checked={editingFilter.availableToConsult}
                            onChange={(checked) =>
                              setEditingFilter({
                                ...editingFilter,
                                availableToConsult: checked,
                              })
                            }
                          />
                        </PopoverFlexLayout>
                        <PopoverDivider />
                        <PopoverFlexLayout>
                          <PopoverTitle style={{ marginRight: '0px' }}>
                            내 연구분야스킬
                          </PopoverTitle>
                          <Tooltip
                            title="프로필 내 스킬을 요구하는 기업만 표시"
                            color="#fe706f"
                          >
                            <QuestionCircleOutlined
                              style={{
                                marginLeft: '2px',
                                marginRight: '12px',
                                fontSize: '14px',
                                color: '#fe706f',
                              }}
                            />
                          </Tooltip>
                          <Switch
                            size="small"
                            checked={editingFilter.mySkills}
                            onChange={(checked) =>
                              setEditingFilter({
                                ...editingFilter,
                                mySkills: checked,
                              })
                            }
                          />
                        </PopoverFlexLayout>
                        <PopoverDivider />
                        <PopoverFlexLayout>
                          <PopoverTitle>채용형태</PopoverTitle>
                          <StyledSelect
                            mode="multiple"
                            allowClear
                            placeholder="채용형태 선택"
                            value={editingFilter.recruitmentTypes}
                            onChange={(value) =>
                              setEditingFilter({
                                ...editingFilter,
                                recruitmentTypes: value,
                              })
                            }
                          >
                            {recruitmentTypeArray.map((item) => (
                              <Select.Option value={item}>{item}</Select.Option>
                            ))}
                          </StyledSelect>
                        </PopoverFlexLayout>
                        <PopoverDivider />
                        <PopoverFlexLayout>
                          <PopoverTitle>부스 담당자</PopoverTitle>
                          <Checkbox
                            checked={
                              editingFilter.manager.findIndex(
                                (item) => item === CounselorType.field,
                              ) > -1
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setEditingFilter({
                                  ...editingFilter,
                                  manager: editingFilter.manager.concat(
                                    CounselorType.field,
                                  ),
                                });
                              } else {
                                setEditingFilter({
                                  ...editingFilter,
                                  manager: editingFilter.manager.filter(
                                    (a) => a !== CounselorType.field,
                                  ),
                                });
                              }
                            }}
                          >
                            현업
                          </Checkbox>
                          <Checkbox
                            checked={
                              editingFilter.manager.findIndex(
                                (item) => item === CounselorType.hr,
                              ) > -1
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setEditingFilter({
                                  ...editingFilter,
                                  manager: editingFilter.manager.concat(
                                    CounselorType.hr,
                                  ),
                                });
                              } else {
                                setEditingFilter({
                                  ...editingFilter,
                                  manager: editingFilter.manager.filter(
                                    (a) => a !== CounselorType.hr,
                                  ),
                                });
                              }
                            }}
                          >
                            인사
                          </Checkbox>
                        </PopoverFlexLayout>
                        <PopoverDivider />
                        <PopoverFlexLayout>
                          <PopoverTitle>행사일</PopoverTitle>
                          <div style={{ width: '260px' }}>
                            {editingFilter.dates.length > 0 && (
                              <div
                                style={{
                                  display: 'flex-wrap',
                                  maxWidth: '260px',
                                  marginBottom: '8px',
                                }}
                              >
                                {editingFilter.dates.map((item) => (
                                  <span
                                    style={{
                                      width: 'fit-content',
                                      margin: '6px 4px',
                                      padding: '4px 8px',
                                      maxHeight: '22px',
                                      height: '22px',
                                      alignItems: 'center',
                                      backgroundColor: '#f5f5f5',
                                    }}
                                  >
                                    {item}
                                    <button
                                      style={{
                                        cursor: 'pointer',
                                        padding: 0,
                                        border: 'none',
                                        outline: 'none',
                                        marginLeft: '8px',
                                        marginTop: '-1px',
                                        backgroundColor: 'transparent',
                                      }}
                                      onClick={() =>
                                        setEditingFilter({
                                          ...boothFilter,
                                          dates: editingFilter.dates.filter(
                                            (a) => a !== item,
                                          ),
                                        })
                                      }
                                    >
                                      <CloseOutlined
                                        style={{
                                          fontSize: '12px',
                                          color: '#8c8c8c',
                                        }}
                                      />
                                    </button>
                                  </span>
                                ))}
                              </div>
                            )}
                            <DatePicker
                              value={null}
                              style={{ borderRadius: '10px' }}
                              inputReadOnly
                              format="YYYY-MM-DD"
                              onChange={(e) => {
                                console.log(
                                  editingFilter.dates.findIndex(
                                    (a) => a === moment(e).format('YYYY-MM-DD'),
                                  ),
                                );
                                setEditingFilter({
                                  ...editingFilter,
                                  dates:
                                    editingFilter.dates.findIndex(
                                      (a) =>
                                        a === moment(e).format('YYYY-MM-DD'),
                                    ) > -1
                                      ? editingFilter.dates
                                      : editingFilter.dates.concat(
                                          moment(e).format('YYYY-MM-DD'),
                                        ),
                                });
                              }}
                              placeholder="년월일"
                            />
                          </div>
                        </PopoverFlexLayout>
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#fe706f',
                            marginTop: '24px',
                            marginBottom: '8px',
                          }}
                        >
                          * 적용 버튼을 눌러야 변경사항이 적용됩니다.
                        </p>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsFilterPopoverOpened(false);
                            setBoothFilter(editingFilter);
                          }}
                          style={{
                            margin: '0 149px 0',
                            borderRadius: '10px',
                          }}
                        >
                          적용
                        </Button>
                      </PopoverLayout>
                    }
                  >
                    <FilterButton
                      ref={buttonRef}
                      onClick={() => setIsFilterPopoverOpened(true)}
                    >
                      <FilterFilled
                        style={{
                          fontSize: '16px',
                          color: '#fe706f',
                          marginRight: '10px',
                        }}
                      />
                      필터로 원하는 부스 찾기
                    </FilterButton>{' '}
                  </Popover>
                </>
              )}
              <Spacer />
              <SearchInput
                size="large"
                placeholder="기업명 검색"
                value={boothSearchValue}
                onChange={(e) => setBoothSearchValue(e.currentTarget.value)}
                prefix={<SearchOutlined style={{ color: '#fe706f' }} />}
              />
            </>
          )}

          <Popover
            open={isFilterPopoverOpened && !isMobile}
            onClose={() => setIsFilterPopoverOpened(false)}
            width={isMobile ? '335px' : '356px'}
            height={isMobile ? '220px' : '420px'}
            anchorRef={buttonRef}
            horizontalAlign={'left'}
            borderRadius="10px"
            removePadding
            verticalAlign={'bottom'}
          ></Popover>
        </FilterLayout>
        <ListBodyLayout>
          {isBoothSearching ? (
            <>
              {searchBoothsByFairData?.searchExhibitedBoothsByFair?.map(
                (item, index) => (
                  <BoothItem
                    id={item.id}
                    itemId={`booth-${item.id}`}
                    onClick={() => setSavedBoothScrollItem(`booth-${item.id}`)}
                    fairId={fairId}
                    key={index}
                    logo={item.booth?.company?.logo?.url}
                    companyNameKR={
                      item.booth?.company?.basicCompanyProfile?.companyNameKR
                    }
                    boothName={item.booth?.title}
                    hirings={
                      item.booth?.jobVacancies?.map((item) => item.position) ??
                      []
                    }
                    managers={item.booth?.counselorRoles.map((item) =>
                      item === 'FIELD' ? '현업담당자' : '인사담당자',
                    )}
                    consultingTotalCount={item.totalCounselCount}
                    consultingAppliedCount={item.approvedCounselCount}
                    dates={
                      item.dateAndCounselReservations?.map(
                        (reservation) => reservation.date,
                      ) ?? []
                    }
                  />
                ),
              )}
            </>
          ) : (
            <>
              {getBoothsByFairData?.exhibitedBoothsByFair?.map(
                (item, index) => (
                  <BoothItem
                    id={item.id}
                    fairId={fairId}
                    itemId={`booth-${item.id}`}
                    onClick={() => setSavedBoothScrollItem(`booth-${item.id}`)}
                    key={index}
                    logo={item.booth?.company?.logo?.url}
                    companyNameKR={
                      item.booth?.company?.basicCompanyProfile?.companyNameKR
                    }
                    boothName={item.booth?.title}
                    hirings={
                      item.booth?.jobVacancies?.map((item) => item.position) ??
                      []
                    }
                    managers={item.booth?.counselorRoles.map((item) =>
                      item === 'FIELD' ? '현업담당자' : '인사담당자',
                    )}
                    consultingTotalCount={item.totalCounselCount}
                    consultingAppliedCount={item.approvedCounselCount}
                    dates={
                      item.dateAndCounselReservations?.map(
                        (reservation) => reservation.date,
                      ) ?? []
                    }
                  />
                ),
              )}
            </>
          )}
        </ListBodyLayout>
        <Modal
          closeButtonTransparent
          radius={'30px'}
          width={isMobile ? '343px' : '969px'}
          height={isMobile ? '527px' : '730px'}
          isOpen={isEventModalOpened}
          onClose={() => setIsEventModalOpened(false)}
        >
          <EventModal event={getFairData?.fair?.event} />
        </Modal>
        <Modal
          closeButtonTransparent
          radius={'30px'}
          width={isMobile ? '343px' : '500px'}
          height={isMobile ? '500px' : '600px'}
          isOpen={isParticipatedUniversitiesModalOpened}
          onClose={() => setIsParticipatedUniversitiesModalOpened(false)}
        >
          <ParticipatedCompaniesModal companies={companies} />
        </Modal>
        <Modal
          isOpen={isFilterPopoverOpened && isMobile}
          onClose={() => setIsFilterPopoverOpened(false)}
        >
          <PopoverLayout>
            <div style={{ height: '40px' }} />
            <PopoverFlexLayout>
              <Spacer />
              필터 초기화
              <Button
                type="link"
                onClick={() => {
                  setEditingFilter({
                    availableToConsult: false,
                    mySkills: false,
                    recruitmentTypes: [],
                    manager: [],
                    dates: [],
                  });
                }}
              >
                <RetweetOutlined style={{ fontSize: '24px' }} />
              </Button>
            </PopoverFlexLayout>
            <PopoverDivider />
            <PopoverFlexLayout>
              <PopoverTitle>상담신청 가능</PopoverTitle>
              <Switch
                size="small"
                checked={editingFilter.availableToConsult}
                onChange={(checked) =>
                  setEditingFilter({
                    ...editingFilter,
                    availableToConsult: checked,
                  })
                }
              />
            </PopoverFlexLayout>
            <PopoverDivider />
            <PopoverFlexLayout>
              <PopoverTitle style={{ marginRight: 0 }}>
                내 연구분야스킬
              </PopoverTitle>
              <Tooltip
                title="프로필 내 스킬을 요구하는 기업만 표시"
                color="#fe706f"
              >
                <QuestionCircleOutlined
                  style={{
                    marginLeft: '2px',
                    marginRight: '12px',
                    color: '#fe706f',
                  }}
                />
              </Tooltip>
              <Switch
                size="small"
                checked={editingFilter.mySkills}
                onChange={(checked) =>
                  setEditingFilter({
                    ...editingFilter,
                    mySkills: checked,
                  })
                }
              />
            </PopoverFlexLayout>
            <PopoverDivider />
            <PopoverFlexLayout>
              <PopoverTitle>채용형태</PopoverTitle>
              <StyledSelect
                mode="multiple"
                allowClear
                placeholder="채용형태 선택"
                value={editingFilter.recruitmentTypes}
                onChange={(value) =>
                  setEditingFilter({
                    ...editingFilter,
                    recruitmentTypes: value,
                  })
                }
              >
                {recruitmentTypeArray.map((item) => (
                  <Select.Option value={item}>{item}</Select.Option>
                ))}
              </StyledSelect>
            </PopoverFlexLayout>
            <PopoverDivider />
            <PopoverFlexLayout>
              <PopoverTitle>부스 담당자</PopoverTitle>
              <Checkbox
                checked={
                  editingFilter.manager.findIndex(
                    (item) => item === CounselorType.field,
                  ) > -1
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setEditingFilter({
                      ...editingFilter,
                      manager: editingFilter.manager.concat(
                        CounselorType.field,
                      ),
                    });
                  } else {
                    setEditingFilter({
                      ...editingFilter,
                      manager: editingFilter.manager.filter(
                        (a) => a !== CounselorType.field,
                      ),
                    });
                  }
                }}
              >
                현업
              </Checkbox>
              <Checkbox
                checked={
                  editingFilter.manager.findIndex(
                    (item) => item === CounselorType.hr,
                  ) > -1
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setEditingFilter({
                      ...editingFilter,
                      manager: editingFilter.manager.concat(CounselorType.hr),
                    });
                  } else {
                    setEditingFilter({
                      ...editingFilter,
                      manager: editingFilter.manager.filter(
                        (a) => a !== CounselorType.hr,
                      ),
                    });
                  }
                }}
              >
                인사
              </Checkbox>
            </PopoverFlexLayout>
            <PopoverDivider />
            <PopoverFlexLayout>
              <PopoverTitle>행사일</PopoverTitle>
              <div style={{ width: '260px' }}>
                {editingFilter.dates.length > 0 && (
                  <div
                    style={{
                      display: 'flex-wrap',
                      maxWidth: '260px',
                      marginBottom: '8px',
                    }}
                  >
                    {editingFilter.dates.map((item) => (
                      <span
                        style={{
                          width: 'fit-content',
                          margin: '6px 4px',
                          padding: '4px 8px',
                          maxHeight: '22px',
                          height: '22px',
                          alignItems: 'center',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        {item}
                        <button
                          style={{
                            cursor: 'pointer',
                            padding: 0,
                            border: 'none',
                            outline: 'none',
                            marginLeft: '8px',
                            marginTop: '-1px',
                            backgroundColor: 'transparent',
                          }}
                          onClick={() =>
                            setEditingFilter({
                              ...boothFilter,
                              dates: editingFilter.dates.filter(
                                (a) => a !== item,
                              ),
                            })
                          }
                        >
                          <CloseOutlined
                            style={{ fontSize: '12px', color: '#8c8c8c' }}
                          />
                        </button>
                      </span>
                    ))}
                  </div>
                )}
                <DatePicker
                  value={null}
                  style={{ borderRadius: '10px' }}
                  format="YYYY-MM-DD"
                  onChange={(e) => {
                    console.log(
                      editingFilter.dates.findIndex(
                        (a) => a === moment(e).format('YYYY-MM-DD'),
                      ),
                    );
                    setEditingFilter({
                      ...editingFilter,
                      dates:
                        editingFilter.dates.findIndex(
                          (a) => a === moment(e).format('YYYY-MM-DD'),
                        ) > -1
                          ? editingFilter.dates
                          : editingFilter.dates.concat(
                              moment(e).format('YYYY-MM-DD'),
                            ),
                    });
                  }}
                  placeholder="년월일"
                />
              </div>
            </PopoverFlexLayout>
            <p
              style={{
                textAlign: 'center',
                fontSize: '12px',
                color: '#fe706f',
                marginTop: '24px',
                marginBottom: '8px',
              }}
            >
              * 적용 버튼을 눌러야 변경사항이 적용됩니다.
            </p>
            <Button
              type="primary"
              onClick={() => {
                setIsFilterPopoverOpened(false);
                setBoothFilter(editingFilter);
              }}
              style={{ margin: '0 149px 0', borderRadius: '10px' }}
            >
              적용
            </Button>
          </PopoverLayout>
        </Modal>
      </Layout>
    );
  }
  return <div />;
};

export default FairDetail;
