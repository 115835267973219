import React from 'react';
import styled from 'styled-components';
import { GiftOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '69px 12px 31px' : '74px 38px 31px'};
  overflow-y: ${isMobile ? 'scroll' : ''};
`;

const EventLayout = styled.div`
  width: 100%;
  max-height: 366px;
  border-radius: 10px;
  border: 1px solid #fe706f;
  overflow: hidden;
`;

const EventHeaderLayout = styled.div`
  border-radius: 9px 9px 0 0;
  background-color: #fe706f;
  padding: ${isMobile ? '15px 24px' : '15px 45px'};
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  display: flex;
  align-items: center;
`;

const EventBodyLayout = styled.div`
  height: 280px;
  padding: ${isMobile ? '28px 24px' : '33px 47px 26px'};
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
`;

const EventTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 13px;
`;

const EventDescription = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0em;
  white-space: pre-wrap;
`;

const DateText = styled.p`
  width: 100%;
  text-align: right;
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  div {
    flex: 1;
  }
  p {
    font-weight: 700;
    margin-right: 24px;
  }
`;

const WinnerLayout = styled.div`
  margin-top: ${isMobile ? '8px' : '25px'};
  height: 259px;
  border-radius: 10px;
  border: 1px solid #fe706f;
  overflow: hidden;
`;

const WinnerHeaderLayout = styled.div`
  border-bottom: 1px solid #fe706f;
  border-radius: 9px 9px 0 0;
  padding: ${isMobile ? '15px 24px' : '15px 45px'};
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #fe706f;
  background-color: #fff1f0;
  display: flex;
  align-items: center;
`;

const WinnerBodyLayout = styled.div`
  height: 173px;
  padding: ${isMobile ? '28px 24px' : '33px 47px 26px'};
  overflow-y: scroll;
  white-space: pre-wrap;
`;

const EventModal = ({ event }) => {
  return (
    <Layout>
      <EventLayout>
        <EventHeaderLayout>
          <GiftOutlined style={{ fontSize: '24px', marginRight: '6px' }} />
          이벤트
        </EventHeaderLayout>
        <EventBodyLayout>
          <EventDescription>{event.description}</EventDescription>
          <DateText>
            <div />
            <p>당첨자 발표일</p>
            {event.winnerAnnouncedAt}
          </DateText>
        </EventBodyLayout>
      </EventLayout>
      <WinnerLayout>
        <WinnerHeaderLayout>
          <GiftOutlined
            style={{ color: '#fe706f', fontSize: '24px', marginRight: '6px' }}
          />
          당첨자
        </WinnerHeaderLayout>
        <WinnerBodyLayout>{event.winner}</WinnerBodyLayout>
      </WinnerLayout>
    </Layout>
  );
};

export default EventModal;
