import { BookOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Input, Radio, Upload } from 'antd';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import InputBig from '../../components/InputBig';
import Modal from '../../components/Modal';
import 'react-quill/dist/quill.snow.css';
import SmallModalLayout from '../../components/SmallModalLayout';
import ImageResize from 'quill-image-resize-module-react';
import {
  AdvertisementStep,
  FileType,
  recruitmentTypeArray,
  sizeArray,
} from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import ReactQuill, { Quill } from 'react-quill';
import {
  CREATE_ADVERTISEMENT,
  GET_ADVERTISEMENT_DOMAINS,
  UPDATE_ADVERTISEMENT,
} from '../../queries/advertisement';
import {
  DELETE_COMPANY_FILE,
  DELETE_FILE_BY_URL,
  GET_COMPANY_ADVERTISEMENT,
  GET_COMPANY_PROFILE,
  UPLOAD_COMPANY_FILE,
} from '../../queries/company';
import QuillToolbar, {
  formats,
  undoChange,
  redoChange,
} from '../../components/QuillToolbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Icon from '../../components/Icon';

Quill.register('modules/imageResize', ImageResize);

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid #d9d9d9;
  }
  .ql-container {
    border: none;
  }
  .ql-editor {
    font-size: 18px;
  }
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const ContentLayout = styled.div`
  margin: 24px 0;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  height: calc(100vh - 200px);
  overflow-y: scroll;
  margin-bottom: 0px;
  position: relative;
`;

const TopLayout = styled.div`
  background-color: white;
  padding: 0 0 30px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const HeaderButton = styled(Button)`
  margin-left: 12px;
  width: 176px;
  height: 53px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const HeaderInput = styled(Input)``;

const EditingLayout = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: scroll;
  background-color: white;
  box-shadow: 0px 5px 23px 0px #0000000a;
  display: flex;
  flex-flow: column;
  padding: 20px 30px;
  justify-content: flex-end;
`;

const EditingLayoutHeader = styled.div`
  display: flex;
  height: 23px;
  max-height: 23px;
  align-items: center;
  width: 100%;
  margin-bottom: 72px;
`;

const EditingLayoutTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #111111;
  text-align: center;
`;

const ImageFlexLayout = styled.div`
  display: flex;
  margin: 0 auto;
`;

const YoutubeInputLayout = styled.div`
  margin-top: 24px;
  margin-left: 54px;
  p {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 12px;
  }
`;

const MarginSpacer = styled.div`
  height: ${(props) => props.height};
`;

const BottomButtonLayout = styled.div`
  margin: auto 23px 16px;
  display: flex;
`;

const BottomButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  font-size: 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  width: 600px;
  grid-gap: 16px 0;
  margin-top: 64px;
  margin-bottom: 40px;
`;

const TitleLayout = styled.div`
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const EditingTitleLayout = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const convertStepToStepString = (step) => {
  switch (step) {
    case AdvertisementStep.content:
      return '홍보글 작성';
    case AdvertisementStep.imageOrVideo:
      return '대표 이미지/영상';
    case AdvertisementStep.companySize:
      return '기업 규모';
    case AdvertisementStep.domain:
      return '관련 분야';
    case AdvertisementStep.recruitmentTypes:
      return '채용 형태';
    default:
      return '홍보글 작성';
  }
};
const CompanyAdvertisementManage = () => {
  const { user } = useAuth();
  const [getCompanyAdvertisement, { data: getCompanyAdvertisementData }] =
    useLazyQuery(GET_COMPANY_ADVERTISEMENT);
  useEffect(() => {
    if (user) {
      getCompanyAdvertisement({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyAdvertisement, user]);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [step, setStep] = useState(AdvertisementStep.content);
  const [uploadCompanyFile] = useMutation(UPLOAD_COMPANY_FILE, {
    onCompleted: (data) => {
      setImage(data.uploadCompanyFile);
    },
  });
  const [image, setImage] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [domains, setDomains] = useState([]);
  const [recruitmentTypes, setRecruitmentTypes] = useState([]);
  const quillRef = useRef(null);
  const { data: getAdvertisementDomainsData } = useQuery(
    GET_ADVERTISEMENT_DOMAINS,
  );
  const [quillRange, setQuillRange] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [deleteImageByUrl] = useMutation(DELETE_FILE_BY_URL);
  const [uploadContentImage] = useMutation(UPLOAD_COMPANY_FILE, {
    onCompleted: (data) => {
      const getEditor = quillRef.current.getEditor;
      if (getEditor) {
        getEditor().insertEmbed(
          quillRange.index,
          'image',
          data.uploadCompanyFile?.url,
        );
        getEditor().setSelection(quillRange.index + 1);
        setAddresses((prev) => [...prev, data.uploadCompanyFile?.url]);
      }
    },
  });

  const handleImage = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const getEditor = quillRef.current.getEditor;
      const file = input.files[0];
      // var formData = new FormData();
      // formData.append("file", file);

      const range = getEditor().getSelection(true);
      setQuillRange(range);
      try {
        uploadContentImage({
          variables: {
            userId: user.id,
            fileType: FileType.advertisementContentImage,
            file: file,
          },
        });
      } catch (e) {
        getEditor().deleteText(range.index, 1);
      }
    };
  }, [uploadContentImage, user.id]);

  const [deleteCompanyFile] = useMutation(DELETE_COMPANY_FILE, {
    onCompleted: () => {
      setImage(null);
    },
  });
  const [isCreateSuccessModalOpened, setIsCreateSuccessModalOpened] =
    useState(false);
  const [createAdvertisement] = useMutation(CREATE_ADVERTISEMENT, {
    onCompleted: () => {
      setStep(AdvertisementStep.content);
      getCompanyAdvertisement({
        variables: {
          id: user.id,
        },
      });
      setIsCreateSuccessModalOpened(true);
    },
  });
  const [isUpdateSuccessModalOpened, setIsUpdateSuccessModalOpened] =
    useState(false);

  const [updateAdvertisement] = useMutation(UPDATE_ADVERTISEMENT, {
    onCompleted: () => {
      setStep(AdvertisementStep.content);
      getCompanyAdvertisement({
        variables: {
          id: user.id,
        },
      });
      setIsUpdateSuccessModalOpened(true);
    },
  });
  useEffect(() => {
    setStep(AdvertisementStep.content);
    setTitle(getCompanyAdvertisementData?.company?.advertisement?.title ?? '');
    let urls = [];
    const tmp = document.createElement('div');
    tmp.innerHTML =
      getCompanyAdvertisementData?.company?.advertisement?.content ?? '';
    const elem = tmp.getElementsByTagName('img');
    if (elem) {
      Array.from(elem)?.forEach((item) => urls.push(item.currentSrc));
    }
    setAddresses(urls);
    setContent(
      getCompanyAdvertisementData?.company?.advertisement?.content ?? '',
    );
    setImage(
      getCompanyAdvertisementData?.company?.advertisement?.image ?? null,
    );
    setYoutubeLink(
      getCompanyAdvertisementData?.company?.advertisement?.youtubeLink ?? '',
    );
    setCompanySize(
      getCompanyAdvertisementData?.company?.advertisement?.companySize ??
        '대기업',
    );
    setDomains(
      getCompanyAdvertisementData?.company?.advertisement?.domains ?? [],
    );
    setRecruitmentTypes(
      getCompanyAdvertisementData?.company?.advertisement?.recruitmentTypes ??
        [],
    );
  }, [getCompanyAdvertisementData]);
  const handleUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png' || 'image/jpg';
    if (!isJpgOrPng) {
      alert('JPG/JPEG/PNG 파일만 업로드 가능합니다.');
    } else if (file.size / 1024 / 1024 >= 10) {
      alert('10MB보다 작은 파일만 업로드 가능합니다.');
    } else {
      uploadCompanyFile({
        variables: {
          userId: user.id,
          fileType: FileType.advertisementImage,
          file: file,
        },
      });
    }
    return false;
  };
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  useEffect(() => {
    if (user.id) {
      getCompanyProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyProfile, user]);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  useEffect(() => {
    if (
      companyProfileData &&
      (!Boolean(companyProfileData?.company?.managerInfo?.name) ||
        !Boolean(
          companyProfileData?.company?.basicCompanyProfile?.companyNameKR,
        ))
    ) {
      setIsWarningModalOpened(true);
    }
  }, [companyProfileData]);
  const history = useHistory();
  return (
    <Layout>
      <TitleText>홍보글 관리</TitleText>
      {getCompanyAdvertisementData && (
        <ContentLayout>
          <TitleLayout>
            <Icon icon="edit" size="21px" color="#ff343e" />
            <Title>
              Step 1. {convertStepToStepString(AdvertisementStep.content)}
            </Title>
          </TitleLayout>
          <TopLayout>
            <HeaderInput
              value={title}
              disabled={
                getCompanyAdvertisementData?.company?.advertisement &&
                !getCompanyAdvertisementData?.company?.advertisement?.approved
              }
              onChange={(e) => setTitle(e.currentTarget.value)}
              placeholder="기업명을 포함한 제목을 입력해주세요"
              style={{
                borderRadius: '10px',
                fontSize: '20px',
                border: 'none',
                fontWeight: 500,
                backgroundColor: 'transparent',
                color: 'black',
              }}
            />
            <Spacer />
            <HeaderButton
              type="primary"
              onClick={() => {
                setStep(AdvertisementStep.imageOrVideo);
              }}
              disabled={
                (getCompanyAdvertisementData?.company?.advertisement &&
                  !getCompanyAdvertisementData?.company?.advertisement
                    ?.approved) ||
                title === ''
              }
            >
              {getCompanyAdvertisementData?.company?.advertisement &&
              getCompanyAdvertisementData?.company?.advertisement?.approved
                ? '홍보글 수정하기'
                : getCompanyAdvertisementData?.company?.advertisement
                    ?.approved === false &&
                  getCompanyAdvertisementData?.company?.advertisement !== null
                ? '게시 승인 대기'
                : '홍보글 게시하기'}
            </HeaderButton>
          </TopLayout>
          <QuillToolbar />
          <ReactQuill
            ref={quillRef}
            placeholder="기업을 홍보할 수 있는 글을 자유롭게 작성해주세요"
            theme="snow"
            value={content}
            onChange={(newContent) => {
              setContent(newContent);
            }}
            modules={{
              toolbar: {
                container: '#toolbar',
                handlers: {
                  undo: undoChange,
                  redo: redoChange,
                  image: handleImage,
                },
              },
              imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize', 'Toolbar'],
              },
              history: {
                delay: 500,
                maxStack: 100,
                userOnly: true,
              },
            }}
            formats={formats}
            readOnly={
              getCompanyAdvertisementData?.company?.advertisement &&
              !getCompanyAdvertisementData?.company?.advertisement?.approved
            }
          />

          {step !== AdvertisementStep.content && (
            <EditingLayout>
              <EditingLayoutHeader>
                <EditingTitleLayout>
                  <Icon icon="edit" size="21px" color="#ff343e" />
                  <Title>
                    Step{' '}
                    {Object.values(AdvertisementStep).findIndex(
                      (keyStep) => keyStep === step,
                    ) + 1}
                    . {convertStepToStepString(step)}
                  </Title>
                </EditingTitleLayout>
                <Spacer />
                <button
                  style={{
                    outline: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    marginRight: 0,
                    marginLeft: 'auto',
                    padding: 0,
                    backgroundColor: 'transparent',
                  }}
                  onClick={() => setStep(AdvertisementStep.content)}
                >
                  <CloseOutlined
                    style={{
                      fontSize: isMobile ? '20px' : '23px',
                      color: '#aaaaaa',
                    }}
                  />
                </button>
              </EditingLayoutHeader>

              {step === AdvertisementStep.imageOrVideo && (
                <>
                  <EditingLayoutTitle>
                    대표 이미지 혹은 대표 영상 링크를 설정해주세요.
                  </EditingLayoutTitle>
                  <p
                    style={{
                      fontSize: '12px',
                      textAlign: 'center',
                      marginTop: '8px',
                    }}
                  >
                    *이미지와 유튜브 링크 중 한 개만 입력가능합니다.
                  </p>
                  <MarginSpacer height="72px" />
                  <ImageFlexLayout>
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      disabled={youtubeLink !== ''}
                      fileList={
                        image
                          ? [
                              {
                                uid: image.id,
                                name: image.name,
                                status: 'done',
                                url: image.url,
                              },
                            ]
                          : null
                      }
                      beforeUpload={handleUpload}
                      maxCount={4}
                      showUploadList={{
                        showPreviewIcon: false,
                      }}
                      onRemove={(file) => {
                        deleteCompanyFile({
                          variables: {
                            userId: user.id,
                            fileType: FileType.advertisementImage,
                            fileId: file.uid,
                          },
                        });
                        return false;
                      }}
                    >
                      {(image === null || image === undefined) && (
                        <div>
                          <PlusOutlined style={{ fontSize: '24px' }} />
                          <div style={{ marginTop: 8 }}>대표 이미지</div>
                        </div>
                      )}
                    </Upload>
                    <YoutubeInputLayout>
                      <p>대표 영상</p>
                      <InputBig
                        disabled={image !== null}
                        placeholder={'유튜브 링크를 입력해주세요'}
                        width={'290px'}
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.currentTarget.value)}
                      />
                    </YoutubeInputLayout>
                  </ImageFlexLayout>
                  <Spacer />
                  <BottomButtonLayout>
                    <BottomButton
                      onClick={() => setStep(AdvertisementStep.content)}
                      type="primary"
                    >
                      이전
                    </BottomButton>
                    <Spacer />
                    <BottomButton
                      disabled={image === null && youtubeLink === ''}
                      onClick={() => {
                        setStep(AdvertisementStep.companySize);
                      }}
                      type="primary"
                    >
                      다음
                    </BottomButton>
                  </BottomButtonLayout>
                </>
              )}
              {step === AdvertisementStep.companySize && (
                <>
                  <EditingLayoutTitle>
                    기업 규모를 선택해주세요.
                  </EditingLayoutTitle>
                  <MarginSpacer height="80px" />
                  <div style={{ margin: '0 auto' }}>
                    {sizeArray.map((item) => (
                      <>
                        <Radio
                          key="item"
                          checked={companySize === item}
                          onChange={() => setCompanySize(item)}
                        >
                          {item}
                        </Radio>
                        <br />
                        <br />
                      </>
                    ))}
                  </div>
                  <Spacer />
                  <BottomButtonLayout>
                    <BottomButton
                      onClick={() => setStep(AdvertisementStep.imageOrVideo)}
                      type="primary"
                    >
                      이전
                    </BottomButton>
                    <Spacer />
                    <BottomButton
                      onClick={() => {
                        setStep(AdvertisementStep.domain);
                      }}
                      type="primary"
                    >
                      다음
                    </BottomButton>
                  </BottomButtonLayout>
                </>
              )}
              {step === AdvertisementStep.domain && (
                <>
                  <EditingLayoutTitle>
                    기업과 관련된 분야를 모두 선택해주세요.
                  </EditingLayoutTitle>
                  <MarginSpacer height="64px" />
                  <Grid>
                    {getAdvertisementDomainsData?.advertisementDomains?.map(
                      (item) => (
                        <div>
                          <Checkbox
                            checked={
                              domains.findIndex((a) => a.id === item.id) > -1
                            }
                            onChange={(e) => {
                              console.log(e.target.checked);
                              setDomains((prev) =>
                                e.target.checked
                                  ? [...prev, item]
                                  : prev.filter((a) => a.id !== item.id),
                              );
                            }}
                          >
                            {item.name}
                          </Checkbox>
                        </div>
                      ),
                    )}
                  </Grid>
                  <Spacer />
                  <BottomButtonLayout>
                    <BottomButton
                      onClick={() => setStep(AdvertisementStep.companySize)}
                      type="primary"
                    >
                      이전
                    </BottomButton>
                    <Spacer />
                    <BottomButton
                      disabled={domains.length === 0}
                      onClick={() => {
                        setStep(AdvertisementStep.recruitmentTypes);
                      }}
                      type="primary"
                    >
                      다음
                    </BottomButton>
                  </BottomButtonLayout>
                </>
              )}
              {step === AdvertisementStep.recruitmentTypes && (
                <>
                  <EditingLayoutTitle>
                    기업의 채용 형태를 모두 선택해주세요.
                  </EditingLayoutTitle>
                  <MarginSpacer height="24px" />
                  <Grid>
                    {recruitmentTypeArray.map((item) => (
                      <div>
                        <Checkbox
                          checked={
                            recruitmentTypes.findIndex((a) => a === item) > -1
                          }
                          onChange={(e) => {
                            setRecruitmentTypes((prev) =>
                              e.target.checked
                                ? [...prev, item]
                                : prev.filter((a) => a !== item),
                            );
                          }}
                        >
                          {item}
                        </Checkbox>
                      </div>
                    ))}
                  </Grid>
                  <Spacer />
                  <BottomButtonLayout>
                    <BottomButton
                      onClick={() => setStep(AdvertisementStep.domain)}
                      type="primary"
                    >
                      이전
                    </BottomButton>
                    <Spacer />
                    <BottomButton
                      disabled={recruitmentTypes.length === 0}
                      onClick={() => {
                        if (
                          getCompanyAdvertisementData?.company?.advertisement
                        ) {
                          let urls = [];
                          const tmp = document.createElement('div');
                          tmp.innerHTML = content;
                          const elem = tmp.getElementsByTagName('img');
                          if (elem) {
                            Array.from(elem)?.forEach((item) =>
                              urls.push(item.currentSrc),
                            );
                          }
                          const deleted = addresses.filter(
                            (item) => urls.findIndex((a) => a === item) === -1,
                          );
                          deleted.forEach((item) => {
                            deleteImageByUrl({
                              variables: {
                                url: item,
                              },
                            });
                          });
                          updateAdvertisement({
                            variables: {
                              id: getCompanyAdvertisementData?.company
                                ?.advertisement?.id,
                              advertisementInput: {
                                title,
                                content: content,
                                imageFileId: image?.id,
                                youtubeLink,
                                companySize,
                                domainIds: domains.map((item) => item.id),
                                recruitmentTypes,
                              },
                            },
                          });
                        } else {
                          let urls = [];
                          const tmp = document.createElement('div');
                          tmp.innerHTML = content;
                          const elem = tmp.getElementsByTagName('img');
                          if (elem) {
                            Array.from(elem)?.forEach((item) =>
                              urls.push(item.currentSrc),
                            );
                          }
                          const deleted = addresses.filter(
                            (item) => urls.findIndex((a) => a === item) === -1,
                          );
                          deleted.forEach((item) => {
                            deleteImageByUrl({
                              variables: {
                                url: item,
                              },
                            });
                          });
                          createAdvertisement({
                            variables: {
                              companyId: user.id,
                              advertisementInput: {
                                title,
                                content: content,
                                imageFileId: image?.id,
                                youtubeLink,
                                companySize,
                                domainIds: domains.map((item) => item.id),
                                recruitmentTypes,
                              },
                            },
                          });
                        }
                      }}
                      type="primary"
                    >
                      {getCompanyAdvertisementData?.company?.advertisement
                        ? '수정완료'
                        : '게시신청'}
                    </BottomButton>
                  </BottomButtonLayout>
                  {getCompanyAdvertisementData?.company?.advertisement && (
                    <p style={{ marginTop: '12px', textAlign: 'center' }}>
                      *관리자 승인 이후에는 별도의 신청 절차 없이 자유롭게
                      홍보글을 수정할 수 있습니다.
                    </p>
                  )}
                </>
              )}
            </EditingLayout>
          )}
        </ContentLayout>
      )}
      <Modal
        isOpen={isCreateSuccessModalOpened}
        onClose={() => setIsCreateSuccessModalOpened(false)}
      >
        <SmallModalLayout
          setDescriptionCenter
          title="홍보글 게시 신청 완료"
          description={
            '홍보글 게시 신청이 완료되었습니다.\n관리자의 승인을 기다려주세요.'
          }
          removeLeftButton
          onClickRightButton={() => setIsCreateSuccessModalOpened(false)}
        />
      </Modal>
      <Modal
        isOpen={isUpdateSuccessModalOpened}
        onClose={() => setIsUpdateSuccessModalOpened(false)}
      >
        <SmallModalLayout
          setDescriptionCenter
          title="홍보글 수정 완료"
          description={'홍보글 수정이 완료되었습니다.'}
          removeLeftButton
          onClickRightButton={() => setIsUpdateSuccessModalOpened(false)}
        />
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => history.push('/profile/manage')}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default CompanyAdvertisementManage;
