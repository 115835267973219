import { useLazyQuery } from '@apollo/client';
import { Typography } from 'antd';
import React, { createContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Switch } from 'react-router-dom';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import {
  GET_ON_MATCHING_COMPANIES,
  SEARCH_ON_MATCHING_COMPANIES,
} from '../../queries/matching';
import MobileDisabled from '../MobileDisabled';
import CompanyDetailPage from './CompanyDetailPage';
import CompanyPage from './CompanyPage';
import StudentPage from './StudentPage';

export const MatchingStudentContext = createContext({});

const Matching = () => {
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState('company');
  const [isFilterPopoverOpened, setIsFilterPopoverOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [getFinished, setGetFinished] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchFinished, setSearchFinished] = useState(false);
  const [filter, setFilter] = useState({
    domains: [],
    skills: [],
    recruitmentTypes: [],
    requiredDegrees: [],
  });
  const [listCompaniesWithSkills, setListCompaniesWithSkills] = useState([]);
  const [getOnMatchingCompanies, { loading: getOnMatchingCompaniesLoading }] =
    useLazyQuery(GET_ON_MATCHING_COMPANIES, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setListCompaniesWithSkills((prev) => {
          if (
            prev.findIndex(
              (item) =>
                item.company?.id ===
                data?.onMatchingCompanies?.[0]?.company?.id,
            ) === -1
          ) {
            return [...prev, ...data?.onMatchingCompanies];
          }
          return prev;
        });
        if (data?.onMatchingCompanies?.length < 30) {
          setGetFinished(true);
        }
      },
    });
  const [
    searchOnMatchingCompanies,
    { loading: searchOnMatchingCompaniesLoading },
  ] = useLazyQuery(SEARCH_ON_MATCHING_COMPANIES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setListCompaniesWithSkills((prev) => {
        if (
          prev.findIndex(
            (item) =>
              item.company?.id ===
              data?.searchOnMatchingCompanies?.[0]?.company?.id,
          ) === -1
        ) {
          return [...prev, ...data?.searchOnMatchingCompanies];
        }
        return prev;
      });
      if (data?.searchOnMatchingCompanies?.length < 30) {
        setSearchFinished(true);
      }
    },
  });
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue === '') {
        setIsSearching(false);
      } else {
        setIsSearching(true);
        setListCompaniesWithSkills([]);
        searchOnMatchingCompanies({
          variables: {
            first: 30,
            after: null,
            keyword: searchValue,
          },
        });
        setFilter({
          domains: [],
          skills: [],
          recruitmentTypes: [],
          requiredDegrees: [],
        });
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);
  useEffect(() => {
    if (!isSearching) {
      setGetFinished(false);
      setListCompaniesWithSkills([]);
      getOnMatchingCompanies({
        variables: {
          first: 30,
          after: null,
          domains: filter.domains?.length === 0 ? null : filter.domains,
          skillIds: filter.skills?.length === 0 ? null : filter.skills,
          recruitmentTypes:
            filter.recruitmentTypes.length === 0
              ? null
              : filter?.recruitmentTypes,
          requiredDegrees:
            filter?.requiredDegrees.length === 0
              ? null
              : filter.requiredDegrees,
        },
      });
    }
  }, [filter, isSearching]);
  const [savedCompanyScrollItem, setSavedCompanyScrollItem] = useState('');
  useEffect(() => {
    setSavedCompanyScrollItem('');
    setFilter({
      domains: [],
      skills: [],
      recruitmentTypes: [],
      requiredDegrees: [],
    });
    setIsSearching(false);
    setGetFinished(false);
    setSearchValue('');
    setSearchFinished(false);
    setListCompaniesWithSkills([]);
  }, [selectedTab]);
  return (
    <>
      <Route exact path="/matching/company">
        {isMobile ? (
          <MobileDisabled />
        ) : (
          <>
            {user.userType === UserType.student ? (
              <Typography.Title level={3} style={{ margin: '40px' }}>
                404 Forbidden
              </Typography.Title>
            ) : (
              <CompanyPage />
            )}
          </>
        )}
      </Route>
      <Route exact path="/matching/student">
        <MatchingStudentContext.Provider
          value={{
            selectedTab,
            setSelectedTab,
            searchValue,
            setSearchValue,
            getFinished,
            setGetFinished,
            searchFinished,
            setSearchFinished,
            listCompaniesWithSkills,
            setListCompaniesWithSkills,
            getOnMatchingCompanies,
            getOnMatchingCompaniesLoading,
            searchOnMatchingCompanies,
            searchOnMatchingCompaniesLoading,
            isFilterPopoverOpened,
            setIsFilterPopoverOpened,
            filter,
            setFilter,
            isSearching,
            setIsSearching,
            savedCompanyScrollItem,
            setSavedCompanyScrollItem,
          }}
        >
          <StudentPage />
        </MatchingStudentContext.Provider>
      </Route>
      <Route exact path="/matching/company/:companyId">
        <CompanyDetailPage />
      </Route>
    </>
  );
};

export default Matching;
