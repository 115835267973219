import { BookOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Layout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '464px'};
  height: ${(props) => props.height};
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 34px 0 22px;
  overflow: scroll;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fe706f;
`;

const FlexLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 16px auto 0 46px;
  p {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    margin-left: 8px;
    letter-spacing: 0em;
    text-align: left;
    white-space: pre-wrap;
  }
`;

const TextArea = styled(Input.TextArea)`
  border-radius: 10px;
  margin: 20px 35px;
  height: 126px !important;
  width: ${isMobile ? 'calc(100% - 72px)' : '394px'};
`;

const ButtonLayout = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`;

const BottomLeftButton = styled(Button)`
  width: 96px;
  height: 50px;
  border: 1px solid #fe706f;
  color: #fe706f;
  font-size: 15px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  width: 96px;
  height: 50px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 15px;
`;

const SmallModalWithTextareaLayout = ({
  title,
  description,
  placeholder,
  onClickRightButton,
  onClickLeftButton,
  removeLeftButton = false,
  height = '340px',
  rightButtonName = '확인',
}) => {
  const [text, setText] = useState('');
  return (
    <Layout height={height}>
      <Title>{title}</Title>
      {description ? (
        <FlexLayout>
          <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
          <p>{description}</p>
        </FlexLayout>
      ) : (
        <div style={{ height: '16px' }} />
      )}
      <TextArea
        placeholder={placeholder}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <ButtonLayout>
        {!removeLeftButton && (
          <>
            <BottomLeftButton onClick={onClickLeftButton}>
              취소
            </BottomLeftButton>
            <div style={{ width: '8px' }} />
          </>
        )}

        <BottomRightButton
          type="primary"
          disabled={text === ''}
          onClick={onClickRightButton(text)}
        >
          {rightButtonName}
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default SmallModalWithTextareaLayout;
