import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import {
  DownOutlined,
  RightOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import { GET_EXHIBITED_BOOTHS_BY_STUDENT } from '../../queries/booth';
import {
  DELETE_COUNSEL_RESERVATION,
  SUBSCRIBE_COUNSEL_STATE_CHANGED,
} from '../../queries/counsel';
import CounselMemoModal from '../../pages/BoothAndCounsel/ManageCounsel/CounselMemoModal';
import moment from 'moment';
import { ReservationState } from '../../constants/static';
import { convertStateToNumber } from '../../constants/utils';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import MethodModal from '../BoothAndCounsel/ManageCounsel/MethodModal';
import { isMobile } from 'react-device-detect';
import SmallModalLayout from '../../components/SmallModalLayout';
import { Button } from 'antd';

const Layout = styled.div`
  padding: ${isMobile ? '50px 16px' : '50px 50px 24px 61px'};
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
  margin-bottom: 24px;
  margin-left: ${isMobile ? '16px' : ''};
`;

const ListLayout = styled.div`
  margin: ${isMobile ? '0' : '0 61px'};
`;

const FairItem = styled.div`
  width: 100%;
  border: 1.5px solid #fe706f;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const FairItemTitleLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '8px 16px' : '0 22px 0 33px'};
  align-items: center;
  min-height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const FairItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: ${isMobile ? '0 0 8px 0' : '0 0 0 0'};
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: ${isMobile ? '' : '240px'};
`;

const DateBadge = styled.div`
  border-radius: 10px;
  background-color: ${(props) => (props.isOpened ? 'white' : '#fbfbfb')};
  padding: 5px 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
  margin-right: 16px;
`;

const OnlineBadge = styled.div`
  border-radius: 10px;
  background: ${(props) => (props.isOnline ? '#FFF7E6' : '#F9F0FF')};
  border: 1px solid ${(props) => (props.isOnline ? '#FFA171' : '#D3ADF7')};
  color: ${(props) => (props.isOnline ? '#FFA171' : '#722ED1')};
  font-weight: 500;
  font-size: 12px;
  margin-right: auto;
  margin-left: 0;
  padding: 0 8px;
  height: 22px;
  margin-right: 16px;
`;

const WaitingText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4d4f;
`;

const CounselItem = styled.div`
  height: ${isMobile ? '168px' : '59px'};
  border-radius: 10px;
  background-color: #fbfbfb;
  padding: 13px 15px;
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  margin: ${isMobile ? '0 27px 10px' : '0 62px 10px'};
`;

const CounselDateBadge = styled.div`
  width: 144px;
  height: 32px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  p {
    width: 44px;
  }
`;

const CounselStateText = styled.p`
  margin-right: ${isMobile ? '' : '35px'};
  margin-left: 34px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.color};
`;

const ButtonLayout = styled.div`
  min-width: 230px;
  display: flex;
  align-items: center;
`;

const CounselRightButton = styled.button`
  cursor: pointer;
  margin-left: ${isMobile ? '' : '8px'};
  margin-right: ${isMobile ? '8px' : ''};
  margin-top: ${isMobile ? '16px' : ''};
  height: 32px;
  border-radius: 10px;
  padding: 5px 16px 5px 16px;
  background-color: #f2f2f2;
  color: #373334;
  font-weight: 500;
  outline: none;
  border: none;
`;

const CounselBoothText = styled.p`
  font-family: Noto Sans KR;
  min-width: ${isMobile ? '' : '220px'};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0em;
  flex: 1;
  margin-left: ${isMobile ? '' : '12px'};
  margin-top: ${isMobile ? '21px' : ''};
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  height: ${isMobile ? '44px' : ''};
`;

const GuideText = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 8px;
  margin-right: 8px;
  div {
    width: 8px !important;
    height: 8px !important;
    min-width: 8px !important;
    border-radius: 8px;
    background-color: #fe706f;
    margin-right: 8px;
  }
`;
const convertStateToComponentStyle = (state) => {
  switch (state) {
    case ReservationState.proceeding:
      return {
        text: '상담중',
        color: '#52c41a',
        background: '#f6ffed',
      };
    case ReservationState.pending:
      return { text: '승인대기', color: '#ff4d4f', background: '#ffccc7' };
    case ReservationState.completed:
      return { text: '상담완료', color: '#373334', background: '#f2f2f2' };
    case ReservationState.approved:
      return { text: '승인', color: '#57a0f5', background: '#e6f7ff' };
    case ReservationState.declined:
      return { text: '반려', color: '#820014', background: '#fdf3f2' };
    case ReservationState.canceled:
      return { text: '취소', color: '#b9b9b9', background: '#fdfdfd' };
    default:
      return {
        text: '상담중',
        color: '#52c41a',
        background: '#f6ffed',
      };
  }
};
const CounselHistory = () => {
  const [
    getExhibitedBoothsByStudent,
    { data: getExhibitedBoothsByStudentData },
  ] = useLazyQuery(GET_EXHIBITED_BOOTHS_BY_STUDENT);
  const [openedFair, setOpenedFair] = useState({
    fairId: 0,
    date: '',
  });
  const { user } = useAuth();
  const { data: subscribeCounselStateChanged } = useSubscription(
    SUBSCRIBE_COUNSEL_STATE_CHANGED,
    {
      variables: {
        userId: user.id,
      },
    },
  );
  useEffect(() => {
    if (subscribeCounselStateChanged) {
      console.log(subscribeCounselStateChanged);
      getExhibitedBoothsByStudent({
        variables: {
          studentId: user.id,
        },
      });
    }
  }, [subscribeCounselStateChanged, user.id]);
  const [deleteCounselReservation, { data: deleteCounselReservationData }] =
    useMutation(DELETE_COUNSEL_RESERVATION, {
      onCompleted: () => {
        setCancelingReservationId(0);
      },
    });
  const [method, setMethod] = useState(
    {
      opened: false,
      link: '',
      method: '',
    },
    [],
  );
  const fairs = useMemo(() => {
    if (getExhibitedBoothsByStudentData) {
      let array = [];
      getExhibitedBoothsByStudentData?.exhibitedBoothsByStudent?.forEach(
        (item) => {
          item.dateAndCounselReservations?.forEach((subItem) => {
            if (
              array.findIndex(
                (a) => a.fairId === item.fair?.id && a.date === subItem.date,
              ) > -1
            ) {
              let index = array.findIndex(
                (a) => a.fairId === item.fair?.id && a.date === subItem.date,
              );
              let current = array[index];
              array[index] = {
                fairId: current?.fairId,
                fairTitle: current?.fairTitle,
                isOnline: current?.isOnline,
                date: current?.date,
                reservations: current.reservations
                  .concat(
                    subItem?.counselReservations
                      ?.filter((res) => parseInt(res.student?.id) === user.id)
                      ?.map((res) => {
                        return {
                          boothId: item.id,
                          boothTitle: item.booth?.title,
                          boothZoomLink: item.booth?.zoomLink,
                          boothCounselMethod: item.booth?.counselMethod,
                          reservation: res,
                        };
                      }),
                  )
                  .sort((a, b) =>
                    moment(a.reservation?.startAt ?? '', 'HH:mm').diff(
                      moment(b.reservation?.startAt ?? '', 'HH:mm'),
                    ),
                  ),
              };
            } else {
              array.push({
                fairId: item?.fair?.id,
                fairTitle: item?.fair?.title ?? '',
                isOnline: item?.fair?.isOnline ?? false,
                date: subItem?.date,
                reservations: subItem?.counselReservations
                  ?.filter((res) => parseInt(res.student?.id) === user.id)
                  ?.map((res) => ({
                    boothId: item.id,
                    boothTitle: item.booth?.title,
                    boothZoomLink: item.booth?.zoomLink,
                    boothCounselMethod: item.booth?.counselMethod,
                    reservation: res,
                  }))
                  .sort((a, b) =>
                    moment(a.reservation?.startAt ?? '', 'HH:mm').diff(
                      moment(b.reservation?.startAt ?? '', 'HH:mm'),
                    ),
                  ),
              });
            }
          });
        },
      );
      return array.sort((a, b) => -moment(a.date).diff(moment(b.date)));
    }
    return [];
  }, [getExhibitedBoothsByStudentData, user.id]);
  useEffect(() => {
    getExhibitedBoothsByStudent({
      variables: {
        studentId: user.id,
      },
    });
  }, [user, getExhibitedBoothsByStudent]);
  useEffect(() => {
    if (deleteCounselReservationData) {
      getExhibitedBoothsByStudent({
        variables: {
          studentId: user.id,
        },
      });
    }
  }, [user, deleteCounselReservationData]);
  const history = useHistory();
  const [selectedMemoModal, setSelectedMemoModal] = useState({
    id: 0,
    memo: '',
  });
  const [cancelingReservationId, setCancelingReservationId] = useState(0);
  useEffect(() => {
    if (fairs?.length > 0)
      setOpenedFair({
        fairId: fairs[0].fairId,
        date: fairs[0].date,
      });
  }, [fairs]);
  return (
    <Layout>
      <TitleText>상담 신청 내역</TitleText>
      <GuideText>
        <div />
        상담 시작 10분전에 문자가 전송되고, 상담중 상태로 변경됩니다.
      </GuideText>
      <GuideText>
        <div />
        상담중 상태일 때 오른쪽에 생성되는 상담 방법 버튼을 통해 상담 방법을 알
        수 있습니다.
      </GuideText>
      <GuideText>
        <div />
        아래 상담의 좌측 화살표버튼을 클릭해 상세 내용을 살펴보세요.
      </GuideText>
      <div style={{ height: '24px' }} />
      <ListLayout>
        {fairs
          ?.filter((item) => item.reservations.length > 0)
          .map((item) => (
            <div
              style={{
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'top',
              }}
            >
              <Button
                shape="circle"
                type={
                  openedFair.fairId === item.fairId &&
                  openedFair.date === item.date
                    ? 'primary'
                    : 'ghost'
                }
                style={{ marginRight: '12px', marginTop: '32px' }}
                onClick={() => {
                  setOpenedFair(
                    openedFair.fairId === item.fairId &&
                      openedFair.date === item.date
                      ? {
                          fairId: 0,
                          date: '',
                        }
                      : {
                          fairId: item.fairId,
                          date: item.date,
                        },
                  );
                }}
              >
                {openedFair.fairId === item.fairId &&
                openedFair.date === item.date ? (
                  <DownOutlined
                    style={{
                      fontSize: '16px',
                    }}
                  />
                ) : (
                  <RightOutlined
                    style={{
                      fontSize: '16px',
                    }}
                  />
                )}
              </Button>
              <FairItem
                key={item.id}
                opened={
                  openedFair.id === item.id && openedFair.date === item.date
                }
              >
                <FairItemTitleLayout
                  opened={
                    openedFair.fairId === item.fairId &&
                    openedFair.date === item.date
                  }
                >
                  {isMobile ? (
                    <>
                      <div>
                        <FairItemTitle
                          opened={
                            openedFair.fairId === item.fairId &&
                            openedFair.date === item.date
                          }
                        >
                          {item.fairTitle}
                        </FairItemTitle>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <DateBadge
                            isOpened={
                              openedFair.fairId === item.fairId &&
                              openedFair.date === item.date
                            }
                          >
                            {item.date}
                          </DateBadge>
                          <OnlineBadge isOnline={item.isOnline}>
                            {item.isOnline ? '온라인' : '오프라인'}
                          </OnlineBadge>
                          <WaitingText>
                            {isMobile ? '완료' : '상담완료'}{' '}
                            {
                              item.reservations?.filter(
                                (a) =>
                                  a.reservation?.state ===
                                  ReservationState.completed,
                              )?.length
                            }
                            회
                          </WaitingText>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <FairItemTitle
                        opened={
                          openedFair.fairId === item.fairId &&
                          openedFair.date === item.date
                        }
                      >
                        {item.fairTitle}
                      </FairItemTitle>
                      <Spacer />
                      <DateBadge
                        isOpened={
                          openedFair.fairId === item.fairId &&
                          openedFair.date === item.date
                        }
                      >
                        {item.date}
                      </DateBadge>
                      <OnlineBadge isOnline={item.isOnline}>
                        {item.isOnline ? '온라인' : '오프라인'}
                      </OnlineBadge>
                      <WaitingText>
                        상담완료{' '}
                        {
                          item.reservations?.filter(
                            (a) =>
                              a.reservation?.state ===
                              ReservationState.completed,
                          )?.length
                        }
                        회
                      </WaitingText>
                    </>
                  )}
                </FairItemTitleLayout>
                {openedFair.fairId === item.fairId &&
                  openedFair.date === item.date && (
                    <>
                      <div style={{ height: '24px' }} />
                      {item.reservations.map((reservation) => (
                        <CounselItem>
                          {isMobile ? (
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <CounselDateBadge>
                                <p>{reservation.reservation?.startAt}</p>
                                <SwapRightOutlined
                                  style={{ color: '#bfbfbf', margin: '0 8px' }}
                                />
                                <p>{reservation.reservation?.endAt}</p>
                              </CounselDateBadge>
                              <CounselStateText
                                color={
                                  convertStateToComponentStyle(
                                    reservation.reservation?.state,
                                  ).color
                                }
                              >
                                {
                                  convertStateToComponentStyle(
                                    reservation.reservation?.state,
                                  ).text
                                }
                              </CounselStateText>
                            </div>
                          ) : (
                            <CounselDateBadge>
                              <p>{reservation.reservation?.startAt}</p>
                              <SwapRightOutlined
                                style={{ color: '#bfbfbf', margin: '0 8px' }}
                              />
                              <p>{reservation.reservation?.endAt}</p>
                            </CounselDateBadge>
                          )}
                          <CounselBoothText>
                            {reservation.boothTitle}
                          </CounselBoothText>
                          {!isMobile && <Spacer />}
                          {!isMobile && (
                            <CounselStateText
                              color={
                                convertStateToComponentStyle(
                                  reservation.reservation?.state,
                                ).color
                              }
                            >
                              {
                                convertStateToComponentStyle(
                                  reservation.reservation?.state,
                                ).text
                              }
                            </CounselStateText>
                          )}

                          <ButtonLayout>
                            {!isMobile && <Spacer />}
                            {item.isOnline &&
                              reservation.reservation?.state ===
                                ReservationState.proceeding && (
                                <>
                                  {reservation.boothCounselMethod === '' &&
                                  reservation.boothZoomLink === '' ? (
                                    <p
                                      style={{
                                        marginLeft: '8px',
                                        marginRight: '0px',
                                        color: 'grey',
                                      }}
                                    >
                                      상담 방법이 없습니다
                                    </p>
                                  ) : (
                                    <CounselRightButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setMethod({
                                          opened: true,
                                          link: reservation.boothZoomLink,
                                          method:
                                            reservation.boothCounselMethod,
                                        });
                                      }}
                                    >
                                      상담 하기
                                    </CounselRightButton>
                                  )}
                                </>
                              )}
                            {(reservation.reservation?.state ===
                              ReservationState.pending ||
                              reservation.reservation?.state ===
                                ReservationState.approved) && (
                              <CounselRightButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCancelingReservationId(
                                    reservation.reservation?.id,
                                  );
                                }}
                              >
                                상담 취소
                              </CounselRightButton>
                            )}
                            {(reservation.reservation?.state ===
                              ReservationState.proceeding ||
                              reservation.reservation?.state ===
                                ReservationState.completed) && (
                              <CounselRightButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  console.log(reservation.reservation);
                                  setSelectedMemoModal({
                                    id: reservation.reservation.id,
                                    memo: reservation.reservation.studentMemo,
                                  });
                                }}
                              >
                                상담기록
                              </CounselRightButton>
                            )}
                            {reservation.reservation?.state !==
                              ReservationState.canceled && (
                              <CounselRightButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push(
                                    `/fair/${item.fairId}/booth/${reservation.boothId}`,
                                  );
                                }}
                              >
                                부스 보러가기
                              </CounselRightButton>
                            )}
                          </ButtonLayout>
                        </CounselItem>
                      ))}
                    </>
                  )}
              </FairItem>
            </div>
          ))}
      </ListLayout>
      <Modal
        isOpen={method.opened}
        onClose={() => {
          setMethod({
            opened: false,
            link: '',
            method: '',
          });
        }}
        width={isMobile ? '343px' : '664px'}
        height="455px"
      >
        <MethodModal
          onClose={() =>
            setMethod({
              opened: false,
              link: '',
              method: '',
            })
          }
          link={method.link}
          method={method.method}
        />
      </Modal>
      <Modal
        isOpen={selectedMemoModal.id > 0}
        onClose={() => {
          setSelectedMemoModal({
            id: 0,
            memo: '',
          });
        }}
        width={isMobile ? 'calc(100vw - 32px)' : '971px'}
        height="527px"
      >
        <CounselMemoModal
          id={selectedMemoModal.id}
          currentMemo={selectedMemoModal.memo}
          onClose={() => {
            setSelectedMemoModal({
              id: 0,
              memo: '',
            });
            getExhibitedBoothsByStudent({
              variables: {
                studentId: user.id,
              },
            });
          }}
        />
      </Modal>
      <Modal
        isOpen={cancelingReservationId > 0}
        onClose={() => setCancelingReservationId(0)}
        radius="30px"
      >
        <SmallModalLayout
          title="상담 취소"
          description={<>상담을 취소하시겠습니까?</>}
          setDescriptionCenter={true}
          removeLeftButton={true}
          onClickRightButton={() => {
            deleteCounselReservation({
              variables: {
                id: cancelingReservationId,
              },
            });
          }}
          height="305px"
        />
      </Modal>
    </Layout>
  );
};

export default CounselHistory;
