import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep, JobVacancyStep, Location } from '../../../constants/static';
import { UPDATE_JOB_VACANCY } from '../../../queries/jobVacancy';

const { Option } = Select;
const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 783px;
  margin: 0 auto;
  padding: 0 18px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 96px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const StyledSelect = styled(Select)`
  margin-left: 15px;
  width: 298px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 783px;
  min-height: 149px !important;
  border-radius: 10px;
  margin: 20px auto;
`;

const TaskDetail = ({ jobVacancy, isCreating, getJobVacancies }) => {
  const [editingLocation, setEditingLocation] = useState(undefined);
  const [editingTaskDetail, setEditingTaskDetail] = useState('');
  const [updateJobVacancy, { data }] = useMutation(UPDATE_JOB_VACANCY);
  useEffect(() => {
    setEditingLocation(jobVacancy?.workplace ?? undefined);
    setEditingTaskDetail(jobVacancy?.task ?? '');
  }, [jobVacancy]);

  const onClickLeftButton = () => {
    if (editingLocation === undefined || editingTaskDetail === '') {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            step: JobVacancyStep.managerEmail,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            workplace: editingLocation,
            task: editingTaskDetail,
            step: JobVacancyStep.managerEmail,
          },
        },
      });
    }
  };
  const onClickRightButton = () => {
    if (isCreating) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            workplace: editingLocation,
            task: editingTaskDetail,
            step: JobVacancyStep.requirements,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            workplace: editingLocation,
            task: editingTaskDetail,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getJobVacancies();
    }
  }, [data]);
  return (
    <Layout>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>근무 지역을 선택해주세요.</p>
        <StyledSelect
          size="large"
          placeholder="지역을 선택해주세요"
          value={editingLocation}
          onChange={(value) => setEditingLocation(value)}
        >
          {Location.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </StyledSelect>
      </FlexLayout>
      <div style={{ height: '46px' }} />
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>입사 후 해당 포지션에서 어떤 일을 하게 되는지 작성해주세요.</p>
      </FlexLayout>
      <StyledTextarea
        placeholder="업무내용을 입력해주세요"
        value={editingTaskDetail}
        onChange={(e) => setEditingTaskDetail(e.currentTarget.value)}
      />
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={
                editingTaskDetail === '' || editingLocation === undefined
              }
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={editingTaskDetail === '' || editingLocation === undefined}
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default TaskDetail;
