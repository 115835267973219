import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import CompanyProfileModal from '../../components/CompanyProfileModal';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import {
  MatchingApplicationState,
  MatchingSuggestionState,
  MatchingType,
} from '../../constants/static';
import JobVacancyModal from '../Booth/JobVacancyModal';
import { convertStateToComponentStyle } from './ApplicationsTab';

const Layout = styled.div`
  width: 320px;
  height: 428px;
  cursor: pointer;
  background-color: #fcfcfc;
  border-radius: 30px;
  box-shadow: 0px 12px 23px 0px #0d03030a;
  padding: 24px 0 25px;
  display: flex;
  margin-bottom: ${isMobile ? '20px' : ''};
  flex-direction: column;
`;

const FlexLayout = styled.div`
  margin: 0 25px 17px;
  display: flex;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const DateText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
`;

const StateText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: ${(props) => props.color};
`;

const ImageLayout = styled.div`
  margin-right: 17px;
  height: 72px;
  width: 72px;
  position: relative;
  border-radius: 10px;
  background-color: #f2f2f2;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 72px;
    max-height: 72px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const CompanyNameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 2.8em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DomainText = styled.p`
  font-family: Noto Sans KR;
  margin-left: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 8px;
`;

const JobVacancyText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  margin-left: 25px;

  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const WorkplaceText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  margin-left: 25px;

  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 4px;
`;

const RecruitmentScheduleText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  margin-left: 25px;

  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #f1f1f1;
  margin: 20px 0;
`;

const SkillsLayout = styled.div`
  height: 27px;
  margin: 0 32px;
  overflow: hidden;
`;

const Skill = styled.div`
  height: 27px;
  border-radius: 10px;
  background-color: #fff3f0;
  color: #ffa171;
  margin: 0 8px 8px 0;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 13px;
  line-height: 19px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const TypesLayout = styled.div`
  height: 22px;
  margin: 16px 32px 0;
  overflow: hidden;
`;

const Type = styled.div`
  height: 22px;
  border-radius: 2px;
  padding: 1px 8px;
  background-color: #fff1f0;
  color: #fe706f;
  font-size: 12px;
  margin-right: 8px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.1em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const BottomButtonLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 0;
`;

const BottomButton = styled(Button)`
  border-radius: 10px;
  margin: 0 8px;
  font-weight: 500;
`;

const SuggestionModalLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '892px'};
  height: 527px;
`;
const SuggestionModalBody = styled.div`
  height: ${isMobile ? 'calc(100% - 119px)' : 'calc(100% - 92px)'};
  width: 100%;
  position: absolute;
  top: ${isMobile ? '92px' : '92px'};
  z-index: 0;
  overflow: scroll;
`;

const ItemModalBody = styled.div`
  height: ${isMobile ? 'calc(100% - 92px)' : 'calc(100% - 92px)'};
  width: 100%;
  position: absolute;
  top: ${isMobile ? '92px' : '92px'};
  z-index: 0;
  overflow: scroll;
`;

const ModalHeader = styled.div`
  height: '92px';
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: ${isMobile ? '' : 'flex'};
  padding: ${isMobile ? '35px 26px 16px' : '35px 26px 32px'};
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: ${isMobile ? '' : '16px'};
  margin-bottom: ${isMobile ? '14px' : ''};
`;

const SuggestionRowItem = styled.div`
  display: flex;
  width: 100%;
  margin: ${isMobile ? '22px 28px' : '22px 49px 22px 77px'};
  span {
    width: ${isMobile ? '100px' : '199px'};
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
  p {
    white-space: pre-wrap;
    font-family: Noto Sans KR;
    width: ${isMobile ? '200px' : '567px'};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-all;
  }
`;

const ModalDivider = styled.div`
  height: 1px;
  background-color: #f0f0f0;
`;

const ItemLayout = styled.div`
  padding: 31px 0;
`;

const ItemTitleLayout = styled.div`
  display: flex;
  align-items: center;
  padding: ${isMobile ? '0 29px 0 16px' : '0 65px'};
`;

const ItemTitleText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 8px;
  color: ${(props) => props.color};
`;

const StudentMatchingComponent = ({
  matchingType,
  jobVacancy,
  matchingState,
  reasonDeclined,
  reasonCanceled,
  interviewSuggestion,
  createdAt,
  onClickBottomButton,
  onClickBottomLeftButton,
}) => {
  const [canceledReason, setCanceledReason] = useState('');
  const [declinedReason, setDeclinedReason] = useState('');
  const [isInformationModalOpened, setIsInformationModalOpened] =
    useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [openedInformation, setOpenedInformation] = useState('jobVacancy');
  return (
    <Layout
      onClick={() => {
        if (
          !isInformationModalOpened &&
          canceledReason === '' &&
          declinedReason === '' &&
          selectedSuggestion === null
        ) {
          setIsInformationModalOpened(true);
        }
      }}
    >
      <FlexLayout>
        <DateText>
          {matchingType === MatchingType.suggested ? '제안일' : '지원일'} :{' '}
          {createdAt?.split(' ')?.[0]}
        </DateText>
        <Spacer />
        <StateText
          color={
            convertStateToComponentStyle(matchingState, matchingType).color
          }
        >
          {convertStateToComponentStyle(matchingState, matchingType).text}
        </StateText>
      </FlexLayout>
      <FlexLayout>
        <ImageLayout>
          {jobVacancy?.company?.logo !== null &&
            jobVacancy?.company?.logo !== undefined && (
              <img src={jobVacancy?.company?.logo?.url} alt="thumbnail" />
            )}
        </ImageLayout>
        <CompanyNameText>
          {jobVacancy?.company?.basicCompanyProfile?.companyNameKR}
          <br />
          {jobVacancy?.company?.basicCompanyProfile?.companyNameEN}
        </CompanyNameText>
      </FlexLayout>
      <DomainText>
        {jobVacancy?.company?.basicCompanyProfile?.domain}
      </DomainText>
      <JobVacancyText>{jobVacancy?.position}</JobVacancyText>
      <WorkplaceText>근무지역 : {jobVacancy?.workplace}</WorkplaceText>
      <RecruitmentScheduleText>
        채용일정 : {jobVacancy?.recruitmentSchedule}
      </RecruitmentScheduleText>
      <Divider />
      <SkillsLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {jobVacancy?.skills?.slice(0, 2)?.map((item, index) => (
            <Skill key={index}>{item.skillDetail}</Skill>
          ))}
          {2 < jobVacancy?.skills?.length && (
            <p style={{ fontSize: '12px', marginTop: '4px', color: '#FFA171' }}>
              외 {jobVacancy?.skills?.length - 2}개
            </p>
          )}
        </div>
      </SkillsLayout>
      <TypesLayout>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {jobVacancy?.company?.recruitmentTypes
            ?.slice(0, 2)
            ?.map((item, index) => (
              <Type key={index}>{item}</Type>
            ))}
          {2 < jobVacancy?.company?.recruitmentTypes?.length && (
            <p style={{ fontSize: '12px', marginTop: '2px', color: '#FE706F' }}>
              외 {jobVacancy?.company?.recruitmentTypes?.length - 2}개
            </p>
          )}
        </div>
      </TypesLayout>
      <BottomButtonLayout>
        {matchingType === MatchingType.applied ? (
          <>
            {(matchingState === MatchingApplicationState.pending ||
              matchingState === MatchingApplicationState.approved) && (
              <BottomButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  onClickBottomButton();
                }}
              >
                지원 취소
              </BottomButton>
            )}
            {matchingState === MatchingApplicationState.declined && (
              <BottomButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeclinedReason(reasonDeclined);
                }}
              >
                반려 사유
              </BottomButton>
            )}
            {matchingState === MatchingApplicationState.canceled && (
              <BottomButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setCanceledReason(reasonCanceled);
                }}
              >
                취소 사유
              </BottomButton>
            )}
          </>
        ) : (
          <>
            <BottomButton
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedSuggestion(interviewSuggestion);
              }}
            >
              제안 내용
            </BottomButton>
            {matchingState === MatchingSuggestionState.pending && (
              <>
                <BottomButton
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickBottomLeftButton();
                  }}
                >
                  수락
                </BottomButton>
                <BottomButton
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickBottomButton();
                  }}
                >
                  거절
                </BottomButton>
              </>
            )}
            {matchingState === MatchingSuggestionState.declined && (
              <BottomButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeclinedReason(reasonDeclined);
                }}
              >
                거절 사유
              </BottomButton>
            )}
            {matchingState === MatchingSuggestionState.canceled && (
              <BottomButton
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setCanceledReason(reasonCanceled);
                }}
              >
                취소 사유
              </BottomButton>
            )}
          </>
        )}
      </BottomButtonLayout>
      <Modal
        isOpen={canceledReason !== ''}
        onClose={() => setCanceledReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title="취소 사유"
          description={canceledReason}
          onClickRightButton={() => setCanceledReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={declinedReason !== ''}
        onClose={() => setDeclinedReason('')}
        radius="30px"
      >
        <SmallModalLayout
          title={
            matchingType === MatchingType.suggested ? '거절 사유' : '반려 사유'
          }
          description={declinedReason}
          onClickRightButton={() => setDeclinedReason('')}
          removeLeftButton
          height="305px"
        />
      </Modal>
      <Modal
        isOpen={selectedSuggestion !== null}
        onClose={() => setSelectedSuggestion(null)}
        closeButtonTransparent
      >
        <SuggestionModalLayout>
          <ModalHeader>
            <ModalHeaderTitle>제안 정보</ModalHeaderTitle>
          </ModalHeader>
          <SuggestionModalBody>
            {selectedSuggestion?.message && (
              <>
                <SuggestionRowItem>
                  <span>제안 메시지</span>
                  <p>{selectedSuggestion?.message}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.rank && (
              <>
                <SuggestionRowItem>
                  <span>직급</span>
                  <p>{selectedSuggestion?.rank}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.minSalary && (
              <>
                <SuggestionRowItem>
                  <span>연봉</span>
                  <p>
                    {selectedSuggestion?.minSalary} ~{' '}
                    {selectedSuggestion?.maxSalary} 만원
                  </p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
            {selectedSuggestion?.stockOption && (
              <>
                <SuggestionRowItem>
                  <span>스톡옵션 여부</span>
                  <p>{selectedSuggestion?.stockOption}</p>
                </SuggestionRowItem>
                <ModalDivider />
              </>
            )}
          </SuggestionModalBody>
        </SuggestionModalLayout>
      </Modal>
      {jobVacancy && (
        <Modal
          isOpen={isInformationModalOpened === true}
          onClose={(e) => {
            setIsInformationModalOpened(false);
            setOpenedInformation('jobVacancy');
          }}
          closeButtonTransparent
        >
          <SuggestionModalLayout>
            <ModalHeader>
              <ModalHeaderTitle>{jobVacancy?.position}</ModalHeaderTitle>
            </ModalHeader>
            <ItemModalBody>
              <ItemLayout>
                <ItemTitleLayout>
                  <Button
                    type="link"
                    color={
                      openedInformation === 'jobVacancy' ? '#000000' : '#fe706f'
                    }
                    onClick={() =>
                      setOpenedInformation(
                        openedInformation === 'company'
                          ? 'jobVacancy'
                          : 'company',
                      )
                    }
                  >
                    {openedInformation === 'jobVacancy' ? (
                      <RightOutlined
                        style={{ fontSize: '18px', color: '#000000' }}
                      />
                    ) : (
                      <DownOutlined
                        style={{ fontSize: '18px', color: '#fe706f' }}
                      />
                    )}
                  </Button>
                  <ItemTitleText
                    color={
                      openedInformation === 'jobVacancy' ? '#000000' : '#fe706f'
                    }
                  >
                    기업 정보
                  </ItemTitleText>
                </ItemTitleLayout>
                {openedInformation === 'company' && (
                  <CompanyProfileModal id={jobVacancy?.company?.id} />
                )}
              </ItemLayout>
              <ModalDivider />
              <ItemLayout>
                <ItemTitleLayout>
                  <Button
                    type="link"
                    color={
                      openedInformation === 'company' ? '#000000' : '#fe706f'
                    }
                    onClick={() =>
                      setOpenedInformation(
                        openedInformation === 'jobVacancy'
                          ? 'company'
                          : 'jobVacancy',
                      )
                    }
                  >
                    {openedInformation === 'company' ? (
                      <RightOutlined
                        style={{ fontSize: '18px', color: '#000000' }}
                      />
                    ) : (
                      <DownOutlined
                        style={{ fontSize: '18px', color: '#fe706f' }}
                      />
                    )}
                  </Button>
                  <ItemTitleText
                    color={
                      openedInformation === 'company' ? '#000000' : '#fe706f'
                    }
                  >
                    채용 정보
                  </ItemTitleText>
                </ItemTitleLayout>
                {openedInformation === 'jobVacancy' && (
                  <JobVacancyModal showHeader={false} jobVacancy={jobVacancy} />
                )}
              </ItemLayout>
            </ItemModalBody>
          </SuggestionModalLayout>
        </Modal>
      )}
    </Layout>
  );
};

export default StudentMatchingComponent;
