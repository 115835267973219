import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox } from 'antd';

import { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import {
  recruitmentTypeArray,
  StudentProfileStep,
} from '../../../../constants/static';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 40px;
  margin-bottom: ${isMobile ? '40px' : '0'};
  display: grid;
  grid-template-columns: ${isMobile ? '1fr' : '1fr 1fr 1fr'};
  grid-template-rows: ${isMobile ? '' : '1fr 1fr 1fr'};
  padding: ${isMobile ? '0 24px' : '0 151px'};
  gap: ${isMobile ? '16px' : ''};
  width: 100%;
  height: ${isMobile ? '' : '204px'};
  .ant-checkbox-wrapper {
    margin: 0 !important;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const RecruitmentTypes = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
}) => {
  const [recruitmentTypes, setRecruitmentTypes] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.recruitmentTypes?.length > 0) {
      setRecruitmentTypes(profile?.recruitmentTypes);
    }
  }, [profile]);
  const handleClickLeftButton = () => {
    if (recruitmentTypes.length === 0) {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: StudentProfileStep.labInfo,
          },
        },
      });
    } else {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: StudentProfileStep.labInfo,
            recruitmentTypes: recruitmentTypes,
          },
        },
      });
    }
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.skills,
          recruitmentTypes: recruitmentTypes,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <BodyLayout>
        {recruitmentTypeArray.map((item) => (
          <Checkbox
            checked={recruitmentTypes.findIndex((type) => type === item) > -1}
            onChange={() => {
              if (recruitmentTypes.findIndex((type) => type === item) > -1) {
                setRecruitmentTypes((prev) =>
                  prev.filter((prevItem) => prevItem !== item),
                );
              } else {
                setRecruitmentTypes((prev) => [...prev, item]);
              }
            }}
          >
            {item}
          </Checkbox>
        ))}
      </BodyLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            type="primary"
            onClick={handleClickRightButton}
            disabled={recruitmentTypes.length === 0}
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={recruitmentTypes.length === 0}
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default RecruitmentTypes;
