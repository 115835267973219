import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input, TimePicker, Select } from 'antd';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import { CheckOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';

const newMoment = extendMoment(moment);

const { RangePicker } = TimePicker;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 0;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Spacer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.width};
`;

const FlexLayout = styled.div`
  margin: 16px auto;
  display: flex;
  align-items: center;
`;

const DescriptionText = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const StyledTimePicker = styled(RangePicker)`
  border-radius: 10px;
`;

const StyledSelect = styled(Select)`
  width: 102px;
  max-width: 102px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const TimeLayout = styled.div`
  width: 700px;
  height: 183px;
  border-radius: 10px;
  background-color: #fbfbfb;
  margin: 0 auto;
  padding: 0 27px;
  overflow: scroll;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px 16px;
  width: 100%;
`;

const DateChip = styled.div`
  width: 139px;
  height: 32px;
  padding: 0 12px;
  border-radius: 10px;
  border: ${(props) => (!props.selected ? '1px solid #fefefe' : '')};
  background-color: ${(props) => (!props.selected ? '#ffffff' : '#FFF1F0')};
  font-weight: ${(props) => (!props.selected ? 300 : 500)};
  color: ${(props) => (!props.selected ? '#aaaaaa' : '#262626')};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CounselingTime = ({ booth, isCreating, getBooths }) => {
  const [times, setTimes] = useState([]);
  const [timeRange, setTimeRange] = useState({
    startAt: '',
    endAt: '',
  });
  const [interval, setInterval] = useState(15);
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);

  useEffect(() => {
    setTimeRange({
      startAt: booth.counselStartAt ?? '',
      endAt: booth.counselEndAt ?? '',
    });
    setInterval(booth.counselInterval ?? (booth.isOnline ? 15 : 5));
    setTimes(
      booth.enabledCounsels?.map((item) => item.startAt + ' - ' + item.endAt) ??
        [],
    );
  }, [booth]);

  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselStartAt: timeRange.startAt,
            counselEndAt: timeRange.endAt,
            counselInterval: interval,
            enabledCounsels: times.map((item) => ({
              startAt: item.split(' - ')[0],
              endAt: item.split(' - ')[1],
            })),
            step: BoothStep.present,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselStartAt: timeRange.startAt,
            counselEndAt: timeRange.endAt,
            counselInterval: interval,
            enabledCounsels: times.map((item) => ({
              startAt: item.split(' - ')[0],
              endAt: item.split(' - ')[1],
            })),
            step: BoothStep.complete,
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    if (times.length === 0) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            step: BoothStep.counselorInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            counselStartAt: timeRange.startAt,
            counselEndAt: timeRange.endAt,
            counselInterval: interval,
            enabledCounsels: times.map((item) => ({
              startAt: item.split(' - ')[0],
              endAt: item.split(' - ')[1],
            })),
            step: BoothStep.counselorInformation,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);
  const chipArray = useMemo(() => {
    if (timeRange.startAt !== '' && timeRange.endAt !== '') {
      const range = newMoment.range(
        moment()
          .startOf('day')
          .hours(timeRange.startAt.split(':')[0])
          .minute(timeRange.startAt.split(':')[1]),
        moment()
          .startOf('day')
          .hours(timeRange.endAt.split(':')[0])
          .minute(timeRange.endAt.split(':')[1]),
      );
      const array = Array.from(range.by('minutes', { step: interval }));
      return array
        .map((item, index) =>
          index < array.length - 1
            ? `${moment(item).format('HH:mm')} - ${moment(
                array[index + 1],
              ).format('HH:mm')}`
            : undefined,
        )
        .filter((item) => !!item);
    }
    return [];
  }, [timeRange, interval]);

  const [isNotInitialValue, setIsNotInitialValue] = useState(false);
  useEffect(() => {
    if (isNotInitialValue) {
      setTimes(chipArray);
    }
  }, [chipArray, isNotInitialValue]);
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>가능 상담 시간을 모두 선택해주세요.</p>
      </TitleLaout>
      <FlexLayout>
        <DescriptionText>운영시간</DescriptionText>
        <Spacer width="13px" />
        <StyledTimePicker
          placeholder={['시작시간', '종료시간']}
          format="HH:mm"
          allowClear={false}
          value={[
            timeRange.startAt !== ''
              ? moment(timeRange.startAt, 'HH:mm')
              : undefined,
            timeRange.endAt !== ''
              ? moment(timeRange.endAt, 'HH:mm')
              : undefined,
          ]}
          onChange={(e) => {
            setIsNotInitialValue(true);
            setTimeRange({
              startAt: moment(e[0]).format('HH:mm'),
              endAt: moment(e[1]).format('HH:mm'),
            });
          }}
        />
        <Spacer width="60px" />
        <DescriptionText>상담시간</DescriptionText>
        <Spacer width="27px" />
        <StyledSelect
          value={interval}
          onChange={(value) => {
            setInterval(value);
            setIsNotInitialValue(true);
          }}
        >
          {!booth.isOnline && (
            <>
              <Select.Option value={5}>5분</Select.Option>
              <Select.Option value={10}>10분</Select.Option>
            </>
          )}
          <Select.Option value={15}>15분</Select.Option>
          <Select.Option value={20}>20분</Select.Option>
          <Select.Option value={30}>30분</Select.Option>
          <Select.Option value={60}>1시간</Select.Option>
        </StyledSelect>
      </FlexLayout>
      <TimeLayout>
        {timeRange.startAt !== '' && timeRange.endAt !== '' && (
          <>
            <Spacer width="16px" />
            <Grid>
              {chipArray.map((item, index) => (
                <DateChip
                  key={index}
                  selected={times.findIndex((a) => a === item) > -1}
                  onClick={() => {
                    if (times.findIndex((a) => a === item) > -1) {
                      setTimes((prev) => prev.filter((a) => a !== item));
                    } else {
                      setTimes((prev) => prev.concat(item));
                    }
                  }}
                >
                  {item}
                  {times.findIndex((a) => a === item) > -1 && (
                    <CheckOutlined
                      style={{ marginLeft: '10px', color: '#fe706f' }}
                    />
                  )}
                </DateChip>
              ))}
            </Grid>
            <Spacer width="16px" />
          </>
        )}
      </TimeLayout>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={onClickRightButton}
              disabled={times.length === 0}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={times.length === 0}
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default CounselingTime;
