import { BookOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DELETE_COUNSELOR } from '../../../queries/counsel';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: 35px 35px 22px;
  display: flex;
  flex-flow: column;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 11px;
  color: #ff434e;
`;

const FlexLayout = styled.div`
  display: flex;
  margin: 114px auto 0;
  padding: 0 18px;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  margin-top: 20px;
  width: 394px;
  border-radius: 10px;
`;

const BottomButtonLayout = styled.div`
  margin: auto auto 0;
  display: flex;
`;

const BottomButton = styled(Button)`
  margin: 0 8px;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  font-size: 15px;
`;

const DeleteCounselAccountModal = ({ counselAccount, onClose }) => {
  const [text, setText] = useState('');
  const [deleteCounselor, { data }] = useMutation(DELETE_COUNSELOR);
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);
  return (
    <Layout>
      <Title>상담계정 삭제</Title>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>삭제를 원하시면 계정의 이메일을 입력해주세요.</p>
      </FlexLayout>
      <StyledInput
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
        placeholder="이메일을 입력해주세요"
      />
      <BottomButtonLayout>
        <BottomButton
          onClick={onClose}
          style={{ borderColor: '#FE706F', color: '#FE706F' }}
        >
          취소
        </BottomButton>
        <BottomButton
          onClick={() => {
            deleteCounselor({
              variables: {
                id: counselAccount.id,
              },
            });
          }}
          disabled={text !== counselAccount.email}
          type="primary"
        >
          확인
        </BottomButton>
      </BottomButtonLayout>
    </Layout>
  );
};

export default DeleteCounselAccountModal;
