import { gql } from '@apollo/client';

export const DOWNLOAD_COUNSELS_EXCEL = gql`
  mutation DownloadCounselsExcel($exhibitedBoothId: ID, $date: String) {
    downloadCounselsExcel(exhibitedBoothId: $exhibitedBoothId, date: $date) {
      id
      name
      url
    }
  }
`;
