import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep, JobVacancyStep } from '../../../constants/static';
import { UPDATE_JOB_VACANCY } from '../../../queries/jobVacancy';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 96px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const JobVacancyTitle = ({ jobVacancy, isCreating, getJobVacancies }) => {
  const [editingTitle, setEditingTitle] = useState('');

  const [updateJobVacancy, { data }] = useMutation(UPDATE_JOB_VACANCY);
  useEffect(() => {
    setEditingTitle(jobVacancy?.position ?? '');
  }, [jobVacancy]);

  const onClickRightButton = () => {
    if (isCreating) {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            position: editingTitle,
            step: JobVacancyStep.managerEmail,
          },
        },
      });
    } else {
      updateJobVacancy({
        variables: {
          id: jobVacancy.id,
          jobVacancyInput: {
            position: editingTitle,
          },
        },
      });
    }
  };
  useEffect(() => {
    if (data) {
      getJobVacancies();
    }
  }, [data]);
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>채용 포지션을 작성해주세요.</p>
      </TitleLaout>
      <StyledInput
        value={editingTitle}
        onChange={(e) => setEditingTitle(e.currentTarget.value)}
        placeholder="입력해주세요"
      />
      <ButtonLayout>
        {isCreating ? (
          <BottomRightButton
            onClick={onClickRightButton}
            disabled={editingTitle === ''}
            type="primary"
          >
            다음
          </BottomRightButton>
        ) : (
          <BottomCenterButton
            onClick={onClickRightButton}
            disabled={editingTitle === ''}
            type="primary"
          >
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default JobVacancyTitle;
