import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { useEffect } from 'react';
import { Button, Input } from 'antd';
import { Tag } from 'antd';
import {
  ADD_STUDENT_SKILL,
  ADD_STUDENT_TEMPORARY_SKILL,
  DELETE_STUDENT_SKILL,
  DELETE_STUDENT_TEMPORARY_SKILL,
  GET_SKILLS,
  SEARCH_SKILLS,
} from '../../../../queries/skill';
import { CheckOutlined, SearchOutlined } from '@ant-design/icons';
import { StudentProfileStep } from '../../../../constants/static';
import { groupBy } from '../../../../constants/utils';
import { isMobile } from 'react-device-detect';
import Popover from '../../../../components/Popover';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const SkillboxLayout = styled.div`
  margin: ${isMobile ? '24px' : '0 90px 31px'};
  background: #fcfcfc;
  border-radius: 15px;
  min-height: 107px;
  padding: 10px 0 22px;
  border: 1px solid #fe706f;
`;

const SkillboxTitie = styled.p`
  margin-left: 22px;
  font-weight: 500;
  font-size: 14px;
  color: #171725;
`;

const TagLayout = styled.div`
  padding: 0 30px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 11px 0 22px;
  background-color: #ebebeb;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: ${isMobile ? '60px 60px 0' : '0 60px'};
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const StyledTag = styled(Tag)`
  border-radius: 4px;
  background-color: #fe706f;
  height: 22px;
  border: none;
  color: white;
  margin-bottom: 4px;
  svg {
    color: white;
  }
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const SearchInputLayout = styled.div`
  display: flex;
  margin: 0 auto auto;
  border-radius: 10px;
  padding: ${isMobile ? '0 24px' : '0'};
  width: ${isMobile ? '100%' : '539px'};
  height: 40px;
  overflow: hidden;
  input {
    border-radius: 10px 0 0 10px;
    width: ${isMobile ? '' : '500px'};
    font-size: 14px;
  }
  button {
    border-radius: 0 10px 10px 0;
    height: 40px;
  }
`;

const SearchPopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow-y: scroll;
  padding: 8px 16px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border: 1px solid #f1f1f5;
  p {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 0;
  }
`;

const DisabledText = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0;
  color: #8c8c8c;
`;

const Skills = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const { data: skillSet } = useQuery(GET_SKILLS);
  const [skillDetails, setSkillDetails] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);

  useEffect(() => {
    setSkillDetails((prev) =>
      prev.concat(profile?.skills ?? []).concat(profile?.temporarySkills ?? []),
    );
  }, [profile]);

  const handleClickLeftButton = () => {
    if (skillDetails.length > 0) {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: isMobile
              ? StudentProfileStep.educations
              : StudentProfileStep.recruitmentTypes,
          },
        },
      });
    } else {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: isMobile
              ? StudentProfileStep.educations
              : StudentProfileStep.recruitmentTypes,
          },
        },
      });
    }
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.projects,
        },
      },
    });
  };
  const [searchSkills, { data: searchSkillsData }] =
    useLazyQuery(SEARCH_SKILLS);
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  const [searchValue, setSearchValue] = useState('');
  const buttonRef = useRef(null);
  const [searchPopoverOpened, setSearchPopoverOpened] = useState(false);
  const [addStudentSkill] = useMutation(ADD_STUDENT_SKILL, {
    onCompleted: (data) => {
      setSkillDetails((prev) => prev.concat(data.addStudentSkill));
    },
  });
  const [addStudentTemporarySkill] = useMutation(ADD_STUDENT_TEMPORARY_SKILL, {
    onCompleted: (data) => {
      setSkillDetails((prev) => prev.concat(data.addStudentTemporarySkill));
    },
  });
  const [deleteStudentSkill] = useMutation(DELETE_STUDENT_SKILL, {
    onCompleted: (data) => {
      setSkillDetails((prev) =>
        prev.filter((item) => item.id !== data.deleteStudentSkill),
      );
    },
  });
  const [deleteStudentTemporarySkill] = useMutation(
    DELETE_STUDENT_TEMPORARY_SKILL,
    {
      onCompleted: (data) => {
        setSkillDetails((prev) =>
          prev.filter((item) => item.id !== data.deleteStudentTemporarySkill),
        );
      },
    },
  );
  return (
    <Layout>
      <SkillboxLayout>
        <SkillboxTitie>보유 스킬</SkillboxTitie>
        <Divider />
        <TagLayout>
          {skillDetails?.map((skill) => (
            <StyledTag
              key={skill.id}
              closable
              onClose={(e) => {
                e.preventDefault();
                if (skill?.field === undefined) {
                  deleteStudentTemporarySkill({
                    variables: {
                      studentId: id,
                      temporarySkillId: skill.id,
                    },
                  });
                } else {
                  deleteStudentSkill({
                    variables: {
                      studentId: id,
                      skillId: skill.id,
                    },
                  });
                }
              }}
            >
              <span>
                {skill.field
                  ? `${skill?.field}/${skill.skillDetail}`
                  : `기타연구분야/${skill.skillDetail}`}
              </span>
            </StyledTag>
          ))}
        </TagLayout>
      </SkillboxLayout>
      <SearchInputLayout>
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.currentTarget.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              searchSkills({
                variables: {
                  keyword: searchValue,
                },
              });
              setSearchPopoverOpened(true);
            }
          }}
          placeholder="분야/스킬명을 검색해주세요."
        />
        <Button
          ref={buttonRef}
          disabled={searchValue === ''}
          type="primary"
          onClick={() => {
            searchSkills({
              variables: {
                keyword: searchValue,
              },
            });
            setSearchPopoverOpened(true);
          }}
        >
          <SearchOutlined />
        </Button>
        <Popover
          open={searchPopoverOpened}
          width={isMobile ? '240px' : '539px'}
          height="154px"
          onClose={() => setSearchPopoverOpened(false)}
          anchorRef={buttonRef}
          horizontalAlign="right"
          verticalAlign="bottom"
          removePadding
          borderRadius="0 0 6px 6px"
        >
          <SearchPopoverLayout>
            {searchSkillsData?.searchSkills?.length === 0 && (
              <DisabledText>결과 없음</DisabledText>
            )}
            {searchSkillsData?.searchSkills?.map((item) => (
              <p
                style={{
                  cursor:
                    skillDetails?.filter((a) => a.id === item.id)?.length === 0
                      ? 'pointer'
                      : 'default',
                  color:
                    skillDetails?.filter((a) => a.id === item.id)?.length === 0
                      ? 'grey'
                      : '#000',
                }}
                onClick={() => {
                  if (
                    skillDetails?.filter((a) => a.id === item.id)?.length === 0
                  ) {
                    addStudentSkill({
                      variables: {
                        studentId: id,
                        skillId: item.id,
                      },
                    });
                  }
                }}
              >
                {`${item.field}/${item.skillDetail}`}
              </p>
            ))}
            <button
              style={{
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
                color: '#fe706f',
                padding: 0,
                fontSize: 12,
              }}
              onClick={() => {
                addStudentTemporarySkill({
                  variables: {
                    studentId: id,
                    skillDetail: searchValue,
                  },
                });
                setSearchPopoverOpened(false);
                setSearchValue('');
              }}
            >
              직접 추가하기
            </button>
          </SearchPopoverLayout>
        </Popover>
      </SearchInputLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            type="primary"
            onClick={handleClickRightButton}
            disabled={skillDetails.length === 0}
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={skillDetails?.length === 0}
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Skills;
