import React, { useState } from 'react';
import {
  LeftOutlined,
  RightOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { Button, Divider } from 'antd';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  padding: 43px 32px 31px;
`;

const Title = styled.p`
  margin-left: ${isMobile ? 0 : '31px'};
  margin-bottom: 34px;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
`;

const TabLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '0 0 24px 0' : '32px 40px'};
  p {
    margin-right: ${isMobile ? '60px' : '137px'};
    font-family: Noto Sans KR;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #fe706f;
  }
  border-bottom: ${isMobile ? '' : '2px solid #e5e5e5'};
`;

const BodyLayout = styled.div`
  height: 448px;
  display: flex;
  align-items: center;
`;

const ItemLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '20px 0' : '25px 40px'};
  border-bottom: 1px solid #e5e5e5;
`;

const NameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  width: 170px;
  margin-right: 12px;
`;

const DateLayout = styled.div`
  display: flex;
  flex-flow: wrap;
  width: 320px;
  div {
    padding: 2px 8px;
    border: 1px solid #f2f2f2;
    font-size: 14px;
    color: #262626;
    font-weight: 500;
    margin-right: 11px;
    margin-bottom: 8px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const ImageLayout = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 10px;
  position: relative;
  background-color: #fbfbfb;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 48px;
    max-width: 48px;
  }
  margin-right: 17px;
`;

const ArrowButtonLayout = styled.div`
  width: ${isMobile ? '24px' : '56px'};
  margin-bottom: 120px;
`;

const ParticipatedCompaniesModal = ({ companies }) => {
  console.log(companies);
  const [showingIndex, setShowingIndex] = useState(0);
  useState(0);
  return (
    <Layout>
      <Title>참가 기업 리스트</Title>
      <BodyLayout>
        <ArrowButtonLayout>
          <Button
            type="link"
            style={{ padding: isMobile ? 0 : '' }}
            onClick={() => setShowingIndex((prev) => prev - 1)}
            disabled={showingIndex === 0}
          >
            <LeftOutlined style={{ fontSize: isMobile ? '22px' : '24px' }} />
          </Button>
        </ArrowButtonLayout>
        <div
          style={{
            flex: 1,
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontWeight: 500,
              fontSize: '16px',
              marginBottom: '24px',
            }}
          >
            {companies[showingIndex]?.[0]}
          </p>
          <div style={{ flex: 1, overflowY: 'scroll' }}>
            {[...(companies[showingIndex]?.[1] ?? [])]
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              ?.map((company, i) => (
                <>
                  <div
                    style={{
                      height: '64px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 12px',
                    }}
                  >
                    <ImageLayout>
                      {company.logo && <img src={company.logo} alt="logo" />}
                    </ImageLayout>

                    <span>{company.name}</span>
                  </div>
                  <div
                    style={{
                      height: 1,
                      backgroundColor: '#efefef',
                      margin: '0 12px',
                    }}
                  />
                </>
              ))}
          </div>
        </div>
        <ArrowButtonLayout>
          <Button
            type="link"
            style={{ padding: isMobile ? 0 : '' }}
            onClick={() => setShowingIndex((prev) => prev + 1)}
            disabled={showingIndex >= companies.length - 1}
          >
            <RightOutlined style={{ fontSize: isMobile ? '22px' : '24px' }} />
          </Button>
        </ArrowButtonLayout>
      </BodyLayout>
    </Layout>
  );
};

export default ParticipatedCompaniesModal;
