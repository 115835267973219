import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Switch } from 'antd';
import {
  DownOutlined,
  EditOutlined,
  RightOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import { Color } from '../../constants/color';
import { JobVacancyStep } from '../../constants/static';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  COMPLETED_JOB_VACANCIES_BY_COMPANY,
  JOB_VACANCIES_BY_COMPANY,
  UPDATE_JOB_VACANCY,
} from '../../queries/jobVacancy';
import useAuth from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import { LATEST_MATCHING_APPLICATION_BY_COMPANY } from '../../queries/matching';
import { isMobile } from 'react-device-detect';
import Icon from '../../components/Icon';
import BannerIllust from '../../img/MatchingBannerIllust.png';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const JobVacanciesLayout = styled.div`
  padding: 0 60px;
  margin-top: 24px;
`;

const JobVacancyItem = styled.div`
  width: 100%;
  border: ${(props) =>
    props.isEditing ? '1.5px dashed #ffccc7' : '1.5px solid  #fe706f'};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background-color: white;
  margin-bottom: 20px;
`;

const JobVacancyItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const JobVacancyItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: 240px;
`;

const RightButtonLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  button {
    margin: 0 12px;
  }
`;

const JobVacancyBodyLayout = styled.div`
  padding: 22px 0 34px;
  height: 410px;
  overflow-y: scroll;
`;

const JobVacancyContentLayout = styled.div`
  display: flex;
  padding: 0 66px 0 77px;
`;

const JobVacancyContentTitle = styled.p`
  min-width: 84x !important;
  width: 84px !important;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: #f0f0f0;
`;

const JobVacancyBody = styled.div`
  margin-left: 88px;
  margin-top: 4px;
  white-space: pre-wrap;
  p {
    span {
      font-weight: 500;
    }
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 567px;
  }
`;

const SkillBody = styled.div`
  margin-left: 88px;
  margin-top: 4px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 20px;
    margin-bottom: 8px;
    height: 32px;
    border-radius: 5px;
    background-color: #fbfbfb;
    padding: 5px 16px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2e2e2e;
  }
`;

const AddJobVacancyButton = styled(Button)`
  display: flex;
  width: 161px;
  height: 47px;
  align-items: center;
  border-radius: 15px;
  font-weight: 700;
  padding: 0 16px;
  margin: 56px auto 24px auto;
  background-color: #ff4d4f;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  height: ${isMobile ? '288px' : ''};
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: ${isMobile ? '35px 23px' : '37px 48px'};
  img {
    right: ${isMobile ? '-8px' : '27px'};
    bottom: ${isMobile ? '44px' : '18px'};
    width: ${isMobile ? '148px' : '220px'};
    height: ${isMobile ? '148px' : '222px'};
    position: absolute;
    z-index: 0;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  position: ${isMobile ? 'absolute' : ''};
  z-index: 2;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: ${isMobile ? '140px' : '34px'};
  width: 226px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;
const PostJobVacancy = () => {
  const [openedJobVacancyId, setOpenedJobVacancyId] = useState(0);
  const [getJobVacanciesByCompany, { data: getJobVacanciesByCompanyData }] =
    useLazyQuery(COMPLETED_JOB_VACANCIES_BY_COMPANY);
  const [updateJobVacancy, { data: updateJobVacancyData }] =
    useMutation(UPDATE_JOB_VACANCY);
  const { user } = useAuth();
  const history = useHistory();
  useEffect(() => {
    getJobVacanciesByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user, getJobVacanciesByCompany]);
  const [postingJobVacancyId, setPostingJobVacancyId] = useState(0);
  const [depostingJobVacancyId, setDePostingJobVacancyId] = useState(0);
  useEffect(() => {
    if (updateJobVacancyData) {
      setPostingJobVacancyId(0);
      setDePostingJobVacancyId(0);
      getJobVacanciesByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [updateJobVacancyData, user.id]);
  const [
    latestMatchingApplicationByCompany,
    { data: latestMatchingApplicationByCompanyData },
  ] = useLazyQuery(LATEST_MATCHING_APPLICATION_BY_COMPANY);
  useEffect(() => {
    if (user.id > 0) {
      latestMatchingApplicationByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [user.id, latestMatchingApplicationByCompany]);
  return (
    <Layout>
      <TitleText>채용 공고 게시</TitleText>
      {latestMatchingApplicationByCompanyData !== undefined && (
        <>
          {latestMatchingApplicationByCompanyData?.latestMatchingApplicationByCompany ===
            null ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'PENDING' ||
          latestMatchingApplicationByCompanyData
            ?.latestMatchingApplicationByCompany?.state === 'DECLINED' ? (
            <AnnounceLayout>
              <img src={BannerIllust} alt="banner" />
              <AnnounceTitleLayout>
                <Icon icon={'edit'} size="21px" color="#ff434e" />
                <p>
                  {latestMatchingApplicationByCompanyData
                    ?.latestMatchingApplicationByCompany?.state === 'PENDING'
                    ? '매칭 서비스 신청 승인 대기 중입니다'
                    : '매칭 서비스를 신청해주세요!'}
                </p>
              </AnnounceTitleLayout>
              <AnnounceBodyText>
                {latestMatchingApplicationByCompanyData
                  ?.latestMatchingApplicationByCompany?.state === 'PENDING' ? (
                  <>
                    <br />
                    조금만 기다려주세요!
                    <br />
                    승인 완료 후 해당 기능 사용 가능합니다.
                    <br />
                  </>
                ) : (
                  <>
                    아직 매칭 서비스를 신청하지 않으셨네요!
                    <br />
                    매칭 서비스를 신청하여 인재를 만나볼 기회를 넓혀보세요.
                  </>
                )}
              </AnnounceBodyText>
              {latestMatchingApplicationByCompanyData
                ?.latestMatchingApplicationByCompany?.state !== 'PENDING' && (
                <AnnounceBottomButton
                  onClick={() => {
                    history.push('/company-meeting/apply-matching');
                  }}
                >
                  <p>매칭서비스 신청하러가기</p>
                  <Icon icon={'edit'} size="21px" color="#ffffff" />
                </AnnounceBottomButton>
              )}
            </AnnounceLayout>
          ) : (
            <>
              <AddJobVacancyButton
                type="primary"
                onClick={() => {
                  history.push('/hire/manage-hire');
                }}
              >
                채용 공고 관리
                <EditOutlined
                  style={{ fontSize: '23px', marginLeft: '16px' }}
                />
              </AddJobVacancyButton>
              <JobVacanciesLayout>
                {getJobVacanciesByCompanyData?.completedJobVacanciesByCompany
                  ?.filter((item) => item.step === JobVacancyStep.complete)
                  .map((item) => (
                    <JobVacancyItem
                      key={item.id}
                      opened={openedJobVacancyId === item.id}
                      onClick={() => {
                        setOpenedJobVacancyId(
                          openedJobVacancyId === item.id ? 0 : item.id,
                        );
                      }}
                    >
                      <JobVacancyItemTitleLayout
                        opened={openedJobVacancyId === item.id}
                      >
                        {openedJobVacancyId === item.id ? (
                          <DownOutlined
                            style={{
                              fontSize: '24px',
                              color:
                                item.step === JobVacancyStep.complete
                                  ? 'black'
                                  : '#ffffff',
                            }}
                          />
                        ) : (
                          <RightOutlined
                            style={{
                              fontSize: '24px',
                              color:
                                item.step === JobVacancyStep.complete
                                  ? '#fe706f'
                                  : '#ffffff',
                            }}
                          />
                        )}
                        <JobVacancyItemTitle
                          opened={openedJobVacancyId === item.id}
                        >
                          {item.position}
                        </JobVacancyItemTitle>
                        <RightButtonLayout>
                          <p
                            style={{
                              fontSize: '12px',
                              color: '#aaaaaa',
                              marginRight: '20px',
                            }}
                          >
                            게시 ON / OFF
                          </p>
                          <Switch
                            size="small"
                            checked={item.onMatching}
                            onChange={(checked, e) => {
                              e.stopPropagation();
                              if (checked) {
                                setPostingJobVacancyId(item.id);
                              } else {
                                setDePostingJobVacancyId(item.id);
                              }
                            }}
                          />
                        </RightButtonLayout>
                      </JobVacancyItemTitleLayout>
                      {openedJobVacancyId === item.id && (
                        <JobVacancyBodyLayout>
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              담당자 이메일
                            </JobVacancyContentTitle>

                            <JobVacancyBody>
                              <p>{item.managerEmail}</p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              세부 업무 내용
                            </JobVacancyContentTitle>

                            <JobVacancyBody>
                              <p>
                                <span>근무지역 :</span> {item.workplace} <br />
                                <br />
                                {item.task}
                              </p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              지원 자격 및 우대사항
                            </JobVacancyContentTitle>

                            <JobVacancyBody>
                              <p>
                                <span>필요학위 :</span> {item.requiredDegree}{' '}
                                <br />
                                <br />
                                {item.requiredKnowledge}
                              </p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              연구 분야 스킬
                            </JobVacancyContentTitle>

                            <SkillBody>
                              {item.skills.map((item, index) => (
                                <div key={index}>
                                  {item.field}/{item.skillDetail}
                                </div>
                              ))}
                            </SkillBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              근무요건
                            </JobVacancyContentTitle>

                            <JobVacancyBody>
                              <p>{item.environment}</p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              채용절차
                            </JobVacancyContentTitle>

                            <JobVacancyBody>
                              <p>
                                <span>
                                  {item.recruitmentSchedule === '상시채용' &&
                                    '상시채용'}
                                  {item.recruitmentSchedule !== '상시채용' && (
                                    <>
                                      {item.recruitmentSchedule.split(' ~ ')[0]}
                                      <SwapRightOutlined
                                        style={{
                                          fontSize: '16px',
                                          color: '#bfbfbf',
                                          margin: '0 8px',
                                        }}
                                      />
                                      {item.recruitmentSchedule.split(' ~ ')[1]}
                                    </>
                                  )}
                                </span>
                                <br />
                                <br />
                                {item.recruitmentProcess}
                              </p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                          <Divider />
                          <JobVacancyContentLayout>
                            <JobVacancyContentTitle>
                              게시 학교
                            </JobVacancyContentTitle>
                            <JobVacancyBody>
                              <p>
                                {item.approvedUniversities.reduce(
                                  (acc, cur, index) =>
                                    acc + (index === 0 ? '' : ', ') + cur.name,
                                  '',
                                )}
                              </p>
                            </JobVacancyBody>
                          </JobVacancyContentLayout>
                        </JobVacancyBodyLayout>
                      )}
                    </JobVacancyItem>
                  ))}
              </JobVacanciesLayout>
              <Modal
                isOpen={postingJobVacancyId > 0}
                onClose={() => setPostingJobVacancyId(0)}
                radius="30px"
                removeCloseButton
              >
                <SmallModalLayout
                  title="채용 공고 게시"
                  description={
                    <span>
                      매칭 서비스에 채용 공고가 게시되어
                      <br />
                      인재가 해당 채용 공고에 지원할 수 있습니다.
                      <br />
                      게재하시겠습니까?
                    </span>
                  }
                  onClickLeftButton={() => setPostingJobVacancyId(0)}
                  onClickRightButton={() => {
                    updateJobVacancy({
                      variables: {
                        id: postingJobVacancyId,
                        jobVacancyInput: {
                          onMatching: true,
                        },
                      },
                    });
                  }}
                />
              </Modal>
              <Modal
                isOpen={depostingJobVacancyId > 0}
                onClose={() => setDePostingJobVacancyId(0)}
                radius="30px"
                removeCloseButton
              >
                <SmallModalLayout
                  title="채용 공고 게시 취소"
                  description={
                    <span>
                      게재된 채용 공고를 매칭서비스에서
                      <br />
                      더 이상 볼 수 없게 됩니다.
                      <br />
                      게재를 취소 하시겠습니까?
                    </span>
                  }
                  onClickLeftButton={() => setDePostingJobVacancyId(0)}
                  onClickRightButton={() => {
                    updateJobVacancy({
                      variables: {
                        id: depostingJobVacancyId,
                        jobVacancyInput: {
                          onMatching: false,
                        },
                      },
                    });
                  }}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default PostJobVacancy;
