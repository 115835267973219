import { useLazyQuery } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  GET_APPROVED_ADVERTISEMENTS,
  GET_RANDOM_REP_ADVERTISEMENT,
} from '../../queries/advertisement';
import AdvertisementDefault from './Advertisement';
import AdvertisementDetail from './AdvertisementDetail';

export const AdvertisementContext = createContext({});

const Advertisement = () => {
  const [filter, setFilter] = useState({
    size: [],
    domainIds: [],
    recruitmentTypes: [],
  });
  const [getFinished, setGetFinished] = useState(false);
  const [sort, setSort] = useState('최신순');
  const [scrollItem, setScrollItem] = useState('');
  const [listAdvertisements, setListAdvertisements] = useState([]);
  const [
    getApprovedAdvertisements,
    { data: getApprovedAdvertisementsData, loading },
  ] = useLazyQuery(GET_APPROVED_ADVERTISEMENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setListAdvertisements((prev) => {
        if (
          prev.findIndex(
            (item) => item?.id === data?.approvedAdvertisements?.[0]?.id,
          ) === -1
        ) {
          return [...prev, ...data?.approvedAdvertisements];
        }
        return prev;
      });
      if (data?.approvedAdvertisements?.length < 30) {
        setGetFinished(true);
      }
    },
  });
  const [getRandomRepAdvertisement, { data: getRandomRepAdvertisementData }] =
    useLazyQuery(GET_RANDOM_REP_ADVERTISEMENT);
  const [randomRepState, setRandomRepState] = useState(null);
  useEffect(() => {
    setRandomRepState(getRandomRepAdvertisementData?.randomRepAdvertisement);
  }, [getRandomRepAdvertisementData?.randomRepAdvertisement]);
  return (
    <AdvertisementContext.Provider
      value={{
        getFinished,
        setGetFinished,
        sort,
        setSort,
        filter,
        setFilter,
        getApprovedAdvertisements,
        listAdvertisements,
        setListAdvertisements,
        getApprovedAdvertisementsData,
        getRandomRepAdvertisement,
        getRandomRepAdvertisementData,
        randomRepState,
        setRandomRepState,
        loading,
        scrollItem,
        setScrollItem,
      }}
    >
      <Switch>
        <Route exact path="/advertisement">
          <AdvertisementDefault />
        </Route>
        <Route exact path="/advertisement/:advertisementId">
          <AdvertisementDetail />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </AdvertisementContext.Provider>
  );
};

export default Advertisement;
