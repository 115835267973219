import { BookOutlined, LeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  APPLY_PRESENTATION,
  CANCEL_APPLY_PRESENTATION,
  GET_IS_STUDENT_APPLIED,
  GET_PRESENTATION,
} from '../../queries/presentation';
import useAuth from '../../hooks/useAuth';
import { UserType } from '../../constants/static';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import moment from 'moment';
import 'react-quill/dist/quill.bubble.css';
import ReactQuill from 'react-quill';
import { GET_STUDENT_PROFILE } from '../../queries/student';
import EventModal from '../Fair/components/EventModal';

const Layout = styled.div`
  height: 100%;
  overflow-y: scroll;
  .ql-editor {
    font-size: ${isMobile ? '14px' : '18px'};
  }
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
  button {
    outline: none;
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    cursor: pointer;
    p {
      margin-left: 9px;
      font-weight: 500;
      color: #aaaaaa;
      font-size: 20px;
    }
  }
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding: ${isMobile ? '84px 4px 0' : '84px 64px 0 64px'};
  position: relative;
`;

const ContentLayout = styled.div`
  margin: ${isMobile ? '0' : '40px 0'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0 0 35px' : '0 18px 35px'};
  height: ${isMobile ? '' : 'calc(100vh - 220px)'};
  overflow-y: scroll;
  margin-bottom: 32px;
`;

const TitleLayout = styled.div`
  padding: ${isMobile ? '16px 30px 8px' : '35px 18px 0'};
  position: fixed;
  z-index: 800;
  width: ${isMobile ? '100%' : 'calc(100% - 244px)'};
  height: ${isMobile ? '' : '78px'};
  background-color: white;
  p {
    margin-left: ${isMobile ? '' : '35px'};
    font-weight: 700;
    margin-bottom: ${isMobile ? '13px' : ''};
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.0032em;
    text-align: left;
  }
  border-bottom: 1px solid #f1f1f1;
`;

const TopLayout = styled.div`
  width: 100%;
  margin-top: ${isMobile ? '12px' : '32px'};
  margin-bottom: 24px;
  display: ${isMobile ? 'flex' : 'flex'};
  align-items: center;
  justify-content: center;
  flex-flow: ${isMobile ? 'column' : 'row'};
  img {
    width: ${isMobile ? '90vw' : '467px'};
    height: ${isMobile ? '60vw' : '300px'};
    border-radius: 10px;
    object-fit: cover;
    margin-right: ${isMobile ? '' : '76px'};
  }
`;

const Badge = styled.div`
  height: 22px;
  border-radius: 35px;
  padding: 0 8px;
  background-color: ${(props) =>
    props.isRejected ? '#f2f2f2' : props.isOnline ? '#fff7e6' : '#F9F0FF'};
  border: 1px solid ${(props) => (props.isOnline ? '#ffa171' : '#D3ADF7')};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) => (props.isOnline ? '#ffa171' : '#722ED1')};
`;

const TopRightLayout = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: ${isMobile ? '16px' : ''};
  p {
    width: 100px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    min-width: 100px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 14px;
  }
  span {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    width: ${isMobile ? 'calc(100vw - 166px)' : '280px'};
    max-width: ${isMobile ? 'calc(100vw - 166px)' : '280px'};
  }
`;

const PresentationDetail = () => {
  const now = moment().startOf('day');
  const history = useHistory();
  const location = useLocation();
  const [getPresentation, { data: getPresentationData }] =
    useLazyQuery(GET_PRESENTATION);
  const [getIsStudentApplied, { data: getIsStudentAppliedData, loading }] =
    useLazyQuery(GET_IS_STUDENT_APPLIED);
  const { user } = useAuth();

  useEffect(() => {
    const id = location.pathname.split('/')?.[2];
    if (id) {
      getPresentation({
        variables: {
          id,
        },
      });
      getIsStudentApplied({
        variables: {
          presentationId: id,
          studentId: user.id,
        },
      });
    }
  }, [location, getPresentation, getIsStudentApplied, user.id]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [modalState, setModalState] = useState('default');
  useEffect(() => {
    if (!isModalOpened) {
      setTimeout(() => {
        setModalState('default');
      }, 100);
    }
  }, [isModalOpened]);
  const [applyPresentation] = useMutation(APPLY_PRESENTATION, {
    onCompleted: (data) => {
      const id = location.pathname.split('/')?.[2];
      getPresentation({
        variables: {
          id,
        },
      });
      getIsStudentApplied({
        variables: {
          presentationId: id,
          studentId: user.id,
        },
      });
      if (data?.applyPresentation === true) {
        setModalState('success');
      } else {
        setModalState('fail');
      }
    },
  });
  const [cancelApplyPresentation] = useMutation(CANCEL_APPLY_PRESENTATION, {
    onCompleted: (data) => {
      const id = location.pathname.split('/')?.[2];
      getPresentation({
        variables: {
          id,
        },
      });
      getIsStudentApplied({
        variables: {
          presentationId: id,
          studentId: user.id,
        },
      });
      setIsCancelModalOpened(false);
    },
  });
  const disabledUniversity = useMemo(() => {
    let domains = [];
    getPresentationData?.presentation?.universities?.forEach(
      (univ) => (domains = domains.concat(univ.domain.split(','))),
    );
    console.log(domains);

    if (
      domains.length > 0 &&
      domains.findIndex((item) => item === user.email.split('@')[1]) === -1
    ) {
      return true;
    }
    return false;
  }, [getPresentationData?.presentation?.universities, user.email]);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);
  const [isEventModalOpened, setIsEventModalOpened] = useState(false);

  const [getStudentProfile, { data: getStudentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  useEffect(() => {
    if (user.userType === UserType.student) {
      getStudentProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [user, getStudentProfile]);
  return (
    <Layout>
      <Header>
        <button onClick={() => history.push('/presentation')}>
          <LeftOutlined style={{ fontSize: '24px', color: '#aaaaaa' }} />
          <p>목록</p>
        </button>
      </Header>
      <BodyLayout>
        <ContentLayout>
          <TitleLayout>
            <p>{getPresentationData?.presentation.title}</p>
          </TitleLayout>
          <BodyLayout>
            <TopLayout>
              <img
                src={getPresentationData?.presentation.image?.url}
                alt="thumbnail"
              />
              <div>
                <TopRightLayout>
                  <p>진행일시</p>
                  <span>
                    {getPresentationData?.presentation.date}{' '}
                    {getPresentationData?.presentation?.startAt} -{' '}
                    {getPresentationData?.presentation?.endAt}
                  </span>
                </TopRightLayout>
                <TopRightLayout>
                  <p>신청 가능 인원</p>
                  <span>
                    {getPresentationData?.presentation?.personnel} /{' '}
                    {getPresentationData?.presentation?.maxPersonnel}
                  </span>
                </TopRightLayout>
                <TopRightLayout>
                  <p>온라인 여부</p>
                  <Badge isOnline={getPresentationData?.presentation?.isOnline}>
                    {getPresentationData?.presentation?.isOnline
                      ? '온라인'
                      : '오프라인'}
                  </Badge>
                </TopRightLayout>
                {!isMobile && (
                  <>
                    {user.userType === UserType.student && (
                      <>
                        {getIsStudentAppliedData?.isStudentApplied ? (
                          <Button
                            type="primary"
                            style={{
                              width: '176px',
                              height: '53px',
                              borderRadius: '10px',
                              marginTop: '15px',
                              fontWeight: 700,
                            }}
                            onClick={() => {
                              setIsCancelModalOpened(true);
                            }}
                            disabled={loading}
                          >
                            설명회 참가 신청 취소
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            style={{
                              width: '168px',
                              height: '53px',
                              borderRadius: '10px',
                              marginTop: '15px',
                              fontWeight: 700,
                            }}
                            onClick={() => {
                              if (
                                Boolean(
                                  getStudentProfileData?.student
                                    ?.basicStudentProfile?.name,
                                )
                              ) {
                                setIsModalOpened(true);
                              } else {
                                setIsWarningModalOpened(true);
                              }
                            }}
                            disabled={
                              loading ||
                              !getIsStudentAppliedData ||
                              moment(getPresentationData?.presentation?.date) <
                                now ||
                              disabledUniversity ||
                              getPresentationData?.presentation?.personnel >=
                                getPresentationData?.presentation?.maxPersonnel
                            }
                          >
                            설명회 참가 신청
                          </Button>
                        )}
                        {getPresentationData?.presentation?.event && (
                          <Button
                            style={{
                              width: '168px',
                              height: '53px',
                              marginLeft: '8px',
                              marginRight: '8px',
                              borderRadius: '10px',
                              marginTop: '15px',
                              fontWeight: 700,
                            }}
                            type="primary"
                            onClick={() => setIsEventModalOpened(true)}
                          >
                            이벤트
                          </Button>
                        )}
                        {getPresentationData?.presentation?.surveyLink && (
                          <Button
                            style={{
                              width: '168px',
                              height: '53px',
                              borderRadius: '10px',
                              marginTop: '15px',
                              fontWeight: 700,
                            }}
                            onClick={() => {
                              window.open(
                                getPresentationData?.presentation?.surveyLink,
                                '_blank',
                              );
                            }}
                            type="primary"
                          >
                            설문조사
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {isMobile && user.userType === UserType.student && (
                <>
                  {getIsStudentAppliedData?.isStudentApplied ? (
                    <Button
                      type="primary"
                      style={{
                        width: '176px',
                        height: '53px',
                        borderRadius: '10px',
                        marginTop: '15px',
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        setIsCancelModalOpened(true);
                      }}
                      disabled={loading}
                    >
                      설명회 참가 신청 취소
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      style={{
                        width: '176px',
                        height: '53px',
                        borderRadius: '10px',
                        marginTop: '15px',
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        if (
                          Boolean(
                            getStudentProfileData?.student?.basicStudentProfile
                              ?.name,
                          )
                        ) {
                          setIsModalOpened(true);
                        } else {
                          setIsWarningModalOpened(true);
                        }
                      }}
                      disabled={
                        loading ||
                        !getIsStudentAppliedData ||
                        moment(getPresentationData?.presentation?.date) < now ||
                        disabledUniversity ||
                        getPresentationData?.presentation?.personnel >=
                          getPresentationData?.presentation?.maxPersonnel
                      }
                    >
                      설명회 참가 신청
                    </Button>
                  )}
                  {getPresentationData?.presentation?.event && (
                    <Button
                      style={{
                        width: '176px',
                        height: '53px',
                        borderRadius: '10px',
                        marginTop: '8px',
                        fontWeight: 700,
                      }}
                      type="primary"
                      onClick={() => setIsEventModalOpened(true)}
                    >
                      이벤트
                    </Button>
                  )}
                  {getPresentationData?.presentation?.surveyLink && (
                    <Button
                      style={{
                        width: '176px',
                        height: '53px',
                        borderRadius: '10px',
                        marginTop: '8px',
                        fontWeight: 700,
                      }}
                      onClick={() => {
                        window.open(
                          getPresentationData?.presentation?.surveyLink,
                          '_blank',
                        );
                      }}
                      type="primary"
                    >
                      설문조사
                    </Button>
                  )}
                </>
              )}
            </TopLayout>
            <ReactQuill
              value={getPresentationData?.presentation?.content ?? ''}
              readOnly
              theme="bubble"
            />
          </BodyLayout>
        </ContentLayout>
      </BodyLayout>
      <Modal isOpen={isModalOpened} onClose={() => setIsModalOpened(false)}>
        <SmallModalLayout
          setDescriptionCenter
          height="305px"
          title={
            modalState === 'default'
              ? '설명회 신청'
              : modalState === 'success'
              ? '설명회 신청 완료'
              : '설명회 신청 실패'
          }
          description={
            modalState === 'default'
              ? `${getPresentationData?.presentation.date} ${getPresentationData?.presentation?.startAt} - ${getPresentationData?.presentation?.endAt}에 진행되는\n${getPresentationData?.presentation?.title}를\n신청하시겠습니까?`
              : modalState === 'success'
              ? `가입하신 이메일로 설명회에 대한 안내가\n발송되었습니다.`
              : `정원 초과로 설명회 참가 신청해 실패\n했습니다.`
          }
          removeLeftButton={modalState !== 'default'}
          onClickLeftButton={() => setIsModalOpened(false)}
          onClickRightButton={() => {
            if (modalState === 'default') {
              applyPresentation({
                variables: {
                  studentId: user.id,
                  presentationId: getPresentationData?.presentation?.id,
                },
              });
            } else {
              setIsModalOpened(false);
            }
          }}
        />
      </Modal>
      <Modal
        isOpen={isCancelModalOpened}
        onClose={() => setIsCancelModalOpened(false)}
      >
        <SmallModalLayout
          setDescriptionCenter
          height="305px"
          title={'설명회 참가 신청 취소'}
          description={'참가 신청을 취소하시겠습니까?'}
          onClickLeftButton={() => setIsCancelModalOpened(false)}
          onClickRightButton={() => {
            cancelApplyPresentation({
              variables: {
                studentId: user.id,
                presentationId: getPresentationData?.presentation?.id,
              },
            });
          }}
        />
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => setIsWarningModalOpened(false)}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
      <Modal
        closeButtonTransparent
        radius={'30px'}
        width={isMobile ? '343px' : '969px'}
        height={isMobile ? '527px' : '730px'}
        isOpen={isEventModalOpened}
        onClose={() => setIsEventModalOpened(false)}
      >
        <EventModal event={getPresentationData?.presentation?.event} />
      </Modal>
    </Layout>
  );
};

export default PresentationDetail;
