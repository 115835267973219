import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import CompanyProfileModal from '../../components/CompanyProfileModal';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import SmallModalWithTextareaLayout from '../../components/SmallModalWithTextareaLayout';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { LOOK_UP_JOB_VACANCY } from '../../queries/jobVacancy';
import { ASK_COMPANY } from '../../queries/matching';
import JobVacancyModal from '../Booth/JobVacancyModal';

const Layout = styled.div`
  width: ${(props) => (props.forSlide ? '300px' : '320px')} !important;
  min-width: ${(props) => (props.forSlide ? '300px' : '320px')} !important;
  height: ${(props) => (props.removeTypes ? '192px' : '348px')};
  background-color: #fcfcfc;
  cursor: pointer;
  border-radius: 30px;
  box-shadow: 0px 12px 23px 0px #0d03030a;
  padding: 24px 0 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${isMobileOnly ? '12px' : ''};
`;

const FlexLayout = styled.div`
  margin: 0 25px 17px;
  display: flex;
  align-items: center;
`;

const ImageLayout = styled.div`
  margin-right: 17px;
  height: 72px;
  width: 72px;
  position: relative;
  border-radius: 10px;
  background-color: #f2f2f2;
  img {
    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    max-width: 72px;
    max-height: 72px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const CompanyNameText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 2.8em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const DomainText = styled.p`
  font-family: Noto Sans KR;
  margin-left: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 8px;
`;

const JobVacancyText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  margin-left: 25px;

  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 16px;
  width: 248px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const WorkplaceText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  margin-left: 25px;

  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  margin-bottom: 4px;
`;

const RecruitmentScheduleText = styled.p`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  margin-left: 25px;

  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #f1f1f1;
  margin: 20px 0;
`;

const SkillsLayout = styled.div`
  height: 27px !important;
  margin: 0 25px;
  overflow: hidden;
`;

const Skill = styled.div`
  height: 27px;
  border-radius: 10px;
  background-color: #fff3f0;
  color: #ffa171;
  margin: 0 8px 8px 0;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 13px;
  line-height: 19px;
  letter-spacing: -0.0032em;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const TypesLayout = styled.div`
  height: 22px;
  margin: 16px 25px 0;
  overflow: hidden;
`;

const Type = styled.div`
  height: 22px;
  border-radius: 2px;
  padding: 1px 8px;
  background-color: #fff1f0;
  color: #fe706f;
  font-size: 12px;
  margin-right: 8px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.1em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 100px;
`;

const SaveButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: 14px;
`;

const SuggestionModalLayout = styled.div`
  width: ${isMobile ? 'calc(100vw - 32px)' : '892px'};
  height: 527px;
`;

const ItemModalBody = styled.div`
  height: ${isMobile ? 'calc(100% - 119px)' : 'calc(100% - 92px)'};
  width: 100%;
  position: absolute;
  top: ${isMobile ? '119px' : '92px'};
  z-index: 0;
  overflow: scroll;
`;

const ModalHeader = styled.div`
  height: ${isMobile ? '119px' : '92px'};
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: ${isMobile ? '' : 'flex'};
  padding: ${isMobile ? '35px 26px 16px' : '35px 26px 28px'};
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: ${isMobile ? '' : '16px'};
  margin-bottom: ${isMobile ? '14px' : ''};
`;

const ModalDivider = styled.div`
  height: 1px;
  background-color: #f0f0f0;
`;

const ItemLayout = styled.div`
  padding: ${(props) =>
    isMobile ? `${props.removeCompany ? '0 0 20px' : '20px 0'}` : '31px 0'};
`;

const ItemTitleLayout = styled.div`
  display: flex;
  align-items: center;
  padding: ${isMobile ? '0 29px 0 16px' : '0 65px'};
`;

const ItemTitleText = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 8px;
  color: ${(props) => props.color};
`;

const Spacer = styled.div`
  flex: 1;
`;

const JobVacancyComponent = ({
  jobVacancy,
  forSlide = false,
  onClickSaveButton,
  saved,
  applied,
  onClickApplyButton,
  removeTypes = false,
  removeCompany = false,
  updateViewCount = false,
}) => {
  const [isInformationModalOpened, setIsInformationModalOpened] =
    useState(false);
  const [openedInformation, setOpenedInformation] = useState('jobVacancy');
  const [askingQuestion, setAskingQuestion] = useState(false);
  const [askCompany] = useMutation(ASK_COMPANY, {
    onCompleted: (data) => {
      setAskingQuestion(false);
    },
  });
  const { user } = useAuth();
  const [lookUpJobVacancy] = useMutation(LOOK_UP_JOB_VACANCY);
  const deprecated = useMemo(() => {
    return (
      jobVacancy.recruitmentSchedule.split(' ~ ')?.[1] &&
      moment(jobVacancy.recruitmentSchedule.split(' ~ ')?.[1]) <
        moment(moment().startOf('day'))
    );
  }, [jobVacancy]);
  return (
    <>
      <Layout
        style={{
          opacity: deprecated ? 0.5 : 1,
        }}
        forSlide={forSlide}
        removeTypes={removeTypes}
        onClick={() => {
          if (updateViewCount) {
            lookUpJobVacancy({
              variables: {
                studentId: user.id,
                jobVacancyId: jobVacancy.id,
              },
            });
          }
          if (!isInformationModalOpened) {
            setIsInformationModalOpened(true);
          }
        }}
      >
        {user.userType !== UserType.company && (
          <SaveButton
            type="link"
            style={{ padding: '3px 6px' }}
            onClick={(e) => {
              e.stopPropagation();
              onClickSaveButton();
            }}
          >
            <Icon size="17px" icon={saved ? 'bookmarkFilled' : 'bookmark'} />
          </SaveButton>
        )}
        {!removeCompany && (
          <FlexLayout>
            <ImageLayout>
              {jobVacancy?.company?.logo !== null &&
                jobVacancy?.company?.logo !== undefined && (
                  <img src={jobVacancy?.company?.logo?.url} alt="thumbnail" />
                )}
            </ImageLayout>
            <CompanyNameText>
              {jobVacancy?.company?.basicCompanyProfile?.companyNameKR}
              <br />
              {jobVacancy?.company?.basicCompanyProfile?.companyNameEN}
            </CompanyNameText>
          </FlexLayout>
        )}
        {!removeCompany && (
          <DomainText>
            {jobVacancy?.company?.basicCompanyProfile?.domain}
          </DomainText>
        )}
        <JobVacancyText>{jobVacancy?.position}</JobVacancyText>
        <WorkplaceText>근무지역 : {jobVacancy?.workplace}</WorkplaceText>
        <RecruitmentScheduleText>
          채용일정 : {jobVacancy?.recruitmentSchedule}
        </RecruitmentScheduleText>
        <Divider />
        <SkillsLayout>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {jobVacancy?.skills?.slice(0, 2)?.map((item, index) => (
              <Skill key={index}>{item.skillDetail}</Skill>
            ))}
            {2 < jobVacancy?.skills?.length && (
              <p
                style={{ fontSize: '12px', marginTop: '4px', color: '#FFA171' }}
              >
                외 {jobVacancy?.skills?.length - 2}개
              </p>
            )}
          </div>
        </SkillsLayout>
        {!removeTypes && (
          <TypesLayout>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {jobVacancy?.company?.recruitmentTypes
                ?.slice(0, 2)
                ?.map((item, index) => (
                  <Type key={index}>{item}</Type>
                ))}
              {3 < jobVacancy?.company?.recruitmentTypes?.length && (
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '2px',
                    color: '#FE706F',
                  }}
                >
                  외 {jobVacancy?.company?.recruitmentTypes?.length - 2}개
                </p>
              )}
            </div>
          </TypesLayout>
        )}
      </Layout>
      {jobVacancy && (
        <Modal
          isOpen={isInformationModalOpened === true}
          onClose={(e) => {
            setIsInformationModalOpened(false);
            setOpenedInformation('jobVacancy');
          }}
          closeButtonTransparent
        >
          <SuggestionModalLayout>
            <ModalHeader>
              <ModalHeaderTitle>{jobVacancy?.position}</ModalHeaderTitle>
              {user.userType !== UserType.company && (
                <>
                  <Spacer />
                  {isMobile ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {applied ? (
                          <p
                            style={{
                              padding: '0 16px',
                              color: '#fe706f',
                              fontSize: '14px',
                            }}
                          >
                            면접 지원 완료
                          </p>
                        ) : (
                          <Button
                            disabled={deprecated}
                            type="primary"
                            style={{
                              borderRadius: '10px',
                              marginRight: '16px',
                            }}
                            onClick={onClickApplyButton}
                          >
                            면접 지원하기
                          </Button>
                        )}

                        <div width="20px" />
                        <Button
                          style={{
                            borderRadius: '10px',
                            marginRight: '16px',
                            backgroundColor: !saved ? '#ffffff' : '#ffccc7',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fe706f',
                            border: 'none',
                          }}
                          onClick={() => {
                            onClickSaveButton();
                          }}
                        >
                          <Icon
                            size="12px"
                            icon={saved ? 'bookmarkFilled' : 'bookmark'}
                          />
                          <div style={{ width: '8px' }} />
                          {saved ? '저장 완료' : '저장'}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {applied ? (
                        <p
                          style={{
                            padding: '0 16px',
                            color: '#fe706f',
                            fontSize: '14px',
                          }}
                        >
                          면접 지원 완료
                        </p>
                      ) : (
                        <Button
                          type="primary"
                          style={{
                            borderRadius: '10px',
                            marginRight: '16px',
                          }}
                          onClick={onClickApplyButton}
                          disabled={deprecated}
                        >
                          면접 지원하기
                        </Button>
                      )}

                      <div width="20px" />
                      <Button
                        style={{
                          borderRadius: '10px',
                          marginRight: '16px',
                          backgroundColor: !saved ? '#ffffff' : '#ffccc7',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#fe706f',
                          border: 'none',
                        }}
                        onClick={() => {
                          onClickSaveButton();
                        }}
                      >
                        <Icon
                          size="12px"
                          icon={saved ? 'bookmarkFilled' : 'bookmark'}
                        />
                        <div style={{ width: '8px' }} />
                        {saved ? '저장 완료' : '저장'}
                      </Button>
                    </>
                  )}
                </>
              )}
            </ModalHeader>
            <ItemModalBody>
              {!removeCompany && (
                <ItemLayout>
                  <ItemTitleLayout>
                    <Button
                      type="link"
                      color={
                        openedInformation === 'jobVacancy'
                          ? '#000000'
                          : '#fe706f'
                      }
                      onClick={() =>
                        setOpenedInformation(
                          openedInformation === 'company'
                            ? 'jobVacancy'
                            : 'company',
                        )
                      }
                    >
                      {openedInformation === 'jobVacancy' ? (
                        <RightOutlined
                          style={{ fontSize: '18px', color: '#000000' }}
                        />
                      ) : (
                        <DownOutlined
                          style={{ fontSize: '18px', color: '#fe706f' }}
                        />
                      )}
                    </Button>
                    <ItemTitleText
                      color={
                        openedInformation === 'jobVacancy'
                          ? '#000000'
                          : '#fe706f'
                      }
                    >
                      기업 정보
                    </ItemTitleText>
                    <Spacer />
                    {user.userType !== UserType.company && (
                      <Button
                        type="primary"
                        style={{ borderRadius: '10px', marginRight: '16px' }}
                        onClick={() => setAskingQuestion(true)}
                      >
                        기업에 질문하기
                      </Button>
                    )}
                  </ItemTitleLayout>
                  {openedInformation === 'company' && (
                    <CompanyProfileModal id={jobVacancy?.company?.id} />
                  )}
                </ItemLayout>
              )}
              {!removeCompany && <ModalDivider />}
              <ItemLayout removeCompany={removeCompany}>
                {!removeCompany && (
                  <ItemTitleLayout>
                    <Button
                      type="link"
                      color={
                        openedInformation === 'company' ? '#000000' : '#fe706f'
                      }
                      onClick={() =>
                        setOpenedInformation(
                          openedInformation === 'jobVacancy'
                            ? 'company'
                            : 'jobVacancy',
                        )
                      }
                    >
                      {openedInformation === 'company' ? (
                        <RightOutlined
                          style={{ fontSize: '18px', color: '#000000' }}
                        />
                      ) : (
                        <DownOutlined
                          style={{ fontSize: '18px', color: '#fe706f' }}
                        />
                      )}
                    </Button>
                    <ItemTitleText
                      color={
                        openedInformation === 'company' ? '#000000' : '#fe706f'
                      }
                    >
                      채용 정보
                    </ItemTitleText>
                  </ItemTitleLayout>
                )}
                {openedInformation === 'jobVacancy' && (
                  <JobVacancyModal showHeader={false} jobVacancy={jobVacancy} />
                )}
              </ItemLayout>
            </ItemModalBody>
          </SuggestionModalLayout>
        </Modal>
      )}
      <Modal isOpen={askingQuestion} onClose={() => setAskingQuestion(false)}>
        <SmallModalWithTextareaLayout
          title="기업에 질문하기 (Q&A)"
          onClickLeftButton={() => setAskingQuestion(false)}
          placeholder={'질문 내용을 입력해주세요.'}
          onClickRightButton={(text) => () => {
            askCompany({
              variables: {
                studentId: user.id,
                companyId: jobVacancy?.company?.id,
                message: text,
              },
            });
          }}
        />
      </Modal>
    </>
  );
};

export default JobVacancyComponent;
