import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BoothDetail from './BoothDetail';

const Booth = () => {
  return (
    <Switch>
      <Route exact path="/fair/:fairId/booth/:boothId">
        <BoothDetail />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Booth;
