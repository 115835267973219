import { BookOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { UPDATE_BOOTH } from '../../../queries/booth';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  padding: 37px 0 23px;
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const ContentLayout = styled.div`
  display: flex;
  width: fit-content;
  margin: 40px auto 0;
  align-items: center;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
`;

const StyledInput = styled(Input)`
  border-radius: 10px;
  width: 466px;
  font-size: 14px;
  height: 40px;
  margin: 18px auto 16px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const ZoomLinkModal = ({ item, onClose }) => {
  const [editingLink, setEditingLink] = useState('');
  const [editingCounselMethod, setEditingCounselMethod] = useState('');
  useEffect(() => {
    setEditingLink(item.link);
    setEditingCounselMethod(item.counselMethod);
  }, [item]);
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);
  return (
    <Layout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>온라인 상담 방법</Title>
      </TitleLayout>
      <ContentLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>화상상담 링크를 작성해주세요</p>
      </ContentLayout>
      <StyledInput
        placeholder="화상상담 링크를 작성해주세요"
        value={editingLink}
        onChange={(e) => setEditingLink(e.currentTarget.value)}
      />
      <ContentLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>
          Zoom이 아닌 타사 솔루션 혹은 유선상담인 경우 상담방법을 입력해주세요.
        </p>
      </ContentLayout>
      <StyledInput
        placeholder="상담 방법을 작성하지 않으시면 'Zoom을 통한 화상상담'으로 저장됩니다."
        value={editingCounselMethod}
        onChange={(e) => setEditingCounselMethod(e.currentTarget.value)}
      />
      <ButtonLayout>
        <BottomCenterButton
          type="primary"
          onClick={() =>
            updateBooth({
              variables: {
                id: item.id,
                boothInput: {
                  zoomLink: editingLink,
                  counselMethod:
                    editingCounselMethod === ''
                      ? 'Zoom을 통한 화상상담'
                      : editingCounselMethod,
                },
              },
            })
          }
        >
          확인
        </BottomCenterButton>
      </ButtonLayout>
    </Layout>
  );
};

export default ZoomLinkModal;
