import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 225ms;
  opacity: ${(props) => props.opacity};
  outline: none;
  z-index: 1000;
`;

const Backdrop = styled.button`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  touch-action: none;
  background-color: #171725;
  opacity: 60%;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
`;

const ContentLayout = styled.div`
  background-color: #ffffff;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
  position: relative;
  overflow: hidden;
  z-index: 1000;
  margin-left: ${(props) => props.shiftIndex * 16}px;
  margin-top: ${(props) => props.shiftIndex * 16}px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 24px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;
  background-color: ${(props) =>
    props.closeButtonTransparent ? 'transparent' : 'white'};
`;

const Modal = ({
  isOpen,
  onClose,
  width,
  height,
  children,
  radius,
  dismissable,
  closeButtonTransparent,
  removeCloseButton = false,
  shiftIndex = 0,
}) => {
  const modalRef = useRef(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (isOpen) {
      if (modalRef.current) modalRef.current.focus();
      setVisible(true);
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = 'scroll';
      document.body.style.height = '100%';
    }
  }, [isOpen]);
  useEffect(() => {
    return () => {
      document.body.style.overflow = 'scroll';
      document.body.style.height = '100%';
    };
  }, []);
  const handleTransitionEnd = useCallback(() => {
    setVisible(false);
  }, []);

  if (!isOpen && !visible) return null;
  return ReactDOM.createPortal(
    <Layout
      ref={modalRef}
      role="presentation"
      tabIndex={-1}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      }}
      onTransitionEnd={isOpen ? undefined : handleTransitionEnd}
      opacity={isOpen && visible ? 1 : 0}
    >
      <Backdrop
        aria-label="backdrop"
        aria-hidden="true"
        onClick={() => {
          if (!dismissable) {
            onClose();
            document.body.style.overflow = 'scroll';
            document.body.style.height = '100%';
          }
        }}
      />
      <ContentLayout
        shiftIndex={shiftIndex}
        width={width}
        height={height}
        radius={radius ?? '10px'}
      >
        {children}
        {!removeCloseButton && (
          <CloseButton
            onClick={() => {
              document.body.style.overflow = 'scroll';
              document.body.style.height = '100%';
              onClose();
            }}
            closeButtonTransparent={closeButtonTransparent}
          >
            <CloseOutlined
              style={{ fontSize: isMobile ? '20px' : '23px', color: '#aaaaaa' }}
            />
          </CloseButton>
        )}
      </ContentLayout>
    </Layout>,
    document.body,
  );
};

export default Modal;
