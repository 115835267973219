import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import Logo from '../img/LogoBlack.png';
import AuthModal from '../components/AuthModal';
import useAuth from '../hooks/useAuth';
import Icon from './Icon';
import Popover from './Popover';
import { useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { NotificationType, UserType } from '../constants/static';
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_STATE,
  READ_NOTIFICATION,
  SUBSCRIBE_NOTIFICATION_ADDED,
  SUBSCRIBE_NOTIFICATION_STATE,
} from '../queries/notification';
import { toast } from 'react-toastify';
import { timeForToday } from '../constants/utils';

const Layout = styled.div`
  position: fixed;
  top: 0;
  height: ${isMobile ? '56px' : '64px'};
  background-color: #ffffff;
  border-bottom: 1px solid #f8f8f8;
  display: flex;
  padding: ${isMobile ? '0 24px 0 26px' : '0 102px'};
  align-items: center;
  width: 100%;
  z-index: 1000;
`;

const LogoLink = styled(Link)`
  margin-left: 0;
  margin-right: auto;
  width: ${isMobile ? '75px' : '100px'};
  height: ${isMobile ? '25px' : '33px'};
  img {
    width: ${isMobile ? '75px' : '100px'};
    height: ${isMobile ? '25px' : '33px'};
  }
`;

const RightButtonLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

const RightButton = styled.div`
  cursor: pointer;
  height: 100%;
  padding: 21px 20px;
  font-size: 14px;
  font-weight: 700;
  color: #1e1e1e;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  div {
    opacity: ${(props) => (props.selected ? '100%' : '0%')};
    height: 3px;
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background-color: #fe706f;
  }
`;

const DownloadButton = styled.div`
  cursor: pointer;
  height: 100%;
  padding: 21px 20px;
  font-size: 14px;
  color: #fe706f;
  border-radius: 10px 10px 0px 0px;
  position: relative;
`;

const RightItalicButton = styled.div`
  cursor: pointer;
  height: 100%;
  padding: 18px 20px;
  font-size: 18px;
  font-weight: 700;
  font-family: Dancing Script, cursive;
  color: #1e1e1e;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  div {
    opacity: ${(props) => (props.selected ? '100%' : '0%')};
    height: 3px;
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background-color: #fe706f;
  }
`;

const RightIconButton = styled.div`
  cursor: pointer;
  width: 63px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${isMobile ? '0 0 0 12px' : '16px'};
  border-radius: 10px 10px 0px 0px;
  position: relative;
  span {
    opacity: ${(props) => (props.selected ? '100%' : '0%')};
    height: 3px;
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background-color: #fe706f;
  }
`;

const MobileRightIconButton = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${isMobile ? '0 0 0 22px' : '16px'};
  border-radius: 10px 10px 0px 0px;
  position: relative;
  span {
    opacity: ${(props) => (props.selected ? '100%' : '0%')};
    height: 3px;
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    background-color: #fe706f;
  }
`;

const DrawerIconButton = styled.div`
  padding: 0 0 0 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 122px;
  margin-bottom: 32px;
`;

const PopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
`;

const PopoverItem = styled.button`
  padding: 9px 16px;
  font-size: 14px;
  height: 40px;
  color: #414141;
  font-weight: 400;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  &:hover {
    font-weight: 700;
    background-color: #f2f2f2;
  }
`;

const Drawer = styled.div`
  width: 182px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  background-color: #ffffff;
  filter: drop-shadow(-3px 0px 12px rgba(0, 0, 0, 0.1));
  border-radius: 10px 0px 0px 10px;
  padding: 16px 0 16px;
  z-index: 1000;
  overflow-y: scroll;
`;

const MobileMenu = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-bottom: 8px;
  color: #414141;
  background-color: ${(props) => (props.selected ? '#f2f2f2' : 'transparent')};
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? 700 : 500)}; ;
`;

const MobileDownloadMenu = styled.div`
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #fe706f;
  cursor: pointer;
`;

const NoticePopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid #fe706f;
  border-radius: 10px;
  background-color: #fbfbfb;
`;

const NotificationItem = styled.div`
  padding: 16px;
  min-height: 94px;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  flex-flow: column;
  text-align: left;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'inherit')};
  p {
    text-align: right;
    margin-top: 1px;
    font-family: Noto Sans KR;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #ff4d4f;
    margin-top: auto;
    margin-bottom: 0;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #fe706f;
`;

const ToastLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 19px 16px;
  cursor: pointer;
  font-size: 12px;
  color: #262626;
  p {
    margin-left: 19px;
    display: block; /* or inline-block */
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    line-height: 1.8em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Notice = ({ userId, isMobile, userType }) => {
  const { data: subscribeNotificationStateData } = useSubscription(
    SUBSCRIBE_NOTIFICATION_STATE,
    {
      variables: {
        userId,
      },
    },
  );
  const { data: subscribeNotificationAddedData } = useSubscription(
    SUBSCRIBE_NOTIFICATION_ADDED,
    {
      variables: {
        userId,
      },
    },
  );
  const { data: getNotificationStateData } = useQuery(GET_NOTIFICATION_STATE, {
    variables: {
      userId,
    },
  });
  const [readNotification, { data: readNotificationData }] =
    useMutation(READ_NOTIFICATION);
  const [getNotifications, { data: getNotificationsData }] =
    useLazyQuery(GET_NOTIFICATIONS);
  const read = useMemo(() => {
    if (subscribeNotificationStateData) {
      return subscribeNotificationStateData?.notificationStateChanged?.read;
    }
    if (getNotificationStateData) {
      return getNotificationStateData?.notificationState?.read;
    }
    return true;
  }, [getNotificationStateData, subscribeNotificationStateData]);
  const [isNoticePopoverOpened, setIsNoticePopoverOpened] = useState(false);
  const buttonRef = useRef(null);
  const { user } = useAuth();

  useEffect(() => {
    if (subscribeNotificationAddedData) {
      if (isNoticePopoverOpened) {
        getNotifications({
          variables: {
            userId: user.id,
          },
        });
      } else {
        toast(
          <ToastLayout
            onClick={() => {
              getNotifications({
                variables: {
                  userId: user.id,
                },
              });
              setTimeout(() => setIsNoticePopoverOpened(true), 500);
            }}
          >
            <Icon icon="notificationPink" color="transparent" size="23px" />
            <p>{subscribeNotificationAddedData?.notificationAdded?.message}</p>
          </ToastLayout>,
        );
      }
    }
  }, [getNotifications, subscribeNotificationAddedData, user.id]);
  useEffect(() => {
    if (getNotificationsData !== undefined && isNoticePopoverOpened === false) {
      readNotification({
        variables: {
          userId,
        },
      });
    }
  }, [getNotificationsData, isNoticePopoverOpened, readNotification, userId]);
  const history = useHistory();
  return (
    <>
      {isMobile ? (
        <MobileRightIconButton
          selected={!isMobile && isNoticePopoverOpened}
          onClick={() => {
            setIsNoticePopoverOpened(true);
            getNotifications({
              variables: {
                userId: user.id,
              },
            });
          }}
          ref={buttonRef}
        >
          {read ? (
            <Icon
              icon="notification"
              size={isMobile ? '24px' : '31px'}
              color="transparent"
            />
          ) : (
            <Icon
              icon="notificationNew"
              size={isMobile ? '24px' : '31px'}
              color="transparent"
            />
          )}
          <span />
        </MobileRightIconButton>
      ) : (
        <RightIconButton
          selected={!isMobile && isNoticePopoverOpened}
          onClick={() => {
            setIsNoticePopoverOpened(true);
            getNotifications({
              variables: {
                userId: user.id,
              },
            });
          }}
          ref={buttonRef}
        >
          {read ? (
            <Icon
              icon="notification"
              size={isMobile ? '24px' : '31px'}
              color="transparent"
            />
          ) : (
            <Icon
              icon="notificationNew"
              size={isMobile ? '24px' : '31px'}
              color="transparent"
            />
          )}
          <span />
        </RightIconButton>
      )}

      <Popover
        open={isNoticePopoverOpened}
        width={isMobile ? '248px' : '385px'}
        height="376px"
        onClose={() => setIsNoticePopoverOpened(false)}
        anchorRef={buttonRef}
        horizontalAlign="right"
        verticalAlign="bottom"
        removePadding
        borderRadius="10px"
      >
        <NoticePopoverLayout>
          {getNotificationsData?.notifications?.map((item, index) => (
            <>
              <NotificationItem
                isLink={item.type !== NotificationType.notice}
                onClick={() => {
                  setIsNoticePopoverOpened(false);
                  switch (item.notificationType) {
                    case NotificationType.profileUpdateRequest:
                      history.push('/profile/manage');
                      break;
                    case NotificationType.counselReservationRequest:
                      if (userType === UserType.company) {
                        history.push('/booth-and-counsel/manage-counsel');
                      } else {
                        history.push('/counsel/manage-counsel');
                      }
                      break;
                    case NotificationType.counselReservationCanceled:
                      if (userType === UserType.company) {
                        history.push('/booth-and-counsel/manage-counsel');
                      } else {
                        history.push('/counsel/manage-counsel');
                      }
                      break;
                    case NotificationType.counselReservationResult:
                      history.push('/counsel/history');
                      break;
                    case NotificationType.fairApplicationResult:
                      history.push('/booth-and-counsel/display-booth');
                      break;
                    case NotificationType.matchingApplicationResult:
                      history.push('/company-meeting/apply-matching');
                      break;
                    case NotificationType.matchingApplied:
                      history.push('/company-meeting/manage-matching');
                      break;
                    case NotificationType.matchingSuggested:
                      history.push('/meeting/manage-interview');
                      break;
                    case NotificationType.matchingAppliedCanceled:
                      history.push('/company-meeting/manage-matching');
                      break;
                    case NotificationType.matchingSuggestedCanceled:
                      history.push('/meeting/manage-interview');
                      break;
                    case NotificationType.matchingAppliedApproved:
                      history.push('/meeting/manage-interview');
                      break;
                    case NotificationType.matchingAppliedDeclined:
                      history.push('/meeting/manage-interview');
                      break;
                    case NotificationType.matchingSuggestedApproved:
                      history.push('/company-meeting/manage-matching');
                      break;
                    case NotificationType.matchingSuggestedDeclined:
                      history.push('/company-meeting/manage-matching');
                      break;
                    default:
                      break;
                  }
                }}
              >
                {item.message}
                <p>{timeForToday(item.createdAt)}</p>
              </NotificationItem>
              {getNotificationsData?.notifications.length < 4 ||
                (index < getNotificationsData?.notifications?.length - 1 && (
                  <Divider />
                ))}
            </>
          ))}
        </NoticePopoverLayout>
      </Popover>
    </>
  );
};

const Header = ({ isSignInModalOpened, setIsSignInModalOpened }) => {
  const [selectedMenu, setSelectedMenu] = useState('');
  const { user, setUser } = useAuth();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    setSelectedMenu(location.pathname.split('/')[1]);
  }, [location]);
  const [isProfilePopoverVisible, setIsProfilePopoverVisible] = useState(false);
  const buttonRef = useRef(null);
  const handleClickPopoverMenu = () => {
    setIsProfilePopoverVisible(false);
  };
  useEffect(() => {
    if (isMobile) {
      setIsProfilePopoverVisible(false);
    }
  }, [location]);
  useEffect(() => {
    if (user.id > 0) {
    }
  }, [user.id]);
  return (
    <Layout>
      <LogoLink to={'/'}>
        <img src={Logo} alt="logo" />
      </LogoLink>
      <RightButtonLayout>
        {!isMobile && user.id !== 0 && (
          <>
            <RightButton
              selected={selectedMenu === 'fair' && !isSignInModalOpened}
              onClick={() => {
                history.push('/fair');
              }}
            >
              박람회
              <div />
            </RightButton>
            {user.userType !== UserType.counselor && (
              <>
                {user.userType === UserType.company && (
                  <RightButton
                    selected={
                      selectedMenu === 'matching' &&
                      !isSignInModalOpened &&
                      location.pathname.split('/')[2] === 'company' &&
                      location.pathname.split('/').length < 3
                    }
                    onClick={() => {
                      history.push('/matching/company');
                    }}
                  >
                    매칭 인재 <div />
                  </RightButton>
                )}
                <RightButton
                  selected={
                    selectedMenu === 'matching' &&
                    !isSignInModalOpened &&
                    (location.pathname.split('/')[2] === 'student' ||
                      (location.pathname.split('/')[2] === 'company' &&
                        location.pathname.split('/').length > 3))
                  }
                  onClick={() => {
                    history.push('/matching/student');
                  }}
                >
                  {user.userType === UserType.student ? '매칭' : '매칭 기업'}
                  <div />
                </RightButton>
              </>
            )}
            <RightButton
              selected={selectedMenu === 'presentation' && !isSignInModalOpened}
              onClick={() => {
                history.push('/presentation');
              }}
            >
              설명회
              <div />
            </RightButton>
            <RightButton
              selected={
                selectedMenu === 'advertisement' && !isSignInModalOpened
              }
              onClick={() => {
                history.push('/advertisement');
              }}
            >
              기업라운지
              <div />
            </RightButton>
          </>
        )}
        {isMobile ? (
          <>
            {user.id !== 0 && (
              <Notice
                userId={user.id}
                isMobile={true}
                userType={user.userType}
              />
            )}
            <MobileRightIconButton
              ref={buttonRef}
              onClick={() =>
                setIsProfilePopoverVisible(!isProfilePopoverVisible)
              }
            >
              <Icon icon="menu" size="24px" color="black" />
            </MobileRightIconButton>
          </>
        ) : (
          <>
            <DownloadButton
              onClick={() => {
                window.open('https://tally.so/r/mO7Zdg', '_blank');
              }}
            >
              서비스 소개서 다운로드
            </DownloadButton>
            {user.id === 0 ? (
              <RightButton
                onClick={() => {
                  setIsSignInModalOpened(true);
                }}
                selected={isSignInModalOpened}
              >
                로그인/회원가입
                <div />
              </RightButton>
            ) : (
              <>
                <RightIconButton
                  ref={buttonRef}
                  onClick={() => setIsProfilePopoverVisible(true)}
                  selected={
                    selectedMenu === 'profile' || isProfilePopoverVisible
                  }
                >
                  <Icon icon="profile" size="32px" color="transparent" />
                  <span />
                </RightIconButton>
                <Notice
                  userId={user.id}
                  isMobile={false}
                  userType={user.userType}
                />
              </>
            )}
          </>
        )}
      </RightButtonLayout>
      {isMobile && isProfilePopoverVisible && (
        <Drawer>
          <DrawerIconButton
            ref={buttonRef}
            onClick={() => setIsProfilePopoverVisible(!isProfilePopoverVisible)}
          >
            <Icon icon="menu" size="24px" color="#fe706f" />
          </DrawerIconButton>
          <MobileMenu
            selected={selectedMenu === ''}
            onClick={() => {
              history.push('/');
              handleClickPopoverMenu();
            }}
          >
            홈
          </MobileMenu>
          {user.id !== 0 && (
            <>
              <MobileMenu
                selected={selectedMenu === 'fair'}
                onClick={() => {
                  history.push('/fair');
                  handleClickPopoverMenu();
                }}
              >
                박람회
              </MobileMenu>
              <MobileMenu
                selected={selectedMenu === 'matching'}
                onClick={() => {
                  history.push('/matching/student');
                  handleClickPopoverMenu();
                }}
              >
                매칭
              </MobileMenu>
              {user.userType === UserType.student && (
                <>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/presentation');
                    }}
                    selected={selectedMenu === 'presentation'}
                  >
                    설명회
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/advertisement');
                    }}
                    selected={selectedMenu === 'advertisement'}
                  >
                    기업라운지
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/profile/manage');
                    }}
                    selected={selectedMenu === 'profile'}
                  >
                    프로필
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/counsel/history');
                    }}
                    selected={selectedMenu === 'counsel'}
                  >
                    상담
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/meeting/manage-interview');
                    }}
                    selected={
                      selectedMenu === 'meeting' &&
                      location.pathname.split('/')[2] === 'manage-interview'
                    }
                  >
                    매칭 관리
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/meeting/qna');
                    }}
                    selected={
                      selectedMenu === 'meeting' &&
                      location.pathname.split('/')[2] === 'qna'
                    }
                  >
                    Q&A
                  </MobileMenu>
                  <MobileMenu
                    onClick={() => {
                      handleClickPopoverMenu();
                      history.push('/student-presentation-manage/applications');
                    }}
                    selected={
                      location.pathname.split('/')?.[2] === 'applications' &&
                      location.pathname.split('/')?.[1] ===
                        'student-presentation-manage'
                    }
                  >
                    설명회 신청내역
                  </MobileMenu>
                </>
              )}
            </>
          )}
          {user.id === 0 ? (
            <MobileMenu
              onClick={() => {
                setIsSignInModalOpened(true);
                handleClickPopoverMenu();
              }}
            >
              {'로그인/회원가입'}
            </MobileMenu>
          ) : (
            <MobileMenu
              isSignOutButton
              onClick={() => {
                handleClickPopoverMenu();
                setUser({ id: 0, email: '', userType: '' });
                localStorage.setItem(
                  'user',
                  JSON.stringify({
                    id: 0,
                    email: '',
                    userType: '',
                  }),
                );
                localStorage.setItem('accessToken', '');
                if (location.pathname.split('/')?.[1] !== 'survey') {
                  history.push('/');
                }
              }}
            >
              {'로그아웃'}
            </MobileMenu>
          )}
          <div
            style={{
              margin: '16px 8px 0',
              height: '1px',
              backgroundColor: '#dedede',
            }}
          />
          <MobileDownloadMenu
            onClick={() => {
              window.open('https://tally.so/r/mO7Zdg', '_blank');
            }}
          >
            {'서비스 소개서 다운로드'}
          </MobileDownloadMenu>
        </Drawer>
      )}
      {!isMobile && (
        <Popover
          open={isProfilePopoverVisible}
          width={user.userType !== UserType.counselor ? '120px' : '96px'}
          height={
            user.userType === UserType.counselor
              ? '120px'
              : user.userType == UserType.company
              ? '280px'
              : '200px'
          }
          onClose={() => setIsProfilePopoverVisible(false)}
          anchorRef={buttonRef}
          horizontalAlign="left"
          verticalAlign="bottom"
          removePadding
          borderRadius="0 0 6px 6px"
        >
          <PopoverLayout>
            <PopoverItem
              onClick={() => {
                history.push(
                  user.userType === UserType.counselor
                    ? '/profile-counsel/manage'
                    : '/profile/manage',
                );
                handleClickPopoverMenu();
              }}
            >
              {user.userType === UserType.student ? '프로필' : '기업정보'}
            </PopoverItem>
            <PopoverItem
              onClick={() => {
                if (user.userType === UserType.company) {
                  history.push('/hire/manage-hire');
                }
                if (user.userType === UserType.student) {
                  history.push('/counsel/history');
                }
                if (user.userType === UserType.counselor) {
                  history.push('/counsel/manage-counsel');
                }
                handleClickPopoverMenu();
              }}
            >
              {user.userType === UserType.student
                ? '상담'
                : user.userType === UserType.company
                ? '채용 공고'
                : '상담'}
            </PopoverItem>
            {user.userType !== UserType.counselor && (
              <PopoverItem
                onClick={() => {
                  handleClickPopoverMenu();
                  if (user.userType === UserType.company) {
                    history.push('/booth-and-counsel/display-booth');
                  } else {
                    history.push('/meeting/manage-interview');
                  }
                }}
              >
                {user.userType === UserType.student ? '매칭' : '박람회'}
              </PopoverItem>
            )}
            {user.userType === UserType.student && (
              <PopoverItem
                onClick={() => {
                  handleClickPopoverMenu();
                  history.push('/student-presentation-manage/applications');
                }}
              >
                설명회
              </PopoverItem>
            )}
            {user.userType === UserType.company && (
              <>
                <PopoverItem
                  onClick={() => {
                    handleClickPopoverMenu();
                    history.push('/company-meeting/apply-matching');
                  }}
                >
                  매칭
                </PopoverItem>
                <PopoverItem
                  onClick={() => {
                    handleClickPopoverMenu();
                    history.push('/presentation-manage/applications');
                  }}
                >
                  설명회
                </PopoverItem>
                <PopoverItem
                  onClick={() => {
                    handleClickPopoverMenu();
                    history.push('/advertisement-manage/manage');
                  }}
                >
                  기업라운지
                </PopoverItem>
              </>
            )}
            <PopoverItem
              isSignOutButton
              onClick={() => {
                handleClickPopoverMenu();
                setUser({ id: 0, email: '', userType: '' });
                localStorage.setItem(
                  'user',
                  JSON.stringify({
                    id: 0,
                    email: '',
                    userType: '',
                  }),
                );
                localStorage.setItem('accessToken', '');
                if (location.pathname.split('/')?.[1] !== 'survey') {
                  history.push('/');
                }
              }}
            >
              로그아웃
            </PopoverItem>
          </PopoverLayout>
        </Popover>
      )}
      <AuthModal
        initialPage={'signIn'}
        isOpen={isSignInModalOpened}
        onClose={() => {
          setIsSignInModalOpened(false);
        }}
      />
    </Layout>
  );
};

export default Header;
