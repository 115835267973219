import { CheckOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import SmallModalLayout from '../../components/SmallModalLayout';
import SmallModalWithTextareaLayout from '../../components/SmallModalWithTextareaLayout';
import {
  MatchingApplicationState,
  MatchingSuggestionState,
  MatchingType,
} from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import {
  ACCEPT_MATCHING,
  APPLY_MATCHING,
  CANCEL_MATCHING,
  DECLINE_MATCHING,
  GET_MATCHINGS_BY_STUDENT,
} from '../../queries/matching';
import StudentMatchingComponent from './StudentMatchingComponent';

const Layout = styled.div`
  margin-top: ${isMobile ? '18px' : '28px'};
  background-color: ${isMobile ? '' : 'white'};
  border-radius: 10px;
  padding: ${isMobile ? '0 0 24px' : '25px 33px 48px'};
`;

const Divider = styled.div`
  height: 1px;
  background-color: #f0f0f0;
  margin: 22px;
`;

const FilterLayout = styled.div`
  display: flex;
  align-items: ${isMobile ? 'center' : 'baseline'};
  justify-content: ${isMobile ? 'center' : ''};
  margin: ${isMobile ? '0 0 20px' : '0 32px 0'};
  height: ${isMobile ? '73px' : ''};
  background-color: ${isMobile ? 'white' : ''};
`;

const ToggleButton = styled.button`
  cursor: pointer;
  display: flex;
  margin-left: ${isMobile ? '6px' : '12px'};
  margin-right: ${isMobile ? '6px' : 0};
  border-radius: 10px;
  background-color: ${(props) => props.background};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  height: 22px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const ListLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(368px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '16px'};
  padding: 'initial';
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  align-items: ${isMobile ? 'center' : ''};
`;

const filters = [
  MatchingSuggestionState.pending,
  MatchingSuggestionState.approved,
  MatchingSuggestionState.declined,
  MatchingSuggestionState.canceled,
];

export const convertStateToComponentStyle = (state, matchingType) => {
  if (matchingType === MatchingType.applied) {
    switch (state) {
      case MatchingApplicationState.declined:
        return {
          text: matchingType === MatchingType.applied ? '반려' : '거절',
          color: '#ff4d4f',
          background: '#ffccc7',
        };
      case MatchingApplicationState.canceled:
        return { text: '취소', color: '#373334', background: '#f2f2f2' };
      case MatchingApplicationState.approved:
        return {
          text: matchingType === MatchingType.applied ? '승인' : '수락',
          color: '#57a0f5',
          background: '#e6f7ff',
        };
      case MatchingApplicationState.pending:
        return { text: '대기', color: '#52c41a', background: '#f6ffed' };
      default:
        return { text: '대기', color: '#52c41a', background: '#f6ffed' };
    }
  } else {
    switch (state) {
      case MatchingSuggestionState.declined:
        return {
          text: matchingType === MatchingType.applied ? '반려' : '거절',
          color: '#ff4d4f',
          background: '#ffccc7',
        };
      case MatchingSuggestionState.canceled:
        return { text: '취소', color: '#373334', background: '#f2f2f2' };
      case MatchingSuggestionState.approved:
        return {
          text: matchingType === MatchingType.applied ? '승인' : '수락',
          color: '#57a0f5',
          background: '#e6f7ff',
        };
      case MatchingSuggestionState.pending:
        return { text: '대기', color: '#52c41a', background: '#f6ffed' };
      default:
        return { text: '대기', color: '#52c41a', background: '#f6ffed' };
    }
  }
};

const SuggestionsTab = () => {
  const [timestampFlag, setTimestampFlag] = useState(new Date());
  const { user } = useAuth();
  const [getMatchingsByStudent, { data: getMatchingsByStudentData }] =
    useLazyQuery(GET_MATCHINGS_BY_STUDENT);
  useEffect(() => {
    getMatchingsByStudent({
      variables: {
        studentId: user.id,
        matchingType: MatchingType.suggested,
      },
    });
  }, [user.id, getMatchingsByStudent]);
  const [toggleFilters, setToggleFilters] = useState(
    new Set([
      MatchingSuggestionState.pending,
      MatchingSuggestionState.approved,
      MatchingSuggestionState.declined,
      MatchingSuggestionState.canceled,
    ]),
  );
  const handleClickToggleButton = (item) => {
    if (!toggleFilters.has(item)) {
      setToggleFilters((prev) => new Set(prev.add(item)));
    } else {
      setToggleFilters((prev) => {
        return new Set([...prev].filter((x) => x !== item));
      });
    }
  };
  const [declineMatching, { error: declineMatchingError }] = useMutation(
    DECLINE_MATCHING,
    {
      onCompleted: () => {
        getMatchingsByStudent({
          variables: {
            studentId: user.id,
            matchingType: MatchingType.suggested,
          },
        });
        setDecliningMatchingId(0);
      },
    },
  );
  const [approveMatching, { error: approveMatchingError }] = useMutation(
    ACCEPT_MATCHING,
    {
      onCompleted: () => {
        getMatchingsByStudent({
          variables: {
            studentId: user.id,
            matchingType: MatchingType.suggested,
          },
        });
        setApprovingMatchingId(0);
      },
    },
  );
  useEffect(() => {
    if (declineMatchingError) {
      if (
        declineMatchingError?.graphQLErrors?.[0]?.extensions?.code ===
        'NO_PERMISSION'
      ) {
        alert('권한이 없습니다.');
      }
      setDecliningMatchingId(0);
      getMatchingsByStudent({
        variables: {
          studentId: user.id,
          matchingType: MatchingType.suggested,
        },
      });
    }
  }, [declineMatchingError, timestampFlag]);
  useEffect(() => {
    if (approveMatchingError) {
      if (
        approveMatchingError?.graphQLErrors?.[0]?.extensions?.code ===
        'NO_PERMISSION'
      ) {
        alert('권한이 없습니다.');
      }
      setApprovingMatchingId(0);
      getMatchingsByStudent({
        variables: {
          studentId: user.id,
          matchingType: MatchingType.suggested,
        },
      });
    }
  }, [approveMatchingError, timestampFlag]);
  const [decliningMatchingId, setDecliningMatchingId] = useState(0);
  const [approvingMatchingId, setApprovingMatchingId] = useState(0);

  return (
    <Layout>
      <FilterLayout>
        {!isMobile && <Spacer />}
        {filters.map((item) => (
          <ToggleButton
            color={
              convertStateToComponentStyle(item, MatchingType.suggested).color
            }
            background={
              convertStateToComponentStyle(item, MatchingType.suggested)
                .background
            }
            onClick={(e) => {
              handleClickToggleButton(item);
              e.stopPropagation();
            }}
          >
            {toggleFilters.has(item) && <CheckOutlined />}
            {convertStateToComponentStyle(item, MatchingType.suggested).text}
          </ToggleButton>
        ))}
      </FilterLayout>
      {!isMobile && <Divider />}
      <ListLayout>
        {getMatchingsByStudentData?.matchingsByStudent
          ?.filter((item) => toggleFilters.has(item?.matchingState))
          ?.map((item) => (
            <StudentMatchingComponent
              matchingType={MatchingType.suggested}
              key={item.id}
              createdAt={item.createdAt}
              jobVacancy={item.jobVacancy}
              matchingState={item.matchingState}
              reasonDeclined={item.reasonDeclined}
              reasonCanceled={item.reasonCanceled}
              interviewSuggestion={item?.interviewSuggestion}
              onClickBottomButton={() => {
                if (item.matchingState === MatchingSuggestionState.pending) {
                  setDecliningMatchingId(item.id);
                }
              }}
              onClickBottomLeftButton={() => {
                if (item.matchingState === MatchingSuggestionState.pending) {
                  setApprovingMatchingId(item.id);
                }
              }}
            />
          ))}
      </ListLayout>
      <Modal
        isOpen={decliningMatchingId > 0}
        onClose={() => setDecliningMatchingId(0)}
        radius="30px"
      >
        <SmallModalWithTextareaLayout
          title="면접 제안 거절"
          description={'면접 제안을 거절하시겠습니까?'}
          onClickLeftButton={() => setDecliningMatchingId(0)}
          placeholder={'거절 사유를 입력해주세요.'}
          onClickRightButton={(text) => () => {
            setTimestampFlag(new Date());
            declineMatching({
              variables: {
                matchingId: decliningMatchingId,
                declineReason: text,
              },
            });
          }}
        />
      </Modal>
      <Modal
        isOpen={approvingMatchingId > 0}
        onClose={() => setApprovingMatchingId(0)}
        radius="30px"
      >
        <SmallModalLayout
          title="면접 제안 수락"
          description={'면접 제안을 수락하시겠습니까?'}
          setDescriptionCenter={true}
          onClickLeftButton={() => setApprovingMatchingId(0)}
          onClickRightButton={() => {
            setTimestampFlag(new Date());
            approveMatching({
              variables: {
                matchingId: approvingMatchingId,
              },
            });
          }}
        />
      </Modal>
    </Layout>
  );
};

export default SuggestionsTab;
