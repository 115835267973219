import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { JOB_VACANCIES_BY_COMPANY } from '../../../queries/jobVacancy';
import { CheckOutlined, FormOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_BOOTH } from '../../../queries/booth';
import { BoothStep } from '../../../constants/static';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const RecruitsLayout = styled.div`
  width: 469px;
  height: 196px;
  padding: 0 24px;
  border-radius: 10px;
  background: #fbfbfb;
  overflow: scroll;
  margin: 34px auto;
`;

const RecruitmentItem = styled.div`
  height: 32px;
  background-color: ${(props) => (props.selected ? '#fff1f0' : 'white')};
  padding: 0 12px;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 13px;
  border-radius: 10px;
  cursor: pointer;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 366px;
  }
`;

const AddRecruitmentButton = styled.button`
  width: 422px;
  height: 32px;
  border-radius: 10px;
  margin-top: 13px;
  margin-bottom: 13px;
  border: 1px dashed #fe706f;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background-color: white;
  color: #fe706f;
  outline: none;
  cursor: pointer;
  &:hover {
    background-color: #fe706f;
    color: white;
    border: none;
  }
`;

const RecruitmentInformation = ({ booth, isCreating, getBooths }) => {
  const [recruits, setRecruits] = useState([]);
  const [updateBooth, { data }] = useMutation(UPDATE_BOOTH);
  const [getJobVacanciesByCompany, { data: getJobVacanciesByCompanyData }] =
    useLazyQuery(JOB_VACANCIES_BY_COMPANY);
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getJobVacanciesByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user, getJobVacanciesByCompany]);
  useEffect(() => {
    setRecruits(booth?.jobVacancies ?? []);
  }, [booth]);

  const onClickRightButton = () => {
    if (isCreating) {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            jobVacancyIds: recruits.map((item) => item.id),
            step: BoothStep.managerInformation,
          },
        },
      });
    } else {
      updateBooth({
        variables: {
          id: booth.id,
          boothInput: {
            jobVacancyIds: recruits.map((item) => item.id),
          },
        },
      });
    }
  };

  const onClickLeftButton = () => {
    updateBooth({
      variables: {
        id: booth.id,
        boothInput: {
          jobVacancyIds: recruits.map((item) => item.id),
          step: BoothStep.online,
        },
      },
    });
  };

  useEffect(() => {
    if (data) {
      getBooths();
    }
  }, [data]);

  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>부스와 관련된 채용 공고를 모두 선택해 주세요.</p>
      </TitleLaout>
      <RecruitsLayout>
        {getJobVacanciesByCompanyData?.jobVacanciesByCompany?.map((item) => (
          <RecruitmentItem
            key={item.id}
            selected={recruits.findIndex((a) => a.id === item.id) > -1}
            onClick={() =>
              recruits.findIndex((a) => a.id === item.id) > -1
                ? setRecruits((prev) => prev.filter((a) => a.id !== item.id))
                : setRecruits((prev) => prev.concat(item))
            }
          >
            <p>{item.position}</p>
            {recruits.findIndex((a) => a.id === item.id) > -1 && (
              <CheckOutlined
                style={{ color: '#fe706f', marginRight: 0, marginLeft: 'auto' }}
              />
            )}
          </RecruitmentItem>
        ))}
        <AddRecruitmentButton
          onClick={() => {
            updateBooth({
              variables: {
                id: booth.id,
                boothInput: {
                  jobVacancyIds: recruits.map((item) => item.id),
                },
              },
            });
            history.push('/hire/manage-hire');
          }}
        >
          <FormOutlined style={{ marginRight: '10px' }} />
          새로운 채용 공고 작성하기(기존 내용 저장)
        </AddRecruitmentButton>
      </RecruitsLayout>
      <ButtonLayout>
        {isCreating ? (
          <>
            <BottomLeftButton onClick={onClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={onClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        ) : (
          <BottomCenterButton onClick={onClickRightButton} type="primary">
            완료
          </BottomCenterButton>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default RecruitmentInformation;
