import { CheckOutlined, FormOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import Icon from '../../../components/Icon';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_BOOTHS_BY_COMPANY,
  SET_BOOTHS_TO_ORDER,
} from '../../../queries/booth';
import useAuth from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import Modal from '../../../components/Modal';

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  min-height: ${(props) => (props.isModal ? '527px' : '527px')};
  height: 100%;
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const Divider = styled.div`
  margin: 30px 0;
  height: 1px;
  background-color: #ededed;
`;

const BodyLayout = styled.div`
  padding: 0 80px 23px;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const FairTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
  border-radius: 0px;
  text-align: left;
  margin-bottom: 16px;
`;

const FlexLayout = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const Badge = styled.div`
  height: 22px;
  border-radius: 35px;
  padding: 0 8px;
  margin-right: ${(props) => (props.isOnline ? '48px' : '37px')};
  background-color: ${(props) =>
    props.isRejected ? '#f2f2f2' : props.isOnline ? '#fff7e6' : '#F9F0FF'};
  border: 1px solid
    ${(props) =>
      props.isRejected ? '#aaaaaa' : props.isOnline ? '#ffa171' : '#D3ADF7'};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) =>
    props.isRejected ? '#373334' : props.isOnline ? '#ffa171' : '#722ED1'};
`;

const DateLayout = styled.div`
  display: grid;
  width: 520px;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px 7px;
  div {
    margin-right: 10px;
    height: 32px;
    width: 96px;
    left: 39px;
    top: 45px;
    border-radius: 10px;
    padding: 5px, 12px, 5px, 12px;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isRejected ? '#767676' : '#262626')};
  }
`;

const BoothListLayout = styled.div`
  display: flex;
  height: 196px;
  width: 803px;
  padding: 13px 22px;
  border-radius: 10px;
  background-color: #fbfbfb;
`;

const ListLayout = styled.div`
  height: 100%;
  overflow-y: scroll;
  width: 619px;
`;

const Item = styled.div`
  height: 32px;
  border-radius: 10px;
  background-color: ${(props) => (props.selected ? '#fff1f0' : '#ffffff')};
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 700 : 400)};
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-top: 4px;
  cursor: ${(props) => (props.isDisabled ? '' : 'pointer')};
`;

const ExhibitionCount = styled.div`
  width: 112px;
  height: 32px;
  border-radius: 10px;
  padding: 5px 10px 5px 14px;
  background-color: #fbfbfb;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  p {
    color: #fe706f;
    margin-left: 7px;
  }
`;

const BottomButton = styled(Button)`
  margin: 40px auto;
  border-radius: 10px;
  width: 96px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  height: 50px;
`;

const AddBoothButton = styled.button`
  height: 32px;
  width: 100%;
  border-radius: 10px;
  margin-top: 4px;
  margin-bottom: 13px;
  border: 1px dashed #fe706f;
  padding: 0 12px;
  display: flex;
  align-items: center;
  background-color: white;
  color: #fe706f;
  outline: none;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #fe706f;
    color: white;
    border: none;
  }
`;

const ModalLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const ExhibitBoothModal = ({ editingOrder, setEditingOrder }) => {
  const [getBooths, { data: getBoothsByCompanyData }] = useLazyQuery(
    GET_BOOTHS_BY_COMPANY,
  );
  useEffect(() => {
    setSelectedBooths(editingOrder?.booths?.map((item) => item.id) ?? []);
  }, [editingOrder]);
  const [setBoothsToOrder, { data: setBoothsToOrderData, error }] =
    useMutation(SET_BOOTHS_TO_ORDER);
  const { user } = useAuth();
  useEffect(() => {
    getBooths({
      variables: {
        id: user.id,
      },
    });
  }, [user.id, getBooths]);
  const [selectedBooths, setSelectedBooths] = useState([]);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  useEffect(() => {
    if (
      error &&
      (error?.graphQLErrors?.[0]?.extensions?.code === 'BOOTH_ALREADY_EXISTS' ||
        error?.graphQLErrors?.[0]?.extensions?.code ===
          'COUNSEL_RESERVATION_EXISTS')
    ) {
      setIsErrorModalOpened(true);
    } else if (setBoothsToOrderData) {
      setEditingOrder(null);
    }
  }, [setBoothsToOrderData, error]);
  const history = useHistory();
  return (
    <EditingBodyLayout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>부스등록</Title>
      </TitleLayout>
      <Divider />
      <BodyLayout>
        <FairTitle>{editingOrder?.title}</FairTitle>
        <FlexLayout>
          <Badge isOnline={editingOrder?.isOnline}>
            {editingOrder?.isOnline ? '온라인' : '오프라인'}
          </Badge>
          <div style={{ flex: 1 }} />
          <DateLayout>
            {editingOrder?.dates?.map((item) => (
              <div>{item}</div>
            ))}
          </DateLayout>
        </FlexLayout>
        <BoothListLayout>
          <ExhibitionCount>
            전시 부스{' '}
            <p>
              {selectedBooths.length}/{editingOrder?.totalBoothCount}
            </p>
          </ExhibitionCount>
          <div style={{ width: '33px' }} />
          <ListLayout>
            {getBoothsByCompanyData?.boothsByCompany
              ?.filter(
                (item) =>
                  item.step === 'COMPLETE' &&
                  item.isOnline === editingOrder?.isOnline,
              )
              .map((item) => (
                <Item
                  key={item.id}
                  isDisabled={
                    selectedBooths.length >= editingOrder?.totalBoothCount &&
                    selectedBooths.findIndex((a) => a === item.id) < 0
                  }
                  selected={selectedBooths.findIndex((a) => a === item.id) > -1}
                  onClick={() =>
                    setSelectedBooths((prev) => {
                      if (prev.findIndex((a) => a === item.id) > -1) {
                        return prev.filter((b) => b !== item.id);
                      }
                      if (
                        selectedBooths.length < editingOrder?.totalBoothCount
                      ) {
                        return prev.concat(item.id);
                      }
                      return prev;
                    })
                  }
                >
                  {item.title}
                  {selectedBooths.findIndex((a) => a === item.id) > -1 && (
                    <CheckOutlined
                      style={{
                        marginRight: 0,
                        marginLeft: 'auto',
                        color: '#fe706f',
                      }}
                    />
                  )}
                </Item>
              ))}
            <AddBoothButton
              onClick={() => {
                history.push('/booth-and-counsel/manage-booth');
              }}
            >
              <FormOutlined style={{ marginRight: '10px' }} />
              새로운 부스 생성하기
            </AddBoothButton>
          </ListLayout>
        </BoothListLayout>
        <BottomButton
          type="primary"
          onClick={() => {
            setBoothsToOrder({
              variables: {
                orderId: editingOrder.id,
                boothIds: selectedBooths,
              },
            });
          }}
        >
          완료
        </BottomButton>
      </BodyLayout>
      <Modal
        width={'400px'}
        height={'300px'}
        isOpen={isErrorModalOpened}
        onClose={() => {
          setIsErrorModalOpened(false);
          setEditingOrder(null);
        }}
      >
        <ModalLayout>
          <p style={{ margin: 'auto 24px 8px 24px', textAlign: 'center' }}>
            {error?.graphQLErrors?.[0]?.message
              ?.split(':')?.[1]
              ?.split('\n')
              ?.map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                );
              })}
          </p>
          <BottomButton
            type="primary"
            style={{ marginTop: 'auto', marginBottom: '24px' }}
            onClick={() => {
              setIsErrorModalOpened(false);
              setEditingOrder(null);
            }}
          >
            확인
          </BottomButton>
        </ModalLayout>
      </Modal>
    </EditingBodyLayout>
  );
};

export default ExhibitBoothModal;
