import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';
import Background from '../../../../img/SignUpSuccessModalBackground.png';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    top: 67px;
  }
`;

const Text = styled.p`
  font-family: Noto Sans KR;
  position: absolute;
  transform: translate(-50%, 0);
  top: 40px;
  left: 50%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: 136px;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
  margin-top: 160px;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const MobileProfileComplete = ({
  id,
  refetchProfile,
  setIsShowingMobileCompleteForm,
}) => {
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  const handleClickButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.educations,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      setIsShowingMobileCompleteForm(false);
    }
  }, [data]);
  return (
    <Layout>
      <img src={Background} alt="background" />
      <Text>
        기본정보와 학력 작성을 완료하였습니다! 이후 정보는 PC에서 작성을
        진행해주세요.
      </Text>
      <ButtonLayout>
        <BottomLeftButton onClick={handleClickButton}>이전</BottomLeftButton>
        <BottomRightButton
          type="primary"
          onClick={() => setIsShowingMobileCompleteForm(false)}
        >
          완료
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default MobileProfileComplete;
