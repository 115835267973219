import React from 'react';
import styled from 'styled-components';
import Img from '../img/MobileDisabled.png';

const Layout = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 320px;
  height: 288px;
  margin-bottom: 48px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 23px 0px #0000000a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 156px;
    margin: 42px auto 15px;
  }
  p {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #373334;
  }
`;

const MobileDisabled = () => {
  return (
    <Layout>
      <Content>
        <img src={Img} alt="background" />
        <p>
          모바일 화면을 지원하지 않는 페이지입니다.
          <br />
          PC에서 접속 부탁드립니다
        </p>
      </Content>
    </Layout>
  );
};

export default MobileDisabled;
