import React, { useState, useEffect, useMemo } from 'react';
import Modal from '../Modal';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal';
import SignUpSuccessModal from './SignUpSuccessModal';
import { isMobile } from 'react-device-detect';
import FindPasswordModal from './FindPasswordModal';
import FindPasswordSuccessModal from './FindPasswordSuccessModal';

const AuthModal = ({ initialPage, isOpen, onClose }) => {
  const [page, setPage] = useState(initialPage);
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setPage(initialPage);
      }, 500);
    }
  }, [isOpen, initialPage]);
  const height = useMemo(() => {
    if (page === 'signIn') {
      return '541px';
    } else if (page === 'signUp') {
      return isMobile ? '600px' : '760px';
    } else if (page === 'findPassword') {
      return isMobile ? '541px' : '640px';
    } else {
      return '225px';
    }
  }, [page]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={isMobile ? '341px' : '464px'}
      height={height}
      closeButtonTransparent
    >
      {page === 'signIn' ? (
        <SignInModal
          isOpen={isOpen}
          onClose={onClose}
          handlePage={(newPage) => setPage(newPage)}
        />
      ) : page === 'signUp' ? (
        <SignUpModal
          handlePage={(newPage) => setPage(newPage)}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : page === 'findPassword' ? (
        <FindPasswordModal
          handlePage={(newPage) => setPage(newPage)}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : page === 'findPasswordSuccess' ? (
        <FindPasswordSuccessModal handlePage={(newPage) => setPage(newPage)} />
      ) : (
        <SignUpSuccessModal handlePage={(newPage) => setPage(newPage)} />
      )}
    </Modal>
  );
};

export default AuthModal;
