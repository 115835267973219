import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CompanySurveyPage from './CompanySurveyPage';
import StudentSurveyPage from './StudentSurveyPage';

const Survey = () => {
  return (
    <Switch>
      <Route exact path="/survey/student">
        <StudentSurveyPage />
      </Route>
      <Route exact path="/survey/company">
        <CompanySurveyPage />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Survey;
