import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input, Select } from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  SearchOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import Popover from '../../../../components/Popover';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { SEARCH_UNIVERSITIES } from '../../../../queries/university';
import { StudentProfileStep } from '../../../../constants/static';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { isMobile } from 'react-device-detect';

const { RangePicker } = DatePicker;
const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  margin: ${isMobile ? '0 auto 12px' : '0 0 0 auto'};
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  flex-flow: ${isMobile ? 'column' : 'row'};
  background-color: ${(props) =>
    props.isDeletable && isMobile ? 'rgba(251, 251, 251, 0.9)' : ''};
  padding: ${isMobile ? '27px 21px 5px' : '5px 50px 5px 86px'};
  ${DeleteRowButton} {
    opacity: ${(props) => (props.isDeletable && isMobile ? '100%' : '0%')};
  }
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const StyledRangePicker = styled(RangePicker)`
  height: 40px;
  border-radius: 10px;
`;

const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
`;

const SearchInputLayout = styled.div`
  display: flex;
  margin-left: ${isMobile ? '' : '11px'};
  margin-top: ${isMobile ? '16px' : ''};
  border-radius: 10px;
  width: ${isMobile ? '' : '172px'};
  height: 40px;
  overflow: hidden;
  input {
    border-radius: 10px 0 0 10px;
    width: ${isMobile ? '' : '132px'};
    font-size: 14px;
  }
  button {
    border-radius: 0 10px 10px 0;
    height: 40px;
  }
`;

const SearchPopoverLayout = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow-y: scroll;
  padding: 8px 16px;
  background-color: white;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border: 1px solid #f1f1f5;
  p {
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    margin: 4px 0;
  }
`;

const DisabledText = styled.p`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0;
  color: #8c8c8c;
`;

const UniversityTextLayout = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${isMobile ? '' : '11px'};
  margin-top: ${isMobile ? '16px' : ''};
  padding: 10px 14px 10px 8px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background: #fcfcfc;
  p {
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const ManualInputLayout = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${isMobile ? '' : '11px'};
  margin-top: ${isMobile ? '16px' : ''};
  padding: 10px 14px 10px 8px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background: #ffffff;
  p {
    min-width: 120px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 6px;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const MajorInput = styled(Input)`
  border-radius: 10px;
  width: ${isMobile ? '' : '144px'};
  font-size: 14px;
  height: 40px;
  margin-left: ${isMobile ? '' : '11px'};
  margin-top: ${isMobile ? '16px' : ''};
`;

const StyledSelect = styled(Select)`
  margin-right: ${isMobile ? '' : '18px'};
  div {
    height: 40px !important;
    border-radius: 10px !important;
    padding-top: 4px !important;
    input {
      margin-top: 4px !important;
    }
  }
  width: ${isMobile ? '' : '120px'};
  border-radius: 10px;
  height: 40px;
  margin-left: ${isMobile ? '' : '11px'};
  margin-top: ${isMobile ? '16px' : '0'};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Education = ({
  education,
  updateEducation,
  deleteEducation,
  isDeletable,
}) => {
  const buttonRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [isSearchPopoverOpened, setIsSearchPopoverOpened] = useState(false);
  const [manual, setManual] = useState(false);
  const [searchUniversities, { data: universities }] =
    useLazyQuery(SEARCH_UNIVERSITIES);
  useEffect(() => {
    if (education.onGoing) {
      updateEducation({
        startAt: education.startAt,
        endAt: '',
        university: education.university,
        major: education.major,
        degree: education.degree,
        onGoing: education.onGoing,
      });
    }
  }, [education.onGoing]);
  return (
    <FlexLayout isDeletable={isDeletable}>
      {education.onGoing ? (
        <StyledDatePicker
          placeholder={'시작년월'}
          picker="month"
          value={
            education.startAt !== '' ? moment(education.startAt) : undefined
          }
          onChange={(e) => {
            updateEducation({
              startAt: e ? moment(e).format('YYYY-MM') : '',
              endAt: education.endAt,
              university: education.university,
              major: education.major,
              degree: education.degree,
              onGoing: education.onGoing,
            });
          }}
        />
      ) : (
        <StyledRangePicker
          placeholder={['시작년월', '종료년월']}
          picker="month"
          value={[
            education.startAt !== '' ? moment(education.startAt) : undefined,
            education.endAt !== '' ? moment(education.endAt) : undefined,
          ]}
          onChange={(e) => {
            updateEducation({
              startAt: e ? moment(e[0]).format('YYYY-MM') : '',
              endAt: e ? moment(e[1]).format('YYYY-MM') : '',
              university: education.university,
              major: education.major,
              degree: education.degree,
              onGoing: education.onGoing,
            });
          }}
        />
      )}
      <Checkbox
        style={{
          margin: isMobile ? '8px 0 0 0' : '8px 0 0 16px',
          minWidth: '72px',
        }}
        onClick={(e) => {
          updateEducation({
            startAt: education.startAt,
            endAt: education.endAt,
            university: education.university,
            major: education.major,
            degree: education.degree,
            onGoing: !education.onGoing,
          });
        }}
        checked={education.onGoing}
      >
        재학중
      </Checkbox>
      {manual ? (
        <ManualInputLayout>
          <input
            style={{
              border: 'none',
              marginRight: '8px',
              padding: 0,
              fontSize: '14px',
              fontWeight: 500,
              outline: 'none',
              width: isMobile ? '' : '118px',
            }}
            value={education.university ?? ''}
            onChange={(e) =>
              updateEducation({
                startAt: education.startAt,
                endAt: education.endAt,
                university: e.currentTarget?.value,
                major: education.major,
                degree: education.degree,
                onGoing: education.onGoing,
              })
            }
          />
          <button
            onClick={() => {
              setManual(false);
              setSearchValue('');
              setIsSearchPopoverOpened(false);
              updateEducation({
                startAt: education.startAt,
                endAt: education.endAt,
                university: '',
                major: education.major,
                degree: education.degree,
                onGoing: education.onGoing,
              });
            }}
          >
            <SyncOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
          </button>
        </ManualInputLayout>
      ) : (
        <>
          {education.university === '' ? (
            <SearchInputLayout>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    searchUniversities({
                      variables: {
                        keyword: searchValue,
                      },
                    });
                    setIsSearchPopoverOpened(true);
                  }
                }}
                placeholder="대학교"
              />
              <Button
                ref={buttonRef}
                disabled={searchValue === ''}
                type="primary"
                onClick={() => {
                  searchUniversities({
                    variables: {
                      keyword: searchValue,
                    },
                  });
                  setIsSearchPopoverOpened(true);
                }}
              >
                <SearchOutlined />
              </Button>
              <Popover
                open={isSearchPopoverOpened}
                width="174px"
                height="182px"
                onClose={() => setIsSearchPopoverOpened(false)}
                anchorRef={buttonRef}
                horizontalAlign="right"
                verticalAlign="bottom"
                removePadding
                borderRadius="0 0 6px 6px"
              >
                <SearchPopoverLayout>
                  {universities?.searchUniversities?.length === 0 && (
                    <DisabledText>결과 없음</DisabledText>
                  )}
                  {universities?.searchUniversities?.map((univ) => (
                    <p
                      onClick={() => {
                        updateEducation({
                          startAt: education.startAt,
                          endAt: education.endAt,
                          university: univ.university,
                          major: education.major,
                          degree: education.degree,
                          onGoing: education.onGoing,
                        });
                        setSearchValue('');
                        setIsSearchPopoverOpened(false);
                      }}
                    >
                      {univ.university}
                    </p>
                  ))}
                  <button
                    style={{
                      border: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      color: '#fe706f',
                      padding: 0,
                      fontSize: 12,
                    }}
                    onClick={() => {
                      updateEducation({
                        startAt: education.startAt,
                        endAt: education.endAt,
                        university: searchValue,
                        major: education.major,
                        degree: education.degree,
                        onGoing: education.onGoing,
                      });
                      setManual(true);
                    }}
                  >
                    직접 입력
                  </button>
                </SearchPopoverLayout>
              </Popover>
            </SearchInputLayout>
          ) : (
            <UniversityTextLayout>
              <p>{education.university}</p>
              <button
                onClick={() =>
                  updateEducation({
                    startAt: education.startAt,
                    endAt: education.endAt,
                    university: '',
                    major: education.major,
                    degree: education.degree,
                    onGoing: education.onGoing,
                  })
                }
              >
                <CloseOutlined style={{ fontSize: '12px', color: '#8c8c8c' }} />
              </button>
            </UniversityTextLayout>
          )}
        </>
      )}

      <MajorInput
        value={education.major}
        placeholder="학과"
        onChange={(e) =>
          updateEducation({
            startAt: education.startAt,
            endAt: education.endAt,
            university: education.university,
            major: e.currentTarget.value,
            degree: education.degree,
            onGoing: education.onGoing,
          })
        }
      />
      <StyledSelect
        placeholder="학위"
        value={education.degree === '' ? undefined : education.degree}
        onChange={(value) =>
          updateEducation({
            startAt: education.startAt,
            endAt: education.endAt,
            university: education.university,
            major: education.major,
            degree: value,
            onGoing: education.onGoing,
          })
        }
      >
        <Option value="학사">학사</Option>
        <Option value="학/석사연계">학/석사연계</Option>
        <Option value="석사">석사</Option>
        <Option value="박사">박사</Option>
        <Option value="석/박통합">석/박통합</Option>
        <Option value="박사후(포닥)">박사후(포닥)</Option>
      </StyledSelect>
      <DeleteRowButton
        disabled={!isDeletable}
        onClick={() => deleteEducation()}
      >
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </FlexLayout>
  );
};
const Educations = ({
  id,
  refetchProfile,
  profile,
  isModal,
  closeModal,
  setIsShowingMobileCompleteForm,
}) => {
  const [educations, setEducations] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.educations?.length > 0) {
      profile?.educations?.forEach((education) => {
        setEducations((previousEducations) => {
          return [
            ...previousEducations,
            {
              startAt: education.startAt ?? '',
              endAt: education.endAt ?? '',
              university: education.university,
              major: education.major,
              degree: education.degree,
            },
          ];
        });
      });
    } else {
      setEducations([
        {
          startAt: '',
          endAt: '',
          university: '',
          major: '',
          degree: '',
          onGoing: false,
        },
      ]);
    }
  }, [profile]);
  const handleClickLeftButton = () => {
    if (
      educations.findIndex(
        (education) =>
          education.startAt === '' ||
          (education.endAt === '' && !education.onGoing) ||
          education.university === '' ||
          education.major === '' ||
          education.degree === '',
      ) > -1
    ) {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: StudentProfileStep.matchingInfo,
          },
        },
      });
    } else {
      updateStudentProfile({
        variables: {
          id: id,
          studentProfileInput: {
            step: StudentProfileStep.matchingInfo,
            educations: educations.map((edu) => ({
              startAt: edu.startAt,
              endAt: edu.endAt,
              university: edu.university,
              major: edu.major,
              degree: edu.degree,
            })),
          },
        },
      });
    }
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : isMobile
            ? StudentProfileStep.skills
            : StudentProfileStep.labInfo,
          educations: educations.map((edu) => ({
            startAt: edu.startAt,
            endAt: edu.endAt,
            university: edu.university,
            major: edu.major,
            degree: edu.degree,
          })),
        },
      },
    });
  };
  useEffect(() => {
    // if (
    //   isMobile &&
    //   data?.updateStudentProfile?.step === StudentProfileStep.recruitmentTypes
    // ) {
    //   setIsShowingMobileCompleteForm(true);
    // }
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <BodyLayout>
        <p
          style={{
            marginLeft: isMobile ? '24px' : '80px',
            marginBottom: '24px',
            fontSize: '15px',
            fontWeight: 500,
          }}
        >
          학사 &gt; 석사 &gt; 박사 순으로 기재 바랍니다.
        </p>
        {educations.map((education, index) => (
          <>
            <Education
              key={index}
              isDeletable={educations.length > 1}
              deleteEducation={() =>
                setEducations((previousEducations) =>
                  previousEducations.filter(
                    (_, prevIndex) => index !== prevIndex,
                  ),
                )
              }
              education={education}
              updateEducation={(newEducation) =>
                setEducations((previousEducations) => {
                  return previousEducations.map(
                    (previousEducation, previousIndex) =>
                      previousIndex === index
                        ? newEducation
                        : previousEducation,
                  );
                })
              }
            />
            {index + 1 !== educations.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setEducations((prevEducations) => {
            return [
              ...prevEducations,
              {
                startAt: '',
                endAt: '',
                university: '',
                major: '',
                degree: '',
                onGoing: false,
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            disabled={
              educations.findIndex(
                (education) =>
                  education.startAt === '' ||
                  (education.endAt === '' && !education.onGoing) ||
                  education.university === '' ||
                  education.major === '' ||
                  education.degree === '',
              ) > -1
            }
            onClick={handleClickRightButton}
            type="primary"
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton
              disabled={
                educations.findIndex(
                  (education) =>
                    education.startAt === '' ||
                    (education.endAt === '' && !education.onGoing) ||
                    education.university === '' ||
                    education.major === '' ||
                    education.degree === '',
                ) > -1
              }
              onClick={handleClickRightButton}
              type="primary"
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Educations;
