import React, { useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../../../hooks/useInput';
import InputBig from '../../../../components/InputBig';
import { Button, Checkbox } from 'antd';
import { emailRegex, phoneNumberRegex } from '../../../../constants/regex';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CompanyProfileStep } from '../../../../constants/static';
import { UPDATE_COMPANY_PROFILE } from '../../../../queries/company';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const InputLayout = styled.div`
  width: 294px;
  margin: 0 auto 16px;
`;

const DescriptionText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3a3a3a;
  text-align: center;
  margin-top: 26px;
  margin-bottom: 26px;
`;

const FlexLayout = styled.div`
  display: flex;
  width: 280px;
  margin: 0 auto;
`;

const Spacer = styled.div`
  flex: 1;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const ManagerInfo = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [{ name, phoneNumber, email }, onChange] = useInputs({
    name: profile?.managerInfo?.name ?? '',
    phoneNumber: profile?.managerInfo?.phoneNumber ?? '',
    email: profile?.managerInfo?.email ?? '',
  });
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isPhoneNumberChecked, setIsPhoneNumberChecked] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  useEffect(() => {
    setIsPhoneNumberChecked(profile?.managerInfo?.phoneMarketingAgreement);
    setIsEmailChecked(profile?.managerInfo?.emailMarketingAgreement);
  }, [profile]);
  useEffect(() => {
    const regex = new RegExp(phoneNumberRegex);
    if (phoneNumber !== '' && !regex.test(phoneNumber)) {
      setPhoneNumberError('유효하지 않은 연락처입니다.');
    } else {
      setPhoneNumberError('');
    }
  }, [phoneNumber]);
  useEffect(() => {
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailError('유효하지 않은 이메일입니다.');
    } else {
      setEmailError('');
    }
  }, [email]);
  const [updateCompanyProfile, { data }] = useMutation(UPDATE_COMPANY_PROFILE);
  const handleClickNextButton = () => {
    updateCompanyProfile({
      variables: {
        id: id,
        companyProfileInput: {
          step: isModal
            ? CompanyProfileStep.complete
            : CompanyProfileStep.basicCompanyProfile,
          managerInfo: {
            name: name,
            phoneNumber: phoneNumber,
            email: email,
            emailMarketingAgreement: isEmailChecked,
            phoneMarketingAgreement: isPhoneNumberChecked,
          },
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);
  return (
    <Layout>
      <InputLayout>
        <InputBig
          name={'name'}
          value={name}
          onChange={onChange}
          label="담당자 이름"
        />
      </InputLayout>
      <InputLayout>
        <InputBig
          name={'phoneNumber'}
          value={phoneNumber}
          onChange={onChange}
          label="담당자 연락처"
          placeholder={'012-3456-7890'}
          errorMessage={phoneNumberError}
        />
      </InputLayout>
      <InputLayout>
        <InputBig
          name={'email'}
          value={email}
          onChange={onChange}
          label="담당자 이메일"
          errorMessage={emailError}
        />
      </InputLayout>
      <DescriptionText>행사 정보 및 뉴스레터를 받아보시겠어요?</DescriptionText>
      <FlexLayout>
        <Checkbox
          checked={isEmailChecked}
          onChange={(e) => setIsEmailChecked(e.target.checked)}
        >
          이메일 수신 동의
        </Checkbox>
        <Spacer />
        <Checkbox
          checked={isPhoneNumberChecked}
          onChange={(e) => setIsPhoneNumberChecked(e.target.checked)}
        >
          핸드폰 수신 동의
        </Checkbox>
      </FlexLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton
            onClick={handleClickNextButton}
            disabled={email === '' || emailError !== ''}
            type="primary"
          >
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomRightButton
              type="primary"
              onClick={handleClickNextButton}
              disabled={email === '' || emailError !== ''}
            >
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default ManagerInfo;
