import React, { useMemo, useState } from 'react';
import { Button, Checkbox } from 'antd';
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  HomePage,
  ProfilePage,
  FairPage,
  CounselPage,
  MatchingPage,
} from './pages';
import styled from 'styled-components';
import Sidemenu from './components/Sidemenu';
import useAuth from './hooks/useAuth';
import './App.less';
import Header from './components/Header';
import { useEffect } from 'react';
import BoothAndCounsel from './pages/BoothAndCounsel';
import JobVacancyAndMeeting from './pages/JobVacancyAndMeeting';
import { isMobile } from 'react-device-detect';
import { Typography } from 'antd';
import { UserType } from './constants/static';
import CompanyCounselorProfile from './pages/ProfileCounselor';
import MobileDisabled from './pages/MobileDisabled';
import CompanyMatching from './pages/CompanyMatching';
import ManageMatching from './pages/StudentMatching/ManageMatching';
import StudentMatching from './pages/StudentMatching';
import Survey from './pages/Survey';
import Presentation from './pages/Presentation';
import CompanyPresentation from './pages/CompanyPresentation';
import StudentPresentation from './pages/StudentPresentation';
import Advertisement from './pages/Advertisement';
import CompanyAdvertisement from './pages/CompanyAdvertisement';
import Modal from './components/Modal';
import { GET_POPUPS } from './queries/popup';
import { useQuery } from '@apollo/client';

const Layout = styled.div`
  min-height: 100%;
  background-color: #fcfcfc;
`;

const CustomButton = styled(Button)`
  width: 200px;
`;

const BodyLayout = styled.div`
  padding-top: ${isMobile ? '56px' : '64px'};
  height: 100%;
  display: flex;
`;

const ContentLayout = styled.div`
  padding-left: ${(props) => (props.isSidemenuExists ? '259px' : '0')};
  width: 100%;
  height: 100%;
`;

export const PrivateRoute = ({ children, isSignedIn, ...rest }) => {
  const { userData } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user?.id > 0 || userData?.id > 0) {
          return children;
        } else {
          alert('로그인이 필요한 페이지입니다.');
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

const SignOutPage = () => {
  const { setUser } = useAuth();
  const history = useHistory();
  useEffect(() => {
    setUser({ id: 0, email: '', userType: '' });
    localStorage.setItem(
      'user',
      JSON.stringify({
        id: 0,
        email: '',
        userType: '',
      }),
    );
    localStorage.setItem('accessToken', '');
    history.push('/');
  }, [history, setUser]);
  return <div />;
};

const AdvertisementModal = ({ url, index, id, imageUrl }) => {
  const [isAdvertisementModalOpened, setIsAdvertiseModalOpened] =
    useState(false);
  const [advertisementCloseChecked, setAdvertisementCloseChecked] =
    useState(false);
  useEffect(() => {
    const senlabAdvertisementClosedIds = JSON.parse(
      localStorage.getItem('senlabAdvertisementClosedIds'),
    );
    if (
      !senlabAdvertisementClosedIds ||
      senlabAdvertisementClosedIds?.filter((closedId) => closedId === id)
        .length === 0
    ) {
      setIsAdvertiseModalOpened(true);
    }
  }, [id]);

  return (
    <Modal
      shiftIndex={index}
      isOpen={isAdvertisementModalOpened}
      closeButtonTransparent
      onClose={() => {
        if (advertisementCloseChecked) {
          const senlabAdvertisementClosedIds = JSON.parse(
            localStorage.getItem('senlabAdvertisementClosedIds'),
          );
          localStorage.setItem(
            'senlabAdvertisementClosedIds',
            JSON.stringify([...(senlabAdvertisementClosedIds ?? []), id]),
          );
        }
        setIsAdvertiseModalOpened(false);
      }}
      width={isMobile ? '300px' : '400px'}
      height={isMobile ? '460px' : '608px'}
    >
      <div
        style={{
          width: '100%',
          height: isMobile ? '350px' : '560px',
          cursor: url ? 'pointer' : '',
        }}
        onClick={() => {
          if (url) {
            window.open(url, '_blank');
          }
        }}
      >
        <img
          style={{ objectFit: 'contain', width: '100%' }}
          src={imageUrl}
          alt="advertisement"
        />
        <div
          onClick={(e) => e.stopPropagation()}
          style={{ flex: 1, display: 'flex', alignItems: 'center' }}
        >
          <div
            style={{
              height: isMobile ? '34px' : '40px',
              flex: 1,
            }}
          />
          <Checkbox
            style={{ marginRight: '8px' }}
            checked={advertisementCloseChecked}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation?.();
              setAdvertisementCloseChecked(e.target.checked);
            }}
          >
            다시 보지 않기
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { data: getPopupsData } = useQuery(GET_POPUPS);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);
    if (user) {
      auth.setUser({
        id: user.id,
        email: user.email,
        userType: user.userType,
      });
    }
  }, []);
  const isSidemenuExists = useMemo(() => {
    const firstPath = location.pathname.split('/')?.[1] ?? '';
    if (auth.user.id === 0) {
      return false;
    }
    if (isMobile) {
      return false;
    }
    switch (auth.user.userType) {
      case UserType.student:
        return (
          firstPath === 'profile' ||
          firstPath === 'counsel' ||
          firstPath === 'meeting' ||
          firstPath === 'student-presentation-manage'
        );
      case UserType.company:
        return (
          firstPath === 'profile' ||
          firstPath === 'booth-and-counsel' ||
          firstPath === 'hire' ||
          firstPath === 'company-meeting' ||
          firstPath === 'presentation-manage' ||
          firstPath === 'advertisement-manage'
        );
      case UserType.counselor:
        return firstPath === 'profile-counsel' || firstPath === 'counsel';
      default:
        return false;
    }
  }, [auth.user, location]);
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false);
  // useEffect(() => {
  //   const senlabAdvertisementClosedIds = localStorage.getItem(
  //     'senlabAdvertisementClosedIds',
  //   );
  //   console.log(senlabAdvertisementClosedIds);
  //   localStorage.setItem('senlabAdvertisementClosedIds', JSON.[
  //     ...(senlabAdvertisementClosedIds ?? []),
  //     3,
  //   ]);
  // }, []);
  return (
    <Layout>
      {getPopupsData?.popups?.map((item, index) => (
        <AdvertisementModal
          index={index}
          id={item.id}
          url={item.url ?? ''}
          imageUrl={item.image?.url ?? ''}
        />
      ))}
      <Header
        isSignInModalOpened={isSignInModalOpened}
        setIsSignInModalOpened={setIsSignInModalOpened}
      />
      <BodyLayout>
        {isSidemenuExists && <Sidemenu />}
        <ContentLayout isSidemenuExists={isSidemenuExists}>
          <Switch>
            <Route exact path="/">
              <HomePage setIsSignInModalOpened={setIsSignInModalOpened} />
            </Route>
            <Route exact path="/sign-out">
              <SignOutPage />
            </Route>
            <PrivateRoute exact path="/profile-counsel/manage">
              {isMobile ? <MobileDisabled /> : <CompanyCounselorProfile />}
            </PrivateRoute>
            <PrivateRoute path="/profile">
              <ProfilePage />
            </PrivateRoute>
            <PrivateRoute path="/fair">
              <FairPage />
            </PrivateRoute>
            <PrivateRoute path="/matching">
              <MatchingPage />
            </PrivateRoute>
            <PrivateRoute path="/counsel">
              <CounselPage />
            </PrivateRoute>
            <PrivateRoute path="/booth-and-counsel">
              <BoothAndCounsel />
            </PrivateRoute>
            <PrivateRoute path="/hire">
              <JobVacancyAndMeeting />
            </PrivateRoute>
            <PrivateRoute path="/company-meeting">
              {isMobile ? <MobileDisabled /> : <CompanyMatching />}
            </PrivateRoute>
            <PrivateRoute path="/meeting">
              <StudentMatching />
            </PrivateRoute>
            <PrivateRoute path="/presentation-manage/applications">
              <CompanyPresentation />
            </PrivateRoute>
            <PrivateRoute path="/student-presentation-manage/applications">
              <StudentPresentation />
            </PrivateRoute>
            <PrivateRoute path="/advertisement-manage">
              <CompanyAdvertisement />
            </PrivateRoute>
            <Route path="/survey">
              <Survey />
            </Route>
            <PrivateRoute path="/presentation">
              {isMobile &&
              (auth.user.userType === UserType.counselor ||
                auth.user.userType === UserType.company) ? (
                <MobileDisabled />
              ) : (
                <Presentation />
              )}
            </PrivateRoute>
            <PrivateRoute path="/advertisement">
              {isMobile &&
              (auth.user.userType === UserType.counselor ||
                auth.user.userType === UserType.company) ? (
                <MobileDisabled />
              ) : (
                <Advertisement />
              )}
            </PrivateRoute>
            <Route>
              <Typography.Title level={3} style={{ margin: '40px' }}>
                404 Forbidden
              </Typography.Title>
            </Route>
          </Switch>
        </ContentLayout>
      </BodyLayout>
    </Layout>
  );
};

export default App;
