import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../hooks/useInput';
import { Color } from '../../constants/color';
import Input from '../Input';
import { emailRegex, passwordRegex } from '../../constants/regex';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from 'antd';
import Alert from '../Alert';
import { isMobile } from 'react-device-detect';
import {
  SEND_VERIFICATION_EMAIL,
  VERIFY_EMAIL,
  SIGN_UP,
} from '../../queries/auth';
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { EmailType, UserType } from '../../constants/static';
import { GET_APPROVED_UNIVERSITIES } from '../../queries/university';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '42px 36px 14px' : '42px 51px 40px'};
  z-index: 10;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${isMobile ? '12px' : '41px'};
  color: '#3a3a3a';
`;

const SubTitleText = styled.p`
  font-size: 12px;
  color: '#3a3a3a';
  text-align: center;
  margin-bottom: 10px;
`;

const UserTypeSelectLayout = styled.div`
  width: 272px;
  overflow: hidden;
  display: flex;
  border-radius: 10px;
  margin: 0 auto;
`;

const UserTypeSelect = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? Color.Primary : '#f5f5f5')};
  width: 136px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? '#ffffff' : '#bfbfbf')};
  border-left: ${(props) =>
    props.selected ? '' : props.left ? '1px solid #d9d9d9' : ''};
  border-right: ${(props) =>
    props.selected ? '' : props.right ? '1px solid #d9d9d9' : ''};
  border-top: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-bottom: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-radius: ${(props) =>
    props.left ? '10px 0 0 10px' : props.right ? '0 10px 10px 0' : ''};
  margin-bottom: ${isMobile ? '14px' : '46px'};
`;

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: #d9d9d9;
`;

const InputLayout = styled.div`
  margin-bottom: ${isMobile ? '8px' : '22px'};
`;

const BottomText = styled.p`
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: ${isMobile ? '10px' : '12px'};
  color: #ababb6;
  a {
    text-decoration: underline;
    color: #ababb6;
  }
`;

const SignUpModal = ({ handlePage, isOpen, onClose }) => {
  const [
    sendVerificationEmail,
    {
      loading: sendVerificationEmailLoading,
      error: sendVerificationEmailError,
      data: sendVerificationEmailData,
    },
  ] = useMutation(SEND_VERIFICATION_EMAIL);
  const [
    verifyEmail,
    {
      data: verifyEmailData,
      loading: verifyEmailLoading,
      error: verifyEmailError,
    },
  ] = useMutation(VERIFY_EMAIL);
  const [
    signUp,
    { data: signUpData, loading: signUpLoading, error: signUpError },
  ] = useMutation(SIGN_UP);
  const [userType, setUserType] = useState(UserType.student);
  const [
    { email, verificationNumber, password, confirmPassword },
    onChange,
    reset,
  ] = useInputs({
    email: '',
    verificationNumber: '',
    password: '',
    confirmPassword: '',
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [verificationNumberErrorMessage, setVerificationNumberErrorMessage] =
    useState('');
  const passwordErrorMessage = useMemo(() => {
    const regex = new RegExp(passwordRegex);
    return password !== '' && !regex.test(password)
      ? '비밀번호가 형식에 맞지 않습니다.'
      : '';
  }, [password]);
  const confirmPasswordErrorMessage = useMemo(() => {
    return confirmPassword !== '' &&
      password !== '' &&
      password !== confirmPassword
      ? '비밀번호가 일치하지 않습니다.'
      : '';
  }, [password, confirmPassword]);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [sent, setSent] = useState(false);
  useEffect(() => {
    setSent(false);
    setVerificationSuccess(false);
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailErrorMessage('유효하지 않은 이메일입니다.');
    } else {
      setEmailErrorMessage('');
    }
  }, [email]);

  useEffect(() => {
    setVerificationNumberErrorMessage('');
  }, [verificationNumber]);
  useEffect(() => {
    if (
      verifyEmailError?.graphQLErrors?.[0]?.extensions?.code === 'CODE_INVALID'
    ) {
      setVerificationNumberErrorMessage('잘못된 인증번호 입니다.');
    }
  }, [verifyEmailError]);
  useEffect(() => {
    if (
      sendVerificationEmailError?.graphQLErrors?.[0]?.extensions?.code ===
      'UNIVERSITY_NOT_APPROVED'
    ) {
      setEmailErrorMessage('승인되지 않은 대학교입니다.');
    }
  }, [sendVerificationEmailError]);
  useEffect(() => {
    if (verifyEmailData?.verify) {
      setVerificationSuccess(true);
    }
  }, [verifyEmailData]);
  useEffect(() => {
    if (signUpData?.signUp) {
      handlePage('signUpSuccess');
    }
  }, [signUpData, handlePage]);
  useEffect(() => {
    if (
      signUpError?.graphQLErrors?.[0]?.extensions?.code ===
      'EMAIL_ALREADY_EXISTS'
    ) {
      setEmailErrorMessage('이미 가입된 이메일입니다.');
    } else if (
      signUpError?.graphQLErrors?.[0]?.extensions?.code === 'EMAIL_BLOCKED'
    ) {
      setEmailErrorMessage('관리자에 의해 차단 당한 이메일입니다.');
    }
  }, [signUpError]);
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  useEffect(() => {
    reset();
  }, [userType]);
  const { data: universitiesData } = useQuery(GET_APPROVED_UNIVERSITIES);

  return (
    <Layout>
      <BodyLayout>
        <TitleText>회원가입</TitleText>
        <SubTitleText>회원유형</SubTitleText>
        <UserTypeSelectLayout>
          <UserTypeSelect
            selected={userType === UserType.student}
            left
            onClick={() => setUserType(UserType.student)}
          >
            학생
          </UserTypeSelect>
          <Divider />
          <UserTypeSelect
            selected={userType === UserType.company}
            right
            onClick={() => setUserType(UserType.company)}
          >
            기업
          </UserTypeSelect>
        </UserTypeSelectLayout>
        <InputLayout>
          <Input
            name="email"
            value={email}
            onChange={onChange}
            label="이메일"
            placeholder={
              userType === UserType.student
                ? isMobile
                  ? '학교 이메일'
                  : '학교 이메일을 입력해주세요'
                : isMobile
                ? '기업 이메일'
                : '기업 이메일을 입력해주세요'
            }
            errorMessage={emailErrorMessage}
            rightButtonName={
              sendVerificationEmailData && sent ? '재발송' : '메일발송'
            }
            rightButtonLoading={sendVerificationEmailLoading}
            rightButtonDisabled={emailErrorMessage !== '' || email === ''}
            rightButtonOnClick={() => {
              setSent(true);
              sendVerificationEmail({
                variables: {
                  email: email,
                  emailType: EmailType.signUp,
                  userType: userType,
                },
              });
            }}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(emailErrorMessage !== '' || email === '')
              ) {
                setSent(true);
                sendVerificationEmail({
                  variables: {
                    email: email,
                    emailType: EmailType.signUp,
                    userType: userType,
                  },
                });
              }
            }}
          />
          {userType === UserType.student && (
            <p
              style={{
                fontSize: '11px',
                margin: '8px 4px 12px',
                color: 'grey',
              }}
            >
              제휴 학교 학생만 가입이 가능합니다
              <br />
              {universitiesData?.approvedUniversities?.map((item, index) => (
                <>
                  {item.name}
                  {index < universitiesData?.approvedUniversities?.length - 1 &&
                    ', '}
                </>
              ))}
            </p>
          )}
        </InputLayout>
        <InputLayout>
          <Input
            name="verificationNumber"
            value={verificationNumber}
            onChange={onChange}
            label="이메일 인증번호"
            disabled={verifyEmailLoading || verificationSuccess}
            errorMessage={verificationNumberErrorMessage}
            inputRightIcon={
              verificationSuccess && (
                <CheckCircleFilled
                  style={{ fontSize: '16px', color: '#52c41a' }}
                />
              )
            }
            rightButtonName={verificationSuccess ? '인증완료' : '인증'}
            rightButtonLoading={verifyEmailLoading}
            rightButtonDisabled={
              verificationNumber === '' || verificationSuccess
            }
            rightButtonOnClick={() =>
              verifyEmail({
                variables: {
                  code: verificationNumber,
                  email: email,
                  emailType: EmailType.signUp,
                },
              })
            }
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(verificationNumber === '' || verificationSuccess)
              ) {
                verifyEmail({
                  variables: {
                    code: verificationNumber,
                    email: email,
                    emailType: EmailType.signUp,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="password"
            value={password}
            onChange={onChange}
            label="비밀번호"
            placeholder={
              isMobile
                ? '영문/숫자/특수문자/8자 이상'
                : '영문, 숫자, 특수문자 포함 8자 이상'
            }
            secure
            errorMessage={passwordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  !verificationSuccess
                )
              ) {
                signUp({
                  variables: {
                    email: email,
                    password: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChange}
            label="비밀번호 확인"
            placeholder={
              isMobile
                ? '영문/숫자/특수문자/8자 이상'
                : '영문, 숫자, 특수문자 포함 8자 이상'
            }
            secure
            errorMessage={confirmPasswordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  !verificationSuccess
                )
              ) {
                signUp({
                  variables: {
                    email: email,
                    password: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <Button
          onClick={() => {
            signUp({
              variables: {
                email: email,
                password: password,
                userType: userType,
              },
            });
          }}
          disabled={
            email === '' ||
            emailErrorMessage !== '' ||
            password === '' ||
            passwordErrorMessage !== '' ||
            confirmPassword === '' ||
            confirmPasswordErrorMessage !== '' ||
            !verificationSuccess
          }
          loading={signUpLoading}
          type="primary"
          style={{
            width: '272px',
            margin: isMobile ? '6px auto 0' : '20px 44px 0',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          회원가입
        </Button>
        <BottomText>
          <a
            href="https://docs.google.com/document/d/1lxihwjpteVY0a9oD8Bv8_1x5pWYM5-tB/edit?usp=sharing&ouid=114277475264944287911&rtpof=true&sd=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            개인정보처리방침
          </a>
          과&nbsp;
          <a
            href={
              userType === UserType.company
                ? 'https://docs.google.com/document/d/1GtIYMd0XPxbyFu7gjTH8Othd6NF_8Nv_/edit'
                : 'https://docs.google.com/document/d/1OuwLWuDTfgvp7Cu8cibqnZUgP9Q8SM97/edit'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {userType === UserType.company
              ? '기업서비스 이용약관'
              : '학생서비스 이용약관'}
          </a>
          {!isMobile && '          을 확인하고, 동의합니다.'}
        </BottomText>
        {isMobile && (
          <p
            style={{
              fontSize: isMobile ? '10px' : '12px',
              color: '#ababb6',
              textAlign: 'center',
            }}
          >
            을 확인하고, 동의합니다.
          </p>
        )}
        <Alert
          text="이미 가입된 이메일입니다"
          icon={
            <ExclamationCircleOutlined
              style={{ color: '#ff4d4f', fontSize: 21 }}
            />
          }
          color="#fe706f"
          isOpen={isAlertOpened}
          onClose={() => setIsAlertOpened(false)}
        />
      </BodyLayout>
    </Layout>
  );
};

export default SignUpModal;
