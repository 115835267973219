import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import useAuth from '../../../hooks/useAuth';
import {
  GET_STUDENT_PROFILE,
  UPDATE_STUDENT_PROFILE,
} from '../../../queries/student';
import {
  GET_COMPANY_PROFILE,
  UPDATE_COMPANY_PROFILE,
} from '../../../queries/company';
import Icon from '../../../components/Icon';
import BannerIllust from '../../../img/ProfileIllust.png';
import ConvertStepToComponent from './EditLayoutComponent';
import {
  CompanyProfileStep,
  StudentProfileStep,
  UserType,
} from '../../../constants/static';
import StudentProfile from './StudentProfile';
import CompanyProfile from './CompanyProfile';
import { isMobile } from 'react-device-detect';

const Layout = styled.div`
  padding: ${isMobile ? '50px 16px 24px' : '50px 50px 24px 61px'};
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  margin-left: ${isMobile ? '8px' : ''};
  color: #030303;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  height: ${isMobile ? '288px' : ''};
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: ${isMobile ? '35px 23px' : '37px 48px'};
  img {
    right: ${isMobile ? '-8px' : '27px'};
    bottom: ${isMobile ? '44px' : '18px'};
    width: ${isMobile ? '148px' : '220px'};
    height: ${isMobile ? '148px' : '222px'};
    position: absolute;
    z-index: 0;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  position: ${isMobile ? 'absolute' : ''};
  z-index: 2;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: ${isMobile ? '140px' : '34px'};
  width: 183px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const ProfileEditingLayout = styled.div`
  margin: ${isMobile ? '43px 0' : '43px 53px 43px 43px'};
  background: #ffffff;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.04);
  border-radius: 30px;
`;

const RequiredText = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #f88988;
`;

const ManageProfilePage = () => {
  const { user } = useAuth();
  const [getStudentProfile, { data: studentProfileData }] =
    useLazyQuery(GET_STUDENT_PROFILE);
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  const [updateStudentProfile, { data: updateStudentProfileMutationData }] =
    useMutation(UPDATE_STUDENT_PROFILE);
  const [updateCompanyProfile, { data: updateCompanyProfileMutationData }] =
    useMutation(UPDATE_COMPANY_PROFILE);
  useEffect(() => {
    if (user.userType !== '') {
      if (user.userType === UserType.student) {
        getStudentProfile({
          variables: {
            id: user.id,
          },
        });
      } else {
        getCompanyProfile({
          variables: {
            id: user.id,
          },
        });
      }
    }
  }, [getCompanyProfile, getStudentProfile, user]);
  useEffect(() => {
    if (user.userType !== '') {
      if (
        updateStudentProfileMutationData !== undefined &&
        user.userType === 'STUDENT'
      ) {
        getStudentProfile({
          variables: {
            id: user.id,
          },
        });
      } else if (updateCompanyProfileMutationData !== undefined) {
        getCompanyProfile({
          variables: {
            id: user.id,
          },
        });
      }
    }
  }, [updateStudentProfileMutationData, updateCompanyProfileMutationData]);
  const [isShowingMobileCompleteForm, setIsShowingMobileCompleteForm] =
    useState(false);
  return (
    <Layout>
      <TitleText>
        {user.userType === UserType.student ? '프로필' : '기업정보 관리'}
        {user.userType === UserType.student &&
          studentProfileData?.student?.step !== 'COMPLETE' && (
            <RequiredText>
              * 프로필 작성을 완료 후 재차 수정이 가능합니다.
            </RequiredText>
          )}
      </TitleText>
      {(studentProfileData?.student || companyProfileData?.company) && (
        <>
          {user.userType === UserType.student ? (
            <>
              {studentProfileData?.student?.step === 'IDLE' ? (
                <AnnounceLayout>
                  <img src={BannerIllust} alt="banner" />
                  <AnnounceTitleLayout>
                    <Icon icon={'edit'} size="21px" color="#ff434e" />
                    <p>프로필을 완성해주세요!</p>
                  </AnnounceTitleLayout>
                  <AnnounceBodyText>
                    아직 학생 프로필이 없네요!
                    <br />
                    프로필을 완성하여 {isMobile && <br />}박람회 및 매칭
                    서비스를 이용해주세요!
                  </AnnounceBodyText>
                  <AnnounceBottomButton
                    onClick={() =>
                      updateStudentProfile({
                        variables: {
                          id: user.id,
                          studentProfileInput: {
                            step: StudentProfileStep.basicStudentProfile,
                          },
                        },
                      })
                    }
                  >
                    <p>프로필 완성하기</p>
                    <Icon icon={'edit'} size="21px" color="#ffffff" />
                  </AnnounceBottomButton>
                </AnnounceLayout>
              ) : (
                <>
                  {studentProfileData?.student?.step === 'COMPLETE' ||
                  (isMobile &&
                    !isShowingMobileCompleteForm &&
                    studentProfileData?.student?.step !==
                      'BASIC_STUDENT_PROFILE' &&
                    studentProfileData?.student?.step !== 'EDUCATIONS' &&
                    studentProfileData?.student?.step !== 'MATCHING_INFO' &&
                    studentProfileData?.student?.step !== 'SKILLS' &&
                    studentProfileData?.student?.step !== 'RECRUITMENT_TYPES' &&
                    studentProfileData?.student?.step !==
                      'RECRUITMENT_INFO') ? (
                    <StudentProfile
                      id={user.id}
                      refetchProfile={() =>
                        getStudentProfile({
                          variables: {
                            id: user.id,
                          },
                        })
                      }
                      profile={studentProfileData?.student}
                    />
                  ) : (
                    <ProfileEditingLayout>
                      <ConvertStepToComponent
                        id={user.id}
                        step={
                          studentProfileData?.student?.step ||
                          StudentProfileStep.basicStudentProfile
                        }
                        refetchProfile={() =>
                          getStudentProfile({
                            variables: {
                              id: user.id,
                            },
                          })
                        }
                        userType={user.userType}
                        profile={studentProfileData?.student}
                        isShowingMobileCompleteForm={
                          isShowingMobileCompleteForm
                        }
                        setIsShowingMobileCompleteForm={
                          setIsShowingMobileCompleteForm
                        }
                      />
                    </ProfileEditingLayout>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {companyProfileData?.company?.step === 'IDLE' ? (
                <AnnounceLayout>
                  <AnnounceTitleLayout>
                    <Icon icon={'edit'} size="21px" color="#ff434e" />
                    <p>프로필을 완성해주세요!</p>
                  </AnnounceTitleLayout>
                  <AnnounceBodyText>
                    아직 기업 프로필이 없네요!
                    <br />
                    프로필을 완성하여 박람회 및 매칭 서비스를 이용해주세요!
                  </AnnounceBodyText>
                  {user.userType === UserType.company && (
                    <AnnounceBottomButton
                      onClick={() =>
                        updateCompanyProfile({
                          variables: {
                            id: user.id,
                            companyProfileInput: {
                              step: CompanyProfileStep.managerInfo,
                            },
                          },
                        })
                      }
                    >
                      <p>프로필 완성하기</p>
                      <Icon icon={'edit'} size="21px" color="#ffffff" />
                    </AnnounceBottomButton>
                  )}
                  <img src={BannerIllust} alt="banner" />
                </AnnounceLayout>
              ) : (
                <>
                  {companyProfileData?.company?.step === 'COMPLETE' ? (
                    <CompanyProfile
                      id={user.id}
                      refetchProfile={() =>
                        getCompanyProfile({
                          variables: {
                            id: user.id,
                          },
                        })
                      }
                      profile={companyProfileData?.company}
                      userType={user.userType}
                    />
                  ) : (
                    <ProfileEditingLayout>
                      <ConvertStepToComponent
                        id={user.id}
                        step={
                          companyProfileData?.company?.step ||
                          CompanyProfileStep.managerInfo
                        }
                        refetchProfile={() =>
                          getCompanyProfile({
                            variables: {
                              id: user.id,
                            },
                          })
                        }
                        userType={user.userType}
                        profile={companyProfileData?.company}
                      />
                    </ProfileEditingLayout>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default ManageProfilePage;
