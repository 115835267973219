import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import useAuth from '../../hooks/useAuth';
import { Color } from '../../constants/color';
import Icon from '../../components/Icon';
import {
  BookOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { GET_FAIR_APPLICATIONS_IN_BOOTH_PAGE } from '../../queries/fair';
import SelectFair from './DisplayBoothComponents/SelectFair';
import Orders from './DisplayBoothComponents/Orders';
import BusinessRegistration from './DisplayBoothComponents/BusinessRegistration';
import { FairApplicationsState } from '../../constants/static';
import ExhibitBoothModal from './DisplayBoothComponents/ExhibitBoothModal';
import { DELETE_ORDER } from '../../queries/booth';
import BannerIllust from '../../img/BoothBannerIllust.png';
import DisplayBoothSuccessModal from './DisplayBoothComponents/DisplayBoothSuccessModal';
import PolicyAgreement from './DisplayBoothComponents/PolicyAgreement';
import { GET_COMPANY_PROFILE } from '../../queries/company';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  overflow: scroll;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const ApplyFairButton = styled(Button)`
  display: flex;
  width: 168px;
  height: 47px;
  align-items: center;
  border-radius: 15px;
  font-weight: 700;
  padding: 0 25px;
  margin: 56px auto 12px;
  background-color: #ff4d4f;
`;

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  min-height: ${(props) => (props.isModal ? '527px' : '527px')};
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const StepLayout = styled.div`
  margin: 0 72px 32px 61px;
  background-color: #e2e2ea;
  border-radius: 8.5px;
  height: 6px;
  position: relative;
`;

const FilledStepLayout = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${Color.Primary};
  border-radius: 6px;
  div {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${Color.Primary};
    border-radius: 24px;
    right: -10px;
    top: -9px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -1px;
    }
  }
`;

const FinishStepLaout = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 24px;
  right: -10px;
  top: -9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FairApplicationItemLayout = styled.div`
  width: 892px;
  margin: 20px auto 0;
  height: 92px;
  border-radius: 10px;
  border: 1.5px solid #f2f2f2;
  box-shadow: 0px 12px 23px 0px #0d03030a;
  padding: 15px 45px 15px 39px;
  display: flex;
  align-items: center;
  background-color: white;
  cursor: ${(props) => (props.isActive ? 'pointer' : '')};
`;

const ItemLeftLayout = styled.div`
  width: 395px;
  margin-right: 58px;
`;

const ItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 395px;
  max-height: 1.4em;
  line-height: 1.4em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${(props) => (props.isRejected ? '#767676' : '#000000')};
`;

const DateLayout = styled.div`
  margin-top: 8px;
  display: flex;
  div {
    margin-right: 10px;
    height: 32px;
    width: 96px;
    left: 39px;
    top: 45px;
    border-radius: 10px;
    padding: 5px, 12px, 5px, 12px;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props.isRejected ? '#767676' : '#262626')};
  }
`;

const Badge = styled.div`
  height: 22px;
  border-radius: 35px;
  padding: 0 8px;
  margin-right: ${(props) => (props.isOnline ? '48px' : '37px')};
  background-color: ${(props) =>
    props.isRejected ? '#f2f2f2' : props.isOnline ? '#fff7e6' : '#F9F0FF'};
  border: 1px solid
    ${(props) =>
      props.isRejected ? '#aaaaaa' : props.isOnline ? '#ffa171' : '#D3ADF7'};
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${(props) =>
    props.isRejected ? '#373334' : props.isOnline ? '#ffa171' : '#722ED1'};
`;

const ExhibitionCount = styled.div`
  width: 112px;
  height: 32px;
  border-radius: 10px;
  padding: 5px 10px 5px 14px;
  background-color: #fbfbfb;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.0032em;
  text-align: left;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  p {
    color: #fe706f;
    margin-left: 7px;
  }
`;

const ApprovedText = styled.p`
  font-family: Noto Sans KR;
  margin-left: 35px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => (props.ongoing ? '#3DD598' : '#5EB0FB')};
`;

const RejectedText = styled.p`
  font-family: Noto Sans KR;
  font-size: 14px;
  margin-left: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => (props.waiting ? '#767676' : '#FF4D4F')};
`;

const AnnounceLayout = styled.div`
  width: 100%;
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: 37px 48px;
  img {
    right: 27px;
    bottom: 18px;
    width: 220px;
    height: 222px;
    position: absolute;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: 34px;
  width: 183px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const convertStepToWidth = ({ step }) => {
  return `${((step + 1) / 4) * 100}%`;
};

const renderStepContent = ({
  editingApplication,
  setEditingApplication,
  setIsSuccessModalOpened,
}) => {
  switch (editingApplication?.step) {
    case 0:
      return (
        <SelectFair
          editingApplication={editingApplication}
          setEditingApplication={setEditingApplication}
        />
      );
    case 1:
      return (
        <Orders
          editingApplication={editingApplication}
          setEditingApplication={setEditingApplication}
        />
      );
    case 3:
      return (
        <PolicyAgreement
          editingApplication={editingApplication}
          setEditingApplication={setEditingApplication}
          showSuccessModal={() => setIsSuccessModalOpened(true)}
        />
      );
    default:
      return (
        <BusinessRegistration
          editingApplication={editingApplication}
          setEditingApplication={setEditingApplication}
        />
      );
  }
};

const convertStepToStepString = ({ step }) => {
  switch (step) {
    case 0:
      return '박람회 선택';
    case 1:
      return '부스 요금제 선택';
    case 2:
      return '사업자 등록증';
    case 3:
      return '참가 규정 동의';
    default:
      return '박람회 선택';
  }
};
const renderContent = ({
  editingApplication,
  setEditingApplication,
  getFairApplications,
  setIsSuccessModalOpened,
}) => {
  return (
    <EditingBodyLayout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>
          Step {editingApplication.step + 1}.{' '}
          {convertStepToStepString({ step: editingApplication?.step })}
        </Title>
      </TitleLayout>
      <StepLayout>
        <FinishStepLaout>
          <CheckOutlined style={{ color: '#aaaaaa', fontSize: '12px' }} />
        </FinishStepLaout>
        <FilledStepLayout
          width={convertStepToWidth({ step: editingApplication?.step })}
        >
          <div>
            <p>
              {editingApplication.step === 2 ? (
                <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
              ) : (
                editingApplication.step + 1
              )}
            </p>
          </div>
        </FilledStepLayout>
      </StepLayout>
      {renderStepContent({
        editingApplication,
        setEditingApplication,
        getFairApplications,
        setIsSuccessModalOpened,
      })}
    </EditingBodyLayout>
  );
};

const DisplayBooth = () => {
  const [editingApplication, setEditingApplication] = useState(null);
  const [getFairApplications, { data }] = useLazyQuery(
    GET_FAIR_APPLICATIONS_IN_BOOTH_PAGE,
  );
  const { user } = useAuth();
  const [deleteOrder, { data: deleteOrderData }] = useMutation(DELETE_ORDER);
  useEffect(() => {
    if (editingApplication === null) {
      getFairApplications({
        variables: {
          id: user.id,
        },
      });
    }
  }, [user, editingApplication]);
  useEffect(() => {
    if (deleteOrderData) {
      getFairApplications({
        variables: {
          id: user.id,
        },
      });
    }
  }, [deleteOrderData, user.id, getFairApplications]);
  const [editingOrder, setEditingOrder] = useState(null);
  useEffect(() => {
    if (editingOrder === null) {
      getFairApplications({
        variables: {
          id: user.id,
        },
      });
    }
  }, [editingOrder, getFairApplications, user.id]);
  const boothArray = useMemo(() => {
    if (data) {
      const now = moment().startOf('day');
      let array = [];
      data.fairApplications.forEach((item) => {
        item.orders.forEach((order) => {
          array.push({
            id: order.id,
            fairId: item.fair.id,
            title: item.fair.title,
            dates: order.applicationDates,
            isOnline: item.fair.isOnline,
            state: item.state,
            booths: order.booths,
            totalBoothCount: order.paymentPlan.numberOfBooth * order.number,
            isOnGoing:
              moment(item.fair.startAt) <= moment(now).startOf('day') &&
              moment(now).startOf('day') <= moment(item.fair.endAt),
            isFinished: moment(now).startOf('day') > moment(item.fair.endAt),
          });
        });
      });
      return array;
    }
    return [];
  }, [data]);
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [getCompanyProfile, { data: companyProfileData }] =
    useLazyQuery(GET_COMPANY_PROFILE);
  useEffect(() => {
    if (user.id) {
      getCompanyProfile({
        variables: {
          id: user.id,
        },
      });
    }
  }, [getCompanyProfile, user]);
  const [isWarningModalOpened, setIsWarningModalOpened] = useState(false);
  useEffect(() => {
    if (
      companyProfileData &&
      (!Boolean(companyProfileData?.company?.managerInfo?.name) ||
        !Boolean(
          companyProfileData?.company?.basicCompanyProfile?.companyNameKR,
        ))
    ) {
      setIsWarningModalOpened(true);
    }
  }, [companyProfileData]);
  const history = useHistory();
  return (
    <Layout>
      <TitleText>박람회 신청 / 부스 전시</TitleText>
      {boothArray?.length === 0 ? (
        <AnnounceLayout>
          <AnnounceTitleLayout>
            <Icon icon={'edit'} size="21px" color="#ff434e" />
            <p>박람회에 신청해주세요!</p>
          </AnnounceTitleLayout>
          <AnnounceBodyText>
            박람회를 아직 신청하지 신청하지 않으셨네요.
            <br />
            박람회를 신청하고 인재들을 만나보세요!
          </AnnounceBodyText>
          <AnnounceBottomButton
            onClick={() =>
              setEditingApplication({
                step: 0,
                fairId: 0,
                orders: [],
                taxBillEmail: '',
                policyAgreement: false,
                businessRegistration: null,
              })
            }
          >
            <p>박람회 신청하기</p>
            <Icon icon={'edit'} size="21px" color="#ffffff" />
          </AnnounceBottomButton>
          <img src={BannerIllust} alt="banner" />
        </AnnounceLayout>
      ) : (
        <>
          <ApplyFairButton
            type="primary"
            onClick={() =>
              setEditingApplication({
                step: 0,
                fairId: 0,
                orders: [],
                taxBillEmail: '',
                policyAgreement: false,
                businessRegistration: null,
              })
            }
          >
            박람회 신청
            <EditOutlined style={{ fontSize: '23px', marginLeft: '26px' }} />
          </ApplyFairButton>
          <p style={{ textAlign: 'center', marginBottom: '32px' }}>
            *부스를 전시하기 전에 내용을 재차 확인 바랍니다
          </p>

          {boothArray?.map((item, index) => (
            <FairApplicationItemLayout
              key={index}
              isActive={
                item.state === FairApplicationsState.approved ||
                item.state === FairApplicationsState.pending
              }
              onClick={() => {
                if (
                  item.state === FairApplicationsState.approved ||
                  item.state === FairApplicationsState.pending
                ) {
                  setEditingOrder(item);
                }
              }}
            >
              <ItemLeftLayout>
                <ItemTitle>{item.title}</ItemTitle>
                <DateLayout>
                  {item.dates.slice(undefined, 4).map((date) => (
                    <div>{date}</div>
                  ))}
                  {item.dates.length > 4 && '...'}
                </DateLayout>
              </ItemLeftLayout>

              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Badge isOnline={item.isOnline}>
                    {item.isOnline ? '온라인' : '오프라인'}
                  </Badge>
                  <ExhibitionCount>
                    전시 부스
                    <p>
                      {item.booths.length}/{item.totalBoothCount}
                    </p>
                  </ExhibitionCount>
                  {item.state === FairApplicationsState.approved ? (
                    <ApprovedText ongoing={item.isOnGoing}>
                      {item.isOnGoing
                        ? '진행중'
                        : item.isFinished
                        ? '종료'
                        : item.booths.length === 0
                        ? '전시 가능'
                        : '전시 중'}
                    </ApprovedText>
                  ) : (
                    <RejectedText
                      isRejected={item.state === FairApplicationsState.declined}
                    >
                      {item.state === FairApplicationsState.pending
                        ? '승인대기'
                        : '승인거절'}
                    </RejectedText>
                  )}
                  {item.state !== FairApplicationsState.approved && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteOrder({
                          variables: {
                            orderId: item.id,
                          },
                        });
                      }}
                      type="link"
                      style={{ marginRight: '0', marginLeft: 'auto' }}
                    >
                      <DeleteOutlined
                        style={{ fontSize: '20px', color: '#767676' }}
                      />
                    </Button>
                  )}
                </div>
                <p
                  style={{
                    position: 'absolute',
                    fontSize: '10px',
                    color: '#fe706f',
                    marginLeft: '64px',
                    marginTop: '6px',
                  }}
                >
                  {item.booths.length < item.totalBoothCount &&
                    '아직 전시되지 않은 부스가 있습니다.'}
                </p>
              </div>
            </FairApplicationItemLayout>
          ))}
        </>
      )}

      {editingApplication && (
        <Modal
          isOpen={editingApplication !== null}
          onClose={() => setEditingApplication(null)}
          width="971px"
          height="528px"
          radius="30px"
          dismissable
        >
          {renderContent({
            editingApplication,
            setEditingApplication,
            getFairApplications,
            setIsSuccessModalOpened,
          })}
        </Modal>
      )}
      {editingOrder && (
        <Modal
          isOpen={editingOrder !== null}
          onClose={() => setEditingOrder(null)}
          width="971px"
          height="528px"
          radius="30px"
          dismissable
        >
          <ExhibitBoothModal
            editingOrder={editingOrder}
            setEditingOrder={setEditingOrder}
          />
        </Modal>
      )}
      <Modal
        isOpen={isSuccessModalOpened}
        onClose={() => setIsSuccessModalOpened(false)}
        width="464px"
        height="280px"
      >
        <DisplayBoothSuccessModal
          onClose={() => setIsSuccessModalOpened(false)}
        />
      </Modal>
      <Modal
        width="360px"
        height="260px"
        isOpen={isWarningModalOpened}
        onClose={() => history.push('/profile/manage')}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            marginTop: '32px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
            <p style={{ marginLeft: '8px' }}>
              프로필 기본정보 작성 후에 가능합니다.
            </p>
          </div>
          <Button
            type="primary"
            style={{
              margin: '56px auto 0',
              height: '50px',
              borderRadius: '15px',
              fontWeight: 700,
            }}
            onClick={() => history.push('/profile/manage')}
          >
            프로필 작성하기
          </Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default DisplayBooth;
