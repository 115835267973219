import React from 'react';
import styled from 'styled-components';
import { BookOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_FAIRS } from '../../../queries/fair';
import { Select, Button } from 'antd';
import moment from 'moment';
const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const StyledSelect = styled(Select)`
  width: 537px;
  margin: 25px auto auto;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const SelectFair = ({ editingApplication, setEditingApplication }) => {
  const { data } = useQuery(GET_FAIRS);
  const now = moment().startOf('day');
  return (
    <Layout>
      <TitleLaout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <p>신청하실 박람회를 선택해주세요.</p>
      </TitleLaout>
      <StyledSelect
        size="large"
        value={
          editingApplication.fairId === 0
            ? undefined
            : editingApplication.fairId
        }
        placeholder="선택해주세요"
        onChange={(value) =>
          setEditingApplication({ ...editingApplication, fairId: value })
        }
      >
        {data?.fairs
          ?.filter((item) => {
            if (item.companyApplicationStartAt) {
              return (
                now.startOf('day').format('YYYY-MM-DD') ===
                  item.companyApplicationStartAt ||
                now.startOf('day').format('YYYY-MM-DD') ===
                  item.companyApplicationEndAt ||
                (now.isAfter(moment(item.companyApplicationStartAt)) &&
                  moment(item.companyApplicationEndAt).isAfter(now))
              );
            } else {
              return (
                now.startOf('day').format('YYYY-MM-DD') === item.startAt ||
                now.startOf('day').format('YYYY-MM-DD') === item.endAt ||
                (now.isAfter(moment(item.startAt)) &&
                  moment(item.endAt).isAfter(now))
              );
            }
          })
          .map((a) => (
            <Option key={a.id} value={a.id}>
              {a.title}
            </Option>
          ))}
      </StyledSelect>
      <ButtonLayout>
        <BottomRightButton
          onClick={() =>
            setEditingApplication({ ...editingApplication, step: 1 })
          }
          disabled={editingApplication.fairId === 0}
          type="primary"
        >
          다음
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default SelectFair;
