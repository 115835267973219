import React from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Switch, Redirect } from 'react-router-dom';
import { UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import MobileDisabled from '../MobileDisabled';
import Account from './Account/Account';
import ManageProfilePage from './ManageProfile/ManageProfile';

const ProfilePage = () => {
  const { user } = useAuth();
  return (
    <Switch>
      <Route exact path="/profile/manage">
        {isMobile && user.userType !== UserType.student ? (
          <MobileDisabled />
        ) : (
          <ManageProfilePage />
        )}
      </Route>
      <Route exact path="/profile/account">
        {user.userType === UserType.counselor ? (
          <Redirect to="/" />
        ) : isMobile ? (
          <MobileDisabled />
        ) : (
          <Account />
        )}
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default ProfilePage;
