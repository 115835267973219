import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import BannerIllust from '../../img/ProfileIllust.png';
import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
  SwapRightOutlined,
} from '@ant-design/icons';
import { Color } from '../../constants/color';
import { JobVacancyStep } from '../../constants/static';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_JOB_VACANCY,
  DELETE_JOB_VACANCY,
  DUPLICATE_JOB_VACANCY,
  JOB_VACANCIES_BY_COMPANY,
} from '../../queries/jobVacancy';
import useAuth from '../../hooks/useAuth';
import JobVacancyTitle from './JobVacancyComponents/JobVacancyTitle';
import TaskDetail from './JobVacancyComponents/TaskDetail';
import Requirements from './JobVacancyComponents/Requirements';
import Skills from './JobVacancyComponents/Skills';
import Environment from './JobVacancyComponents/Environment';
import ApplicationProcess from './JobVacancyComponents/ApplicationProcess';
import RecruitmentProcess from './JobVacancyComponents/RecruitmentProcess';
import SmallModalLayout from '../../components/SmallModalLayout';
import ManagerEmail from './JobVacancyComponents/ManagerEmail';
import PublishedUniversities from './JobVacancyComponents/PublishedUniversities';

const Layout = styled.div`
  padding: 50px 50px 24px 61px;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
`;

const TitleText = styled.p`
  font-size: 19px;
  font-weight: 500;
  color: #030303;
`;

const AnnounceLayout = styled.div`
  width: 100%;
  border-radius: 30px;
  background-color: #fff1f0;
  position: relative;
  margin-top: 43px;
  padding: 37px 48px;
  img {
    right: 27px;
    bottom: 18px;
    width: 220px;
    height: 222px;
    position: absolute;
  }
`;

const AnnounceTitleLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 500;
    color: #ff434e;
  }
`;

const AnnounceBodyText = styled.p`
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

const AnnounceBottomButton = styled.button`
  margin-top: 34px;
  width: 183px;
  height: 47px;
  outline: none;
  border: none;
  box-shadow: 0px 15px 18px rgba(180, 82, 82, 0.11);
  border-radius: 15px;
  background-color: #ff4d4f;
  color: white;
  padding: 13px 20px 13px 25px;
  display: flex;
  cursor: pointer;
  p {
    margin-right: auto;
    margin-left: 0;
    color: white;
    font-weight: 600;
    line-height: 20px;
  }
  align-items: center;
`;

const JobVacanciesLayout = styled.div`
  padding: 0 60px;
  margin-top: 43px;
`;

const JobVacancyItem = styled.div`
  width: 100%;
  border: ${(props) =>
    props.isEditing ? '1.5px dashed #ffccc7' : '1.5px solid  #fe706f'};
  border-radius: 10px;
  overflow: hidden;
  cursor: ${(props) => (props.isEditing ? 'pointer' : 'inherit')};
  background-color: white;
  margin-bottom: 20px;
`;

const JobVacancyItemTitleLayout = styled.div`
  display: flex;
  padding: 0 22px 0 33px;
  align-items: center;
  height: 92px;
  background-color: ${(props) => (props.opened ? '#FFF1F0' : 'white')};
`;

const Spacer = styled.div`
  flex: 1;
`;

const JobVacancyItemTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 0 20px;
  overflow: ${(props) => (props.opened ? '' : 'hidden')};
  word-break: ${(props) => (props.opened ? 'break-all' : '')};
  text-overflow: ellipsis;
  max-width: 240px;
  cursor: pointer;
`;

const EditingText = styled.p`
  margin-right: 96px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #fe706f;
`;

const RightButtonLayout = styled.div`
  margin-right: 0;
  margin-left: auto;
  button {
    margin: 0 12px;
  }
`;

const JobVacancyBodyLayout = styled.div`
  padding: 22px 0 34px;
  height: 410px;
  overflow-y: scroll;
`;

const JobVacancyContentLayout = styled.div`
  display: flex;
  padding: 0 66px 0 77px;
`;

const JobVacancyContentTitle = styled.p`
  min-width: 84x !important;
  width: 84px !important;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 18px 0;
  background-color: #f0f0f0;
`;

const VerticalSpacer = styled.div`
  height: 9px;
`;
const JobVacancyBody = styled.div`
  margin-left: 88px;
  margin-top: 4px;
  white-space: pre-wrap;
  p {
    span {
      font-weight: 500;
    }
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    max-width: 567px;
  }
`;

const SkillBody = styled.div`
  margin-left: 88px;
  margin-top: 4px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 20px;
    margin-bottom: 8px;
    height: 32px;
    border-radius: 5px;
    background-color: #fbfbfb;
    padding: 5px 16px;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #2e2e2e;
  }
`;

const EditingBodyLayout = styled.div`
  padding: 37px 0 23px;
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: scroll;
  min-height: ${(props) => (props.isModal ? '527px' : '527px')};
`;

const TitleLayout = styled.div`
  margin-left: 48px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #ff434e;
`;

const StepLayout = styled.div`
  margin: 0 72px 32px 61px;
  background-color: #e2e2ea;
  border-radius: 8.5px;
  min-height: 6px !important;
  position: relative;
`;

const FilledStepLayout = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${Color.Primary};
  border-radius: 6px;
  div {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${Color.Primary};
    border-radius: 24px;
    right: -10px;
    top: -9px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-top: -1px;
    }
  }
`;

const FinishStepLaout = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
  border-radius: 24px;
  right: -10px;
  top: -9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddJobVacancyButton = styled(Button)`
  display: flex;
  width: 188px;
  height: 47px;
  align-items: center;
  border-radius: 15px;
  font-weight: 700;
  padding: 0 25px;
  margin: 56px auto 42px;
  background-color: #ff4d4f;
`;

const convertStepToWidth = ({ step }) => {
  const index = Object.values(JobVacancyStep).findIndex(
    (keyStep) => keyStep === step,
  );
  return index < 0 ? '0' : `${((index + 1) / 8) * 100}%`;
};

const convertStepToStepString = ({ step }) => {
  switch (step) {
    case JobVacancyStep.title:
      return '모집 직무명';
    case JobVacancyStep.managerEmail:
      return '담당자 이메일';
    case JobVacancyStep.taskDetail:
      return '세부 업무내용';
    case JobVacancyStep.requirements:
      return '지원 자격 요건 및 우대사항';
    case JobVacancyStep.skills:
      return '연구분야 스킬';
    case JobVacancyStep.environment:
      return '근무요건';
    // case JobVacancyStep.applicationProcess:
    //   return '지원방법';
    case JobVacancyStep.recruitmentProcess:
      return '채용절차';
    case JobVacancyStep.publishedUniversities:
      return '지원 가능 학교';
    default:
      return '모집 직무명';
  }
};
const renderStepComponent = ({
  editingJobVacancy,
  editingStep,
  getJobVacancies,
}) => {
  const step = editingStep ?? editingJobVacancy.step;
  switch (step) {
    case JobVacancyStep.title:
      return (
        <JobVacancyTitle
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.managerEmail:
      return (
        <ManagerEmail
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.taskDetail:
      return (
        <TaskDetail
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.requirements:
      return (
        <Requirements
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.skills:
      return (
        <Skills
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.environment:
      return (
        <Environment
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    // case JobVacancyStep.applicationProcess:
    //   return (
    //     <ApplicationProcess
    //       jobVacancy={editingJobVacancy}
    //       isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
    //       getJobVacancies={getJobVacancies}
    //     />
    //   );
    case JobVacancyStep.recruitmentProcess:
      return (
        <RecruitmentProcess
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    case JobVacancyStep.publishedUniversities:
      return (
        <PublishedUniversities
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
    default:
      return (
        <JobVacancyTitle
          jobVacancy={editingJobVacancy}
          isCreating={editingJobVacancy.step !== JobVacancyStep.complete}
          getJobVacancies={getJobVacancies}
        />
      );
  }
};
const renderContent = ({ editingJobVacancy, editingStep, getJobVacancies }) => {
  return (
    <EditingBodyLayout>
      <TitleLayout>
        <Icon icon="edit" size="21px" color="#ff343e" />
        <Title>
          {!editingStep && (
            <>
              Step{' '}
              {Object.values(JobVacancyStep).findIndex(
                (keyStep) => keyStep === editingJobVacancy?.step,
              ) + 1}
              .
            </>
          )}{' '}
          {convertStepToStepString({
            step: editingStep || editingJobVacancy?.step,
          })}
        </Title>
      </TitleLayout>
      {!editingStep && (
        <StepLayout>
          <FinishStepLaout>
            <CheckOutlined style={{ color: '#aaaaaa', fontSize: '12px' }} />
          </FinishStepLaout>
          <FilledStepLayout
            width={convertStepToWidth({
              step: editingJobVacancy?.step,
            })}
          >
            <div>
              <p>
                {Object.values(JobVacancyStep).findIndex(
                  (keyStep) => keyStep === editingJobVacancy?.step,
                ) ===
                Object.values(JobVacancyStep).length - 2 ? (
                  <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
                ) : (
                  Object.values(JobVacancyStep).findIndex(
                    (keyStep) => keyStep === editingJobVacancy?.step,
                  ) + 1
                )}
              </p>
            </div>
          </FilledStepLayout>
        </StepLayout>
      )}
      {renderStepComponent({ editingJobVacancy, editingStep, getJobVacancies })}
    </EditingBodyLayout>
  );
};

const JobVacancy = () => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [openedJobVacancyId, setOpenedJobVacancyId] = useState(0);
  const [createJobVacancy, { data: createJobVacancyData }] =
    useMutation(CREATE_JOB_VACANCY);
  const [deleteErrorModalOpened, setDeleteErrorModalOpened] = useState(false);
  const [deleteJobVacancy, { data: deleteJobVacancyData }] = useMutation(
    DELETE_JOB_VACANCY,
    {
      onError: (error) => {
        if (
          error?.graphQLErrors?.[0]?.extensions?.code ===
          'JOB_VACANCY_MATCHING_EXISTS'
        ) {
          setDeleteErrorModalOpened(true);
        }
      },
    },
  );
  const [duplicateJobVacancy, { data: duplicateJobVacancyData }] = useMutation(
    DUPLICATE_JOB_VACANCY,
  );
  const [getJobVacanciesByCompany, { data: getJobVacanciesByCompanyData }] =
    useLazyQuery(JOB_VACANCIES_BY_COMPANY);
  const [editingJobVacancy, setEditingJobVacancy] = useState(null);
  const { user } = useAuth();
  const [editingStep, setEditingStep] = useState(null);
  useEffect(() => {
    getJobVacanciesByCompany({
      variables: {
        companyId: user.id,
      },
    });
  }, [user, getJobVacanciesByCompany]);
  useEffect(() => {
    if (editingJobVacancy && getJobVacanciesByCompanyData) {
      const index =
        getJobVacanciesByCompanyData?.jobVacanciesByCompany?.findIndex(
          (item) => item.id === editingJobVacancy.id,
        );
      if (index > -1) {
        setEditingJobVacancy(
          getJobVacanciesByCompanyData.jobVacanciesByCompany[index]?.step ===
            JobVacancyStep.complete
            ? null
            : getJobVacanciesByCompanyData.jobVacanciesByCompany[index],
        );
      }
    }
  }, [getJobVacanciesByCompanyData]);
  useEffect(() => {
    if (createJobVacancyData) {
      getJobVacanciesByCompany({
        variables: {
          companyId: user.id,
        },
      });
      setEditingJobVacancy(createJobVacancyData?.createJobVacancy);
      setIsModalOpened(true);
    }
  }, [createJobVacancyData]);
  useEffect(() => {
    if (duplicateJobVacancyData) {
      getJobVacanciesByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [duplicateJobVacancyData]);
  useEffect(() => {
    if (deleteJobVacancyData) {
      getJobVacanciesByCompany({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [deleteJobVacancyData]);

  return (
    <Layout>
      <TitleText>채용 공고 관리</TitleText>
      {getJobVacanciesByCompanyData?.jobVacanciesByCompany?.length === 0 ? (
        <AnnounceLayout>
          <AnnounceTitleLayout>
            <Icon icon={'edit'} size="21px" color="#ff434e" />
            <p>채용 공고를 작성해주세요!</p>
          </AnnounceTitleLayout>
          <AnnounceBodyText>
            채용 공고를 작성하고,
            <br />
            박람회 및 매칭 서비스에 개제 하세요!{' '}
          </AnnounceBodyText>
          <AnnounceBottomButton
            onClick={() => {
              createJobVacancy();
              setEditingStep(null);
            }}
          >
            <p>채용 공고 작성하기</p>
            <Icon icon={'edit'} size="21px" color="#ffffff" />
          </AnnounceBottomButton>
          <img src={BannerIllust} alt="banner" />
        </AnnounceLayout>
      ) : (
        <AddJobVacancyButton
          type="primary"
          onClick={() => {
            createJobVacancy();
            setEditingStep(null);
          }}
        >
          채용 공고 작성하기
          <EditOutlined style={{ fontSize: '23px', marginLeft: '16px' }} />
        </AddJobVacancyButton>
      )}
      <JobVacanciesLayout>
        {getJobVacanciesByCompanyData?.jobVacanciesByCompany.map((item) => (
          <JobVacancyItem
            key={item.id}
            opened={openedJobVacancyId === item.id}
            isEditing={item.step !== JobVacancyStep.complete}
            onClick={() => {
              if (item.step === JobVacancyStep.complete) {
                setOpenedJobVacancyId(
                  openedJobVacancyId === item.id ? 0 : item.id,
                );
              } else {
                setEditingStep(null);
                setEditingJobVacancy(item);
                setIsModalOpened(true);
              }
            }}
          >
            <JobVacancyItemTitleLayout opened={openedJobVacancyId === item.id}>
              {openedJobVacancyId === item.id ? (
                <DownOutlined
                  style={{
                    fontSize: '24px',
                    color:
                      item.step === JobVacancyStep.complete
                        ? 'black'
                        : '#ffffff',
                  }}
                />
              ) : (
                <RightOutlined
                  style={{
                    fontSize: '24px',
                    color:
                      item.step === JobVacancyStep.complete
                        ? '#fe706f'
                        : '#ffffff',
                  }}
                />
              )}
              <JobVacancyItemTitle opened={openedJobVacancyId === item.id}>
                {item.position}
              </JobVacancyItemTitle>
              {openedJobVacancyId === item.id && (
                <Button
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingStep(JobVacancyStep.title);
                    setEditingJobVacancy(item);
                    setIsModalOpened(true);
                  }}
                >
                  <EditOutlined
                    style={{ fontSize: '18px', color: '#ffa283' }}
                  />
                </Button>
              )}
              <Spacer />
              {item.step !== JobVacancyStep.complete && (
                <EditingText>작성중</EditingText>
              )}
              <RightButtonLayout>
                {item.step === JobVacancyStep.complete && (
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      duplicateJobVacancy({
                        variables: {
                          id: item.id,
                        },
                      });
                    }}
                  >
                    <CopyOutlined
                      style={{ fontSize: '20px', color: '#767676' }}
                    />
                  </Button>
                )}
                <Button
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteJobVacancy({
                      variables: {
                        id: item.id,
                      },
                    });
                  }}
                >
                  <DeleteOutlined
                    style={{ fontSize: '20px', color: '#767676' }}
                  />
                </Button>
              </RightButtonLayout>
            </JobVacancyItemTitleLayout>
            {openedJobVacancyId === item.id && (
              <JobVacancyBodyLayout>
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>담당자 이메일</JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.managerEmail);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>{item.managerEmail}</p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider />
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>
                    세부 업무 내용
                  </JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.taskDetail);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>
                      <span>근무지역 :</span> {item.workplace} <br />
                      <br />
                      {item.task}
                    </p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider />
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>
                    지원 자격 및 우대사항
                  </JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.requirements);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>
                      <span>필요학위 :</span> {item.requiredDegree} <br />
                      <br />
                      {item.requiredKnowledge}
                    </p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider />
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>
                    연구 분야 스킬
                  </JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.skills);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <SkillBody>
                    {item.skills.map((item, index) => (
                      <div key={index}>
                        {item.field}/{item.skillDetail}
                      </div>
                    ))}
                  </SkillBody>
                </JobVacancyContentLayout>
                <Divider />
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>근무요건</JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.environment);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>{item.environment}</p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider />
                {/* <JobVacancyContentLayout>
                  <JobVacancyContentTitle>지원방법</JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.applicationProcess);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>{item.applicationProcess}</p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider /> */}
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>채용절차</JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.recruitmentProcess);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>
                      <span>
                        {item.recruitmentSchedule === '상시채용' && '상시채용'}
                        {item.recruitmentSchedule !== '상시채용' && (
                          <>
                            {item.recruitmentSchedule.split(' ~ ')[0]}
                            <SwapRightOutlined
                              style={{
                                fontSize: '16px',
                                color: '#bfbfbf',
                                margin: '0 8px',
                              }}
                            />
                            {item.recruitmentSchedule.split(' ~ ')[1]}
                          </>
                        )}
                      </span>
                      <br />
                      <br />
                      {item.recruitmentProcess}
                    </p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
                <Divider />
                <JobVacancyContentLayout>
                  <JobVacancyContentTitle>
                    지원 가능 학교
                  </JobVacancyContentTitle>
                  <Button
                    type="link"
                    style={{ width: '48px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditingStep(JobVacancyStep.publishedUniversities);
                      setEditingJobVacancy(item);
                      setIsModalOpened(true);
                    }}
                  >
                    <EditOutlined
                      style={{
                        fontSize: '18px',
                        color: '#ffa283',
                        marginRight: '78px',
                      }}
                    />
                  </Button>
                  <JobVacancyBody>
                    <p>
                      {item.approvedUniversities.reduce(
                        (acc, cur, index) =>
                          acc + (index === 0 ? '' : ', ') + cur.name,
                        '',
                      )}
                    </p>
                  </JobVacancyBody>
                </JobVacancyContentLayout>
              </JobVacancyBodyLayout>
            )}
          </JobVacancyItem>
        ))}
      </JobVacanciesLayout>
      {editingJobVacancy !== null && (
        <Modal
          isOpen={isModalOpened}
          onClose={() => {
            setIsModalOpened(false);
          }}
          width="971px"
          height="528px"
          radius="30px"
          dismissable
        >
          {renderContent({
            editingJobVacancy,
            editingStep: editingStep,
            getJobVacancies: () => {
              getJobVacanciesByCompany({
                variables: {
                  companyId: user.id,
                },
              });
              if (editingStep !== null) {
                setIsModalOpened(false);
              }
            },
          })}
        </Modal>
      )}
      <Modal
        radius="30px"
        isOpen={deleteErrorModalOpened}
        onClose={() => setDeleteErrorModalOpened(false)}
      >
        <SmallModalLayout
          title="채용 공고 삭제 실패"
          removeIcon={true}
          description={`면접 제안/지원 내역이 있는 채용공고는 삭제할 수 없습니다.\n관리자에게 문의해주세요.\n02-569-8015`}
          setDescriptionCenter={true}
          removeLeftButton={true}
          onClickRightButton={() => setDeleteErrorModalOpened(false)}
          height="305px"
        />
      </Modal>
    </Layout>
  );
};

export default JobVacancy;
