import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { useEffect } from 'react';
import { Input, Button } from 'antd';
import { StudentProfileStep } from '../../../../constants/static';

const { TextArea } = Input;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TextboxLayout = styled.div`
  padding: 0 94px;
  margin-bottom: 36px;

  p {
    font-weight: 500;
    font-size: 14px;
    color: #3a3a3a;
    margin-bottom: 15px;
  }
  textarea {
    height: 196px;
    border-radius: 10px;
  }
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Introduction = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [introduction, setIntroduction] = useState('');
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);

  useEffect(() => {
    if (profile?.introduction) {
      setIntroduction(profile?.introduction);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.portfolios,
          introduction: introduction,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.recruitmentInfo,
          introduction: introduction,
        },
      },
    });
  };

  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <TextboxLayout>
        <p>자기소개 (500자 이내)</p>
        <TextArea
          value={introduction}
          placeholder="자기소개를 입력해주세요"
          maxLength={500}
          onChange={(e) => setIntroduction(e.currentTarget.value)}
        />
      </TextboxLayout>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Introduction;
