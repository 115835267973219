import React from 'react';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Input as BaseInput } from 'antd';

const Layout = styled.div`
  width: ${(props) => props.width};
  height: 78px;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 11px;
  margin-left: 7px;
  display: flex;
  align-items: center;
`;

const RequiredText = styled.p`
  color: #f3706f;
  margin-left: 2px;
`;

const StyledPassword = styled(BaseInput.Password)`
  input {
    font-size: 14px;
  }
  border-radius: 10px;
  border-color: ${(props) => (props.error ? '#f5222d' : '')};
`;

const FlexLayout = styled.div`
  display: flex;
`;

const StyledBaseInput = styled(BaseInput)`
  height: 40px;
  input {
    font-size: 14px;
  }
  border-radius: 10px;
  border-color: ${(props) => (props.error ? '#f5222d' : '')};
`;

const ErrorMessageLayout = styled.div`
  margin-top: 6px;
  display: flex;
  height: 9px;
  p {
    color: #ff4d4f;
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 0;
    margin-top: 1px;
  }
  align-items: center;
`;
const InputBig = ({
  name,
  width,
  label,
  value,
  onChange,
  errorMessage,
  disabled,
  secure,
  placeholder,
  inputRightIcon,
  isRequired = false,
}) => {
  return (
    <Layout width={width}>
      {label && (
        <Label>
          {label}
          {isRequired && <RequiredText>*</RequiredText>}
        </Label>
      )}
      <FlexLayout>
        {secure ? (
          <StyledPassword
            name={name}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            error={errorMessage}
            disabled={disabled}
            placeholder={placeholder}
          />
        ) : (
          <StyledBaseInput
            name={name}
            value={value}
            onChange={onChange}
            errorMessage={errorMessage}
            disabled={disabled}
            placeholder={placeholder}
            error={errorMessage}
            style={{ borderRadius: '10px', fontSize: '14px' }}
            suffix={inputRightIcon}
          />
        )}
      </FlexLayout>
      {errorMessage && (
        <ErrorMessageLayout>
          <ExclamationCircleOutlined
            style={{
              fontSize: '9px',
              height: '9px',
              color: '#ff4d4f',
              marginTop: '2px',
            }}
          />
          <p>{errorMessage}</p>
        </ErrorMessageLayout>
      )}
    </Layout>
  );
};

export default InputBig;
