import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Upload, Input } from 'antd';
import {
  DeleteOutlined,
  PaperClipOutlined,
  PlusSquareOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  DELETE_STUDENT_FILE,
  UPDATE_STUDENT_PROFILE,
  UPLOAD_STUDENT_FILE,
} from '../../../../queries/student';
import { FileType, StudentProfileStep } from '../../../../constants/static';
import useAuth from '../../../../hooks/useAuth';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  padding: 5px 50px 5px 86px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const NameInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  width: 275px;
`;

const UrlInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  width: 277px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const UploadButton = styled(Button)`
  width: 121px;
  height: 40px;
  border-radius: 10px;
`;

const UploadedLayout = styled.div`
  display: flex;
  align-items: center;
  p {
    font-size: 14px;
    color: #40a9ff;
    margin: 0 24px 0 11px;
    font-weight: 500;
  }
  button {
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
  &:hover {
    button {
      opacity: 100%;
    }
  }
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const Portfolio = ({
  portfolio,
  updatePortfolio,
  deletePortfolio,
  isDeletable,
  userId,
}) => {
  const [uploadStudentFile, { data: uploadStudentFileData }] =
    useMutation(UPLOAD_STUDENT_FILE);
  const [deleteStudentFile, { data: deleteStudentFileData }] =
    useMutation(DELETE_STUDENT_FILE);
  useEffect(() => {
    if (uploadStudentFileData) {
      console.log(uploadStudentFileData.uploadStudentFile);
      updatePortfolio({
        name: portfolio.name,
        url: portfolio.url ?? '',
        file: uploadStudentFileData.uploadStudentFile,
      });
    }
  }, [uploadStudentFileData]);
  useEffect(() => {
    if (deleteStudentFileData) {
      updatePortfolio({
        name: portfolio.name,
        url: portfolio.url ?? '',
        file: null,
      });
    }
  }, [deleteStudentFileData]);
  return (
    <FlexLayout isDeletable={isDeletable}>
      <NameInput
        value={portfolio.name}
        placeholder="이름"
        onChange={(e) =>
          updatePortfolio({
            name: e.currentTarget.value,
            url: portfolio.url,
            file: portfolio.file,
          })
        }
      />
      <UrlInput
        value={portfolio.url}
        placeholder="Url"
        disabled={portfolio.file !== null && portfolio.file !== undefined}
        showUploadList={{
          showPreviewIcon: false,
        }}
        onChange={(e) =>
          updatePortfolio({
            name: portfolio.name,
            url: e.currentTarget.value,
            file: portfolio.file,
          })
        }
      />
      <Upload
        name="file"
        maxCount={1}
        fileList={
          portfolio.file === null || portfolio.file === undefined
            ? []
            : [
                {
                  uid: portfolio.file?.id,
                  name: portfolio.file?.name,
                  status: 'done',
                  url: portfolio.file?.url,
                },
              ]
        }
        onRemove={(file) => {
          deleteStudentFile({
            variables: {
              userId: userId,
              fileType: FileType.portfolio,
              fileId: portfolio.file.id,
            },
          });
        }}
        beforeUpload={(file) => {
          if (file.size / 1024 / 1024 >= 10) {
            alert('10MB보다 작은 파일만 업로드 가능합니다.');
          } else {
            uploadStudentFile({
              variables: {
                userId: userId,
                fileType: FileType.portfolio,
                file: file,
              },
            });
          }
          return false;
        }}
      >
        {(portfolio.file === null || portfolio.file === undefined) && (
          <UploadButton
            disabled={portfolio.url !== '' && portfolio.url !== null}
            style={{ color: '#d9d9d9' }}
          >
            <UploadOutlined />
            파일 업로드
          </UploadButton>
        )}
      </Upload>
      <DeleteRowButton disabled={!isDeletable} onClick={deletePortfolio}>
        <DeleteOutlined
          style={{
            color: '#9e9e9e',
            fontSize: '18px',
            backgroundColor: 'transparent',
          }}
        />
      </DeleteRowButton>
    </FlexLayout>
  );
};

const Portfolios = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [portfolios, setPortfolios] = useState([]);
  const { user } = useAuth();
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.portfolios?.length > 0) {
      profile?.portfolios?.forEach((portfolio) => {
        setPortfolios((previousPortfolios) => {
          return [
            ...previousPortfolios,
            {
              name: portfolio.name,
              url: portfolio.url,
              file: portfolio.file,
            },
          ];
        });
      });
    } else {
      setPortfolios([
        {
          name: '',
          url: '',
          file: null,
        },
      ]);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.careers,
          portfolios: portfolios.map((item) => ({
            name: item.name,
            url: item.url,
            fileId: item.file?.id,
          })),
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.introduction,
          portfolios: portfolios.map((item) => ({
            name: item.name,
            url: item.url,
            fileId: item.file?.id,
          })),
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <BodyLayout>
        <p style={{ margin: '0 auto 24px 96px', fontSize: '12px' }}>
          *파일은 10MB 이하만 업로드 가능합니다.
        </p>
        {portfolios.map((portfolio, index) => (
          <>
            <Portfolio
              key={index}
              userId={user.id}
              isDeletable={portfolios.length > 1}
              deletePortfolio={() =>
                setPortfolios((previousPortfolios) =>
                  previousPortfolios.filter(
                    (_, prevIndex) => index !== prevIndex,
                  ),
                )
              }
              portfolio={portfolio}
              updatePortfolio={(newPortfolio) =>
                setPortfolios((previousPortfolios) => {
                  return previousPortfolios.map(
                    (previousPortfolio, previousIndex) =>
                      previousIndex === index
                        ? newPortfolio
                        : previousPortfolio,
                  );
                })
              }
            />
            {index + 1 !== portfolios.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setPortfolios((prevPortfolios) => {
            return [
              ...prevPortfolios,
              {
                name: '',
                url: '',
                file: null,
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Portfolios;
