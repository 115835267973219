import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  BookOutlined,
  PlusSquareOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { GET_FAIR } from '../../../queries/fair';
import { Select, Button, Input } from 'antd';
import moment from 'moment';
const { Option } = Select;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TitleLaout = styled.div`
  display: flex;
  width: fit-content;
  margin: auto auto 0;
  p {
    margin-left: 8px;
    font-size: 15px;
    color: #3a3a3a;
    font-weight: 500;
  }
  align-items: center;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
`;

const BottomLeftButton = styled(Button)`
  margin-left: 0;
  margin-right: auto;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BodyLayout = styled.div`
  height: 264px;
  overflow-y: scroll;
  border-bottom: 1px solid #ededed;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 80px 5px 86px;
  justify-content: center;
  margin-top: 25px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 36px auto 36px;
  border: none;
  margin-bottom: 36px !important;
`;

const DateSelect = styled(Select)`
  width: 358px;
  margin: 0 6px;
  border-radius: 10px;
  margin-left: 48px;
  div {
    border-radius: 10px !important;
  }
`;

const PaymentPlanSelect = styled(Select)`
  width: 154px;
  margin: 0 6px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const CountInput = styled(Input)`
  width: 82px;
  border-radius: 10px;
  margin: 0 6px;
`;

const TotalText = styled.p`
  text-align: center;
  margin-top: 21px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  width: 100%;
`;
const Orders = ({ editingApplication, setEditingApplication }) => {
  const [getFair, { data }] = useLazyQuery(GET_FAIR);
  useEffect(() => {
    getFair({
      variables: {
        id: editingApplication.fairId,
      },
    });
  }, [editingApplication.fairId]);
  const now = moment().startOf('day');
  useEffect(() => {
    console.log(editingApplication.orders);
    if (editingApplication.orders.length === 0) {
      setEditingApplication({
        ...editingApplication,
        orders: [
          {
            applicationDates: [],
            paymentPlan: undefined,
            number: 0,
          },
        ],
      });
    }
  }, []);
  const dateArray = useMemo(() => {
    if (data) {
      let dates = [moment(data?.fair?.startAt).format('YYYY-MM-DD')];
      let currDate = moment(data?.fair?.startAt).startOf('day');
      let lastDate = moment(data?.fair?.endAt).startOf('day');

      while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.clone()).format('YYYY-MM-DD'));
      }
      dates.push(moment(data?.fair?.endAt).format('YYYY-MM-DD'));
      return dates;
    }
    return [];
  }, [data]);
  return (
    <Layout>
      {data && (
        <>
          <BodyLayout>
            <TitleLaout>
              <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
              <p>행사일 및 부스 요금제를 선택해 주세요.</p>
            </TitleLaout>
            {editingApplication.orders.map((item, index) => (
              <FlexLayout isDeletable={editingApplication.orders.length > 1}>
                <DateSelect
                  mode="multiple"
                  size="large"
                  placeholder="행사일"
                  allo
                  onChange={(value) =>
                    setEditingApplication({
                      ...editingApplication,
                      orders: editingApplication.orders.map((order, i) =>
                        index === i
                          ? { ...order, applicationDates: value }
                          : order,
                      ),
                    })
                  }
                  value={item.applicationDates}
                >
                  {dateArray.map((item) => (
                    <Option
                      value={item}
                      key={item}
                      disabled={
                        data?.fair?.stateByDates?.filter(
                          (a) => a.date === item,
                        )?.[0]?.closed ?? false
                      }
                    >
                      {item}
                    </Option>
                  ))}
                </DateSelect>
                <PaymentPlanSelect
                  size="large"
                  placeholder="요금제"
                  onChange={(value) =>
                    setEditingApplication({
                      ...editingApplication,
                      orders: editingApplication.orders.map((order, i) =>
                        index === i
                          ? {
                              ...order,
                              paymentPlan: data?.fair?.paymentPlans.filter(
                                (b) => b.id === value,
                              )?.[0],
                            }
                          : order,
                      ),
                    })
                  }
                  value={item.paymentPlan?.name}
                >
                  {data?.fair?.paymentPlans.map((a) => {
                    return (
                      <Option key={a.id} value={a.id}>
                        {a.name}
                      </Option>
                    );
                  })}
                </PaymentPlanSelect>
                <CountInput
                  type="number"
                  value={item.number}
                  min="0"
                  onChange={(e) =>
                    setEditingApplication({
                      ...editingApplication,
                      orders: editingApplication.orders.map((order, i) =>
                        index === i
                          ? {
                              ...order,
                              number: e.currentTarget.value.replace('-', ''),
                            }
                          : order,
                      ),
                    })
                  }
                />
                <DeleteRowButton
                  onClick={() =>
                    setEditingApplication({
                      ...editingApplication,
                      orders: editingApplication.orders.filter(
                        (item, i) => index !== i,
                      ),
                    })
                  }
                >
                  <DeleteOutlined
                    style={{ color: '#9e9e9e', fontSize: '18px' }}
                  />
                </DeleteRowButton>
              </FlexLayout>
            ))}
            <FlexLayout>
              <AddButton
                onClick={() =>
                  setEditingApplication({
                    ...editingApplication,
                    orders: [
                      ...editingApplication.orders,
                      {
                        applicationDates: [],
                        paymentPlan: undefined,
                        number: 0,
                      },
                    ],
                  })
                }
              >
                <PlusSquareOutlined
                  style={{ fontSize: '18px', color: '#fe706f' }}
                />
              </AddButton>
            </FlexLayout>
          </BodyLayout>
          <TotalText>
            총액 :{' '}
            {editingApplication.orders
              .reduce(
                (a, c) =>
                  a +
                  (c.paymentPlan?.price ?? 0) *
                    (c.number ?? 0) *
                    c.applicationDates.length,
                0,
              )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            원
          </TotalText>
          <ButtonLayout>
            <BottomLeftButton
              onClick={() =>
                setEditingApplication({ ...editingApplication, step: 0 })
              }
              type="primary"
            >
              이전
            </BottomLeftButton>
            <BottomRightButton
              onClick={() =>
                setEditingApplication({ ...editingApplication, step: 2 })
              }
              disabled={
                editingApplication.orders.findIndex(
                  (item) =>
                    item.applicationDates.length === 0 ||
                    item.paymentPlan === undefined ||
                    item.number === 0 ||
                    item.number === '',
                ) > -1
              }
              type="primary"
            >
              다음
            </BottomRightButton>
          </ButtonLayout>
        </>
      )}
    </Layout>
  );
};

export default Orders;
