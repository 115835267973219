import React, { useState } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { BookOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { INQUIRE_PRESENTATION } from '../../queries/presentation';
import { useMutation } from '@apollo/client';
import useAuth from '../../hooks/useAuth';

const Layout = styled.div`
  width: 464px;
  height: 441px;
  padding: 34px 40px 22px;
  overflow: scroll;
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  color: #fe706f;
`;

const FlexLayout = styled.div`
  display: flex;
  flex-flow: ${isMobile ? 'column' : ''};
  padding: ${isMobile ? '0 24px' : ''};
  margin: ${(props) => (props.center ? 'auto' : '22px auto 8px')};
`;

const Text = styled.p`
  margin-left: 8px;
  margin-top: ${isMobile ? '8px' : '-2px'};
  width: ${(props) => (props.center ? '' : isMobile ? '220px' : '270px')};
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: ${isMobile ? 'center' : 'left'};
  white-space: pre-wrap;
`;

const ButtonLayout = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const BottomLeftButton = styled(Button)`
  width: 96px;
  height: 50px;
  border: 1px solid #fe706f;
  color: #fe706f;
  font-size: 15px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  width: 96px;
  height: 50px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 15px;
`;

const InquiryModal = ({ close }) => {
  const [inquirePresentation] = useMutation(INQUIRE_PRESENTATION, {
    onCompleted: (data) => {
      close();
    },
  });
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [content, setContent] = useState('');
  return (
    <Layout>
      <Title>설명회 신청 문의</Title>
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <Text>이메일</Text>
      </FlexLayout>
      <Input
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
        style={{ borderRadius: '8px', width: '320px' }}
        placeholder="연락받으실 이메일을 입력해주세요."
      />
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <Text>전화번호</Text>
      </FlexLayout>
      <Input
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        style={{ borderRadius: '8px', width: '320px' }}
        placeholder="연락받으실 전화번호를 입력해주세요."
      />
      <FlexLayout>
        <BookOutlined style={{ fontSize: '14px', color: '#fe706f' }} />
        <Text>문의내용</Text>
      </FlexLayout>
      <TextArea
        value={content}
        onChange={(e) => setContent(e.currentTarget.value)}
        style={{ borderRadius: '8px', height: '80px' }}
      />
      <ButtonLayout>
        <>
          <BottomLeftButton onClick={close}>취소</BottomLeftButton>
          <div style={{ width: '8px' }} />
        </>
        <BottomRightButton
          disabled={email === '' || content === '' || phoneNumber === ''}
          type="primary"
          onClick={() =>
            inquirePresentation({
              variables: {
                companyId: user.id,
                email,
                phoneNumber,
                content,
              },
            })
          }
        >
          확인
        </BottomRightButton>
      </ButtonLayout>
    </Layout>
  );
};

export default InquiryModal;
