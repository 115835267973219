import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Input } from 'antd';
import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_STUDENT_PROFILE } from '../../../../queries/student';
import { StudentProfileStep } from '../../../../constants/static';

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex: 1;
`;

const BodyLayout = styled.div`
  margin-top: 0;
`;

const DeleteRowButton = styled.button`
  opacity: 0;
  margin-right: 0;
  margin-left: auto;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 0;
  height: 18px;
  background-color: transparent;
  margin-top: 12px;
  &:disabled {
    cursor: default;
  }
`;

const FlexLayout = styled.div`
  display: flex;
  padding: 5px 50px 5px 86px;
  &:hover {
    ${DeleteRowButton} {
      opacity: ${(props) => (props.isDeletable ? '100%' : '0%')};
    }
    background-color: ${(props) =>
      props.isDeletable ? 'rgba(251, 251, 251, 0.9)' : ''};
  }
`;
const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  width: 218px;
`;

const TitleInput = styled(Input)`
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
  flex: 1;
`;

const IssuerInput = styled(Input)`
  border-radius: 10px;
  margin-right: 18px;
  font-size: 14px;
  height: 40px;
  flex: 1;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 26px 0;
`;

const AddButton = styled(Button)`
  width: 185px;
  height: 50px;
  margin-top: 36px !important;
  border-radius: 15px;
  background-color: #fff1f0 !important;
  margin: 0 auto;
  border: none;
  margin-bottom: 36px !important;
`;

const ButtonLayout = styled.div`
  margin-bottom: 0;
  margin-top: auto;
  display: flex;
  padding: 0 60px;
  width: 100%;
`;

const BottomCenterButton = styled(Button)`
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
  margin: 0 auto;
`;

const BottomLeftButton = styled(Button)`
  margin-right: auto;
  margin-left: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const BottomRightButton = styled(Button)`
  margin-left: auto;
  margin-right: 0;
  width: 96px;
  height: 50px;
  border-radius: 15px;
  font-weight: 700;
`;

const Award = ({ award, updateAward, deleteAward, isDeletable }) => {
  return (
    <FlexLayout isDeletable={isDeletable}>
      <StyledDatePicker
        picker="month"
        placeholder={'수상/취득 일자'}
        value={award.awardedAt !== '' ? moment(award.awardedAt) : undefined}
        onChange={(e) => {
          updateAward({
            awardedAt: e ? moment(e).format('YYYY-MM') : '',
            title: award.title,
            issuer: award.issuer,
          });
        }}
      />
      <TitleInput
        value={award.title}
        placeholder="수상 / 자격증 명"
        onChange={(e) =>
          updateAward({
            awardedAt: award.awardedAt,
            title: e.currentTarget.value,
            issuer: award.issuer,
          })
        }
      />
      <IssuerInput
        value={award.issuer}
        placeholder="발행기관"
        onChange={(e) =>
          updateAward({
            awardedAt: award.awardedAt,
            title: award.title,
            issuer: e.currentTarget.value,
          })
        }
      />
      <DeleteRowButton disabled={!isDeletable} onClick={deleteAward}>
        <DeleteOutlined style={{ color: '#9e9e9e', fontSize: '18px' }} />
      </DeleteRowButton>
    </FlexLayout>
  );
};

const Awards = ({ id, refetchProfile, profile, isModal, closeModal }) => {
  const [awards, setAwards] = useState([]);
  const [updateStudentProfile, { data }] = useMutation(UPDATE_STUDENT_PROFILE);
  useEffect(() => {
    if (profile?.awards?.length > 0) {
      profile?.awards?.forEach((award) => {
        setAwards((prevAwards) => {
          return [
            ...prevAwards,
            {
              awardedAt: award.awardedAt,
              title: award.title,
              issuer: award.issuer,
            },
          ];
        });
      });
    } else {
      setAwards([
        {
          awardedAt: '',
          title: '',
          issuer: '',
        },
      ]);
    }
  }, [profile]);

  const handleClickLeftButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: StudentProfileStep.patents,
          awards: awards,
        },
      },
    });
  };
  const handleClickRightButton = () => {
    updateStudentProfile({
      variables: {
        id: id,
        studentProfileInput: {
          step: isModal
            ? StudentProfileStep.complete
            : StudentProfileStep.careers,
          awards: awards,
        },
      },
    });
  };
  useEffect(() => {
    if (data !== undefined) {
      refetchProfile();
      if (isModal) {
        closeModal();
      }
    }
  }, [data, isModal]);

  return (
    <Layout>
      <BodyLayout>
        {awards.map((award, index) => (
          <>
            <Award
              key={index}
              isDeletable={awards.length > 1}
              deleteAward={() =>
                setAwards((previousAwards) =>
                  previousAwards.filter((_, prevIndex) => index !== prevIndex),
                )
              }
              award={award}
              updateAward={(newAward) =>
                setAwards((previousAwards) => {
                  return previousAwards.map((previousAward, previousIndex) =>
                    previousIndex === index ? newAward : previousAward,
                  );
                })
              }
            />
            {index + 1 !== awards.length && <Divider />}
          </>
        ))}
      </BodyLayout>
      <AddButton
        onClick={() =>
          setAwards((prevAwards) => {
            return [
              ...prevAwards,
              {
                awardedAt: '',
                title: '',
                issuer: '',
              },
            ];
          })
        }
      >
        <PlusSquareOutlined style={{ fontSize: '18px', color: '#fe706f' }} />
      </AddButton>
      <ButtonLayout>
        {isModal ? (
          <BottomCenterButton type="primary" onClick={handleClickRightButton}>
            완료
          </BottomCenterButton>
        ) : (
          <>
            <BottomLeftButton onClick={handleClickLeftButton} type="primary">
              이전
            </BottomLeftButton>
            <BottomRightButton onClick={handleClickRightButton} type="primary">
              다음
            </BottomRightButton>
          </>
        )}
      </ButtonLayout>
    </Layout>
  );
};

export default Awards;
