import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { isMobile, isMobileOnly } from 'react-device-detect';
import useAuth from '../../hooks/useAuth';
import { recruitmentTypeArray, UserType } from '../../constants/static';
import { Button, Input, Popover, Select } from 'antd';
import Modal from '../../components/Modal';
import {
  FilterFilled,
  LoadingOutlined,
  MessageOutlined,
  RetweetOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import StudentComponent from './StudentComponent';
import StudentProfileModal from '../BoothAndCounsel/ManageCounsel/StudentProfileModal';
import Icon from '../../components/Icon';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  COMPLETED_JOB_VACANCIES_BY_COMPANY,
  JOB_VACANCIES_BY_COMPANY,
} from '../../queries/jobVacancy';
import TextArea from 'antd/lib/input/TextArea';
import { GET_APPROVED_UNIVERSITIES } from '../../queries/university';
import {
  CANCEL_SAVE_STUDENT,
  GET_ON_MATCHING_STUDENTS,
  SAVE_STUDENT,
  SEARCH_ON_MATCHING_STUDENTS,
  SUGGEST_INTERVIEW,
} from '../../queries/matching';
import { SEARCH_SKILLS, GET_SKILLS } from '../../queries/skill';
import SmallModalLayout from '../../components/SmallModalLayout';
import { useHistory } from 'react-router-dom';

const Layout = styled.div`
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
  top: 64px;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #030303;
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding: 40px 0 0;
  position: relative;
`;

const ListLayout = styled.div`
  margin: ${isMobile ? '0' : '40px 102px 0'};
  background-color: white;
  border-radius: 10px;
  padding: ${isMobile ? '0 0 35px' : '0 18px 35px'};
  height: ${isMobile ? '' : 'calc(100vh - 210px)'};
  position: relative;
`;

const ListBodyLayout = styled.div`
  display: ${isMobileOnly ? 'flex' : 'grid'};
  grid-template-columns: ${isMobileOnly
    ? ''
    : 'repeat(auto-fit, minmax(327px, max-content))'};
  grid-gap: ${isMobileOnly ? '' : '20px'};
  padding-bottom: 24px;
  flex-direction: ${isMobileOnly ? 'column' : ''};
  justify-content: center;
  margin-bottom: 40px;
  margin-top: ${isMobileOnly ? '' : '24px'};
  overflow-y: scroll;
  height: calc(100% - 80px);
`;

const FilterLayout = styled.div`
  display: ${isMobile ? '' : 'flex'};
  align-items: center;
  padding: ${isMobile ? '24px 28px' : '0 53px'};
  background-color: white;
  position: ${isMobile ? 'relative' : ''};
  top: ${isMobile ? '' : ''};
  height: ${isMobile ? '' : '88px'};
  width: 100%;
  z-index: ${isMobile ? 1 : 10};
`;

const FilterButton = styled(Button)`
  min-width: 203px;
  height: 40px;
  display: flex;
  border: 1px solid #fe706f;
  color: #262626;
  font-weight: 500;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  margin-top: ${isMobile ? '12px' : ''};
`;
const PopoverLayout = styled.div`
  width: 356px;
  height: ${isMobile ? '100%' : '393px'};
  overflow-y: scroll;
  padding: 10px 0 19px;
  .ant-picker-panel {
    display: ${isMobile ? 'none' : ''};
    visibility: ${isMobile ? 'hidden' : ''};
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

const SearchInput = styled(Input)`
  border-radius: 10px;
  width: 320px;
  font-size: ${isMobile ? '16px' : '14px'};
`;

const ModalLayout = styled.div`
  width: 892px;
  height: 527px;
`;

const MatchingModalLayout = styled.div`
  width: 892px;
  height: 598px;
`;

const MatchingModalBody = styled.div`
  height: 506px;
  position: absolute;
  padding: 41px 142px 34px;
  top: 92px;
  z-index: 0;
  overflow: scroll;
`;

const ModalBody = styled.div`
  height: 495px;
  position: absolute;
  width: 100%;
  top: 32px;
  z-index: 0;
`;

const ModalHeader = styled.div`
  height: 92px;
  width: 100%;
  background-color: #fff1f0;
  padding: 0 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: absolute;
`;

const ModalHeaderTitle = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 16px;
`;

const Label = styled.div`
  width: 110px;
  margin-right: 32px;
  margin-top: 4px;
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  span {
    color: #fe706f;
  }
`;

const StyledSelect = styled(Select)`
  width: 468px;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const StyledTextarea = styled(TextArea)`
  width: 468px;
  min-height: 99px !important;
  border-radius: 10px;
`;

const StyledInput = styled(Input)`
  width: 320px;
  border-radius: 10px;
`;

const MiniInput = styled(Input)`
  width: 109px;
  border-radius: 10px;
`;

const StyledText = styled.p`
  margin: 6px 10px 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const MiniTextarea = styled(TextArea)`
  width: 468px;
  min-height: 51px !important;
  border-radius: 10px;
`;

const PopoverFlexLayout = styled.div`
  display: flex;
  padding: ${isMobile ? '0 15px 0 24px' : ''};
  align-items: center;
  color: #262626;
`;

const PopoverDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f0f0f0;
  margin: 17px 0;
`;

const PopoverTitle = styled.div`
  font-size: 14px;
  color: #262626;
  margin-right: 12px;
  margin-left: 12px;
`;

const StyledSelect2 = styled(Select)`
  flex: 1;
  border-radius: 10px;
  div {
    border-radius: 10px !important;
  }
`;

const CompanyPage = () => {
  const { user } = useAuth();
  const [isFilterPopoverOpened, setIsFilterPopoverOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStudentId, setSelectedStudentId] = useState(0);
  const [selectedMatchingStudentId, setSelectedMatchingStudentId] = useState(0);
  const [getJobVacancies, { data: getJobVacanciesData }] = useLazyQuery(
    COMPLETED_JOB_VACANCIES_BY_COMPANY,
  );
  const [
    searchSkills,
    { data: searchSkillsData, loading: searchSkillsLoading },
  ] = useLazyQuery(SEARCH_SKILLS);
  const { data: getSkillsData } = useQuery(GET_SKILLS);
  const [getOnMatchingStudents, { loading: getOnMatchingstudentsLoading }] =
    useLazyQuery(GET_ON_MATCHING_STUDENTS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setListStudents((prev) => {
          if (
            prev.findIndex(
              (item) => item.id === data?.onMatchingStudents?.[0]?.id,
            ) === -1
          ) {
            return [...prev, ...data?.onMatchingStudents];
          }
          return prev;
        });
        if (data?.onMatchingStudents?.length < 30) {
          setGetFinished(true);
        }
      },
    });
  const [
    searchOnMatchingStudents,
    { loading: searchOnMatchingstudentsLoading },
  ] = useLazyQuery(SEARCH_ON_MATCHING_STUDENTS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setListStudents((prev) => {
        if (
          prev.findIndex(
            (item) => item.id === data?.searchOnMatchingStudents?.[0]?.id,
          ) === -1
        ) {
          return [...prev, ...data?.searchOnMatchingStudents];
        }
        return prev;
      });
      if (data?.searchOnMatchingStudents?.length < 30) {
        setSearchFinished(true);
      }
    },
  });
  const [target, setTarget] = useState(null);
  const [getFinished, setGetFinished] = useState(false);
  const [searchFinished, setSearchFinished] = useState(false);
  const [listStudents, setListStudents] = useState([]);

  const onIntersect = useCallback(
    ([entry], observer) => {
      if (entry.isIntersecting && listStudents?.length > 0) {
        if (!isSearching) {
          if (!getOnMatchingstudentsLoading && !getFinished) {
            getOnMatchingStudents({
              variables: {
                first: 30,
                after:
                  listStudents.length === 0
                    ? null
                    : listStudents[listStudents.length - 1].id,
                degrees: filter.degree.length === 0 ? null : filter.degree,
                approvedUniversityIds:
                  filter.universities?.length === 0
                    ? null
                    : filter.universities,
                skillIds: filter.skills?.length === 0 ? null : filter.skills,
                recruitmentTypes:
                  filter.recruitmentTypes?.length === 0
                    ? null
                    : filter.recruitmentTypes,
              },
            });
          }
        } else {
          if (!searchOnMatchingstudentsLoading && !searchFinished) {
            searchOnMatchingStudents({
              variables: {
                first: 30,
                after:
                  listStudents?.length === 0
                    ? null
                    : listStudents[listStudents.length - 1].id,
                keyword: searchValue,
              },
            });
          }
        }
      }
    },
    [listStudents],
  );
  useEffect(() => {
    let observer;
    if (target && user.id > 0) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target, user.id, onIntersect]);
  const history = useHistory();
  const { data: approvedUniversities } = useQuery(GET_APPROVED_UNIVERSITIES);
  const [isSearching, setIsSearching] = useState(false);
  const [filter, setFilter] = useState({
    degree: [],
    universities: [],
    skills: [],
    recruitmentTypes: [],
  });
  const [editingFilter, setEditingFilter] = useState({
    degree: [],
    universities: [],
    skills: [],
    recruitmentTypes: [],
  });

  useEffect(() => {
    if (user.id) {
      getJobVacancies({
        variables: {
          companyId: user.id,
        },
      });
    }
  }, [user.id, getJobVacancies]);
  const [matchingInformation, setMatchingInformation] = useState({
    jobVacancyId: null,
    message: '',
    rank: '',
    minSalary: '',
    maxSalary: '',
    stockOption: '',
  });
  useEffect(() => {
    if (isFilterPopoverOpened) {
      setEditingFilter(filter);
    }
  }, [filter, isFilterPopoverOpened]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchValue === '') {
        setIsSearching(false);
      } else {
        setIsSearching(true);
        setListStudents([]);
        searchOnMatchingStudents({
          variables: {
            first: 30,
            after: null,
            keyword: searchValue,
          },
        });
        setFilter({
          degree: [],
          universities: [],
          skills: [],
          recruitmentTypes: [],
        });
      }
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);
  useEffect(() => {
    if (!isSearching) {
      setGetFinished(false);
      setListStudents([]);
      getOnMatchingStudents({
        variables: {
          first: 30,
          after: null,
          degrees: filter.degree?.length === 0 ? null : filter.degree,
          approvedUniversityIds:
            filter.universities?.length === 0 ? null : filter.universities,
          skillIds: filter.skills?.length === 0 ? null : filter.skills,
          recruitmentTypes:
            filter.recruitmentTypes?.length === 0
              ? null
              : filter.recruitmentTypes,
        },
      });
    }
  }, [filter, isSearching]);
  const [timeoutItem, setTimeoutItem] = useState(0);
  const [cancelSaveStudent] = useMutation(CANCEL_SAVE_STUDENT, {
    onCompleted: (data) => {
      setListStudents((prev) =>
        prev.map((item) =>
          item.id === data.cancelSaveStudent ? { ...item, saved: false } : item,
        ),
      );
    },
  });
  const [saveStudent] = useMutation(SAVE_STUDENT, {
    onCompleted: (data) => {
      setListStudents((prev) =>
        prev.map((item) =>
          item.id === data.saveStudent ? { ...item, saved: true } : item,
        ),
      );
    },
  });
  const [suggestInterview] = useMutation(SUGGEST_INTERVIEW, {
    onCompleted: (data) => {
      setSelectedMatchingStudentId(0);
      setListStudents((prev) =>
        prev.map((item) =>
          item.id === data.suggestInterview
            ? { ...item, suggested: true }
            : item,
        ),
      );
    },
  });
  const [isSearchingSkills, setIsSearchingSkills] = useState(false);
  const [matchingErrorModalOpened, setMatchingErrorModalOpened] =
    useState(false);
  return (
    <Layout>
      <Header>
        <HeaderTitle>
          {user.userType === UserType.student ? '매칭 인재' : '매칭 인재'}
          <span
            style={{
              fontSize: '12px',
              marginLeft: '12px',
              marginTop: '2px',
            }}
          >
            매칭에 참여하는 인재들을 만나보세요!
          </span>
        </HeaderTitle>
      </Header>
      <BodyLayout>
        <ListLayout>
          <FilterLayout>
            <Popover
              placement="bottomLeft"
              visible={isFilterPopoverOpened}
              onVisibleChange={(visible) => {
                setIsFilterPopoverOpened(visible);
              }}
              trigger={'click'}
              content={
                <PopoverLayout>
                  <PopoverFlexLayout>
                    <Spacer />
                    필터 초기화
                    <Button
                      type="link"
                      onClick={() => {
                        setEditingFilter({
                          degree: [],
                          universities: [],
                          skills: [],
                          recruitmentTypes: [],
                        });
                      }}
                    >
                      <RetweetOutlined style={{ fontSize: '24px' }} />
                    </Button>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>학위</PopoverTitle>
                    <StyledSelect2
                      allowClear
                      mode="multiple"
                      showSearch={false}
                      filterOption={false}
                      placeholder="학위 선택"
                      value={editingFilter.degree}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          degree: value,
                        })
                      }
                    >
                      <Select.Option value="학사">학사</Select.Option>
                      <Select.Option value="학/석사연계">
                        학/석사연계
                      </Select.Option>
                      <Select.Option value="석사">석사</Select.Option>
                      <Select.Option value="박사">박사</Select.Option>
                      <Select.Option value="석/박통합">석/박통합</Select.Option>
                      <Select.Option value="박사후(포닥)">
                        박사후(포닥)
                      </Select.Option>
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>학교</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      showSearch={false}
                      filterOption={false}
                      placeholder="학교 선택"
                      value={editingFilter.universities}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          universities: value,
                        })
                      }
                    >
                      {approvedUniversities?.approvedUniversities?.map(
                        (item) => (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        ),
                      )}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>보유스킬</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      placeholder="스킬 선택"
                      filterOption={false}
                      value={editingFilter?.skills ?? []}
                      onDropdownVisibleChange={() =>
                        setIsSearchingSkills(false)
                      }
                      loading={searchSkillsLoading}
                      onSearch={(value) => {
                        if (value === '') {
                          setIsSearchingSkills(false);
                        } else {
                          setIsSearchingSkills(true);
                          clearTimeout(timeoutItem);
                          setTimeoutItem(
                            setTimeout(() => {
                              searchSkills({
                                variables: {
                                  keyword: value,
                                },
                              });
                            }, 300),
                          );
                        }
                      }}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          skills: value,
                        })
                      }
                    >
                      {!isSearchingSkills ? (
                        <>
                          {getSkillsData?.skills?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.field}/${item.skillDetail}`}
                            </Select.Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {searchSkillsData?.searchSkills?.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {`${item.field}/${item.skillDetail}`}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <PopoverDivider />
                  <PopoverFlexLayout>
                    <PopoverTitle>희망채용형태</PopoverTitle>
                    <StyledSelect2
                      mode="multiple"
                      allowClear
                      placeholder="채용형태 선택"
                      showSearch={false}
                      filterOption={false}
                      value={editingFilter.recruitmentTypes}
                      onChange={(value) =>
                        setEditingFilter({
                          ...editingFilter,
                          recruitmentTypes: value,
                        })
                      }
                    >
                      {recruitmentTypeArray?.map((item) => (
                        <Select.Option value={item}>{item}</Select.Option>
                      ))}
                    </StyledSelect2>
                  </PopoverFlexLayout>
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '12px',
                      color: '#fe706f',
                      marginTop: '24px',
                      marginBottom: '8px',
                    }}
                  >
                    * 적용 버튼을 눌러야 변경사항이 적용됩니다.
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      style={{ borderRadius: '10px' }}
                      type="primary"
                      onClick={() => {
                        setIsSearching(false);
                        setIsFilterPopoverOpened(false);
                        setFilter(editingFilter);
                      }}
                    >
                      적용
                    </Button>
                  </div>
                </PopoverLayout>
              }
            >
              <FilterButton onClick={() => setIsFilterPopoverOpened(true)}>
                <FilterFilled
                  style={{
                    fontSize: '16px',
                    color: '#fe706f',
                    marginRight: '10px',
                  }}
                />
                필터로 원하는{' '}
                {user.userType === UserType.student ? '채용공고' : '인재'} 찾기
              </FilterButton>
            </Popover>
            <Spacer />
            <SearchInput
              size="large"
              placeholder={
                user.userType === UserType.student
                  ? '기업명 / 채용공고 검색'
                  : '보유 스킬 검색'
              }
              value={searchValue}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
              prefix={<SearchOutlined style={{ color: '#fe706f' }} />}
            />
          </FilterLayout>
          <ListBodyLayout>
            {listStudents?.map((item) => (
              <StudentComponent
                key={item.id}
                name={item.basicStudentProfile?.name}
                saved={item.saved}
                introduction={item?.briefIntroduction}
                education={`${
                  item.educations?.[
                    item.educations?.length > 0
                      ? item.educations?.length - 1
                      : 0
                  ]?.university ?? ''
                } ${
                  item.educations?.[
                    item.educations?.length > 0
                      ? item.educations?.length - 1
                      : 0
                  ]?.major ?? ''
                } ${
                  item.educations?.[
                    item.educations?.length > 0
                      ? item.educations?.length - 1
                      : 0
                  ]?.degree ?? ''
                }`}
                date={item.recruitmentInfo?.willJoinAt ?? '없음'}
                skills={item.skills
                  ?.map((skill) => skill.skillDetail)
                  .concat(
                    item.temporarySkills?.map((skill) => skill.skillDetail),
                  )}
                types={item.recruitmentTypes}
                onClick={() => setSelectedStudentId(item.id)}
                onClickSaveButton={() => {
                  if (item.saved) {
                    cancelSaveStudent({
                      variables: {
                        companyId: user.id,
                        studentId: item.id,
                      },
                    });
                  } else {
                    saveStudent({
                      variables: {
                        companyId: user.id,
                        studentId: item.id,
                      },
                    });
                  }
                }}
              />
            ))}
          </ListBodyLayout>
        </ListLayout>
        {selectedStudentId > 0 && (
          <Modal
            isOpen={selectedStudentId > 0}
            onClose={() => setSelectedStudentId(0)}
            closeButtonTransparent
          >
            <ModalLayout>
              <ModalHeader>
                <UserOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
                <ModalHeaderTitle>프로필</ModalHeaderTitle>
                <Spacer />
                {listStudents?.filter(
                  (item) => item.id === selectedStudentId,
                )?.[0]?.suggested ? (
                  <p
                    style={{
                      padding: '0 16px',
                      color: '#fe706f',
                      fontSize: '14px',
                    }}
                  >
                    면접 제안 완료
                  </p>
                ) : (
                  <Button
                    type="primary"
                    style={{ borderRadius: '10px', marginRight: '16px' }}
                    onClick={() => {
                      if (
                        getJobVacanciesData?.completedJobVacanciesByCompany?.filter(
                          (item) => item.onMatching,
                        )?.length === 0
                      ) {
                        setMatchingErrorModalOpened(true);
                      } else {
                        setMatchingInformation({
                          jobVacancyId: null,
                          message: '',
                          rank: '',
                          minSalary: '',
                          maxSalary: '',
                          stockOption: '',
                        });
                        setSelectedMatchingStudentId(selectedStudentId);
                        setTimeout(() => setSelectedStudentId(0), [100]);
                      }
                    }}
                  >
                    면접 제안하기
                  </Button>
                )}
                <div width="20px" />
                <Button
                  style={{
                    borderRadius: '10px',
                    marginRight: '16px',
                    backgroundColor: listStudents.filter(
                      (item) => item.id === selectedStudentId,
                    )?.[0]?.saved
                      ? '#ffccc7'
                      : '#ffffff',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#fe706f',
                    border: 'none',
                  }}
                  onClick={() => {
                    const selectedStudent = listStudents.filter(
                      (item) => item.id === selectedStudentId,
                    )?.[0];
                    if (selectedStudent?.saved) {
                      cancelSaveStudent({
                        variables: {
                          companyId: user.id,
                          studentId: selectedStudentId,
                        },
                      });
                    } else {
                      saveStudent({
                        variables: {
                          companyId: user.id,
                          studentId: selectedStudentId,
                        },
                      });
                    }
                  }}
                >
                  <Icon
                    size="12px"
                    icon={
                      listStudents.filter(
                        (item) => item.id === selectedStudentId,
                      )?.[0]?.saved
                        ? 'bookmarkFilled'
                        : 'bookmark'
                    }
                  />
                  <div style={{ width: '8px' }} />
                  {listStudents.filter(
                    (item) => item.id === selectedStudentId,
                  )?.[0]?.saved
                    ? '저장 완료'
                    : '인재 저장하기'}
                </Button>
              </ModalHeader>
              <ModalBody>
                <StudentProfileModal id={selectedStudentId} hideInformation />
              </ModalBody>
            </ModalLayout>
          </Modal>
        )}

        <Modal
          isOpen={selectedMatchingStudentId > 0}
          onClose={() => setSelectedMatchingStudentId(0)}
          closeButtonTransparent
        >
          <MatchingModalLayout>
            <ModalHeader>
              <MessageOutlined style={{ fontSize: '20px', color: '#fe706f' }} />
              <ModalHeaderTitle>면접 제안하기</ModalHeaderTitle>
            </ModalHeader>
            <MatchingModalBody>
              <div style={{ display: 'flex', marginBottom: '25px' }}>
                <Label>
                  제안 포지션<span>*</span>
                </Label>
                <StyledSelect
                  value={matchingInformation.jobVacancyId}
                  placeholder="채용 공고를 선택해주세요."
                  onChange={(value) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      jobVacancyId: value,
                    }))
                  }
                >
                  {getJobVacanciesData?.completedJobVacanciesByCompany
                    ?.filter((item) => item.onMatching)
                    .map((item) => (
                      <Select.Option value={item.id}>
                        {item.position}
                      </Select.Option>
                    ))}
                </StyledSelect>
              </div>
              <div style={{ display: 'flex', marginBottom: '25px' }}>
                <Label>
                  제안 메시지<span>*</span>
                </Label>
                <StyledTextarea
                  placeholder="제안 메시지를 입력해주세요."
                  value={matchingInformation.message}
                  onChange={(e) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      message: e.currentTarget.value,
                    }))
                  }
                />
              </div>
              <div style={{ display: 'flex', marginBottom: '25px' }}>
                <Label>직급</Label>
                <StyledInput
                  placeholder="직급을 입력해주세요."
                  value={matchingInformation.rank}
                  onChange={(e) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      rank: e.target.value,
                    }))
                  }
                />
              </div>
              <div style={{ display: 'flex', marginBottom: '25px' }}>
                <Label>연봉</Label>
                <MiniInput
                  min={'0'}
                  placeholder="최소"
                  value={matchingInformation.minSalary}
                  type="number"
                  onChange={(e) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      minSalary: parseInt(e.target.value) ?? null,
                    }))
                  }
                />
                <StyledText>~</StyledText>
                <MiniInput
                  min={'0'}
                  placeholder="최대"
                  value={matchingInformation.maxSalary}
                  type="number"
                  onChange={(e) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      maxSalary: parseInt(e.target.value) ?? null,
                    }))
                  }
                />
                <StyledText>만원</StyledText>
              </div>
              <div style={{ display: 'flex', marginBottom: '25px' }}>
                <Label>스톡옵션 여부</Label>
                <MiniTextarea
                  placeholder="스톡옵션 여부를 입력해주세요."
                  value={matchingInformation.stockOption}
                  onChange={(e) =>
                    setMatchingInformation((prev) => ({
                      ...prev,
                      stockOption: e.currentTarget.value,
                    }))
                  }
                />
              </div>
              <Button
                type="primary"
                style={{
                  marginLeft: '248px',
                  marginTop: '17px',
                  width: '113px',
                  height: '50px',
                  borderRadius: '10px',
                  fontWeight: 500,
                }}
                disabled={
                  matchingInformation.message === '' ||
                  matchingInformation.jobVacancyId === null
                }
                onClick={() => {
                  suggestInterview({
                    variables: {
                      studentId: selectedMatchingStudentId,
                      jobVacancyId: matchingInformation.jobVacancyId,
                      interviewSuggestionInput: {
                        message: matchingInformation.message,
                        rank: matchingInformation.rank,
                        minSalary:
                          matchingInformation.minSalary === ''
                            ? null
                            : parseInt(matchingInformation.minSalary === ''),
                        maxSalary:
                          matchingInformation.maxSalary === ''
                            ? null
                            : parseInt(matchingInformation.maxSalary === ''),
                        stockOption: matchingInformation.stockOption,
                      },
                    },
                  });
                }}
              >
                면접 제안하기
              </Button>
            </MatchingModalBody>
          </MatchingModalLayout>
        </Modal>
        <Modal
          radius="30px"
          isOpen={matchingErrorModalOpened}
          onClose={() => setMatchingErrorModalOpened(false)}
        >
          <SmallModalLayout
            title="면접 제안 실패"
            description={'매칭 관리에서 채용공고를 먼저 게시해주세요.'}
            setDescriptionCenter={true}
            removeLeftButton={true}
            onClickRightButton={() =>
              history.push('/company-meeting/post-job-vacancy')
            }
            height="305px"
          />
        </Modal>
        <div ref={setTarget} className="Target-Element" />
      </BodyLayout>
    </Layout>
  );
};

export default CompanyPage;
