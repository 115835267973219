import { AlertOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { InterviewState, UserType } from '../../constants/static';
import useAuth from '../../hooks/useAuth';
import { Button, DatePicker, Input, Radio, Space } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_STUDENT_PROFILE } from '../../queries/student';
import { GET_COMPANY_PROFILE } from '../../queries/company';
import moment from 'moment';
import { SET_STUDENT_MATCHING_INTERVIEW_STATE } from '../../queries/matching';
import TextArea from 'antd/lib/input/TextArea';

const Layout = styled.div`
  height: ${isMobile ? 'calc(100vh - 56px)' : 'calc(100vh - 64px)'};
`;

const Header = styled.div`
  width: 100%;
  height: 84px;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #e2e2e2;
  padding: ${isMobile ? '28px 30px' : '28px 108px'};
  z-index: 2;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #030303;
`;

const BodyLayout = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 84px;
`;

const ContentLayout = styled.div`
  margin: ${isMobile ? '16px 0' : '40px 102px'};
  background-color: white;
  border-radius: 10px;
  height: ${isMobile ? '100%' : 'calc(100% - 80px)'};
  overflow: scroll;
  padding: ${isMobile ? '34px 29px' : '72px 142px'};
  display: ${isMobile ? 'flex' : ''};
  flex-flow: ${isMobile ? 'column' : ''};
`;

const Title = styled.p`
  font-family: Noto Sans KR;
  font-size: 15px;
  font-style: normal;
  font-weight: 00;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 24px;
  font-weight: 500;
`;

const AlertLayout = styled.div`
  position: absolute;
  width: 320px;
  height: 240px;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
  border-radius: 10px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-weight: 500;
`;

const BodyText = styled.p`
  margin: 40px 0;
  font-family: Noto Sans KR;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const FlexLayout = styled.div`
  display: flex;
`;

const StyledDatePicker = styled(DatePicker)`
  border-radius: 10px;
  width: 218px;
  margin-top: 20px;
`;

const FinishButton = styled(Button)`
  width: 176px;
  height: 53px !important;
  min-height: 53px !important;
  margin: ${isMobile ? '60px auto 40px' : '60px 0 40px'};
  border-radius: 10px;
  font-weight: 700;
`;

const TextareaTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  p {
    margin-left: 4px;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

const StyledTextarea = styled(TextArea)`
  border-radius: 10px;
  width: 553px;
  min-height: 126px !important;
`;

const CustomSpace = styled(Space)`
  margin-left: 8px;
`;

const StudentSurveyPage = () => {
  const { user } = useAuth();
  const location = useLocation();
  const studentId = useMemo(() => {
    return (
      parseInt(
        location.search
          .split('?')
          ?.filter((item) => item.includes('studentId'))?.[0]
          ?.split('=')?.[1],
      ) ?? 0
    );
  }, [location]);

  const companyId = useMemo(() => {
    return (
      parseInt(
        location.search
          .split('?')
          ?.filter((item) => item.includes('companyId'))?.[0]
          ?.split('=')?.[1],
      ) ?? 0
    );
  }, [location]);
  const matchingId = useMemo(() => {
    return (
      parseInt(
        location.search
          .split('?')
          ?.filter((item) => item.includes('matchingId'))?.[0]
          ?.split('=')?.[1],
      ) ?? 0
    );
  }, [location]);
  const history = useHistory();
  const [interviewState, setInterviewState] = useState(InterviewState.planned);
  const [isInterviewDateFixed, setIsInterviewDateFixed] = useState(false);
  const [interviewDate, setInterviewDate] = useState('');
  const [canceledReason, setCanceledReason] = useState('');
  const [interviewCompletedState, setInterviewCompletedState] =
    useState('결과통보 대기중');
  const [joinState, setJoinState] = useState('입사일 미정');
  const [joinDate, setJoinDate] = useState('');
  const [rejectJoinReason, setRejectJoinReason] = useState('');
  useEffect(() => {
    setJoinDate('');
    setRejectJoinReason('');
  }, [joinState]);
  useEffect(() => {
    setJoinState('입사일 미정');
  }, [interviewCompletedState]);

  const { data: getStudentProfileData } = useQuery(GET_STUDENT_PROFILE, {
    variables: {
      id: studentId,
    },
  });
  const { data: getCompanyData } = useQuery(GET_COMPANY_PROFILE, {
    variables: {
      id: companyId,
    },
  });
  const [setStudentMatchingInverviewState] = useMutation(
    SET_STUDENT_MATCHING_INTERVIEW_STATE,
    {
      onCompleted: (data) => {
        alert('설문조사를 응해주셔서 감사합니다.');
        history.push('/');
      },
    },
  );
  useEffect(() => {
    setInterviewDate('');
    setIsInterviewDateFixed(false);
    setCanceledReason('');
    setInterviewCompletedState('결과통보 대기중');
  }, [interviewState]);
  return (
    <Layout>
      <Header>
        <HeaderTitle>면접 관련 설문조사</HeaderTitle>
      </Header>
      <BodyLayout>
        <ContentLayout>
          {user.id === studentId && user.userType === UserType.student ? (
            <>
              {studentId > 0 &&
                companyId > 0 &&
                matchingId > 0 &&
                getStudentProfileData?.student &&
                getCompanyData?.company && (
                  <>
                    <Title>Q. 면접이 어떻게 진행되고 있나요?</Title>
                    {isMobile ? (
                      <CustomSpace direction="vertical">
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.planned)
                          }
                          checked={interviewState === InterviewState.planned}
                        >
                          면접예정
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.canceled)
                          }
                          checked={interviewState === InterviewState.canceled}
                        >
                          면접취소(거부)
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.completed)
                          }
                          checked={interviewState === InterviewState.completed}
                        >
                          면접완료
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.joined)
                          }
                          checked={interviewState === InterviewState.joined}
                        >
                          입사완료
                        </Radio>
                      </CustomSpace>
                    ) : (
                      <FlexLayout>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.planned)
                          }
                          checked={interviewState === InterviewState.planned}
                        >
                          면접예정
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.canceled)
                          }
                          checked={interviewState === InterviewState.canceled}
                        >
                          면접취소(거부)
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.completed)
                          }
                          checked={interviewState === InterviewState.completed}
                        >
                          면접완료
                        </Radio>
                        <Radio
                          onClick={() =>
                            setInterviewState(InterviewState.joined)
                          }
                          checked={interviewState === InterviewState.joined}
                        >
                          입사완료
                        </Radio>
                      </FlexLayout>
                    )}

                    {interviewState === InterviewState.completed && (
                      <>
                        <BodyText>
                          <span>
                            {
                              getStudentProfileData?.student
                                ?.basicStudentProfile?.name
                            }
                          </span>{' '}
                          회원님{' '}
                          <span>
                            {
                              getCompanyData?.company?.basicCompanyProfile
                                ?.companyNameKR
                            }
                          </span>{' '}
                          기업과{isMobile && <br />} 면접을 진행하시느라 수고
                          많으셨습니다.
                          <br />
                          현재 회원님 면접 후 진행상태를 입력 바랍니다.
                        </BodyText>
                        {isMobile ? (
                          <CustomSpace direction="vertical">
                            <Radio
                              onClick={() =>
                                setInterviewCompletedState('결과통보 대기중')
                              }
                              checked={
                                interviewCompletedState === '결과통보 대기중'
                              }
                            >
                              결과통보 대기중
                            </Radio>
                            <Radio
                              onClick={() => setInterviewCompletedState('합격')}
                              checked={interviewCompletedState === '합격'}
                            >
                              합격
                            </Radio>
                            <Radio
                              onClick={() =>
                                setInterviewCompletedState('불합격')
                              }
                              checked={interviewCompletedState === '불합격'}
                            >
                              불합격
                            </Radio>
                          </CustomSpace>
                        ) : (
                          <FlexLayout>
                            <Radio
                              onClick={() =>
                                setInterviewCompletedState('결과통보 대기중')
                              }
                              checked={
                                interviewCompletedState === '결과통보 대기중'
                              }
                            >
                              결과통보 대기중
                            </Radio>
                            <Radio
                              onClick={() => setInterviewCompletedState('합격')}
                              checked={interviewCompletedState === '합격'}
                            >
                              합격
                            </Radio>
                            <Radio
                              onClick={() =>
                                setInterviewCompletedState('불합격')
                              }
                              checked={interviewCompletedState === '불합격'}
                            >
                              불합격
                            </Radio>
                          </FlexLayout>
                        )}

                        {interviewCompletedState === '합격' && (
                          <>
                            <div style={{ height: '32px' }} />
                            <Title>Q. 입사일이 정해졌나요?</Title>
                            {isMobile ? (
                              <CustomSpace direction="vertical">
                                <Radio
                                  onClick={() => setJoinState('입사일 미정')}
                                  checked={joinState === '입사일 미정'}
                                >
                                  입사일 미정
                                </Radio>
                                <Radio
                                  onClick={() => setJoinState('입사 예정')}
                                  checked={joinState === '입사 예정'}
                                >
                                  입사 예정
                                </Radio>
                                <Radio
                                  onClick={() => setJoinState('입사 거부')}
                                  checked={joinState === '입사 거부'}
                                >
                                  입사 거부
                                </Radio>
                              </CustomSpace>
                            ) : (
                              <FlexLayout>
                                <Radio
                                  onClick={() => setJoinState('입사일 미정')}
                                  checked={joinState === '입사일 미정'}
                                >
                                  입사일 미정
                                </Radio>
                                <Radio
                                  onClick={() => setJoinState('입사 예정')}
                                  checked={joinState === '입사 예정'}
                                >
                                  입사 예정
                                </Radio>
                                <Radio
                                  onClick={() => setJoinState('입사 거부')}
                                  checked={joinState === '입사 거부'}
                                >
                                  입사 거부
                                </Radio>
                              </FlexLayout>
                            )}
                          </>
                        )}
                        <div style={{ height: '32px' }} />
                        {joinState === '입사 예정' && (
                          <StyledDatePicker
                            placeholder="입사일 입력해주세요."
                            value={joinDate !== '' ? moment(joinDate) : null}
                            onChange={(e) =>
                              setJoinDate(
                                e ? moment(e).format('YYYY-MM-DD') : '',
                              )
                            }
                          />
                        )}
                        {joinState === '입사 거부' && (
                          <>
                            <TextareaTitle>
                              <IssuesCloseOutlined
                                style={{ color: '#fe706f' }}
                              />
                              <p>입사 거부사유</p>
                            </TextareaTitle>
                            <StyledTextarea
                              value={rejectJoinReason}
                              onChange={(e) =>
                                setRejectJoinReason(e.target.value)
                              }
                              placeholder="*원활한 채용문화 에티켓 차원에서 상대방이 기다리지 않도록 입사 거부사유를 입력 바랍니다."
                            />
                          </>
                        )}
                        <div style={{ display: isMobile ? 'flex' : '' }}>
                          <FinishButton
                            type="primary"
                            disabled={
                              (interviewCompletedState === '합격' &&
                                joinState === '입사 예정' &&
                                joinDate === '') ||
                              (interviewCompletedState === '합격' &&
                                joinState === '입사 거부' &&
                                rejectJoinReason === '')
                            }
                            onClick={() => {
                              setStudentMatchingInverviewState({
                                variables: {
                                  matchingId,
                                  interviewState,
                                  stateDetail: `${interviewCompletedState}${
                                    interviewCompletedState === '합격'
                                      ? `, ${
                                          joinState === '입사일 미정'
                                            ? '입사일 미정'
                                            : joinState === '입사 예정'
                                            ? `입사예정일 : ${joinDate}`
                                            : `입사 거부, 입사거부사유 : ${rejectJoinReason}`
                                        }`
                                      : ``
                                  }`,
                                },
                              });
                            }}
                          >
                            설문조사 완료
                          </FinishButton>
                        </div>
                      </>
                    )}
                    {interviewState === InterviewState.planned && (
                      <>
                        <BodyText>
                          <span>
                            {
                              getStudentProfileData?.student
                                ?.basicStudentProfile?.name
                            }
                          </span>{' '}
                          회원님{' '}
                          <span>
                            {
                              getCompanyData?.company?.basicCompanyProfile
                                ?.companyNameKR
                            }
                          </span>{' '}
                          기업과{isMobile && <br />} 면접이 예정 되어 있습니다.
                          <br />
                          준비과정에서 문의사항이 있으시면 담당자분께 확인
                          바라며, 진행과정에서 어려운 부분이 있으면{' '}
                          <span>[SENLab]</span> 으로 문의 바랍니다.
                          <br />
                          긴장하시 마시고 면접 잘 보시고, 면접 후 입사가
                          확정되면 저희 <span>SENLab</span>에서 입사축하금을
                          지급하고 있으니,
                          <br />
                          반드시 일정을 공유 바랍니다.
                        </BodyText>
                        <Title>Q. 면접일이 정해지셨나요?</Title>
                        {isMobile ? (
                          <CustomSpace direction="vertical">
                            <Radio
                              onClick={() => setIsInterviewDateFixed(true)}
                              checked={isInterviewDateFixed}
                            >
                              네
                            </Radio>
                            <Radio
                              onClick={() => {
                                setIsInterviewDateFixed(false);
                                setInterviewDate('');
                              }}
                              checked={!isInterviewDateFixed}
                            >
                              아니오
                            </Radio>
                          </CustomSpace>
                        ) : (
                          <FlexLayout>
                            <Radio
                              onClick={() => setIsInterviewDateFixed(true)}
                              checked={isInterviewDateFixed}
                            >
                              네
                            </Radio>
                            <Radio
                              onClick={() => {
                                setIsInterviewDateFixed(false);
                                setInterviewDate('');
                              }}
                              checked={!isInterviewDateFixed}
                            >
                              아니오
                            </Radio>
                          </FlexLayout>
                        )}

                        {isInterviewDateFixed && (
                          <StyledDatePicker
                            placeholder="면접일을 입력해주세요."
                            value={
                              interviewDate !== ''
                                ? moment(interviewDate)
                                : null
                            }
                            onChange={(e) =>
                              setInterviewDate(
                                e ? moment(e).format('YYYY-MM-DD') : '',
                              )
                            }
                          />
                        )}
                        <div style={{ display: isMobile ? 'flex' : '' }}>
                          <FinishButton
                            type="primary"
                            disabled={
                              isInterviewDateFixed && interviewDate === ''
                            }
                            onClick={() => {
                              setStudentMatchingInverviewState({
                                variables: {
                                  matchingId,
                                  interviewState,
                                  stateDetail: `면접일 ${
                                    !isInterviewDateFixed
                                      ? '미정'
                                      : `: ${interviewDate}`
                                  }`,
                                },
                              });
                            }}
                          >
                            설문조사 완료
                          </FinishButton>
                        </div>
                      </>
                    )}
                    {interviewState === InterviewState.canceled && (
                      <>
                        <BodyText>
                          <span>
                            {
                              getStudentProfileData?.student
                                ?.basicStudentProfile?.name
                            }
                          </span>{' '}
                          회원님{' '}
                          <span>
                            {
                              getCompanyData?.company?.basicCompanyProfile
                                ?.companyNameKR
                            }
                          </span>{' '}
                          기업의 {isMobile && <br />}면접을 취소하셨습니다.
                          <br />
                          예정된 면접의 취소 사유를 입력 바랍니다.
                        </BodyText>
                        <TextareaTitle>
                          <IssuesCloseOutlined style={{ color: '#fe706f' }} />
                          <p>취소 사유</p>
                        </TextareaTitle>
                        <StyledTextarea
                          value={canceledReason}
                          onChange={(e) => setCanceledReason(e.target.value)}
                          placeholder="*원활한 채용문화 에티켓 차원에서 상대방이 기다리지 않도록 취소사유를 입력 바랍니다."
                        />
                        <div>
                          <FinishButton
                            type="primary"
                            disabled={canceledReason === ''}
                            onClick={() => {
                              setStudentMatchingInverviewState({
                                variables: {
                                  matchingId,
                                  interviewState,
                                  stateDetail: `취소사유 : ${canceledReason}`,
                                },
                              });
                            }}
                          >
                            설문조사 완료
                          </FinishButton>
                        </div>
                      </>
                    )}
                    {interviewState === InterviewState.joined && (
                      <>
                        <BodyText>
                          <span>
                            {
                              getStudentProfileData?.student
                                ?.basicStudentProfile?.name
                            }
                          </span>{' '}
                          회원님 다시 한번 귀하의 입사를 진심으로 축하드립니다.
                          <br />
                          <span>
                            {
                              getCompanyData?.company?.basicCompanyProfile
                                ?.companyNameKR
                            }
                          </span>{' '}
                          에서는 매칭서비스를 통해 입사하시는 회원분들께
                          <br />
                          입사 축하금을 지원해드리고 있습니다.
                          <br />
                          <br />
                          아래의 지금 방법 및 축하금을 선택하여 필요 서류를 전달
                          바랍니다.
                          <br />
                          <br />
                          <p style={{ fontSize: '14px', color: '#fe706f' }}>
                            <span style={{ fontWeight: 500 }}>
                              축하금 종류 :
                            </span>
                            {isMobile && <br />} 현금 or 상품권 중 택일 (입사 후
                            3개월 이후 지급)
                            <br />
                            <span style={{ fontWeight: 500 }}>
                              현금 필요 서류 :
                            </span>
                            {isMobile && <br />} 신분증, 통장사본 (제세공과금
                            공제 후 지급)
                            <br />
                            <span style={{ fontWeight: 500 }}>
                              상품권 필요 서류 :
                            </span>
                            {isMobile && <br />} 신분증 (백화점, 마트, 문화
                            상품권 등 선택 가능)
                          </p>
                          <br />
                          <br />
                          위의 내용을 참고하여 지급 방법 및 아래 설문조사에 참여
                          바랍니다.
                          <br />
                          <br />
                          <a
                            href={
                              'https://docs.google.com/forms/d/e/1FAIpQLSfub_uMSpQz1_EU6qsfSewoLC4pfkfhgTCXnw8AwDSExGQ4IA/viewform'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: 'underline' }}
                          >
                            https://docs.google.com/forms/d/e/1FAIpQLSfub_uMSpQz1_EU6qsfSewoLC4pfkfhgTCXnw8AwDSExGQ4IA/viewform
                          </a>
                          <br />
                          <br />
                          관련 서류는 하단의 이메일로 회신 부탁드립니다.
                          <br />
                          문의 :{' '}
                          <span>02-569-8015 / matching.help@senlab.co.kr</span>
                        </BodyText>
                        <FinishButton
                          type="primary"
                          onClick={() => {
                            setStudentMatchingInverviewState({
                              variables: {
                                matchingId,
                                interviewState,
                                stateDetail: ``,
                              },
                            });
                          }}
                        >
                          설문조사 완료
                        </FinishButton>
                      </>
                    )}
                  </>
                )}
            </>
          ) : (
            <>
              {user.id === 0 ? (
                <AlertLayout>
                  <AlertOutlined
                    style={{
                      color: '#fe706f',
                      fontSize: '24px',
                      marginBottom: '32px',
                    }}
                  />
                  로그인 후 설문조사가 가능합니다.
                  <br />
                  올바른 계정으로 로그인 후 이용해주세요.
                </AlertLayout>
              ) : (
                <AlertLayout>
                  <AlertOutlined
                    style={{
                      color: '#fe706f',
                      fontSize: '24px',
                      marginBottom: '32px',
                    }}
                  />
                  다른 계정으로 로그인이 되어있습니다.
                  <br />
                  올바른 계정으로 로그인 후 이용해주세요.
                </AlertLayout>
              )}
            </>
          )}
        </ContentLayout>
      </BodyLayout>
    </Layout>
  );
};

export default StudentSurveyPage;
