import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useInputs from '../../hooks/useInput';
import { Color } from '../../constants/color';
import Input from '../Input';
import { emailRegex, passwordRegex } from '../../constants/regex';
import { useMutation } from '@apollo/client';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import {
  SEND_VERIFICATION_EMAIL,
  VERIFY_EMAIL,
  RESET_PASSWORD,
} from '../../queries/auth';
import { CheckCircleFilled } from '@ant-design/icons';
import { EmailType, UserType } from '../../constants/static';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${Color.Background};
  border-radius: 10px;
`;

const BodyLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: ${isMobile ? '42px 36px 14px' : '42px 51px 40px'};
  z-index: 10;
  display: flex;
  flex-flow: column;
`;

const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${isMobile ? '12px' : '41px'};
  color: '#3a3a3a';
`;

const SubTitleText = styled.p`
  font-size: 12px;
  color: '#3a3a3a';
  text-align: center;
  margin-bottom: 10px;
`;

const UserTypeSelectLayout = styled.div`
  width: 272px;
  overflow: hidden;
  display: flex;
  border-radius: 10px;
  margin: 0 auto;
`;

const UserTypeSelect = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.selected ? Color.Primary : '#f5f5f5')};
  width: 136px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? '#ffffff' : '#bfbfbf')};
  border-left: ${(props) =>
    props.selected ? '' : props.left ? '1px solid #d9d9d9' : ''};
  border-right: ${(props) =>
    props.selected ? '' : props.right ? '1px solid #d9d9d9' : ''};
  border-top: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-bottom: ${(props) => (props.selected ? '' : '1px solid #d9d9d9')};
  border-radius: ${(props) =>
    props.left ? '10px 0 0 10px' : props.right ? '0 10px 10px 0' : ''};
  margin-bottom: ${isMobile ? '14px' : '46px'};
`;

const Divider = styled.div`
  height: 32px;
  width: 1px;
  background-color: #d9d9d9;
`;

const InputLayout = styled.div`
  margin-bottom: ${isMobile ? '8px' : '22px'};
`;

const FindPasswordModal = ({ handlePage, isOpen, onClose }) => {
  const [
    sendVerificationEmail,
    { loading: sendVerificationEmailLoading, data: sendVerificationEmailData },
  ] = useMutation(SEND_VERIFICATION_EMAIL);
  const [resetPassword, { data: resetPasswordData }] =
    useMutation(RESET_PASSWORD);
  const [
    verifyEmail,
    {
      data: verifyEmailData,
      loading: verifyEmailLoading,
      error: verifyEmailError,
    },
  ] = useMutation(VERIFY_EMAIL);
  const [userType, setUserType] = useState(UserType.student);
  const [
    { email, verificationNumber, password, confirmPassword },
    onChange,
    reset,
  ] = useInputs({
    email: '',
    verificationNumber: '',
    password: '',
    confirmPassword: '',
  });
  useEffect(() => {
    reset();
  }, [userType]);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [verificationNumberErrorMessage, setVerificationNumberErrorMessage] =
    useState('');
  const [sent, setSent] = useState(false);
  const passwordErrorMessage = useMemo(() => {
    const regex = new RegExp(passwordRegex);
    return password !== '' && !regex.test(password)
      ? '비밀번호가 형식에 맞지 않습니다.'
      : '';
  }, [password]);
  const confirmPasswordErrorMessage = useMemo(() => {
    return confirmPassword !== '' &&
      password !== '' &&
      password !== confirmPassword
      ? '비밀번호가 일치하지 않습니다.'
      : '';
  }, [password, confirmPassword]);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  useEffect(() => {
    setVerificationSuccess(false);
    setSent(false);
    const regex = new RegExp(emailRegex);
    if (email !== '' && !regex.test(email)) {
      setEmailErrorMessage('유효하지 않은 이메일입니다.');
    } else {
      setEmailErrorMessage('');
    }
  }, [email]);

  useEffect(() => {
    setVerificationNumberErrorMessage('');
  }, [verificationNumber]);
  useEffect(() => {
    if (
      verifyEmailError?.graphQLErrors?.[0]?.extensions?.code === 'CODE_INVALID'
    ) {
      setVerificationNumberErrorMessage('잘못된 인증번호 입니다.');
    }
  }, [verifyEmailError]);
  useEffect(() => {
    if (verifyEmailData?.verify) {
      setVerificationSuccess(true);
    }
  }, [verifyEmailData]);
  useEffect(() => {
    if (resetPasswordData?.resetPassword) {
      handlePage('findPasswordSuccess');
    }
  }, [resetPasswordData?.resetPassword]);

  return (
    <Layout>
      <BodyLayout>
        <TitleText>비밀번호 찾기</TitleText>
        <SubTitleText>회원유형</SubTitleText>
        <UserTypeSelectLayout>
          <UserTypeSelect
            selected={userType === UserType.student}
            left
            onClick={() => setUserType(UserType.student)}
          >
            학생
          </UserTypeSelect>
          <Divider />
          <UserTypeSelect
            selected={userType === UserType.company}
            onClick={() => setUserType(UserType.company)}
          >
            기업
          </UserTypeSelect>
          <Divider />
          <UserTypeSelect
            selected={userType === UserType.counselor}
            right
            onClick={() => setUserType(UserType.counselor)}
          >
            상담
          </UserTypeSelect>
        </UserTypeSelectLayout>
        <InputLayout>
          <Input
            name="email"
            value={email}
            onChange={onChange}
            label="이메일"
            placeholder={
              isMobile ? '가입한 이메일' : '가입한 이메일을 입력해주세요.'
            }
            errorMessage={emailErrorMessage}
            rightButtonName={
              sent && sendVerificationEmailData ? '재발송' : '메일발송'
            }
            rightButtonLoading={sendVerificationEmailLoading}
            rightButtonDisabled={emailErrorMessage !== '' || email === ''}
            rightButtonOnClick={() => {
              setSent(true);
              sendVerificationEmail({
                variables: {
                  email: email,
                  emailType: EmailType.resetPassword,
                  userType: userType,
                },
              });
            }}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(emailErrorMessage !== '' || email === '')
              ) {
                setSent(true);
                sendVerificationEmail({
                  variables: {
                    email: email,
                    emailType: EmailType.resetPassword,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="verificationNumber"
            value={verificationNumber}
            onChange={onChange}
            label="이메일 인증번호"
            disabled={verifyEmailLoading || verificationSuccess}
            errorMessage={verificationNumberErrorMessage}
            inputRightIcon={
              verificationSuccess && (
                <CheckCircleFilled
                  style={{ fontSize: '16px', color: '#52c41a' }}
                />
              )
            }
            rightButtonName={verificationSuccess ? '인증완료' : '인증'}
            rightButtonLoading={verifyEmailLoading}
            rightButtonDisabled={
              verificationNumber === '' || verificationSuccess
            }
            rightButtonOnClick={() =>
              verifyEmail({
                variables: {
                  code: verificationNumber,
                  email: email,
                  emailType: EmailType.resetPassword,
                },
              })
            }
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(verificationNumber === '' || verificationSuccess)
              ) {
                verifyEmail({
                  variables: {
                    code: verificationNumber,
                    email: email,
                    emailType: EmailType.resetPassword,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="password"
            value={password}
            onChange={onChange}
            label="새 비밀번호"
            placeholder={
              isMobile
                ? '영문/숫자/특수문자/8자 이상'
                : '영문, 숫자, 특수문자 포함 8자 이상'
            }
            secure
            errorMessage={passwordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  !verificationSuccess
                )
              ) {
                resetPassword({
                  variables: {
                    email: email,
                    newPassword: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <InputLayout>
          <Input
            name="confirmPassword"
            value={confirmPassword}
            onChange={onChange}
            label="새 비밀번호 확인"
            placeholder={
              isMobile
                ? '영문/숫자/특수문자/8자 이상'
                : '영문, 숫자, 특수문자 포함 8자 이상'
            }
            secure
            errorMessage={confirmPasswordErrorMessage}
            onKeyPress={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  email === '' ||
                  emailErrorMessage !== '' ||
                  password === '' ||
                  passwordErrorMessage !== '' ||
                  confirmPassword === '' ||
                  confirmPasswordErrorMessage !== '' ||
                  !verificationSuccess
                )
              ) {
                resetPassword({
                  variables: {
                    email: email,
                    newPassword: password,
                    userType: userType,
                  },
                });
              }
            }}
          />
        </InputLayout>
        <Button
          onClick={() => {
            resetPassword({
              variables: {
                email: email,
                newPassword: password,
                userType: userType,
              },
            });
          }}
          disabled={
            email === '' ||
            emailErrorMessage !== '' ||
            password === '' ||
            passwordErrorMessage !== '' ||
            confirmPassword === '' ||
            confirmPasswordErrorMessage !== '' ||
            !verificationSuccess
          }
          type="primary"
          style={{
            width: '272px',
            margin: isMobile ? '6px auto 0' : '20px auto 0',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          비밀번호 변경
        </Button>
      </BodyLayout>
    </Layout>
  );
};

export default FindPasswordModal;
